import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { DEVELOPER_STATUSES } from "../../../constants";

const Container = styled.div`
  background: white;
  margin-bottom: 10px;
  border-radius: 5px;
  height: auto;
  padding: 8px;
  cursor: ${(props) => (props.draggable ? "grabbing" : "pointer")};
  border: ${(props) => props.missingInfo && "solid 1px red"};
  opacity: ${(props) => props.missingInfo && 0.5};

  &.dragging {
    opacity: 0.5;
  }
`;

const ChildContainer = styled.div`
  &.is-updating {
    opacity: 0.5;
    filter: blur(1.2px);
  }
`;

const SpinnerContainer = styled.div`
  margin-top: -29px;
  text-align: center;
`;

export default function TgKanbanColumnCard({
  drag,
  setDrag,
  cardId,
  columnId,
  substate = null,
  children,
  onClick,
  updateCardId,
  draggable = true,
  userStatus,
}) {
  const isUpdating = updateCardId && updateCardId === cardId;
  return (
    <Container
      draggable={draggable}
      onDragStart={() =>
        draggable &&
        setDrag({
          sourceColumnId: columnId,
          sourceSubstate: substate,
          cardId,
        })
      }
      onDragEnd={() => setDrag(null)}
      className={drag && drag.cardId === cardId ? "dragging" : ""}
      onClick={(event) => onClick({ event, cardId })}
      missingInfo={userStatus === DEVELOPER_STATUSES.PRE_LEAD}
    >
      <ChildContainer className={isUpdating ? "is-updating" : ""}>
        {children}
      </ChildContainer>
      {isUpdating && (
        <SpinnerContainer>
          <Spin />
        </SpinnerContainer>
      )}
    </Container>
  );
}
