import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { Button, ButtonLink, Tooltip } from "tg-design";
import { GET_ALL_POSITION } from "./queries";
import { handleHead, getUrlParams, setQueries } from "../../helper";
import { getQueries } from "./module/QueryHelper";
import CompanySearch from "./module/CompanySearch";
import AssigneeSearch from "./module/AssigneeSearch";
import TitleSearch from "./module/TitleSearch";
import { SORTERS } from "../../constants/index";
import {
  TgTable,
  TgHeader,
  TgDrawer,
  TgTotalInfo,
  TgButtonField,
} from "../tg-styles/Layouts";
import CreatePositionForm from "./drawers/CreatePositionForm";
import PartialError from "../ErrorPage/PartialError";
import Popover from "./Popover";
import PositionFilterOptions from "./PositionFilterOptions";
import { FilterSettingsIcon } from "../Icons";
import { ResetFilters, PopoverContainer } from "./styles";
import { resetFilters, showAll } from "../../store/slices/positionFilter";
import { POSITION_FILTER_LABELS, COLUMN_WIDTHS } from "./constants";

const statusColor = {
  CREATED: "blue",
  IN_PROGRESS: "orange",
  ON_HOLD: "gray",
  FILLED: "green",
  CLOSED: "green",
  CANCELED: "red",
};

const statusFilter = [
  { text: "Created", value: "CREATED" },
  { text: "Awaiting Approval", value: "SUBMITTED" },
  { text: "In Progress", value: "IN_PROGRESS" },
  { text: "On Hold", value: "ON_HOLD" },
  { text: "Filled", value: "FILLED" },
  { text: "Closed", value: "CLOSED" },
  { text: "Canceled", value: "CANCELED" },
];

const positionTypeFilter = [
  { text: "External", value: true },
  { text: "Internal", value: false },
];

const getDayDiff = (dateDiff, date) => {
  if (dateDiff === 0) {
    return <Tooltip text={dayjs(date).format("DD-MM-YYYY")}>Today</Tooltip>;
  }
  if (dateDiff === 1) {
    return (
      <Tooltip text={dayjs(date).format("DD-MM-YYYY")}>
        {dateDiff} day ago
      </Tooltip>
    );
  }
  return (
    <Tooltip text={dayjs(date).format("DD-MM-YYYY")}>
      {dateDiff} days ago
    </Tooltip>
  );
};

const columns = [
  {
    title: "Position",
    alwaysShow: true,
    dataIndex: "title",
    fixed: "left",
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <TitleSearch
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
    render: (value, record) => (
      <Link to={`/positions/${record.id}`}>
        {value}
        {record.isTest && (
          <span style={{ color: "#ff7675", paddingLeft: "5px" }}>-Test-</span>
        )}
      </Link>
    ),
  },
  {
    title: "Company",
    alwaysShow: true,
    fixed: "left",
    dataIndex: "company",
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <CompanySearch
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
      />
    ),
    render: (value) => <Link to={`/companies/${value.id}`}>{value.name}</Link>,
  },
  {
    title: "Talent Partner",
    alwaysShow: true,
    dataIndex: "assignee",
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <AssigneeSearch
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        confirm={confirm}
      />
    ),
    render: (value) => value && value.name,
  },
  {
    title: "State",
    alwaysShow: true,
    dataIndex: "state",
    filters: statusFilter,
    filterMultiple: false,
    align: "center",
    render: (text) => {
      const item = statusFilter.find((i) => i.value === text);
      return <Tag color={statusColor[text]}>{(item && item.text) || text}</Tag>;
    },
  },
  {
    title: "Position Type",
    alwaysShow: true,
    dataIndex: "isExternal",
    filters: positionTypeFilter,
    filterMultiple: false,
    align: "center",
    render: (text) => {
      return (
        <Tag color={text ? "grey" : "green"}>
          {text ? "External" : "Internal"}
        </Tag>
      );
    },
  },
  {
    title: POSITION_FILTER_LABELS.firstBatchCreated.label,
    key: POSITION_FILTER_LABELS.firstBatchCreated.key,
    dataIndex: "firstBatchCreated",
    align: "center",
    render: (date) => {
      if (!date) {
        return "-";
      }
      const now = dayjs();
      const dateDiff = dayjs(now).diff(date, "day");

      return getDayDiff(dateDiff, date);
    },
  },
  {
    title: POSITION_FILTER_LABELS.firstVerifiedTalent.label,
    key: POSITION_FILTER_LABELS.firstVerifiedTalent.key,
    dataIndex: ["firstVerifiedTalent"],
    align: "center",
    render: (date) => {
      if (!date) {
        return "-";
      }
      const now = dayjs();
      const dateDiff = dayjs(now).diff(date, "day");

      return getDayDiff(dateDiff, date);
    },
  },
  {
    title: POSITION_FILTER_LABELS.verificationDuration.label,
    key: POSITION_FILTER_LABELS.verificationDuration.key,
    dataIndex: ["verificationDuration"],
    align: "center",
    render: (value) => {
      const getDuration = (duration) => {
        if (duration === null) {
          return "-";
        }
        if (duration === 1) {
          return `${duration} day`;
        }
        return `${duration} days`;
      };
      return getDuration(value);
    },
  },
  {
    title: POSITION_FILTER_LABELS.totalReachedOut.label,
    key: POSITION_FILTER_LABELS.totalReachedOut.key,
    dataIndex: ["totalReachedOut"],
    align: "center",
    render: (value) => {
      return value;
    },
  },
  {
    title: POSITION_FILTER_LABELS.totalShowedInterest.label,
    key: POSITION_FILTER_LABELS.totalShowedInterest.key,
    dataIndex: ["totalShowedInterest"],
    align: "center",
    render: (value) => {
      return value;
    },
  },
  {
    title: POSITION_FILTER_LABELS.prevetted.label,
    key: POSITION_FILTER_LABELS.prevetted.key,
    dataIndex: ["prevetted"],
    align: "center",
    render: (value) => {
      return value;
    },
  },
  {
    title: POSITION_FILTER_LABELS.verified.label,
    key: POSITION_FILTER_LABELS.verified.key,
    dataIndex: ["verified"],
    align: "center",
    render: (value) => {
      return value;
    },
  },
  {
    title: POSITION_FILTER_LABELS.activelyInTheProcess.label,
    key: POSITION_FILTER_LABELS.activelyInTheProcess.key,
    dataIndex: ["activelyInTheProcess"],
    align: "center",
    render: (value) => {
      return value;
    },
  },
  {
    title: POSITION_FILTER_LABELS.interestedReached.label,
    key: POSITION_FILTER_LABELS.interestedReached.key,
    dataIndex: ["interestedReached"],
    align: "center",
    render: (value) => {
      return value ? `%${value}` : "-";
    },
  },
  {
    title: POSITION_FILTER_LABELS.verifiedPrevetted.label,
    key: POSITION_FILTER_LABELS.verifiedPrevetted.key,
    dataIndex: ["verifiedPrevetted"],
    align: "center",
    render: (value) => {
      return value ? `%${value}` : "-";
    },
  },
];

const DEFAULT_SORT = { createdAt: "DESC" };

export default function Positions() {
  const [positionData, setPositionData] = useState();
  const [tableKey, setTableKey] = useState(0);
  const [drawerContent, setDrawer] = useState(null);
  const [isMatchFilterSettingsVisible, setIsMatchFilterSettingsVisible] =
    useState(false);

  const dispatch = useDispatch();
  const { checkboxFilters } = useSelector((state) => state.positionFilter);

  const [getAllPosition, { loading, data, error }] = useLazyQuery(
    GET_ALL_POSITION,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setPositionData(response.allPosition.positions);
      },
    }
  );
  const DEFAULT_LIMIT = 10;
  const [queryString, setQueryString] = useState("");
  handleHead("positions");

  const wrapperRef = useRef(null);

  useEffect(() => {
    const params = getUrlParams();
    const {
      search,
      filters,
      page = 1,
      limit = DEFAULT_LIMIT,
      sort = DEFAULT_SORT,
    } = getQueries(params);
    const fetch = async () => {
      getAllPosition({
        variables: {
          limit,
          page,
          search,
          filters,
          sort,
        },
      });
    };
    fetch();
  }, [queryString, getAllPosition]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsMatchFilterSettingsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMatchFilterSettingsVisible]);

  const toggleMatchFilterSettings = () => {
    setIsMatchFilterSettingsVisible(!isMatchFilterSettingsVisible);
  };

  const getDefaultPage = () => {
    const params = getUrlParams();
    const { page = 1 } = getQueries(params);
    return page;
  };

  const getDefaultLimit = () => {
    const params = getUrlParams();
    const { limit = DEFAULT_LIMIT } = getQueries(params);
    return limit;
  };

  const onChange = (pagination, filters, sorter) => {
    let sort = JSON.stringify(DEFAULT_SORT);

    if (sorter?.field && sorter?.order) {
      sort = {
        [sorter.field]: SORTERS[sorter.order],
      };
    }

    const params = getUrlParams();
    setQueryString(
      setQueries(
        Object.assign(params, {
          page: pagination.current,
          limit: pagination.pageSize,
          sort,
          ...filters,
        })
      )
    );
  };

  const clearFilters = () => {
    const url = new URL(window.location);
    url.search = "";
    window.history.pushState({}, "", url);
    setQueryString("?");
    setTableKey(tableKey + 1);
  };

  const handleResetFilters = () => {
    dispatch(resetFilters());
  };

  const handleShowAll = () => {
    dispatch(showAll());
  };

  const onDrawerClosed = () => {
    setDrawer(null);
  };

  if (error) return <PartialError />;

  let totalTableWidth = 0;

  COLUMN_WIDTHS.forEach((i) => {
    if (checkboxFilters[i.key]) {
      totalTableWidth += i.width;
    }
  });

  return (
    <div>
      <TgHeader breadCrumbs={[{ title: "Positions", link: "/positions" }]} />
      {drawerContent && (
        <TgDrawer closable={false} onClose={onDrawerClosed} visible>
          {drawerContent}
        </TgDrawer>
      )}

      <div className="info-field">
        <TgTotalInfo
          count={data && data.allPosition.totalCount}
          title="positions"
        />
        <TgButtonField>
          <ButtonLink
            onClick={() => clearFilters()}
            style={{ marginRight: "15px" }}
          >
            Clear Filters
          </ButtonLink>
          <Button
            onClick={() =>
              setDrawer(<CreatePositionForm handleClose={onDrawerClosed} />)
            }
          >
            Create New Position
          </Button>
        </TgButtonField>
      </div>
      <PopoverContainer style={{ paddingRight: "10px" }}>
        {Object.values(checkboxFilters).includes(false) && (
          <ResetFilters onClick={handleShowAll}>Show All</ResetFilters>
        )}
        {Object.values(checkboxFilters).includes(true) && (
          <ResetFilters onClick={handleResetFilters}>Hide All</ResetFilters>
        )}
        <div ref={wrapperRef}>
          <Popover
            width={200}
            floatDimensions={{ right: "calc(100% - 21px)" }}
            display={isMatchFilterSettingsVisible ? "block" : "none"}
            body={<PositionFilterOptions />}
            trigger={<FilterSettingsIcon />}
            onTrigger={toggleMatchFilterSettings}
          />
        </div>
      </PopoverContainer>

      <TgTable
        key={tableKey}
        rowKey={(record) => record.id}
        columns={columns.filter((i) => i.alwaysShow || checkboxFilters[i.key])}
        dataSource={positionData || []}
        onChange={onChange}
        loading={loading}
        pagination={{
          pageSize: getDefaultLimit(),
          total: data && data.allPosition.totalCount,
          defaultCurrent: getDefaultPage(),
        }}
        scroll={{ x: 800 + totalTableWidth, y: "calc(100vh - 200px)" }}
      />
    </div>
  );
}
