/* eslint camelcase: 0 */
import React from "react";
import { TgTable } from "../tg-styles/Layouts";

const columns = [
  {
    title: "",
    dataIndex: "subTitle",
    render: (value) => <b style={{ whiteSpace: "nowrap" }}>{value}</b>,
    width: "10%",
  },
  {
    title: "Position",
    dataIndex: "base",
    render: (value) => <span>{value}</span>,
    width: "30%",
  },
  {
    title: "Batch",
    dataIndex: "position",
    render: (value) => <span>{value}</span>,
    width: "30%",
  },
  {
    title: "User",
    dataIndex: "developer",
    render: (value) => <span>{value}</span>,
    width: "30%",
  },
];

export default function ComparisonTable({ title, data }) {
  return (
    <div>
      <br />
      <h2>{title}</h2>
      <TgTable
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="subTitle"
      />
    </div>
  );
}
