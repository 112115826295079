import React, { useState, useEffect } from "react";
import { Tabs, TabItem } from "tg-design";
import { useQuery } from "@apollo/client";
import { message } from "antd";
import {
  CreateNewBatchSection,
  ExistingBatchesSection,
} from "./BatchesSections";
import { GET_POSITION_BATCHES } from "../../queries";
import { captureErrorWithData } from "../../../../helper";

export default function BatchesTab({ position }) {
  const [currentTab, setCurrentTab] = useState("new-batch");
  const [batches, setBatches] = useState(null);
  const { refetch: getPositionBatches } = useQuery(GET_POSITION_BATCHES, {
    skip: true,
  });

  const getBatches = async () => {
    try {
      const { data } = await getPositionBatches({
        id: position.id,
      });
      setBatches(data.position.batches);
    } catch (error) {
      message.error("An error occurred");
      captureErrorWithData({ positionId: position.id });
    }
  };

  useEffect(() => {
    getBatches();
    // eslint-disable-next-line
  }, [position.id]);

  if (!batches) return "loading";

  return (
    <>
      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab}>
        <TabItem
          component={
            <CreateNewBatchSection
              position={position}
              batches={batches}
              refetch={getBatches}
            />
          }
          name="new-batch"
          title="Create New Batch"
        />
        <TabItem
          component={
            <ExistingBatchesSection
              isTestPosition={position.isTest}
              batches={batches}
              positionID={position.id}
            />
          }
          name="existing-batches"
          title="Existing Batches"
          count={batches.length}
        />
      </Tabs>
    </>
  );
}
