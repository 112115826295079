import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { SearchIcon } from "../../Icons";
import { debounce } from "../../../helper";

const StyledInput = styled.input`
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 8px 16px 8px 40px;

  &:focus {
    outline: none;
  }
`;

const StyledSpinner = styled(Spin)`
  margin-top: -8px;
`;

const IconContainer = styled.div`
  display: inline;
  position: relative;
  left: 30px;
  top: 6px;
`;

export default function SearchInput({ onLazyChange, isSearching, ...props }) {
  const lazyCaller = debounce((value) => {
    onLazyChange(value);
  }, 500);

  const handleChange = (event) => {
    lazyCaller(event.target.value);
  };

  return (
    <div>
      <IconContainer>
        {!isSearching && <SearchIcon />}
        {isSearching && <StyledSpinner />}
      </IconContainer>
      <StyledInput {...props} onChange={handleChange} />
    </div>
  );
}
