import styled from "styled-components";

export const FormContainer = styled.div`
  width: 350px;
  padding: 50px 0;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const inputStyles = {
  marginBottom: "20px",
};

export const Description = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  max-width: 350px;
  margin-bottom: 20px;
`;

export const TimerBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
