import React, { useState, useCallback, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Form, Input, message, Select } from "antd";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  COUNTRIES,
} from "tg-design";
import { Option } from "antd/lib/mentions";
import AlternativeNames from "./AlternativeNames";
import { useResetFormOnCloseModal } from "../../../company/modules/useResetForm";
import { captureErrorWithData, debounce } from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";

const CHECK_IF_UNIVERSITY_EXISTS = gql`
  query checkIfUniversityExistsByName($name: String!) {
    checkIfUniversityExistsByName(name: $name) {
      name
    }
  }
`;

const ModalUniversities = ({ visible, onCancel, createUniversity }) => {
  const [form] = Form.useForm();

  const [state, setState] = useState({ name: "" });
  const [isAlreadyExists, setIsAlreadyExists] = useState(false);

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const [checkIfUniversityExistsByName, { error: universityCheckError }] =
    useLazyQuery(CHECK_IF_UNIVERSITY_EXISTS, {
      onCompleted: (data) => {
        if (data?.checkIfUniversityExistsByName?.name) {
          setIsAlreadyExists(true);
        } else {
          setIsAlreadyExists(false);
        }
      },
      fetchPolicy: "network-only",
    });

  const onOk = async () => {
    const currentValue = form.getFieldsValue();
    if (!currentValue.name) {
      message.error("Name can NOT be empty");
    } else if (!currentValue.country) {
      message.error("Country can NOT be empty");
    } else {
      const variables = { ...currentValue };
      try {
        await createUniversity(variables);
        message.success("University Created");
        setIsAlreadyExists(false);
      } catch (error) {
        captureErrorWithData(error);
        message.error(error.message || error);
        setIsAlreadyExists(false);
      }

      onCancel();
    }
  };

  const onType = useCallback(
    debounce(async (val) => {
      try {
        await checkIfUniversityExistsByName({
          variables: { name: val },
        });
      } catch (err) {
        message.error("Something went wrong");
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (state.name) {
      onType(state.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getValidateStatus = () => {
    const currentValue = form.getFieldsValue();

    if (!currentValue.name) {
      return { status: "error", message: `'name' can not be empty` };
    }
    if (isAlreadyExists) {
      return {
        status: "error",
        message: `A university with the name '${state.name}' already
      exists.`,
      };
    }
  };

  const handleClose = () => {
    setIsAlreadyExists(false);
    onCancel();
  };

  return (
    <>
      {visible && (
        <Modal handleClose={handleClose}>
          <ModalHeader>Create New University</ModalHeader>
          <ModalBody>
            {!universityCheckError ? (
              <Form form={form} layout="vertical" name="universityForm">
                <Form.Item
                  name="name"
                  label="University Name"
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                  validateStatus={getValidateStatus()?.status}
                  help={getValidateStatus()?.message}
                >
                  <Input placeholder="University Name" />
                </Form.Item>
                <AlternativeNames />
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[{ required: true }]}
                >
                  <Select showSearch placeholder="Select a country">
                    {COUNTRIES.map((country, index) => (
                      <Option key={index} value={`${country.name}`}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            ) : (
              <PartialError />
            )}
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onOk} disabled={isAlreadyExists}>
              Save
            </Button>
            <Button onClick={handleClose} outline size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ModalUniversities;
