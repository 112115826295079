import React from "react";
import styled from "styled-components";

const StyledLi = styled.li`
  padding-bottom: 10px;
  margin-bottom: -2px;
  &.active {
    border-bottom: 3px solid #64b7dc;
  }
`;

const StyledLink = styled.a`
  padding: 0px 10px 0px 10px;
  font-weight: 600;
  font-size: 14px;
  color: #1a1a1a;

  &.active {
    color: #64b7dc;
  }
  &:hover {
    color: #64b7dc;
  }
`;

const CountBox = styled.span`
  background: #8fdeac;
  padding: 3px 7px;
  border-radius: 20px;
  margin-left: 5px;
  color: white;
  font-size: 12px;
`;

export default function TgTab({
  name,
  handleChange,
  currentTab,
  children,
  classname,
  count = 0,
  ...props
}) {
  const activeClass = currentTab === name ? "active" : "";

  return (
    <StyledLi className={classname || activeClass}>
      <StyledLink
        className={classname || activeClass}
        onClick={() => handleChange(name)}
        {...props}
      >
        {children}
        {count > 0 && <CountBox>{count}</CountBox>}
      </StyledLink>
    </StyledLi>
  );
}
