import React, { useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { useMutation } from "@apollo/client";
import TextField from "../../../Form/TextField";
import { CheckboxField } from "../../../Form/CheckboxField";
import { UPDATE_CUSTOM_FLOW } from "../../queries";
import { MATCH_STATES } from "../../../../constants";

const CheckBoxContainer = styled.div`
  padding-top: 20px;
`;

const CHECKBOX_STYLE = {
  height: "20px",
  width: "20px",
  marginTop: "-1px",
  marginRight: "10px",
};

const CONTAINER_STYLE = {
  fontSize: "14px",
  color: "#444444",
};

const VISIBILITY_MAP = {
  DEVELOPER: [
    MATCH_STATES.SENT,
    MATCH_STATES.INTERESTED,
    MATCH_STATES.TG_ASSESSMENT,
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.HIRED,
  ],
  COMPANY: [
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.HIRED,
    MATCH_STATES.FAILED,
  ],
};

export default function CustomFlowEditModal({
  positionId,
  data,
  onCancel,
  onSave,
}) {
  const { column } = data;
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(data.substate);
  const [error, setError] = useState(null);
  const [updateCustomFlow] = useMutation(UPDATE_CUSTOM_FLOW);

  const handleSave = async () => {
    if (!value.title || value.title.trim().length === 0) {
      setError("The value is required.");
      return;
    }
    setSaving(true);

    try {
      await updateCustomFlow({
        variables: {
          positionId,
          state: data.column.id,
          substate: data.substate.uuid,
          ...value,
        },
      });
      onSave();
    } catch (error) {
      setSaving(false);
      message.error(error.message);
    }
  };

  return (
    <Modal handleClose={onCancel}>
      <ModalHeader>Edit Subcolumn</ModalHeader>
      <ModalBody>
        <TextField
          autoFocus
          isRequired
          label="Title"
          defaultValue={value.title}
          error={error}
          onChange={(event) =>
            setValue({
              ...value,
              title: event.target.value,
            })
          }
          maxLength={25}
        />
        {VISIBILITY_MAP.DEVELOPER.includes(column.id) && (
          <CheckBoxContainer>
            <CheckboxField
              checked={value.isVisibleToDeveloper}
              name="isVisibleToDeveloper"
              onChange={(event) =>
                setValue({
                  ...value,
                  isVisibleToDeveloper: event.target.checked,
                })
              }
              value={value.isVisibleToDeveloper}
              containerStyles={CONTAINER_STYLE}
              checkboxStyles={CHECKBOX_STYLE}
            >
              Visible to developer app
            </CheckboxField>
          </CheckBoxContainer>
        )}
        {VISIBILITY_MAP.COMPANY.includes(column.id) && (
          <CheckBoxContainer>
            <CheckboxField
              checked={value.isVisibleToCompany}
              name="isVisibleToCompany"
              value={value.isVisibleToCompany}
              onChange={(event) =>
                setValue({
                  ...value,
                  isVisibleToCompany: event.target.checked,
                })
              }
              containerStyles={CONTAINER_STYLE}
              checkboxStyles={CHECKBOX_STYLE}
            >
              Visible to company app
            </CheckboxField>
          </CheckBoxContainer>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSave} loading={saving}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
