import React from "react";
import { useLocation } from "react-router-dom";
import { REMOTE_OPTIONS_FOR_DEVELOPER } from "../../../../../constants";

import { Row, SectionTitle, SectionDescription } from "./styles";

export default function ExpectationView({ expectations }) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  return (
    <>
      {!isPrintPage &&
        expectations &&
        expectations.salary &&
        expectations.salary.expected && (
          <Row>
            <SectionTitle>Salary</SectionTitle>
            <SectionDescription>
              {expectations?.salary?.expected || "-"}{" "}
              {expectations?.salary?.currency} {expectations?.salary?.income} /{" "}
              {expectations?.salary?.period}
            </SectionDescription>
          </Row>
        )}

      {expectations &&
        expectations.benefits &&
        expectations.benefits.expected.length > 0 && (
          <Row mt={2}>
            <SectionTitle>Side benefits</SectionTitle>
            <SectionDescription>
              {expectations?.benefits?.expected
                .map((i) => i.label)
                .join(", ") || "-"}
            </SectionDescription>
          </Row>
        )}

      {expectations &&
        expectations.companySize &&
        expectations.companySize.expected.length > 0 && (
          <Row mt={2}>
            <SectionTitle>Company Size</SectionTitle>
            <SectionDescription>
              {expectations?.companySize?.expected
                .map((i) => i.label)
                .join(", ") || "-"}
            </SectionDescription>
          </Row>
        )}
      {expectations &&
        expectations.technologies &&
        expectations.technologies.expected.length > 0 && (
          <Row mt={2}>
            <SectionTitle>Tech Stack</SectionTitle>

            <SectionDescription>
              {expectations?.technologies?.expected
                .map((i) => i.title)
                .join(", ") || "-"}
            </SectionDescription>
          </Row>
        )}

      {expectations &&
        expectations.location &&
        expectations.location.expected.length > 0 && (
          <Row mt={2}>
            <SectionTitle>Work Location</SectionTitle>

            <SectionDescription>
              {expectations?.location?.expected
                .map((i) => {
                  if (
                    expectations?.location?.istanbulRegion &&
                    i.city === "Istanbul"
                  ) {
                    return `${i.city} - ${expectations?.location?.istanbulRegion}`;
                  }
                  return i.city;
                })
                .join(", ") || "-"}
            </SectionDescription>
          </Row>
        )}

      <Row mt={2}>
        <SectionTitle>Location Preference</SectionTitle>
        <SectionDescription>
          {REMOTE_OPTIONS_FOR_DEVELOPER.find(
            (i) => i.id === expectations?.location?.remote
          )?.title || "-"}
        </SectionDescription>
      </Row>
      {!isPrintPage &&
        expectations &&
        expectations.freelance &&
        expectations.freelance.availableWeeklyHour &&
        expectations.freelance.hourlyRate && (
          <Row>
            <SectionTitle>Freelance</SectionTitle>
            <SectionDescription>
              {expectations?.freelance?.availableWeeklyHour || "-"}{" "}
              {expectations?.freelance?.availableWeeklyHour === 1
                ? "hour"
                : "hours"}{" "}
              availability , rate: {expectations?.freelance?.hourlyRate || "-"}{" "}
              {expectations?.freelance?.hourlyRateCurrency}
            </SectionDescription>
          </Row>
        )}
    </>
  );
}
