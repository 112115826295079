import React, { useState, useCallback } from "react";
import { message } from "antd";
import { useQuery } from "@apollo/client";
import { AsyncSelectField, SelectField } from "../../Form/SelectField";
import { RowBlock, ColumnBlock } from "../../Form/BlockUi";
import {
  Separator,
  GroupContainer,
  AddRowButton,
  AddGroupButton,
} from "./styles";
import { AddGroupIcon, AddRowIcon, DeleteIcon } from "../../Icons";
import { SEARCH_TECHNOLOGY } from "../queries";
import { captureErrorWithData, debounce } from "../../../helper";

const skillExperiences = [
  { value: 1, name: "1 year" },
  { value: 2, name: "2 years" },
  { value: 3, name: "3 years" },
  { value: 4, name: "4 years" },
  { value: 5, name: "5 years" },
  { value: 6, name: "6 years" },
  { value: 7, name: "7 years" },
  { value: 8, name: "8+ years" },
];

export const toValidSkills = (values) => {
  return values
    .filter((arr) => arr.length !== 0)
    .map((row) => {
      return row.map((item) => {
        return {
          _id: item._id,
          experience: item.experience,
        };
      });
    });
};

const Group = ({ skillList, update, groupIndex }) => {
  const { refetch: technologyRefetch } = useQuery(SEARCH_TECHNOLOGY, {
    skip: true,
  });
  const [skillState, setSkillState] = useState(skillList);

  const filterState = (state) => {
    const filteredState = state.filter(
      (s) => s.experience !== 0 && s.title !== ""
    );
    return filteredState;
  };

  const addRow = () => {
    const filteredState = filterState(skillState);
    setSkillState([...filteredState, { title: "", experience: 1 }]);
  };

  const handleOnChange = (skill, index) => {
    const currentState = [...skillState];
    currentState[index] = skill;
    setSkillState(currentState);
    const filteredState = filterState(currentState);
    if (filteredState.length > index) {
      update({ changes: filteredState, idx: groupIndex });
    }
  };

  const deleteRow = (index) => {
    const currentState = [...skillState];
    const filteredState = filterState(currentState);
    filteredState.splice(index, 1);
    setSkillState(filteredState);
    update({ changes: filteredState, idx: groupIndex });
  };

  const handleTechnologySearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await technologyRefetch({
          search: val,
          verified: true,
        });
        callback(data.allTechnology.technologies);
      } catch (err) {
        captureErrorWithData(err);
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  return (
    <GroupContainer>
      {skillState.map((skill, index) => {
        return (
          <div key={skill._id}>
            <RowBlock key={skill._id} mx={2} id={`skillrow-${index}`}>
              <ColumnBlock width="60%" px={2} my={3}>
                <AsyncSelectField
                  defaultValue={{ title: skill.title, id: skill._id }}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option._id}
                  optionStyle={{ background: "white" }}
                  loadOptions={handleTechnologySearch}
                  onChange={(selectedOption) =>
                    handleOnChange(
                      {
                        ...skill,
                        title: selectedOption.title,
                        _id: selectedOption.id,
                      },
                      index
                    )
                  }
                  hasError={!skill.title}
                />
              </ColumnBlock>

              <ColumnBlock width="35%" px={2} my={3}>
                <SelectField
                  defaultValue={{
                    name: skill.experience,
                    value: skill.experience,
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  options={skillExperiences}
                  optionStyle={{ background: "white" }}
                  onChange={(selectedOption) =>
                    handleOnChange(
                      { ...skill, experience: selectedOption.value },
                      index
                    )
                  }
                />
              </ColumnBlock>
              <ColumnBlock width="5%" onClick={() => deleteRow(index)}>
                <span
                  role="img"
                  style={{ cursor: "pointer", fontSize: "10px" }}
                  aria-label="Delete Icon"
                  aria-labelledby={`skillrow-${index}`}
                >
                  ✖️
                </span>
              </ColumnBlock>
            </RowBlock>
            {skillState.length === index ? (
              ""
            ) : (
              <RowBlock mt={1} mb={1} ml={3} style={{ justifyContent: "left" }}>
                <Separator>OR</Separator>
              </RowBlock>
            )}
          </div>
        );
      })}
      <RowBlock mt={3} mb={3} ml={3} style={{ justifyContent: "left" }}>
        <AddRowButton onClick={addRow}>
          <AddRowIcon />
        </AddRowButton>
      </RowBlock>
    </GroupContainer>
  );
};

export default function SkillGroup({ state = [], setState }) {
  const handleUpdate = (changes) => {
    const _initialValues = [...state];
    _initialValues[changes.idx] = changes.changes;
    setState(_initialValues);
  };

  const addGroup = () => {
    const currentState = [...state];
    currentState.push([]);
    setState(currentState);
  };

  const groupDelete = (index) => {
    const currentState = [...state];
    currentState.splice(index, 1);
    setState(currentState);
  };

  const generateKey = (index) => {
    return `${index}_${Math.random()}`;
  };

  return (
    <>
      {state &&
        state.map((list, index) => {
          return (
            <div key={index}>
              <div
                key={generateKey(index)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Group
                  key={generateKey(index)}
                  skillList={list}
                  groupIndex={index}
                  update={handleUpdate}
                />
                <div
                  onClick={() => groupDelete(index)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                  <DeleteIcon color="#ff9898" />
                </div>
              </div>
              {state.length === index ? (
                ""
              ) : (
                <RowBlock
                  mt={1}
                  mb={1}
                  ml={3}
                  style={{ justifyContent: "left" }}
                >
                  <Separator>AND</Separator>
                </RowBlock>
              )}
            </div>
          );
        })}
      <RowBlock mb={3} mt={2} style={{ justifyContent: "left" }}>
        <AddGroupButton type="button" onClick={addGroup}>
          <AddGroupIcon />
        </AddGroupButton>
      </RowBlock>
    </>
  );
}
