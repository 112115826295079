import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { CopyOutlined } from "@ant-design/icons";
import {
  PlaceIcon,
  GitHubIcon,
  StackoverflowIcon,
  ShuffleIcon,
  FlagIconSmall,
  BlogIcon,
  TwitterIcon,
  YoutubeIcon,
  CircleCheckIcon,
} from "../../Icons";
import {
  MATCH_STATE_TITLES,
  MATCH_STATE_BG_COLORS,
  MATCH_STATE_COLORS,
  MATCH_STATES,
} from "../../../constants/states";
import {
  Badge,
  LinkedInLink,
  getActiveCompanyMatchCountWithoutCurrentMatch,
} from "../../position/detail/tabs/MatchPreview";
import { FLAGS } from "../../../constants";
import { handleCopy, removeDuplicateFlags } from "../../../helper";

import { GET_POTENTIAL_USER_POSITION } from "../../developers/queries";

const ProfileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: 10px;
  padding-bottom: 20px;
`;

const AvatarContainer = styled.div`
  margin-right: 15px;

  @media only screen and (max-width: 768px) {
    margin-right: 10px;
  }
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const DataContainer = styled.div`
  font-family: Inter;
  font-style: normal;
`;

const DeveloperName = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #1a1a1a;
  margin: 0px;
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const DeveloperTitle = styled.p`
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

const PlaceRaw = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #9ca3af;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    font-weight: normal;
  }

  & > svg {
    margin-right: 5px;
  }
`;

const Place = styled.div`
  margin-right: 15px;
`;

const LabelContainer = styled.div`
  margin-left: auto;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Label = styled.span`
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 8px;
`;

const SocialBox = styled.div`
  padding-top: 5px;
  & a {
    margin-right: 10px;
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: right;
  margin-bottom: 10px;
  padding-right: 8px;
`;

const ForceMatchLink = styled.div`
  font-size: small;
  margin-top: 20px;
`;

const SocialLink = styled.a``;

const getLinkWithURLScheme = (link) => {
  const regexPattern = new RegExp("https?://");

  if (regexPattern.test(link)) {
    return link;
  }
  return `//${link}`;
};

const MatchStatusLabel = ({ match }) => {
  return (
    <Label
      style={{
        backgroundColor: MATCH_STATE_BG_COLORS[match.state],
        color: MATCH_STATE_COLORS[match.state],
      }}
    >
      {MATCH_STATE_TITLES[match.state] || "Unknown"}
    </Label>
  );
};

export default function ProfileBox({ match, user, showLink }) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  const activeCompanyMatchCount =
    getActiveCompanyMatchCountWithoutCurrentMatch(match);

  const [getPotentialUserPositionData, { data, loading, error }] = useLazyQuery(
    GET_POTENTIAL_USER_POSITION,
    {
      variables: { matchId: match?.id },
    }
  );

  useEffect(() => {
    if (match && match.state === MATCH_STATES.EXTERNAL_SOURCING) {
      getPotentialUserPositionData();
    }

    // eslint-disable-next-line
  }, []);

  const getUserName = () => {
    return (
      <>
        <span>{user?.name} </span>
        {user.isTest && <span style={{ color: "#ff7675" }}>-Test-</span>}
      </>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error!</div>;

  return (
    <ProfileContainer>
      <AvatarContainer>
        <Avatar
          src={
            user?.avatarURL || `${process.env.PUBLIC_URL}/default_avatar.png`
          }
        />
      </AvatarContainer>
      <DataContainer>
        <DeveloperName>
          {showLink ? (
            <Link to={`/developers/${user.id}`} target="_blank">
              {getUserName() || "Noname"}
            </Link>
          ) : (
            <span>{getUserName() || "Noname"}</span>
          )}
          {user.isVerifiedByTP && <CircleCheckIcon />}
        </DeveloperName>
        <DeveloperTitle>{user.role?.title}</DeveloperTitle>
        <PlaceRaw>
          <PlaceIcon />
          <Place>{user?.livingCity?.city}</Place>
        </PlaceRaw>
        {!isPrintPage && (
          <>
            <SocialBox>
              <LinkedInLink
                link={user.social && user.social.linkedin}
                name={user.name}
              />
              {user?.social?.github && (
                <SocialLink
                  href={getLinkWithURLScheme(user?.social?.github)}
                  target="_blank"
                >
                  <GitHubIcon />
                </SocialLink>
              )}
              {user?.social?.stackoverflow && (
                <SocialLink
                  href={getLinkWithURLScheme(user?.social?.stackoverflow)}
                  target="_blank"
                >
                  <StackoverflowIcon />
                </SocialLink>
              )}
              {user?.social?.blog && (
                <SocialLink
                  href={getLinkWithURLScheme(user?.social?.blog)}
                  target="_blank"
                >
                  <BlogIcon />
                </SocialLink>
              )}
              {user?.social?.twitter && (
                <SocialLink
                  href={getLinkWithURLScheme(user?.social?.twitter)}
                  target="_blank"
                >
                  <TwitterIcon />
                </SocialLink>
              )}
              {user?.social?.youtube && (
                <SocialLink
                  href={getLinkWithURLScheme(user?.social?.youtube)}
                  target="_blank"
                >
                  <YoutubeIcon />
                </SocialLink>
              )}
            </SocialBox>
            {data?.getPotentialUserPosition?.positions?.length > 0 && (
              <ForceMatchLink>
                <b>Forcematch link: </b>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_DEV_APP_URL}/landing/${match.position.publicID}?fmt=${data.getPotentialUserPosition.positions[0].signupToken}`}
                >{`${process.env.REACT_APP_DEV_APP_URL}/landing/${match.position.publicID}?fmt=${data.getPotentialUserPosition.positions[0].signupToken}`}</a>

                <CopyOutlined
                  style={{ marginLeft: "10px", cursor: "copy" }}
                  onClick={() =>
                    handleCopy(
                      `${process.env.REACT_APP_DEV_APP_URL}/landing/${match.position.publicID}?fmt=${data.getPotentialUserPosition.positions[0].signupToken}`
                    )
                  }
                />
              </ForceMatchLink>
            )}
          </>
        )}
      </DataContainer>
      {match && !isPrintPage && (
        <LabelContainer>
          <BadgeContainer>
            {match?.user?.flags?.length > 0
              ? removeDuplicateFlags(match.user.flags).map((type) => {
                  return (
                    <Badge
                      href={`/developers/${match.user.id}?tab=flags`}
                      tooltip={
                        FLAGS.find((item) => type === item.type)?.description
                      }
                      onClick={(e) => e.stopPropagation()}
                      icon={
                        <FlagIconSmall
                          colors={
                            FLAGS.find((item) => type === item.type).colors
                          }
                        />
                      }
                      backgroundColor="none"
                    />
                  );
                })
              : ""}
            {activeCompanyMatchCount > 0 ? (
              <Badge
                tooltip="Active process"
                icon={<ShuffleIcon />}
                href={`/developers/${match.user.id}?tab=matches&filters_state=COMPANY_ASSESSMENT,INTERVIEW,SENT_OFFER,HIRED&filters_position_state=CREATED,SUBMITTED,IN_PROGRESS`}
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              ""
            )}
          </BadgeContainer>
          <MatchStatusLabel match={match} />
        </LabelContainer>
      )}
    </ProfileContainer>
  );
}
