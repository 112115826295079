import React from "react";
import { Form, Select } from "antd";

export default function ProfessionInput({ data = [] }) {
  return (
    <Form.Item name="relatedProfession" label="Related Profession">
      <Select
        showSearch
        allowClear
        placeholder="Select Profession"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data.allProfession.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item?.title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
