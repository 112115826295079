import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Layout } from "antd";
import styled from "styled-components";
import { ArrowIcon } from "../../Icons";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  padding: 0 0 0 32px !important;
  margin-left: -24px;
  margin-bottom: 29px;
  background: white;
  border-bottom: solid #c7c7c7 1px;
  min-height: 117px;
  height: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 15px;
`;

const LogoContainer = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
`;

const HeaderTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  padding: 0 !important;
  margin-bottom: 0px;
`;

const ActionComponentContainer = styled.div`
  margin-left: auto;
  order: 2;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0px;
`;

const StyledBreadCrumbs = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const ChildContainer = styled.div`
  margin-top: -26px;
`;

const BreadCrumbLink = styled(Link)`
  color: unset;
`;

const BreadCrumbs = ({ items }) => {
  const fullBreadCrumbs = [
    {
      title: "TG Recruiter",
      link: "/",
    },
    ...items,
  ];

  const isMiddle = (index) => {
    if (fullBreadCrumbs.length === 1) {
      return false;
    }

    if (fullBreadCrumbs.length - 1 === index) {
      return false;
    }

    return true;
  };

  return (
    <StyledBreadCrumbs>
      {fullBreadCrumbs.map((item, index) => (
        <BreadCrumbLink to={{ pathname: item.link }} key={index}>
          {item.title}
          {isMiddle(index) && (
            <span style={{ marginLeft: "5px", marginRight: "5px" }}>
              <ArrowIcon />
            </span>
          )}
        </BreadCrumbLink>
      ))}
    </StyledBreadCrumbs>
  );
};

const getPageTitle = (breadCrumbs) => {
  if (breadCrumbs.length === 0) {
    return "Home";
  }

  return breadCrumbs[breadCrumbs.length - 1].title;
};

export default function TgHeader({
  title,
  logo,
  logoURL,
  breadCrumbs = [],
  actionComponent,
  children,
  showBreadCrumbs = true,
  showHeaderContainer = true,
  ...props
}) {
  const history = useHistory();

  const handleLogoClick = () => {
    history.push(logoURL);
  };

  return (
    <>
      <StyledHeader {...props}>
        {showBreadCrumbs && <BreadCrumbs items={breadCrumbs} />}
        {showHeaderContainer && (
          <HeaderContainer>
            {logo && <LogoContainer src={logo} onClick={handleLogoClick} />}
            {!props.notitle && (
              <HeaderTitle>{title || getPageTitle(breadCrumbs)}</HeaderTitle>
            )}
            <ActionComponentContainer>
              {actionComponent}
            </ActionComponentContainer>
          </HeaderContainer>
        )}
        <ChildContainer>{children}</ChildContainer>
      </StyledHeader>
    </>
  );
}
