import React, { useState } from "react";
import styled from "styled-components";
import { SelectField } from "../../../Form/SelectField";
import FormScaffold from "../../../Form/FormScaffold";
import { RowBlock, ColumnBlock } from "../../../Form/BlockUi";
import { AddGroupIcon, DeleteIcon } from "../../../Icons";
import UploadFile from "./UploadFile";
import { getUnsignedUrl } from "../../../../helper";

const fileLanguageOptions = [
  { label: "English", value: "en-US" },
  { label: "Turkish", value: "tr-TR" },
];

const GroupHeader = styled.p`
  font-family: "Inter";
  font-size: 14px;
  font-weight: bold;
  align-self: start;
  margin: 0;
`;

const GroupContainer = styled.div`
  border: 1px solid #64b7dc;
  box-sizing: border-box;
  border-radius: 5px;
  width: 500px;
  height: 100%;
  margin: 10px 0px;
  cursor: auto;
`;

const AddGroupButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #64b7dc;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
`;

const Group = ({ file, handleUpdate, groupIndex }) => {
  return (
    <GroupContainer>
      <>
        <RowBlock key={file._id} flexWrap="nowrap" align="baseline" my={2}>
          <ColumnBlock width="55%" px={2}>
            <FormScaffold
              label="Report"
              explanation="only pdf"
              options={{ showStatus: false }}
            >
              <UploadFile
                style={{ padding: "50px" }}
                initialFile={file?.fileURL}
                acceptTypes=".pdf"
                label="Upload document"
                handleFile={(change) =>
                  handleUpdate(change, "fileURL", groupIndex)
                }
              />
            </FormScaffold>
          </ColumnBlock>

          <ColumnBlock width="45%" px={2}>
            <FormScaffold
              label="Report Language"
              explanation="choose report language"
              options={{ showStatus: false }}
            >
              <SelectField
                defaultValue={{ label: file.fileLanguage }}
                options={fileLanguageOptions}
                isSearchable={false}
                onChange={(change) =>
                  handleUpdate(change, "fileLanguage", groupIndex)
                }
              />
            </FormScaffold>
          </ColumnBlock>
        </RowBlock>
      </>
    </GroupContainer>
  );
};

export default function FileGroup({ initialValues = [], update }) {
  const [fileState, setFileState] = useState(initialValues.files || []);

  const handleUpdate = (changes, key, idx) => {
    const initialValues = JSON.parse(JSON.stringify(fileState));

    const newState = initialValues.map((item, index) => {
      if (index === idx && key === "fileLanguage") {
        return { ...item, [key]: changes.value };
      }
      if (index === idx && key === "fileURL") {
        return { ...item, [key]: getUnsignedUrl(changes) };
      }
      return { ...item };
    });

    setFileState(newState);
    if (newState) {
      update(newState);
    }
  };
  const addGroup = () => {
    const currentState = [...fileState];
    currentState.push({ fileURL: null, fileLanguage: null });
    setFileState(currentState);
  };

  const groupDelete = (index) => {
    const currentState = [...fileState];
    currentState.splice(index, 1);
    setFileState(currentState);
    update(currentState);
  };

  const generateKey = (index) => {
    return `${index}_${Math.random()}`;
  };

  return (
    <>
      <RowBlock justify="start">
        <GroupHeader>Report</GroupHeader>
      </RowBlock>
      {fileState &&
        fileState.map((file, index) => {
          return (
            <>
              <div
                key={generateKey(index)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Group
                  key={generateKey(index)}
                  file={file}
                  handleUpdate={handleUpdate}
                  groupIndex={index}
                />
                <div
                  onClick={() => groupDelete(index)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                  <DeleteIcon color="#ff9898" />
                </div>
              </div>
            </>
          );
        })}
      <RowBlock mb={3} mt={2} style={{ justifyContent: "left" }}>
        <AddGroupButton type="button" onClick={addGroup}>
          <AddGroupIcon />
        </AddGroupButton>
      </RowBlock>
    </>
  );
}
