export const getQueries = (values) => {
  let sort;
  if (values.sort) {
    sort = JSON.parse(values.sort);
  }

  return {
    search: values.title || "",
    page: parseInt(values.page, 10) || 1,
    limit: parseInt(values.limit, 10) || 10,
    sort,
  };
};
