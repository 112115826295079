import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { RightArrow } from "../../../Icons";
import {
  Description,
  SecondaryDescription,
  Row,
  StatusCard,
  DeveloperCard,
  DeveloperName,
  DeveloperRole,
} from "./style";

export default function StatusChangeModal({
  onClose: closeModal,
  onAccept: acceptChange,
  user,
}) {
  const handleAcceptChanges = async () => {
    await acceptChange();
    closeModal();
  };
  return (
    <Modal handleClose={closeModal}>
      <ModalHeader>
        <Description>
          Are you sure you want to update the status of this match?
        </Description>
        <SecondaryDescription>
          The company will be able to see this match.
        </SecondaryDescription>
      </ModalHeader>
      <ModalBody>
        <Row style={{ marginTop: "0px" }}>
          <DeveloperCard>
            <Row style={{ justifyContent: "space-between", marginTop: 0 }}>
              <DeveloperName>{user.name}</DeveloperName>
            </Row>
            <Row style={{ display: "block", marginTop: 0 }}>
              <DeveloperRole style={{ justifyContent: "none" }}>
                {user.role}
              </DeveloperRole>
            </Row>
          </DeveloperCard>
          <RightArrow />
          <StatusCard>Company Assessment</StatusCard>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleAcceptChanges} size="sm">
          Yes
        </Button>
        <Button variant="secondary" outline size="sm" onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
