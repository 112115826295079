import React from "react";
import styled from "styled-components";
import { ActionMenus, ActionMenu } from "tg-design";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
`;

const toFullMapper = (item) => {
  return {
    label: item.label,
    value: item.value,
    key: item.value,
  };
};

const quickSelectGroups = [
  {
    universityBranches: [
      {
        label: "Bilgisayar Mühendisliği",
        value: "606058e13a3f1fdc76dd38fc",
      },
      {
        label: "Bilgisayar Öğretmenliği",
        value: "606058e13a3f1fdc76dd3942",
      },
      {
        label: "Bilgisayar Ve Öğretim Teknolojileri Öğretmenliği",
        value: "606058e13a3f1fdc76dd3945",
      },
      {
        label: "Bilgisayar Ve Kontrol Öğretmenliği",
        value: "606058e13a3f1fdc76dd3944",
      },
      {
        label: "Bilgisayar Sistemleri Öğretmenliği",
        value: "606058e13a3f1fdc76dd3943",
      },
      {
        label: "Yazılım Mühendisliği",
        value: "606058e13a3f1fdc76dd38fe",
      },
      {
        label: "Bilişim Sistemleri Mühendisliği",
        value: "606058e13a3f1fdc76dd38fd",
      },
    ].map(toFullMapper),
    title: "Computer Departments",
  },
  {
    universityBranches: [
      {
        label: "Bilgisayar Mühendisliği",
        value: "606058e13a3f1fdc76dd38fc",
      },
      {
        label: "İşletme Mühendisliği",
        value: "606058e13a3f1fdc76dd390c",
      },
      {
        label: "Maden Mühendisliği",
        value: "606058e13a3f1fdc76dd3939",
      },
      {
        label: "Otomotiv Mühendisliği",
        value: "606058e13a3f1fdc76dd3940",
      },
      {
        label: "Bilişim Sistemleri Mühendisliği",
        value: "606058e13a3f1fdc76dd38fd",
      },
      {
        label: "Uçak Mühendisliği",
        value: "606058e13a3f1fdc76dd3955",
      },
      {
        label: "Çevre Mühendisliği",
        value: "606058e13a3f1fdc76dd3904",
      },
      {
        label: "Telekomünikasyon Mühendisliği",
        value: "606058e13a3f1fdc76dd390a",
      },
      {
        label: "Sistem Mühendisliği",
        value: "606058e13a3f1fdc76dd390d",
      },
      {
        label: "Jeofizik Mühendisliği",
        value: "606058e13a3f1fdc76dd3931",
      },
      {
        label: "Ziraat Mühendisliği",
        value: "606058e13a3f1fdc76dd395a",
      },
      {
        label: "Elektronik Mühendisliği",
        value: "606058e13a3f1fdc76dd3909",
      },
      {
        label: "Optik Ve Akustik Mühendisliği",
        value: "606058e13a3f1fdc76dd3913",
      },
      {
        label: "Mekatronik Mühendisliği",
        value: "606058e13a3f1fdc76dd392a",
      },
      {
        label: "Nükleer Enerji Mühendisliği",
        value: "606058e13a3f1fdc76dd3947",
      },
      {
        label: "Petrol Ve Doğalgaz Mühendisliği",
        value: "606058e13a3f1fdc76dd3948",
      },
      {
        label: "Kimya Mühendisliği",
        value: "606058e13a3f1fdc76dd3937",
      },
      {
        label: "Su Ürünleri Mühendisliği",
        value: "606058e13a3f1fdc76dd394e",
      },
      {
        label: "Kontrol Mühendisliği",
        value: "606058e13a3f1fdc76dd3928",
      },
      {
        label: "Makine Mühendisliği",
        value: "606058e13a3f1fdc76dd393a",
      },
    ].map(toFullMapper),
    title: "Engineering Departments",
  },
];

export default function QuickBranchSelection({ selectGroup }) {
  return (
    <Container>
      <ActionMenus icon="Quick Sel." menuStyles={{ top: 30 }}>
        {quickSelectGroups.map((item) => (
          <ActionMenu
            key={item.title}
            onClick={() => selectGroup(item.universityBranches)}
          >
            <span style={{ color: "#64B7DC" }}>{item.title}</span>
          </ActionMenu>
        ))}
      </ActionMenus>
    </Container>
  );
}
