import React from "react";
import { Button } from "tg-design";

export const ConfirmationPage = ({ user, handleConfirmation }) => {
  const rawUrl = user.social?.linkedin?.split("?").shift();
  return (
    <div>
      <p>User’s linkedin profile looks like this:</p>
      <p>{rawUrl}</p>
      <Button onClick={handleConfirmation}>Continue</Button>
    </div>
  );
};
