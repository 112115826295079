import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "tg-design";
import { DropDownIcon } from "../../Icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const DropDownToggleButton = styled.button`
  display: inline-flex;
  cursor: pointer;
  border: 1px solid;
  padding: 10px;
  margin-left: 10px;
  border-radius: 4px;
  background: white;
  color: #ff4d4f; /* same as button color */
`;

const DropDownMenu = styled.ul`
  list-style-type: none;
  padding: 5px 0;
  box-shadow: 0 5px 10px 0 #aaaaaa;
  position: absolute;
  margin-top: 55px;
  z-index: 999;
  background: white;
  min-width: 155px;
`;

export const DropDownItem = styled.li`
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 10px;
  margin: 0 10px;
  color: #ff4d4f; /* same as button color */
  pointer-events: ${(props) => props.disabled && "none"};
  &:hover {
    background: #f7f7f7;
    cursor: pointer;
  }
`;

export default function DropDownButton({
  menus,
  children,
  title,
  handleClick,
  handleMenuItemClick,
  ...props
}) {
  const [toggleButton, setToggleButton] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleItemClick = (menu) => {
    setDisabled(true);
    setToggleButton(!toggleButton);
    handleMenuItemClick(menu);
  };

  return (
    <Container>
      <ButtonContainer>
        <Button
          variant="danger"
          outline
          {...props}
          loading={disabled}
          onClick={() => handleClick()}
        >
          {title}
        </Button>
        <DropDownToggleButton
          type="button"
          onClick={() => setToggleButton(!toggleButton)}
        >
          <DropDownIcon />
        </DropDownToggleButton>
      </ButtonContainer>
      {toggleButton && (
        <DropDownMenu>
          {menus.map((menu, index) => {
            return (
              <DropDownItem
                key={index}
                disabled={disabled}
                onClick={() => handleItemClick(menu)}
              >
                {menu.title}
              </DropDownItem>
            );
          })}
        </DropDownMenu>
      )}
    </Container>
  );
}
