import React from "react";
import { Spin } from "antd";

export default function TgKanbanColumnFooter({
  isLoading = false,
  isEnded = false,
  count = 0,
}) {
  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      )}
      {isEnded && count > 0 && (
        <div style={{ textAlign: "center", fontSize: "12px" }}>
          {count} results shown.
        </div>
      )}
    </>
  );
}
