import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function RichTextEditor({
  toolbar = [
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "video"],
    ["clean"],
  ],
  formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "video",
  ],
  ...props
}) {
  RichTextEditor.modules = {
    toolbar,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  RichTextEditor.formats = formats;

  return (
    <ReactQuill
      theme="snow"
      className="rich-text"
      modules={RichTextEditor.modules}
      formats={RichTextEditor.formats}
      {...props}
    />
  );
}
