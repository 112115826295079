import React from "react";
import styled from "styled-components";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../../../../Icons";

export const GroupTitle = styled.h3`
  margin-top: 10px;
  font-weight: 600;
`;

export const ColumnTitle = styled.h5`
  color: #979797;
  margin-bottom: 10px;
`;

export const SectionTitle = styled.h5`
  font-weight: 700;
`;

export const OriginalContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "center"};
  padding: 10px;
  background: rgba(100, 183, 220, 0.1);
  margin-bottom: 10px;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
`;

export const LinkedinSectionContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  background: ${(props) => (props.isChecked ? " #f1f1f1" : "white")};
  cursor: pointer;
`;

export const LinkedinProfilePhoto = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  background: ${(props) => (props.isChecked ? " #f1f1f1" : "white")};
  cursor: pointer;
`;

export const CurrentSectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PrimaryTitle = styled.p`
  font-weight: 700;
`;

export const SecondaryTitle = styled.p`
  font-weight: 600;
`;

export const DescriptionText = styled.p`
  font-weight: 500;
`;

export const TagText = styled.span`
  border: 1px solid rgba(199, 199, 199, 1);
  border-radius: 4px;
  padding: 10px;
  background: ${(props) => (props.isChecked ? " #D6F3E2" : "white")};
  cursor: pointer;
`;

export const DateText = styled.p`
  font-weight: 400;
  font-style: italic;
`;

const SelectAllButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid rgba(199, 199, 199, 1);
  padding: 10px;
  margin: 10px 0 20px 0;
  gap: 10px;
  cursor: pointer;
`;

export const SelectAllButton = ({ handleSelectAll, isChecked }) => {
  return (
    <SelectAllButtonContainer onClick={handleSelectAll}>
      <span>Select All</span>
      {isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
    </SelectAllButtonContainer>
  );
};
