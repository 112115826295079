import React, { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import { useQuery } from "@apollo/client";
import { LANGUAGE_LEVEL_TYPES } from "../../../../../../constants";
import {
  GroupTitle,
  ColumnTitle,
  CurrentSectionContainer,
  LinkedinSectionContainer,
  PrimaryTitle,
  SecondaryTitle,
  OriginalContainer,
  SectionTitle,
} from "./styles";

import { RowBlock, ColumnBlock } from "../../../../../Form/BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../../../../Icons";
import { ALL_LANGUAGES } from "../../../../../../queries/education";
import { AsyncSelectField } from "../../../../../Form/SelectField";
import { debounce } from "../../../../../../helper";

const getLevelType = (value) => {
  const language = LANGUAGE_LEVEL_TYPES.find((level) => level.value === value);
  if (language) {
    return language.label;
  }
};

export const LanguageTab = ({ handleStateChange, linkedinData, user }) => {
  const [state, setState] = useState([]);

  const { refetch: languageRefetch } = useQuery(ALL_LANGUAGES, {
    skip: false,
  });

  const handleLanguageSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await languageRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _languages = data.allLanguages.languages.map((language) => {
          return {
            value: language?.id,
            label: language?.label,
          };
        });
        const sortedlanguages = _languages.sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        return callback(sortedlanguages);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleOptionChange = (
    selectedOption,
    alternativeText,
    changedObjectIndex
  ) => {
    const newState = state.map((languageItem, index) => {
      if (index === changedObjectIndex) {
        if (languageItem.suggestions.length === 0) {
          languageItem.suggestions.push({
            _id: selectedOption.value,
            label: selectedOption.label,
            alternativeTexts: [alternativeText],
          });
        } else {
          languageItem.suggestions[0].label = selectedOption.label;
          languageItem.suggestions[0]._id = selectedOption.value;

          if (
            languageItem.suggestions[0].alternativeTexts.includes(
              alternativeText
            ) === false
          ) {
            languageItem.suggestions[0].alternativeTexts.unshift(
              alternativeText
            );
          }
        }

        return languageItem;
      }

      return languageItem;
    });

    setState(newState);
  };

  useEffect(() => {
    const newState = linkedinData.languages.map((language) => {
      return language;
    });
    setState(newState);
    // eslint-disable-next-line
  }, []);

  const toggleCheck = (idx) => {
    const newState = state.slice();

    const isSelectFieldEmpty =
      !linkedinData.languages[idx].suggestions[0]?.label;

    if (!isSelectFieldEmpty) {
      newState[idx].isChecked = !newState[idx].isChecked;
      const changes = newState.filter((language) => language.isChecked);

      setState(newState);
      handleStateChange("languages", changes);
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Languages</GroupTitle>

      <RowBlock>
        <ColumnBlock
          width="50%"
          justifyContent="flex-start"
          margin="0 0 100px 0"
        >
          <ColumnTitle>Current</ColumnTitle>
          {user.languages.length === 0 ? (
            <span>No language found.</span>
          ) : (
            user.languages.map((item, index) => {
              return (
                <CurrentSectionContainer
                  style={{ marginBottom: "20px" }}
                  key={index}
                >
                  <div>
                    <PrimaryTitle>{item?.language?.label}</PrimaryTitle>
                    <SecondaryTitle>{getLevelType(item.level)}</SecondaryTitle>
                  </div>
                </CurrentSectionContainer>
              );
            })
          )}
        </ColumnBlock>
        <ColumnBlock
          width="50%"
          justifyContent="flex-start"
          margin="0 0 100px 0"
        >
          <ColumnTitle>From Linkedin</ColumnTitle>

          {linkedinData.languages.every(
            (item) => item.suggestions.length === 0
          ) ? (
            <div>
              <span>No language found.</span>
            </div>
          ) : (
            linkedinData.languages.map((item, idx) => {
              return (
                <div key={idx}>
                  <SectionTitle>Original:</SectionTitle>
                  <OriginalContainer column alignItems="left">
                    <PrimaryTitle>{item.language}</PrimaryTitle>
                    <SecondaryTitle>
                      {getLevelType(item.suggestions[0]?.level)}
                    </SecondaryTitle>
                  </OriginalContainer>
                  <SectionTitle>TG Suggestion</SectionTitle>
                  <LinkedinSectionContainer
                    isChecked={state[idx]?.isChecked}
                    style={{ marginBottom: "20px" }}
                  >
                    <div style={{ flex: "1" }}>
                      {item?.suggestions?.length === 0 && (
                        <p>
                          No match found, please select corresponding language
                          below
                        </p>
                      )}
                      <AsyncSelectField
                        isSearchable
                        width="75%"
                        loadOptions={handleLanguageSearch}
                        onChange={(selectedOption) =>
                          handleOptionChange(selectedOption, item.language, idx)
                        }
                        value={
                          item.suggestions[0]?.label
                            ? { label: item.suggestions[0]?.label }
                            : null
                        }
                      />
                    </div>
                    <div
                      onClick={() => {
                        toggleCheck(idx);
                      }}
                    >
                      {state[idx]?.isChecked ? (
                        <CheckedBoxIcon />
                      ) : (
                        <UncheckedBoxIcon />
                      )}
                    </div>
                  </LinkedinSectionContainer>
                </div>
              );
            })
          )}
        </ColumnBlock>
      </RowBlock>
    </div>
  );
};
