import React, { useEffect, useState } from "react";
import { Statistic } from "antd";
import { Link } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { handleHead } from "../../helper";
import { TgTable } from "../tg-styles/Layouts";
import PartialError from "../ErrorPage/PartialError";

const GET_ALL_MATCHES = gql`
  query Matches($limit: Int, $page: Int, $search: String) {
    allMatch(limit: $limit, page: $page, search: $search) {
      matches {
        id
        rate
        user {
          id
          name
        }
        position {
          id
          title
          company {
            id
            name
          }
        }
      }
      currentPage
      totalPages
      totalCount
    }
  }
`;

const columns = [
  {
    title: "Detail",
    dataIndex: "id",
    render: (value) => <Link to={`/matches/${value}`}>Detail</Link>,
  },
  {
    title: "Developer",
    dataIndex: "user",
    render: (value) => (
      <Link to={`/developers/${value.id}`}>{value.name || "No name"}</Link>
    ),
  },
  {
    title: "Position",
    dataIndex: "position",
    render: (value) => (
      <Link to={`/positions/${value ? value.id : null}`}>
        {value ? value.title : "No name"}
      </Link>
    ),
  },
  {
    title: "Company",
    dataIndex: "position",
    render: (value) => (
      <Link to={`/companies/${value ? value.company.id : null}`}>
        {value ? value.company.name : "No name"}
      </Link>
    ),
  },
  {
    title: "Score",
    dataIndex: "rate",
    render: (value) => <span>{value}</span>,
  },
];

const limit = 10;

export default function Matches() {
  const [matchData, setMatchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [getAllMatches, { loading, data, error }] =
    useLazyQuery(GET_ALL_MATCHES);
  handleHead("match");

  useEffect(() => {
    // const params = getUrlParams();
    // const { search, filters } = getQueries(params);
    const fetch = async () => {
      getAllMatches({
        query: GET_ALL_MATCHES,
        variables: {
          limit,
          page: currentPage,
          //   search,
        },
      });
    };
    fetch();
  }, [currentPage, getAllMatches]);

  const onChange = async (pagination, filters, sorter, extra) => {
    if (extra.action === "paginate") {
      setCurrentPage(pagination.current);
    }
  };

  useEffect(() => {
    if (data && data.allMatch) {
      setMatchData(data.allMatch);
    }
  }, [data]);

  if (error) return <PartialError />;

  return (
    <div>
      {/* <SearchForm finishFilter={finishFilter} handleReset={handleReset} /> */}
      <br />
      <Statistic
        title="Total Matches"
        value={matchData && matchData.totalCount}
      />
      <TgTable
        loading={loading}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={matchData && matchData.matches}
        onChange={onChange}
        pagination={{
          pageSize: limit,
          total: matchData && matchData.totalCount,
        }}
      />
    </div>
  );
}
