import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ThreePointIcon } from "../../Icons";

const Container = styled.div`
  flex-grow: 4;
  position: relative;
  margin-top: -6px;
`;

const Button = styled.button`
  background: #ebebeb;
  border-radius: 50%;
  border: none;
  width: 30px;
  height: 30px;
  padding: 7px;
  cursor: pointer;
  position: absolute;
  right: 0px;
`;

const Menus = styled.div`
  position: absolute;
  right: 0px;
  top: 35px;
  width: auto;
  background: white;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 4px 0px;
  z-index: 999;
`;

const StyledMenu = styled.button`
  background: transparent;
  color: #374151;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  padding: 8px 16px;
  display: flex;
  width: 100%;

  &:hover {
    color: #333;
    background: #f8f8f8;
  }
`;

const MenuIconBox = styled.div`
  & svg {
    margin-right: 8px;
    color: #9ca3af;
    min-width: 24px;
    text-align: left;
  }
`;

export const MenuAction = ({ title, icon, handleClose, ...props }) => {
  return (
    <StyledMenu
      type="button"
      className="menu-action"
      {...props}
      onClick={handleClose}
    >
      <MenuIconBox>{icon}</MenuIconBox>
      {title}
    </StyledMenu>
  );
};

export default function TgKanbanColumnActions({ children }) {
  const menusRef = useRef(null);
  const [menus, setMenus] = useState(false);

  const handleToggle = () => {
    setMenus(!menus);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenus(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (child.props.children) {
      return React.Children.map(child.props.children, (subchild) => {
        return React.cloneElement(subchild, {
          handleClose: (event) => {
            setMenus(false);
            subchild.props.onClick(event);
          },
        });
      });
    }

    return React.cloneElement(child, {
      handleClose: (event) => {
        setMenus(false);
        child.props.onClick(event);
      },
    });
  });

  useOutsideAlerter(menusRef);

  return (
    <Container>
      <Button type="button" onClick={handleToggle}>
        <ThreePointIcon />
      </Button>
      {menus && <Menus ref={menusRef}>{childrenWithProps}</Menus>}
    </Container>
  );
}
