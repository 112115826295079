import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxField } from "../Form/CheckboxField";
import { setPositionFilter } from "../../store/slices/positionFilter";
import { POSITION_FILTERS } from "./constants";

const FilterTitle = styled.span`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #444444;
`;
const FilterTitleBorder = styled.div`
  margin: 5px 0 6px 0;
  border: 1px solid #444444;
  width: 100%;
`;

const PositionFilterOptions = () => {
  const { checkboxFilters } = useSelector((state) => state.positionFilter);
  const dispatch = useDispatch();

  const handleCheckbox = (e) => {
    const { checked, name } = e.target;
    dispatch(setPositionFilter({ checked, name }));
  };

  return (
    <div>
      <FilterTitle>Show:</FilterTitle>
      <FilterTitleBorder />
      {POSITION_FILTERS.checkbox.map((c) => {
        return (
          <CheckboxField
            key={c.name}
            name={c.name}
            label={c.label}
            onChange={handleCheckbox}
            checked={checkboxFilters[c.name]}
            value={checkboxFilters[c.name]}
            containerStyles={{
              fontSize: "12px",
              color: "#444444",
              fontWeight: 500,
              lineHeight: 1.6,
              marginBottom: 8,
            }}
            checkboxStyles={{
              height: "12px",
              width: "12px",
              position: "relative",
              top: "3px",
              marginRight: "8px",
            }}
            iconStyles={{
              position: "relative",
              bottom: 2,
            }}
          >
            {c.label}
          </CheckboxField>
        );
      })}
    </div>
  );
};

export default PositionFilterOptions;
