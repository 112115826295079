import styled from "styled-components";
import { TgKanbanColumn } from ".";

export default styled(TgKanbanColumn)`
  background: #ebebeb;
  border: 2px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 230px;
  width: 230px;
  height: calc(100% - 20px);
  padding: 15px 10px;
  margin-right: 0px;
`;
