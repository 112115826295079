import React from "react";
import { useHistory } from "react-router-dom";
import { TgHeaderTab, TgHeaderTabs } from "../../tg-styles/TgHeaderTabs";
import { TgHeader } from "../../tg-styles/Layouts";

export default function DeleteRequestsHeader({ name }) {
  const history = useHistory();

  return (
    <TgHeader
      breadCrumbs={
        name === "active"
          ? [
              { title: "Tools", link: "/tools" },
              {
                title: "Active Delete Requests",
                link: "/tools/active-delete-requests",
              },
            ]
          : name === "passive"
          ? [
              { title: "Tools", link: "/tools" },
              {
                title: "Passive Delete Requests",
                link: "/tools/passive-delete-requests",
              },
            ]
          : [
              { title: "Tools", link: "/tools" },
              { title: "Deleted Users", link: "/tools/deleted-users" },
            ]
      }
    >
      <TgHeaderTabs>
        <TgHeaderTab
          name="active"
          onClick={() => history.push("/tools/active-delete-requests")}
          classname={name === "active" && "active"}
        >
          Active Delete Requests
        </TgHeaderTab>
        <TgHeaderTab
          name="passive"
          onClick={() => history.push("/tools/passive-delete-requests")}
          classname={name === "passive" && "active"}
        >
          Passive Delete Requests
        </TgHeaderTab>
        <TgHeaderTab
          name="deleted"
          onClick={() => history.push("/tools/deleted-users")}
          classname={name === "deleted" && "active"}
        >
          Deleted Users
        </TgHeaderTab>
      </TgHeaderTabs>
    </TgHeader>
  );
}
