import { configureStore } from "@reduxjs/toolkit";
import questionReducer from "./slices/question";
import positionFilterReducer from "./slices/positionFilter";

export const store = configureStore({
  reducer: {
    question: questionReducer,
    positionFilter: positionFilterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.callback"],
        ignoredPaths: ["question.callback"],
      },
    }),
});
