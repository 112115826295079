import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonLink, Button } from "tg-design";
import { CREATE_UNIVERSITY, GET_ALL_UNIVERSITY } from "../../queries";
import UniversityEdit from "./Edit/index";
import ModalUniversities from "../Module/ModalUniversities";
import { getQueries } from "./getQueries";
import TitleSearch from "../Module/TitleSearch";
import { SORTERS } from "../../../../constants/index";
import { getUrlParams, setQueries } from "../../../../helper";
import EducationHeader from "../Module/header";
import {
  TgDrawer,
  TgTable,
  TgTotalInfo,
  TgButtonField,
} from "../../../tg-styles/Layouts";
import PartialError from "../../../ErrorPage/PartialError";

const countryFilter = [{ text: "Show in Turkey", value: "Turkey" }];

export default function UniversityTable() {
  const [drawerState, setdrawerState] = useState({ visible: false });
  const [selectedId, setSelectedId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [universityData, setUniversityData] = useState();
  const [updateQuery, setUpdateQuery] = useState(false);
  const [queryString, setQueryString] = useState("");
  const [tableKey, setTableKey] = useState(0);

  const DEFAULT_LIMIT = 10;

  const [getAllUniversity, { loading, data, error }] = useLazyQuery(
    GET_ALL_UNIVERSITY,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setUniversityData(response.allUniversity.universities);
      },
    }
  );

  const [createUniversities] = useMutation(CREATE_UNIVERSITY);

  const createUniversity = async (val) => {
    await createUniversities({ variables: { ...val } });
    if (queryString !== "") {
      setUpdateQuery(true);
    }
  };

  const showDrawer = (id) => {
    setSelectedId(id);
    setdrawerState({
      visible: true,
    });
  };

  const columns = [
    {
      title: "University",
      dataIndex: "name",
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <TitleSearch
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          placeHolder="Search Universities"
        />
      ),
      render: (value, record) => (
        <ButtonLink onClick={() => showDrawer(record.id)} color="#1890FF">
          {value}
        </ButtonLink>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "center",
      filters: countryFilter,
      filterMultiple: false,
      render: (text) => text,
    },
  ];

  const onClose = () => {
    setdrawerState({
      visible: false,
    });
    setUpdateQuery(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getDefaultPage = () => {
    const params = getUrlParams();
    const { page = 1 } = getQueries(params);
    return page;
  };

  const getDefaultLimit = () => {
    const params = getUrlParams();
    const { limit = DEFAULT_LIMIT } = getQueries(params);
    return limit;
  };

  const onChange = (pagination, filters, sorter) => {
    const sort = {
      [sorter.field]: SORTERS[sorter.order],
    };
    const params = getUrlParams();
    setQueryString(
      setQueries(
        Object.assign(params, {
          page: pagination.current,
          limit: pagination.pageSize,
          sort: JSON.stringify(sort),
          ...filters,
        })
      )
    );
  };

  const clearFilters = () => {
    const url = new URL(window.location);
    url.search = "";
    window.history.pushState({}, "", url);
    setQueryString("?");
    setTableKey(tableKey + 1);
  };

  useEffect(() => {
    const params = getUrlParams();
    const {
      search,
      filters,
      page = 1,
      limit = DEFAULT_LIMIT,
    } = getQueries(params);
    const fetch = async () => {
      getAllUniversity({
        variables: {
          limit,
          page,
          search,
          filters,
        },
      });
    };
    fetch();
    setUpdateQuery(false);
  }, [queryString, updateQuery, getAllUniversity]);

  if (error) return <PartialError />;

  return (
    <div>
      <EducationHeader name="universities" />
      {drawerState.visible && (
        <TgDrawer
          closable={false}
          onClose={onClose}
          visible={drawerState.visible}
        >
          <UniversityEdit id={selectedId} handleClose={onClose} />
        </TgDrawer>
      )}

      <div className="info-field">
        <TgTotalInfo
          count={data && data.allUniversity.totalCount}
          title="universities"
        />

        <TgButtonField>
          <ButtonLink
            onClick={() => clearFilters()}
            style={{ marginRight: "15px" }}
          >
            Clear Filters
          </ButtonLink>
          <Button onClick={() => showModal()}>Create New University</Button>
        </TgButtonField>
      </div>

      <TgTable
        key={tableKey}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={universityData || []}
        onChange={onChange}
        loading={loading}
        pagination={{
          pageSize: getDefaultLimit(),
          total: data && data.allUniversity.totalCount,
          defaultCurrent: getDefaultPage(),
        }}
      />
      <ModalUniversities
        visible={isModalVisible}
        onCancel={handleCancel}
        handleOk={handleOk}
        createUniversity={createUniversity}
      />
    </div>
  );
}
