import { gql } from "@apollo/client";

const CUSTOM_FLOW_FRAGMENT = gql`
  fragment CustomFlowFragment on CustomFlowType {
    uuid
    title
    index
    isVisibleToCompany
    isVisibleToDeveloper
  }
`;

export const POSITION_CUSTOM_FLOWS = gql`
  ${CUSTOM_FLOW_FRAGMENT}
  fragment CustomFlowsFragment on Position {
    customFlows {
      EXTERNAL_SOURCING {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      CREATED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      SENT {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      INTERESTED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      TG_ASSESSMENT {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      COMPANY_ASSESSMENT {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      INTERVIEW {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      SENT_OFFER {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      HIRED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      PASSED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      FAILED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
      CANCELED {
        ... on CustomFlowType {
          ...CustomFlowFragment
        }
      }
    }
  }
`;

export const GET_ALL_POSITION = gql`
  query allPosition(
    $limit: Int
    $search: String
    $page: Int
    $sort: positionSortInputType
    $filters: positionFilterInputType
  ) {
    allPosition(
      limit: $limit
      search: $search
      page: $page
      sort: $sort
      filters: $filters
    ) {
      positions {
        id
        title
        state
        title
        description
        isTest
        isExternal
        firstBatchCreated
        firstVerifiedTalent
        verificationDuration
        totalReachedOut
        totalShowedInterest
        prevetted
        verified
        activelyInTheProcess
        interestedReached
        verifiedPrevetted
        createdAt
        updatedAt
        assignee {
          name
        }
        company {
          id
          name
        }
        matches {
          matchCounter {
            CREATED
            INTERESTED
          }
        }
      }
      currentPage
      totalCount
      totalPages
    }
  }
`;

export const GET_REJECT_REASONS = gql`
  {
    allRejectReason {
      id
      label
    }
  }
`;

export const GET_DEVELOPER_REJECT_REASONS = gql`
  {
    allRejectReason(type: 0) {
      id
      label
    }
  }
`;

export const CREATE_REJECT_REASON = gql`
  mutation createRejectReason(
    $label: String!
    $type: Int!
    $description: String
    $creator: String
  ) {
    createRejectReason(
      label: $label
      type: $type
      description: $description
      creator: $creator
    ) {
      id
    }
  }
`;

export const UPDATE_PASS_REASON = gql`
  mutation updatePassReason($id: ID!, $rejectReason: [ID]) {
    updatePassReason(id: $id, rejectReason: $rejectReason) {
      id
    }
  }
`;

export const GET_PUBLIC_POSITION_ID = gql`
  query Position($id: ID) {
    position(id: $id) {
      publicID
    }
  }
`;

export const GET_POSITION = gql`
  ${POSITION_CUSTOM_FLOWS}
  query Position($id: ID) {
    position(id: $id) {
      id
      title
      state
      externalSourcingMessage
      isExternal
      greenhouseJobId
      role {
        id
        title
      }
      title
      description
      processDurationInDays
      recruitmentStages
      createdAt
      isTest
      externalPositionApplyLink
      ... on Position {
        ...CustomFlowsFragment
      }
      assignee {
        id
        name
      }
      history {
        id
        recruiter {
          name
        }
        position {
          title
        }
        current
        previous
        createdAt
      }
      criteria {
        budget {
          min
          max
          period
          income
          currency
          isVisibleToUser
        }
        stockOption {
          isProvided
          isVisibleToUser
          min
          max
          isRange
          certainValue
        }
        totalExperience {
          min
          max
        }
        benefits {
          expected {
            id
            label
          }
        }
        technologies {
          expected {
            _id
            title
            experience
          }
          otherExpected {
            id
            title
          }
          plus {
            id
            title
          }
        }
        education {
          type
          universities {
            id
            name
          }
          branches {
            id
            label
          }
        }
        languages {
          language {
            id
            label
          }
          languageLevel
        }
        positionLocation {
          expected {
            id
            title
            istanbulRegion
            place {
              id
              capital
              city
              country
            }
          }
          remote
          relocation
          visaSupport
        }
        age {
          min
          max
        }
      }
      content {
        loomVideoURL
        video
      }
      externalMatchReports {
        interested
        notInterested
        interviewed
        notified
        interviewedButNotSignedUp
      }
      company {
        id
        name
        logo
        locations {
          id
          title
          location {
            coordinates
          }
          place {
            city
          }
          fullAddress
          headquarter
        }
      }
      highlights {
        value
        type
      }
    }
  }
`;

export const GET_POSITION_MATCHES = gql`
  query Position(
    $id: ID
    $filters: matchFilterInputType
    $page: Int
    $limit: Int
    $sort: matchSortInputType
  ) {
    position(id: $id) {
      id
      state
      matches(filters: $filters, page: $page, limit: $limit, sort: $sort) {
        matchList {
          id
          state
          createdAt
          updatedAt
          rate
          conversation {
            id
            creator {
              name
              avatarURL
            }
            creatorType
            note
            type
            updatedAt
            createdAt
          }
          user {
            id
            name
            role {
              id
              title
            }
            experience
            birthyear
            social {
              linkedin
            }
            matches {
              rate
              conversation {
                id
                creator {
                  name
                  avatarURL
                }
                creatorType
                note
                type
                updatedAt
                deletedAt
                createdAt
              }
              createdAt
            }
          }
        }
        totalPages
        currentPage
        totalCount
        matchCounter {
          CREATED
          SENT
          INTERESTED
          PASSED
          FAILED
          TG_ASSESSMENT
          CANCELED
          DIRECTED
        }
      }
    }
  }
`;

export const MATCH_STATE_CHANGE = gql`
  mutation MatchStateChange(
    $id: ID!
    $state: String!
    $substate: String
    $failReasons: FailReasonsInputType
    $otherReason: String
  ) {
    matchStateChange(
      id: $id
      state: $state
      substate: $substate
      failReasons: $failReasons
      otherReason: $otherReason
    ) {
      id
      state
    }
  }
`;

export const CREATE_BULK_MATCHES = gql`
  mutation CreateBulkMatchDatas(
    $batchID: ID!
    $positionID: ID!
    $createdCount: Int
    $sentCount: Int
    $interestedCount: Int
    $tgAssessmentCount: Int
  ) {
    createBulkMatchDatas(
      batchID: $batchID
      positionID: $positionID
      createdCount: $createdCount
      sentCount: $sentCount
      interestedCount: $interestedCount
      tgAssessmentCount: $tgAssessmentCount
    )
  }
`;

export const ADD_CUSTOM_FLOW = gql`
  ${POSITION_CUSTOM_FLOWS}
  mutation addCustomFlow($positionId: ID!, $state: String!, $title: String) {
    addCustomFlow(positionId: $positionId, state: $state, title: $title) {
      id
      ... on Position {
        ...CustomFlowsFragment
      }
    }
  }
`;

export const REMOVE_CUSTOM_FLOW = gql`
  ${POSITION_CUSTOM_FLOWS}
  mutation removeCustomFlow(
    $positionId: ID!
    $state: String!
    $substate: String!
  ) {
    removeCustomFlow(
      positionId: $positionId
      state: $state
      substate: $substate
    ) {
      id
      ... on Position {
        ...CustomFlowsFragment
      }
    }
  }
`;

export const UPDATE_CUSTOM_FLOW = gql`
  mutation updateCustomFlow(
    $positionId: ID!
    $state: String!
    $substate: String!
    $title: String!
    $isVisibleToCompany: Boolean!
    $isVisibleToDeveloper: Boolean!
  ) {
    updateCustomFlow(
      positionId: $positionId
      state: $state
      substate: $substate
      title: $title
      isVisibleToCompany: $isVisibleToCompany
      isVisibleToDeveloper: $isVisibleToDeveloper
    ) {
      id
    }
  }
`;

const POSITION_VERSION_FRAGMENT = gql`
  fragment PositionVersionFragment on Position {
    role {
      title
    }
    description
    state
    title
    assignee {
      name
    }
  }
`;

const CRITERIA_VERSION_FRAGMENT = gql`
  fragment CriteriaVersionFragmentForPosition on PositionCriteriaType {
    totalExperience {
      min
      max
    }
    benefits {
      expected {
        label
      }
    }
    budget {
      min
      max
      currency
    }
    age {
      min
      max
    }
    education {
      type
      universities {
        id
        name
      }
      branches {
        id
        label
      }
    }
    languages {
      language {
        id
        label
      }
      languageLevel
    }
    positionLocation {
      expected {
        id
        title
        place {
          city
        }
      }
      remote
    }
    technologies {
      expected {
        _id
        title
        experience
      }
      plus {
        title
      }
      otherExpected {
        title
      }
    }
  }
`;

export const POSITION_VERSION = gql`
  ${POSITION_VERSION_FRAGMENT}
  ${CRITERIA_VERSION_FRAGMENT}
  query PositionVersion($id: ID) {
    position(id: $id) {
      id
      changes {
        timestamp
        diff {
          ... on Position {
            ...PositionVersionFragment
          }
        }
        current {
          ... on Position {
            ...PositionVersionFragment
          }
        }
        ownerType
        owner {
          name
          avatarURL
        }
      }
      criteria {
        changes {
          timestamp
          diff {
            ... on PositionCriteriaType {
              ...CriteriaVersionFragmentForPosition
            }
          }
          current {
            ... on PositionCriteriaType {
              ...CriteriaVersionFragmentForPosition
            }
          }
          ownerType
          owner {
            name
            avatarURL
          }
        }
      }
    }
  }
`;

export const GET_ROLES = gql`
  {
    allRole(limit: 0) {
      roles {
        id
        title
      }
    }
  }
`;

export const GET_ALL_COMPANY = gql`
  query Companies($limit: Int, $search: String) {
    allCompany(limit: $limit, search: $search) {
      companies {
        id
        name
      }
    }
  }
`;

export const GET_FORCE_MATCHES = gql`
  query PositionForceMatches($id: ID) {
    position(id: $id) {
      id
      forceMatches {
        visible
        createdAt
        name
        email
        expired
        link
        matchState
        user {
          id
          name
          status
          isForceMatched
        }
        uniqueId
      }
    }
  }
`;

export const CREATE_FORCE_MATCH = gql`
  mutation CrateForceMatch(
    $position: ID!
    $name: String!
    $matchState: String!
    $email: String
  ) {
    addForceMatch(
      position: $position
      name: $name
      matchState: $matchState
      email: $email
    ) {
      name
      matchState
      link
      visible
    }
  }
`;

export const CREATE_FORCE_MATCH_IN_COMMUNITY = gql`
  mutation CrateForceMatchInCommunity(
    $position: ID!
    $matchState: String!
    $user: ID!
  ) {
    addForceMatch(position: $position, user: $user, matchState: $matchState) {
      name
      matchState
      visible
    }
  }
`;

export const GET_COMPANY = gql`
  query Company($id: ID) {
    company(id: $id) {
      id
      name
      locations {
        id
        title
        lat
        lon
        fullAddress
        placeId
        headquarter
      }
    }
  }
`;

export const GET_ALL_RECRUITER = gql`
  query allRecruiter($limit: Int, $page: Int, $jobType: [Int]) {
    allRecruiter(limit: $limit, page: $page, jobType: $jobType) {
      recruiters {
        id
        name
        email
        role
        jobType
      }
      currentPage
      totalCount
      totalPages
    }
  }
`;

export const UPDATE_POSITION_STATE = gql`
  mutation PositionStateChange($id: ID!, $state: String!) {
    positionStateChange(id: $id, state: $state) {
      id
      state
    }
  }
`;

export const GET_TECHNOLOGY = gql`
  {
    allTechnology(limit: 0) {
      technologies {
        id
        title
        categoryId
        category
      }
    }
  }
`;

export const GET_BENEFITS = gql`
  {
    allBenefits {
      id
      label
    }
  }
`;

export const CREATE_POSITION = gql`
  mutation addPosition(
    $title: String!
    $description: String
    $assignee: ID!
    $company: ID!
  ) {
    addPosition(
      title: $title
      description: $description
      assignee: $assignee
      company: $company
    ) {
      id
      company {
        id
        name
        locations {
          id
          title
          place {
            id
            city
          }
          location {
            coordinates
            type
          }
          fullAddress
          headquarter
        }
      }
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation UpdatePosition(
    $id: ID!
    $title: String
    $description: String
    $recruitmentStages: String
    $role: ID
    $assignee: ID
    $content: PositionContentInputType
    $highlights: [PositionHighlightsInputType]
    $processDurationInDays: Int
    $isExternal: Boolean
    $externalPositionApplyLink: String
    $externalSourcingMessage: String
  ) {
    updatePosition(
      id: $id
      title: $title
      description: $description
      recruitmentStages: $recruitmentStages
      role: $role
      assignee: $assignee
      content: $content
      highlights: $highlights
      processDurationInDays: $processDurationInDays
      isExternal: $isExternal
      externalPositionApplyLink: $externalPositionApplyLink
      externalSourcingMessage: $externalSourcingMessage
    ) {
      id
    }
  }
`;

export const CREATE_POSITION_CRITERIA = gql`
  mutation createPositionCriteria(
    $positionId: ID
    $totalExperience: PositionTotalExperienceInputType
    $budget: PositionBudgetInputType
    $stockOption: PositionStockOptionInputType
    $benefits: PositionBenefitsInputType
    $technologies: PositionTechnologiesInputType
    $positionLocation: PositionLocationInputType
    $age: PositionAgeInputType
    $education: PositionEducationInputType
    $languages: [PositionLanguageInputType]
  ) {
    createPositionCriteria(
      positionId: $positionId
      totalExperience: $totalExperience
      budget: $budget
      benefits: $benefits
      technologies: $technologies
      positionLocation: $positionLocation
      age: $age
      education: $education
      languages: $languages
      stockOption: $stockOption
    ) {
      id
    }
  }
`;

export const UPDATE_POSITION_CRITERIA = gql`
  mutation updatePositionCriteria(
    $positionId: ID!
    $totalExperience: PositionTotalExperienceInputType
    $budget: PositionBudgetInputType
    $benefits: PositionBenefitsInputType
    $technologies: PositionTechnologiesInputType
    $positionLocation: PositionLocationInputType
    $age: PositionAgeInputType
    $education: PositionEducationInputType
    $languages: [PositionLanguageInputType]
    $stockOption: PositionStockOptionInputType
  ) {
    updatePositionCriteria(
      positionId: $positionId
      totalExperience: $totalExperience
      budget: $budget
      benefits: $benefits
      technologies: $technologies
      positionLocation: $positionLocation
      age: $age
      education: $education
      languages: $languages
      stockOption: $stockOption
    ) {
      id
    }
  }
`;

export const SEARCH_UNIVERSITY = gql`
  query AllUniversity($search: String) {
    allUniversity(search: $search) {
      universities {
        id
        name
      }
    }
  }
`;

export const SEARCH_BRANCH = gql`
  query AllUniversityBranch($search: String) {
    allUniversityBranch(search: $search) {
      universityBranches {
        id
        label
      }
    }
  }
`;

export const ALL_LANGUAGES = gql`
  query AllLanguages($search: String, $limit: Int) {
    allLanguages(search: $search, limit: $limit) {
      languages {
        id
        label
        code
        alternativeTexts
      }
    }
  }
`;

export const GET_POSITION_REPORTS = gql`
  query positionReports($id: ID) {
    positionReports(id: $id) {
      id
      createdAt
      matchCount
      interestedCount
      passedCount
      forceMatchCount
      clickedApplyLinkCount
      rejectReasonStats {
        id
        label
        count
      }
      failedCount {
        failedFromInterested
        failedFromTgAssessment
      }
      mailStat {
        delivered
        opened
        clicked
      }
    }
  }
`;

export const GET_POSITION_WITH_COMPANY_REPORTS = gql`
  query Position($id: ID) {
    position(id: $id) {
      id
      history {
        current
        createdAt
      }
      companyReports {
        notified
        interested
        awatingAction
        verified
        inTGAssessment
        hiringProcess
        notResponded
        passed
        failed
      }
    }
  }
`;

export const GET_REJECT_REASON = gql`
  {
    allRejectReason(type: 0) {
      id
      label
    }
  }
`;

export const GET_FAILED_REASON = gql`
  query allRejectReason($type: Int) {
    allRejectReason(type: $type) {
      id
      label
      description
    }
  }
`;

const MatchPreviewData = gql`
  fragment StateMatches on Match {
    id
    state
    substate
    rate
    conversation {
      id
      note
    }
    user {
      id
      name
      workHistory {
        company
        position
      }
      email
      status
      social {
        linkedin
      }
      role {
        id
        title
      }
      flags {
        note
        type
      }
      preferences {
        openToWork
      }
      isVerifiedByTP
      activeCompanyMatchCount
      isTest
    }
    failAttemptToGreenhouse
    position {
      state
    }
    batch {
      id
      index
    }
    creationType
    updatedAt
  }
`;

export const GET_STATE_WITH_MATCHES = gql`
  ${MatchPreviewData}
  query GetStatesWithMatches(
    $positionId: ID
    $states: [matchFilterByStateType]
    $search: String
    $sort: String
    $applier: Boolean
  ) {
    getStatesWithMatches(
      positionId: $positionId
      states: $states
      search: $search
      sort: $sort
      applier: $applier
    ) {
      state
      substate
      matches {
        ...StateMatches
      }
      count
      limit
    }
  }
`;

export const CALCULATE_MATCH_COUNT_BY_POSITION = gql`
  query CalculateMatchCountByPosition($positionId: ID!) {
    calculateMatchCountByPosition(positionId: $positionId)
  }
`;

export const UPDATE_EXTERNAL_MATCH_REPORT = gql`
  mutation UpdateExternalMatchReport(
    $positionId: ID!
    $interested: Int!
    $notInterested: Int!
    $interviewed: Int!
    $notified: Int!
    $interviewedButNotSignedUp: Int!
  ) {
    updateExternalMatchReport(
      positionId: $positionId
      interested: $interested
      notInterested: $notInterested
      interviewed: $interviewed
      notified: $notified
      interviewedButNotSignedUp: $interviewedButNotSignedUp
    ) {
      id
      externalMatchReports {
        notified
        interested
        notInterested
        interviewed
        interviewedButNotSignedUp
      }
    }
  }
`;

export const SEARCH_ALL_USER = gql`
  query AllUser($search: String, $filters: userFilterInputType) {
    allUser(search: $search, filters: $filters) {
      users {
        email
        name
        id
      }
    }
  }
`;

export const SEARCH_ALL_MATCHABLE_USER = gql`
  query AllMatchableUser(
    $positionId: ID!
    $search: String
    $filters: userFilterInputType
  ) {
    allMatchableUser(
      positionId: $positionId
      search: $search
      filters: $filters
    ) {
      matchableUsers {
        email
        name
        id
      }
    }
  }
`;

export const GET_PREVIOUS_RECRUITMENT_STAGES = gql`
  query getPreviousRecruitmentStagesByCompanyId($companyId: ID) {
    getPreviousRecruitmentStagesByCompanyId(companyId: $companyId) {
      id
      title
      recruitmentStages
      updatedAt
    }
  }
`;

export const FIND_MATCHED_USER_COUNT_BY_BATCH_FILTER = gql`
  query findMatchedUserCountByBatchFilter(
    $positionId: ID!
    $filters: batchFilterInputType
  ) {
    findMatchedUserCountByBatchFilter(
      positionId: $positionId
      filters: $filters
    )
  }
`;

export const ADD_NEW_BATCH = gql`
  mutation addNewBatch(
    $positionId: ID!
    $title: String!
    $filters: batchFilterInputType
  ) {
    addNewBatch(positionId: $positionId, title: $title, filters: $filters) {
      id
    }
  }
`;

export const GET_POSITION_BATCHES = gql`
  query position($id: ID!) {
    position(id: $id) {
      id
      batches {
        id
        index
        title
        createdAt
        matchCount
        includeLeads
        keywords
        isVerifiedByTP
        creator {
          id
          name
          avatarURL
        }
        roles {
          id
          title
        }
        otherExpected {
          _id
          title
        }
        totalExperience {
          min
          max
        }
        age {
          min
          max
        }
        positionLocation {
          remote
          expectations {
            id
            city
            country
            capital
          }
        }
        education {
          type
          universities {
            id
            name
          }
          branches {
            id
            label
          }
        }
        budget {
          currency
          income
          period
          min
          max
        }
        languages {
          languageLevel
          language {
            id
            label
          }
        }
        skills {
          experience
          skill {
            _id
            title
          }
        }
        exchangeRate {
          base
          TRY {
            code
            value
          }
        }
        livingCities {
          city
          capital
          country
          id
        }
      }
    }
  }
`;

export const SEARCH_TECHNOLOGY = gql`
  query allTechnology($search: String, $verified: Boolean) {
    allTechnology(search: $search, verified: $verified) {
      technologies {
        id
        title
        categoryId
        category
      }
    }
  }
`;
