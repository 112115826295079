export const POSITION_STATES = {
  ON_HOLD: "ON_HOLD",
  CREATED: "CREATED",
  SUBMITTED: "SUBMITTED",
  IN_PROGRESS: "IN_PROGRESS",
  FILLED: "FILLED",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED",
};

export const POSITION_STATE_TITLES = {
  ON_HOLD: "On Hold",
  CREATED: "Created",
  SUBMITTED: "Awaiting Approval",
  IN_PROGRESS: "In Progress",
  FILLED: "Filled",
  CLOSED: "Closed",
  CANCELED: "Canceled",
};

export const CLOSED_POSITION_STATES = [
  POSITION_STATES.ON_HOLD,
  POSITION_STATES.FILLED,
  POSITION_STATES.CLOSED,
  POSITION_STATES.CANCELED,
];

export const MATCH_STATES = {
  EXTERNAL_SOURCING: "EXTERNAL_SOURCING",
  CREATED: "CREATED",
  SENT: "SENT",
  INTERESTED: "INTERESTED",
  TG_ASSESSMENT: "TG_ASSESSMENT",
  COMPANY_ASSESSMENT: "COMPANY_ASSESSMENT",
  INTERVIEW: "INTERVIEW",
  SENT_OFFER: "SENT_OFFER",
  HIRED: "HIRED",
  PASSED: "PASSED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
};

export const MATCH_CREATION_TYPES = {
  BATCH: {
    key: "BATCH",
    value: "Batch",
    short: "B",
  },
  REFERRAL: { key: "REFERRAL", value: "Referral", short: "R" },
  FORCE_MATCH: { key: "FORCE_MATCH", value: "Force Match", short: "F" },
  LANDING_PAGE: { key: "LANDING_PAGE", value: "Landing", short: "L" },
  MARKETING_PAGE: { key: "MARKETING_PAGE", value: "Marketing", short: "M" },
};

export const CURRENCIES = [
  { label: "USD", value: "USD" },
  { label: "TRY", value: "TRY" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
];

export const CURRENCY_TYPES = {
  USD: "USD",
  TRY: "TRY",
  EUR: "EUR",
  GBP: "GBP",
};

export const BUDGET_PERIODS = [
  { label: "Per year", value: "YEARLY" },
  { label: "Per month", value: "MONTHLY" },
];

export const BUDGET_PERIOD_TYPES = {
  YEARLY: "YEARLY",
  MONTHLY: "MONTHLY",
};

export const BUDGET_PERIOD_TITLES = {
  YEARLY: "year",
  MONTHLY: "month",
};

export const BUDGET_VISIBILITY_OPTIONS = [
  {
    label: "Yes, make it visible",
    value: true,
  },
  {
    label: "No, hide it",
    value: false,
  },
];

export const RELOCATION_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const VISA_SUPPORT_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const STOCK_OPTION_PROVIDE_TYPES = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const STOCK_OPTION_VISIBILITY_TYPES = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const STOCK_OPTION_RANGE_TYPE = [
  { label: "Range", value: "RANGE" },
  { label: "Certain amount", value: "CERTAIN" },
];

export const INCOME_OPTIONS = [
  { label: "Gross", value: "GROSS" },
  { label: "Net", value: "NET" },
];

export const INCOME_TYPES = {
  GROSS: "GROSS",
  NET: "NET",
};

export const INCOME_TYPE_TITLES = {
  GROSS: "Gross",
  NET: "Net",
};

export const HEAD_DATA = {
  home: {
    title: "Homepage | TG Recruiter",
  },
  companies: {
    title: "Companies | TG Recruiter",
  },
  positions: {
    title: "Positions | TG Recruiter",
  },
  developers: {
    title: "People | TG Recruiter",
  },
  tools: {
    title: "Tools | TG Recruiter",
  },
  match: {
    title: "Matches | TG Recruiter",
  },
  companyDetail: {
    title: "Company Detail | TG Recruiter",
  },
  positionDetail: {
    title: "Position Detail | TG Recruiter",
  },
  matchDetail: {
    title: "Match Detail | TG Recruiter",
  },
  profileDetail: {
    title: "Profile Info | TG Recruiter",
  },
  login: {
    title: "Login | Talentgrid",
  },
  forgotPassword: {
    title: "Reset Password | TG Recruiter",
  },
};

export const ISTANBUL_PLACE_ID = "60783e804136605b0a48bfe8";

export const IstanbulRegions = [
  { id: 1, name: "Anatolia" },
  { id: 2, name: "Europe" },
];
export const SORTERS = {
  descend: "DESC",
  ascend: "ASC",
};

export const EDUCATION_TYPES = [
  {
    label: "Associate's Degree",
    value: 0,
  },
  {
    label: "Bachelor's Degree",
    value: 1,
  },
  {
    label: "Master’s Degree",
    value: 2,
  },
  {
    label: "Doctorate Degree",
    value: 3,
  },
];

export const LANGUAGE_LEVEL_TYPES = [
  {
    label: "A1 - Beginner",
    value: 1,
  },
  {
    label: "A2 - Elementary",
    value: 2,
  },
  {
    label: "B1 - Intermediate",
    value: 3,
  },
  {
    label: "B2 - Upper Intermediate",
    value: 4,
  },
  {
    label: "C1 - Advanced",
    value: 5,
  },
  {
    label: "C2 - Proficiency",
    value: 6,
  },
  {
    label: "Native",
    value: 7,
  },
];

export const TECHNOLOGY_CATEGORY_KEYS = {
  1: "language",
  2: "tool",
  3: "cloud",
  4: "versioning",
  5: "framework",
  6: "architecture",
  7: "database",
  8: "system",
  9: "methodologies",
  10: "analytics",
};

export const TECHNOLOGY_CATEGORY_IDS = {
  language: 1,
  tool: 2,
  cloud: 3,
  versioning: 4,
  framework: 5,
  architecture: 6,
  database: 7,
  system: 8,
  methodologies: 9,
  analytics: 10,
};

export const TECHNOLOGY_CATEGORY_LABELS = {
  language: "Programming language",
  framework: "Framework",
  tool: "Tools",
  cloud: "Cloud systems",
  versioning: "Versioning",
  architecture: "Architecture",
  database: "Database systems",
  analytics: "Analytics & reporting",
  methodologies: "Methodologies",
  cicd: "CI/CD Tools",
};

export const TECHNOLOGY_CATEGORIES = [
  {
    label: "Unknown",
    value: 0,
  },
  {
    label: "Programming Language",
    value: 1,
  },
  {
    label: "Tools",
    value: 2,
  },
  {
    label: "Cloud System",
    value: 3,
  },
  {
    label: "Versioning",
    value: 4,
  },
  {
    label: "Framework",
    value: 5,
  },
  {
    label: "Architecture",
    value: 6,
  },
  {
    label: "Database",
    value: 7,
  },
  {
    label: "CI-CD",
    value: 8,
  },
  {
    label: "Methodologies",
    value: 9,
  },
  {
    label: "Analytics",
    value: 10,
  },
];

export const REMOTE_OPTIONS_FOR_DEVELOPER = [
  {
    id: "half",
    title: "Onsite or Remote",
  },
  {
    id: "none",
    title: "Only Onsite",
  },
  {
    id: "full",
    title: "Only Remote",
  },
];

export const REMOTE_OPTIONS_FOR_COMPANY = [
  {
    id: "half",
    title: "Hybrid",
  },
  {
    id: "none",
    title: "Onsite",
  },
  {
    id: "full",
    title: "Full Remote",
  },
];

export const MATCH_MAIL_STATUS_TYPES = {
  DELIVERED: 1,
  OPENED: 2,
  LINK_CLICKED: 3,
  REMINDER_DELIVERED: 4,
  REMINDER_OPENED: 5,
  REMINDER_LINK_CLICKED: 6,
  FAIL_FROM_INTERESTED_DELIVERED: 7,
  FAIL_FROM_INTERESTED_OPENED: 8,
  FAIL_FROM_INTERESTED_LINK_CLICKED: 9,
  FAIL_FROM_TG_ASSESSMENT_DELIVERED: 10,
  FAIL_FROM_TG_ASSESSMENT_OPENED: 11,
  FAIL_FROM_TG_ASSESSMENT_LINK_CLICKED: 12,
  NEW_VERIFIED_MATCH_DELIVERED: 13,
  NEW_VERIFIED_MATCH_OPENED: 14,
  NEW_VERIFIED_MATCH_LINK_CLICKED: 15,
};

export const MATCH_MAIL_STATUSES = [
  {
    id: MATCH_MAIL_STATUS_TYPES.DELIVERED,
    color: "#FAD156",
    title: "Delivered",
  },
  { id: MATCH_MAIL_STATUS_TYPES.OPENED, color: "#72D1FB", title: "Opened" },
  {
    id: MATCH_MAIL_STATUS_TYPES.LINK_CLICKED,
    color: "#8FDEAC",
    title: "Link clicked",
  },
  {
    id: MATCH_MAIL_STATUS_TYPES.REMINDER_DELIVERED,
    color: "#FAD156",
    title: "Delivered",
  },
  {
    id: MATCH_MAIL_STATUS_TYPES.REMINDER_OPENED,
    color: "#72D1FB",
    title: "Opened",
  },
  {
    id: MATCH_MAIL_STATUS_TYPES.REMINDER_LINK_CLICKED,
    color: "#8FDEAC",
    title: "Link clicked",
  },
];

export const CURRENT_YEAR = new Date().getFullYear();

export const CURRENT_MONTH = new Date().getMonth() + 1;

export const VALIDATION_MESSAGES = {
  /* eslint-disable-next-line no-template-curly-in-string */
  required: "${label} required!",
  types: {
    /* eslint-disable-next-line no-template-curly-in-string */
    email: "${label} is not validate email!",
  },
};

export const LINKEDIN_WIZARD_MENU_ITEMS = {
  1: "profile-photo",
  2: "about",
  3: "work-experience",
  4: "education",
  5: "languages",
  6: "skills",
};

export const FLAGS = [
  {
    type: "GREEN",
    description: "Green flag",
    colors: { color: "#8FDEAC", background: "#F1FBF5" },
  },
  {
    type: "YELLOW",
    description: "Yellow flag",
    colors: { color: "#F6B901", background: "#FEF9EA" },
  },
  {
    type: "RED",
    description: "Red flag",
    colors: { color: "#FF7675", background: "#FFDDDC" },
  },
];

export const CONVERSATION_TYPES = {
  MEETING: "MEETING",
  CONVERSATION: "CONVERSATION",
};

export const USER_TYPES = {
  USER: 0,
  RECRUITER: 1,
  EMPLOYER: 2,
};

export const DEVELOPER_STATUSES = {
  PRE_LEAD: "PRE_LEAD",
  PASSIVE: "PASSIVE",
  LEAD: "LEAD",
  MEMBER: "MEMBER",
};

export const MOST_SPOKEN_LANGUAGES = [
  "English",
  "Spanish",
  "German",
  "French",
  "Romanian",
  "Mandarin",
  "Arabic",
  "Russian",
  "Ukrainian",
  "Hindi",
  "Italian",
  "Turkish",
  "Portuguese",
  "Korean",
];

export const MATCH_HISTORY_ACTION_TYPES = {
  SEND_MATCH_REMINDER_EMAIL: 1,
  MATCH_SEEN_BY_DEVELOPER: 2,
  MATCH_TAKEN_BACK_BY_DEVELOPER: 3,
  SENT_TO_GREENHOUSE: 4,
};

export const MATCH_ACTIVITY_TYPES = [
  { value: 0, key: "Profile Info Tab" },
  { value: 1, key: "Work Experience Tab" },
  { value: 2, key: "Education Tab" },
  { value: 3, key: "Conversation Tab" },
];

export const EMPLOYER_TYPES = Object.freeze({
  MEMBER: 0,
  ADMIN: 1,
});
export const RECRUITER_ROLES = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
  PASSIVE: "PASSIVE",
};

export const DESCRIPTIVE_RECRUITER_ROLES = {
  ADMIN: "Admin",
  MEMBER: "Member",
};

export const RECRUITER_ROLES_ARRAY = [
  { key: "ADMIN", title: "Admin" },
  { key: "MEMBER", title: "Member" },
];

export const RECRUITER_JOB_TYPES = {
  TP: 0,
  CP: 1,
};

export const FAIL_REASON_MODAL_TYPES = {
  FROM_COMPANY_STATES_TO_FAILED: "FROM_COMPANY_STATES_TO_FAILED",
  FROM_TG_ASSESSMENT_TO_FAILED: "FROM_TG_ASSESSMENT_TO_FAILED",
};

export const EMAIL_EVENTS = {
  DELIVERY: 1,
  OPEN: 2,
  CLICK: 3,
  BOUNCE: 4,
};

export const EMAIL_TEMPLATES = {
  NEW_RECRUITER_INVITATION: "new-recruiter-invitation",
  NEW_MATCH: "new-match-mail",
  VERIFICATION_CODE: "verification_code",
  ACCOUNT_DELETE: "account_delete",
  PARTNER_RECOMMENDATION: "partner_recommendation",
  MATCH_REMINDER: "new_match_plain-1",
  MATCH_FAIL_FROM_TG_ASSESSMENT: "tg-assessment-to-failed",
  MATCH_FAIL_FROM_INTERESTED: "interested-to-failed",
  MATCH_CHANGED_TO_INTERVIEW_BY_COMPANY: "verified-match-to-interview",
  MATCH_CHANGED_TO_FAILED_BY_COMPANY: "match-failed-by-company",
  NEW_VERIFIED_MATCH: "new-verified-match",
  NEW_SUBMITTED_POSITION: "new_submitted_position",
  COMPANY_FEEDBACK: "company_feedback",
  CONVERSATION_NOTIFICATION: "comment-notification",
  INVITE_EMPLOYER: "invite-employer",
  INVITE_EXISTING_EMPLOYER: "invite-existing-employer",
};

export const DESCRIPTIVE_RECRUITER_JOB_TYPES = {
  0: "TP",
  1: "CP",
};

export const ERROR_TYPES = {
  VALIDATION: "ValidationError",
};

export const proExperiences = [
  { name: "0-1 years", value: 0 },
  { name: "1 year", value: 1 },
  { name: "2 years", value: 2 },
  { name: "3 years", value: 3 },
  { name: "4 years", value: 4 },
  { name: "5 years", value: 5 },
  { name: "6 years", value: 6 },
  { name: "7 years", value: 7 },
  { name: "8 years", value: 8 },
  { name: "9 years", value: 9 },
  { name: "10 years", value: 10 },
  { name: "10+ years", value: 11 },
];
