const HIGHLIGHT_TYPE_ENUM = Object.freeze({
  LOCATION: 0,
  SALARY: 1,
  EXTRA: 2,
});

const HIGHLIGHT_VALUE_ENUM = Object.freeze({
  RELOCATION_SUPPORT: 0,
  FULL_REMOTE: 1,
  POUND_BASED_SALARY: 2,
  EURO_BASED_SALARY: 3,
  DOLLAR_BASED_SALARY: 4,
  STOCK_OPTIONS: 5,
  MODERN_STACK: 6,
});

const POSITION_HIGHLIGHT_OPTIONS = Object.freeze([
  {
    label: "Relocation Support",
    value: {
      value: HIGHLIGHT_VALUE_ENUM.RELOCATION_SUPPORT,
      type: HIGHLIGHT_TYPE_ENUM.LOCATION,
    },
  },
  {
    label: "Full Remote",
    value: {
      value: HIGHLIGHT_VALUE_ENUM.FULL_REMOTE,
      type: HIGHLIGHT_TYPE_ENUM.LOCATION,
    },
  },
  {
    label: "£ Based Salary",
    value: {
      value: HIGHLIGHT_VALUE_ENUM.POUND_BASED_SALARY,
      type: HIGHLIGHT_TYPE_ENUM.SALARY,
    },
  },
  {
    label: "€ Based Salary",
    value: {
      value: HIGHLIGHT_VALUE_ENUM.EURO_BASED_SALARY,
      type: HIGHLIGHT_TYPE_ENUM.SALARY,
    },
  },
  {
    label: "$ Based Salary",
    value: {
      value: HIGHLIGHT_VALUE_ENUM.DOLLAR_BASED_SALARY,
      type: HIGHLIGHT_TYPE_ENUM.SALARY,
    },
  },
  {
    label: "Stock Options",
    value: {
      value: HIGHLIGHT_VALUE_ENUM.STOCK_OPTIONS,
      type: HIGHLIGHT_TYPE_ENUM.EXTRA,
    },
  },
  {
    label: "Modern Stack",
    value: {
      value: HIGHLIGHT_VALUE_ENUM.MODERN_STACK,
      type: HIGHLIGHT_TYPE_ENUM.EXTRA,
    },
  },
]);

export {
  POSITION_HIGHLIGHT_OPTIONS,
  HIGHLIGHT_TYPE_ENUM,
  HIGHLIGHT_VALUE_ENUM,
};
