import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_RECRUITER } from "../../queries/general";
import { TgHeader } from "../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../tg-styles/TgHeaderTabs";
import PartialError from "../ErrorPage/PartialError";
import { TeamMemberProfile } from "../TeamMemberEdit/tabs/TeamMemberEditPage";

export default function TalentPartnerProfile() {
  const { loading, error, refetch, data } = useQuery(GET_RECRUITER);

  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "tpProfile"
  );
  const getTab = () => {
    switch (currentTab) {
      case "tpProfile":
        return (
          <TeamMemberProfile recruiter={data.recruiter} refetch={refetch} />
        );
      default:
        setCurrentTab("tpProfile");
        break;
    }
  };

  if (loading) return "Loading...";
  if (error) return <PartialError />;

  return (
    <div>
      <TgHeader
        breadCrumbs={[{ title: data.recruiter.name, link: "/profile" }]}
      >
        <TgHeaderTabs currentTab={currentTab} handleChange={setCurrentTab}>
          <TgHeaderTab name="tpProfile">Profile</TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>
      <div style={{ paddingLeft: "10px" }}>{getTab()}</div>
    </div>
  );
}
