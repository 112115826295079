import { gql } from "@apollo/client";
import { POSITION_CUSTOM_FLOWS } from "../position/queries";

export const GET_MATCH = gql`
  ${POSITION_CUSTOM_FLOWS}
  query Match($id: ID, $historyLimit: Int) {
    match(id: $id) {
      id
      state
      substate
      createdAt
      rate
      creationType
      sentToGreenhouse
      inviter {
        id
        name
      }
      exchangeRate {
        base
        TRY {
          code
          value
        }
        EUR {
          code
          value
        }
        GBP {
          code
          value
        }
      }
      developerCriteria {
        status
        experience
        birthyear
        skills {
          experience
          skill {
            title
          }
        }
        education {
          type
          school {
            id
            name
          }
          branch {
            id
            label
          }
          startDate
          endDate
        }
        languages {
          level
          language {
            id
            label
          }
        }
        salary {
          open
          expected
          income
          period
          currency
        }
        location {
          open
          expected {
            country
            city
            capital
          }
          remote
        }
      }
      batch {
        id
        title
        createdAt
        matchCount
        includeLeads
        isVerifiedByTP
        creator {
          id
          name
          avatarURL
        }
        roles {
          id
          title
        }
        otherExpected {
          _id
          title
        }
        totalExperience {
          min
          max
        }
        age {
          min
          max
        }
        positionLocation {
          remote
          expectations {
            id
            city
            country
            capital
          }
        }
        education {
          type
          universities {
            id
            name
          }
          branches {
            id
            label
          }
        }
        budget {
          currency
          income
          period
          min
          max
        }
        languages {
          languageLevel
          language {
            id
            label
          }
        }
        skills {
          experience
          skill {
            _id
            title
          }
        }
        exchangeRate {
          base
          TRY {
            code
            value
          }
          EUR {
            code
            value
          }
          GBP {
            code
            value
          }
        }
      }
      conversation {
        id
        creator {
          name
          avatarURL
        }
        creatorType
        note
        type
        updatedAt
        createdAt
      }
      unseenConversationCount
      history(limit: $historyLimit) {
        id
        actions {
          type
          createdAt
        }
        user {
          name
        }
        current
        previous
        createdAt
      }

      mailStatuses {
        id
        type
        templateID
        messageData {
          TextBody
          HtmlBody
          From
          Subject
          Recipients
        }
        createdAt
      }
      failReasons {
        recruiter {
          id
          name
        }
        employer {
          id
          fullName
        }
        description
        reasons {
          label
        }
      }
      fields {
        role {
          score
          used
        }
        skills {
          score
          used
        }
        otherSkills {
          score
          used
        }
        techStack {
          score
          used
        }
        teamSize {
          score
          used
        }
        age {
          score
          used
        }
        location {
          score
          used
        }
        experience {
          score
          used
        }
        salary {
          score
          used
        }
        salary {
          score
          used
          data {
            original {
              developerSalary
              positionMinSalary
              positionMaxSalary
              developerCurrency
              developerIncome
              developerPeriod
              positionCurrency
              positionIncome
              positionPeriod
            }
            calculated {
              developerBaseSalary
              positionMinBaseSalary
              positionMaxBaseSalary
              currency
              income
              period
            }
          }
        }
        benefits {
          score
          used
        }
        educationLevel {
          score
          used
        }
        school {
          score
          used
          matches {
            id
            name
          }
        }
        department {
          score
          used
          matches {
            id
            label
          }
        }
        language {
          score
          used
        }
      }
      rejectReason {
        label
      }
      user {
        id
        bio
        name
        email
        avatarURL
        status
        birthyear
        status
        phone
        inviter {
          id
          name
        }
        isVerifiedByTP
        allVerifiedMatches {
          id
          state
          position {
            id
            title
            company {
              name
            }
          }
          createdAt
        }
        isTest
        resume {
          fileName
          resumeURL
        }
        preferences {
          currency
          openToWork
        }
        invites {
          email
          name
          status
        }
        livingCity {
          city
        }
        skills {
          title
          experience
        }
        otherSkills {
          id
          title
          categoryId
        }
        social {
          linkedin
          stackoverflow
          github
          portfolio
          twitter
          youtube
          blog
        }
        role {
          title
        }
        roles {
          role {
            title
          }
          experience
        }
        education {
          type
          school {
            id
            name
          }
          branch {
            id
            label
          }
          startDate
          endDate
        }
        languages {
          language {
            id
            label
          }
          level
        }
        experience
        social {
          linkedin
        }
        utms {
          source
          medium
          campaign
          term
          content
        }
        criteria {
          salary {
            open
            expected
            currency
            period
            income
          }
          companySize {
            open
            expected {
              label
            }
          }
          benefits {
            open
            expected {
              label
            }
          }
          technologies {
            open
            expected {
              id
              title
            }
          }
          location {
            open
            expected {
              id
              city
            }
            remote
            istanbulRegion
          }
          freelance {
            open
            availableWeeklyHour
            hourlyRate
            hourlyRateCurrency
          }
          other {
            open
            expected
          }
        }
        workHistory {
          company
          position
          startDate
          endDate
          present
          description
        }
        matchSettings {
          available
          availableDate
        }
        flags {
          creator {
            name
            avatarURL
          }
          note
          type
          updatedAt
        }
        activeCompanyMatchCount
      }
      position {
        id
        state
        title
        publicID
        greenhouseJobId
        role {
          id
          title
        }
        assignee {
          name
          avatarURL
        }
        ... on Position {
          ...CustomFlowsFragment
        }
        company {
          id
          logo
          name
          companySize
          teamSize
          technologies {
            id
            title
          }
        }
        criteria {
          age {
            min
            max
          }
          budget {
            min
            max
            currency
            period
            income
          }
          totalExperience {
            min
            max
          }
          benefits {
            expected {
              id
              label
            }
          }
          technologies {
            expected {
              _id
              title
              experience
            }
            otherExpected {
              id
              title
            }
            plus {
              id
              title
            }
          }
          positionLocation {
            expected {
              id
              title
              istanbulRegion
              place {
                id
                city
                country
                capital
              }
            }
            remote
          }
          education {
            type
            universities {
              id
              name
            }
            branches {
              id
              label
            }
          }
          languages {
            language {
              id
              label
            }
            languageLevel
          }
        }
      }
    }
  }
`;

export const GET_MATCH_ACTIVITIES = gql`
  query matchActivities($id: ID) {
    matchActivities(id: $id) {
      activities {
        type
        creator {
          name
          avatarURL
        }
        createdAt
      }
    }
  }
`;

export const GET_MATCH_MAIL_STATUSES = gql`
  query Match($id: ID) {
    match(id: $id) {
      id
      mailStatuses {
        id
        type
        templateID
        createdAt
      }
    }
  }
`;

export const MATCH_STATE_CHANGE = gql`
  mutation MatchStateChange($id: ID!, $state: String!) {
    matchStateChange(id: $id, state: $state) {
      id
      state
    }
  }
`;

export const SENT_TO_GREENHOUSE = gql`
  mutation sentMatchToGreenhouse($matchId: ID!) {
    sentMatchToGreenhouse(matchId: $matchId)
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNoteById(
    $match: ID!
    $noteId: ID!
    $note: String!
    $type: String
  ) {
    updateNoteById(match: $match, note: $note, type: $type, noteId: $noteId) {
      note
      createdAt
      type
      id
      creatorType
      creator {
        name
        avatarURL
        type
        role
      }
    }
  }
`;

export const SEND_FAIL_REASON_TEST_EMAIL = gql`
  mutation sendFailStateTestEmail($id: ID!, $failReason: String) {
    sendFailStateTestEmail(id: $id, failReason: $failReason) {
      responseStatus
      errorMessage
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation addNoteOnMatch($match: ID!, $note: String!, $type: String) {
    addNoteOnMatch(match: $match, note: $note, type: $type) {
      note
      createdAt
      type
      id
      creatorType
      creator {
        name
        avatarURL
        type
        role
      }
    }
  }
`;

export const GET_PREVIOUS_CONVERSATIONS = gql`
  query getPreviousConversationsByUserId($userId: ID) {
    getPreviousConversationsByUserId(userId: $userId) {
      id
      user {
        name
      }
      conversation {
        id
        note
        type
        creator {
          name
          avatarURL
        }
        createdAt
      }
      position {
        id
        title
        company {
          name
        }
      }
    }
  }
`;
