import { gql } from "@apollo/client";

export const UPDATE_USER_AS_RECRUITER = gql`
  mutation UpdateUserAsRecruiter(
    $id: ID
    $name: String
    $bio: String
    $social: SocialInputType
    $languages: [UserLanguageInputType]
    $workHistory: [UserWorkHistoryInputType]
    $education: [UserEducationInputType]
    $phone: String
    $isTest: Boolean
    $preferences: UserPreferenceInputType
  ) {
    updateUserAsRecruiter(
      id: $id
      name: $name
      bio: $bio
      social: $social
      languages: $languages
      workHistory: $workHistory
      education: $education
      phone: $phone
      isTest: $isTest
      preferences: $preferences
    ) {
      id
      name
      phone
      bio
      preferences {
        openToWork
        currency
      }
      workHistory {
        company
        position
        present
        description
        endDate
        startDate
      }
      social {
        github
        linkedin
        stackoverflow
        portfolio
        youtube
        blog
        twitter
      }
      languages {
        language {
          id
          label
        }
        level
      }
    }
  }
`;

export const UPDATE_USER_FROM_LINKEDIN = gql`
  mutation UpdateUserfromLinkedin(
    $id: ID
    $bio: String
    $otherSkills: [ID]
    $languages: [UserLanguageInputType]
    $workHistory: [UserWorkHistoryInputType]
    $education: [UserEducationInputType]
    $isLinkedinAvatarUpload: Boolean
    $avatarURL: String
    $uuid: String
  ) {
    updateUserFromLinkedin(
      id: $id
      isLinkedinAvatarUpload: $isLinkedinAvatarUpload
      avatarURL: $avatarURL
      bio: $bio
      otherSkills: $otherSkills
      languages: $languages
      workHistory: $workHistory
      education: $education
      uuid: $uuid
    ) {
      id
    }
  }
`;

export const GET_USER_WORK_HISTORY = gql`
  query User($id: String) {
    user(id: $id) {
      id
      workHistory {
        company
        position
        startDate
        endDate
        present
        description
      }
    }
  }
`;

export const GET_USER = gql`
  query User($id: String) {
    user(id: $id) {
      id
      bio
      name
      email
      avatarURL
      status
      birthyear
      status
      phone
      isVerifiedByTP
      createdAt
      updatedAt
      inviter {
        id
        name
      }
      resume {
        fileName
        resumeURL
      }
      utms {
        source
        medium
        campaign
        term
        content
      }
      preferences {
        currency
        openToWork
      }
      invites {
        email
        name
        status
      }
      livingCity {
        city
      }
      skills {
        title
        experience
      }
      otherSkills {
        id
        title
        categoryId
      }
      social {
        linkedin
        stackoverflow
        github
        portfolio
        blog
        twitter
        youtube
      }
      role {
        title
      }
      roles {
        role {
          title
        }
        experience
      }
      education {
        type
        school {
          id
          name
        }
        branch {
          id
          label
        }
        startDate
        endDate
      }
      languages {
        language {
          id
          label
        }
        level
      }
      experience
      social {
        linkedin
      }
      criteria {
        salary {
          expected
          currency
          period
          income
        }
        companySize {
          open
          expected {
            label
          }
        }
        benefits {
          open
          expected {
            label
          }
        }
        technologies {
          open
          expected {
            title
          }
        }
        location {
          open
          expected {
            id
            city
          }
          remote
          istanbulRegion
        }
        freelance {
          open
          availableWeeklyHour
          hourlyRate
          hourlyRateCurrency
        }
        other {
          open
          expected
        }
      }
      workHistory {
        company
        position
        startDate
        endDate
        present
        description
      }
      matchSettings {
        available
        availableDate
      }
      matches {
        id
        state
        rate
        createdAt
        isTest
        position {
          id
          state
          title
          isTest
          company {
            id
            name
            isTest
          }
        }
        conversation {
          id
          creator {
            name
            avatarURL
          }
          creatorType
          note
          type
          updatedAt
        }
      }
      flags {
        creator {
          name
          avatarURL
        }
        id
        note
        type
        updatedAt
      }
      isTest
    }
  }
`;

export const GET_ROLES = gql`
  {
    allRole(limit: 0) {
      roles {
        id
        title
      }
    }
  }
`;

export const FIND_MATCHED_USERS = gql`
  query findMatchedUsers(
    $filters: batchFilterInputType
    $limit: Int
    $page: Int
  ) {
    findMatchedUsers(filters: $filters, limit: $limit, page: $page) {
      matchedUsers {
        id
        name
        isVerifiedByTP
        role {
          title
        }
        roles {
          role {
            title
          }
        }
        experience
        social {
          linkedin
        }
        matches {
          id
        }
        isTest
      }
      currentPage
      totalPages
      totalCount
    }
  }
`;

export const GET_CITIES = gql`
  query allPlaces($search: String) {
    allPlaces(search: $search) {
      places {
        id
        city
        country
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

const USER_VERSION_FRAGMENT = gql`
  fragment UserVersionFragment on User {
    role {
      title
    }
    experience
    name
    birthyear
    bio
    livingCity {
      city
    }
    skills {
      title
    }
    otherSkills {
      title
    }
    education {
      type
      school {
        name
      }
      startDate
      endDate
    }
    languages {
      language {
        label
      }
      level
    }
    workHistory {
      company
      position
      startDate
      endDate
      description
    }
  }
`;

const CRITERIA_VERSION_FRAGMENT = gql`
  fragment CriteriaVersionFragmentForDeveloper on DeveloperCriteriaType {
    benefits {
      expected {
        label
      }
    }
    companySize {
      expected {
        label
      }
    }
    salary {
      expected
      currency
      period
      income
    }
    location {
      expected {
        city
      }
      remote
    }
    technologies {
      expected {
        title
      }
    }
  }
`;

export const USER_VERSION = gql`
  ${USER_VERSION_FRAGMENT}
  ${CRITERIA_VERSION_FRAGMENT}
  query UserVersion($id: String) {
    user(id: $id) {
      id
      changes {
        timestamp
        diff {
          ... on User {
            ...UserVersionFragment
          }
        }
        current {
          ... on User {
            ...UserVersionFragment
          }
        }
        ownerType
        owner {
          name
          avatarURL
        }
      }
      criteria {
        changes {
          timestamp
          diff {
            ...CriteriaVersionFragmentForDeveloper
          }
          current {
            ...CriteriaVersionFragmentForDeveloper
          }
          ownerType
          owner {
            name
            avatarURL
          }
        }
      }
    }
  }
`;

export const CREATE_FLAG = gql`
  mutation AddFlagOnUser($userId: ID!, $note: String, $type: String!) {
    addFlagOnUser(userId: $userId, note: $note, type: $type) {
      flags {
        creator {
          name
          avatarURL
        }
        id
        note
        type
        updatedAt
      }
    }
  }
`;

export const UPDATE_FLAG = gql`
  mutation UpdateFlagOnUser(
    $userId: ID!
    $flagId: ID!
    $note: String
    $type: String!
  ) {
    updateFlagOnUser(
      userId: $userId
      flagId: $flagId
      note: $note
      type: $type
    ) {
      flags {
        creator {
          name
          avatarURL
        }
        id
        note
        type
        updatedAt
      }
    }
  }
`;

export const DELETE_FLAG = gql`
  mutation DeleteFlagOnUser($userId: ID!, $flagId: ID!) {
    deleteFlagOnUser(userId: $userId, flagId: $flagId) {
      flags {
        creator {
          name
          avatarURL
        }
        id
        note
        type
        updatedAt
      }
    }
  }
`;

// TODO: params must be required
export const FETCH_USER_FROM_LINKEDIN = gql`
  query FetchUserFromLinkedin($uuid: ID, $username: String, $userId: ID) {
    fetchUserFromLinkedin(uuid: $uuid, userId: $userId, username: $username)
  }
`;

export const UPLOAD_RESUME = gql`
  mutation uploadResume($id: ID!, $resume: Upload!) {
    uploadResume(id: $id, resume: $resume) {
      fileName
      resumeURL
    }
  }
`;

export const DELETE_RESUME = gql`
  mutation DeleteResume($id: ID!) {
    deleteResume(id: $id)
  }
`;

export const GET_POTENTIAL_USER_POSITION = gql`
  query GetPotentialUserPosition($matchId: String) {
    getPotentialUserPosition(matchId: $matchId) {
      positions {
        note
        signupToken
      }
      updatedAt
    }
  }
`;
