import React, { useRef } from "react";
import styled from "styled-components";
import "react-image-crop/dist/ReactCrop.css";
import { TrashIcon, EyeIcon } from "../../Icons";
import Square from "../Square";

const HiddenFileInput = styled.input`
  display: none;
`;

const ImageContainers = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const UploadedImageContainer = styled.div`
  background-color: black;
  display: table;
  border-radius: 4px;

  &:hover {
    & div.image {
      opacity: 0.5;
    }
  }
`;

const UploadedImage = styled.div`
  height: 145px;
  width: ${(props) => (props.width ? `${props.width}px` : "145px")};
  background-image: url("${(props) => props.url}");
  background-size: cover;
  opacity: inherit;
  transition: opacity 0.1s;
  overflow: hidden;
  border-radius: 4px;
`;

const UploadedImageActionButton = styled.button`
  border: none;
  background-color: transparent;
  color: #ddd;
  transition: all 0.1s ease-in-out;
  margin-right: 4px;

  &:hover {
    color: #fff;
    transform: scale(1.2);
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    color: #999;
    margin-right: 8px;

    &:hover {
      color: #888;
    }
  }
`;

const UploadedImageActionLink = styled.a`
  display: inline-block;
  border: none;
  background-color: transparent;
  color: #ddd;
  transition: all 0.1s ease-in-out;
  margin-right: 4px;

  &:hover {
    color: #fff;
    transform: scale(1.2);
  }

  @media only screen and (max-width: 768px) {
    color: #999;
    margin-right: 8px;

    &:hover {
      color: #888;
    }
  }
`;

const NewImageText = styled.div`
  display: table-cell;
  margin-top: 58px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  color: #64b7dc;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  &.is-loading {
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &.is-disabled {
    color: #ddd;
  }
`;

const Spinner = styled.span`
  display: inline-block;
`;

const Image = ({ url, loading, handleDelete, width, isEditDisabled }) => {
  if (loading) {
    return (
      <Square>
        <NewImageText className="is-loading">
          <Spinner className="loader">&nbsp;</Spinner>
        </NewImageText>
      </Square>
    );
  }

  const HoverContent = () => {
    return (
      <>
        <UploadedImageActionLink href={url} target="_blank">
          <EyeIcon />
        </UploadedImageActionLink>
        {!isEditDisabled && (
          <UploadedImageActionButton onClick={handleDelete}>
            <TrashIcon />
          </UploadedImageActionButton>
        )}
      </>
    );
  };

  return (
    <Square
      squareClasses="not-empty"
      width={width}
      hoverContent={<HoverContent />}
    >
      <UploadedImageContainer>
        <UploadedImage className="image" url={url} width={width} />
      </UploadedImageContainer>
    </Square>
  );
};

const AddNewImage = ({
  title = "Upload",
  accept,
  multiple,
  onSelection,
  disabled,
}) => {
  const fileInput = useRef(null);

  const openFileDialog = () => {
    if (!disabled) {
      fileInput.current.click();
    }
  };

  const handleOnChange = (event) => {
    onSelection([...event.target.files]);
    // Input'taki seçimi sıfırlamamız lazım. Eğer cropping aşamasında kullanıcı
    // modalı kapatır, ve daha sonrasında tekrar aynı image'ı seçerse, onChange
    // metodu tetiklenmiyor. Bu nedenle seçim sonrası input değerini temizlemek
    // zorundayız.
    event.target.value = null;
  };

  return (
    <>
      <HiddenFileInput
        type="file"
        id="file"
        ref={fileInput}
        multiple={multiple}
        accept={accept}
        onChange={handleOnChange}
      />
      <Square onClick={openFileDialog}>
        <NewImageText className={disabled && "is-disabled"}>
          {title}
        </NewImageText>
      </Square>
    </>
  );
};

export default function UploadImageButton({
  max = 1,
  accept,
  onDelete,
  files,
  onSelection,
  multiple = false,
  width,
  disabled,
}) {
  return (
    <>
      <ImageContainers>
        {files.map((file, index) => (
          <Image
            key={index}
            loading={file.isUploading || file.isDeleting}
            url={file.url}
            handleDelete={() => onDelete(file)}
            width={width}
            isEditDisabled={disabled}
          />
        ))}
        {max > files.length && (
          <AddNewImage
            multiple={multiple}
            accept={accept}
            onSelection={onSelection}
            disabled={disabled}
          />
        )}
      </ImageContainers>
    </>
  );
}
