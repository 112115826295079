import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "tg-design";
import { useMutation } from "@apollo/client";
import { Upload, Form, Input, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  UPLOAD_IMAGE,
  DELETE_COMPANY_PHOTO,
  UPDATE_COMPANY_PHOTO_DESCRIPTION,
} from "../queries";
import { captureErrorWithData } from "../../../helper";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function UploadOfficePhoto({
  onUpload,
  inputStatus,
  setInputStatus,
  initialValues,
  companyId,
  setStatus,
  refetch,
}) {
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [deleteImage] = useMutation(DELETE_COMPANY_PHOTO);
  const [updateCompanyPhotoDescription] = useMutation(
    UPDATE_COMPANY_PHOTO_DESCRIPTION
  );
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");

  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    tempFile: {},
  });

  useEffect(() => {
    if (initialValues) {
      const setInitialValue = initialValues.map((photo, i) => ({
        ...photo,
        uid: `-${i}`,
        name: `image${i}.png`,
        status: "done",
      }));
      setFileList(setInitialValue);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleCancel = () => {
    setDescription("");
    setState({ ...state, previewVisible: false });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file);
    }

    setDescription(file.description);
    setState({
      tempFile: file,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const beforeUpload = async (file) => {
    handlePreview(file);
    return false;
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await updateCompanyPhotoDescription({
        variables: {
          company: companyId,
          description,
          officePhotoId: state.tempFile.id,
        },
      });
      setLoading(false);
      message.success("Photo description has been updated.");
      handleCancel();
      refetch();
    } catch (error) {
      captureErrorWithData(error);
      message.error("An error occurred!");
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    try {
      setStatus("loading");
      setLoading(true);
      const res = await uploadImage({
        variables: { image: state.tempFile },
      });

      // for addCompany mutation
      const imgData = {};
      imgData.description = description;
      imgData.url = res.data.uploadCompanyImage.imageUrl;
      onUpload(imgData);

      // for fileList state
      const photo = state.tempFile;
      photo.status = "done";
      photo.url = res.data.uploadCompanyImage.imageUrl;
      photo.description = description;
      setFileList([...fileList, photo]);

      message.success("Photo uploaded successfully.");
      handleCancel();
    } catch (error) {
      captureErrorWithData(error);
      message.error(error);
    }
    setLoading(false);
  };

  const handleRemove = async (img) => {
    try {
      setStatus("loading");
      setLoading(true);
      setFileList(fileList.filter((photo) => photo.uid !== img.uid));
      await deleteImage({
        variables: { imageUrl: img.url, company: companyId },
      });
      await refetch();
      setInputStatus({ ...inputStatus, officePhotos: null });
    } catch (error) {
      captureErrorWithData(error);
      message.error(error);
    }
    setLoading(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        customRequest={() => {}}
        accept="image/*"
      >
        {uploadButton}
      </Upload>
      {state.previewVisible && (
        <Modal handleClose={handleCancel}>
          <ModalHeader>{state.previewTitle}</ModalHeader>
          <ModalBody>
            <Form>
              <img
                alt="ofis fotoğrafı"
                style={{ width: "100%", marginBottom: "30px" }}
                src={state.previewImage}
              />
              <Form.Item name="description" label="Description">
                <Input
                  placeholder="Description"
                  defaultValue={description}
                  onChange={handleDescriptionChange}
                />
              </Form.Item>
            </Form>
          </ModalBody>
          <ModalFooter>
            {state.tempFile.status !== "done" && (
              <Button loading={loading} size="sm" onClick={handleUpload}>
                Save
              </Button>
            )}
            {state.tempFile.status === "done" && (
              <Button loading={loading} size="sm" onClick={handleUpdate}>
                Update
              </Button>
            )}
            <Button onClick={handleCancel} outline size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
