import React from "react";
import { useMutation } from "@apollo/client";
import { Checkbox, Form, Input, message, Select } from "antd";
import { Button } from "tg-design";
import { RECRUITER_JOB_TYPES, RECRUITER_ROLES_ARRAY } from "../../../constants";
import { captureErrorWithData } from "../../../helper";
import { TgDrawerTitle } from "../../tg-styles/Layouts";
import { CREATE_NEW_RECRUITER } from "../../TeamMemberEdit/queries";
import { GET_ALL_RECRUITER } from "../../position/queries";

export default function AddNewRecruiter({ onClose }) {
  const [createNewRecruiter] = useMutation(CREATE_NEW_RECRUITER, {
    refetchQueries: [{ query: GET_ALL_RECRUITER }, "allRecruiter"],
  });

  const closeDrawer = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    try {
      createNewRecruiter({
        variables: {
          name: values.name,
          email: values.email,
          role: values.role,
          jobType: values.jobType,
        },
      });
      onClose();
    } catch (error) {
      message.error(error);
      captureErrorWithData(error);
    }
    message.success("New recruiter added!");
  };

  return (
    <div style={{ width: "100%" }}>
      <TgDrawerTitle title="Invite New Recruiter" handleClose={closeDrawer} />
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Full Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true },
            {
              pattern: new RegExp("[a-zA-Z]+@talentgrid.io"),
              message: "E-mail format is wrong: xxx@talentgrid.io",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Recruiter Role"
          name="role"
          rules={[{ required: true }]}
        >
          <Select>
            {RECRUITER_ROLES_ARRAY.map((option) => (
              <Select.Option value={option.key}>{option.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Recruiter Duty"
          name="jobType"
          rules={[{ required: true }]}
        >
          <Checkbox.Group>
            <Checkbox value={RECRUITER_JOB_TYPES.TP}>Talent Partner</Checkbox>
            <Checkbox value={RECRUITER_JOB_TYPES.CP}>Client Partner</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item>
          <Button type="submit">Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
