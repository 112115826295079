import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Avatar, Card, Spin, message, Tag, List, Popconfirm } from "antd";
import { Button } from "tg-design";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  DELETE_USER,
  GET_USER_WITH_DELETEREASON,
  UPDATE_DELETE_REQUEST,
} from "../../queries";
import { captureErrorWithData } from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";

export default function UserDelete({ id }) {
  const { loading, error, data } = useQuery(GET_USER_WITH_DELETEREASON, {
    variables: { id },
  });

  const [deleteUsers] = useMutation(DELETE_USER);
  const [updateDeleteRequests] = useMutation(UPDATE_DELETE_REQUEST);

  if (loading) return <Spin />;
  if (error) return <PartialError />;

  const handleDelete = async () => {
    try {
      const variables = {
        id,
      };
      await deleteUsers({ variables });

      message.success("User Deleted");
    } catch (err) {
      captureErrorWithData(err);
      message.error("Something went wrong");
    }
  };

  const handleCancel = async () => {
    const userId = id;
    const deleteRequest = data.user.deleteReason.find((i) => i.active)._id;
    try {
      const variables = {
        userId,
        deleteRequest,
      };
      await updateDeleteRequests({ variables });

      message.success("Delete Request Canceled");
    } catch (err) {
      captureErrorWithData(err);
      message.error("Something went wrong");
    }
  };

  const { Meta } = Card;
  const { name, avatarURL, email } = data.user;

  const userURL = `/developers/${id}`;

  return (
    <>
      <Card
        style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
        loading={loading}
      >
        <Meta
          avatar={<Avatar src={avatarURL} />}
          title={<a href={userURL}>{name}</a>}
          description={
            <div>
              <Tag>{email}</Tag>
              {data.user.deleteReason.map((i, key) => (
                <List
                  style={
                    i.active
                      ? { color: "#ff7675", paddingTop: "5px" }
                      : { color: "#8684a6", paddingTop: "5px" }
                  }
                >
                  {i.reason
                    ? `Reason#${key + 1}: ${i.reason?.label}, ${i.createdAt}`
                    : `Reason#${key + 1}: No Reason, ${i.createdAt}`}
                </List>
              ))}
            </div>
          }
        />
      </Card>
      {data.user.deleteReason.map(
        (i, key) =>
          i.active && (
            <div key={key}>
              <Popconfirm
                title="You'r about to DELETE this user permanently!!"
                icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
                onConfirm={handleDelete}
              >
                <Button variant="primary">Delete Permanently</Button>
              </Popconfirm>
              <Popconfirm
                title="Cancel Delete Request?"
                icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
                onConfirm={handleCancel}
              >
                <Button variant="secondary" style={{ marginLeft: 16 }}>
                  Cancel Request
                </Button>
              </Popconfirm>
            </div>
          )
      )}
    </>
  );
}
