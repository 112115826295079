import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import {
  Timeline,
  TimelineItem,
  Avatar,
  RichTextEditor,
  Button,
  HorizantalAlignRightIcon,
  ThumbDownIcon,
  ReplyIcon,
  toRichTextState,
  richTextToMarkdown,
  Tooltip,
} from "tg-design";
import {
  ADD_CONVERSATION,
  UPDATE_CONVERSATION,
  DELETE_CONVERSATION,
  ADD_ACTION_TO_CONVERSATION,
  GET_USER_CONVERSATION,
  MAKE_CONVERSATIONS_SEEN,
} from "../../../../queries/match";
import { GET_RECRUITER } from "../../../../queries/general";
import { captureErrorWithData } from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";
import ConversationItem from "./ConversationItem";
import HistoryItem from "./HistoryItem";
import { MATCH_STATES } from "../../../../constants/states";
import { CONVERSATION_TYPES, USER_TYPES } from "../../../../constants";
import { RowBlock } from "../../../Form/BlockUi";
import NoteModal from "./NoteModal/index";

const tooltipText = (
  <div>
    <div>Apply heading style [1-2]: Ctrl + Shift + [1-2]</div>
    <div>Bold: Ctrl+b for Win, Ctrl+s for Mac</div>
    <div>Italic: Ctrl+i</div>
    <div>Strike through: Ctrl+Shift+x</div>
    <div>Underline: Ctrl+u</div>
    <div>Numbered list: Ctrl+Shift+7</div>
    <div>Bulleted list: Ctrl+Shift+8</div>
  </div>
);

const CommentContainer = styled.div`
  position: relative;
`;

const HISTORY_TYPES = {
  CONVERSATION: "CONVERSATION",
  HISTORY: "HISTORY",
};

const ALLOWED_HISTORY_STATES = [
  MATCH_STATES.COMPANY_ASSESSMENT,
  MATCH_STATES.INTERVIEW,
  MATCH_STATES.SENT_OFFER,
  MATCH_STATES.HIRED,
  MATCH_STATES.PASSED,
  MATCH_STATES.FAILED,
];

const sorter = (a, b) => {
  return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1;
};

export const getHistoryIcon = (history) => {
  if (history.current === MATCH_STATES.FAILED) {
    return <ThumbDownIcon />;
  }

  if (
    history.previous === MATCH_STATES.FAILED &&
    history.current !== MATCH_STATES.FAILED
  ) {
    return <ReplyIcon />;
  }

  return <HorizantalAlignRightIcon />;
};

const toMixedHistory = ({ conversation, history }) => {
  const newConversation = [...conversation];
  newConversation.sort(sorter);
  const items = [
    ...newConversation.map((item) => {
      return {
        ...item,
        itemType: HISTORY_TYPES.CONVERSATION,
      };
    }),
    ...history
      .filter((i) => ALLOWED_HISTORY_STATES.includes(i.current))
      .map((item) => {
        return {
          ...item,
          itemType: HISTORY_TYPES.HISTORY,
          icon: getHistoryIcon(item),
        };
      }),
  ];
  return items.sort(sorter);
};

export default function ConversationTab({ match, onSeenAll }) {
  const [state, setState] = useState({
    isLoading: true,
    note: toRichTextState(""),
    conversation: [],
    history: [],
  });

  const [modalState, setModalState] = useState({ note: null });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { error, refetch: refetchConversation } = useQuery(
    GET_USER_CONVERSATION,
    {
      skip: true,
    }
  );
  const { data: recruiterData } = useQuery(GET_RECRUITER);
  const [addConversation] = useMutation(ADD_CONVERSATION);
  const [updateConversation] = useMutation(UPDATE_CONVERSATION);
  const [deleteConversation] = useMutation(DELETE_CONVERSATION);
  const [addActionToConversation] = useMutation(ADD_ACTION_TO_CONVERSATION);
  const [makeConversationsSeen] = useMutation(MAKE_CONVERSATIONS_SEEN);

  const refreshConversations = async () => {
    const result = await refetchConversation({
      id: match.id,
    });
    setState({
      ...state,
      conversation: result?.data?.match?.conversation,
      note: toRichTextState(""),
      history: result?.data?.match?.history,
      isLoading: false,
    });
  };

  const sendConversationSeen = async ({ conversationIds }) => {
    try {
      await makeConversationsSeen({
        variables: {
          matchId: match.id,
          conversationIds,
        },
      });
      onSeenAll();
    } catch (error) {
      captureErrorWithData(error);
      message.error("An error occurred");
    }
  };

  useEffect(() => {
    refreshConversations();
    // eslint-disable-next-line
  }, [match.id]);

  useEffect(() => {
    const unseenIds = state.conversation
      .filter((i) => i.unseen)
      .map((i) => i.id);
    if (unseenIds.length > 0) {
      sendConversationSeen({ conversationIds: unseenIds });
    }
    // eslint-disable-next-line
  }, [state.conversation]);

  if (!recruiterData || state.isLoading) return "loading";
  if (error) return <PartialError />;

  const CREATOR = {
    avatarURL: recruiterData.recruiter.avatarURL,
    name: recruiterData.recruiter.name,
    id: recruiterData.recruiter.id,
  };

  const handleSubmit = async ({ modalNote }) => {
    try {
      const note = richTextToMarkdown(modalNote || state.note);
      const newState = JSON.parse(JSON.stringify(state.conversation));

      const conversationType =
        state?.conversation?.length === 0 || modalNote
          ? CONVERSATION_TYPES.MEETING
          : CONVERSATION_TYPES.CONVERSATION;

      newState.push({
        creatorType: USER_TYPES.RECRUITER,
        creator: CREATOR,
        createdAt: new Date(),
        note,
        actions: [],
        type: conversationType,
      });

      const result = await addConversation({
        variables: {
          matchId: match.id,
          note,
          type: conversationType,
        },
      });
      setState({
        ...state,
        conversation: result?.data?.addConversation?.conversation || [],
        note: toRichTextState(""),
        type: conversationType,
      });
      if (match?.conversation?.length === 0) {
        await refreshConversations();
      }
    } catch (error) {
      captureErrorWithData(error);
      message.error("An error occurred");
    }
  };

  const handleDelete = async ({ conversation }) => {
    try {
      await deleteConversation({
        variables: {
          matchId: match.id,
          conversationId: conversation.id,
        },
      });

      refreshConversations();
    } catch (error) {
      refreshConversations();
      captureErrorWithData(error);
      message.error("An error occurred");
    }
  };

  const handleEdit = ({ conversation }) => {
    if (conversation.type === CONVERSATION_TYPES.MEETING) {
      setModalState({
        ...modalState,
        note: toRichTextState(conversation.note),
      });
      setIsModalOpen(true);
      return null;
    }
    setState({
      ...state,
      conversation: state.conversation.map((item) => {
        if (item.id === conversation.id) {
          return {
            ...item,
            isEditing: true,
          };
        }
        return item;
      }),
    });
  };

  const cancelEditing = ({ conversation }) => {
    setState({
      ...state,
      conversation: state.conversation.map((item) => {
        if (item.id === conversation.id) {
          return {
            ...item,
            isEditing: false,
          };
        }
        return item;
      }),
    });
  };

  const updateNote = async ({ conversation, editValue }) => {
    setState({
      ...state,
      conversation: state.conversation.map((item) => {
        if (item?.id === conversation?.id) {
          return {
            ...item,
            isEditing: true,
            note: editValue,
          };
        }
        return item;
      }),
    });

    try {
      await updateConversation({
        variables: {
          matchId: match.id,
          conversationId: conversation.id,
          note: editValue,
        },
      });

      refreshConversations();
    } catch (error) {
      captureErrorWithData(error);
      message.error("An error occurred");
    }
  };

  const handleEmojiSelect = async ({ unicode, conversation }) => {
    const { recruiter } = recruiterData;
    const newState = JSON.parse(JSON.stringify(state.conversation));
    const currentConversation = newState.find((i) => i.id === conversation.id);
    const found = currentConversation.actions.find(
      (i) => i.unicode === unicode && i.creator.id === recruiter.id
    );
    if (found) {
      currentConversation.actions = currentConversation.actions.filter(
        (i) => i.unicode !== unicode || i.creator.id !== recruiter.id
      );
    } else {
      currentConversation.actions.push({
        unicode,
        creatorType: USER_TYPES.RECRUITER,
        creator: CREATOR,
      });
    }
    setState({
      ...state,
      conversation: newState,
    });
    try {
      await addActionToConversation({
        variables: {
          matchId: match.id,
          conversationId: conversation.id,
          unicode,
        },
      });
    } catch (error) {
      captureErrorWithData(error);
      message.error("An error occurred!");
    }
  };

  const MIXED_HISTORY = toMixedHistory(state);

  return (
    <Timeline>
      {MIXED_HISTORY.map((item, index) => (
        <div key={index}>
          {item.itemType === HISTORY_TYPES.CONVERSATION && (
            <ConversationItem
              currentUserId={recruiterData?.recruiter?.id}
              conversation={item}
              handleEmojiSelect={handleEmojiSelect}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              cancelEditing={cancelEditing}
              updateNote={updateNote}
              match={match}
            />
          )}
          {item.itemType === HISTORY_TYPES.HISTORY && (
            <HistoryItem history={item} match={match} />
          )}
        </div>
      ))}
      {state?.conversation.length > 0 ? (
        <TimelineItem
          icon={
            <Avatar
              name={recruiterData?.recruiter?.name}
              src={recruiterData?.recruiter?.avatarURL}
              icon={false}
            />
          }
        >
          <CommentContainer>
            <RowBlock justify="flex-end" color="#aaa" padding="0 5px">
              <Tooltip text={tooltipText}>Shortcuts</Tooltip>
            </RowBlock>
            <RichTextEditor
              state={state.note}
              onChange={(note) => setState({ ...state, note })}
              height="100px"
            />
            <Button
              style={{ marginTop: "10px" }}
              onClick={handleSubmit}
              disabled={
                (richTextToMarkdown(state.note) || "").trim().length === 0
              }
            >
              Comment
            </Button>
          </CommentContainer>
        </TimelineItem>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ marginTop: "10px" }}
            onClick={() => setIsModalOpen(true)}
          >
            Add Interview Note
          </Button>
        </div>
      )}
      {isModalOpen && (
        <NoteModal
          match={match}
          tooltipText={tooltipText}
          modalState={modalState}
          conversation={state.conversation}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleSubmit={handleSubmit}
          updateNote={updateNote}
        />
      )}
    </Timeline>
  );
}
