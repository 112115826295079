import { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../components/contexts/AppContext";

export const useAuthToken = () => {
  const removeAuthFlag = () => localStorage.removeItem("isAuth");
  const isAuth = () => localStorage.getItem("isAuth") || false;
  return [removeAuthFlag, isAuth];
};

// TODO: implement apollo Clear Store
export const useLogout = () => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [removeAuthFlag] = useAuthToken();

  const logout = async () => {
    appContext.logout();
    removeAuthFlag();
    history.push("/login");
  };
  return logout;
};
