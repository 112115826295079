import React from "react";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Button } from "tg-design";

export default function TitleSearch({
  confirm,
  setSelectedKeys,
  clearFilters,
  selectedKeys,
}) {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="Search position"
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        value={selectedKeys[0]}
        onPressEnter={() => confirm(selectedKeys)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          onClick={() => confirm(selectedKeys)}
          size="xs"
          style={{ width: 90 }}
        >
          <SearchOutlined />
          Search
        </Button>
        <Button
          onClick={clearFilters}
          variant="secondary"
          outline
          size="xs"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
}
