import styled from "styled-components";

const SettingsContainer = styled.div`
  margin-right: 30px;
  border: 1px solid #ededed;
`;

const Profile = styled.div`
  border: 1px solid #ededed;
  border-radius: 5px;
`;

const ProfileHeader = styled.div`
  background: #f7f7f7;
  padding: 15px;
  display: flex;
  align-items: center;
  & > :first-child {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }
  & > span {
    font-weight: 700;
    font-size: 32px;
    color: #111827;
    margin-left: 15px;
  }
  & > :last-child {
    margin-left: 26px;
  }
`;

const ProfileItem = styled.div`
  padding: 40px 32px;
  border-bottom: 1px solid #ededed;
  display: flex;
  & > span {
    font-weight: 600;
    font-size: 16px;
    flex: 1;
  }
  & > div {
    flex: 2;
  }
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
  & > div {
    display: flex;
    align-items: center;
    & > strong {
      width: 113px;
      font-size: 14px;
      font-weight: 600;
      margin-right: 17px;
    }
    & > span {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const RevokeAccess = styled.div`
  display: flex;
  flex-direction: column;
  & > button {
    width: 170px;
    margin-bottom: 14px;
  }
  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const Permissions = styled.div`
  display: flex;
  flex-direction: column;
  & > label {
    height: inherit !important;
  }
`;

const RadioLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  & input:checked ~ .checkmark {
    background-color: #8fdeac;
  }
  & input ~ .checkmark:after {
    display: block;
  }
  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
  }
`;

const RadioCheckMark = styled.span`
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e5e5e5;
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const RadioTextWrapper = styled.span`
  & > strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
  }
  & > ul {
    margin: 0;
    padding-left: 24px;
  }
  & li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
  }
`;

const CheckboxLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 6px;
  cursor: ${({ disabled }) => !disabled && "pointer"};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & input:checked ~ .checkmark {
    background-color: #8fdeac;
    border-color: #8fdeac;
  }
  & input:disabled ~ .checkmark {
    background-color: #c7c7c7;
    border-color: #c7c7c7;
  }
  & input:checked ~ .checkmark:after {
    display: block;
  }
  & input:disabled ~ .checkmark:after {
    display: block;
  }
  & .checkmark:after {
    left: 2px;
    top: 0px;
    width: 2.5px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxCheckmark = styled.span`
  position: absolute;
  top: 4px;
  left: 0;
  height: 9px;
  width: 9px;
  background-color: white;
  border: 2px solid #c4c4c4;
  border-radius: 1px;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const CheckboxTextWrapper = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  & > strong {
    font-weight: 700;
  }
`;

const MemberPositionContainer = styled.div`
  background-color: #f9fafb;
  margin-left: 35px;
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 5px;
  & > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
    margin-bottom: 10px;
  }
`;

export {
  SettingsContainer,
  Profile,
  ProfileHeader,
  ProfileItem,
  InfoList,
  RevokeAccess,
  Permissions,
  RadioLabel,
  RadioCheckMark,
  RadioTextWrapper,
  CheckboxLabel,
  CheckboxCheckmark,
  CheckboxTextWrapper,
  MemberPositionContainer,
};
