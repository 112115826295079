import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Form, Input, Spin, message } from "antd";
import { Button } from "tg-design";
import { GET_LANGUAGE, UPDATE_LANGUAGE } from "../../queries";
import { TgDrawerTitle } from "../../../tg-styles/Layouts";
import layout from "../../layout";
import { VALIDATION_MESSAGES } from "../../../../constants";
import { captureErrorWithData } from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";

export default function LanguageEdit({ id, handleClose }) {
  const { loading, error, data } = useQuery(GET_LANGUAGE, {
    variables: { id },
  });

  const [updateLanguage] = useMutation(UPDATE_LANGUAGE);

  if (loading) return <Spin />;
  if (error) return <PartialError />;

  const handleSubmit = async (values) => {
    try {
      const variables = {
        id,
        ...values,
      };

      await updateLanguage({ variables });
      message.success("Language Updated");
    } catch (err) {
      captureErrorWithData(err);
      message.error("Something went wrong");
    }
  };

  return (
    <>
      <TgDrawerTitle title="Edit Language" handleClose={handleClose} />
      <Form
        {...layout}
        name="role-edit"
        onFinish={handleSubmit}
        validateMessages={VALIDATION_MESSAGES}
        initialValues={data.language}
      >
        <Form.Item name="label" label="Language" rules={[{ required: true }]}>
          <Input placeholder="Language" />
        </Form.Item>

        <Form.Item>
          <Button type="submit" block size="xs">
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
