import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Label, Input } from "../FormUi";

const Container = styled.div`
  display: block;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;

  :first-child {
    margin-left: 0px;
  }

  :last-child {
    margin-right: 0px;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
`;

const NumericField = ({
  autoFocus,
  defaultValue,
  error,
  label,
  otherStyle,
  onChange,
  status,
  ...props
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(defaultValue);

  const handleOnChange = (e) => {
    const stringValue = e.target.value.replace(/[^0-9]/g, "") || 0;
    const numberValue = Number.parseInt(stringValue, 10);
    setValue(numberValue);
    if (Number.isNaN(numberValue)) {
      return;
    }
    if (value !== numberValue) {
      onChange(numberValue);
    }
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={otherStyle}>
      {label && <Label mb={1}>{label}</Label>}
      <Input
        ref={inputRef}
        type="tel"
        value={value}
        onChange={handleOnChange}
        hasError={status === "error"}
        {...props}
      />
      {error ? (
        <Label my={2} hasError={error}>
          {error}
        </Label>
      ) : null}
    </Container>
  );
};

export default NumericField;
