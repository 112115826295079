import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { PhoneField } from "tg-design";
import { RECRUITER_ROLES } from "../../../constants";
import { captureErrorWithData, useLazyCaller } from "../../../helper";
import FormScaffold from "../../Form/FormScaffold";
import TextField from "../../Form/TextField";
import { UPDATE_RECRUITER } from "../queries";
import UploadRecruiterAvatar from "../UploadRecruiterAvatar";
import { tpProfileValidationSchema } from "../tpProfileValidationSchema";

export function TeamMemberProfile({ recruiter, refetch, loggedInRecruiter }) {
  const [inputStatus, setInputStatus] = useState({});
  const [recruiterState, setRecruiterState] = useState(recruiter);
  const [updateRecruiter] = useMutation(UPDATE_RECRUITER);
  const lazyCaller = useLazyCaller();

  const handleSubmit = async (newState, field) => {
    try {
      setInputStatus({ ...inputStatus, [field]: "loading" });
      await updateRecruiter({
        variables: {
          ...newState,
          [field]: newState[field],
        },
      });
      const newInputStatus = newState[field] ? "success" : "pending";
      setInputStatus({ ...inputStatus, [field]: newInputStatus });
      refetch();
    } catch (error) {
      captureErrorWithData(error);
      setInputStatus({ ...inputStatus, [field]: "error" });
    }
  };

  const validateItem = (field, changes, handleOnValid) => {
    setInputStatus({ ...inputStatus, [field]: "pending" });

    tpProfileValidationSchema.fields[field]
      .validate(changes[field])
      .then(handleOnValid)
      .catch((err) => {
        captureErrorWithData(err);
        setInputStatus({ ...inputStatus, [field]: "error" });
      });
  };

  const applyChange = ({ field, changes }) => {
    validateItem(field, changes, () => {
      const newState = {
        ...recruiterState,
        ...changes,
      };
      setRecruiterState(newState);
      lazyCaller(() => handleSubmit(newState, field), 500);
    });
  };

  const getItemStatus = (name) => {
    if (inputStatus[name]) {
      return inputStatus[name];
    }
    if (recruiter[name]) {
      return "success";
    }

    return "pending";
  };

  const handleUploadStarted = () => {
    setInputStatus({ avatarURL: "pending" });
  };

  const handlePhoto = () => {
    setInputStatus({ avatarURL: "success" });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    applyChange({
      field: name,
      changes: {
        [name]: value,
      },
    });
  };

  const handlePhoneChange = (value) => {
    if (recruiterState.phone === value) {
      return;
    }
    applyChange({
      field: "phone",
      changes: {
        phone: value,
      },
    });
  };

  return (
    <div style={{ maxWidth: "500px" }}>
      <FormScaffold label="Profile Photo" status={getItemStatus("avatarURL")}>
        <UploadRecruiterAvatar
          onStarted={handleUploadStarted}
          handlePhoto={handlePhoto}
          initialPhoto={recruiterState.avatarURL}
          userId={recruiterState.id}
          disabled={loggedInRecruiter?.role === RECRUITER_ROLES.MEMBER}
        />
      </FormScaffold>
      <FormScaffold label="Name" status={getItemStatus("name")}>
        <TextField
          name="name"
          disabled={loggedInRecruiter?.role === RECRUITER_ROLES.MEMBER}
          defaultValue={recruiterState.name}
          onChange={handleOnChange}
        />
      </FormScaffold>
      <FormScaffold label="E-mail" status={getItemStatus("email")}>
        <TextField
          name="email"
          disabled={loggedInRecruiter?.role === RECRUITER_ROLES.MEMBER}
          defaultValue={recruiterState.email}
          onChange={handleOnChange}
        />
      </FormScaffold>
      <FormScaffold label="Phone" status={getItemStatus("phone")}>
        <PhoneField
          name="phone"
          disabled={loggedInRecruiter?.role === RECRUITER_ROLES.MEMBER}
          defaultValue={[recruiterState.phone]}
          onChange={handlePhoneChange}
        />
      </FormScaffold>
    </div>
  );
}
