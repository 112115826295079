import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, message } from "antd";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "tg-design";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useResetFormOnCloseModal } from "../../../company/modules/useResetForm";
import SkillInput from "../skillInput";
import { GET_ALL_VERIFIED_TECH, CHECK_IF_ROLE_EXISTS } from "../../queries";
import {
  captureErrorWithData,
  debounce,
  filterElementsWithSameObjectValuesFromArray,
} from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";

const ModalRoles = ({ visible, onCancel, createRole }) => {
  const [form] = Form.useForm();

  const [state, setState] = useState({ title: "" });
  const [isAlreadyExists, setIsAlreadyExists] = useState(false);

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const { data: allSkillData, error: skillError } = useQuery(
    GET_ALL_VERIFIED_TECH,
    {
      variables: {
        limit: 9999,
        verified: true,
      },
    }
  );

  const [checkIfRoleExistsByTitle, { error: roleCheckError }] = useLazyQuery(
    CHECK_IF_ROLE_EXISTS,
    {
      onCompleted: (data) => {
        if (data?.checkIfRoleExistsByTitle?.title) {
          setIsAlreadyExists(true);
        } else {
          setIsAlreadyExists(false);
        }
      },
      fetchPolicy: "network-only",
    }
  );
  const onOk = async () => {
    const currentValue = form.getFieldsValue();

    if (!currentValue.title) {
      message.error("Title can NOT be empty");
    } else {
      const uniqueSkills = filterElementsWithSameObjectValuesFromArray({
        array: currentValue.skills,
        key: "id",
      });

      const formattedSkills = uniqueSkills.filter((v) => v).map((v) => v.id);

      const variables = {
        title: currentValue.title,
        sort: parseInt(currentValue.sort, 10) || 0,
        skills: formattedSkills,
      };
      try {
        await createRole(variables);
        message.success("Role Created");
        setIsAlreadyExists(false);
      } catch (error) {
        captureErrorWithData(error);
        message.error(error.message || error);
        setIsAlreadyExists(false);
      }

      onCancel();
    }
  };

  const onType = useCallback(
    debounce(async (val) => {
      try {
        await checkIfRoleExistsByTitle({
          variables: { title: val },
        });
      } catch (err) {
        message.error("Something went wrong");
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (state.title) {
      onType(state.title);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getValidateStatus = () => {
    const currentValue = form.getFieldsValue();

    if (!currentValue.title) {
      return { status: "error", message: `'title' can not be empty` };
    }
    if (isAlreadyExists) {
      return {
        status: "error",
        message: `A role with the title '${state.title}' already
      exists.`,
      };
    }
  };

  const handleClose = () => {
    setIsAlreadyExists(false);
    onCancel();
  };

  return (
    <>
      {visible && (
        <Modal handleClose={handleClose}>
          <ModalHeader>Create New Role</ModalHeader>
          <ModalBody>
            {!roleCheckError && !skillError ? (
              <Form form={form} layout="vertical" name="roleForm">
                <Form.Item
                  name="title"
                  label="Role Title"
                  onChange={(e) =>
                    setState({ ...state, title: e.target.value })
                  }
                  validateStatus={getValidateStatus()?.status}
                  help={getValidateStatus()?.message}
                >
                  <Input placeholder="Role Title" />
                </Form.Item>
                <Form.Item name="sort" label="Sorting">
                  <Input placeholder="Sort" type="number" />
                </Form.Item>
                <Form.Item label="Skills" name="skills" initialValue={[]}>
                  <SkillInput allSkillData={allSkillData} />
                </Form.Item>
              </Form>
            ) : (
              <PartialError />
            )}
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onOk} disabled={isAlreadyExists}>
              Save
            </Button>
            <Button onClick={handleClose} outline size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ModalRoles;
