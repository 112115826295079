import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: flex;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 13px;
  width: 14px;
  height: 14px;
  background: ${(props) => (props.checked ? "#8fdeac" : "#fff")};
  border: 1px solid;
  border-color: ${(props) =>
    props.checked ? "#8fdeac" : props.theme.colors.font[1]};
  border-radius: 3px;
  transition: all 150ms;
  margin-right: 15px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px #8fdeac;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const Label = styled.label`
  display: flex;
  font-family: ${(props) => props.theme.family.medium};
  flex-direction: column;
  color: ${(props) =>
    props.hasError ? "#ff7675" : props.theme.colors.font[1]};
  font-size: 12px;
  position: relative;
  text-align: left;
`;

const BlockCheckboxButton = styled.input`
  &[type="radio"] {
    visibility: hidden;
    width: 0px;
  }
  &[type="checkbox"] {
    visibility: hidden;
    width: 0px;
  }
`;

const BlockCheckBoxLabel = styled.label`
  display: flex;
  font-family: ${(props) => props.theme.family.demi};
  border-radius: 5px;
  padding: 10px 17px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  color: #444444;
  font-size: 14px;
  min-width: 280px;
  position: relative;
  width: ${(props) => (props.width ? props.width : "280px")}
  height: 40px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.green[3] : "#f5f6f8"};
`;

const FlatCheckBoxLabel = styled.label`
  display: flex;
  padding: 10px 17px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
`;

const FlatCheckBox = styled.label`
  display: flex;
  font-family: ${(props) => props.theme.family.bold};
  padding: 4px 17px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ isSelected }) => (isSelected ? "white" : "#64b7dc")};
  font-size: 14px;
  position: relative;
  font-weight: 600;
  width: ${(props) => (props.width ? props.width : "290px")};
  height: 40px;
  background-color: ${({ isSelected }) => (isSelected ? "#64b7dc" : "#f5f6f8")};
  margin-bottom: 15px;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const FlatCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;

  :first-child {
    margin-left: 0px;
  }

  :last-child {
    margin-right: 0px;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
`;

export const CheckboxField = ({
  className,
  containerStyles = {},
  checkboxStyles = {},
  ...props
}) => (
  <Label color="font.1">
    <CheckboxContainer className={className} style={containerStyles}>
      <HiddenCheckbox
        name={props.name}
        value={props.value}
        checked={props.value}
        onChange={props.onChange}
      />
      <StyledCheckbox checked={props.value} style={checkboxStyles}>
        <Icon viewBox="0 2 24 24">
          <polyline points="20 6 9 17 4 12" strokeWidth="4" />
        </Icon>
      </StyledCheckbox>
      {props.children}
    </CheckboxContainer>
  </Label>
);

export const BlockCheckboxField = ({ className, ...props }) => {
  return (
    <>
      <BlockCheckboxButton
        type="checkbox"
        {...props}
        checked={props.checked}
        onChange={props.onChange}
      />
      <BlockCheckBoxLabel
        width={props.width}
        name={props.name}
        isSelected={props.checked}
        htmlFor={props.id}
      >
        {props.icon && (
          <StyledCheckbox checked={props.checked}>
            <Icon viewBox="0 3 24 24" style={{ display: "block" }}>
              <polyline points="20 6 9 17 4 12" strokeWidth="4" />
            </Icon>
          </StyledCheckbox>
        )}
        {props.label}
      </BlockCheckBoxLabel>
    </>
  );
};

export const FlatCheckboxField = ({ className, options = [], ...props }) => {
  return (
    <FlatCheckboxContainer {...props}>
      {options &&
        options.map(({ value, label }, index) => (
          <FlatCheckBox
            isSelected={value === props.defaultValue}
            key={index}
            value={value}
            onClick={() => props.onChange({ checked: value, key: props.name })}
            {...props}
          >
            <BlockCheckboxButton
              type="checkbox"
              {...props}
              checked={value === props.defaultValue}
              onChange={props.onChange}
            />
            <FlatCheckBoxLabel
              label={label}
              name={label}
              isSelected={value === props.defaultValue}
              htmlFor={props.id}
            >
              {label}
            </FlatCheckBoxLabel>
          </FlatCheckBox>
        ))}
    </FlatCheckboxContainer>
  );
};
