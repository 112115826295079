import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { ButtonLink } from "tg-design";
import { GET_USERS_WITH_DELETEREASON } from "../queries";
import UserDelete from "./Edit/index";
import { TgDrawer, TgTable } from "../../tg-styles/Layouts";
import DeleteRequestsHeader from "./header";
import PartialError from "../../ErrorPage/PartialError";

const getActiveReason = (deleteReason) => {
  const activeReason = deleteReason.find((i) => i.active);
  return activeReason.createdAt;
};

const sorter = (value) => {
  const activeReason = value.deleteReason.find((i) => i.active);
  return activeReason.createdAt;
};

export default function UsersWithActiveRequestsTable() {
  const [drawerState, setdrawerState] = useState({ visible: false });
  const [selectedId, setSelectedId] = useState(null);

  const [updateQuery, setUpdateQuery] = useState(false);

  const [userData, setUserData] = useState();

  const [getAllUsers, { loading, error }] = useLazyQuery(
    GET_USERS_WITH_DELETEREASON,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const usersWithActiveDeleteRequest =
          data.getAllUsersHasDeleteReason.filter((item) =>
            item.deleteReason.some((i) => i.active)
          );
        setUserData(usersWithActiveDeleteRequest);
      },
    }
  );

  const showDrawer = (id) => {
    setSelectedId(id);
    setdrawerState({
      visible: true,
    });
  };

  const columns = [
    {
      title: "User",
      dataIndex: "name",
      render: (value, record) => (
        <ButtonLink onClick={() => showDrawer(record.id)}>
          {value || "Noname User"}
        </ButtonLink>
      ),
    },
    {
      title: "Created At",
      dataIndex: "deleteReason",
      align: "center",
      render: (value) => dayjs(getActiveReason(value)).format("DD.MM.YYYY"),
      sorter: (a, b) => new Date(sorter(a)) - new Date(sorter(b)),
      defaultSortOrder: "descend",
    },
  ];

  const onClose = () => {
    setdrawerState({
      visible: false,
    });
    setUpdateQuery(true);
  };

  useEffect(() => {
    const fetch = async () => {
      getAllUsers();
    };
    fetch();
    setUpdateQuery(false);
  }, [updateQuery, getAllUsers]);

  if (error) return <PartialError />;

  return (
    <div>
      <DeleteRequestsHeader name="active" />

      {drawerState.visible && (
        <TgDrawer
          closable={false}
          onClose={onClose}
          visible={drawerState.visible}
        >
          <UserDelete id={selectedId} />
        </TgDrawer>
      )}

      <TgTable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={userData || []}
        loading={loading}
      />
    </div>
  );
}
