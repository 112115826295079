/* eslint-disable */
import React, { useState } from "react";
import { message, Popconfirm, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button } from "tg-design";
import { ModalOfficeLocation } from "../../modules/OfficeLocationModal";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { TgTable } from "../../../tg-styles/Layouts";

import {
  UPDATE_OFFICE_LOCATION,
  CREATE_OFFICE_LOCATION,
  DELETE_COMPANY_LOCATION,
} from "../../queries";

export default function LocationsTab({ locations, refetch }) {
  const [createOfficeLocation] = useMutation(CREATE_OFFICE_LOCATION);
  const [updateOfficeLocation] = useMutation(UPDATE_OFFICE_LOCATION);
  const [deleteCompanyLocation] = useMutation(DELETE_COMPANY_LOCATION);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);

  let { companyId } = useParams();
  const showModal = (value) => {
    setSelectedValue(value);
    setIsModalVisible(true);
  };

  const handleOk = (value) => {
    setIsModalVisible(false);
    setSelectedValue();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedValue();
  };

  const handleDelete = async (val) => {
    try {
      const variables = {
        locationId: val.id,
      };
      await deleteCompanyLocation({ variables });
      message.success("Ofice location deleted");
    } catch (error) {
      message.error(`${error}`);
      console.log("error: ", error);
    }
  };

  const updateLocation = async (val) => {
    await updateOfficeLocation({ variables: { ...val, companyId } });
    setSelectedValue();
    refetch();
  };

  const createLocation = async (val) => {
    await createOfficeLocation({ variables: { ...val, companyId } });
    setSelectedValue();
    refetch();
  };

  const locationColumns = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "City",
      dataIndex: "place",
      render: (place) => (
        <span>{place ? `${place.city}, ${place.country}` : ""}</span>
      ),
    },
    {
      title: "Lat",
      dataIndex: "location",
      render: (location) => (
        <span>{location ? location.coordinates[1] : ""}</span>
      ),
    },
    {
      title: "Lon",
      dataIndex: "location",
      render: (location) => (
        <span>{location ? location.coordinates[0] : ""}</span>
      ),
    },
    {
      title: "Full Address",
      dataIndex: "fullAddress",
      width: "25%",
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "x",
      render: (val) => (
        <Button outline size="xs" onClick={() => showModal(val)}>
          Edit
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "x",
      render: (val) => (
        <Button variant="danger" outline size="xs">
          <Popconfirm
            title="You'r about to DELETE this location permanently!"
            icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleDelete(val)}
          >
            <a style={{ color: "inherit" }} href="#">
              Delete
            </a>
          </Popconfirm>
        </Button>
      ),
    },
  ];
  return (
    <>
      <TgTable
        style={{ maxWidth: "600px" }}
        columns={locationColumns}
        dataSource={locations}
        pagination={false}
        rowKey="id"
      />
      {isModalVisible && (
        <ModalOfficeLocation
          visible={true}
          onCancel={handleCancel}
          handleOk={handleOk}
          selectedValue={selectedValue}
          updateLocation={updateLocation}
          createLocation={createLocation}
        />
      )}
      <br />
      <Button onClick={() => showModal()}>Add New Location</Button>
    </>
  );
}
