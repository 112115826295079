import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { Description } from "./style";

export default function EmptyWorkHistoryModal({ onClose: closeModal, user }) {
  const handleGoLinkedinSync = () => {
    return window.open(
      `/developers/${user.id}?tab=linkedin`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Modal handleClose={closeModal}>
      <ModalHeader>
        <Description>Developer&apos;s work history is empty!</Description>
      </ModalHeader>
      <ModalBody>
        To change state of this match, you have to add work history to user via
        LinkedIn Sync
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleGoLinkedinSync} size="sm">
          Go to LinkedIn Sync
        </Button>
        <Button variant="secondary" outline size="sm" onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
