import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "tg-design";
import { UPLOAD_IMAGE, DELETE_COMPANY_LOGO } from "../queries";
import { captureErrorWithData } from "../../../helper";

export default function UploadLogo({
  handleLogo,
  initialLogo,
  companyId,
  onStarted,
  onError,
}) {
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [deleteImage] = useMutation(DELETE_COMPANY_LOGO);
  const [state, setState] = useState({
    uploading: false,
    fileList: [],
  });

  useEffect(() => {
    if (initialLogo) {
      const initialFileList = {
        url: initialLogo,
        uid: "-1",
        name: "logo.png",
        status: "done",
      };
      setState({ ...state, fileList: [initialFileList] });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initialLogo]);

  const handleRemove = async (img) => {
    try {
      if (onStarted) {
        onStarted();
      }
      setState({ ...state, uploading: true });
      await deleteImage({
        variables: { imageUrl: img.url, company: companyId },
      });
      const newFileList = state.fileList.filter(
        (photo) => photo.uid !== img.uid
      );
      setState({ uploading: false, fileList: newFileList });
      handleLogo(null);
      message.success("Logo deleted");
    } catch (error) {
      captureErrorWithData(error);
      setState({ ...state, uploading: false });
      message.error("Logo deletion failed");
      if (onError) {
        onError();
      }
    }
  };

  const handleUploadLogo = async (uploadFile) => {
    const { fileList } = state;
    setState({ ...state, uploading: true });

    try {
      if (onStarted) {
        onStarted();
      }

      const res = await uploadImage({
        variables: { image: uploadFile.file },
      });

      const updatedFileList = fileList.map((file) => {
        if (file.uid === uploadFile.file.uid) {
          file.url = res.data.uploadCompanyImage.imageUrl;
          file.status = "done";
        }
        return file;
      });
      setState({ fileList: updatedFileList, uploading: false });
      handleLogo(res.data.uploadCompanyImage.imageUrl);
      message.success("Logo uploaded");
    } catch (error) {
      captureErrorWithData(error);
      setState({ ...state, uploading: false });
      message.error("Logo upload failed");
      if (onError) {
        onError();
      }
    }
  };

  const beforeUpload = (file) => {
    setState(() => ({
      fileList: [file],
    }));
    return true;
  };

  return (
    <Upload
      fileList={state.fileList}
      loading={state.uploading}
      onRemove={handleRemove}
      beforeUpload={beforeUpload}
      customRequest={handleUploadLogo}
      multiple={false}
      accept="image/*"
    >
      <Button loading={state.uploading} outline size="xs">
        <UploadOutlined />
        Upload Logo
      </Button>
    </Upload>
  );
}
