import React from "react";
import DeepDrawer from "../module/DeepDrawer";
import MatchSideBar from "./Sidebar";
import Notes from "../position/detail/Notes";
import FailReasons from "../position/detail/FailReasons";

export default function MatchDetailDrawer({
  matchId,
  handleChangeStatus,
  setUpdateCardId,
  setNextMatch,
  customFlows,
  updateMatchState,
  history,
}) {
  return (
    <DeepDrawer
      base={
        <MatchSideBar
          id={matchId}
          history={history}
          setUpdateCardId={setUpdateCardId}
          handleChangeStatus={handleChangeStatus}
          setNextMatch={setNextMatch}
          customFlows={customFlows}
          updateMatchState={updateMatchState}
        />
      }
      notes={{
        component: <Notes id={matchId} />,
      }}
      failReasons={{
        title: "Fail Reasons",
        component: <FailReasons id={matchId} />,
      }}
    />
  );
}
