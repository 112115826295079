import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import {
  MATCH_STATES,
  FLAGS,
  POSITION_STATES,
  MATCH_CREATION_TYPES,
} from "../../../../constants";
import {
  LinkedInIcon,
  ShuffleIcon,
  FlagIconSmall,
  CircleCheckIcon,
  WarningSignIcon,
  SearchIcon,
} from "../../../Icons";
import { removeDuplicateFlags } from "../../../../helper";

export const LinkedInLink = ({ link, name }) => {
  if (link) {
    return /^https?:\/\//.test(link) ? (
      <a
        className="no-drawer"
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        onClick={(e) => e.stopPropagation()}
      >
        <LinkedInIcon />
      </a>
    ) : (
      <a
        className="no-drawer"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://${link}`}
        onClick={(e) => e.stopPropagation()}
      >
        <LinkedInIcon />
      </a>
    );
  }

  return (
    <a
      className="no-drawer"
      target="_blank"
      href={`https://www.linkedin.com/search/results/all/?keywords=${name}`}
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <LinkedInIcon color="#999" />
    </a>
  );
};

const BadgeContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledBadge = styled.a`
  border-radius: 5px;
  padding: 0px 2px;
  display: flex;
  cursor: pointer;
`;

const BatchCount = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin-left: auto;
  border: 1px solid #a2daf1;
  background-color: #f5fafd;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;

  &.yellow {
    border: 1px solid #fad156;
    background-color: #fef9ea;
  }
`;

export const getActiveCompanyMatchCountWithoutCurrentMatch = (match) => {
  const COMPANY_STATES = [
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.HIRED,
  ];
  const POSITION_STATUS = [
    POSITION_STATES.CLOSED,
    POSITION_STATES.CANCELED,
    POSITION_STATES.ON_HOLD,
    POSITION_STATES.FILLED,
  ];
  const activeCompanyMatchCount = match?.user?.activeCompanyMatchCount || 0;
  if (activeCompanyMatchCount === 0) {
    return activeCompanyMatchCount;
  }

  // Eğer mevcut state de aktif company state'lerinden herhangi biriyse, sayıyı
  // 1 azaltmamız lazım.
  if (
    COMPANY_STATES.includes(match.state) &&
    !POSITION_STATUS.includes(match.position.state)
  ) {
    return activeCompanyMatchCount - 1;
  }

  return activeCompanyMatchCount;
};

export const Badge = ({
  backgroundColor = "#FF7675",
  icon,
  tooltip,
  ...props
}) => {
  return (
    <>
      <Tooltip title={tooltip}>
        <StyledBadge style={{ backgroundColor }} target="_blank" {...props}>
          {icon}
        </StyledBadge>
      </Tooltip>
    </>
  );
};

export default function MatchPreview({ match }) {
  const activeCompanyMatchCount =
    getActiveCompanyMatchCountWithoutCurrentMatch(match);

  const isTest = match?.user?.isTest;

  const getUserName = () => {
    return (
      <>
        <span>{match?.user?.name || match?.user?.email} </span>
        {isTest && <span style={{ color: "#ff7675" }}>-Test-</span>}
      </>
    );
  };

  return (
    <>
      <div style={{ display: "flex", marginBottom: "5px" }}>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {getUserName() || "Noname"}
          {match?.user?.isVerifiedByTP && <CircleCheckIcon />}
        </div>

        {match.state !== MATCH_STATES.EXTERNAL_SOURCING && (
          <BatchCount
            title="Batch count"
            className={!match.batch ? "yellow" : ""}
          >
            {match.creationType === MATCH_CREATION_TYPES.FORCE_MATCH.key
              ? MATCH_CREATION_TYPES.FORCE_MATCH.short
              : ""}
            {match.creationType === MATCH_CREATION_TYPES.LANDING_PAGE.key
              ? MATCH_CREATION_TYPES.LANDING_PAGE.short
              : ""}
            {match.creationType === MATCH_CREATION_TYPES.REFERRAL.key
              ? MATCH_CREATION_TYPES.REFERRAL.short
              : ""}
            {match.creationType === MATCH_CREATION_TYPES.MARKETING_PAGE.key
              ? MATCH_CREATION_TYPES.MARKETING_PAGE.short
              : ""}
            {match.creationType === MATCH_CREATION_TYPES.BATCH.key
              ? match?.batch?.index
              : ""}
          </BatchCount>
        )}
      </div>
      <div style={{ display: "flex", marginBottom: "-3px" }}>
        <div style={{ fontSize: 12 }}>
          {match.user?.role?.title || "Missing role"}
        </div>
        <div style={{ marginLeft: "auto" }}>
          <LinkedInLink
            link={match?.user?.social?.linkedin}
            name={match?.user?.name}
          />
        </div>
      </div>
      <BadgeContainer>
        {match?.user?.flags?.length > 0
          ? removeDuplicateFlags(match.user.flags).map((type) => {
              return (
                <Badge
                  href={`/developers/${match.user.id}?tab=internalNotes`}
                  onClick={(e) => e.stopPropagation()}
                  icon={
                    <FlagIconSmall
                      colors={FLAGS.find((item) => type === item.type).colors}
                    />
                  }
                  backgroundColor="none"
                />
              );
            })
          : ""}
        {activeCompanyMatchCount > 0 && (
          <Badge
            href={`/developers/${match.user.id}?tab=matches&filters_state=COMPANY_ASSESSMENT,INTERVIEW,SENT_OFFER,HIRED&filters_position_state=CREATED,SUBMITTED,IN_PROGRESS`}
            onClick={(e) => e.stopPropagation()}
            icon={<ShuffleIcon />}
          />
        )}
        {match.failAttemptToGreenhouse && <WarningSignIcon />}
        {match?.user?.preferences.openToWork && (
          <Badge
            onClick={(e) => e.stopPropagation()}
            icon={<SearchIcon size={14} color="#8FDEAC" />}
            title="Actively looking for a job"
            backgroundColor="#F1FBF5"
          />
        )}
      </BadgeContainer>
    </>
  );
}
