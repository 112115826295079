import React from "react";
import { Button } from "tg-design";
import { SectionTitle } from "../styles";
import { LanguageCard } from "../Cards";
import EmptyData from "../../../../../common/EmptyData";

export default function LanguageSection({
  state,
  handleNew,
  handleDelete,
  handleEdit,
}) {
  return (
    <>
      <SectionTitle>
        Languages{" "}
        <Button variant="secondary" size="xs" outline onClick={handleNew}>
          Add
        </Button>
      </SectionTitle>

      {(!state.languages || state.languages.length === 0) && <EmptyData />}

      {state.languages &&
        state.languages.map((item, index) => (
          <LanguageCard
            key={index}
            data={item}
            onDelete={() => handleDelete(index)}
            onEdit={() => handleEdit(item, index)}
          />
        ))}
    </>
  );
}
