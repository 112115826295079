import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthToken } from "../config/auth";

import { TgLayout } from "../components/tg-styles/Layouts";

const { Content } = TgLayout;

const PublicLayout = ({ component: Component, restricted, ...rest }) => {
  const [, isAuth] = useAuthToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() && restricted ? (
          <Redirect to="/" />
        ) : (
          <TgLayout style={{ minHeight: "100vh", marginLeft: "0px" }}>
            <TgLayout className="site-layout" style={{ marginLeft: "0px" }}>
              <Content style={{ margin: "25px 16px" }}>
                <Component {...props} />
              </Content>
            </TgLayout>
          </TgLayout>
        )
      }
    />
  );
};

export default PublicLayout;
