import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Avatar, message } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import { Button } from "tg-design";
import { CREATE_FLAG, UPDATE_FLAG, DELETE_FLAG } from "../../../queries";
import { ColumnBlock, RowBlock } from "../../../../Form/BlockUi";
import CreateOrUpdateFlag from "../../../drawers/CreateOrUpdateFlag";
import { TgDrawer, TgButtonField } from "../../../../tg-styles/Layouts";
import { FlagIcon } from "../../../../Icons";
import { capitalize, captureErrorWithData } from "../../../../../helper";
import GridList from "./GridListMenu";
import { FLAGS } from "../../../../../constants";

const FlagNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  background: #f9fafb;
  padding: 20px;
  width: 100%;
`;

const FlagNoteTitle = styled.div`
  margin-top: 20px;
`;

const FlagNoteContent = styled.span`
  font-weight: 400;
  margin: 20px 0;
`;

const CreatorName = styled.span`
  color: #111827;
  font-weight: 700;
`;

const FlagNoteDate = styled.span`
  color: #6b7280;
  font-size: 12px;
`;

const IconContainer = styled.div`
  margin-left: auto;
`;

export default function FlagTab({ user, sidebarView }) {
  const history = useHistory();
  const [drawerContent, setDrawer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [flags, setFlags] = useState(user.flags || []);

  const [addFlagOnUser] = useMutation(CREATE_FLAG);
  const [updateFlagOnUser] = useMutation(UPDATE_FLAG);
  const [deleteFlagOnUser] = useMutation(DELETE_FLAG);

  const getAllFlags = () => {
    const allFlags = [];

    flags.map((flag) => allFlags.push({ flag }));

    return allFlags.sort(
      (a, b) => new Date(b.flag.updatedAt) - new Date(a.flag.updatedAt)
    );
  };

  const onDrawerClosed = () => {
    setDrawer(null);
  };

  const handleSubmit = async (flagNote) => {
    if (!flagNote.note || flagNote?.note?.length === 0) {
      return message.error("Note section can NOT be empty");
    }
    if (!flagNote.type) {
      return message.error("Flag State can NOT be empty");
    }
    if (!flagNote.isNew) {
      try {
        const result = await addFlagOnUser({
          variables: {
            userId: user.id,
            note: flagNote.note,
            type: flagNote.type,
          },
        });
        if (result.data.addFlagOnUser.flags) {
          setFlags(result.data.addFlagOnUser.flags);
        }
        message.success("New Internal Note created succesfully");
        onDrawerClosed();
      } catch (error) {
        message.error(error);
        captureErrorWithData(error);
      }
    }
    if (flagNote.isNew) {
      try {
        const result = await updateFlagOnUser({
          variables: {
            userId: user.id,
            flagId: flagNote.flagId,
            note: flagNote.note,
            type: flagNote.type,
          },
        });
        if (result.data.updateFlagOnUser.flags) {
          setFlags(result.data.updateFlagOnUser.flags);
        }
        message.success("New Internal Note created succesfully");
        onDrawerClosed();
      } catch (error) {
        message.error(error);
        captureErrorWithData(error);
      }
    }
  };

  const handleEdit = (flag) => {
    setDrawer(
      <CreateOrUpdateFlag
        onClose={onDrawerClosed}
        initialValues={flag}
        handleSubmit={handleSubmit}
      />
    );
  };

  const handleDelete = async (flagId) => {
    setLoading(true);
    try {
      const result = await deleteFlagOnUser({
        variables: {
          userId: user.id,
          flagId,
        },
      });
      if (result.data.deleteFlagOnUser.flags) {
        setFlags(result.data.deleteFlagOnUser.flags);
      }
      message.success("Internal Note deleted succesfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error);
      captureErrorWithData(error);
    }
  };

  useEffect(() => {
    const { hash } = history.location;

    if (hash.includes("draweropen")) {
      setDrawer(
        <CreateOrUpdateFlag
          onClose={onDrawerClosed}
          handleSubmit={handleSubmit}
        />
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {drawerContent && (
        <TgDrawer closable={false} onClose={onDrawerClosed} visible>
          {drawerContent}
        </TgDrawer>
      )}
      <TgButtonField>
        <Button
          outline={sidebarView}
          onClick={() =>
            sidebarView
              ? window.open(
                  `/developers/${user.id}?tab=internalNotes#draweropen`,
                  "_blank"
                )
              : setDrawer(
                  <CreateOrUpdateFlag
                    onClose={onDrawerClosed}
                    handleSubmit={handleSubmit}
                  />
                )
          }
        >
          Create a New Internal Note
        </Button>
      </TgButtonField>
      {getAllFlags().map((item) => {
        const { flag } = item;
        const { colors } = FLAGS.find((item) => flag.type === item.type);
        return (
          <RowBlock
            key={flag.id}
            wrap="nowrap"
            justifyContent="start"
            alignItems="start"
            mt={4}
          >
            <FlagNoteContainer>
              <RowBlock gridGap="10px">
                {flag.creator.avatarURL ? (
                  <Avatar size="large" src={flag.creator.avatarURL} />
                ) : (
                  <Avatar
                    size="large"
                    src={`${process.env.PUBLIC_URL}/default_avatar.png`}
                  />
                )}
                <ColumnBlock>
                  <CreatorName>{flag.creator.name}</CreatorName>
                  <FlagNoteDate>
                    {dayjs(flag?.updatedAt).format("DD.MM.YYYY")}
                  </FlagNoteDate>
                </ColumnBlock>
                <IconContainer>
                  <FlagIcon colors={colors} />
                </IconContainer>
              </RowBlock>

              <FlagNoteTitle>
                <span style={{ color: colors.color }}>
                  {capitalize(flag.type)} flagged{" "}
                </span>
                note written to users profile.
              </FlagNoteTitle>

              <FlagNoteContent
                dangerouslySetInnerHTML={{
                  __html: flag.note,
                }}
              />
            </FlagNoteContainer>
            {!sidebarView && (
              <GridList
                handleEdit={() => handleEdit(flag)}
                handleDelete={() => handleDelete(flag.id)}
                loading={loading}
              />
            )}
          </RowBlock>
        );
      })}
    </div>
  );
}
