import ReactSelect from "react-select";
import styled from "styled-components";
import { space } from "styled-system";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

export const StyledSelect = styled(ReactSelect)`
  ${space}
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
  ${space}
`;

export const StyledAsyncSelect = styled(AsyncSelect)`
  ${space}
`;

export const StyledAsyncCreatableSelect = styled(AsyncCreatableSelect)`
  ${space}
`;
