import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { TGToast } from "tg-design";
import { ThemeProvider } from "styled-components";
import { useAppApolloClient } from "./config/apolloClient";
import routes from "./router";
import PublicLayout from "./layouts/PublicLayout";
import AuthLayout from "./layouts/AuthLayout";
import theme from "./styles/theme";
import "./styles/app.css";
import WebSocketProvider from "./WebSocket";
import AppProvider from "./AppProvider";
import ErrorBoundary from "./utils/ErrorBoundary";

function App() {
  const client = useAppApolloClient();
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <WebSocketProvider>
          <BrowserRouter>
            <AppProvider>
              <ErrorBoundary>
                <Switch>
                  {routes
                    .filter((r) => r.deprecated)
                    .map((route, index) => (
                      <Redirect
                        key={index}
                        from={route.path}
                        to={route.redirect}
                      />
                    ))}
                  {routes
                    .filter((r) => r.needAuth)
                    .map((route, index) => (
                      <AuthLayout
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        header={route.header}
                        component={route.content}
                        props={route.props || {}}
                      />
                    ))}
                  {routes
                    .filter((r) => !r.needAuth)
                    .map((route, index) => (
                      <PublicLayout
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        header={route.header}
                        component={route.content}
                        props={route.props}
                      />
                    ))}
                </Switch>
              </ErrorBoundary>
            </AppProvider>
          </BrowserRouter>
        </WebSocketProvider>
      </ApolloProvider>
      <TGToast />
    </ThemeProvider>
  );
}

export default App;
