import React from "react";
import { Textarea } from "tg-design";

export default function DescriptionStep({ onChange }) {
  return (
    <Textarea
      name="description"
      rows="24"
      placeholder="Add a description"
      onChange={onChange}
    />
  );
}
