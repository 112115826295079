import React from "react";
import { Link } from "react-router-dom";
import { TgHeader, TgCard, TgCardWrapper } from "../tg-styles/Layouts";

import {
  RolesIcon,
  UsersIcon,
  EducationIcon,
  LanguagesIcon,
  TechnologiesIcon,
} from "../Icons";

export default function Tools() {
  return (
    <div>
      <TgHeader breadCrumbs={[{ title: "Tools", link: "/tools" }]} />
      <TgCardWrapper>
        <Link to="/tools/roles">
          <TgCard justify="start" align="center">
            <span style={{ marginLeft: "28px" }}>
              <RolesIcon />
            </span>
            <span style={{ marginLeft: "20px" }}>Roles</span>
          </TgCard>
        </Link>
        <Link to="/tools/active-delete-requests">
          <TgCard justify="start" align="center">
            <span style={{ marginLeft: "28px" }}>
              <UsersIcon />
            </span>
            <span style={{ marginLeft: "20px" }}>Users</span>
          </TgCard>
        </Link>
        <Link to="/tools/education/universities">
          <TgCard justify="start" align="center">
            <span style={{ marginLeft: "28px" }}>
              <EducationIcon />
            </span>
            <span style={{ marginLeft: "20px" }}>Education</span>
          </TgCard>
        </Link>
        <Link to="/tools/languages">
          <TgCard justify="start" align="center">
            <span style={{ marginLeft: "28px" }}>
              <LanguagesIcon />
            </span>
            <span style={{ marginLeft: "20px" }}>Languages</span>
          </TgCard>
        </Link>
        <Link to="/tools/technologies">
          <TgCard justify="start" align="center">
            <span style={{ marginLeft: "28px" }}>
              <TechnologiesIcon />
            </span>
            <span style={{ marginLeft: "20px" }}>Technologies</span>
          </TgCard>
        </Link>
        <Link to="/tools/documents">
          <TgCard justify="start" align="center">
            <span style={{ marginLeft: "28px" }}>
              <TechnologiesIcon />
            </span>
            <span style={{ marginLeft: "20px" }}>Documents</span>
          </TgCard>
        </Link>
      </TgCardWrapper>
    </div>
  );
}
