import React from "react";
import { selectStyles } from "./SelectStyles";
import { Label } from "../FormUi";
import { ColumnBlock } from "../BlockUi";
import { StyledSelect } from "./styles";
import IsRequired from "../IsRequired";

export default function SelectField({
  textAlign,
  valueAlign,
  optionStyle,
  selectedBgColorful,
  bgColor,
  isRequired = false,
  hasError = false,
  ...props
}) {
  const { label, width = "100%", flex } = props;
  return (
    <ColumnBlock width={width} flex={flex}>
      {label && (
        <Label mb={1}>
          {label} {isRequired && <IsRequired />}
        </Label>
      )}
      <StyledSelect
        {...props}
        styles={selectStyles({
          textAlign,
          valueAlign,
          optionStyle,
          selectedBgColorful,
          bgColor,
          hasError,
        })}
      />
    </ColumnBlock>
  );
}
