import React from "react";
import { Switch } from "antd";
import { PhoneField } from "tg-design";
import Textarea from "../../../../../Form/Textarea";
import { SectionTitle } from "../styles";
import TextField from "../../../../../Form/TextField";
import FormScaffold from "../../../../../Form/FormScaffold";
import UploadPhoto from "./UploadPhoto";

export default function ProfileInfoSection({
  state,
  onChange,
  getInputStatus,
  setInputStatus,
}) {
  const handleUploadStated = () => {
    setInputStatus({ name: "avatarURL", value: "pending" });
  };

  const handlePhoto = () => {
    setInputStatus({ name: "avatarURL", value: "success" });
  };

  return (
    <>
      <SectionTitle>Profile Info</SectionTitle>
      <FormScaffold label="Profile Photo" status={getInputStatus("avatarURL")}>
        <UploadPhoto
          handlePhoto={handlePhoto}
          initialPhoto={state.avatarURL}
          userId={state.id}
          onStarted={handleUploadStated}
        />
      </FormScaffold>
      <FormScaffold label="Name Surname" status={getInputStatus("name")}>
        <TextField
          name="name"
          defaultValue={state.name}
          onChange={(event) =>
            onChange({ name: "name", value: event.target.value })
          }
        />
      </FormScaffold>
      <FormScaffold label="Phone" status={getInputStatus("phone")}>
        <PhoneField
          name="phone"
          defaultValue={[state.phone]}
          onChange={(value) => onChange({ name: "phone", value })}
        />
      </FormScaffold>
      <FormScaffold label="Biography" status={getInputStatus("bio")}>
        <Textarea
          name="bio"
          defaultValue={state.bio}
          onChange={(event) =>
            onChange({ name: "bio", value: event.target.value })
          }
          rows="5"
        />
      </FormScaffold>
      <FormScaffold
        style={{ margin: "30px 0 50px 0" }}
        label={
          <>
            <span style={{ marginRight: "15px" }}>Actively Looking</span>
            <Switch
              onChange={(value) =>
                onChange({ name: "preferences.openToWork", value })
              }
              checked={state.preferences.openToWork}
            />
          </>
        }
      />
    </>
  );
}
