import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Tag } from "antd";
import { GET_COMPANY } from "../queries";
import { handleHead } from "../../../helper";
import { TgHeader } from "../../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../../tg-styles/TgHeaderTabs";
import {
  PositionTab,
  GeneralInfoTab,
  TechStackTab,
  LocationsTab,
  ContactsTab,
  TgNotesTab,
} from "./tabs";
import PartialError from "../../ErrorPage/PartialError";

export default function CompanyDetail() {
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "general"
  );
  const { companyId } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_COMPANY, {
    variables: { id: companyId },
  });

  if (loading) return "loading";
  if (error) return <PartialError />;

  const { logo, name, positions, isTest, hasGreenhouseIntegration } =
    data.company;
  handleHead("companyDetail", name);

  const getTab = () => {
    switch (currentTab) {
      case "position":
        return (
          <PositionTab
            positions={positions}
            refetch={refetch}
            company={data.company}
          />
        );
      case "general":
        return <GeneralInfoTab company={data.company} refetch={refetch} />;
      case "tech-stack":
        return <TechStackTab company={data.company} refetch={refetch} />;
      case "contacts":
        return (
          <ContactsTab contacts={data.company.contacts} refetch={refetch} />
        );
      case "locations":
        return (
          <LocationsTab locations={data.company.locations} refetch={refetch} />
        );
      case "notes":
        return (
          <TgNotesTab
            notes={data.company.tgNotes}
            id={data.company.id}
            refetch={refetch}
          />
        );
      default:
        setCurrentTab("general");
        break;
    }
  };

  const getCompanyTitle = () => {
    return (
      <>
        <span>{name} </span>
        {isTest && <span style={{ color: "#ff7675" }}>-Test-</span>}
      </>
    );
  };

  return (
    <div>
      <TgHeader
        title={getCompanyTitle() || "No name"}
        logo={logo}
        breadCrumbs={[
          { title: "Companies", link: "/companies" },
          { title: name, link: `/companies/${companyId}` },
        ]}
        actionComponent={
          <>
            <Tag color="blue">
              {data?.company?.clientPartner?.name || "No Assignee"}
            </Tag>
            <>
              {hasGreenhouseIntegration && (
                <Tag style={{ marginTop: "8px" }} color="green">
                  Connected Greenhouse
                </Tag>
              )}
            </>
          </>
        }
      >
        <TgHeaderTabs currentTab={currentTab} handleChange={setCurrentTab}>
          <TgHeaderTab name="position">Positions</TgHeaderTab>
          <TgHeaderTab name="general">General Info</TgHeaderTab>
          <TgHeaderTab name="tech-stack">Tech Stack</TgHeaderTab>
          <TgHeaderTab name="contacts">Contacts</TgHeaderTab>
          <TgHeaderTab name="locations">Locations</TgHeaderTab>
          <TgHeaderTab name="notes">TG Notes</TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>
      <div style={{ paddingLeft: "10px" }}>{getTab()}</div>
    </div>
  );
}
