import React from "react";
import { TimelineHistory } from "tg-design";
import { MATCH_ACTIVITY_TYPES } from "../../../../constants";
import { Username } from "../ConversationTab/HistoryItem";
import { SolidEyeIcon } from "../../../Icons";

const getActivityType = (type) => {
  const activity = MATCH_ACTIVITY_TYPES.find((i) => i.value === type);
  return activity?.key;
};

export default function ActivityItem({ activity }) {
  const creator = activity.creator || { name: "Somebody" };
  return (
    <TimelineHistory createdAt={activity.createdAt} icon={<SolidEyeIcon />}>
      <Username>{creator?.name}</Username> viewed{" "}
      <i>{getActivityType(activity.type)}</i>.
    </TimelineHistory>
  );
}
