import styled from "styled-components";

export default styled.div`
  display: block;
  background-color: #c7c7c7;
  width: auto;
  height: auto;
  min-width: 28px;
  min-height: 28px;
  border-radius: 50%;
  padding: 4px 4px 4px 4px;
  margin-left: 10px;
  margin-top: -4px;
  text-align: center;
`;
