import React from "react";
import styled from "styled-components";
import { Button } from "tg-design";
import TextField from "../Form/TextField";

const Container = styled.div`
  display: flex;
  gap: 0px;

  & input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 42px;
  }

  & button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;

export default function SearchLine({ value, onChange, handleSearchClick }) {
  const handleEnter = ({ key }) => {
    if (key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <Container>
      <TextField
        placeholder="Name or email address"
        otherStyle={{ flexGrow: 4 }}
        value={value}
        onChange={onChange}
        onKeyDown={handleEnter}
      />
      <Button size="sm" onClick={handleSearchClick}>
        Search
      </Button>
    </Container>
  );
}
