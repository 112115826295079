import styled from "styled-components";

export const Separator = styled.div`
  padding: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #9ca3af;
`;

export const GroupContainer = styled.div`
  border: 1px solid #64b7dc;
  box-sizing: border-box;
  border-radius: 5px;
  width: 500px;
  height: 100%;
  margin: 10px 0px;
  cursor: auto;
`;

export const AddRowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  width: 37px;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  background: white;
`;
export const AddGroupButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid #64b7dc;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
`;
