import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { Link } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { Button, ButtonLink } from "tg-design";
import { handleHead } from "../../helper";
import CreateCompanyForm from "./drawers/CreateCompanyForm";

import {
  TgTable,
  TgHeader,
  TgDrawer,
  TgButtonField,
  TgSearch,
} from "../tg-styles/Layouts";
import PartialError from "../ErrorPage/PartialError";
import { SORTERS } from "../../constants";

const GET_ALL_COMPANY = gql`
  query allCompany(
    $limit: Int
    $search: String
    $page: Int
    $sort: companySortInputType
  ) {
    allCompany(limit: $limit, search: $search, page: $page, sort: $sort) {
      companies {
        id
        name
        clientPartner {
          name
        }
        logo
        positions {
          id
        }
        isTest
      }
      currentPage
      totalCount
      totalPages
    }
  }
`;

const columns = [
  {
    title: "Company Name",
    dataIndex: "name",
    render: (value, record) => (
      <Link to={`/companies/${record.id}`}>
        <img src={record.logo} alt="Company Logo" />
        {value}{" "}
        {record.isTest && (
          <span style={{ color: "#ff7675", paddingLeft: "5px" }}>-Test-</span>
        )}
      </Link>
    ),
  },
  {
    title: "Position Count",
    dataIndex: "positions",
    render: (value) => value.length,
  },
  {
    title: "Client Partner",
    dataIndex: "clientPartner",
    render: (value) => value?.name || null,
  },
];

const sort = {
  createdAt: SORTERS.descend,
};

export default function Companies() {
  const [getCompany, { loading, data, error }] = useLazyQuery(GET_ALL_COMPANY, {
    fetchPolicy: "network-only",
  });
  const [search, setSearch] = useState("");
  const [tableKey, setTableKey] = useState(0);
  const [drawerContent, setDrawer] = useState(null);
  const [form] = Form.useForm();
  handleHead("companies");

  useEffect(() => {
    (async () => {
      await getCompany({
        query: GET_ALL_COMPANY,
        variables: {
          search,
          limit: 10,
          page: 1,
          sort,
        },
      });
    })();
    setTableKey(tableKey + 1);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [search]);

  const onChange = async (pagination, filters, sorter, extra) => {
    if (extra.action === "paginate") {
      await getCompany({
        query: GET_ALL_COMPANY,
        variables: {
          search,
          limit: 10,
          page: pagination.current,
          sort,
        },
      });
    }
  };

  const onReset = () => {
    form.resetFields();
    setSearch("");
  };

  const onDrawerClosed = () => {
    setDrawer(null);
  };

  if (error) return <PartialError />;

  return (
    <div>
      <TgHeader breadCrumbs={[{ title: "Companies", link: "/companies" }]} />
      {drawerContent && (
        <TgDrawer closable={false} onClose={onDrawerClosed} visible>
          {drawerContent}
        </TgDrawer>
      )}

      <TgButtonField>
        <Form form={form} layout="inline">
          <Form.Item name="search" style={{ alignItems: "center" }}>
            <TgSearch
              placeholder="Search Company"
              onSearch={(val) => val && setSearch(val)}
              enterButton
            />
          </Form.Item>
          {search && (
            <ButtonLink
              onClick={onReset}
              style={{ marginRight: 15, alignSelf: "center" }}
            >
              reset
            </ButtonLink>
          )}
        </Form>

        <Button
          onClick={() =>
            setDrawer(<CreateCompanyForm handleClose={onDrawerClosed} />)
          }
        >
          Create New Company
        </Button>
      </TgButtonField>

      <TgTable
        key={tableKey}
        rowKey="id"
        columns={columns}
        dataSource={data && data.allCompany.companies}
        onChange={onChange}
        loading={loading}
        pagination={{
          pageSize: 10,
          total: data && data.allCompany.totalCount,
          defaultCurrent: 1,
        }}
      />
    </div>
  );
}
