import React, { useEffect } from "react";
import styled from "styled-components";
import { Drawer } from "antd";
import { CloseIconBold } from "../../Icons";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: ${(props) => (props.width ? props.width : "55%")} !important;
  }
  .ant-drawer-content {
    overflow: hidden !important;
  }
`;

const GeneralCloseBox = styled.button`
  position: fixed;
  right: ${({ width }) => width || "55%"};
  top: 0px;
  z-index: 9999;
  display: block;
  cursor: pointer;
  background: white;
  padding: 10px;
  border: none;
  border-right: 1px solid #f7f7f7;
  border-bottom-left-radius: 3px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default function TgDrawer({ onClose, children, ...props }) {
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  document.body.style.overflow = "hidden"; // add body style when drawer is open
  const handleClose = () => {
    document.body.style.overflow = "auto";
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { onClose: handleClose });
  });

  return (
    <>
      <GeneralCloseBox type="button" {...props} onClick={handleClose}>
        <CloseIconBold />
      </GeneralCloseBox>
      <StyledDrawer onClose={handleClose} {...props}>
        {childrenWithProps}
      </StyledDrawer>
    </>
  );
}
