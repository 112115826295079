import React from "react";
import { DeleteIcon, EditIcon } from "../../../../../Icons";
import {
  StyledCard,
  ActionBox,
  DeleteButton,
  EditButton,
  CardTitle,
  CardLine,
} from "../styles";

export default function CompanyCard({ data, onDelete, onEdit }) {
  return (
    <StyledCard>
      <ActionBox>
        <DeleteButton type="button" onClick={onDelete}>
          <DeleteIcon color="currentColor" />
        </DeleteButton>
        <EditButton type="button" onClick={() => onEdit(data)}>
          <EditIcon color="currentColor" />
        </EditButton>
      </ActionBox>
      <CardTitle>
        {data.company} ({data.position})
      </CardTitle>
      <CardLine>
        {data.startDate} - {data.endDate || "Present"}
      </CardLine>
      <CardLine>{data.description}</CardLine>
    </StyledCard>
  );
}
