import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import FormScaffold from "../../../Form/FormScaffold";
import { SelectField } from "../../../Form/SelectField";
import { captureErrorWithData, useLazyCaller } from "../../../../helper";
import { UPDATE_COMPANY, GET_TECHNOLOGIES } from "../../queries";
import { TECHNOLOGY_CATEGORY_KEYS } from "../../../../constants";
import PartialError from "../../../ErrorPage/PartialError";

export const techStack = [
  { title: "Programming Languages", name: "language", id: 1 },
  { title: "Framework & Libraries", name: "framework", id: 5 },
  { title: "Architecture", name: "architecture", id: 6 },
  { title: "Database Systems", name: "database", id: 7 },
  { title: "Cloud Technologies", name: "cloud", id: 3 },
  { title: "Version Control Tools", name: "versioning", id: 4 },
  { title: "CI/CD Tools", name: "system", id: 8 },
  { title: "Methodology & Applications", name: "methodologies", id: 9 },
  { title: "Business Management Tools", name: "tool", id: 2 },
  { title: "Analytics & reporting tools", name: "analytics", id: 10 },
];

function filterData(data, filterObj) {
  return data.reduce((acc, val) => {
    const category = filterObj[val.categoryId];
    if (!acc[category]) {
      acc[category] = [val];
    } else {
      acc[category].push(val);
    }
    return acc;
  }, {});
}

export default function TechStackTab({ company, refetch }) {
  const {
    loading: techLoading,
    error: techError,
    data: techData,
  } = useQuery(GET_TECHNOLOGIES, {
    variables: {
      verified: true,
    },
  });
  const [inputStatus, setInputStatus] = useState({});
  const [state, setState] = useState(company.technologies);
  const lazyCaller = useLazyCaller();
  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const handleSubmit = async (newState, categoryId) => {
    try {
      setInputStatus({ ...inputStatus, [categoryId]: "loading" });
      await updateCompany({
        variables: {
          id: company.id,
          technologies: newState.map((i) => i.id),
        },
      });
      const newInputStatus = newState.some((i) => i.categoryId === categoryId)
        ? "success"
        : "pending";
      setInputStatus({ ...inputStatus, [categoryId]: newInputStatus });
      refetch();
    } catch (error) {
      captureErrorWithData(error);
      setInputStatus({ ...inputStatus, [categoryId]: "error" });
    }
  };

  const handleOnChange = (categoryId, values) => {
    setInputStatus({ ...inputStatus, [categoryId]: "pending" });
    const newState = [
      ...state.filter((i) => i.categoryId !== categoryId),
      ...values,
    ];
    setState(newState);
    lazyCaller(() => handleSubmit(newState, categoryId), 500);
  };

  const getItemStatus = (id) => {
    if (inputStatus[id]) {
      return inputStatus[id];
    }

    if (company.technologies.some((i) => i.categoryId === id)) {
      return "success";
    }

    return "pending";
  };

  if (techLoading) return "loading...";
  if (techError) return <PartialError />;

  const filteredData = filterData(
    techData.allTechnology.technologies,
    TECHNOLOGY_CATEGORY_KEYS
  );

  return (
    <div style={{ maxWidth: "600px" }}>
      {techStack.map((item) => (
        <FormScaffold
          label={item.title}
          status={getItemStatus(item.id)}
          key={item.id}
        >
          <SelectField
            isMulti
            isClearable
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            options={filteredData[item.name]}
            placeholder={`Select ${item.title}`}
            defaultValue={company.technologies.filter(
              (i) => i.categoryId === item.id
            )}
            onChange={(values) => handleOnChange(item.id, values)}
            closeMenuOnSelect={false}
          />
        </FormScaffold>
      ))}
    </div>
  );
}
