import React, { useState } from "react";
import { PageHeader } from "antd";
import { Button } from "tg-design";
import NoteSection from "../module/Notes/NoteSection";
import AddOrUpdateNote from "../module/Notes/AddNoteOrUpdate";

export default function Notes({ data, refetch }) {
  const { match } = data;
  const [state, setState] = useState({ notes: match.notes || [] });

  const handleNewNote = () => {
    const newState = { ...state, showForm: true, isNew: true };
    setState(newState);
  };

  const handleNoteEdit = (noteData) => {
    const newState = {
      ...state,
      showForm: true,
      isNew: false,
      selectedNote: noteData,
    };
    setState(newState);
  };
  return (
    <>
      <PageHeader
        ghost={false}
        title="Notes"
        extra={
          !state.showForm && [
            <Button onClick={handleNewNote}>Add New Note</Button>,
          ]
        }
      />
      {state.showForm && (
        <AddOrUpdateNote
          style={{
            marginTop: "30px",
            background: "#f9f9f9",
            padding: "20px",
            borderRadius: "5px",
          }}
          refetch={refetch}
          initialValues={{ match, note: state.selectedNote }}
          isNew={state.isNew}
        />
      )}
      {!state.showForm &&
        state.notes.map((note) => (
          <div
            style={{
              marginTop: "30px",
              background: "#f9f9f9",
              padding: "20px",
              borderRadius: "5px",
            }}
            key={note?.id}
          >
            <NoteSection
              note={note}
              assignee={match.position.assignee}
              handleEdit={() => handleNoteEdit(note)}
              companyName={
                note.creatorType === 2
                  ? match.position.company.name
                  : "TalentGrid"
              }
            />
          </div>
        ))}
    </>
  );
}
