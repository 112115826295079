import React from "react";
import { Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "tg-design";

const AlternativeNames = () => {
  return (
    <Form.List name="alternativeTexts">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Form.Item required={false} key={index} label="Alternative Names">
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Name"
                    addonAfter={
                      fields.length >= 1 ? (
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null
                    }
                  />
                </Form.Item>
              </Form.Item>
            ))}
            <Form.Item label=" ">
              <Button
                variant="text"
                dashed
                size="xs"
                block
                style={{
                  color: "#7F7F7F",
                }}
                onClick={() => {
                  add();
                }}
              >
                <PlusOutlined /> Add Alternative Name
              </Button>
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

export default AlternativeNames;
