import React, { useState } from "react";
import styled from "styled-components";
import BatchCard from "./BatchCard";

const EmptyRecord = styled.div`
  margin-left: -20px;
  margin-right: -20px;
  padding: 0px 20px;
  font-size: 16px;
  font-size: 500;
`;

const ScrollContainer = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default function ExistingBatchesSection({
  batches,
  isTestPosition,
  positionID,
}) {
  const [currentFilter, setCurrentFilter] = useState(null);

  const handleMouseOver = (value) => {
    setCurrentFilter((state) => {
      if (state !== value) {
        return value;
      }
      return state;
    });
  };

  return (
    <>
      {batches?.length === 0 && (
        <EmptyRecord>There is not any batch yet.</EmptyRecord>
      )}
      {batches?.length > 0 && (
        <ScrollContainer>
          {batches.map((batch, index) => (
            <BatchCard
              key={index}
              batch={batch}
              positionID={positionID}
              isTestPosition={isTestPosition}
              currentFilter={currentFilter}
              setCurrentFilter={handleMouseOver}
            />
          ))}
        </ScrollContainer>
      )}
    </>
  );
}
