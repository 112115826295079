import React, { useState, useCallback } from "react";
import { message } from "antd";
import { useQuery } from "@apollo/client";
import { AsyncSelectField } from "../../Form/SelectField";
import { RowBlock, ColumnBlock } from "../../Form/BlockUi";
import {
  Separator,
  GroupContainer,
  AddRowButton,
  AddGroupButton,
} from "./styles";
import { AddGroupIcon, AddRowIcon, DeleteIcon } from "../../Icons";
import { SEARCH_TECHNOLOGY } from "../queries";
import { TECHNOLOGY_CATEGORY_IDS } from "../../../constants";
import { captureErrorWithData, debounce } from "../../../helper";

export const toValidOtherSkills = (values) => {
  return values
    .filter((arr) => arr.length !== 0)
    .map((row) => {
      return row.map((item) => {
        return item._id;
      });
    });
};

const Group = ({ skillList, update, groupIndex }) => {
  const { refetch: technologyRefetch } = useQuery(SEARCH_TECHNOLOGY, {
    skip: true,
  });
  const [skillState, setSkillState] = useState(skillList);

  const filterState = (state) => {
    const filteredState = state.filter((s) => s.title !== "");
    return filteredState;
  };

  const addRow = () => {
    const filteredState = filterState(skillState);
    setSkillState([...filteredState, { title: "" }]);
  };

  const handleOnChange = (skill, index) => {
    const currentState = [...skillState];
    currentState[index] = skill;
    setSkillState(currentState);
    const filteredState = filterState(currentState);
    if (filteredState.length > index) {
      update({ changes: filteredState, idx: groupIndex });
    }
  };

  const deleteRow = (index) => {
    const currentState = [...skillState];
    const filteredState = filterState(currentState);
    filteredState.splice(index, 1);
    setSkillState(filteredState);
    update({ changes: filteredState, idx: groupIndex });
  };

  const handleTechnologySearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await technologyRefetch({
          search: val,
          verified: true,
        });
        callback(
          data.allTechnology.technologies.filter(
            (i) =>
              i.categoryId !== TECHNOLOGY_CATEGORY_IDS.language ||
              i.categoryId !== TECHNOLOGY_CATEGORY_IDS.framework
          )
        );
      } catch (err) {
        captureErrorWithData(err);
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  return (
    <GroupContainer>
      {skillState.map((skill, index) => {
        return (
          <div key={skill._id}>
            <RowBlock
              key={skill._id}
              mx={2}
              id={`skillrow-${index}`}
              justify="flex-start"
            >
              <ColumnBlock width="95%" px={2} my={3}>
                <AsyncSelectField
                  defaultValue={{ title: skill.title, id: skill._id }}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option._id}
                  optionStyle={{ background: "white" }}
                  loadOptions={handleTechnologySearch}
                  onChange={(selectedOption) =>
                    handleOnChange(
                      {
                        ...skill,
                        title: selectedOption.title,
                        _id: selectedOption.id,
                      },
                      index
                    )
                  }
                  hasError={!skill.title}
                />
              </ColumnBlock>

              <ColumnBlock width="5%" onClick={() => deleteRow(index)}>
                <span
                  role="img"
                  style={{ cursor: "pointer", fontSize: "10px" }}
                  aria-label="Delete Icon"
                  aria-labelledby={`skillrow-${index}`}
                >
                  ✖️
                </span>
              </ColumnBlock>
            </RowBlock>
            {skillState.length === index ? (
              ""
            ) : (
              <RowBlock mt={1} mb={1} ml={3} style={{ justifyContent: "left" }}>
                <Separator>OR</Separator>
              </RowBlock>
            )}
          </div>
        );
      })}
      <RowBlock mt={3} mb={3} ml={3} style={{ justifyContent: "left" }}>
        <AddRowButton onClick={addRow}>
          <AddRowIcon />
        </AddRowButton>
      </RowBlock>
    </GroupContainer>
  );
};

export default function OtherSkillGroup({ state = [], setState }) {
  const handleUpdate = (changes) => {
    const _initialValues = [...state];
    _initialValues[changes.idx] = changes.changes;
    setState(_initialValues);
  };

  const addGroup = () => {
    const currentState = [...state];
    currentState.push([]);
    setState(currentState);
  };

  const groupDelete = (index) => {
    const currentState = [...state];
    currentState.splice(index, 1);
    setState(currentState);
  };

  const generateKey = (index) => {
    return `${index}_${Math.random()}`;
  };

  return (
    <>
      {state &&
        state.map((list, index) => {
          return (
            <div key={index}>
              <div
                key={generateKey(index)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Group
                  key={generateKey(index)}
                  skillList={list}
                  groupIndex={index}
                  update={handleUpdate}
                />
                <div
                  onClick={() => groupDelete(index)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                  <DeleteIcon color="#ff9898" />
                </div>
              </div>
              {state.length === index ? (
                ""
              ) : (
                <RowBlock
                  mt={1}
                  mb={1}
                  ml={3}
                  style={{ justifyContent: "left" }}
                >
                  <Separator>AND</Separator>
                </RowBlock>
              )}
            </div>
          );
        })}
      <RowBlock mb={3} mt={2} style={{ justifyContent: "left" }}>
        <AddGroupButton type="button" onClick={addGroup}>
          <AddGroupIcon />
        </AddGroupButton>
      </RowBlock>
    </>
  );
}
