import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import FormScaffold from "../../../Form/FormScaffold";
import RichTextEditor from "../../../Form/RichTextEditor";
import { captureErrorWithData, useLazyCaller } from "../../../../helper";
import { UPDATE_COMPANY } from "../../queries";

export default function TgNotesTab({ notes, id, refetch }) {
  const [validationStatus, setValidationStatus] = useState({});
  const [state, setState] = useState(notes);
  const lazyCaller = useLazyCaller();
  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const handleSubmit = async (newState, field) => {
    try {
      setValidationStatus({ ...validationStatus, [field]: "loading" });
      await updateCompany({
        variables: {
          id,
          [field]: newState[field],
        },
      });
      await refetch();
      setValidationStatus({ ...validationStatus, [field]: null });
    } catch (error) {
      captureErrorWithData(error);
      setValidationStatus({ ...validationStatus, [field]: "error" });
    }
  };

  const validateItem = (field, changes, handleOnValid) => {
    setValidationStatus({ ...validationStatus, [field]: "pending" });
    return handleOnValid();
  };

  const applyChange = ({ field, changes }) => {
    validateItem(field, changes, () => {
      const newState = {
        ...state,
        ...changes,
      };
      setState(newState);
      lazyCaller(() => handleSubmit(newState, field), 500);
    });
  };

  const handleOnChangeValue = (field, value) => {
    applyChange({
      field,
      changes: {
        [field]: value,
      },
    });
  };

  const getItemStatus = (name) => {
    if (validationStatus[name]) {
      return validationStatus[name];
    }

    if (notes) {
      return "success";
    }

    return "pending";
  };

  return (
    <div style={{ maxWidth: "600px" }}>
      <FormScaffold
        label="Tg Notes"
        status={getItemStatus("tgNotes")}
        explanation="This note will be seen by the TALENT, under the position details in their dashboard."
      >
        <RichTextEditor
          name="tgNotes"
          defaultValue={notes}
          onChange={(value) => handleOnChangeValue("tgNotes", value)}
        />
      </FormScaffold>
    </div>
  );
}
