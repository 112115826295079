import React from "react";
import { useLocation } from "react-router-dom";
import ReadMore from "../../../../ReadMore";
import {
  TECHNOLOGY_CATEGORY_KEYS,
  TECHNOLOGY_CATEGORY_LABELS,
} from "../../../../../constants";
import { Row, SectionTitle, SectionDescription } from "./styles";

const categoryLabel = [
  "language",
  "versioning",
  "cloud",
  "tool",
  "architecture",
  "database",
  "analytics",
  "methodologies",
  "cicd",
];

const generateKey = (index) => {
  return `${index}_${Math.random()}`;
};

export default function OtherSkillsView({ otherSkills }) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  const regulatedData = otherSkills.reduce((acc, val) => {
    const category = TECHNOLOGY_CATEGORY_KEYS[val.categoryId];
    if (!acc[category]) {
      acc[category] = [val.title];
    } else {
      acc[category].push(val.title);
    }

    return acc;
  }, {});

  const getValue = (key) => {
    return regulatedData && regulatedData[key].join(", ");
  };

  const items = categoryLabel
    .filter((cl) => regulatedData[cl])
    .map((key, index) => {
      return (
        <Row key={generateKey(index)} my={{ mobileS: 3, laptopL: 2 }}>
          <SectionTitle>{TECHNOLOGY_CATEGORY_LABELS[key]}:</SectionTitle>
          {isPrintPage && (
            <SectionDescription>
              {regulatedData[key] ? getValue(key) : " -"}
            </SectionDescription>
          )}
          {!isPrintPage && (
            <SectionDescription>
              {regulatedData[key] ? (
                <ReadMore text={getValue(key)} limit={100} />
              ) : (
                " -"
              )}
            </SectionDescription>
          )}
        </Row>
      );
    });

  return <Row>{items}</Row>;
}
