import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import { withClientState } from "apollo-link-state";
import { onError } from "@apollo/client/link/error";
import { message } from "antd";

const IN_MEMORY_CACHE = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({});

  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URL,
  options: {
    reconnect: true,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message: errorMessage, statusCode }) => {
      if (statusCode === 401) {
        localStorage.removeItem("isAuth");
        window.history.go();
      }
      if (statusCode === 403) {
        message.error(errorMessage);
        window.history.back();
      }
    });
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const stateLink = withClientState({
  cache: IN_MEMORY_CACHE,
  resolvers: {
    Mutation: {
      updateNetworkStatus: (_, { isConnected }, { cache }) => {
        const data = {
          networkStatus: {
            __typename: "NetworkStatus",
            isConnected,
          },
        };
        cache.writeData({ data });
        return null;
      },
    },
  },
});

/* eslint-disable-next-line new-cap */
const uploadLink = new createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "include",
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  ApolloLink.from([stateLink, errorLink, authMiddleware.concat(uploadLink)])
);

export const useAppApolloClient = () => {
  return new ApolloClient({
    link,
    cache: IN_MEMORY_CACHE,
  });
};
