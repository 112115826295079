import React from "react";
import styled from "styled-components";

const StyledUl = styled.ul`
  list-style: none;
  display: initial;
`;

export default function TgHeaderTabs({
  currentTab,
  handleChange,
  isNavigationAllowed,
  setIsNavigationAllowed,
  children,
}) {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (child === null) {
      return;
    }
    return React.cloneElement(child, {
      currentTab,
      handleChange,
      isNavigationAllowed,
      setIsNavigationAllowed,
    });
  });

  return <StyledUl>{childrenWithProps}</StyledUl>;
}
