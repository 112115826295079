import React from "react";
import { Select, message } from "antd";
import { useMutation } from "@apollo/client";
import { MATCH_STATE_CHANGE } from "./queries";
import { captureErrorWithData } from "../../helper";

const { Option } = Select;

const matchStates = [
  {
    id: "CREATED",
    name: "Created",
    allow: ["CANCELED"],
  },
  { id: "SENT", name: "Sent", allow: ["CREATED"] }, // SENT option'i CREATED durumunda secilebilir
  {
    id: "INTERESTED",
    name: "Interested",
    allow: [],
  },
  {
    id: "PASSED",
    name: "Passed",
    allow: [
      "SENT",
      "TG_ASSESSMENT",
      "INTERVIEW",
      "SENT_OFFER",
      "COMPANY_ASSESSMENT",
    ],
  },
  {
    id: "TG_ASSESSMENT",
    name: "TG Assessment",
    allow: ["INTERESTED"],
  },
  {
    id: "COMPANY_ASSESSMENT",
    name: "Company Assessment",
    allow: ["TG_ASSESSMENT"],
  },
  {
    id: "INTERVIEW",
    name: "Interview",
    allow: ["COMPANY_ASSESSMENT"],
  },

  {
    id: "SENT_OFFER",
    name: "Sent Offer",
    allow: ["INTERVIEW"],
  },
  {
    id: "HIRED",
    name: "Hired",
    allow: ["SENT_OFFER"],
  },
  {
    id: "FAILED",
    name: "Failed",
    allow: ["INTERESTED", "TG_ASSESSMENT", "INTERVIEW", "COMPANY_ASSESSMENT"],
  },
  {
    id: "CANCELED",
    name: "Canceled",
    allow: ["CREATED"],
  },
];

export default function MatchStateChange({ defaultState, id, refetch }) {
  const [matchStateChange] = useMutation(MATCH_STATE_CHANGE);

  const handleStateChange = async (state) => {
    try {
      await matchStateChange({
        variables: { id, state },
      });
      await refetch();
      message.success(`Match state is updated as ${state}`);
    } catch (error) {
      captureErrorWithData(error);
      message.error(`State update failed${error}`);
    }
  };
  return (
    <Select
      defaultValue={defaultState}
      style={{ width: "170px" }}
      onChange={handleStateChange}
    >
      {matchStates.map((item, i) => (
        <Option
          key={i}
          value={item.id}
          disabled={!item.allow.includes(defaultState)}
        >
          {item.name}
        </Option>
      ))}
    </Select>
  );
}
