import React, { useState } from "react";
import { message, Tag } from "antd";
import {
  Timeline,
  TimelineHistory,
  HorizantalAlignRightIcon,
  ButtonLink,
  Modal,
  ModalHeader,
  ModalBody,
} from "tg-design";
import styled from "styled-components";
import { getHistoryIcon } from "./SidebarTabs/ConversationTab";
import { EmailIcon } from "../Icons";
import {
  EMAIL_EVENTS,
  EMAIL_TEMPLATES,
  MATCH_STATES,
  MATCH_HISTORY_ACTION_TYPES,
} from "../../constants";

const MailDataContainer = styled.div`
  background: rgb(242, 244, 246);
  height: 300px;
`;

const MailHeader = styled.div`
  font-size: 15px;
  display: block;
  & > span {
    display: block;
  }
`;
const MailContent = styled.div``;

const findLastIndexOfStateFromHistory = ({ history, state }) => {
  return history.map((item) => item.current).lastIndexOf(state);
};

const MailTemplate = ({ messageData }) => {
  return (
    <MailDataContainer>
      <MailContent dangerouslySetInnerHTML={{ __html: messageData.HtmlBody }} />
    </MailDataContainer>
  );
};

export default function History({
  history,
  rejectReason,
  createdAt,
  failReasons,
  mailStatuses,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageData, setMessageData] = useState(null);

  const onOpen = (messageData) => {
    if (!messageData) {
      return message.error(
        "This e-mail might be an old one. We couldn't find it."
      );
    }
    setIsModalOpen(true);
    setMessageData(messageData);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const uniqueMailTypes = [];

  const mailStatusTypes = {
    [EMAIL_TEMPLATES.NEW_MATCH]: {
      [EMAIL_EVENTS.DELIVERY]: {
        count: 0,
        definition: "Match mail sent",
        messageData: null,
      },
      [EMAIL_EVENTS.OPEN]: {
        count: 0,
        definition: "Match mail opened",
        messageData: null,
      },
      [EMAIL_EVENTS.CLICK]: {
        count: 0,
        definition: "Match mail clicked",
        messageData: null,
      },
      [EMAIL_EVENTS.BOUNCE]: {
        count: 0,
        definition: "Match mail bounced",
        messageData: null,
      },
    },
    [EMAIL_TEMPLATES.MATCH_REMINDER]: {
      [EMAIL_EVENTS.DELIVERY]: {
        count: 0,
        definition: "Reminder mail sent",
        messageData: null,
      },
      [EMAIL_EVENTS.OPEN]: {
        count: 0,
        definition: "Reminder mail opened",
        messageData: null,
      },
      [EMAIL_EVENTS.CLICK]: {
        count: 0,
        definition: "Reminder mail clicked",
        messageData: null,
      },
      [EMAIL_EVENTS.BOUNCE]: {
        count: 0,
        definition: "Reminder mail bounced",
        messageData: null,
      },
    },
    [EMAIL_TEMPLATES.MATCH_FAIL_FROM_INTERESTED]: {
      [EMAIL_EVENTS.DELIVERY]: {
        count: 0,
        definition: "Fail mail sent",
        messageData: null,
      },
      [EMAIL_EVENTS.OPEN]: {
        count: 0,
        definition: "Fail mail opened",
        messageData: null,
      },
      [EMAIL_EVENTS.CLICK]: {
        count: 0,
        definition: "Fail mail clicked",
        messageData: null,
      },
      [EMAIL_EVENTS.BOUNCE]: {
        count: 0,
        definition: "Fail mail bounced",
        messageData: null,
      },
    },
    [EMAIL_TEMPLATES.MATCH_FAIL_FROM_TG_ASSESSMENT]: {
      [EMAIL_EVENTS.DELIVERY]: {
        count: 0,
        definition: "Fail mail sent",
        messageData: null,
      },
      [EMAIL_EVENTS.OPEN]: {
        count: 0,
        definition: "Fail mail opened",
        messageData: null,
      },
      [EMAIL_EVENTS.CLICK]: {
        count: 0,
        definition: "Fail mail clicked",
        messageData: null,
      },
      [EMAIL_EVENTS.BOUNCE]: {
        count: 0,
        definition: "Fail mail bounced",
        messageData: null,
      },
    },
    [EMAIL_TEMPLATES.NEW_VERIFIED_MATCH]: {
      [EMAIL_EVENTS.DELIVERY]: {
        count: 0,
        definition: "New verified mail sent",
        messageData: null,
      },
      [EMAIL_EVENTS.OPEN]: {
        count: 0,
        definition: "New verified mail opened",
        messageData: null,
      },
      [EMAIL_EVENTS.CLICK]: {
        count: 0,
        definition: "New verified mail clicked",
        messageData: null,
      },
      [EMAIL_EVENTS.BOUNCE]: {
        count: 0,
        definition: "New verified mail bounced",
        messageData: null,
      },
    },
  };

  const filteredMailStatuses = mailStatuses
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .filter((element) => {
      const isDuplicate = uniqueMailTypes.find((item) => {
        return (
          element.templateID === item.templateID && element.type === item.type
        );
      });

      mailStatusTypes[element.templateID][element.type].count++;

      if (element.messageData) {
        mailStatusTypes[element.templateID][element.type].messageData =
          element.messageData;
      }

      if (!isDuplicate) {
        uniqueMailTypes.push({
          type: element.type,
          templateID: element.templateID,
        });

        return true;
      }

      return false;
    })
    .map((i) => ({
      ...i,
      count: mailStatusTypes[i.templateID][i.type].count,
      definition: mailStatusTypes[i.templateID][i.type].definition,
      messageData: mailStatusTypes[i.templateID][i.type].messageData,
    }));

  const historyArray = [...history, ...filteredMailStatuses].sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const findAndSetHistoryActionMessage = (actions, type) => {
    const found = actions.find((action) => action.type === type);
    if (!found) return;

    switch (found.type) {
      case MATCH_HISTORY_ACTION_TYPES.SENT_TO_GREENHOUSE:
        return "Passed Greenhouse";
      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      {messageData && isModalOpen && (
        <Modal width="1000px" handleClose={onClose}>
          <ModalHeader>
            <span style={{ fontSize: "20px" }}>{messageData.Subject}</span>
            <MailHeader>
              <span>
                <b>From:</b> {messageData.From}
              </span>
              <span>
                <b>To:</b> {` <${messageData.Recipients[0]}>`}
              </span>
            </MailHeader>
          </ModalHeader>
          <ModalBody>
            <MailTemplate messageData={messageData} />
          </ModalBody>
        </Modal>
      )}
      <Timeline>
        <TimelineHistory
          createdAt={createdAt}
          icon={<HorizantalAlignRightIcon />}
        >
          State updated: <Tag color="purple">CREATED</Tag>
        </TimelineHistory>
        {history &&
          historyArray.map((state, i) => {
            return state.current ? (
              <TimelineHistory
                key={i}
                createdAt={state.createdAt}
                icon={getHistoryIcon(state)}
              >
                State updated: <Tag color="purple">{state.current}</Tag>
                {state.current === MATCH_STATES.PASSED && (
                  <div style={{ marginTop: "10px" }}>
                    {rejectReason?.length > 0 ? (
                      rejectReason.map((i) => <Tag color="red">{i.label}</Tag>)
                    ) : (
                      <Tag color="red">No reason specified</Tag>
                    )}
                  </div>
                )}
                {findAndSetHistoryActionMessage(state.actions, 4) && (
                  <Tag color="green">
                    {findAndSetHistoryActionMessage(state.actions, 4)}
                  </Tag>
                )}
                {findLastIndexOfStateFromHistory({
                  history: historyArray,
                  state: MATCH_STATES.FAILED,
                }) === i &&
                  state.current === MATCH_STATES.FAILED &&
                  failReasons && (
                    <div style={{ marginTop: "10px" }}>
                      {failReasons?.employer?.fullName && (
                        <u>
                          The user&apos;s status was changed to
                          &ldquo;Failed&rdquo; by company employer
                          <b> ({failReasons?.employer?.fullName}):</b>
                        </u>
                      )}
                      {failReasons?.recruiter?.name && (
                        <u>
                          The user&apos;s status was changed to
                          &ldquo;Failed&rdquo; by talent partner
                          <b> ({failReasons?.recruiter?.name}):</b>
                        </u>
                      )}
                      <ul>
                        {failReasons.reasons?.map((item) => {
                          return <li>{item.label}</li>;
                        })}
                      </ul>
                      {failReasons.description || (
                        <Tag>No additional description specified</Tag>
                      )}
                    </div>
                  )}
              </TimelineHistory>
            ) : (
              <TimelineHistory
                key={i}
                createdAt={state.createdAt}
                icon={<EmailIcon size="20px" />}
              >
                <ButtonLink
                  style={{
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    color: "#444444",
                  }}
                  onClick={() => onOpen(state.messageData)}
                  underline
                >
                  {state.definition} {state.count}{" "}
                  {state.count === 1 ? "time." : "times."}
                </ButtonLink>
              </TimelineHistory>
            );
          })}
      </Timeline>
    </div>
  );
}
