import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, FormWrapper, TextField, message } from "tg-design";
import * as Yup from "yup";

import { SET_PASSWORD } from "../queries";
import { FormContainer, inputStyles } from "../styles";

const setPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().trim().min(6).max(100),
  passwordRetry: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null]),
});

export default function SetPasswordStep({ email, pin, handleStep }) {
  const [setPassword, { loading }] = useMutation(SET_PASSWORD);
  const [isReadyToGo, setIsReadyToGo] = useState(false);

  const handleClick = async (e, data) => {
    try {
      await setPassword({
        variables: {
          email,
          pin,
          ...data,
        },
      });
      handleStep();
    } catch (err) {
      // eslint-disable-next-line no-debugger
      err.graphQLErrors.map((errorMessage) => {
        return message.error(errorMessage.message);
      });
    }
  };

  const handleChange = (e, data) => {
    setIsReadyToGo(setPasswordValidationSchema.isValidSync(data));
  };

  return (
    <FormWrapper onChange={handleChange} onSubmit={handleClick}>
      <FormContainer>
        <TextField
          label="Password"
          name="password"
          type="password"
          autoFocus
          style={inputStyles}
        />
        <TextField
          label="Retype Password"
          type="password"
          name="passwordRetry"
          style={inputStyles}
        />
        <Button
          type="submit"
          style={{ marginTop: "20px", width: "100%", marginBottom: "20px" }}
          disabled={!isReadyToGo}
          loading={loading}
        >
          Save new password
        </Button>
      </FormContainer>
    </FormWrapper>
  );
}
