import styled from "styled-components";
import {
  space,
  color,
  width,
  margin,
  padding,
  display,
  alignItems,
  justifyContent,
  height,
  overflow,
  overflowX,
  overflowY,
  gridGap,
  gridRowGap,
  border,
  flex,
  flexWrap,
} from "styled-system";

export const RowBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: ${(props) => (props.wrap ? props.wrap : "wrap")};
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  flex-wrap: ${(props) => props.wrap};
  pointer-events: ${(props) =>
    props.pointerEvents ? props.pointerEvents : "all"};
  ${width}
  ${space}
  ${display}
  ${alignItems}
  ${justifyContent}
  ${height}
  ${padding}
  ${overflow}
  ${overflowX}
  ${overflowY}
  ${gridRowGap}
  ${gridGap}
  ${border}
  ${flex}
  ${flexWrap}
  ${color}
`;

export const ColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  opacity: ${(props) => props.opacity};
  flex: ${(props) => props.flex};
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  ${width}
  ${margin}
  ${space}
  ${display}
  ${alignItems}
  ${justifyContent}
  ${height}
  ${overflow}
  ${overflowX}
  ${overflowY}
`;

export const CriteriaFieldTitle = styled.div`
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 25px;
  ${space}
`;
