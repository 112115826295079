import styled from "styled-components";
import { space, width, typography, background } from "styled-system";

export const Label = styled.label`
  font-family: ${(props) => props.theme.family.bold};
  flex-direction: column;
  color: ${(props) =>
    props.hasError ? "#ff7675" : props.theme.colors.font[0]};
  font-size: 14px;
  position: relative;
  font-weight: bold;
  ${space}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  height: 40px;
  background-color: #f5f6f8;
  border-radius: 5px;
  padding: 11px 9px;
  border-style: solid;
  font-weight: bold;
  text-align: left;
  font-family: ${(props) => props.theme.family.demi};
  color: #444444;
  font-size: 14px;
  ${space}
  ${width}
  ${typography}
  ${background}
  border-color: ${(props) => (props.hasError ? "#ff7675" : "transparent")};
  outline: none;
  :focus {
    border-color: #64b7dc !important;
  }
`;

export const InlineInputs = styled.div`
  display: flex;
  align-items: center;
  ${space};
  @media only screen and (max-width: 768px) {
    :not(.keep-inline) {
      display: block;
    }
  }
`;
