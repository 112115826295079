import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Switch } from "antd";
import FormScaffold from "../../../../Form/FormScaffold";
import TextField from "../../../../Form/TextField";
import { SelectField } from "../../../../Form/SelectField";
import { captureErrorWithData, useLazyCaller } from "../../../../../helper";
import { UPDATE_POSITION, GET_ALL_RECRUITER } from "../../../queries";
import RichTextEditor from "../../../../Form/RichTextEditor";
import { generalValidationSchema } from "../../../module/validationSchemas";
import RecruitmentStages from "../../RecruitmentStages";
import PartialError from "../../../../ErrorPage/PartialError";
import { POSITION_HIGHLIGHT_OPTIONS } from "../../../../../constants/positionHighlights";
import { CustomExternalSourcingRichTextArea } from "./CustomExternalSourcingToolbar";

export default function PositionDetailForm({ position, refetch }) {
  const { role, assignee, company, id, processDurationInDays } = position;
  const [formState, setFormState] = useState({
    title: position ? position.title : null,
    role: { id: role ? role.id : null, title: role ? role.title : null },
    processDurationInDays: processDurationInDays || null,
    description: position ? position.description : null,
    recruitmentStages: position ? position.recruitmentStages : null,
    isExternal: position ? position.isExternal : null,
    externalPositionApplyLink: position
      ? position.externalPositionApplyLink
      : "",
    externalSourcingMessage: position ? position.externalSourcingMessage : null,
    assignee: {
      id: assignee ? assignee.id : null,
      name: assignee ? assignee.name : null,
    },
    content: position.content
      ? {
          video: position.content.video ? position.content.video : false,
          loomVideoURL: position.content.loomVideoURL
            ? position.content.loomVideoURL
            : null,
        }
      : null,
  });

  const [isVisiblePositionVideo, setIsVisiblePositionVideo] = useState(
    position.content?.video
  );
  const [recruiterData, setRecruiterData] = useState([]);
  const [inputStatus, setInputStatus] = useState({});
  const lazyCaller = useLazyCaller();
  const [updatePosition] = useMutation(UPDATE_POSITION);

  const { error: recruiterError } = useQuery(GET_ALL_RECRUITER, {
    fetchPolicy: "no-cache",
    variables: {
      limit: 100,
      jobType: [0],
    },
    onCompleted: (data) => {
      setRecruiterData(data.allRecruiter.recruiters);
    },
  });

  const handleSubmit = async (newState, field) => {
    try {
      setInputStatus({ ...inputStatus, [field]: "loading" });
      await updatePosition({
        variables: {
          id,
          [field]: newState[field],
        },
      });
      await refetch();
      setInputStatus({ ...inputStatus, [field]: null });
    } catch (error) {
      captureErrorWithData(error);
      setInputStatus({ ...inputStatus, [field]: "error" });
    }
  };

  const validateItem = (field, changes, handleOnValid) => {
    setInputStatus({ ...inputStatus, [field]: "pending" });
    if (!generalValidationSchema.fields[field]) {
      return handleOnValid();
    }
    generalValidationSchema.fields[field]
      .validate(changes[field])
      .then(handleOnValid)
      .catch((err) => {
        captureErrorWithData(err);
        setInputStatus({ ...inputStatus, [field]: "error" });
      });
  };

  const applyChange = ({ field, changes }) => {
    const newState = {
      ...formState,
      ...changes,
    };

    setFormState(newState);
    validateItem(field, changes, () => {
      lazyCaller(() => handleSubmit(newState, field), 500);
    });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target; // eslint-disable-line prefer-const

    if (name.includes(".")) {
      const [fieldName, subFieldName] = name.split(".");

      return applyChange({
        field: fieldName,
        changes: {
          [fieldName]: {
            [subFieldName]: value,
          },
        },
      });
    }

    applyChange({
      field: name,
      changes: { [name]: value },
    });
  };

  const handleOnChangeValue = (field, value) => {
    if (field.includes(".")) {
      const [fieldName, subFieldName] = field.split(".");

      return applyChange({
        field: fieldName,
        changes: {
          [fieldName]: {
            [subFieldName]: value,
          },
        },
      });
    }

    applyChange({
      field,
      changes: {
        [field]: value,
      },
    });
  };

  const handleSelectChange = (field, event) => {
    const value = event.value ? event.value : event.id;

    applyChange({
      field,
      changes: {
        [field]: value,
      },
    });
  };

  const handleOnNumericChange = (field, event) => {
    const value = event.target.value ? parseInt(event.target.value) : null;
    handleOnChangeValue(field, value);
  };

  const getItemStatus = (name) => {
    if (inputStatus[name]) {
      return inputStatus[name];
    }

    if (name === "content") {
      return position[name]?.loomVideoURL && "success";
    }

    if (Array.isArray(position[name])) {
      if (position[name].length === 0) {
        return "pending";
      }
      return "success";
    }

    if (position[name]) {
      return "success";
    }

    return "pending";
  };

  if (recruiterError) return <PartialError />;

  return (
    <>
      <FormScaffold
        label={
          <span style={{ marginRight: "15px" }}>Is external position?</span>
        }
        status={getItemStatus("isExternal")}
      >
        <Switch
          onChange={(value) => {
            return handleOnChangeValue("isExternal", value);
          }}
          checked={position.isExternal}
        />
      </FormScaffold>
      {position.isExternal && (
        <FormScaffold
          label={<span style={{ marginRight: "15px" }}>Apply Link</span>}
          status={getItemStatus("externalPositionApplyLink")}
        >
          <TextField
            name="externalPositionApplyLink"
            defaultValue={position.externalPositionApplyLink}
            onChange={handleOnChange}
          />
        </FormScaffold>
      )}
      <FormScaffold label="Highlights" status={getItemStatus("highlights")}>
        <SelectField
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          closeMenuOnSelect={false}
          defaultValue={POSITION_HIGHLIGHT_OPTIONS.filter((o) =>
            position.highlights
              .map(({ value }) => value)
              .includes(o.value.value)
          )}
          isMulti
          onChange={(options) =>
            handleOnChangeValue(
              "highlights",
              options.map(({ value }) => value)
            )
          }
          options={POSITION_HIGHLIGHT_OPTIONS}
        />
      </FormScaffold>
      <FormScaffold label="Company Name" status="success">
        <SelectField placeholder={company.name} isDisabled />
      </FormScaffold>
      <FormScaffold
        label="Position Title"
        status={getItemStatus("title")}
        isRequired
      >
        <TextField
          name="title"
          defaultValue={position.title}
          onChange={handleOnChange}
        />
      </FormScaffold>
      <FormScaffold
        label="Estimated Process Duration (days)"
        status={getItemStatus("processDurationInDays")}
      >
        <TextField
          type="number"
          name="processDurationInDays"
          defaultValue={processDurationInDays}
          onChange={(event) =>
            handleOnNumericChange("processDurationInDays", event)
          }
        />
      </FormScaffold>
      <FormScaffold
        label="Position Description"
        status={getItemStatus("description")}
        explanation="This note will be seen by the TALENT, under the position details in their dashboard."
        isRequired
      >
        <RichTextEditor
          name="description"
          defaultValue={position.description}
          onChange={(value) => handleOnChangeValue("description", value)}
        />
      </FormScaffold>
      <FormScaffold
        label="External Sourcing Message"
        status={getItemStatus("externalSourcingMessage")}
      >
        <CustomExternalSourcingRichTextArea
          name="externalSourcingMessage"
          defaultValue={
            position.externalSourcingMessage ||
            "Force match link: [FORCE_MATCH_LINK]"
          }
          onChange={(value, delta, source, editor) => {
            const plainText = editor.getText();
            handleOnChangeValue("externalSourcingMessage", plainText);
          }}
        />
      </FormScaffold>
      <FormScaffold
        label={
          <>
            <span style={{ marginRight: "15px" }}>
              Loom video about the position
            </span>
            <Switch
              onChange={(value) => {
                setIsVisiblePositionVideo(value);
                return handleOnChangeValue("content.video", value);
              }}
              checked={isVisiblePositionVideo}
            />
          </>
        }
        status={getItemStatus("content")}
        explanation={isVisiblePositionVideo && "Enter the loom link below"}
      >
        {isVisiblePositionVideo && (
          <TextField
            name="content.loomVideoURL"
            defaultValue={position.content?.loomVideoURL}
            onChange={handleOnChange}
          />
        )}
      </FormScaffold>
      <RecruitmentStages
        positionId={position.id}
        companyId={position.company.id}
        getItemStatus={getItemStatus}
        handleOnChangeValue={handleOnChangeValue}
        recruitmentStages={position.recruitmentStages}
      />
      <FormScaffold
        label="Talent Partner"
        status={getItemStatus("assignee")}
        isRequired
      >
        <SelectField
          name="assignee"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          defaultValue={assignee && assignee}
          options={recruiterData && recruiterData}
          onChange={(event) => handleSelectChange("assignee", event)}
        />
      </FormScaffold>
    </>
  );
}
