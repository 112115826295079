import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { message } from "antd";
import { Button } from "tg-design";
import FormScaffold from "../../../Form/FormScaffold";
import { SelectField } from "../../../Form/SelectField";
import RichTextEditor from "../../../Form/RichTextEditor";
import {
  UPDATE_NOTE,
  CREATE_NOTE,
  GET_PREVIOUS_CONVERSATIONS,
} from "../../../matchDetail/queries";
import {
  captureErrorWithData,
  getUserWorkAndEducationHistoryOptions,
} from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";

const NOTE_TYPE = "MEETING";

export default function AddOrUpdateNote({ initialValues, isNew, onClose }) {
  const { match, note = { type: "", note: "" } } = initialValues;
  const [options, setOptions] = useState([]);

  const { error } = useQuery(GET_PREVIOUS_CONVERSATIONS, {
    variables: {
      userId: match.user.id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const options = data.getPreviousNotesByUserId
        .filter((item) => item.id !== match.id)
        .map((item) => {
          return item.notes
            .filter((n) => n.type === NOTE_TYPE)
            .map((i) => {
              return {
                id: item.id,
                title: `${i.creator.name} - ${i.type.toLowerCase()} - ${
                  item.position.company.name
                }, ${item.position.title}`,
                note: i.note,
              };
            });
        });
      setOptions(options.flat());
    },
  });

  const [inputStatus, setInputStatus] = useState({
    status: isNew ? "pending" : "success",
  });
  const [state, setState] = useState({ note, isNew });

  const [updateNote] = useMutation(UPDATE_NOTE);
  const [createNote] = useMutation(CREATE_NOTE, {
    onCompleted: (data) => {
      if (data) {
        setState({ note: data.addNoteOnMatch[0], isNew: false });
        setInputStatus({ status: "success" });
      }
    },
  });

  const _updateNote = async ({ note }) => {
    try {
      setInputStatus({ status: "loading" });
      const result = await updateNote({
        variables: {
          match: match.id,
          noteId: state.note.id,
          note,
          type: NOTE_TYPE,
        },
      });
      setInputStatus({ status: "success" });
      return result;
    } catch (error) {
      captureErrorWithData(error);
      setInputStatus({ status: "error" });
    }
  };

  const handlePreviousNoteSelect = ({ note }) => {
    const newState = { ...state };

    Object.assign(newState.note, { note });

    setState(newState);
  };

  const handleUserHistorySelect = (value) => {
    const newState = { ...state };

    Object.assign(newState.note, {
      note: `${newState.note.note} ${value.note}`,
    });

    setState(newState);
  };

  const handleOnChangeNote = ({ note }) => {
    const newState = { ...state };

    Object.assign(newState.note, { note });

    setState(newState);
  };

  const handleSubmit = async ({ note }) => {
    try {
      if (!state.isNew) {
        const result = await _updateNote({ note });
        onClose({ notes: result?.data?.updateNoteById || [] });
        message.success("Note updated successfully!");
      } else {
        const result = await createNote({
          variables: { match: match.id, ...state.note, type: NOTE_TYPE },
        });
        onClose({ notes: result?.data?.addNoteOnMatch || [] });
        message.success("Note created successfully!");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const getLabel = () => {
    if (isNew) {
      return "New Note";
    }
    return `${initialValues?.note?.creator?.name} left a note at ${dayjs(
      initialValues?.note?.createdAt
    ).format("DD/MM/YYYY")}`;
  };

  if (error) return <PartialError />;

  return (
    <>
      <FormScaffold label={getLabel()} status={inputStatus.status}>
        <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          <SelectField
            flex="1"
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            options={options}
            isSearchable={false}
            onChange={(value) =>
              handlePreviousNoteSelect({
                note: value.note,
              })
            }
            placeholder="Quick Fill: Previous Meeting"
          />
          <SelectField
            flex="1"
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            options={getUserWorkAndEducationHistoryOptions(match)}
            isSearchable={false}
            onChange={(value) => handleUserHistorySelect(value)}
            placeholder="Quick Fill: User's Profile"
          />
        </div>
        <RichTextEditor
          name="notes"
          label="Notes"
          value={state.note.note}
          onChange={(value) =>
            handleOnChangeNote({
              note: value,
            })
          }
        />
        <Button
          block
          style={{ marginTop: "10px" }}
          onClick={() =>
            handleSubmit({
              note: state.note.note,
            })
          }
        >
          Submit
        </Button>
      </FormScaffold>
    </>
  );
}
