import React from "react";
import EducationHistorySection from "./EducationHistorySection";
import LanguageSection from "./LanguageSection";

export default function EducationTab({ user }) {
  return (
    <>
      <EducationHistorySection user={user} />
      <LanguageSection user={user} />
    </>
  );
}
