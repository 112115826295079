import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorage, setLocalStorage } from "../../helper";
import {
  INITIAL_POSITION_FILTER_CHECKBOX_STATE,
  DEFAULT_POSITION_FILTER_CHECKBOX_STATE,
  POSITION_CHECKBOX_FILTERS_KEY,
} from "../../components/position/constants";

const initialState = {
  checkboxFilters: getLocalStorage({
    key: POSITION_CHECKBOX_FILTERS_KEY,
    defaultValue: INITIAL_POSITION_FILTER_CHECKBOX_STATE,
    isJSON: true,
  }),
  isActive: true,
};

export const positionFilterSlice = createSlice({
  name: "positionFilter",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.checkboxFilters = INITIAL_POSITION_FILTER_CHECKBOX_STATE;
      setLocalStorage({
        key: POSITION_CHECKBOX_FILTERS_KEY,
        isJSON: true,
        value: INITIAL_POSITION_FILTER_CHECKBOX_STATE,
      });
    },
    showAll: (state) => {
      state.checkboxFilters = DEFAULT_POSITION_FILTER_CHECKBOX_STATE;
      setLocalStorage({
        key: POSITION_CHECKBOX_FILTERS_KEY,
        isJSON: true,
        value: DEFAULT_POSITION_FILTER_CHECKBOX_STATE,
      });
    },
    setPositionFilter: (state, { payload }) => {
      const { checked, name } = payload;
      state.checkboxFilters[name] = checked;
      setLocalStorage({
        key: POSITION_CHECKBOX_FILTERS_KEY,
        isJSON: true,
        value: state.checkboxFilters,
      });
    },
  },
});

export const { resetFilters, showAll, setPositionFilter } =
  positionFilterSlice.actions;

export default positionFilterSlice.reducer;
