import styled from "styled-components";

export const ResetFilters = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  text-align: center;
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
