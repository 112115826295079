import React from "react";
import styled from "styled-components";
import { CloseIcon } from "../../Icons";

const DrawerTitle = styled.h1`
  margin-bottom: 30px;
`;

const CloseContainer = styled.a`
  position: absolute;
  right: 30px;
`;

export default function TgDrawerTitle({ title, handleClose }) {
  return (
    <DrawerTitle>
      {title}
      <CloseContainer onClick={handleClose}>
        <CloseIcon />
      </CloseContainer>
    </DrawerTitle>
  );
}
