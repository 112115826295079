import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message, Popconfirm } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { Button } from "tg-design";

import {
  GET_DOCUMENT,
  UPDATE_DOCUMENT,
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
} from "../../queries";

import { SelectField } from "../../../Form/SelectField";
import TextField from "../../../Form/TextField";
import RichTextEditor from "../../../Form/RichTextEditor";
import FormScaffold from "../../../Form/FormScaffold";
import { TgDrawerTitle } from "../../../tg-styles/Layouts";

import {
  captureErrorWithData,
  getUnsignedUrl,
  removeTypename,
  capitalize,
} from "../../../../helper";

import FileGroup from "./FileGroup";
import UploadCoverImage from "./UploadCoverImage";
import PartialError from "../../../ErrorPage/PartialError";
import { ERROR_TYPES } from "../../../../constants";

const visibleOptions = [
  { label: "Published", value: true },
  { label: "Draft", value: false },
];

const reportsValidationSchema = Yup.object().shape({
  publishedAt: Yup.string().trim().required("Publish Date is required."),
  files: Yup.array()
    .min(1, "Report is required")
    .required("Report is required")
    .of(
      Yup.object().shape({
        fileLanguage: Yup.string()
          .trim()
          .required("Language is required.")
          .nullable(),
        fileURL: Yup.string()
          .trim()
          .required("Report file is required.")
          .nullable(),
      })
    ),
  coverImageURL: Yup.string()
    .trim()
    .required("Cover photo is required.")
    .nullable(),
  description: Yup.string()
    .trim()
    // html char. hesap ettigmiz icin fark oluyor
    .min(17, "Description must be at least 10 characters.")
    .required("Description is required"),
  title: Yup.string()
    .trim()
    .min(5, "Title must be at least 5 characters.")
    .required("Title is required"),
});

export default function DocumentSidebar({
  refetch,
  handleDrawerClose,
  initialValues,
  type,
}) {
  const [state, setState] = useState({ ...initialValues, type });

  const {
    loading,
    error,
    refetch: getDocumentRefetch,
  } = useQuery(GET_DOCUMENT, {
    skip: true,
    onCompleted: (data) => {
      setState({ ...data.document });
    },
  });

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: () => {
      refetch();
      handleDrawerClose();
    },
  });

  const [createDocument] = useMutation(CREATE_DOCUMENT, {
    onCompleted: () => {
      refetch();
      handleDrawerClose();
    },
  });

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: () => {
      message.success(`Document was deleted.`);
      refetch();
      handleDrawerClose();
    },
  });

  useEffect(() => {
    if (!initialValues && initialValues !== undefined) {
      getDocumentRefetch({ variables: { id: initialValues.id } });
    }
  }, [initialValues, getDocumentRefetch]);

  if (loading) return <Spin />;
  if (error) return <PartialError />;
  if (!state) return <Spin />;

  const handleSubmit = async () => {
    if (!initialValues) {
      try {
        await reportsValidationSchema.validate(state);

        await createDocument({
          variables: {
            ...state,
          },
        });
        message.success(`Document was created.`);
      } catch (err) {
        if (err.name !== ERROR_TYPES.VALIDATION) {
          message.error("Something went wrong");
          captureErrorWithData(err);
        } else {
          message.error(err.message);
        }
      }
    }

    if (initialValues) {
      try {
        const newState = removeTypename({
          ...state,
        });
        const files = newState.files.map((file) => {
          return {
            fileLanguage: file.fileLanguage,
            fileURL: getUnsignedUrl(file.fileURL),
          };
        });

        newState.files = files;
        await reportsValidationSchema.validate(newState);
        await updateDocument({
          variables: {
            ...newState,
            coverImageURL: getUnsignedUrl(newState.coverImageURL),
          },
        });
        message.success(`Document was updated.`);
      } catch (err) {
        if (err.name !== ERROR_TYPES.VALIDATION) {
          message.error("Something went wrong");
          captureErrorWithData(err);
        } else {
          message.error(err.message);
        }
      }
    }
  };

  const handleDelete = async () => {
    deleteDocument({
      variables: {
        id: initialValues.id,
      },
    });
  };

  const handlePhotoUpload = (key, url) => {
    setState({ ...state, [key]: url });
  };

  const handleReportGroupUpdate = (newState) => {
    setState({ ...state, files: removeTypename(newState) });
  };

  return (
    <div>
      {loading && "...loading"}
      {!loading && (
        <>
          <TgDrawerTitle
            title={
              initialValues
                ? `Edit ${capitalize(type)}`
                : `Add ${capitalize(type)}`
            }
            handleClose={handleDrawerClose}
          />
          <FormScaffold
            label="Title"
            explanation="English"
            options={{ showStatus: false }}
            style={{ marginTop: "10px", paddingLeft: "0px" }}
            formBodyStyles={{ paddingLeft: "0px" }}
            isRequired
          >
            <TextField
              placeholder="Title"
              value={state.title}
              width="100%"
              onChange={(e) =>
                setState({
                  ...state,
                  title: e.target.value,
                })
              }
              mb={4}
            />
          </FormScaffold>
          <FormScaffold
            label="Description"
            explanation="English"
            options={{ showStatus: false }}
            style={{ marginTop: "10px", paddingLeft: "0px" }}
            formBodyStyles={{ paddingLeft: "0px" }}
            isRequired
          >
            <RichTextEditor
              value={state.description || ""}
              width="100%"
              onChange={(value) =>
                setState({
                  ...state,
                  description: value,
                })
              }
              mb={4}
            />
          </FormScaffold>

          <FormScaffold
            label="Publish Date"
            explanation="eg: 2021-01-12"
            options={{ showStatus: false }}
            style={{ marginTop: "10px", paddingLeft: "0px" }}
            formBodyStyles={{ paddingLeft: "0px" }}
            isRequired
          >
            <TextField
              placeholder="YYYY-MM-DD"
              value={state.publishedAt}
              width="100%"
              onChange={(e) =>
                setState({
                  ...state,
                  publishedAt: e.target.value,
                })
              }
              mb={4}
            />
          </FormScaffold>

          <FileGroup
            initialValues={initialValues}
            update={handleReportGroupUpdate}
          />

          <FormScaffold
            label="Cover Photo"
            explanation="only .jpeg .png .jpg"
            options={{ showStatus: false }}
            style={{ marginTop: "10px", paddingLeft: "0px" }}
            formBodyStyles={{ paddingLeft: "0px" }}
            isRequired
          >
            <UploadCoverImage
              documentId={initialValues?.id}
              initialPhoto={state?.coverImageURL}
              label="Upload Cover Photo"
              handlePhotoUpload={(url) =>
                handlePhotoUpload("coverImageURL", getUnsignedUrl(url))
              }
            />
          </FormScaffold>
          <FormScaffold
            label="Status"
            explanation="Draft or  Published"
            options={{ showStatus: false }}
            style={{ marginTop: "10px", paddingLeft: "0px" }}
            formBodyStyles={{ paddingLeft: "0px" }}
            isRequired
          >
            <SelectField
              mb={4}
              defaultValue={visibleOptions.find((o) => {
                if (initialValues) {
                  return o.value === initialValues?.visible;
                }
                return true;
              })}
              isSearchable={false}
              options={visibleOptions}
              onChange={(event) => setState({ ...state, visible: event.value })}
            />
          </FormScaffold>

          {initialValues && (
            <Popconfirm
              title="You'r about to DELETE this report permanently!!"
              icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
              onConfirm={handleDelete}
            >
              <Button variant="danger" outline block>
                Delete
              </Button>
            </Popconfirm>
          )}

          <Button onClick={handleSubmit} block style={{ marginTop: "10px" }}>
            {initialValues && "Update"}
            {!initialValues && "Create"}
          </Button>
        </>
      )}
    </div>
  );
}
