import { gql } from "@apollo/client";

export const GET_SIMPLE_ANALYTICS = gql`
  query GetSimpleAnalytics($startDate: Date, $endDate: Date) {
    analytics {
      newUsers(startDate: $startDate, endDate: $endDate)
      newCompanies(startDate: $startDate, endDate: $endDate)
      hiredUsers(startDate: $startDate, endDate: $endDate)
      totalInterestedMatches(startDate: $startDate, endDate: $endDate)
      leadToMember(startDate: $startDate, endDate: $endDate)
      deletedUsers(startDate: $startDate, endDate: $endDate)
      averageUsersMatchedPosition(startDate: $startDate, endDate: $endDate)
      createdToSentMatches(startDate: $startDate, endDate: $endDate)
      memberToFirstInterestedPosition(startDate: $startDate, endDate: $endDate)
    }
  }
`;

export const GET_CATEGORIZED_ANALYTICS = gql`
  query GetCategorizedAnalytics($startDate: Date, $endDate: Date) {
    analytics {
      mostRoleOfDevelopers(startDate: $startDate, endDate: $endDate)
      mostTechStackCategoryOfDevelopers(
        startDate: $startDate
        endDate: $endDate
      )
      mostTechStackCategoryOfCompanies(startDate: $startDate, endDate: $endDate)
      topAccountCloseReasons(startDate: $startDate, endDate: $endDate)
      salaryAverageByUserRole(startDate: $startDate, endDate: $endDate)
    }
  }
`;
