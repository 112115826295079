import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  title: "Are you sure?",
  content: "Do you want to continue with this action?",
  callback: () => {},
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    open: (state, { payload }) => {
      state.isOpen = true;
      state.title = payload.title || initialState.title;
      state.content = payload.content || initialState.content;
      state.callback = payload.callback || initialState.callback;
    },
    close: (state) => {
      state.isOpen = false;
      state.title = initialState.title;
      state.content = initialState.content;
      state.callback = () => {};
    },
  },
});

export const { open, close } = questionSlice.actions;

export default questionSlice.reducer;
