export const POSITION_CHECKBOX_FILTERS_KEY = "checkBoxFilters-1";

export const POSITION_FILTER_LABELS = {
  firstBatchCreated: { key: "firstBatchCreated", label: "First Batch Created" },
  firstVerifiedTalent: {
    key: "firstVerifiedTalent",
    label: "First Verified Talent",
  },
  verificationDuration: {
    key: "verificationDuration",
    label: "Verification Duration",
  },
  totalReachedOut: { key: "totalReachedOut", label: "Total Reached Out" },
  totalShowedInterest: {
    key: "totalShowedInterest",
    label: "Total Showed Interest",
  },
  prevetted: { key: "prevetted", label: "Pre-Vetted" },
  verified: { key: "verified", label: "Verified" },
  activelyInTheProcess: {
    key: "activelyInTheProcess",
    label: "Actively In the Process",
  },
  interestedReached: {
    key: "interestedReached",
    label: "Interested / Reached",
  },
  verifiedPrevetted: {
    key: "verifiedPrevetted",
    label: "Verified / Pre-Vetted",
  },
};

export const INITIAL_POSITION_FILTER_CHECKBOX_STATE = {
  firstBatchCreated: false,
  firstVerifiedTalent: false,
  verificationDuration: false,
  totalReachedOut: false,
  totalShowedInterest: false,
  prevetted: false,
  verified: false,
  activelyInTheProcess: false,
  interestedReached: false,
  verifiedPrevetted: false,
};

export const DEFAULT_POSITION_FILTER_CHECKBOX_STATE = {
  firstBatchCreated: true,
  firstVerifiedTalent: true,
  verificationDuration: true,
  totalReachedOut: true,
  totalShowedInterest: true,
  prevetted: true,
  verified: true,
  activelyInTheProcess: true,
  interestedReached: true,
  verifiedPrevetted: true,
};

export const POSITION_FILTERS = {
  checkbox: [
    {
      name: POSITION_FILTER_LABELS.firstBatchCreated.key,
      label: POSITION_FILTER_LABELS.firstBatchCreated.label,
    },
    {
      name: POSITION_FILTER_LABELS.firstVerifiedTalent.key,
      label: POSITION_FILTER_LABELS.firstVerifiedTalent.label,
    },
    {
      name: POSITION_FILTER_LABELS.verificationDuration.key,
      label: POSITION_FILTER_LABELS.verificationDuration.label,
    },
    {
      name: POSITION_FILTER_LABELS.totalReachedOut.key,
      label: POSITION_FILTER_LABELS.totalReachedOut.label,
    },
    {
      name: POSITION_FILTER_LABELS.totalShowedInterest.key,
      label: POSITION_FILTER_LABELS.totalShowedInterest.label,
    },
    {
      name: POSITION_FILTER_LABELS.prevetted.key,
      label: POSITION_FILTER_LABELS.prevetted.label,
    },
    {
      name: POSITION_FILTER_LABELS.verified.key,
      label: POSITION_FILTER_LABELS.verified.label,
    },
    {
      name: POSITION_FILTER_LABELS.activelyInTheProcess.key,
      label: POSITION_FILTER_LABELS.activelyInTheProcess.label,
    },
    {
      name: POSITION_FILTER_LABELS.interestedReached.key,
      label: POSITION_FILTER_LABELS.interestedReached.label,
    },
    {
      name: POSITION_FILTER_LABELS.verifiedPrevetted.key,
      label: POSITION_FILTER_LABELS.verifiedPrevetted.label,
    },
  ],
};

export const COLUMN_WIDTHS = [
  {
    key: POSITION_FILTER_LABELS.firstBatchCreated.key,
    width: 150,
  },
  {
    key: POSITION_FILTER_LABELS.firstVerifiedTalent.key,
    width: 150,
  },
  {
    key: POSITION_FILTER_LABELS.verificationDuration.key,
    width: 100,
  },
  {
    key: POSITION_FILTER_LABELS.totalReachedOut.key,
    width: 80,
  },
  {
    key: POSITION_FILTER_LABELS.totalShowedInterest.key,
    width: 80,
  },
  {
    key: POSITION_FILTER_LABELS.prevetted.key,
    width: 80,
  },
  {
    key: POSITION_FILTER_LABELS.verified.key,
    width: 80,
  },
  {
    key: POSITION_FILTER_LABELS.activelyInTheProcess.key,
    width: 80,
  },
  {
    key: POSITION_FILTER_LABELS.interestedReached.key,
    width: 80,
  },
  {
    key: POSITION_FILTER_LABELS.verifiedPrevetted.key,
    width: 80,
  },
];
