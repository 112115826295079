import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { SelectField } from "../../Form/SelectField";
import {
  POSITION_STATES,
  RECRUITER_JOB_TYPES,
  RECRUITER_ROLES,
} from "../../../constants";
import { GET_ALL_RECRUITER } from "../../position/queries";
import {
  REMOVE_RECRUITER_FROM_TEAM,
  UPDATE_COMPANIES_CLIENT_PARTNERS,
  UPDATE_POSITIONS_ASSIGNEE,
  UPDATE_RECRUITER_SETTINGS_BY_ADMIN,
} from "../queries";
import useQuestion from "../../../hooks/useQuestion";
import {
  Permissions,
  ProfileItem,
  RadioCheckMark,
  RadioLabel,
  RadioTextWrapper,
  RevokeAccess,
  SettingsContainer,
} from "../styles";
import { CheckboxField } from "../../Form/CheckboxField";
import { createCopyOfArray } from "../../../helper";

export default function UserSettings({
  recruiter,
  loggedInRecruiter,
  refecth,
}) {
  const [recruiterData, setRecruiterData] = useState(recruiter);

  const [newTalentPartner, setNewTalentPartner] = useState(null);
  const [newClientPartner, setNewClientPartner] = useState(null);
  const [isPositionAssigmentModalOpen, setIsPositionAssigmentModalOpen] =
    useState(false);
  const [isCompanyAssigmentModalOpen, setIsCompanyAssigmentModalOpen] =
    useState(false);
  const [updateRecruiterPermission] = useMutation(
    UPDATE_RECRUITER_SETTINGS_BY_ADMIN
  );
  const [updateCompaniesClientPartners] = useMutation(
    UPDATE_COMPANIES_CLIENT_PARTNERS
  );
  const [updatePositionsAssignee] = useMutation(UPDATE_POSITIONS_ASSIGNEE);

  const { data, loading: allRecruiterLoading } = useQuery(GET_ALL_RECRUITER, {
    variables: {
      limit: 100,
    },
  });

  const [removeRecruiterFromTeam] = useMutation(REMOVE_RECRUITER_FROM_TEAM);
  const question = useQuestion();
  const history = useHistory();

  const handlePermissionChange = async (event, role) => {
    event.preventDefault();
    const newState = {
      ...recruiterData,
      role,
    };

    try {
      await updateRecruiterPermission({
        variables: {
          ...newState,
        },
      });

      setRecruiterData(newState);
      message.success("Recruiter Updated!");
    } catch (error) {
      if (error.graphQLErrors) {
        error.graphQLErrors.map((errorMessage) => {
          return message.error(errorMessage.message);
        });
      } else {
        message.error("An error occurred!");
      }
    }
  };

  const handleRemoveFromTeam = async () => {
    try {
      await removeRecruiterFromTeam({
        variables: { recruiterId: recruiter.id },
      });
      history.push("/team");
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleUpdateRecruiterJobType = async ({ jobTypeOfRecruiter }) => {
    const newState = {
      ...recruiterData,
      jobType: jobTypeOfRecruiter,
    };

    try {
      await updateRecruiterPermission({
        variables: {
          ...newState,
        },
      });

      setRecruiterData(newState);
      message.success("Recruiter Updated!");
    } catch (error) {
      if (error.graphQLErrors) {
        error.graphQLErrors.map((errorMessage) => {
          return message.error(errorMessage.message);
        });
      } else {
        message.error("An error occurred!");
      }
    }
  };

  const handleCheckBoxChange = async (event) => {
    let jobTypeOfRecruiter = createCopyOfArray(recruiterData.jobType);

    if (event.target.name === "talentPartner") {
      if (jobTypeOfRecruiter.includes(RECRUITER_JOB_TYPES.TP)) {
        if (jobTypeOfRecruiter.length === 1) {
          return message.error("You should have at least 1 duty");
        }
        if (recruiter?.assignedPositionsCount > 0) {
          return setIsPositionAssigmentModalOpen(true);
        }
        jobTypeOfRecruiter = jobTypeOfRecruiter.filter(
          (item) => item !== RECRUITER_JOB_TYPES.TP
        );
      } else {
        jobTypeOfRecruiter.push(RECRUITER_JOB_TYPES.TP);
      }
    }

    if (event.target.name === "clientPartner") {
      if (jobTypeOfRecruiter.includes(RECRUITER_JOB_TYPES.CP)) {
        if (jobTypeOfRecruiter.length === 1) {
          return message.error("You should have at least 1 duty");
        }
        if (recruiter?.assignedCompaniesCount > 0) {
          return setIsCompanyAssigmentModalOpen(true);
        }
        jobTypeOfRecruiter = jobTypeOfRecruiter.filter(
          (item) => item !== RECRUITER_JOB_TYPES.CP
        );
      } else {
        jobTypeOfRecruiter.push(RECRUITER_JOB_TYPES.CP);
      }
    }

    await handleUpdateRecruiterJobType({ jobTypeOfRecruiter });
  };

  const handleRevokeAccess = async () => {
    question(
      {
        content: (
          <div>
            Are you sure you want to remove <strong>{recruiter.name}</strong>{" "}
            from the team?
          </div>
        ),
        title: "Revoke Access",
      },
      handleRemoveFromTeam
    );
  };

  const handlePositionAssigneeModalClose = () => {
    setIsPositionAssigmentModalOpen(false);
  };
  const handleCompanyAssigneeModalClose = () => {
    setIsCompanyAssigmentModalOpen(false);
  };

  if (
    loggedInRecruiter.role !== RECRUITER_ROLES.ADMIN ||
    recruiter.role === RECRUITER_ROLES.PASSIVE
  ) {
    history.push("/");
    return null;
  }

  const handleOnSelectFieldChange = (value, name) => {
    if (name === "talentPartnerAssigment") {
      setNewTalentPartner(value);
    }
    if (name === "clientPartnerAssigment") {
      setNewClientPartner(value);
    }

    return null;
  };

  const handleSave = async (name) => {
    let jobTypeOfRecruiter = createCopyOfArray(recruiterData.jobType);

    try {
      if (name === "talentPartnerAssigment") {
        await updatePositionsAssignee({
          variables: {
            oldAssignee: recruiterData.id,
            newAssignee: newTalentPartner,
            states: [POSITION_STATES.IN_PROGRESS, POSITION_STATES.ON_HOLD],
          },
        });

        setRecruiterData({
          ...recruiterData,
          assignedPositionsCount: 0,
        });

        jobTypeOfRecruiter = jobTypeOfRecruiter.filter(
          (item) => item !== RECRUITER_JOB_TYPES.TP
        );

        refecth();

        setNewTalentPartner(null);
      }
      if (name === "clientPartnerAssigment") {
        await updateCompaniesClientPartners({
          variables: {
            oldClientPartner: recruiterData.id,
            newClientPartner,
          },
        });

        jobTypeOfRecruiter = jobTypeOfRecruiter.filter(
          (item) => item !== RECRUITER_JOB_TYPES.CP
        );

        refecth();

        setNewClientPartner(null);
      }
    } catch (error) {
      message.error("Something went wrong");
    }

    await handleUpdateRecruiterJobType({ jobTypeOfRecruiter });

    setIsCompanyAssigmentModalOpen(false);
    setIsPositionAssigmentModalOpen(false);
    return null;
  };

  if (allRecruiterLoading) return "Loading";

  return (
    <>
      {isPositionAssigmentModalOpen && (
        <Modal handleClose={handlePositionAssigneeModalClose} height="500px">
          <ModalHeader>Change Talent Partner of Positions</ModalHeader>
          <ModalBody>
            <div>
              There are open positions this Talent Partner is working on. Assign
              a new Talent Partner to these positions before changing the this
              Talent Partner&apos;s role.
            </div>
            <SelectField
              maxHeight="10px"
              name="talentPartnerAssigment"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              defaultValue={null}
              options={
                data?.allRecruiter.recruiters.filter(
                  (item) =>
                    item.id !== recruiter.id &&
                    item.jobType.includes(RECRUITER_JOB_TYPES.TP)
                ) &&
                data.allRecruiter.recruiters.filter(
                  (item) =>
                    item.id !== recruiter.id &&
                    item.jobType.includes(RECRUITER_JOB_TYPES.TP)
                )
              }
              onChange={(value) =>
                handleOnSelectFieldChange(value.id, "talentPartnerAssigment")
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!newTalentPartner}
              onClick={() => handleSave("talentPartnerAssigment")}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {isCompanyAssigmentModalOpen && (
        <Modal
          handleClose={handleCompanyAssigneeModalClose}
          overflow="hidden"
          height="500px"
        >
          <ModalHeader>Change Client Partner of Companies</ModalHeader>
          <ModalBody>
            <div>
              This person is the Client Partner of some companies. You need to
              choose a new Client Partner for these companies.
            </div>
            <SelectField
              maxHeight="500px"
              name="clientPartnerAssigment"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              defaultValue={null}
              options={
                data.allRecruiter.recruiters.filter(
                  (item) =>
                    item.id !== recruiter.id &&
                    item.jobType.includes(RECRUITER_JOB_TYPES.CP)
                ) &&
                data.allRecruiter.recruiters.filter(
                  (item) =>
                    item.id !== recruiter.id &&
                    item.jobType.includes(RECRUITER_JOB_TYPES.CP)
                )
              }
              onChange={(value) =>
                handleOnSelectFieldChange(value.id, "clientPartnerAssigment")
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!newClientPartner}
              onClick={() => handleSave("clientPartnerAssigment")}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <SettingsContainer>
        <ProfileItem>
          <span>Permissions</span>
          <Permissions>
            <RadioLabel
              onClick={(e) => {
                handlePermissionChange(e, RECRUITER_ROLES.ADMIN);
              }}
            >
              <RadioTextWrapper>
                <strong>Admin</strong>
                <ul>
                  <li>Can add or remove new users</li>
                  <li>Can view details of all positions</li>
                </ul>
              </RadioTextWrapper>
              <input
                type="radio"
                name="permission"
                checked={recruiterData.role === RECRUITER_ROLES.ADMIN}
              />
              <RadioCheckMark className="checkmark" />
            </RadioLabel>
            <RadioLabel
              onClick={(e) => {
                handlePermissionChange(e, RECRUITER_ROLES.MEMBER);
              }}
            >
              <RadioTextWrapper>
                <strong>Member</strong>
                <ul>
                  <li>Can view details of added positions</li>
                </ul>
              </RadioTextWrapper>
              <input
                type="radio"
                name="permission"
                checked={recruiterData.role === RECRUITER_ROLES.MEMBER}
              />
              <RadioCheckMark className="checkmark" />
            </RadioLabel>
          </Permissions>
        </ProfileItem>
        <ProfileItem>
          <span>Duties</span>
          <Permissions>
            <CheckboxField
              checked={recruiterData.jobType.includes(RECRUITER_JOB_TYPES.TP)}
              name="talentPartner"
              value={recruiterData.jobType.includes(RECRUITER_JOB_TYPES.TP)}
              onChange={handleCheckBoxChange}
              containerStyles={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                marginTop: "-1",
                color: "#1a1a1a",
                marginBottom: "15px",
                fontWeight: 600,
                lineHeight: "20px",
              }}
              checkboxStyles={{
                height: "20px",
                width: "20px",
                marginRight: "10px",
              }}
            >
              Talent Partner
            </CheckboxField>
            <CheckboxField
              checked={recruiterData.jobType.includes(RECRUITER_JOB_TYPES.CP)}
              name="clientPartner"
              value={recruiterData.jobType.includes(RECRUITER_JOB_TYPES.CP)}
              onChange={handleCheckBoxChange}
              containerStyles={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "#1a1a1a",
                marginBottom: "5px",
                fontWeight: 600,
                lineHeight: "20px",
              }}
              checkboxStyles={{
                height: "20px",
                width: "20px",
                marginRight: "10px",
              }}
            >
              Client Partner
            </CheckboxField>
          </Permissions>
        </ProfileItem>
        <ProfileItem>
          <span>Revoke access</span>
          <RevokeAccess>
            <Button variant="danger" outline onClick={handleRevokeAccess}>
              Revoke access
            </Button>
            <span>Removes user from organization. </span>
            <span>This action cannot be undone so proceed with caution.</span>
          </RevokeAccess>
        </ProfileItem>
      </SettingsContainer>
    </>
  );
}
