import styled from "styled-components";

const TgCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TgCard = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  width: ${(props) => (props.width ? props.width : "250px")};
  height: ${(props) => (props.height ? props.height : "90px")};
  padding: 10px;
  background-color: #f1f1f1;
  color: black;
  margin: ${(props) => (props.margin ? props.margin : "17.5px")};
  font-weight: 700;
  font-size: 20px;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};

  :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

export { TgCard, TgCardWrapper };
