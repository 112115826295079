import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import OptionStep from "./steps/OptionStep";
import { FAIL_REASON_MODAL_TYPES, MATCH_STATES } from "../../../../constants";
import { MATCH_STATE_CHANGE } from "../../../position/queries";
import { Description, SecondaryDescription } from "../StatusChangeModal/style";
import DescriptionStep from "./steps/DescriptionStep";

export default function FailReasonsModal({
  companyName,
  roleTitle,
  matchId,
  substate = null,
  onClose,
  onCompleted,
  type,
}) {
  const [sending, setSending] = useState(false);
  const [step, setStep] = useState("options");
  const [state, setState] = useState({
    failReasons: [],
    description: "",
    otherReason: "",
    isValid: false,
  });
  const [changeState] = useMutation(MATCH_STATE_CHANGE);

  const handleChangeCopmanyAssessmentFailReason = ({
    value,
    event,
    description,
  }) => {
    const { checked } = event.target;
    const newState = {
      ...state,
    };

    if (checked) {
      newState.failReasons.push(value);
      newState.description = description;
    } else {
      newState.failReasons = newState.failReasons.filter(
        (item) => item !== value
      );
    }
    setState(newState);
  };

  const handleChangeTGAssessmentFailReason = ({
    value,
    event,
    description,
  }) => {
    const { checked } = event.target;

    const newState = {
      ...state,
    };

    if (checked) {
      // DB'de ki hazır description'ların içerisinde bulunan
      // {COMPANY_NAME} ve {ROLE} alanlarının dinamik
      // çalışması için şirket adı ve rol ile replace ediyoruz.
      description = description.replace("{COMPANY_NAME}", companyName);
      description = description.replace("{ROLE}", roleTitle);

      newState.failReasons = [value];
      newState.description = description;
    } else {
      newState.failReasons = newState.failReasons.filter(
        (item) => item !== value
      );
    }
    setState(newState);
  };

  const handleFailReasonChange = ({
    value,
    event,
    description,
    failReasonType,
  }) => {
    if (
      failReasonType === FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED
    ) {
      return handleChangeCopmanyAssessmentFailReason({
        value,
        event,
        description,
      });
    }
    return handleChangeTGAssessmentFailReason({ value, event, description });
  };

  const handleSetOtherReason = (event) => {
    setState({
      ...state,
      otherReason: event.target.value,
    });
  };

  const isButtonActive = () => {
    if (
      step === "options" &&
      type === FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED
    ) {
      return (
        state.failReasons.length > 0 &&
        (!state.failReasons.includes("other") || state.otherReason.length > 0)
      );
    }

    return state.failReasons.length > 0;
  };
  const handleOnDescriptionChange = (event) => {
    const { value } = event.target;
    setState({
      ...state,
      description: value,
    });
  };

  const getCurrentStep = () => {
    if (step === "options") {
      return (
        <OptionStep
          failReasonType={type}
          matchId={matchId}
          companyName={companyName}
          roleTitle={roleTitle}
          handleFailReasonChange={handleFailReasonChange}
          failReasons={state.failReasons}
          otherReason={state.otherReason}
          setOtherReason={handleSetOtherReason}
        />
      );
    }

    return <DescriptionStep onChange={handleOnDescriptionChange} />;
  };

  const submitForm = async () => {
    setSending(true);
    const variables = {
      id: matchId,
      state: MATCH_STATES.FAILED,
      substate,
      failReasons: {
        description: state.description,
        reasons: state.failReasons.filter((item) => item !== "other"),
      },
      otherReason: state.otherReason,
    };

    try {
      await changeState({
        variables,
      });
      onCompleted();
    } catch (error) {
      message.error(error.message || error);
      onCompleted();
    }
  };

  const handleButtonClick = () => {
    if (
      type === FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED &&
      step === "options"
    ) {
      return setStep("description");
    }
    submitForm();
  };

  return (
    <Modal handleClose={onClose}>
      <ModalHeader>
        <Description>Why do you want to pass on this profile?</Description>
        <SecondaryDescription>
          {type === FAIL_REASON_MODAL_TYPES.FROM_TG_ASSESSMENT_TO_FAILED &&
            "A mail will be sent to the candidate according to your selection"}
        </SecondaryDescription>
      </ModalHeader>
      <ModalBody>{getCurrentStep()}</ModalBody>
      <ModalFooter>
        <Button
          disabled={!isButtonActive()}
          onClick={handleButtonClick}
          loading={sending}
        >
          {type === FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED &&
            step === "options" &&
            "Next"}
          {type === FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED &&
            step === "description" &&
            "Submit"}
          {type === FAIL_REASON_MODAL_TYPES.FROM_TG_ASSESSMENT_TO_FAILED &&
            "Submit"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
