import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UPLOAD_FILE } from "../../queries";
import { captureErrorWithData } from "../../../../helper";

const getFileNameFromURL = (url, pathname) => {
  const { pathname: _pathname } = new URL(url);
  return _pathname.replace(`/${pathname}/`, "");
};

export default function UploadFile({
  handleFile,
  initialFile,
  onStarted,
  onError,
  acceptTypes,
  label = "Upload File",
}) {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [state, setState] = useState({
    uploading: false,
    fileList: [],
  });

  useEffect(() => {
    if (initialFile) {
      const filename = getFileNameFromURL(initialFile, "documents");
      const initialFileList = {
        url: initialFile,
        uid: "-1",
        name: filename || "attachment#1",
        status: "done",
      };
      setState({ ...state, fileList: [initialFileList] });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initialFile]);

  const handleUploadFile = async (fileDoc) => {
    const { fileList } = state;
    setState({ ...state, uploading: true });

    try {
      if (onStarted) {
        onStarted();
      }

      const res = await uploadFile({
        variables: { file: fileDoc.file },
      });

      const updatedFileList = fileList.map((file) => {
        if (file.uid === fileDoc.file.uid) {
          file.url = res.data.uploadDocument.fileURL;
          file.status = "done";
        }
        return file;
      });
      setState({ fileList: updatedFileList, uploading: false });
      handleFile(res.data.uploadDocument.fileURL);
    } catch (error) {
      captureErrorWithData(error);
      setState({ ...state, uploading: false });
      if (onError) {
        onError();
      }
    }
  };

  const beforeUpload = (file) => {
    setState(() => ({
      fileList: [file],
    }));
    return true;
  };

  return (
    <Upload
      fileList={state.fileList}
      loading={state.uploading}
      beforeUpload={beforeUpload}
      customRequest={handleUploadFile}
      multiple={false}
      showUploadList={{ showRemoveIcon: false }}
      accept={acceptTypes}
    >
      <Button loading={state.uploading} outline size="xs">
        <UploadOutlined />
        {label}
      </Button>
    </Upload>
  );
}
