import React from "react";
import Home from "./components/home";
import Positions from "./components/position";
import PositionDetail from "./components/position/detail";
import Company from "./components/company";
import Developers from "./components/developers";
import Matches from "./components/matches";
import DeveloperDetail from "./components/developers/detail";
import CompanyDetail from "./components/company/detail";
import Login from "./components/login";
import Tools from "./components/tools";
import Roles from "./components/tools/role";
import UsersWithActiveRequests from "./components/tools/deleteRequests/usersWithActiveRequests";
import UsersWithPassiveRequests from "./components/tools/deleteRequests/usersWithPassiveRequests";
import DeletedUsers from "./components/tools/deleteRequests/deletedUsers";
import Universities from "./components/tools/education/university";
import Branches from "./components/tools/education/branches";
import Languages from "./components/tools/languages";
import Technologies from "./components/tools/technologies";
import Documents from "./components/tools/documents";
import MatchDetail from "./components/matchDetail";
import MatchPrintPage from "./components/matchDetail/MatchPrintPage";
import Analytics from "./components/analytics";
import TalentPartnerProfile from "./components/TpProfilePage";
import TalentGridTeam from "./components/TpTeamPage";
import TeamMemberProfileAndSettings from "./components/TeamMemberEdit";
import ForgotPassword from "./components/ForgotPassword";

const routes = [
  {
    path: "/",
    exact: true,
    header: () => <h3>Main Page</h3>,
    content: Home,
    needAuth: true,
  },
  {
    path: "/login",
    exact: true,
    header: () => <h3>Login</h3>,
    content: () => Login,
    needAuth: false,
  },
  {
    path: "/companies",
    exact: true,
    header: () => <h3>Companies</h3>,
    content: Company,
    needAuth: true,
  },
  {
    path: "/company/detail/:companyId",
    header: () => <h3>Company Detail</h3>,
    content: CompanyDetail,
    deprecated: true,
    redirect: "/companies/:companyId",
  },
  {
    path: "/companies/:companyId",
    header: () => <h3>Company Detail</h3>,
    content: CompanyDetail,
    needAuth: true,
  },
  {
    path: "/positions",
    exact: true,
    header: () => <h3>Positions</h3>,
    content: Positions,
    needAuth: true,
  },
  {
    path: "/positions/:positionId",
    header: () => <h3>Position Detail</h3>,
    content: PositionDetail,
    needAuth: true,
  },
  {
    path: "/position/detail/:positionId",
    header: () => <h3>Position Detail</h3>,
    content: PositionDetail,
    deprecated: true,
    redirect: "/positions/:positionId",
  },
  {
    path: "/developers",
    header: () => <h3>People</h3>,
    content: Developers,
    needAuth: true,
    exact: true,
  },
  {
    path: "/developer/detail/:userId",
    header: () => <h3>People Detail</h3>,
    content: DeveloperDetail,
    exact: true,
    deprecated: true,
    redirect: "/developers/:userId",
  },
  {
    path: "/developers/:userId",
    header: () => <h3>People Detail</h3>,
    content: DeveloperDetail,
    needAuth: true,
    exact: true,
  },
  {
    path: "/tools",
    exact: true,
    header: () => <h3>Tools</h3>,
    content: Tools,
    needAuth: true,
  },
  {
    path: "/profile",
    exact: true,
    header: () => <h3>Talent Partner Profile</h3>,
    content: TalentPartnerProfile,
    needAuth: true,
  },
  {
    path: "/team",
    exact: true,
    header: () => <h3>TalentGrid Team</h3>,
    content: TalentGridTeam,
    needAuth: true,
  },
  {
    path: "/team/:recruiterId",
    header: () => <h3>Recruiter</h3>,
    content: TeamMemberProfileAndSettings,
    needAuth: true,
  },
  {
    path: "/tools/roles",
    exact: true,
    header: () => <h3>Tools | Roles</h3>,
    content: Roles,
    needAuth: true,
  },
  {
    path: "/tools/active-delete-requests",
    exact: true,
    header: () => <h3>Tools | Users with Active Delete Requests</h3>,
    content: UsersWithActiveRequests,
    needAuth: true,
  },
  {
    path: "/tools/passive-delete-requests",
    exact: true,
    header: () => <h3>Tools | Users with Passive Delete Requests</h3>,
    content: UsersWithPassiveRequests,
    needAuth: true,
  },
  {
    path: "/tools/deleted-users",
    exact: true,
    header: () => <h3>Tools | Deleted Users</h3>,
    content: DeletedUsers,
    needAuth: true,
  },
  {
    path: "/tools/education/universities",
    exact: true,
    header: () => <h3>Tools | Education | Universities</h3>,
    content: Universities,
    needAuth: true,
  },
  {
    path: "/tools/education/branches",
    exact: true,
    header: () => <h3>Tools | Education | Branches</h3>,
    content: Branches,
    needAuth: true,
  },
  {
    path: "/tools/languages",
    exact: true,
    header: () => <h3>Tools | Languages</h3>,
    content: Languages,
    needAuth: true,
  },
  {
    path: "/tools/technologies",
    exact: true,
    header: () => <h3>Tools | Technologies</h3>,
    content: Technologies,
    needAuth: true,
  },
  {
    path: "/tools/documents",
    exact: true,
    header: () => <h3>Tools | Documents</h3>,
    content: Documents,
    needAuth: true,
  },
  {
    path: "/matches/:matchId",
    header: () => <h3>Match Detail</h3>,
    content: MatchDetail,
    deprecated: true,
    redirect: "/matches/:matchId",
  },
  {
    path: "/matches/:matchId",
    header: () => <h3>Match Detail</h3>,
    content: MatchDetail,
    needAuth: true,
  },
  {
    path: "/matches",
    header: () => <h3>Match List</h3>,
    content: Matches,
    needAuth: true,
  },
  {
    path: "/analytics",
    header: () => <h3>Analytics</h3>,
    content: Analytics,
    needAuth: true,
  },
  {
    path: "/print-match/:matchId",
    header: () => <h3>Print Match</h3>,
    content: MatchPrintPage,
    needAuth: true,
    props: {
      showSider: false,
    },
  },
  {
    path: "/forgot-password",
    header: () => <h3>Reset Password</h3>,
    content: ForgotPassword,
    needAuth: false,
    exact: true,
  },
];

export default routes;
