import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonLink } from "tg-design";
import { GET_ALL_LANGUAGES } from "../queries";
import { getQueries } from "../education/branches/getQueries";
import LanguageEdit from "./Edit/index";
import TitleSearch from "../education/Module/TitleSearch";
import { SORTERS } from "../../../constants/index";
import { getUrlParams, setQueries } from "../../../helper";

import {
  TgDrawer,
  TgTable,
  TgHeader,
  TgTotalInfo,
  TgButtonField,
} from "../../tg-styles/Layouts";
import PartialError from "../../ErrorPage/PartialError";

export default function LanguageTable() {
  const [drawerState, setDrawerState] = useState({ visible: false });
  const [selectedId, setSelectedId] = useState(null);
  const [LanguageData, setLanguageData] = useState();
  const [updateQuery, setUpdateQuery] = useState(false);
  const [queryString, setQueryString] = useState("");
  const [tableKey, setTableKey] = useState(0);

  const DEFAULT_LIMIT = 10;

  const [getAllLanguages, { loading, data, error }] = useLazyQuery(
    GET_ALL_LANGUAGES,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setLanguageData(response.allLanguages.languages);
      },
    }
  );

  const showDrawer = (id) => {
    setSelectedId(id);
    setDrawerState({
      visible: true,
    });
  };

  const columns = [
    {
      title: "Languages",
      dataIndex: "label",
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <TitleSearch
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          placeHolder="Search Languages"
        />
      ),
      render: (value, record) => (
        <ButtonLink onClick={() => showDrawer(record.id)}>{value}</ButtonLink>
      ),
    },
  ];

  const onClose = () => {
    setDrawerState({
      visible: false,
    });
    setUpdateQuery(true);
  };

  const getDefaultPage = () => {
    const params = getUrlParams();
    const { page = 1 } = getQueries(params);
    return page;
  };

  const getDefaultLimit = () => {
    const params = getUrlParams();
    const { limit = DEFAULT_LIMIT } = getQueries(params);
    return limit;
  };

  const onChange = (pagination, filters, sorter) => {
    const sort = {
      [sorter.field]: SORTERS[sorter.order],
    };
    const params = getUrlParams();
    setQueryString(
      setQueries(
        Object.assign(params, {
          page: pagination.current,
          limit: pagination.pageSize,
          sort: JSON.stringify(sort),
          ...filters,
        })
      )
    );
  };

  const clearFilters = () => {
    const url = new URL(window.location);
    url.search = "";
    window.history.pushState({}, "", url);
    setQueryString("?");
    setTableKey(tableKey + 1);
  };

  useEffect(() => {
    const params = getUrlParams();
    const { search, page = 1, limit = DEFAULT_LIMIT } = getQueries(params);
    const fetch = async () => {
      getAllLanguages({
        variables: { search, limit, page },
      });
    };
    fetch();
    setUpdateQuery(false);
  }, [queryString, updateQuery, getAllLanguages]);

  if (error) return <PartialError />;

  return (
    <div>
      <TgHeader
        breadCrumbs={[
          { title: "Tools", link: "/tools" },
          {
            title: "Languages",
            link: "/tools/languages",
          },
        ]}
      />
      {drawerState.visible && (
        <TgDrawer
          closable={false}
          onClose={onClose}
          visible={drawerState.visible}
        >
          <LanguageEdit id={selectedId} handleClose={onClose} />
        </TgDrawer>
      )}

      <div className="info-field">
        <TgTotalInfo
          count={data && data.allLanguages.totalCount}
          title="languages"
        />
        <TgButtonField>
          <ButtonLink onClick={() => clearFilters()}>Clear Filters</ButtonLink>
        </TgButtonField>
      </div>

      <TgTable
        key={tableKey}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={LanguageData || []}
        onChange={onChange}
        loading={loading}
        pagination={{
          pageSize: getDefaultLimit(),
          total: data && data.allLanguages.totalCount,
          defaultCurrent: getDefaultPage(),
        }}
      />
    </div>
  );
}
