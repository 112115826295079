import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { message } from "tg-design";
import { CREATE_POSITION } from "../queries";
import { TgDrawerTitle } from "../../tg-styles/Layouts";
import PositionFields from "../module/PositionFields";
import { captureErrorWithData } from "../../../helper";

export default function CreatePositionForm({ onClose, initialValues }) {
  const [addPosition] = useMutation(CREATE_POSITION);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const { data } = await addPosition({
        variables: values,
      });
      setLoading(false);
      history.push(`/positions/${data.addPosition.id}`);
    } catch (error) {
      setLoading(false);
      captureErrorWithData(error);
      message.error(error.message, { autoClose: false });
    }
  };

  return (
    <div>
      <TgDrawerTitle title="Create new position" handleClose={onClose} />

      <PositionFields
        handlePosition={handleSubmit}
        btnName="Create New Position"
        submitLoading={loading}
        initialValues={initialValues}
      />
    </div>
  );
}
