/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form, Tag } from "antd";
import { useLazyQuery } from "@apollo/client";
import { ButtonLink } from "tg-design";
import { getQueries } from "./getQueries";
import dayjs from "dayjs";
import {
  TgDrawer,
  TgTable,
  TgButtonField,
  TgSearch,
} from "../../../tg-styles/Layouts";

import { SORTERS, TECHNOLOGY_CATEGORIES } from "../../../../constants/index";

import { getUrlParams, setQueries } from "../../../../helper";

import { GET_ALL_VERIFIED_TECH } from "../../queries";

import TechnologySidebar from "./TechnologySidebar";
import PartialError from "../../../ErrorPage/PartialError";

export default function VerifiedTechnologies() {
  const [techData, setTechData] = useState();
  const [queryString, setQueryString] = useState("");
  const [drawerState, setdrawerState] = useState({ visible: false });
  const [search, setSearch] = useState("");
  const [form] = Form.useForm();

  const [getAllVerifiedTech, { loading, error, data }] = useLazyQuery(
    GET_ALL_VERIFIED_TECH,
    {
      fetchPolicy: "network-only",
      variables: {
        verified: true,
      },
      onCompleted: (data) => {
        setTechData(data.allTechnology.technologies);
      },
    }
  );

  const DEFAULT_LIMIT = 20;
  const DEFAULT_SORT = { updatedAt: "DESC" };

  const fetch = async () => {
    const params = getUrlParams();
    const {
      page = 1,
      limit = DEFAULT_LIMIT,
      sort = DEFAULT_SORT,
    } = getQueries(params);
    const verified = true;
    getAllVerifiedTech({
      variables: { search, limit, page, verified, sort },
    });
  };

  useEffect(() => {
    fetch();
  }, [queryString, window.history, search]);

  const getDefaultPage = () => {
    const params = getUrlParams();
    const { page = 1 } = getQueries(params);
    return page;
  };

  const getDefaultLimit = () => {
    const params = getUrlParams();
    const { limit = DEFAULT_LIMIT } = getQueries(params);
    return limit;
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Category",
      dataIndex: "categoryId",
      render: (category) => (
        <span>{TECHNOLOGY_CATEGORIES[category]?.label}</span>
      ),
    },
    {
      title: "Alternative Titles",
      dataIndex: "alternativeTexts",
      render: (titles) => {
        return titles.map((title) => <Tag>{title}</Tag>);
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      defaultSortOrder: "descend",
      render: (date) => {
        const now = dayjs();
        const dateDiff = dayjs(now).diff(date, "day");
        return dateDiff === 0 ? "Bugün" : `${dateDiff} gün önce`;
      },
      sorter: (a, b) => {},
    },
    {
      title: "Edit",
      dataIndex: "id",
      render: (value, record) => (
        <a onClick={() => showDrawer(value)}>Detail</a>
      ),
    },
  ];

  const showDrawer = (id) => {
    setdrawerState({
      visible: true,
      id,
    });
  };

  const onClose = () => {
    setdrawerState({
      visible: false,
      id: null,
    });
  };

  if (error) return <PartialError />;

  const onChange = (pagination, filters, sorter, extra) => {
    const sort = {
      [sorter.field]: SORTERS[sorter.order],
    };
    const params = getUrlParams();
    setQueryString(
      setQueries(
        Object.assign(params, {
          page: pagination.current,
          limit: pagination.pageSize,
          sort: JSON.stringify(sort),
          ...filters,
        })
      )
    );
  };

  const onReset = () => {
    form.resetFields();
    setSearch("");
  };

  return (
    <>
      <TgButtonField>
        <Form form={form} layout="inline">
          <Form.Item name="search" style={{ justifyContent: "end" }}>
            <TgSearch
              placeholder="Search"
              onSearch={(val) => val && setSearch(val)}
              enterButton
            />
          </Form.Item>
          {search && (
            <ButtonLink onClick={onReset} style={{ alignSelf: "center" }}>
              reset
            </ButtonLink>
          )}
        </Form>
      </TgButtonField>

      {drawerState.visible && (
        <TgDrawer
          closable={false}
          onClose={onClose}
          visible={drawerState.visible}
        >
          <TechnologySidebar
            id={drawerState.id}
            refetch={fetch}
            handleDrawerClose={onClose}
          />
        </TgDrawer>
      )}
      <TgTable
        width="100%"
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        dataSource={techData}
        onChange={onChange}
        pagination={{
          pageSize: getDefaultLimit(),
          total: data && data.allTechnology?.totalCount,
          defaultCurrent: getDefaultPage(),
        }}
      />
    </>
  );
}
