import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import { Button } from "tg-design";
import { UPDATE_EXTERNAL_MATCH_REPORT } from "../queries";
import { TgDrawerTitle } from "../../tg-styles/Layouts";
import TextField from "../../Form/TextField";
import { captureErrorWithData } from "../../../helper";

export default function CustomReportForm({
  onClose,
  report = {
    notified: 0,
    interested: 0,
    notInterested: 0,
    interviewed: 0,
    interviewedButNotSignedUp: 0,
  },
  positionId,
}) {
  const [state, setState] = useState(report);

  const [updateExternalReport, { loading }] = useMutation(
    UPDATE_EXTERNAL_MATCH_REPORT
  );

  const handleSubmit = async () => {
    try {
      await updateExternalReport({
        variables: { ...state, positionId },
      });
    } catch (error) {
      captureErrorWithData(error);
      message.error(error.message);
    }
  };

  return (
    <div>
      {loading && "...loading"}
      {!loading && (
        <>
          <TgDrawerTitle title="Custom Reports" handleClose={onClose} />
          <TextField
            type="number"
            label="Total notified"
            width="100%"
            style={{ marginBottom: "40px" }}
            value={state.notified}
            onChange={(e) =>
              setState({ ...state, notified: parseInt(e.target.value) })
            }
          />
          <TextField
            type="number"
            label="Total not interested"
            width="100%"
            style={{ marginBottom: "40px" }}
            value={state.notInterested}
            onChange={(e) =>
              setState({ ...state, notInterested: parseInt(e.target.value) })
            }
          />
          <TextField
            type="number"
            label="Interviewed but not signed up"
            width="100%"
            style={{ marginBottom: "40px" }}
            value={state.interviewedButNotSignedUp}
            onChange={(e) =>
              setState({
                ...state,
                interviewedButNotSignedUp: parseInt(e.target.value),
              })
            }
          />
          <Button onClick={handleSubmit} block>
            Update Report
          </Button>
        </>
      )}
    </div>
  );
}
