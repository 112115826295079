import React, { useState } from "react";
import { Tabs, TabItem } from "tg-design";
import { DetailSection, VersionSection } from "./PublicDetailSections";

export default function PublicDetailsTab({ position, refetch }) {
  const [currentTab, setCurrentTab] = useState("details");
  return (
    <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab}>
      <TabItem
        component={<DetailSection position={position} refetch={refetch} />}
        name="details"
        title="Details"
      />
      <TabItem
        component={<VersionSection position={position} refetch={refetch} />}
        name="versions"
        title="Versions"
      />
    </Tabs>
  );
}
