import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Tabs, TabItem } from "tg-design";
import { GET_MATCH_ACTIVITIES } from "../../queries";
import ActivityItem from "./ActivityItem";
import History from "../../History";
import EmptyData from "../../../common/EmptyData";
import PartialError from "../../../ErrorPage/PartialError";

export default function ActivityTab({ match }) {
  const { id, history, createdAt, rejectReason, failReasons } = match;
  const [currentTab, setCurrentTab] = useState("history");

  const { loading, error, data } = useQuery(GET_MATCH_ACTIVITIES, {
    variables: { id },
  });

  if (loading) return "loading";
  if (error) return <PartialError />;

  const { activities } = data.matchActivities;

  return (
    <>
      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab}>
        <TabItem
          component={
            <History
              mailStatuses={match.mailStatuses}
              history={history}
              createdAt={createdAt}
              rejectReason={rejectReason}
              failReasons={failReasons}
            />
          }
          name="history"
          title="Match history"
        />
        <TabItem
          component={
            activities?.length > 0 ? (
              activities.map((activity) => <ActivityItem activity={activity} />)
            ) : (
              <EmptyData />
            )
          }
          name="activity"
          title="Company activity"
        />
      </Tabs>
    </>
  );
}
