/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { captureErrorWithData } from "../helper";
import ErrorPage from "../components/ErrorPage";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: "",
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error) {
    captureErrorWithData(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} />;
    }
    return this.props.children;
  }
}
