import React from "react";
import PositionDetailForm from "./PositionDetailForm";
import PositionCriteriaForm from "./PositionCriteriaForm";

export default function DetailSection({ position, refetch }) {
  return (
    <div style={{ maxWidth: "600px", paddingBottom: "100px" }}>
      <PositionDetailForm position={position} refetch={refetch} />
      <PositionCriteriaForm position={position} refetch={refetch} />
    </div>
  );
}
