import React, { useState, useCallback } from "react";
import { message } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import { DecimalField } from "tg-design";
import FormScaffold from "../../../../Form/FormScaffold";
import { SelectField, AsyncSelectField } from "../../../../Form/SelectField";
import {
  useLazyCaller,
  captureErrorWithData,
  removeTypename,
  debounce,
} from "../../../../../helper";
import {
  GET_BENEFITS,
  UPDATE_POSITION_CRITERIA,
  ALL_LANGUAGES,
  SEARCH_TECHNOLOGY,
} from "../../../queries";
import {
  BUDGET_PERIODS,
  BUDGET_VISIBILITY_OPTIONS,
  CURRENCIES,
  INCOME_OPTIONS,
  RELOCATION_OPTIONS,
  REMOTE_OPTIONS_FOR_COMPANY,
  STOCK_OPTION_PROVIDE_TYPES,
  STOCK_OPTION_RANGE_TYPE,
  STOCK_OPTION_VISIBILITY_TYPES,
  VISA_SUPPORT_OPTIONS,
} from "../../../../../constants";
import SkillGroup from "../../../module/SkillGroup";
import OtherSkillGroup from "../../../module/OtherSkillGroup";
import LanguageGroup, {
  toValidLanguages,
  getDefaultLanguages,
} from "../../../module/LanguageGroup";
import { ColumnBlock, RowBlock } from "../../../../Form/BlockUi";
import { criteriaValidationSchema } from "../../../module/validationSchemas";
import { InlineInputs } from "../../../../Form/FormUi";
import {
  BlockCheckboxField,
  FlatCheckboxField,
} from "../../../../Form/CheckboxField";
import NumericField from "../../../../Form/NumericField";
import PartialError from "../../../../ErrorPage/PartialError";

const PercentageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100px;
  font-size: 20px;
  border-color: #f1f1f1;
  border-radius: 5px;
  border-style: solid;
  flex-shrink: 0;
`;

export default function PositionCriteriaForm({ position, refetch }) {
  const { company, criteria, id } = position;
  const { refetch: technologyRefetch } = useQuery(SEARCH_TECHNOLOGY, {
    skip: true,
  });

  const [formState, setFormState] = useState({
    totalExperience: {
      min:
        criteria && criteria.totalExperience.min
          ? criteria.totalExperience.min
          : null,
      max:
        criteria && criteria.totalExperience.max
          ? criteria.totalExperience.max
          : null,
    },
    education: {
      type: criteria ? criteria.education.type : null,
      universities: criteria
        ? criteria.education.universities.map((i) => i.id)
        : null,
      branches: criteria ? criteria.education.branches.map((i) => i.id) : null,
    },
    languages: getDefaultLanguages(criteria?.languages),
    benefits: {
      expected: {
        id: criteria ? criteria.benefits.expected.map((i) => i.id) : null,
        label: criteria ? criteria.benefits.expected : null,
      },
    },
    technologies: {
      expected: criteria?.technologies?.expected || [],
      otherExpected: criteria?.technologies?.otherExpected || [],
      plus: criteria ? criteria.technologies.plus.map((i) => i.id) : null,
    },
    age: {
      min: criteria && criteria.age.min ? criteria.age.min : null,
      max: criteria && criteria.age.max ? criteria.age.max : null,
    },
    budget: {
      period: criteria?.budget?.period || "MONTHLY",
      income: criteria?.budget?.income || "GROSS",
      min: criteria?.budget?.min || null,
      max: criteria?.budget?.max || null,
      currency: criteria?.budget?.currency || "USD",
      isVisibleToUser: criteria?.budget?.isVisibleToUser,
    },
    positionLocation: {
      expected:
        criteria && criteria.positionLocation.expected
          ? criteria.positionLocation.expected.id
          : null,
      remote:
        criteria && criteria.positionLocation.remote
          ? criteria.positionLocation.remote
          : null,

      relocation: criteria?.positionLocation?.relocation || null,
      visaSupport: criteria?.positionLocation?.visaSupport,
    },
    stockOption: {
      min: criteria?.stockOption?.min?.toString() || null,
      max: criteria?.stockOption?.max?.toString() || null,
      certainValue: criteria?.stockOption?.certainValue?.toString() || null,
      isProvided: criteria?.stockOption?.isProvided,
      isVisibleToUser: criteria?.stockOption?.isVisibleToUser,
      isRange: criteria?.stockOption?.isRange || "RANGE",
    },
  });

  const [inputStatus, setInputStatus] = useState({});
  const [benefitsData, setBenefitsData] = useState([]);

  const lazyCaller = useLazyCaller();
  const [updateCriteria] = useMutation(UPDATE_POSITION_CRITERIA);

  const remoteValue =
    (position.criteria &&
      position.criteria.positionLocation &&
      position.criteria.positionLocation.remote) ||
    "";

  const { error: benefitError } = useQuery(GET_BENEFITS, {
    onCompleted: (data) => {
      setBenefitsData(data.allBenefits);
    },
  });

  const {
    data: langData,
    loading,
    error: langError,
  } = useQuery(ALL_LANGUAGES, {
    variables: { limit: 300 },
  });

  const handleTechnologySearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await technologyRefetch({
          search: val,
          verified: true,
        });
        callback(data.allTechnology.technologies);
      } catch (err) {
        captureErrorWithData(err);
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleSubmit = async (newState, field, path) => {
    try {
      const variables = removeTypename({
        positionId: id,
        [field]: newState[field],
      });

      if (variables.technologies) {
        variables.technologies = {
          ...variables.technologies,
          expected: variables?.technologies?.expected
            .map((i) => {
              return i.map((j) => {
                return { _id: j._id, experience: j.experience };
              });
            })
            .filter((s) => s.length > 0),
          otherExpected: variables?.technologies?.otherExpected
            .map((i) => {
              return i.map((j) => {
                return j._id || j.id;
              });
            })
            .filter((s) => s.length > 0),
        };

        const positionExpected = (criteria?.technologies?.expected || []).map(
          (i) => {
            return i.map((j) => {
              return { _id: j._id, experience: j.experience };
            });
          }
        );

        const positionOtherExpected = (
          criteria?.technologies?.otherExpected || []
        ).map((i) => {
          return i.map((j) => {
            return j._id || j.id;
          });
        });

        const positionPlus = (criteria?.technologies?.plus || []).map(
          (i) => i.id
        );

        if (
          JSON.stringify(positionExpected) ===
            JSON.stringify(variables?.technologies?.expected) &&
          JSON.stringify(positionOtherExpected) ===
            JSON.stringify(variables?.technologies?.otherExpected) &&
          JSON.stringify(positionPlus) ===
            JSON.stringify(variables?.technologies?.plus)
        ) {
          setInputStatus({ ...inputStatus, [path]: "success" });
          return;
        }
      }

      if (variables.languages) {
        variables.languages = toValidLanguages(variables.languages);
      }
      setInputStatus({ ...inputStatus, [path]: "loading" });

      await updateCriteria({ variables });
      await refetch();
      setInputStatus({ ...inputStatus, [field]: null });
    } catch (error) {
      captureErrorWithData(error);
      if (field !== path && path) {
        setInputStatus({ ...inputStatus, [path]: "error" });
      } else {
        setInputStatus({ ...inputStatus, [field]: "error" });
      }
    }
  };

  const validateItem = (field, changes, rootField, handleOnValid) => {
    setInputStatus({ ...inputStatus, [rootField || field]: "pending" });

    if (!criteriaValidationSchema.fields[field]) {
      return handleOnValid();
    }

    let rules = criteriaValidationSchema.fields[field];
    let value = changes[field];

    if (rootField) {
      rules = rules.fields[rootField];
      value = value[rootField];
    }

    rules
      .validate(value)
      .then(handleOnValid)
      .catch((err) => {
        captureErrorWithData(err);
        if (rootField) {
          setInputStatus({ ...inputStatus, [rootField]: "error" });
        } else {
          setInputStatus({ ...inputStatus, [field]: "error" });
        }
      });
  };

  const applyChange = ({ field, changes, rootField }) => {
    const newState = {
      ...formState,
      ...changes,
    };
    setFormState(newState);

    validateItem(field, changes, rootField, () => {
      lazyCaller(() => handleSubmit(newState, field, rootField || field), 500);
    });
  };

  const handleNumericCriteriaChange = (motherField, childField, event) => {
    let value = event?.target?.value ? parseInt(event.target.value) : event;
    if (motherField === "stockOption") {
      value = event.target.value;
    }
    applyChange({
      field: motherField,
      changes: {
        [motherField]: { ...formState[motherField], [childField]: value },
      },
    });
  };

  const handleSelectChange = (motherField, childField, event) => {
    let value;
    if (event.value !== false) {
      value = event ? event.id || event.value : null;
    } else {
      value = event.value;
    }

    applyChange({
      field: motherField,
      changes: {
        [motherField]: { ...formState[motherField], [childField]: value },
      },
    });
  };

  const handleLanguageChange = (changes) => {
    const newState = [...changes];
    applyChange({
      field: "languages",
      changes: { languages: newState },
    });
  };

  const handleRemoteChange = (motherField, childField, event, option) => {
    const { checked } = event.target;

    let values = [];
    let value = option.id;

    if (remoteValue !== "") {
      values = remoteValue.split(",");
    }
    if (checked) {
      values.push(value);
      value = values.join(",");
    } else {
      values = values.filter((item) => item !== value);
      value = values.join(",");
      values.push(value);
    }

    applyChange({
      field: motherField,
      changes: {
        [motherField]: { ...formState[motherField], [childField]: value },
      },
    });
  };

  const handleMultiSelectChange = (motherField, childField, event) => {
    const value = event.map((i) => i.id || i.value);
    if (!formState.technologies.expected && motherField === "technologies") {
      formState.technologies.expected = [];
    }

    applyChange({
      field: motherField,
      changes: {
        [motherField]: { ...formState[motherField], [childField]: value },
      },
      rootField: childField === "plus" && "plus",
    });
  };

  const handleSkillChange = (skills) => {
    applyChange({
      field: "technologies",
      changes: {
        technologies: {
          ...formState.technologies,
          expected: skills,
        },
      },
      rootField: "expected",
    });
  };

  const handleOtherSkillChange = (otherSkills) => {
    applyChange({
      field: "technologies",
      changes: {
        technologies: {
          ...formState.technologies,
          otherExpected: otherSkills,
        },
      },
      rootField: "otherExpected",
    });
  };

  const getItemStatus = (name, secondName) => {
    const { technologies, benefits } = position?.criteria;
    if (inputStatus[secondName]) {
      return inputStatus[secondName];
    }

    if (inputStatus[name]) {
      return inputStatus[name];
    }

    if (secondName === "plus" && technologies?.plus.length === 0) {
      return "pending";
    }

    if (secondName === "expected" && technologies?.expected.length === 0) {
      return "pending";
    }

    if (
      secondName === "otherExpected" &&
      technologies?.otherExpected.length === 0
    ) {
      return "pending";
    }

    if (name === "benefits" && benefits?.expected.length === 0) {
      return "pending";
    }

    if (Array.isArray(position.criteria[name])) {
      if (position.criteria[name].length === 0) {
        return "pending";
      }
      return "success";
    }

    if (position.criteria[name]) {
      return "success";
    }

    return "pending";
  };

  const handleBudgetOptionChange = (e) => {
    applyChange({
      field: "budget",
      changes: {
        budget: { ...formState.budget, [e.key]: e.checked },
      },
    });
  };

  if (loading) return "loading";
  if (benefitError || langError) return <PartialError />;
  const handleStockOptionChange = (e) => {
    if (e.checked) {
      return setFormState({
        ...formState,
        stockOption: {
          ...formState.stockOption,
          min: null,
          max: null,
          certainValue: null,
          isRange: e.checked,
        },
      });
    }
  };

  return (
    <div style={{ maxWidth: "600px", paddingBottom: "100px" }}>
      <FormScaffold
        label="Total Experience"
        status={getItemStatus("totalExperience")}
        isRequired
      >
        <NumericField
          type="number"
          placeholder="min"
          name="experience-min"
          defaultValue={formState.totalExperience.min}
          onChange={(event) =>
            handleNumericCriteriaChange("totalExperience", "min", event)
          }
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <NumericField
          type="number"
          placeholder="max"
          name="experience-max"
          defaultValue={formState.totalExperience.max}
          onChange={(event) =>
            handleNumericCriteriaChange("totalExperience", "max", event)
          }
          style={{ width: "100%" }}
          otherStyle={{ marginLeft: 0 }}
        />
      </FormScaffold>
      <FormScaffold
        label="Must Have Skills"
        status={getItemStatus("technologies", "expected")}
      >
        <SkillGroup
          initialValues={criteria && criteria.technologies.expected}
          state={formState.technologies.expected}
          setState={handleSkillChange}
        />
      </FormScaffold>
      <FormScaffold
        label="Other Required Skills"
        status={getItemStatus("technologies", "otherExpected")}
      >
        <OtherSkillGroup
          initialValues={criteria && criteria.technologies.otherExpected}
          state={formState.technologies.otherExpected}
          setState={handleOtherSkillChange}
        />
      </FormScaffold>
      <FormScaffold label="Plus" status={getItemStatus("technologies", "plus")}>
        <AsyncSelectField
          name="plus"
          isMulti
          isClearable
          defaultValue={
            criteria &&
            criteria.technologies.plus.length > 0 &&
            criteria.technologies.plus.map((item) => {
              return item;
            })
          }
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.id}
          loadOptions={handleTechnologySearch}
          onChange={(event) =>
            handleMultiSelectChange("technologies", "plus", event)
          }
          closeMenuOnSelect={false}
        />
      </FormScaffold>
      <FormScaffold
        label="Salary"
        explanation="Enter the gross or net salary range that will be used for matching."
        status={getItemStatus("budget")}
        isRequired
      >
        <InlineInputs>
          <FlatCheckboxField
            onChange={handleBudgetOptionChange}
            icon={false}
            name="income"
            defaultValue={formState?.budget?.income}
            options={INCOME_OPTIONS}
            checked
            mr={2}
            width="60%"
          />
          <FlatCheckboxField
            onChange={handleBudgetOptionChange}
            icon={false}
            name="period"
            defaultValue={formState.budget.period}
            options={BUDGET_PERIODS}
            checked
            width="60%"
          />
        </InlineInputs>
        {/* <InlineInputs></InlineInputs> */}
        <InlineInputs mb={3}>
          <NumericField
            type="number"
            name="budget-min"
            placeholder="Min"
            defaultValue={formState.budget.min}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleNumericCriteriaChange("budget", "min", event)
            }
          />
          <NumericField
            type="number"
            name="budget-max"
            placeholder="Max"
            defaultValue={formState.budget.max}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleNumericCriteriaChange("budget", "max", event)
            }
          />
          <SelectField
            name="budget-currency"
            placeholder="Currency"
            defaultValue={{
              label: formState.budget.currency || {
                label: "USD",
                value: "USD",
              },
            }}
            options={CURRENCIES}
            onChange={(event) =>
              handleSelectChange("budget", "currency", event)
            }
          />
        </InlineInputs>
        <SelectField
          label="Salary Visibility"
          name="salary-visibility"
          placeholder="Select salary visibiltiy"
          defaultValue={{
            label: formState.budget.isVisibleToUser
              ? "Yes, make it visible"
              : "No, hide it",
          }}
          options={BUDGET_VISIBILITY_OPTIONS}
          onChange={(event) =>
            handleSelectChange("budget", "isVisibleToUser", event)
          }
        />
      </FormScaffold>
      <LanguageGroup
        languageData={langData.allLanguages.languages}
        getItemStatus={getItemStatus}
        state={formState.languages}
        setState={handleLanguageChange}
        updateLanguages={handleLanguageChange}
        options={{ showStatus: true }}
      />

      <FormScaffold label="Side Benefits" status={getItemStatus("benefits")}>
        <SelectField
          name="benefits"
          isMulti
          isClearable
          defaultValue={
            criteria &&
            criteria.benefits.expected.length > 0 &&
            criteria.benefits.expected.map((item) => {
              return item;
            })
          }
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          options={benefitsData && benefitsData}
          onChange={(event) =>
            handleMultiSelectChange("benefits", "expected", event)
          }
          closeMenuOnSelect={false}
        />
      </FormScaffold>
      <FormScaffold
        label="Position Location"
        status={getItemStatus("positionLocation")}
        isRequired
      >
        <RowBlock>
          <ColumnBlock width="100%">
            {REMOTE_OPTIONS_FOR_COMPANY.map((option, index) => {
              return (
                <BlockCheckboxField
                  label={option.title}
                  key={index}
                  id={option.id}
                  name={option.id}
                  onChange={(event) =>
                    handleRemoteChange(
                      "positionLocation",
                      "remote",
                      event,
                      option
                    )
                  }
                  checked={remoteValue.split(",").includes(option.id)}
                  icon
                  checkboxStyles={{
                    height: "14px",
                    width: "14px",
                    margin: "0 10px 0 0",
                  }}
                />
              );
            })}
          </ColumnBlock>
        </RowBlock>
        <RowBlock mt={2}>
          <ColumnBlock width="100%">
            <SelectField
              label="Location"
              name="positionLocation"
              isDisabled={
                remoteValue.split(",").length === 1 &&
                remoteValue.includes("full")
              }
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.id}
              value={criteria && criteria.positionLocation.expected}
              options={company && company.locations}
              onChange={(event) =>
                handleSelectChange("positionLocation", "expected", event)
              }
            />
          </ColumnBlock>
        </RowBlock>
        <RowBlock mt={2}>
          <ColumnBlock width="100%">
            <SelectField
              label="Relocation Support"
              name="relocation-support"
              placeholder="Select relocation support"
              defaultValue={{
                label: formState.positionLocation.relocation ? "Yes" : "No",
              }}
              options={RELOCATION_OPTIONS}
              onChange={(event) =>
                handleSelectChange("positionLocation", "relocation", event)
              }
            />
          </ColumnBlock>
        </RowBlock>
        <RowBlock mt={2}>
          <ColumnBlock width="100%">
            <SelectField
              label="Visa Support"
              name="visa-support"
              placeholder="Select visa support"
              defaultValue={{
                label: formState.positionLocation.visaSupport ? "Yes" : "No",
              }}
              options={VISA_SUPPORT_OPTIONS}
              onChange={(event) =>
                handleSelectChange("positionLocation", "visaSupport", event)
              }
            />
          </ColumnBlock>
        </RowBlock>
      </FormScaffold>
      <FormScaffold
        label="Do you provide stock options?"
        status={getItemStatus("stockOption")}
        isRequired
      >
        <RowBlock mt={2}>
          <ColumnBlock width="100%">
            <SelectField
              name="stock-option-provide"
              defaultValue={{
                label: formState.stockOption.isProvided ? "Yes" : "No",
              }}
              options={STOCK_OPTION_PROVIDE_TYPES}
              onChange={(event) => {
                handleSelectChange("stockOption", "isProvided", event);
              }}
            />
          </ColumnBlock>
        </RowBlock>
        <RowBlock mt={30}>
          <ColumnBlock width="100%">
            <InlineInputs>
              <FlatCheckboxField
                onChange={handleStockOptionChange}
                icon={false}
                name="isRange"
                defaultValue={formState.stockOption?.isRange}
                options={STOCK_OPTION_RANGE_TYPE}
                checked
                width="50%"
              />
            </InlineInputs>
            <InlineInputs mb={3} style={{}}>
              {formState?.stockOption?.isRange === "RANGE" && (
                <>
                  <DecimalField
                    name="stock-option-min"
                    placeholder="Min"
                    value={position?.criteria?.stockOption?.min || ""}
                    onChange={(value) =>
                      handleNumericCriteriaChange("stockOption", "min", value)
                    }
                  />
                  <DecimalField
                    name="stock-option-max"
                    placeholder="Max"
                    value={position?.criteria?.stockOption?.max || ""}
                    onChange={(value) =>
                      handleNumericCriteriaChange("stockOption", "max", value)
                    }
                  />
                </>
              )}
              {formState?.stockOption?.isRange === "CERTAIN" && (
                <DecimalField
                  name="stock-option-certain"
                  placeholder="Amount"
                  value={position?.criteria?.stockOption?.certainValue || ""}
                  onChange={(value) =>
                    handleNumericCriteriaChange(
                      "stockOption",
                      "certainValue",
                      value
                    )
                  }
                />
              )}
              <PercentageContainer> % </PercentageContainer>
            </InlineInputs>
          </ColumnBlock>
        </RowBlock>
        <RowBlock mt={2}>
          <ColumnBlock width="100%">
            <SelectField
              label="Would you like to display the amount to candidates?"
              name="stock-option-visibility"
              defaultValue={{
                label: formState.stockOption.isVisibleToUser ? "Yes" : "No",
              }}
              options={STOCK_OPTION_VISIBILITY_TYPES}
              onChange={(event) => {
                handleSelectChange("stockOption", "isVisibleToUser", event);
              }}
            />
          </ColumnBlock>
        </RowBlock>
      </FormScaffold>
    </div>
  );
}
