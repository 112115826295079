import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import * as Yup from "yup";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tag,
  TextField,
  Button,
} from "tg-design";
import PartialError from "../../../ErrorPage/PartialError";
import { RowBlock } from "../../../Form/BlockUi";
import {
  UPDATE_PASS_REASON,
  GET_REJECT_REASON,
  CREATE_REJECT_REASON,
} from "../../../position/queries";

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 3px 10px #dddddd;
  border-radius: 20px;
  padding: 5px;
  background: #ffffff;

  &.saved {
    background: #c7eed5;
  }
  &.other {
    padding: 0;
    width: 280px;
  }
`;
const HeaderContainer = styled.div`
  flex-direction: row;
`;

const validationSchema = Yup.object().shape({
  otherReason: Yup.string()
    .required("Required")
    .min(5, "Pass reason must contain at least 5 letters."),
});

export default function PassReasonModal({
  matchId,
  userId,
  onClose,
  onCompleted,
}) {
  const [state, setState] = useState([]);
  const [validationErr, setValidationErr] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [loading, setLoading] = useState(null);

  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(GET_REJECT_REASON);
  const [createRejectReason, { error: rejectMutationError }] =
    useMutation(CREATE_REJECT_REASON);
  const [updatePassReason] = useMutation(UPDATE_PASS_REASON);

  const handleRejectReasonSubmit = async (rejectReasons) => {
    try {
      setLoading(true);
      if (otherReason) {
        await validationSchema.validate({ otherReason });
        setLoading(true);
        const newRejectReason = await createRejectReason({
          variables: {
            creator: userId,
            label: otherReason,
            type: 0,
          },
        });
        await updatePassReason({
          variables: {
            id: matchId,
            rejectReason: [
              ...rejectReasons,
              newRejectReason.data.createRejectReason.id,
            ],
          },
        });
      } else {
        await updatePassReason({
          variables: {
            id: matchId,
            rejectReason: rejectReasons,
          },
        });
      }
      await onCompleted();
      setLoading(false);
    } catch (error) {
      if (error.name === "ValidationError") {
        setValidationErr(error);
      }
      setLoading(false);
    }
  };

  const handleClick = async (e, rejectReason) => {
    const names = e.currentTarget.className.split(" ");
    if (!names.includes("saved")) {
      names.push("saved");
      e.currentTarget.className = names.join(" ");
      setState([...state, rejectReason]);
    } else {
      names.pop("saved");
      const filteredNames = names.filter((item) => item !== "saved");
      e.currentTarget.className = filteredNames.join(" ");
      const filteredState = state.filter((item) => item !== rejectReason);
      setState([...filteredState]);
    }
  };

  const handleOtherReasonClick = () => {
    const inputField = document.getElementById("textfield");
    const inputElement = document.getElementById("other");

    inputField.focus();
    inputElement.classList.add("saved");
  };

  const handleClear = (e) => {
    const inputField = document.getElementById("textfield");
    const inputElement = document.getElementById("other");

    inputField.value = "";
    inputElement.classList.remove("saved");

    setOtherReason("");
    setValidationErr("");

    e.stopPropagation();
  };
  if (queryLoading) return <div>Loading...</div>;
  if (queryError) return <PartialError />;

  const items = data.allRejectReason.map((item) => (
    <TagContainer key={item.id} onClick={(e) => handleClick(e, item.id)}>
      <Tag fontSize="14px" backgroundColor="inherit" content={item.label} />
    </TagContainer>
  ));

  const otherReasonField = (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      Other:{" "}
      <TextField
        id="textfield"
        clearable
        handleClear={handleClear}
        value={otherReason}
        onChange={(e) => setOtherReason(e.target.value)}
        hasError={validationErr && validationErr.message}
        containerStyle={{ marginBottom: 0 }}
      />
    </div>
  );

  return (
    <Modal handleClose={onClose}>
      <ModalHeader>
        <HeaderContainer>Why did you pass this position?</HeaderContainer>
      </ModalHeader>

      <ModalBody>
        <RowBlock
          justify="start"
          gridGap="10px"
          pt={{ mobileS: 1, tablet: 2, laptop: 3 }}
          pb={{ mobileS: 1, laptop: 2 }}
        >
          {items}
          <TagContainer
            onClick={(e) => handleOtherReasonClick(e)}
            id="other"
            className="other"
          >
            <Tag
              fontSize="14px"
              backgroundColor="inherit"
              content={otherReasonField}
            />
          </TagContainer>
        </RowBlock>

        {rejectMutationError && <div>Something went wrong...</div>}
      </ModalBody>
      <ModalFooter>
        <Button
          mt={2}
          onClick={() => handleRejectReasonSubmit(state)}
          variant="primary"
          size={{ mobileS: "large", tablet: "medium", laptop: "large" }}
          disabled={state.length === 0 && !otherReason}
        >
          Submit
          {loading && <div>Loading...</div>}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
