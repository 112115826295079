import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 34px;
`;

export const Description = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

export const SecondaryDescription = styled.div`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #9ca3af;
`;

export const StatusCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 195px;
  background: #ffffff;
  border: 3px dashed #64b7dc;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #64b7dc;
`;

export const DeveloperCard = styled.div`
  width: 195px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
`;

export const DeveloperName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;

export const MatchRate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;

export const DeveloperRole = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`;
