import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Space, message } from "antd";
import { useMutation } from "@apollo/client";
import { Button } from "tg-design";
import { NewContactModal } from "../../modules/NewContactModal";
import { TgTable, TgButtonField } from "../../../tg-styles/Layouts";
import {
  CREATE_EMPLOYER,
  UPDATE_EMPLOYER,
  REMOVE_EMPLOYER,
  INVITE_EMPLOYER,
  NOTIFY_EMPLOYER_FOR_COMPANY,
  GET_EXISTING_EMPLOYERS,
} from "../../queries";
import { InterestedIcon, CloseIcon } from "../../../Icons";
import { handleCopy, removeWhiteSpace } from "../../../../helper";
import { ExistingContactModal } from "../../modules/ExistingContactModal";
import { EMPLOYER_TYPES } from "../../../../constants";

export default function ContactsTab({ contacts, refetch }) {
  const { companyId } = useParams();

  const [createCompanyUser] = useMutation(CREATE_EMPLOYER, {
    onCompleted: async () => {
      await refetch();
    },
  });
  const [updateCompanyUser] = useMutation(UPDATE_EMPLOYER, {
    onCompleted: async () => {
      await refetch();
    },
  });

  const [removeEmployer] = useMutation(REMOVE_EMPLOYER, {
    onCompleted: async () => {
      await refetch();
    },
    refetchQueries: [
      {
        query: GET_EXISTING_EMPLOYERS,
        variables: { companyId },
      },
    ],
  });

  const [inviteEmployer] = useMutation(INVITE_EMPLOYER, {
    onCompleted: async () => {
      await refetch();
    },
  });

  const [notifyEmployerForCompany] = useMutation(NOTIFY_EMPLOYER_FOR_COMPANY);

  const [isNewContactModalVisible, setIsNewContactModalVisible] =
    useState(false);
  const [isExistingModalVisible, setIsExistingModalVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const showNewContactModal = (val) => {
    setIsNewContactModalVisible(true);
    if (val) {
      setSelectedValue(val);
    }
  };

  const showExistingContactModal = () => {
    setIsExistingModalVisible(true);
  };

  const handleOk = () => {
    setSelectedValue();
  };

  const handleCancel = () => {
    setIsNewContactModalVisible(false);
    setSelectedValue();
  };

  const createEmployer = async (val) => {
    val.email = removeWhiteSpace(val.email);
    try {
      await createCompanyUser({ variables: { ...val, company: companyId } });
      setIsNewContactModalVisible(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const removeFromCompany = async (val) => {
    try {
      await removeEmployer({
        variables: {
          id: val.id,
          companyId,
        },
      });
    } catch (e) {
      message.error(e.message);
    }
  };

  const copyInvitationLink = (val) => {
    const invitationLink = `${process.env.REACT_APP_COMPANY_URL}/register?invite=${val.invite.token}`;
    handleCopy(invitationLink);
  };

  const inviteEmployerToCompanyApp = async (val) => {
    try {
      await inviteEmployer({
        variables: {
          id: val.id,
        },
      });
    } catch (error) {
      return message.error("Error!");
    }
    message.success("Invited");
  };

  const notifyEmployer = async ({ id }) => {
    try {
      await notifyEmployerForCompany({
        variables: {
          employerId: id,
          companyId,
        },
      });
    } catch (error) {
      return message.error(error.message ? error.message : "Error!");
    }
    message.success("Notified");
  };

  const editEmployer = async (val) => {
    val.email = removeWhiteSpace(val.email);
    try {
      await updateCompanyUser({
        variables: { ...val, id: selectedValue.id, company: companyId },
      });
      setIsNewContactModalVisible(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const contactColumns = [
    {
      title: "Fullname",
      dataIndex: "fullName",
      render: (value, record) => (
        <div>
          {value}{" "}
          {record.isTest && <div style={{ color: "#ff7675" }}>-Test-</div>}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => <span>{role || ""}</span>,
    },
    {
      title: "Access Type",
      dataIndex: "permission",
      render: (permission) => (
        <span>
          {permission?.role === EMPLOYER_TYPES.ADMIN ? "Admin" : "Member" || ""}
        </span>
      ),
    },
    {
      title: "Registered",
      dataIndex: "isSign",
      align: "center",
      render: (sign) => {
        if (!sign) {
          return <CloseIcon />;
        }
        return <InterestedIcon />;
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (val) => {
        if (val.isSign) {
          return (
            <Button
              variant="text"
              size="xs"
              onClick={() => notifyEmployer(val)}
            >
              Notify
            </Button>
          );
        }
        if (!val.invite) {
          return (
            <Button
              variant="text"
              size="xs"
              onClick={() => inviteEmployerToCompanyApp(val)}
            >
              Invite
            </Button>
          );
        }
        if (!val.isSign && !val.invite.token) {
          return (
            <Button
              variant="text"
              size="xs"
              onClick={() => inviteEmployerToCompanyApp(val)}
            >
              Invite
            </Button>
          );
        }
        if (!val.isSign && val.invite.token) {
          return (
            <Space size="small">
              <Button
                variant="text"
                size="xs"
                onClick={() => inviteEmployerToCompanyApp(val)}
              >
                Re-Invite
              </Button>
              <Button
                variant="text"
                size="xs"
                onClick={() => copyInvitationLink(val)}
              >
                Copy Invite Link
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (val) => (
        <Button variant="text" size="xs" onClick={() => removeFromCompany(val)}>
          Remove
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (val) => (
        <Button
          variant="text"
          size="xs"
          onClick={() => showNewContactModal(val)}
          disabled={val.isSign}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div style={{ maxWidth: "600px" }}>
      <TgTable
        columns={contactColumns}
        dataSource={contacts}
        pagination={false}
        rowKey="id"
      />
      <br />
      <TgButtonField>
        <Button onClick={() => showNewContactModal()}>
          Create new contact
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          variant="secondary"
          onClick={showExistingContactModal}
        >
          Add existing contact
        </Button>
      </TgButtonField>
      {isNewContactModalVisible && (
        <NewContactModal
          visible
          onCancel={handleCancel}
          handleOk={handleOk}
          createEmployer={createEmployer}
          updateEmployer={editEmployer}
          selectedValue={selectedValue}
        />
      )}
      {isExistingModalVisible && (
        <ExistingContactModal
          setIsExistingModalVisible={setIsExistingModalVisible}
        />
      )}
    </div>
  );
}
