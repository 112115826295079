import React from "react";
import { Form, Space, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "tg-design";

const { Option } = Select;

export default function SkillInput({ allSkillData = [] }) {
  const [form] = Form.useForm();
  return (
    <Form.List form={form} name={["skills"]}>
      {(fields, { add, remove }) => (
        <>
          {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
          {fields.map((field, index) => (
            <Space key={index} align="baseline">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.skills !== curValues.skills
                }
              >
                {() => (
                  <Form.Item
                    {...field}
                    name={[field.name, "id"]}
                    fieldKey={[field.fieldKey, "id"]}
                  >
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select Skill"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {allSkillData.allTechnology.technologies.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>

              <MinusCircleOutlined
                onClick={() => remove(field.name)}
                style={{ marginRight: "20px" }}
              />
            </Space>
          ))}

          <Form.Item>
            <Button
              variant="text"
              dashed
              onClick={() => add()}
              block
              size="xs"
              style={{
                color: "#7F7F7F",
              }}
            >
              <PlusOutlined />
              Add Skill
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}
