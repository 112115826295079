import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { POSITION_STATES } from "../../../../../constants/states";

export const BoxTitle = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  margin: 0px 0px 20px 0px;
`;

export const RowBreaker = styled.hr`
  margin: 40px 0px;
  border-top: 1px solid #ebebeb;
  border-left: none;
`;

dayjs.extend(relativeTime);

export const getPositionStartDate = (position) => {
  if (!position.history) {
    return null;
  }

  const inProgressStep = position.history.find(
    (item) => item.current === POSITION_STATES.IN_PROGRESS
  );

  if (!inProgressStep) {
    return null;
  }

  return inProgressStep.createdAt;
};

export const getPositionStartDateFromNow = (position) => {
  const date = getPositionStartDate(position);
  if (!date) {
    return "-";
  }

  return dayjs(date).fromNow();
};

export const getStartedDay = (position) => {
  const startDate = getPositionStartDate(position);
  if (!startDate) {
    return "-";
  }
  const diff = dayjs().diff(dayjs(startDate), "days", true);
  return Math.ceil(diff);
};
