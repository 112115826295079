import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import { Button } from "tg-design";
// eslint-disable-next-line
import moment from "moment";
import { DatePicker, Space } from "antd";
import { GET_CATEGORIZED_ANALYTICS } from "../queries";
import CollapsableCard from "./modules/CollapsableCard";
import PartialError from "../../ErrorPage/PartialError";

const CardLayout = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 20px;
  flex-wrap: wrap;
  gap: 20px;
`;

const tables = [
  {
    key: "mostRoleOfDevelopers",
    title: "Cumilative # of role of developers",
    value: [],
  },
  {
    key: "mostTechStackCategoryOfDevelopers",
    title: "Most preffered tech stacks by category for developers ",
    value: [],
  },
  {
    key: "mostTechStackCategoryOfCompanies",
    title: "Most preffered tech stacks by category for companies ",
    value: [],
  },
  {
    key: "salaryAverageByUserRole",
    title:
      "Avg. salary expectation grouped by role of developer (USD, Monthly, Gross)",
    value: [],
  },
  {
    key: "topAccountCloseReasons",
    title: "Top Account Close Reason",
    value: [],
  },
];

export default function CategorizedAnalytics() {
  const [getAnalytics, { data, error }] = useLazyQuery(
    GET_CATEGORIZED_ANALYTICS
  );

  const [state, setState] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const handleOnChange = (_, dateValue) => {
    setState({ ...state, startDate: dateValue[0], endDate: dateValue[1] });
  };

  const handleOnClick = () => {
    const { startDate, endDate } = state;
    getAnalytics({ variables: { startDate, endDate } });
  };

  if (data && data.analytics) {
    const keys = Object.keys(data.analytics);

    tables.map((column) => {
      if (keys.includes(column.key)) {
        column.value = data.analytics[column.key];
      }
      return column;
    });
  }

  useEffect(() => {
    handleOnClick();
    // eslint-disable-next-line
  }, []);

  if (error) return <PartialError />;

  return (
    <div>
      <Space direction="horizontal" size={12}>
        <DatePicker.RangePicker
          defaultValue={[moment().startOf("month"), moment().endOf("month")]}
          ranges={{
            "This Week": [moment().startOf("week"), moment()],
            "Last Week": [
              moment().subtract(1, "weeks").startOf("week"),
              moment().subtract(1, "weeks").endOf("week"),
            ],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last 90 Days": [moment().subtract(3, "month"), moment()],
          }}
          onChange={handleOnChange}
        />
        <Button size="sm" onClick={handleOnClick}>
          Calculate
        </Button>
      </Space>
      <CardLayout>
        {data &&
          tables.map((item) => {
            return (
              <CollapsableCard
                key={item.key}
                title={item.title}
                data={item.value}
                mt={2}
                open
              />
            );
          })}
      </CardLayout>
    </div>
  );
}
