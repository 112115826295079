import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { RefreshIcon } from "../../Icons";

const CalculateMatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  border-radius: 4px;
  height: 100px;
  padding: 14px;
`;

const CalculateMatchButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
`;

export default function CalculateMatchCount({
  loading = false,
  matchCount = null,
  isCriteriaChanged,
  handleClick,
}) {
  return (
    <CalculateMatchContainer>
      {loading && <Spin size="small" />}
      {!loading && (
        <>
          {isCriteriaChanged === false && matchCount !== null && (
            <>
              <div>Currently matching:</div>
              <div>
                <b>{matchCount}</b> users
              </div>
            </>
          )}
          <div onClick={handleClick}>
            {isCriteriaChanged ? (
              <CalculateMatchButton style={{ marginTop: "10px" }}>
                <span>Recalculate</span> <RefreshIcon />
              </CalculateMatchButton>
            ) : (
              <CalculateMatchButton>Calculate Match Count</CalculateMatchButton>
            )}
          </div>
        </>
      )}
    </CalculateMatchContainer>
  );
}
