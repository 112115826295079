import React, { useRef, useEffect } from "react";
import { Label, Input } from "../FormUi";
import IsRequired from "../IsRequired";

const fieldStyle = {
  display: "flex",
  flexDirection: "column",
};

const TextField = ({ isRequired = false, autoFocus = false, ...props }) => {
  const inputRef = useRef(null);
  const { error, label, value, otherStyle } = props;

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ ...fieldStyle, ...otherStyle }}>
        {label && (
          <Label mb={1}>
            {label} {isRequired && <IsRequired />}
          </Label>
        )}
        <Input ref={inputRef} value={value} {...props} />
        {error ? (
          <Label my={2} hasError={error}>
            {error}
          </Label>
        ) : null}
      </div>
    </>
  );
};

export default TextField;
