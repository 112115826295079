import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { POSITION_STATE_TITLES } from "../../../constants";
import { TgCard } from "../Layouts";
import { WaitingIcon, InterestedIcon } from "../../Icons";

const TgAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 40px;
`;

const MatchDescription = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin: 14px 24px;
  font-size: 14px;
  font-weight: 400;
`;

const IconContainer = styled.div`
  display: inline-block;
  min-width: 24px !important;
  position: relative;
  top: ${(props) => (props.top ? props.top : "4px")}; ;
`;

const matchDescription = (matches) => {
  const { INTERESTED, CREATED } = matches.matchCounter;
  if (INTERESTED === 0 && CREATED === 0) {
    return (
      <MatchDescription>
        <p id="greating">
          Great! No awaiting actions for this position.{" "}
          <span role="img" aria-label="Party" aria-labelledby="greating">
            🎉
          </span>
        </p>
      </MatchDescription>
    );
  }
  return (
    <MatchDescription>
      <div>
        <IconContainer>
          <WaitingIcon />
        </IconContainer>
        {CREATED} waiting
      </div>
      <div>
        <IconContainer top="5px">
          <InterestedIcon />
        </IconContainer>
        {INTERESTED} interested
      </div>
    </MatchDescription>
  );
};

export default function TgPositionCard({ value }) {
  const { company, id, title, matches, state, createdAt } = value;
  const positionState = POSITION_STATE_TITLES[state];
  const timeFromCreation = dayjs(createdAt).fromNow();

  return (
    <Link to={`/positions/${id}?tab=2`}>
      <TgCard flexDirection="column" width="300px" height="300px">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <TgAvatar src={company.logo} />
          <span style={{ paddingLeft: "10px", lineHeight: "22px" }}>
            {company.name}
          </span>
        </div>

        <p
          style={{
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "20px",
            margin: "auto 24px",
          }}
        >
          <span>{title}</span>
        </p>

        {matchDescription(matches)}

        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            margin: "auto 24px",
          }}
        >
          <span>
            {positionState} {timeFromCreation}
          </span>
        </p>
      </TgCard>
    </Link>
  );
}
