import React from "react";
import ReadMore from "../../../../../ReadMore";
import {
  GroupTitle,
  ColumnTitle,
  DescriptionText,
  LinkedinSectionContainer,
} from "./styles";

import { RowBlock, ColumnBlock } from "../../../../../Form/BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../../../../Icons";

export const AboutTab = ({
  checked,
  linkedinData,
  handleStateChange,
  user,
}) => {
  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>About</GroupTitle>

      <RowBlock>
        <ColumnBlock width="50%" justifyContent="flex-start">
          <ColumnTitle>Current</ColumnTitle>
          <DescriptionText>
            <ReadMore text={user.bio ? user.bio : "none"} limit={250} />
          </DescriptionText>
        </ColumnBlock>
        <ColumnBlock width="50%" justifyContent="flex-start">
          <ColumnTitle>From Linkedin</ColumnTitle>
          <LinkedinSectionContainer
            isChecked={checked.bio}
            onClick={() => handleStateChange("bio", linkedinData.bio)}
          >
            <div style={{ flex: "1" }}>
              <DescriptionText>
                <ReadMore
                  text={linkedinData.bio ? linkedinData.bio : "none"}
                  limit={250}
                />
              </DescriptionText>
            </div>
            <div>{checked.bio ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}</div>
          </LinkedinSectionContainer>
        </ColumnBlock>
      </RowBlock>
    </div>
  );
};
