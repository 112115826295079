import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { useQuery } from "@apollo/client";
import { MarkdownContent } from "tg-design";
import { GET_MATCH } from "./queries";
import DeveloperProfile from "./DeveloperProfile/index";
import { BackgroundTab } from "./SidebarTabs";
import GeneralInfoTab from "../developers/detail/tabs/GeneralInfoTab";
import PartialError from "../ErrorPage/PartialError";
import logoImage from "../../images/talentgrid-logo-dark.png";
import {
  Row,
  SectionTitle,
  SectionDescription,
} from "../developers/detail/tabs/GeneralInfoTab/styles";

export default function MatchPrintPage() {
  const { matchId } = useParams();
  const [match, setMatchData] = useState();

  const { loading, error } = useQuery(GET_MATCH, {
    variables: { id: matchId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.errors) {
        setMatchData(data.match);
        const username = data.match.user.name;
        window.document.title = `${username} - TalentGrid`;
      }
    },
  });

  useEffect(() => {
    if (match) {
      setTimeout(window.print, 2000); //  beacuse of the font family not loaded. we must use the local fonts
    }
  }, [match]);

  if (loading) return <Spin />;

  if (error) return <PartialError />;

  const ConversationTab = ({ conversation }) => {
    return (
      <Row>
        <SectionTitle fontSize="16px">TG Notes:</SectionTitle>
        <SectionDescription style={{ marginBottom: "20px" }}>
          <MarkdownContent>{conversation.note}</MarkdownContent>
        </SectionDescription>
      </Row>
    );
  };

  const TGBrand = () => (
    <>
      <div style={{ float: "right", marginRight: "20px" }}>
        <img style={{ width: "120px" }} src={logoImage} alt="TG Logo" />
      </div>
    </>
  );

  return (
    <>
      <TGBrand />
      {match && (
        <div style={{ pageBreakInside: "avoid" }}>
          <DeveloperProfile match={match} />
          <GeneralInfoTab user={match.user} />
          {match?.conversation && match?.conversation.length > 0 && (
            <ConversationTab conversation={match?.conversation[0]} />
          )}
          <BackgroundTab match={match} />
        </div>
      )}
    </>
  );
}
