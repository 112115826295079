const MATCH_STATES = {
  EXTERNAL_SOURCING: "EXTERNAL_SOURCING",
  CREATED: "CREATED",
  SENT: "SENT",
  INTERESTED: "INTERESTED",
  TG_ASSESSMENT: "TG_ASSESSMENT",
  COMPANY_ASSESSMENT: "COMPANY_ASSESSMENT",
  INTERVIEW: "INTERVIEW",
  SENT_OFFER: "SENT_OFFER",
  HIRED: "HIRED",
  PASSED: "PASSED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
};

export const MATCH_STATE_TITLES = {
  [MATCH_STATES.CREATED]: "Created",
  [MATCH_STATES.SENT]: "Sent",
  [MATCH_STATES.INTERESTED]: "Interested",
  [MATCH_STATES.TG_ASSESSMENT]: "In Assessment",
  [MATCH_STATES.COMPANY_ASSESSMENT]: "Company Assessment",
  [MATCH_STATES.INTERVIEW]: "Interview",
  [MATCH_STATES.SENT_OFFER]: "Offer Sent",
  [MATCH_STATES.HIRED]: "Filled",
  [MATCH_STATES.PASSED]: "Passed",
  [MATCH_STATES.FAILED]: "Failed",
  [MATCH_STATES.CANCELED]: "Canceled",
};

export const POSITIVE_ACTION_BUTTON_TITLES = {
  [MATCH_STATES.SENT]: "Send Match",
  [MATCH_STATES.TG_ASSESSMENT]: "Proceed to Interview",
  [MATCH_STATES.COMPANY_ASSESSMENT]: "Share with Company",
  [MATCH_STATES.INTERVIEW]: "Proceed to Interview",
  [MATCH_STATES.SENT_OFFER]: "Proceed to Offer Sent",
  [MATCH_STATES.HIRED]: "Hired Successfully",
  [MATCH_STATES.PASSED]: "Candidate Passed",
};

export const NEGATIVE_ACTION_BUTTON_TITLES = {
  [MATCH_STATES.PASSED]: "Candidate Passed",
  [MATCH_STATES.FAILED]: "Failed",
  [MATCH_STATES.CANCELED]: "Canceled",
};

export const REVIVE_ACTION_BUTTON_TITLES = {
  [MATCH_STATES.CREATED]: "Created",
  [MATCH_STATES.TG_ASSESSMENT]: "TG Assessment",
  [MATCH_STATES.COMPANY_ASSESSMENT]: "Company Assessment",
  [MATCH_STATES.INTERVIEW]: "Interview",
  [MATCH_STATES.SENT_OFFER]: "Offer Sent",
};

export const MATCH_STATE_BG_COLORS = {
  [MATCH_STATES.COMPANY_ASSESSMENT]: "#BFBFBF",
  [MATCH_STATES.INTERVIEW]: "#FBDB68",
  [MATCH_STATES.SENT_OFFER]: "#64B7DC",
  [MATCH_STATES.HIRED]: "#8FDEAC",
  [MATCH_STATES.FAILED]: "#D98787",
};

export const MATCH_STATE_COLORS = {
  [MATCH_STATES.COMPANY_ASSESSMENT]: "#444",
  [MATCH_STATES.INTERVIEW]: "#444",
  [MATCH_STATES.SENT_OFFER]: "white",
  [MATCH_STATES.HIRED]: "#333",
  [MATCH_STATES.FAILED]: "white",
};

export const POSITION_STATES = {
  ON_HOLD: "ON_HOLD",
  CREATED: "CREATED",
  SUBMITTED: "SUBMITTED",
  IN_PROGRESS: "IN_PROGRESS",
  FILLED: "FILLED",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED",
};
const { ON_HOLD, CANCELED, CLOSED, CREATED, FILLED, IN_PROGRESS, SUBMITTED } =
  POSITION_STATES;

export const POSITION_RESTRICTIONS = [
  { id: ON_HOLD, name: "On Hold", restrictions: [CLOSED, CANCELED] },
  {
    id: CREATED,
    name: "Created",
    restrictions: [IN_PROGRESS, FILLED, ON_HOLD, CLOSED, CANCELED],
  }, // CREATED optionu position state:  IN_PROGRESS, FILLED ... durumlarinda disabled
  {
    id: SUBMITTED,
    name: "Awaiting Approval",
    restrictions: [CREATED, IN_PROGRESS, FILLED, ON_HOLD, CLOSED, CANCELED],
  },
  {
    id: IN_PROGRESS,
    name: "In Progress",
    restrictions: [FILLED, CLOSED],
  },
  {
    id: FILLED,
    name: "Filled",
    restrictions: [CREATED, CLOSED, CANCELED, ON_HOLD],
  },
  {
    id: CLOSED,
    name: "Closed",
    restrictions: [CREATED, IN_PROGRESS, CANCELED, ON_HOLD],
  },
  { id: CANCELED, name: "Canceled", restrictions: [CLOSED] },
];

const MATCH_STATE_ALLOWANCE_LIST = {
  [MATCH_STATES.EXTERNAL_SOURCING]: [],
  [MATCH_STATES.CREATED]: [MATCH_STATES.CANCELED],
  [MATCH_STATES.SENT]: [MATCH_STATES.CREATED],
  [MATCH_STATES.INTERESTED]: [],
  [MATCH_STATES.PASSED]: [
    MATCH_STATES.TG_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.EXTERNAL_SOURCING,
  ],
  [MATCH_STATES.TG_ASSESSMENT]: [MATCH_STATES.INTERESTED],
  [MATCH_STATES.COMPANY_ASSESSMENT]: [MATCH_STATES.TG_ASSESSMENT],
  [MATCH_STATES.INTERVIEW]: [MATCH_STATES.COMPANY_ASSESSMENT],
  [MATCH_STATES.SENT_OFFER]: [MATCH_STATES.INTERVIEW],
  [MATCH_STATES.HIRED]: [MATCH_STATES.SENT_OFFER],
  [MATCH_STATES.FAILED]: [
    MATCH_STATES.TG_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.INTERESTED,
  ],
  [MATCH_STATES.CANCELED]: [MATCH_STATES.CREATED],
};

export { MATCH_STATES, MATCH_STATE_ALLOWANCE_LIST };
