import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { space } from "styled-system";
import { message } from "antd";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonLink,
} from "tg-design";
import { ColumnBlock } from "../../../../../Form/BlockUi";
import { captureErrorWithData, useModal } from "../../../../../../helper";
import { DeleteIcon } from "../../../../../Icons";
import { UPLOAD_RESUME, DELETE_RESUME } from "../../../../queries";
import FormScaffold from "../../../../../Form/FormScaffold";

const Wrapper = styled.div`
  ${space}
  position: relative;
`;

const Container = styled.div`
  display: flex;
  gap: 15px;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

export default function ResumeSection({
  id,
  resume,
  getInputStatus,
  setInputStatus,
}) {
  const [uploadResume, { loading }] = useMutation(UPLOAD_RESUME);
  const [deleteResume] = useMutation(DELETE_RESUME);

  const [currentResume, setCurrentResume] = useState(resume || {});

  const { showModal, setShowModal } = useModal();

  let fileInput = useRef(null);

  const [selectedFile, setSelectedFile] = useState();

  const onSelectFile = (e) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setSelectedFile(files);
      setShowModal();
    }
  };

  const onInputClick = (e) => {
    e.target.value = ""; // when choose same image, to trigger input onChange
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (selectedFile[0].size > 10485760) {
      setShowModal(false);
      return message.error("This file is larger than our 10 MB size limit.");
    }
    if (selectedFile[0].type !== "application/pdf") {
      setShowModal(false);
      return message.error("File type should be pdf");
    }
    try {
      setInputStatus({ name: "resume.resumeURL", value: "pending" });
      const res = await uploadResume({
        variables: { id, resume: selectedFile[0] },
      });
      if (res) {
        setCurrentResume(res.data.uploadResume);
        setInputStatus({ name: "resume.resumeURL", value: "success" });
        setShowModal(false);
      }
    } catch (error) {
      setInputStatus({ name: "resume.resumeURL", value: "error" });
      message.error("Something went wrong!");
      setShowModal(false);
      captureErrorWithData(error);
    }
  };

  const handleClick = () => {
    fileInput.click();
  };

  const removeResume = async () => {
    try {
      setInputStatus({ name: "resume.resumeURL", value: "pending" });
      const res = await deleteResume({
        variables: { id },
      });
      if (res) {
        setCurrentResume({ fileName: null, resumeURL: null });
        setInputStatus({ name: "resume.resumeURL", value: "success" });
      }
    } catch (error) {
      message.error("Something went wrong!");
      setInputStatus({ name: "resume.resumeURL", value: "error" });
      captureErrorWithData(error);
    }
  };

  const setVal = (val) => {
    fileInput = val;
  };

  return (
    <>
      {showModal && (
        <Modal handleClose={setShowModal}>
          <ModalHeader>
            <div>Are you sure you want to upload this file?</div>
            <small>PDF files with less than 10 MB size are supported.</small>
          </ModalHeader>
          <ModalBody>
            <ColumnBlock>File name: {selectedFile[0].name}</ColumnBlock>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              size="large"
              onClick={handleFileSubmit}
              loading={loading}
            >
              Save CV
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <FormScaffold label="Resume" status={getInputStatus("resume.resumeURL")}>
        <Wrapper>
          <input
            type="file"
            accept="application/pdf"
            multiple={false}
            onChange={onSelectFile}
            onClick={onInputClick}
            style={{ display: "none" }}
            ref={(val) => setVal(val)}
          />
          {currentResume?.resumeURL ? (
            <Container>
              <ButtonLink
                href={currentResume.resumeURL}
                target="_blank"
                rel="noreferrer"
              >
                <span>{currentResume.fileName || "No name"}</span>
              </ButtonLink>

              <IconContainer onClick={removeResume}>
                <DeleteIcon />
              </IconContainer>
            </Container>
          ) : (
            <Button variant="secondary" outline size="sm" onClick={handleClick}>
              Upload
            </Button>
          )}
        </Wrapper>
      </FormScaffold>
    </>
  );
}
