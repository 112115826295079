import React, { useState, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { Button, ButtonLink, FormWrapper, TextField } from "tg-design";
import * as Yup from "yup";

import AuthTemplate from "../ForgotPassword/AuthTemplate";
import AppContext from "../contexts/AppContext";
import { FormContainer, inputStyles } from "../ForgotPassword/styles";
import { captureErrorWithData, handleHead } from "../../helper";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    loginAsRecruiter(email: $email, password: $password) {
      token
      userId
    }
  }
`;

const loginValidationSchema = Yup.object().shape({
  email: Yup.string().trim().email().required(),
  password: Yup.string().trim().min(6).max(100).required(),
});

const Login = () => {
  const appContext = useContext(AppContext);
  const [login] = useMutation(LOGIN);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isReadyToGo, setIsReadyToGo] = useState(false);
  const [email, setEmail] = useState("");

  handleHead("login");

  const handleLogin = async (e, values) => {
    try {
      setLoading(true);
      const { data } = await login({
        variables: { ...values },
      });
      if (data.loginAsRecruiter.token) {
        localStorage.setItem("isAuth", true);
        history.push("/");
      }
      setLoading(false);
      appContext.refresh();
    } catch (error) {
      setLoading(false);
      captureErrorWithData(error);
      message.error(error.message);
      localStorage.setItem("isAuth", false);
    }
  };

  const handleChange = (e, data) => {
    setEmail(data.email);
    setIsReadyToGo(loginValidationSchema.isValidSync(data));
  };

  return (
    <AuthTemplate title="Recruiter Login">
      <FormWrapper onChange={handleChange} onSubmit={handleLogin}>
        <FormContainer>
          <TextField
            name="email"
            label="Email"
            value={history.location?.state?.email || ""}
            autoFocus
            style={inputStyles}
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            style={inputStyles}
          />
          <Button
            type="submit"
            style={{ marginTop: "20px", width: "100%", marginBottom: "20px" }}
            disabled={!isReadyToGo}
            loading={loading}
          >
            Log In
          </Button>
          <ButtonLink
            onClick={() =>
              history.push({
                pathname: "/forgot-password",
                state: { email },
              })
            }
          >
            Forgot your password?
          </ButtonLink>
        </FormContainer>
      </FormWrapper>
    </AuthTemplate>
  );
};

export default <Login />;
