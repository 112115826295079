import React from "react";
import styled from "styled-components";

const UL = styled.ul`
  margin: 0px;
  list-style-type: none;
  padding-left: 0px;
`;

export default function SkillCriteriaLine({ skills }) {
  return (
    <UL>
      {skills.map((row, rowIndex) => (
        <li key={rowIndex}>
          {row.map((column, columnIndex) => (
            <span key={columnIndex}>
              {column.experience
                ? `${column.skill.title} ${column.experience} years`
                : column.title}
              {columnIndex < row.length - 1 && <strong> OR </strong>}
            </span>
          ))}
        </li>
      ))}
    </UL>
  );
}
