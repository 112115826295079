import React from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Tooltip } from "antd";
import { TgLayout } from "../components/tg-styles/Layouts";
import { GET_RECRUITER } from "../queries/general";
import { TgLogo, TgMenu, TgMenuItem } from "../components/tg-styles/sidebar";
import {
  HomeIcon,
  CompaniesIcon,
  PositionsIcon,
  PeopleIcon,
  ToolsIcon,
  ReportIcon,
} from "../components/Icons";
import RecruiterMenu from "./RecruiterMenu";
import PartialError from "../components/ErrorPage/PartialError";

const { Sider } = TgLayout;

const RecruiterMenuContainer = styled.span`
  position: fixed;
  bottom: 0px;
  margin-bottom: 22px;
  padding: 10px 10px 10px 19px;
`;

const IconContainer = styled.div``;

function SideBar({ showSider = true }) {
  const { loading, data: recruiterData, error } = useQuery(GET_RECRUITER);

  if (loading) return "loading...";
  if (error) return <PartialError />;

  return (
    <Sider
      theme="light"
      width="115"
      style={{
        display: showSider ? "auto" : "none",
        borderRight: "solid #C7C7C7 1px",
        height: "100vh",
        position: "fixed",
        left: 0,
        zIndex: 1,
      }}
    >
      <TgLogo width="35px" />
      <TgMenu
        mode="inline"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TgMenuItem
          route="/"
          icon={
            <Tooltip placement="right" title="Home">
              <IconContainer>
                <HomeIcon />
              </IconContainer>
            </Tooltip>
          }
          style={{ display: "relative" }}
        />

        <TgMenuItem
          route="/companies"
          icon={
            <Tooltip placement="right" title="Companies">
              <IconContainer>
                <CompaniesIcon />
              </IconContainer>
            </Tooltip>
          }
        />

        <TgMenuItem
          route="/positions"
          icon={
            <Tooltip placement="right" title="Positions">
              <IconContainer>
                <PositionsIcon />
              </IconContainer>
            </Tooltip>
          }
        />

        <TgMenuItem
          route="/developers"
          icon={
            <Tooltip placement="right" title="Developers">
              <IconContainer>
                <PeopleIcon />
              </IconContainer>
            </Tooltip>
          }
        />

        <TgMenuItem
          route="/analytics"
          icon={
            <Tooltip placement="right" title="Analytics">
              <IconContainer>
                <ReportIcon />
              </IconContainer>
            </Tooltip>
          }
        />

        {recruiterData.recruiter.role === "ADMIN" && (
          <TgMenuItem
            route="/tools"
            icon={
              <Tooltip placement="right" title="Tools">
                <IconContainer>
                  <ToolsIcon />
                </IconContainer>
              </Tooltip>
            }
          />
        )}
      </TgMenu>
      <RecruiterMenuContainer>
        <RecruiterMenu />
      </RecruiterMenuContainer>
    </Sider>
  );
}

export default SideBar;
