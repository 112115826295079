import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { message } from "antd";
import { selectStyles } from "./SelectStyles";
import { Label } from "../FormUi";
import { ColumnBlock } from "../BlockUi";
import { debounce } from "../../../helper";
import { StyledAsyncSelect } from "./styles";

const GET_CITIES = gql`
  query allPlaces($search: String, $showCountries: Boolean) {
    allPlaces(search: $search, showCountries: $showCountries) {
      places {
        id
        city
        country
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

const getCityLabel = (option, countryPrefix) => {
  if (option.city === option.country) {
    return `${countryPrefix} ${option.city}`.trim();
  }
  return `${option.city}, ${option.country}`;
};

export default function CitySelectField({
  textAlign,
  valueAlign,
  optionStyle,
  selectedBgColorful,
  onChange,
  defaultValue,
  value,
  showCountries,
  countryPrefix = "",
  ...props
}) {
  const { label, width } = props;
  const { refetch: searchCities } = useQuery(GET_CITIES, {
    skip: true,
  });

  const handleSelect = (values) => {
    onChange(values);
  };

  const loadOptions = useCallback(
    debounce(async (search, callback) => {
      try {
        const { data } = await searchCities({
          search,
          showCountries,
        });
        callback(data.allPlaces.places);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  return (
    <ColumnBlock width={width}>
      {label && <Label mb={1}>{label}</Label>}
      <StyledAsyncSelect
        {...props}
        styles={selectStyles({
          textAlign,
          valueAlign,
          optionStyle,
          selectedBgColorful,
        })}
        getOptionLabel={(option) => getCityLabel(option, countryPrefix)}
        getOptionValue={(option) => option.id}
        placeholder="Şehir ara"
        defaultValue={defaultValue}
        defaultOptions={[]}
        value={value}
        loadOptions={loadOptions}
        onChange={handleSelect}
      />
    </ColumnBlock>
  );
}
