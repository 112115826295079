import React from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { Tooltip } from "antd";

const StyledLi = styled.li`
  float: left;
  margin-top: 9px;
  border-bottom: 2px solid #fff;
  height: ${(props) => props.height || "55px"};
  &.active {
    border-bottom: 2px solid #64b7dc;
  }

  &:first-child {
    margin-left: -10px;
  }
`;

const StyledLink = styled.a`
  padding: 0px 10px 0px 10px;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => (props.isDisabled ? "#cccccc" : "#1a1a1a")};

  &.active {
    color: #64b7dc;
  }

  &:hover {
    color: ${(props) => (props.isDisabled ? "#cccccc" : "#64b7dc")};
  }
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function TgHeaderTab({
  name,
  handleChange,
  currentTab,
  children,
  classname,
  isNavigationAllowed,
  setIsNavigationAllowed,
  isDisabled,
  tooltipText,
  ...props
}) {
  const query = useQuery();
  const history = useHistory();
  const activeClass = currentTab === name ? "active" : "";

  const changeTab = (tabName) => {
    query.delete("search");
    query.delete("sort");
    query.set("tab", tabName);
    history.push({ search: query.toString() });
    handleChange(tabName);
  };

  const handleLocalChange = (tabName) => {
    if (isNavigationAllowed || isNavigationAllowed === undefined) {
      changeTab(tabName);
    }
    if (!isNavigationAllowed && isNavigationAllowed !== undefined) {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(
        "There are unsaved changes. Are you sure you want to change the page?"
      );
      if (answer) {
        changeTab(tabName);
        setIsNavigationAllowed(true);
      }
    }
  };

  return (
    <StyledLi className={classname || activeClass} {...props}>
      <Tooltip title={tooltipText}>
        <StyledLink
          className={classname || activeClass}
          isDisabled={isDisabled}
          onClick={() => !isDisabled && handleLocalChange(name)}
          {...props}
        >
          {children}
        </StyledLink>
      </Tooltip>
    </StyledLi>
  );
}
