import styled from "styled-components";
import { Input } from "antd";

const { Search } = Input;

export const TgButtonField = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  align-items: center;
`;

export const TgSearch = styled(Search)`
  .ant-input,
  .ant-input-search-button {
    height: ${(props) => (props.height ? props.height : "46px")};
  }
  .ant-input-search-button {
    background: #64b7dc;
    border-radius: 5px;
  }
`;
