import React, { useState } from "react";
import { Modal, ModalBody, toRichTextState, ModalHeader } from "tg-design";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { RowBlock } from "../../../../Form/BlockUi";
import NoteTab from "./tabs/NoteTab";
import PreviousNotesTab from "./tabs/PreviousNotesTab";
import { TgHeaderTab, TgHeaderTabs } from "../../../../tg-styles/TgHeaderTabs";
import { GET_PREVIOUS_CONVERSATIONS } from "../../../queries";
import { CONVERSATION_TYPES } from "../../../../../constants";
import PartialError from "../../../../ErrorPage/PartialError";

const TabsContainer = styled.div`
  margin-top: -26px;
`;

const NOTE_TEMPLATE = `
### **Soft skills**:
- 
- 
- 
### **Notable projects**:
- 
- 
- 
### **Motivation about the [COMPANY_NAME]**:
- 
- 
- 
### **Salary Expectation:**
- 
- 
- 
`;

const replaceNote = ({ companyName }) => {
  return NOTE_TEMPLATE.replace("[COMPANY_NAME]", companyName);
};

export default function NoteModal({
  match,
  tooltipText,
  isModalOpen,
  modalState,
  conversation,
  setIsModalOpen,
  handleSubmit,
  updateNote,
}) {
  const [state, setState] = useState({
    note:
      modalState.note ||
      toRichTextState(
        replaceNote({ companyName: match.position?.company?.name || "" })
      ),
  });

  const [currentTab, setCurrentTab] = useState("noteTab");

  const [previousInterviewDatas, setPreviousInterviewDatas] = useState([]);

  const { loading, error } = useQuery(GET_PREVIOUS_CONVERSATIONS, {
    variables: {
      userId: match.user.id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const previewInterviewNotes = data.getPreviousConversationsByUserId
        .filter((item) => item.id !== match.id)
        .map((item) => {
          return item.conversation
            .filter((n) => n.type === CONVERSATION_TYPES.MEETING)
            .map((i) => {
              return {
                id: item.id,
                creator: i.creator,
                position: item.position,
                note: i.note,
                createdAt: i.createdAt,
              };
            });
        })
        .map((item) => item[0]);

      setPreviousInterviewDatas(previewInterviewNotes);
    },
  });

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const getTab = () => {
    let TabContent = null;

    switch (currentTab) {
      case "noteTab":
        TabContent = (
          <NoteTab
            handleSubmit={handleSubmit}
            updateNote={updateNote}
            conversation={conversation}
            isModalOpen={isModalOpen}
            state={state}
            setState={setState}
            tooltipText={tooltipText}
          />
        );
        break;

      case "previousNotesTab":
        TabContent = (
          <PreviousNotesTab
            match={match}
            setCurrentTab={setCurrentTab}
            previousInterviewDatas={previousInterviewDatas}
            setState={setState}
          />
        );
        break;

      default:
        TabContent = (
          <NoteTab
            handleSubmit={handleSubmit}
            updateNote={updateNote}
            conversation={conversation}
            isModalOpen={isModalOpen}
            state={state}
            setState={setState}
            tooltipText={tooltipText}
          />
        );
        break;
    }

    return TabContent;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <PartialError />;

  return (
    <Modal
      height="80vh"
      width="800px"
      handleClose={handleClose}
      outsideClose={false}
    >
      <ModalHeader>Interview Note</ModalHeader>
      <ModalBody style={{ color: "#000000", height: "68vh" }}>
        <RowBlock justify="start">
          <TabsContainer>
            <TgHeaderTabs currentTab={currentTab} handleChange={setCurrentTab}>
              <TgHeaderTab height="30px" name="noteTab">
                Notes
              </TgHeaderTab>
              <TgHeaderTab height="30px" name="previousNotesTab">
                Previous Notes ({previousInterviewDatas.length})
              </TgHeaderTab>
            </TgHeaderTabs>
          </TabsContainer>
        </RowBlock>

        {getTab()}
      </ModalBody>
    </Modal>
  );
}
