import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { message } from "antd";
import { TgDrawerTitle } from "../../../../../tg-styles/Layouts";
import { SelectField, AsyncSelectField } from "../../../../../Form/SelectField";
import { InlineInputs, HalfSizeInput, ApplyButton, RowInput } from "../styles";
import { EDUCATION_TYPES, CURRENT_YEAR } from "../../../../../../constants";
import {
  debounce,
  createYearsList,
  rangeOfYears,
} from "../../../../../../helper";
import { educationValidationSchema } from "../validations";
import {
  SEARCH_UNIVERSITY,
  SEARCH_BRANCH,
} from "../../../../../../queries/education";

const START_YEARS = createYearsList();
const ALL_END_DATES = rangeOfYears(1950, CURRENT_YEAR + 10)
  .map((year) => {
    return { label: year, value: year };
  })
  .sort((a, b) => b.value - a.value);

export const getPossibleStartYears = (endDates) => {
  if (endDates) {
    return START_YEARS.filter((year) => year.value <= endDates);
  }
  return START_YEARS;
};

export const getPossibleEndYears = (startDates) => {
  if (startDates) {
    return ALL_END_DATES.filter((year) => year.value >= startDates);
  }
  return ALL_END_DATES;
};

const toFormState = (data) => {
  return {
    ...data,
    endDate: data.endDate || null,
  };
};

export default function EducationForm({ data = {}, onClose, onSave }) {
  const [isValid, setValid] = useState(false);
  const [form, setForm] = useState(toFormState(data));

  const { refetch: universityRefetch } = useQuery(SEARCH_UNIVERSITY, {
    skip: true,
  });
  const { refetch: branchRefetch } = useQuery(SEARCH_BRANCH, {
    skip: true,
  });

  const handleOptionChange = ({ value, label, type }) => {
    let _value = value;

    if (type === "school") {
      _value = {
        name: label,
        id: value,
      };
    }
    if (type === "branch") {
      _value = {
        label,
        id: value,
      };
    }

    const newState = {
      ...form,
      [type]: _value,
    };

    if (type === "startDate") {
      newState.possibleEndDates = ALL_END_DATES.filter(
        (date) => date.value === 0 || date.value >= value
      );
    }

    // Kullanıcı başlangıç tarihinden daha küçük bir bitiş tarihi seçemez.
    if (
      Number.isInteger(newState.startDate) &&
      Number.isInteger(newState.endDate) &&
      newState.endDate < newState.startDate &&
      newState.endDate > 0
    ) {
      newState.endDate = null;
    }

    setForm(newState);
  };

  const handleSchoolSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await universityRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        callback(
          data.allUniversity.universities.map((university) => {
            return { value: university.id, label: university.name };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleBranchSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await branchRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _branches = data.allUniversityBranch.universityBranches.map(
          (branch) => {
            return {
              value: branch?.id,
              label: branch?.label,
            };
          }
        );
        callback(_branches);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleSave = () => {
    onSave({
      school: form.school,
      branch: form.branch,
      type: form.type,
      endDate: form.endDate,
      startDate: form.startDate,
    });
  };

  useEffect(() => {
    educationValidationSchema
      .validate({
        school: form.school && form.school.id,
        branch: form.branch && form.branch.id,
        type: form.type,
      })
      .then(() => {
        setValid(true);
      })
      .catch(() => {
        setValid(false);
      });
  }, [form]);

  return (
    <>
      <TgDrawerTitle title="Education" handleClose={onClose} />
      <RowInput>
        <SelectField
          isRequired
          label="Education Level"
          isSearchable
          options={EDUCATION_TYPES}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={
            form.type !== null
              ? EDUCATION_TYPES.find((item) => item.value === form.type)
              : null
          }
          onChange={(selectedOption) =>
            handleOptionChange({
              ...selectedOption,
              type: "type",
            })
          }
        />
      </RowInput>
      <RowInput>
        <AsyncSelectField
          isRequired
          label="School attended"
          isSearchable
          loadOptions={handleSchoolSearch}
          onChange={(selectedOption) =>
            handleOptionChange({
              ...selectedOption,
              type: "school",
            })
          }
          value={form.school?.name ? { label: form.school?.name } : null}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
        />
      </RowInput>
      <RowInput>
        <AsyncSelectField
          isRequired
          label="Department"
          isSearchable
          loadOptions={handleBranchSearch}
          onChange={(selectedOption) =>
            handleOptionChange({
              ...selectedOption,
              type: "branch",
            })
          }
          value={
            form.branch?.label || form.branch?.label
              ? { label: form.branch?.label || form.branch?.label }
              : null
          }
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
        />
      </RowInput>
      <InlineInputs>
        <HalfSizeInput>
          <SelectField
            label="Year started"
            isSearchable
            options={getPossibleStartYears(form.endDate)}
            onChange={(selectedOption) =>
              handleOptionChange({
                ...selectedOption,
                type: "startDate",
              })
            }
            value={form.startDate ? { label: form.startDate } : null}
          />
        </HalfSizeInput>
        <HalfSizeInput>
          <SelectField
            label="Year finished"
            isSearchable
            options={getPossibleEndYears(form.startDate)}
            onChange={(selectedOption) =>
              handleOptionChange({
                ...selectedOption,
                type: "endDate",
              })
            }
            value={form.endDate ? { label: form.endDate } : null}
          />
        </HalfSizeInput>
      </InlineInputs>
      <ApplyButton onClick={handleSave} disabled={!isValid} />
    </>
  );
}
