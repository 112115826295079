import React from "react";
import styled from "styled-components";
import { TimelineHistory } from "tg-design";
import { MATCH_STATE_TITLES } from "../../../../constants/states";

export const Username = styled.span`
  font-family: Inter;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: #111827;
`;

const Candidate = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #444444;
`;

export default function HistoryItem({ history, match }) {
  const creator = history.creator || { name: "Somebody" };
  return (
    <TimelineHistory createdAt={history.createdAt} icon={history.icon}>
      <Username>{creator?.name}</Username> moved{" "}
      <Candidate>{match.user.name}</Candidate> to{" "}
      <i>{MATCH_STATE_TITLES[history.current]}</i>.
    </TimelineHistory>
  );
}
