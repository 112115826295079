import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import "./styles/index.css";
import App from "./App";
import { store } from "./store";

if (process.env.REACT_APP_HOST_ENV !== "development") {
  Sentry.init({
    dsn: "https://1e64027b6c2a41288617e0e6616dfa4a@o1064775.ingest.sentry.io/6519618",
    environment: process.env.REACT_APP_HOST_ENV,
    integrations: [new BrowserTracing()],
    ignoreErrors: [/Network\sError/i],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
