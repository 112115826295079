import * as Yup from "yup";

export const basicInfoValidationSchema = Yup.object().shape({
  bio: Yup.string().trim().max(1000).nullable(),
  "preferences.openToWork": Yup.boolean(),
  name: Yup.string().trim().required(),
  "social.linkedin": Yup.string()
    .matches(
      /^$|((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)([^/]+\/(.)))/
    )
    .nullable(),
  "social.github": Yup.string()
    .matches(
      /^$|^(?:https?:\/\/)?(?:www\.)?github\.com\/(?<login>[A-z0-9_-]+)\/?/
    )
    .nullable(),
  "social.stackoverflow": Yup.string()
    .matches(
      /^$|^(?:https?:\/\/)?(?:www\.)?stackoverflow\.com\/(?<login>[A-z0-9_-]+)\/?/
    )
    .nullable(),
  "social.twitter": Yup.string()
    .matches(
      /^$|^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?<login>[A-z0-9_-]+)\/?/
    )
    .nullable(),
  "social.youtube": Yup.string()
    .matches(
      /^$|^(https?:\/\/)?(www\.)?youtu((\.be)|(be\..{2,5}))\/((user)|(channel)|(c)|(@[A-z0-9_-]))\/?/
    )
    .nullable(),
  "social.blog": Yup.string().url().nullable(),
  "social.portfolio": Yup.string().url().nullable(),
  phone: Yup.string()
    .matches(/^\+(?:[0-9]●?){6,14}[0-9]$/, "This is not a valid phone number.")
    .required(`This is not a valid phone number.`),
});

export const workHistoryValidationSchema = Yup.object().shape({
  company: Yup.string().trim().required(""),
  position: Yup.string().trim().required(""),
  startDate: Yup.date().required(""),
  present: Yup.boolean().required(""),
  endDate: Yup.mixed()
    .nullable("")
    .when("present", (present, schema) => {
      if (!present) {
        return schema.required("");
      }
      return schema.nullable("");
    }),
});

export const educationValidationSchema = Yup.object().shape({
  school: Yup.string().trim().required(""),
  branch: Yup.string().trim().required(""),
  type: Yup.number().required(""),
});

export const languageValidationSchema = Yup.object().shape({
  language: Yup.string().trim().required(""),
  level: Yup.number().min(1).max(7).required(""),
});
