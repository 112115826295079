import React from "react";
import { Avatar } from "antd";
import { LinkedinProfilePhoto, GroupTitle, ColumnTitle } from "./styles";

import { RowBlock, ColumnBlock } from "../../../../../Form/BlockUi";

import { CheckedBoxIcon, UncheckedBoxIcon } from "../../../../../Icons";

export const ProfilePhotoTab = ({
  checked,
  linkedinData,
  handleStateChange,
  user,
}) => {
  const defaultAvatarURL = "/default_avatar.png";
  const recURL = process.env.PUBLIC_URL;
  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Profile Photo</GroupTitle>
      <RowBlock mt={3}>
        <ColumnBlock width="50%">
          <ColumnTitle>Current</ColumnTitle>
          <span>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              src={
                user.avatarURL ? user.avatarURL : `${recURL}${defaultAvatarURL}`
              }
            />
          </span>
        </ColumnBlock>
        <ColumnBlock width="50%">
          <ColumnTitle>From Linkedin</ColumnTitle>
          <LinkedinProfilePhoto
            isChecked={checked.avatarURL}
            onClick={() =>
              handleStateChange("avatarURL", linkedinData.avatarURL)
            }
          >
            <RowBlock alignItems="center">
              <Avatar
                style={{ display: "inline-block" }}
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                src={
                  linkedinData.avatarURL
                    ? linkedinData.avatarURL
                    : `${recURL}${defaultAvatarURL}`
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: "1",
                }}
              >
                {checked.avatarURL ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
              </div>
            </RowBlock>
          </LinkedinProfilePhoto>
        </ColumnBlock>
      </RowBlock>
    </div>
  );
};
