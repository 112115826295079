import React from "react";
import dayjs from "dayjs";
import { WorkExperienceIcon } from "../../../../Icons";
import EmptyData from "../EditDeveloperTab/EmptyData";
import {
  Container,
  WorkInfoContainer,
  IconContainer,
  WorkingDates,
  CompanyName,
  WorkHistoryConatiner,
  PositionTitle,
  WorkDescription,
} from "./styles";

export default function WorkExperienceTab({ user }) {
  return (
    <>
      {(!user.workHistory || user.workHistory.length === 0) && <EmptyData />}

      {user.workHistory.map((workHistory, index) => {
        return (
          <Container key={index}>
            <WorkInfoContainer>
              <IconContainer>
                <WorkExperienceIcon />
              </IconContainer>
              <WorkHistoryConatiner>
                <PositionTitle>{workHistory.position}</PositionTitle>
                <CompanyName>{workHistory.company}</CompanyName>
                <WorkingDates>
                  {dayjs(workHistory.startDate).format("MMMM YYYY")} -{" "}
                  {workHistory.endDate == null
                    ? " Present"
                    : dayjs(workHistory.endDate).format("MMMM YYYY")}
                </WorkingDates>
              </WorkHistoryConatiner>
            </WorkInfoContainer>
            <WorkDescription
              dangerouslySetInnerHTML={{ __html: workHistory.description }}
            />
          </Container>
        );
      })}
    </>
  );
}
