import React, { useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import { Button } from "tg-design";
import {
  CREATE_FORCE_MATCH_IN_COMMUNITY,
  SEARCH_ALL_MATCHABLE_USER,
} from "../../queries";

import { SelectField, AsyncSelectField } from "../../../Form/SelectField";

import { captureErrorWithData, debounce } from "../../../../helper";

const MatchStateOptions = [{ label: "TG Assessment", value: "TG_ASSESSMENT" }];

export default function CommunityTab({ position, refetch }) {
  const [state, setState] = useState();

  const { refetch: userSearchRefetch } = useQuery(SEARCH_ALL_MATCHABLE_USER, {
    variables: { positionId: position },
    skip: true,
  });

  const [addForceMatch, { loading }] = useMutation(
    CREATE_FORCE_MATCH_IN_COMMUNITY,
    {
      onCompleted: (data) => {
        if (!data.addForceMatch.visible) {
          message.warning(
            "Match already exists but match state has been updated"
          );
        }
      },
    }
  );

  const handleSubmit = async () => {
    try {
      await addForceMatch({
        variables: { ...state, position },
      });
      message.success("The record has been created.");
      refetch();
    } catch (error) {
      message.error(error.message);
      captureErrorWithData(error);
    }
  };

  const handleUserSearch = useCallback(
    debounce(async (val, callback) => {
      // refetch because of useQuery and useLazyQuery doesnt return a promise
      try {
        const { data } = await userSearchRefetch({
          search: val,
        });
        callback(
          data.allMatchableUser.matchableUsers.map((i) => {
            return { label: `${i.name}, ${i.email}`, value: i.id };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  return (
    <div>
      {loading && "...loading"}
      {!loading && (
        <>
          <AsyncSelectField
            label="Search user"
            placeholder="Type email or name"
            loadOptions={handleUserSearch}
            onChange={(event) => setState({ ...state, user: event.value })}
            mb={4}
          />
          <SelectField
            label="Match will be created this state"
            mb={4}
            options={MatchStateOptions}
            onChange={(event) =>
              setState({ ...state, matchState: event.value })
            }
          />
          <Button onClick={handleSubmit} block>
            Create Force Match
          </Button>
        </>
      )}
    </div>
  );
}
