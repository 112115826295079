import { gql } from "@apollo/client";

const CONVERSATION_FRAGMENT = gql`
  fragment ConversationFragment on Match {
    history {
      current
      previous
      user {
        name
        avatarURL
      }
      createdAt
      creatorType
      creator {
        id
        name
        avatarURL
      }
    }
    conversation {
      id
      type
      creatorType
      deletedAt
      creator {
        id
        name
        avatarURL
      }
      actions {
        creatorType
        creator {
          id
          name
          avatarURL
        }
        unicode
      }
      note
      createdAt
      unseen
    }
  }
`;

export const ADD_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation addConversation($matchId: ID!, $note: String!, $type: String) {
    addConversation(matchId: $matchId, note: $note, type: $type) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const UPDATE_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation updateConversation(
    $matchId: ID!
    $conversationId: ID!
    $note: String!
  ) {
    updateConversation(
      matchId: $matchId
      conversationId: $conversationId
      note: $note
    ) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const DELETE_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation removeConversation($matchId: ID!, $conversationId: ID!) {
    removeConversation(matchId: $matchId, conversationId: $conversationId) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const ADD_ACTION_TO_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation addActionToConversation(
    $matchId: ID!
    $conversationId: ID!
    $unicode: String!
  ) {
    addActionToConversation(
      matchId: $matchId
      conversationId: $conversationId
      unicode: $unicode
    ) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const MAKE_CONVERSATIONS_SEEN = gql`
  ${CONVERSATION_FRAGMENT}
  mutation makeConversationsSeen($matchId: ID!, $conversationIds: [ID]) {
    makeConversationsSeen(
      matchId: $matchId
      conversationIds: $conversationIds
    ) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;

export const GET_USER_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  query match($id: ID) {
    match(id: $id) {
      ... on Match {
        ...ConversationFragment
      }
    }
  }
`;
