import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledMenuItem = styled(NavLink)`
  font-size: 16px;
  font-style: normal;
  color: #1a1a1a;
  padding: 0px !important;
  margin-bottom: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;

  &.active {
    color: #64b7dc !important;
    > span {
      color: #64b7dc;
    }
  }
  &:hover {
    color: #1a1a1a;
  }
`;

const IconContainer = styled.span`
  margin-right: 8px !important;
  min-width: 24px !important;
  text-align: right;
  display: flex;
  justify-content: center;
`;

export default function TgMenuItem({ route, icon, children }) {
  return (
    <StyledMenuItem
      key={route}
      to={route}
      isActive={(match) => {
        if (!match) {
          return false;
        }
        return route === match.url;
      }}
    >
      <IconContainer className="anticon anticon-desktop ant-menu-item-icon">
        {icon}
      </IconContainer>
      {children}
    </StyledMenuItem>
  );
}
