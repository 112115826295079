import React from "react";
import styled from "styled-components";
import { width, left, right, display } from "styled-system";

const PopoverContainer = styled.div`
  position: relative;
`;

const PopoverTrigger = styled.div`
  cursor: pointer;
`;

const PopoverBody = styled.div`
  background-color: #fff;
  position: absolute;
  padding: 15px;
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  ${width}
  ${left}
  ${right}
  ${display}
`;

const Popover = ({
  width,
  trigger,
  body,
  onTrigger = () => {},
  display,
  floatDimensions = { left: "0px", right: "0px" },
}) => {
  return (
    <PopoverContainer>
      <PopoverTrigger onClick={onTrigger}>{trigger}</PopoverTrigger>
      <PopoverBody {...floatDimensions} width={width} display={display}>
        {body}
      </PopoverBody>
    </PopoverContainer>
  );
};

export default Popover;
