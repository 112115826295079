import React, { useState } from "react";
import styled from "styled-components";
import {
  TimelineComment,
  MarkdownContent,
  RichTextEditor,
  ActionMenus,
  ActionMenu,
  ReadMore,
  Button,
  toRichTextState,
  richTextToMarkdown,
} from "tg-design";
import { CONVERSATION_TYPES } from "../../../../constants";
import useQuestion from "../../../../hooks/useQuestion";

export const InterviewMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #111827;
  margin-bottom: 10px;
`;

export const Candidate = styled.span`
  font-weight: 600;
`;

const EditActions = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 10px;
`;

const WarningMessage = () => {
  return (
    <>
      You are trying to <b>delete</b> message from conversation. <br />
      Are you sure?
    </>
  );
};

export default function ConversationItem({
  currentUserId,
  conversation,
  handleEmojiSelect,
  handleDelete,
  handleEdit,
  cancelEditing,
  updateNote,
  match,
}) {
  const [editValue, setEditValue] = useState(toRichTextState(""));
  const question = useQuestion();

  const startEditing = () => {
    setEditValue(toRichTextState(conversation.note));
    handleEdit({ conversation });
  };

  let actionMenus = null;
  if (conversation.creator.id === currentUserId) {
    actionMenus = (
      <ActionMenus variant="right">
        <ActionMenu onClick={startEditing}>Edit</ActionMenu>
      </ActionMenus>
    );
  }
  if (
    conversation.creator.id === currentUserId &&
    conversation.type === CONVERSATION_TYPES.CONVERSATION
  ) {
    actionMenus = (
      <ActionMenus variant="right">
        <ActionMenu onClick={startEditing}>Edit</ActionMenu>
        <ActionMenu
          onClick={() =>
            question(
              {
                content: <WarningMessage />,
              },
              () => handleDelete({ conversation })
            )
          }
        >
          Delete
        </ActionMenu>
      </ActionMenus>
    );
  }

  const author =
    conversation.type === CONVERSATION_TYPES.MEETING
      ? match.position.assignee
      : conversation.creator;

  return (
    <TimelineComment
      createdAt={conversation.createdAt}
      user={author}
      isEmojiActive={!conversation.deletedAt}
      onEmojiSelect={({ unicode }) =>
        handleEmojiSelect({ unicode, conversation })
      }
      actions={conversation.actions || []}
      currentUserId={currentUserId}
      actionMenus={!conversation.deletedAt && actionMenus}
    >
      {conversation.type === CONVERSATION_TYPES.MEETING && (
        <InterviewMessage>
          Notes from the <Candidate>{match.user.name}</Candidate> Interview:
        </InterviewMessage>
      )}
      {conversation.isEditing && (
        <>
          <RichTextEditor state={editValue} onChange={setEditValue} />
          <EditActions>
            <Button
              size="sm"
              onClick={() =>
                updateNote({
                  conversation,
                  editValue: richTextToMarkdown(editValue),
                })
              }
              disabled={
                (richTextToMarkdown(editValue) || "").trim().length === 0
              }
            >
              Save
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => cancelEditing({ conversation })}
            >
              Cancel
            </Button>
          </EditActions>
        </>
      )}
      {!conversation.isEditing && (
        <ReadMore maxHeight={200}>
          {!conversation.deletedAt ? (
            <MarkdownContent>{conversation.note}</MarkdownContent>
          ) : (
            <i>Deleted</i>
          )}
        </ReadMore>
      )}
    </TimelineComment>
  );
}
