import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { message } from "tg-design";
import { Tag, Select } from "antd";
import { GET_POSITION, UPDATE_POSITION_STATE } from "../queries";
import { handleHead } from "../../../helper";
import { TgHeader } from "../../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../../tg-styles/TgHeaderTabs";
import {
  MatchesNewTab,
  ReportsTab,
  TimelineTab,
  ForceMatchesTab,
  BatchesTab,
  PublicDetailsTab,
} from "./tabs";
import PartialError from "../../ErrorPage/PartialError";
import {
  POSITION_RESTRICTIONS,
  POSITION_STATES,
} from "../../../constants/states";

const { Option } = Select;

const HeaderActions = ({
  state,
  handleStateChange,
  assignee,
  isUpdating,
  hasGreenhouseConnect,
}) => {
  return (
    <>
      <Select
        value={state}
        style={{ width: "110px" }}
        onChange={handleStateChange}
        size="small"
        loading={isUpdating}
      >
        {POSITION_RESTRICTIONS.map((item, i) => (
          <Option
            key={i}
            value={item.id}
            disabled={item.restrictions.includes(state)}
          >
            {item.name}
          </Option>
        ))}
      </Select>
      <Tag color="magenta" style={{ margin: "10px 0 0" }}>
        {assignee ? assignee.name : "No assignee"}
      </Tag>
      {hasGreenhouseConnect && (
        <Tag color="green" style={{ margin: "10px 0 0" }}>
          Connected Greenhouse
        </Tag>
      )}
    </>
  );
};

export default function PositionDetail() {
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "publicDetails"
  );
  const { positionId } = useParams();
  const history = useHistory();
  const [positionStateChange, { loading: updating }] = useMutation(
    UPDATE_POSITION_STATE
  );
  const [isFirstEntry, setFirstEntry] = useState(true);
  const { loading, error, data, refetch } = useQuery(GET_POSITION, {
    variables: { id: positionId },
    onCompleted: (data) => {
      if (
        isFirstEntry &&
        data.position.state === POSITION_STATES.IN_PROGRESS &&
        currentTab !== "batches"
      ) {
        setFirstEntry(false);
        setCurrentTab("matchesNew");
      }
    },
  });

  if (loading) return "loading";
  if (error) return <PartialError />;

  const { company, title, state, assignee, isTest, greenhouseJobId } =
    data.position;

  const handleStateChange = async (state) => {
    try {
      if (state === POSITION_STATES.IN_PROGRESS && !assignee) {
        throw new Error("You must assign a talent partner first");
      }
      await positionStateChange({
        variables: { id: data.position.id, state },
      });
      await refetch();
      message.success(`Position state is updated as ${state}`);
    } catch (error) {
      message.error(`${error}`, { autoClose: false });
    }
  };

  const getTab = () => {
    switch (currentTab) {
      case "matchesNew":
        return (
          <MatchesNewTab
            positionId={positionId}
            position={data?.position}
            history={history}
            refetch={refetch}
          />
        );
      case "publicDetails":
        return <PublicDetailsTab position={data.position} refetch={refetch} />;
      case "batches":
        return <BatchesTab position={data.position} refetch={refetch} />;
      case "timeline":
        return (
          <TimelineTab
            history={data.position.history}
            positionCreatedAt={data.position.createdAt}
            refetch={refetch}
          />
        );
      case "reports":
        return <ReportsTab position={data.position} refetch={refetch} />;
      case "force-matches":
        return <ForceMatchesTab position={data.position} refetch={refetch} />;
      default:
        setCurrentTab("publicDetails");
        break;
    }
  };

  const getPositionTitle = () => {
    return (
      <>
        <span>{title} </span>
        {isTest && <span style={{ color: "#ff7675" }}>-Test-</span>}
      </>
    );
  };

  handleHead("positionDetail", company.name, title);

  return (
    <div>
      <TgHeader
        title={getPositionTitle() || "Noname"}
        logo={company.logo}
        logoURL={`/company/detail/${company.id}`}
        breadCrumbs={[
          { title: "Positions", link: "/positions" },
          {
            title: data.position.title,
            link: `/positions/${positionId}`,
          },
        ]}
        actionComponent={
          <HeaderActions
            state={state}
            handleStateChange={handleStateChange}
            assignee={assignee}
            isUpdating={updating}
            hasGreenhouseConnect={greenhouseJobId}
          />
        }
      >
        <TgHeaderTabs currentTab={currentTab} handleChange={setCurrentTab}>
          <TgHeaderTab name="matchesNew">Matches</TgHeaderTab>
          <TgHeaderTab name="publicDetails">Public Details</TgHeaderTab>
          <TgHeaderTab name="batches">Batch Management</TgHeaderTab>
          <TgHeaderTab name="timeline">Timeline</TgHeaderTab>
          <TgHeaderTab name="reports">Reports</TgHeaderTab>
          <TgHeaderTab
            isDisabled={[
              POSITION_STATES.ON_HOLD,
              POSITION_STATES.SUBMITTED,
              POSITION_STATES.CREATED,
            ].includes(data?.position?.state)}
            tooltipText={
              [
                POSITION_STATES.ON_HOLD,
                POSITION_STATES.SUBMITTED,
                POSITION_STATES.CREATED,
              ].includes(data?.position?.state) &&
              "This position is not In Progress yet."
            }
            name="force-matches"
          >
            Force Matches
          </TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>
      <div style={{ paddingLeft: "10px" }}>{getTab()}</div>
    </div>
  );
}
