import React from "react";
import { SectionTitle } from "../styles";
import TextField from "../../../../../Form/TextField";
import FormScaffold from "../../../../../Form/FormScaffold";

export default function SocialSection({ state, onChange, getInputStatus }) {
  const { social } = state;
  return (
    <>
      <SectionTitle>Social</SectionTitle>
      <FormScaffold label="GitHub" status={getInputStatus("social.github")}>
        <TextField
          name="github"
          defaultValue={social.github}
          onChange={(event) =>
            onChange({ name: "social.github", value: event.target.value })
          }
        />
      </FormScaffold>
      <FormScaffold label="LinkedIn" status={getInputStatus("social.linkedin")}>
        <TextField
          name="linkedin"
          defaultValue={social.linkedin}
          onChange={(event) =>
            onChange({ name: "social.linkedin", value: event.target.value })
          }
        />
      </FormScaffold>
      <FormScaffold
        label="StackOverflow"
        status={getInputStatus("social.stackoverflow")}
      >
        <TextField
          name="stackoverflow"
          defaultValue={social.stackoverflow}
          onChange={(event) =>
            onChange({
              name: "social.stackoverflow",
              value: event.target.value,
            })
          }
        />
      </FormScaffold>
      <FormScaffold
        label="Portfolio"
        status={getInputStatus("social.portfolio")}
      >
        <TextField
          name="portfolio"
          defaultValue={social.portfolio}
          onChange={(event) =>
            onChange({ name: "social.portfolio", value: event.target.value })
          }
        />
      </FormScaffold>
      <FormScaffold label="Twitter" status={getInputStatus("social.twitter")}>
        <TextField
          name="twitter"
          defaultValue={social.twitter}
          onChange={(event) =>
            onChange({ name: "social.twitter", value: event.target.value })
          }
        />
      </FormScaffold>
      <FormScaffold label="Blog" status={getInputStatus("social.blog")}>
        <TextField
          name="blog"
          defaultValue={social.blog}
          onChange={(event) =>
            onChange({ name: "social.blog", value: event.target.value })
          }
        />
      </FormScaffold>
      <FormScaffold label="YouTube" status={getInputStatus("social.youtube")}>
        <TextField
          name="portfolio"
          defaultValue={social.youtube}
          onChange={(event) =>
            onChange({ name: "social.youtube", value: event.target.value })
          }
        />
      </FormScaffold>
    </>
  );
}
