import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { message, Switch } from "antd";
import { Button } from "tg-design";
import md5 from "md5";
import { UPDATE_USER_AS_RECRUITER } from "../../../queries";
import {
  WorkHistorySection,
  EducationSection,
  ProfileInfoSection,
  LanguageSection,
  SocialSection,
} from "./Sections";
import {
  removeTypename,
  isAllInputsValid,
  captureErrorWithData,
} from "../../../../../helper";
import { TgDrawer } from "../../../../tg-styles/Layouts";
import { WorkHistoryForm, EducationForm, LanguageForm } from "./DrawerContents";
import { basicInfoValidationSchema } from "./validations";
import AppContext from "../../../../contexts/AppContext";
import FormScaffold from "../../../../Form/FormScaffold";
import ResumeSection from "./Sections/ResumeSection";

const Container = styled.div`
  max-width: 500px;
`;

const DEFAULT_OPTIONS = {
  showStatus: false,
};

export default function Edit({
  user,
  isNavigationAllowed,
  setIsNavigationAllowed,
}) {
  const [initialHash, setInitialHash] = useState(null);
  const [drawer, setDrawer] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [state, setState] = useState(user);
  const [changes, setChanges] = useState({ id: user.id });
  const [inputStatus, setInputStatus] = useState({});
  const isBlocked = useRef(!isNavigationAllowed);

  const [updateUser] = useMutation(UPDATE_USER_AS_RECRUITER);
  const history = useHistory();
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (initialHash === null) {
      setInitialHash(md5(JSON.stringify(state)));
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [state]);

  const handleSave = async () => {
    setSaving(true);
    const variables = removeTypename({
      ...changes,
      languages: (state.languages || []).map((i) => {
        return {
          language: i.language.id,
          level: i.level,
        };
      }),
      education: (state.education || []).map((item) => {
        return {
          ...item,
          branch: item.branch?.id || item.branch,
          school: item.school?.id || item.school,
        };
      }),
      social: { ...state.social },
    });

    try {
      await updateUser({
        variables,
      });
      setSaving(false);
      setInitialHash(md5(JSON.stringify(state)));
      setIsNavigationAllowed(true);
      message.success("The profile has been updated!");
    } catch (error) {
      captureErrorWithData(error);
      message.error("Something went wrong!");
      setSaving(false);
    }
  };

  const getInputStatus = (name) => {
    if (inputStatus[name]) {
      return inputStatus[name];
    }
    if (name.includes(".") === false && state[name]) {
      return "success";
    }
    if (name.includes(".") === true) {
      const [root, field] = name.split(".");
      if (state[root][field]) {
        return "success";
      }
    }
  };

  const updateState = ({ name, value }) => {
    if (name.includes(".") === false) {
      setState({
        ...state,
        [name]: value,
      });
      setChanges({
        ...changes,
        [name]: value,
      });
      setIsNavigationAllowed(false);
      return;
    }

    const [root, field] = name.split(".");
    setState({
      ...state,
      [root]: {
        ...state[root],
        [field]: value,
      },
    });
    setChanges({
      ...changes,
      [root]: {
        ...changes[root],
        [field]: value,
      },
    });
    setIsNavigationAllowed(false);
  };

  const onChange = ({ name, value }) => {
    setInputStatus({
      ...inputStatus,
      [name]: "pending",
    });
    basicInfoValidationSchema.fields[name]
      .validate(value)
      .then(() => {
        setInputStatus({
          ...inputStatus,
          [name]: "success",
        });
        updateState({ name, value });
      })
      .catch(() => {
        setInputStatus({
          ...inputStatus,
          [name]: "error",
        });
      });
  };

  const handleAddWorkHistory = (data) => {
    setState({
      ...state,
      workHistory: [...state.workHistory, data],
    });
    setChanges({
      ...changes,
      workHistory: [...state.workHistory, data],
    });
    setIsNavigationAllowed(false);
    setDrawer(null);
  };

  const handleNewWorkHistory = () => {
    setDrawer(
      <WorkHistoryForm onSave={(data) => handleAddWorkHistory(data)} />
    );
  };

  const handleDeleteWorkHistory = (deleteIndex) => {
    const workHistory = state.workHistory.filter(
      (i, index) => index !== deleteIndex
    );
    setState({
      ...state,
      workHistory,
    });
    setChanges({
      ...changes,
      workHistory,
    });
    setIsNavigationAllowed(false);
  };

  const handleWorkHistoryUpdate = (data, updatedIndex) => {
    const workHistory = state.workHistory.map((item, index) => {
      if (index === updatedIndex) {
        return data;
      }
      return item;
    });
    setState({
      ...state,
      workHistory,
    });
    setChanges({
      ...changes,
      workHistory,
    });
    setIsNavigationAllowed(false);
    setDrawer(null);
  };

  const handleEditWorkHistory = (data, index) => {
    setDrawer(
      <WorkHistoryForm
        data={data}
        onSave={(data) => handleWorkHistoryUpdate(data, index)}
      />
    );
  };

  const handleAddEducation = (data) => {
    setState({
      ...state,
      education: [...state.education, data],
    });
    setChanges({
      ...changes,
      education: [...state.education, data],
    });
    setIsNavigationAllowed(false);
    setDrawer(null);
  };

  const handleNewEducation = () => {
    setDrawer(<EducationForm onSave={(data) => handleAddEducation(data)} />);
  };

  const handleDeleteEducation = (deleteIndex) => {
    const education = state.education.filter(
      (i, index) => index !== deleteIndex
    );
    setState({
      ...state,
      education,
    });
    setChanges({
      ...changes,
      education,
    });
    setIsNavigationAllowed(false);
  };

  const handleEducationUpdate = (data, updatedIndex) => {
    const education = state.education.map((item, index) => {
      if (index === updatedIndex) {
        return data;
      }
      return item;
    });
    setState({
      ...state,
      education,
    });
    setChanges({
      ...changes,
      education,
    });
    setIsNavigationAllowed(false);

    setDrawer(null);
  };

  const handleEditEducation = (data, index) => {
    setDrawer(
      <EducationForm
        data={data}
        onSave={(data) => handleEducationUpdate(data, index)}
      />
    );
  };

  const handleAddLanguage = (data) => {
    setState({
      ...state,
      languages: [...state.languages, data],
    });
    setChanges({
      ...changes,
      languages: [...state.languages, data],
    });
    setIsNavigationAllowed(false);

    setDrawer(null);
  };

  const handleNewLanguage = () => {
    setDrawer(<LanguageForm onSave={(data) => handleAddLanguage(data)} />);
  };

  const handleDeleteLanguage = (deleteIndex) => {
    const languages = state.languages.filter(
      (i, index) => index !== deleteIndex
    );
    setState({
      ...state,
      languages,
    });
    setChanges({
      ...changes,
      languages,
    });
    setIsNavigationAllowed(false);
  };

  const handleLanguageUpdate = (data, updatedIndex) => {
    const languages = state.languages.map((item, index) => {
      if (index === updatedIndex) {
        return data;
      }
      return item;
    });
    setState({
      ...state,
      languages,
    });
    setChanges({
      ...changes,
      languages,
    });

    setIsNavigationAllowed(false);

    setDrawer(null);
  };

  const handleTestStatusChange = (value) => {
    const userMatchesThatAreNotTest = state?.matches?.filter((i) => !i.isTest);

    if (userMatchesThatAreNotTest.length > 0 && value) {
      setState({ ...changes, isTest: false });
      setState({ ...state, isTest: false });
      return message.error("This user already has matches that are NOT test");
    }
    setState({
      ...state,
      isTest: value,
    });
    setChanges({
      ...changes,
      isTest: value,
    });

    setIsNavigationAllowed(false);
  };

  const handleEditLanguage = (data, index) => {
    setDrawer(
      <LanguageForm
        data={data}
        onSave={(data) => handleLanguageUpdate(data, index)}
      />
    );
  };

  const currentHash = md5(JSON.stringify(state));

  useEffect(() => {
    isBlocked.current = !isNavigationAllowed;
    return () => {
      isBlocked.current = false;
    };
  }, [isNavigationAllowed]);

  useEffect(() => {
    history.block((location) => {
      if (
        isBlocked.current &&
        location.search === "" &&
        location.state !== undefined
      )
        return "There are unsaved changes. Are you sure you want to change the page?";
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {drawer && (
        <TgDrawer closable={false} onClose={() => setDrawer(null)} visible>
          {drawer}
        </TgDrawer>
      )}

      {appContext.isAdmin && (
        <FormScaffold
          style={{ margin: "30px 0 50px 0" }}
          options={DEFAULT_OPTIONS}
          label={
            <>
              <span style={{ marginRight: "15px" }}>Test</span>
              <Switch
                onChange={(value) => handleTestStatusChange(value)}
                checked={state.isTest}
              />
            </>
          }
        />
      )}

      <Container>
        <Button
          onClick={handleSave}
          loading={isSaving}
          disabled={
            !isAllInputsValid(inputStatus) || currentHash === initialHash
          }
          style={{ position: "fixed", right: "30px" }}
        >
          Save Changes
        </Button>
        <ProfileInfoSection
          state={state}
          onChange={onChange}
          getInputStatus={getInputStatus}
          setInputStatus={({ name, value }) =>
            setInputStatus({ ...inputStatus, [name]: value })
          }
        />
        <ResumeSection
          resume={user.resume}
          id={user.id}
          getInputStatus={getInputStatus}
          setInputStatus={({ name, value }) =>
            setInputStatus({ ...inputStatus, [name]: value })
          }
        />
        <SocialSection
          state={state}
          onChange={onChange}
          getInputStatus={getInputStatus}
        />
        <WorkHistorySection
          state={state}
          handleNew={handleNewWorkHistory}
          handleDelete={handleDeleteWorkHistory}
          handleEdit={handleEditWorkHistory}
        />
        <EducationSection
          state={state}
          handleNew={handleNewEducation}
          handleDelete={handleDeleteEducation}
          handleEdit={handleEditEducation}
        />
        <LanguageSection
          state={state}
          handleNew={handleNewLanguage}
          handleDelete={handleDeleteLanguage}
          handleEdit={handleEditLanguage}
        />
      </Container>
    </>
  );
}
