import React, { useState, useEffect } from "react";
import { Tooltip, RichTextEditor, richTextToMarkdown } from "tg-design";

import { CheckedBoxIcon } from "../../../../../Icons";
import { removeWhiteSpace, useLazyCaller } from "../../../../../../helper";
import { RowBlock } from "../../../../../Form/BlockUi";

export default function NoteTab({
  state,
  setState,
  tooltipText,
  isModalOpen,
  conversation,
  handleSubmit,
  updateNote,
}) {
  const [isTyping, setIsTyping] = useState(false);

  const lazyCaller = useLazyCaller();

  const note = richTextToMarkdown(state.note);

  const handleUpdate = async ({ conversation, note }) => {
    await updateNote({
      conversation,
      editValue: note,
    });
    setIsTyping(false);
  };

  const submitNote = async () => {
    await handleSubmit({ modalNote: state.note });
    setIsTyping(false);
  };

  useEffect(() => {
    if (
      conversation?.length > 0 &&
      removeWhiteSpace(conversation[0].note) === removeWhiteSpace(note)
    ) {
      return;
    }

    if (isModalOpen && note && removeWhiteSpace(note).length > 0) {
      setIsTyping(true);
      if (conversation?.length === 0) {
        lazyCaller(() => submitNote(), 2000);
      } else {
        lazyCaller(
          () =>
            handleUpdate({
              conversation: conversation[0],
              note,
            }),
          2000
        );
      }
    }

    // eslint-disable-next-line
  }, [state.note]);

  return (
    <>
      <RowBlock justify="flex-end" color="#aaa">
        <Tooltip text={tooltipText} place="left">
          Shortcuts
        </Tooltip>
      </RowBlock>
      <div className="interview-editor">
        <RichTextEditor
          height="100%"
          state={state?.note}
          onChange={(note) => setState({ ...state, note })}
        />
      </div>

      {removeWhiteSpace(note).length > 0 && (
        <RowBlock pt={2} justify="start">
          {isTyping ? (
            "typing..."
          ) : (
            <RowBlock align="center" gridGap="5px">
              Saved
              <CheckedBoxIcon />
            </RowBlock>
          )}
        </RowBlock>
      )}
    </>
  );
}
