import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin: 15px 0px;
  flex-wrap: wrap;
  page-break-inside: avoid;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const WorkInfoContainer = styled.div`
  display: flex;
  width: 53%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 60px;
  width: 60px;
  background: #e5e5e5;
  border-radius: 5px;
`;

export const WorkHistoryConatiner = styled.div`
  margin-right: 20px;
  display: block;
`;

export const PositionTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const CompanyName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const WorkingDates = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
`;

export const WorkDescription = styled.div`
  flex: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`;
