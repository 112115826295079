import React from "react";
import { useLocation } from "react-router-dom";
import {
  SectionContainer,
  SectionTitle,
} from "../../developers/detail/tabs/EducationTab/styles";
import WorkExperienceTab from "../../developers/detail/tabs/WorkExperienceTab";
import EducationTab from "../../developers/detail/tabs/EducationTab";

export default function BackgroundTab({ match }) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  return (
    <>
      {isPrintPage && match.user.workHistory.length === 0 && <></>}
      {isPrintPage &&
        match?.user?.workHistory &&
        match.user.workHistory.length > 0 && (
          <SectionContainer style={{ pageBreakInside: "avoid" }}>
            <SectionTitle>Work Experience</SectionTitle>
            <WorkExperienceTab user={match.user} />
          </SectionContainer>
        )}
      {!isPrintPage && (
        <SectionContainer style={{ pageBreakInside: "avoid" }}>
          <SectionTitle>Work Experience</SectionTitle>
          <WorkExperienceTab user={match.user} />
        </SectionContainer>
      )}

      <EducationTab user={match.user} />
    </>
  );
}
