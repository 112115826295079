/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { space } from "styled-system";

export const InputWrap = styled.div`
  ${space}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  width: 35px;
  height: 56px;
  border-radius: 5px;
  background-color: #f5f6f8;
  border: 2px solid transparent;
  font-size: 30px;
  padding-left: 9px;
  margin: 5px;
  :focus {
    border: 2px solid #abe6c1;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;
const UP_ARROW_KEY = 38;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

// type = number || text
function PinField({ type, fields, onChange, ...props }) {
  const initialList = Array(fields).fill("");
  const [list, setList] = useState(initialList);
  const inputRef = useRef([]);

  function handleChange(e) {
    const index = Number(e.target.dataset.id);
    let { value } = e.target;

    if (type === "number") {
      value = value.replace(/[^\d]/g, "");
    }

    const inputList = [...list];
    if (value.length === 1) {
      inputList[index] = e.target.value;
      setList(inputList);
    } else if (value.length > 1) {
      value.split("").map((val, i) => {
        if (index + i < fields) {
          inputList[index + i] = val;
        }
        return false;
      });
      setList(inputList);
    }

    const next =
      inputRef.current[
        index < list.length && value.length < list.length
          ? index + Number(value.length)
          : list.length - 1
      ];
    if (next) {
      next.focus();
      next.select();
    }
    const fullValue = inputList.join("");
    if (onChange && fullValue) {
      onChange(fullValue);
    }
  }

  function handleKeydown(e) {
    const index = Number(e.target.dataset.id);
    const next = inputRef.current[index + 1];
    const prev = inputRef.current[index - 1];

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        // eslint-disable-next-line no-case-declarations
        const inputList = [...list];
        inputList[index] = "";
        setList(inputList);

        if (inputRef.current[index].value === "" && prev) {
          prev.focus();
          prev.select();
        }
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prev) {
          prev.focus();
          prev.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (next) {
          next.focus();
          next.select();
        }
        break;

      case UP_ARROW_KEY:
      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        if (e.target.type === "number") {
          e.preventDefault();
          break;
        }
        break;

      default:
        break;
    }
  }

  return (
    <InputWrap ml={props.ml}>
      {list.map((val, i) => (
        <Input
          ref={(ref) => {
            inputRef.current[i] = ref;
          }}
          data-id={i}
          key={i}
          type={type}
          value={val}
          onKeyDown={(e) => handleKeydown(e)}
          onChange={(e) => handleChange(e)}
          autoComplete="off"
          autoFocus={i === 0 ? "autoFocus" : ""}
        />
      ))}
    </InputWrap>
  );
}

export default PinField;
