import React, { useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 30px);
  padding-right: 5px;
  padding-bottom: 50px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  &.on-hover {
    filter: blur(1.2px);
  }

  &.has-substate {
    padding-bottom: unset;
  }
`;

export default function TgKanbanColumnBody({
  isOnHover,
  onLoadMore,
  children,
  hasSubState,
}) {
  const containerRef = useRef();
  const classes = [];
  if (isOnHover) {
    classes.push("on-hover");
  }

  if (hasSubState) {
    classes.push("has-substate");
  }

  const handleScroll = () => {
    if (!onLoadMore) {
      return;
    }

    const { scrollHeight, scrollTop } = containerRef.current;
    const diff = scrollHeight - scrollTop;
    if (diff < 1000) {
      onLoadMore();
    }
  };

  return (
    <Container
      ref={containerRef}
      onScroll={handleScroll}
      className={classes.join(" ")}
    >
      {children}
    </Container>
  );
}
