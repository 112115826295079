import { gql } from "@apollo/client";

export const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

export const VERIFY_PIN = gql`
  query VerifyPin($email: String, $digit: Int) {
    pin(email: $email, digit: $digit) {
      email
      digit
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!, $email: String!, $pin: Int!) {
    updateRecruiterPassword(password: $password, email: $email, pin: $pin) {
      id
    }
  }
`;
