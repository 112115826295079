import React, { useState, useRef } from "react";
import { Input, Space, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Button } from "tg-design";
import { TgTable, TgDrawer } from "../../../tg-styles/Layouts";
import { RowBlock } from "../../../Form/BlockUi";
import CreatePositionForm from "../../../position/drawers/CreatePositionForm";

const positionStates = [
  { text: "On Hold", value: "ON_HOLD" },
  { text: "Created", value: "CREATED" },
  { text: "Awaiting Approval", value: "SUBMITTED" },
  { text: "In Progress", value: "IN_PROGRESS" },
  { text: "Filled", value: "FILLED" },
  { text: "Closed", value: "CLOSED" },
  { text: "Canceled", value: "CANCELED" },
];

export default function PositionTab({ positions, company }) {
  const [drawerContent, setDrawer] = useState(null);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onDrawerClosed = () => {
    setDrawer(null);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="xs"
            style={{ width: 90 }}
          >
            <SearchOutlined />
            Search
          </Button>
          <Button
            variant="secondary"
            outline
            onClick={() => handleReset(clearFilters)}
            size="xs"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Position",
      dataIndex: "title",
      render: (value, record) => (
        <Link to={`/positions/${record.id}`}>
          {value}{" "}
          {record.isTest && (
            <span style={{ color: "#ff7675", paddingLeft: "5px" }}>-Test-</span>
          )}
        </Link>
      ),
      ...getColumnSearchProps("title"),
    },
    {
      title: "Status",
      dataIndex: "state",
      filters: positionStates,
      align: "center",
      onFilter: (value, record) => record.state.indexOf(value) === 0,
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "TP",
      dataIndex: "assignee",
      render: (val) => val && val.name,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      align: "center",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (date) => dayjs(date).format("DD.MM.YYYY"),
    },
    {
      title: "Total Matches",
      dataIndex: "matches",
      align: "center",
      render: (val) => val.length,
      sorter: (a, b) => a.matches - b.matches,
    },
  ];

  return (
    <div>
      {drawerContent && (
        <TgDrawer closable={false} onClose={onDrawerClosed} visible>
          {drawerContent}
        </TgDrawer>
      )}
      <RowBlock justify="flex-end" mb={3}>
        <Button
          onClick={() =>
            setDrawer(
              <CreatePositionForm
                initialValues={{
                  company: { value: company.id, label: company.name },
                }}
                handleClose={onDrawerClosed}
              />
            )
          }
        >
          Create a Position
        </Button>
      </RowBlock>
      <TgTable
        columns={columns}
        dataSource={positions}
        size="small"
        rowKey="id"
      />
    </div>
  );
}
