import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 0px -10px 5px -10px;
  padding: 5px 10px;
  transition: background-color 0.2s ease;
  background-color: ${({ isActive }) => (isActive ? "#f5fafd" : "unset")};
  border-radius: 5px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const Content = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

export default function FilterLine({
  title,
  currentFilter,
  setCurrentFilter,
  children,
}) {
  return (
    <Container
      onMouseOver={() => setCurrentFilter(title)}
      onMouseLeave={() => setCurrentFilter(null)}
      isActive={currentFilter === title}
    >
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  );
}
