import { gql } from "@apollo/client";

export const GET_RECRUITER_WITH_SUMMARY = gql`
  {
    recruiter {
      id
      name
      talentPartnerSummary {
        id
        state
        title
        company {
          id
          name
          logo
        }
        matches {
          matchList {
            id
            state
          }
          matchCounter {
            CREATED
            INTERESTED
          }
        }
        createdAt
      }
      clientPartnerSummary {
        id
        state
        title
        company {
          id
          name
          logo
        }
        matches {
          matchList {
            id
            state
          }
          matchCounter {
            CREATED
            INTERESTED
          }
        }
        createdAt
      }
    }
  }
`;
