import React, { useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { useParams, Link } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ButtonDropdown, DropdownItem, Button } from "tg-design";
import { Tooltip } from "antd";
import { TgTable, TgDrawer } from "../../../tg-styles/Layouts";
import { GET_FORCE_MATCHES, GET_PUBLIC_POSITION_ID } from "../../queries";
import { RowBlock } from "../../../Form/BlockUi";
import CreateForceMatchForm from "../../drawers/ForceMatchForm";
import { handleCopy } from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";
import { Input } from "../../../Form/FormUi";
import { POSITION_STATES } from "../../../../constants";

const PublicLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 500px;
`;

const IconContainer = styled.div`
  border-left: 1px solid #ddd;
  padding 10px;
`;

export default function ForceMatchesTab({ position }) {
  const { positionId } = useParams();
  const [drawerState, setDrawerState] = useState(null);
  const publicLinkTypes = [
    { type: "LANDING", title: "Landing" },
    { type: "MARKETING", title: "Marketing" },
  ];

  const [selectedItem, setSelectedItem] = useState(publicLinkTypes[0]);

  const { loading, data, refetch, error } = useQuery(GET_FORCE_MATCHES, {
    fetchPolicy: "no-cache",
    variables: {
      id: positionId,
    },
  });

  const {
    loading: publicIDLoading,
    data: publicIDData,
    error: publicIDError,
  } = useQuery(GET_PUBLIC_POSITION_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: positionId,
    },
  });

  if (publicIDLoading) return "Loading";
  const onDrawerClosed = () => {
    setDrawerState(null);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value, record) => {
        if (record?.user?.name) {
          return record.user.name;
        }
        return value;
      },
    },
    {
      title: "Type",
      dataIndex: "user",
      render: (value, record) => {
        if (!record.visible) {
          return "Force State Change";
        }
        if (!value) {
          return "New User";
        }
        if (value.isForceMatched) {
          return "New User";
        }
        return "In Community User";
      },
    },
    {
      title: "Match State",
      dataIndex: "matchState",
      render: (val) => val,
    },
    {
      title: "Signup Link",
      dataIndex: "link",
      render: (text) => (
        <>
          {text}{" "}
          <CopyOutlined
            alt="copy to clipboard"
            onClick={() => handleCopy(text)}
          />
        </>
      ),
    },
    {
      title: "Member Status",
      dataIndex: "user",
      render: (val, record) => {
        if (!val) {
          return "Waiting";
        }
        if (val.status === "LEAD") {
          return (
            <Link to={`/developers/${record.user.id}`}>Lead | Detail</Link>
          );
        }
        if (val.status === "MEMBER") {
          return (
            <Link to={`/developers/${record.user.id}`}>Member | Detail</Link>
          );
        }
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (val) => dayjs(val).format("DD/MM/YY HH:mm"),
    },
  ];

  if (error || publicIDError) return <PartialError />;

  return (
    <>
      {drawerState && (
        <TgDrawer closable onClose={onDrawerClosed} visible>
          {drawerState}
        </TgDrawer>
      )}
      {position.state === POSITION_STATES.IN_PROGRESS && (
        <RowBlock justify="space-between" mb={3}>
          <PublicLinkContainer>
            <ButtonDropdown title={selectedItem.title}>
              {publicLinkTypes.map((element) => {
                return (
                  <DropdownItem onClick={() => setSelectedItem(element)}>
                    {element.title}
                  </DropdownItem>
                );
              })}
            </ButtonDropdown>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Input
                readOnly
                style={{
                  border: "none",
                  background: "white",
                  width: "300px",
                }}
                value={
                  selectedItem.type === "MARKETING"
                    ? `${process.env.REACT_APP_DEV_APP_URL}/m/${publicIDData.position.publicID}`
                    : `${process.env.REACT_APP_DEV_APP_URL}/landing/${publicIDData.position.publicID}`
                }
              />
              <Tooltip title="Copy">
                <IconContainer
                  onClick={() =>
                    handleCopy(
                      selectedItem.type === "MARKETING"
                        ? `${process.env.REACT_APP_DEV_APP_URL}/m/${publicIDData.position.publicID}`
                        : `${process.env.REACT_APP_DEV_APP_URL}/landing/${publicIDData.position.publicID}`
                    )
                  }
                >
                  <CopyOutlined />
                </IconContainer>
              </Tooltip>
            </div>
          </PublicLinkContainer>
          <Button
            onClick={() =>
              setDrawerState(
                <CreateForceMatchForm
                  handleClose={onDrawerClosed}
                  position={positionId}
                  refetch={refetch}
                />
              )
            }
          >
            Add Force Match
          </Button>
        </RowBlock>
      )}
      {data && (
        <TgTable
          columns={columns}
          size="small"
          dataSource={data.position.forceMatches}
          loading={loading || publicIDLoading}
        />
      )}
    </>
  );
}
