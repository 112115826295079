import React, { useState, useEffect } from "react";

import { GroupTitle, SectionTitle, TagText, SelectAllButton } from "./styles";
import { RowBlock } from "../../../../../Form/BlockUi";
import { TECHNOLOGY_CATEGORY_KEYS } from "../../../../../../constants";

export const SkillTab = ({ handleStateChange, linkedinData }) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const newState = linkedinData.skills.filter((item) => {
      return (
        !item.isDefined &&
        item.suggestions.every(
          (skill) =>
            TECHNOLOGY_CATEGORY_KEYS[skill.categoryId] !== "language" &&
            TECHNOLOGY_CATEGORY_KEYS[skill.categoryId] !== "framework"
        )
      );
    });
    setState(newState);
    // eslint-disable-next-line
  }, []);

  const toggleCheck = (idx) => {
    const newState = state.slice();
    newState[idx].isChecked = !newState[idx].isChecked;
    const changes = newState.filter((skill) => skill.isChecked);
    setState(newState);
    handleStateChange("skills", changes);
  };

  const handleSelectAll = () => {
    const isChecked = state.every((item) => item.isChecked);
    const newState = state.slice();

    if (!isChecked) {
      newState.map((item) => {
        item.isChecked = true;
        return item;
      });

      setState(newState);
    } else {
      newState.map((item) => {
        item.isChecked = false;
        return item;
      });
      setState(newState);
    }
    const changes = newState.filter((skill) => skill.isChecked);
    handleStateChange("skills", changes);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Skills</GroupTitle>
      <SectionTitle>Select the relevant skills:</SectionTitle>

      <SelectAllButton
        handleSelectAll={handleSelectAll}
        isChecked={state.every((item) => item.isChecked)}
      />

      <RowBlock
        justify="flex-start"
        gridGap="10px"
        style={{ marginBottom: "100px" }}
      >
        {state.length === 0 ? (
          <div>
            <span>No skills found.</span>
          </div>
        ) : (
          state.map((item, idx) => {
            return (
              <TagText
                isChecked={state[idx]?.isChecked}
                onClick={() => {
                  toggleCheck(idx);
                }}
                key={idx}
              >
                {item.title}
              </TagText>
            );
          })
        )}
      </RowBlock>
    </div>
  );
};
