import React, { useState, useRef, useCallback } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import {
  UPLOAD_AVATAR,
  DELETE_AVATAR,
} from "../../../../../../queries/developer";
import Uploader from "../../../../../common/Uploader";
import { getUnsignedUrl, getCroppedImg } from "../../../../../../helper";

const CropperContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DEFAULT_CROP_OPTIONS = {
  unit: "px",
  x: 30,
  y: 30,
  width: 110,
  height: 110,
  aspect: 1 / 1,
};

export default function UploadPhoto({
  handlePhoto,
  initialPhoto,
  userId,
  onStarted,
  onError,
}) {
  const [uploadImage] = useMutation(UPLOAD_AVATAR);
  const [deleteImage] = useMutation(DELETE_AVATAR);
  const [uploadedFile, setUploadedFile] = useState(
    initialPhoto
      ? {
          uuid: uuidv4(),
          isUploading: false,
          isDeleting: false,
          url: initialPhoto,
        }
      : null
  );
  const [isModalOpen, setModal] = useState(false);
  const [selectImage, setSelectImage] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [targetImage, setTargetImage] = useState(null);
  const [crop, setCrop] = useState(DEFAULT_CROP_OPTIONS);
  const imgRef = useRef(null);

  const handleDelete = async (file) => {
    try {
      if (onStarted) {
        onStarted();
      }
      setUploadedFile({
        ...uploadedFile,
        isDeleting: true,
      });
      await deleteImage({
        variables: { imageUrl: file.url, id: userId },
      });
      handlePhoto(null);
      setUploadedFile(null);
    } catch (error) {
      message.error("Photo deletion failed");
      if (onError) {
        onError();
      }
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const startCropping = (selectedFiles) => {
    setSelectImage(selectedFiles);
    setModal(true);
    const reader = new FileReader();
    reader.addEventListener("load", () => setTargetImage(reader.result));
    reader.readAsDataURL(selectedFiles[0]);
  };

  const onCropComplete = async (cropImage) => {
    if (imgRef && cropImage.width && cropImage.height) {
      const croppedImage = await getCroppedImg(
        imgRef.current,
        cropImage,
        selectImage[0].name
      );
      setCompletedCrop(croppedImage);
    }
  };

  const handleCropperClose = () => {
    setModal(false);
    setSelectImage(null);
    setCompletedCrop(null);
    setTargetImage(null);
    imgRef.current = null;
    setCrop(DEFAULT_CROP_OPTIONS);
  };

  const handleCroppedImage = async (e) => {
    e.preventDefault();
    setModal(false);

    try {
      if (onStarted) {
        onStarted();
      }
      const file = {
        uuid: uuidv4(),
        name: selectImage[0].name,
        isUploading: true,
      };
      setUploadedFile(file);
      const response = await uploadImage({
        variables: { image: completedCrop, id: userId },
      });
      setUploadedFile({
        ...file,
        isUploading: false,
        url: response.data.uploadAvatar.imageUrl,
      });
      handlePhoto(getUnsignedUrl(response.data.uploadAvatar.imageUrl));
    } catch (error) {
      message.error("Photo upload failed");
      if (onError) {
        onError();
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal handleClose={handleCropperClose}>
          <ModalHeader>Crop the image</ModalHeader>
          <ModalBody>
            <CropperContainer>
              <ReactCrop
                src={targetImage}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onImageLoaded={onLoad}
                onComplete={onCropComplete}
              />
            </CropperContainer>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCroppedImage}>Crop & Save</Button>
          </ModalFooter>
        </Modal>
      )}
      <Uploader
        accept="image/*"
        onSelection={startCropping}
        onDelete={handleDelete}
        files={uploadedFile ? [uploadedFile] : []}
      />
    </>
  );
}
