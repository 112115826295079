import React from "react";

import TgDonut from "../../../../TgDonut";

import {
  FlexRow,
  FlexColumn,
  Item,
  IndexBox,
  Title,
  Experience,
} from "./styles";

const SkillListView = ({ data = [] }) => {
  if (data.length > 0) {
    data.sort((a, b) => b.value - a.value);
  }

  const getExperienceLabel = (experience) => {
    if (experience === 1) {
      return <Experience>{experience} year</Experience>;
    }
    if (experience > 1 && experience < 8) {
      return <Experience>{experience} years</Experience>;
    }
    if (experience >= 8) {
      return <Experience>{experience}+ years</Experience>;
    }
  };
  return data.map((item, index) => (
    <Item key={index}>
      <IndexBox>{index + 1}</IndexBox>
      <Title>{item?.label}</Title>
      {getExperienceLabel(item.value)}
    </Item>
  ));
};

export default function SkillView({ skills }) {
  const data = skills.map((skill) => {
    return { label: skill.title, value: skill.experience };
  });
  return (
    <FlexRow>
      <FlexColumn width={{ mobileS: "100%", laptopL: "50%" }} mt={4}>
        <SkillListView data={data} />
      </FlexColumn>
      <FlexColumn width={{ mobileS: "100%", laptopL: "50%" }}>
        <TgDonut data={data} />
      </FlexColumn>
    </FlexRow>
  );
}
