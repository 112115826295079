import React from "react";
import { Select } from "antd";
import { useQuery } from "@apollo/client";
import { ButtonLink } from "tg-design";
import { GET_ALL_RECRUITER } from "../queries";
import PartialError from "../../ErrorPage/PartialError";

const { Option } = Select;

export default function AssigneeSearch({ confirm, setSelectedKeys }) {
  const { loading, error, data } = useQuery(GET_ALL_RECRUITER, {
    variables: {
      limit: 100,
    },
  });

  if (loading) return "loading";

  const onConfirm = (e) => {
    setSelectedKeys(e);
    confirm();
  };

  if (error) return <PartialError />;

  return (
    <div>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a partner"
        optionFilterProp="children"
        onChange={onConfirm}
      >
        {data &&
          data.allRecruiter.recruiters.map((field) => (
            <Option key={field.id} value={field.id}>
              {field.name}
            </Option>
          ))}
      </Select>
      <ButtonLink style={{ margin: "10px" }} onClick={() => onConfirm(null)}>
        Clear Selected
      </ButtonLink>
    </div>
  );
}
