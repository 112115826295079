import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
} from "tg-design";
import { Form, Input, message, Radio } from "antd";
import * as Yup from "yup";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useResetFormOnCloseModal } from "./useResetForm";
import { EMPLOYER_TYPES } from "../../../constants";

const contactValidationSchema = Yup.object().shape({
  fullName: Yup.string().trim().required("Name is required"),
  role: Yup.string().trim().required("Role is required"),
  email: Yup.string().trim().required("Email is required"),
  accessType: Yup.number().required("Access type is required"),
});

export const NewContactModal = ({
  visible,
  onCancel,
  createEmployer,
  updateEmployer,
  selectedValue,
}) => {
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    const currentValue = form.getFieldsValue();

    contactValidationSchema
      .validate(currentValue)
      .then(() => {
        if (selectedValue) {
          updateEmployer(currentValue);
        } else {
          createEmployer(currentValue);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <>
      {visible && (
        <Modal handleClose={onCancel}>
          <ModalHeader>
            {selectedValue ? "Edit Employer" : "Create Employer"}
          </ModalHeader>
          <ModalBody>
            <Form form={form} layout="vertical" name="contactForm">
              <Form.Item
                name="fullName"
                label="Name - surname"
                rules={[{ required: true }]}
                initialValue={selectedValue && selectedValue.fullName}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="role"
                label="Role in the company"
                initialValue={selectedValue && selectedValue.role}
                rules={[{ required: true }]}
              >
                <Input placeholder="ie: HR Manager" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                initialValue={selectedValue && selectedValue.email}
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone Number"
                initialValue={selectedValue && selectedValue.phone}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="accessType"
                label={
                  <>
                    Access Type
                    <Tooltip
                      text={
                        <div
                          style={{
                            marginRight: "20px",
                            marginTop: "10px",
                            width: "194px",
                          }}
                        >
                          <ul>
                            <li>
                              <b>Admin</b> users can access all positions,
                              create & update other users.
                            </li>
                            <li>
                              <b>Members</b> can only view positions they have
                              access to. (They can access all positions as a
                              default setting unless changed.)
                            </li>
                          </ul>
                        </div>
                      }
                    >
                      <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </>
                }
                initialValue={selectedValue && selectedValue.permission?.role}
              >
                <Radio.Group>
                  <Radio.Button value={EMPLOYER_TYPES.ADMIN}>
                    Admin
                  </Radio.Button>
                  <Radio.Button value={EMPLOYER_TYPES.MEMBER}>
                    Member
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onOk}>
              Save
            </Button>
            <Button onClick={onCancel} outline size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
