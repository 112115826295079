import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { space } from "styled-system";
import { message, Spin } from "antd";
import { ColumnBlock, RowBlock } from "../../../../Form/BlockUi";
import { POSITION_VERSION } from "../../../queries";
import { filterNullOfObject } from "../../../../../helper";
import useKeyPress from "../../../../../hooks/useKeyPress";
import {
  EducationTypeTextField,
  RangeField,
  RemoteOptionTextField,
  SimpleListField,
  SimpleTextField,
  SkillGroupListField,
  VersionOwner,
  LanguageListField,
} from "../../../../module/VersionView";
import { TgButtonField } from "../../../../tg-styles/Layouts";
import PartialError from "../../../../ErrorPage/PartialError";

const Header = styled.div`
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.font[2]};
  transition: opacity 0.5s;
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 25px;
  ${space}
`;

const Previuous = styled(ColumnBlock)`
  width: 35%;
  font-weight: bold;
`;
const Current = styled(ColumnBlock)`
  width: 35%;
  font-weight: bold;
`;
const Title = styled(RowBlock)`
  width: 20%;
  justify-content: left;
  font-weight: bold;
`;

const ArrowButton = styled(TgButtonField)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.blue[1]};
  font-size: 18px;
  cursor: pointer;
`;

export default function VersionSection({ position }) {
  const { positionId } = useParams();
  const [isReady, setIsReady] = useState(false);
  const [emptyView, setEmptyView] = useState(false);
  const handleLeft = useKeyPress({ key: "ArrowLeft" });
  const handleRight = useKeyPress({ key: "ArrowRight" });
  const [positionState, setPositionState] = useState(position);

  const [state, setState] = useState({
    all: [],
    selected: {},
    nextArrow: false,
    previousArrow: true,
  });

  const { loading: PositionLoading, error } = useQuery(POSITION_VERSION, {
    variables: {
      id: positionId,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.position.changes && data.position.criteria) {
        const all = data.position.changes
          .concat(data.position.criteria.changes)
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
        const selected = all[0];
        setState({ ...state, all, selected });
        setIsReady(true);
      } else {
        setEmptyView(true);
      }
    },
  });

  const goToDom = (key = "") => {
    const currentDom = document.getElementById(key);
    if (currentDom) {
      currentDom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleNext = () => {
    const idx = state.all.findIndex(
      (c) => c.timestamp === state.selected.timestamp
    );

    if (idx === 0) {
      message.error("This is the latest version");
      return false;
    }
    const selected = state.all[idx - 1];
    const { __typename, ...changes } = filterNullOfObject(selected.current);

    let newPositionState = { ...positionState, ...changes };
    if (__typename === "PositionCriteriaType") {
      newPositionState = {
        ...positionState,
        criteria: { ...positionState.criteria, ...changes },
      };
    }
    const key = Object.keys(changes)[0];
    if (key) {
      goToDom(key);
    }
    setPositionState(newPositionState);
    const nextArrow = idx <= state.all.length;
    setState({ ...state, selected, nextArrow });
  };

  const handlePrevious = () => {
    const idx = state.all.findIndex(
      (c) => c.timestamp === state.selected.timestamp
    );

    if (state.all.length - 1 === idx) {
      message.error("This is first version");
      return false;
    }
    const selected = state.all[idx + 1];

    const { __typename, ...changes } = filterNullOfObject(state.selected.diff);

    let newPositionState = { ...positionState, ...changes };
    if (__typename === "PositionCriteriaType") {
      newPositionState = {
        ...positionState,
        criteria: { ...positionState.criteria, ...changes },
      };
    }

    const key = Object.keys(changes)[0];
    if (key) {
      goToDom(key);
    }

    setPositionState(newPositionState);
    const previousArrow = idx < state.all.length;
    setState({ ...state, selected, previousArrow });
  };

  useEffect(() => {
    if (handleLeft) {
      handlePrevious();
    }
    if (handleRight) {
      handleNext();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [handleLeft, handleRight]);

  const WaitMessage = () => {
    return (
      <>
        <Spin />
        <span style={{ marginLeft: "10px" }}>
          It may take a long time please wait
        </span>
      </>
    );
  };

  if (error) return <PartialError />;

  return (
    <div>
      {emptyView && "There is no version history"}
      {PositionLoading && !isReady && <WaitMessage />}
      {!PositionLoading && isReady && (
        <>
          <VersionOwner
            timestamp={state?.selected?.timestamp}
            name={state?.selected?.owner?.name}
            avatarURL={state?.selected?.owner?.avatarURL}
          />
          <Header mt={3}>
            <Title>Title</Title>
            <Previuous>Previous</Previuous>
            <Current>Current</Current>
          </Header>
          <SimpleTextField
            id="title"
            title="Position Title"
            name="title"
            path="title"
            doc={positionState}
            state={state}
          />
          <SimpleTextField
            id="assignee"
            title="Assignee"
            name="assignee.name"
            path="assignee.name"
            doc={positionState}
            state={state}
          />
          <SimpleTextField
            id="description"
            title="Position Description"
            name="description"
            path="description"
            doc={positionState}
            state={state}
          />
          <RangeField
            id="totalExperience"
            title="Total Experience"
            name="totalExperience"
            path="criteria.totalExperience"
            doc={positionState}
            state={state}
          />
          <RangeField
            id="age"
            title="Age"
            name="age"
            path="criteria.age"
            doc={positionState}
            state={state}
          />
          <RangeField
            id="budget"
            title="Budget"
            name="budget"
            path="criteria.budget"
            doc={positionState}
            state={state}
            showCurrencyBox
          />
          <SkillGroupListField
            title="Must have skills"
            id="technologies"
            name="technologies.expected"
            path="criteria.technologies.expected"
            doc={positionState}
            state={state}
          />
          <SkillGroupListField
            title="Other Required Skills"
            id="otherSkills"
            name="technologies.otherExpected"
            path="criteria.technologies.otherExpected"
            doc={positionState}
            state={state}
          />
          <SimpleListField
            title="Plus"
            name="technologies.plus"
            path="criteria.technologies.plus"
            doc={positionState}
            state={state}
            labelKey="title"
          />
          <LanguageListField
            id="languages"
            title="Languages"
            name="languages"
            path="criteria.languages"
            doc={positionState}
            state={state}
          />
          <SimpleListField
            title="Universities"
            name="education.universities"
            path="criteria.education.universities"
            doc={positionState}
            state={state}
            labelKey="name"
          />
          <EducationTypeTextField
            title="Education Type"
            name="education.type"
            path="criteria.education.type"
            doc={positionState}
            state={state}
          />
          <RemoteOptionTextField
            id="positionLocation"
            title="Working Options"
            name="positionLocation.remote"
            path="criteria.positionLocation.remote"
            doc={positionState}
            state={state}
          />
          <SimpleTextField
            title="Working Place"
            name="positionLocation.expected.title"
            path="criteria.positionLocation.expected.title"
            doc={positionState}
            state={state}
          />
          <SimpleListField
            id="benefits"
            title="Benefits"
            name="benefits.expected"
            path="criteria.benefits.expected"
            doc={positionState}
            state={state}
            labelKey="label"
          />
          <RowBlock width="600px" mt={3}>
            <ArrowButton onClick={handlePrevious}>{"< Previous"}</ArrowButton>

            <ArrowButton onClick={handleNext}>{"Next >"}</ArrowButton>
          </RowBlock>
        </>
      )}
    </div>
  );
}
