import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { EditIcon, GridListIcon, TrashIcon } from "../../../../Icons";

const IconContainer = styled.div`
  padding-left: 10px;
  cursor: pointer;
`;

const GridMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin-top: 50px;
  position: absolute;
  right: 40px;
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

const GridMenuItem = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background: #e0e1e1;
    border-radius: 5px;
  }
`;

const GridMenuText = styled.span`
  line-height: 14px;
`;

export default function GridListMenu({ handleEdit, handleDelete, loading }) {
  const [showMenu, setShowMenu] = useState(false);

  const iconRef = useRef();
  const menuRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (
      iconRef.current &&
      menuRef.current &&
      !iconRef.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const handleMenuItemClick = async (item) => {
    if (item === "edit") {
      await handleEdit();
      setShowMenu(false);
    }
    if (item === "delete") {
      await handleDelete();
      setShowMenu(false);
    }
  };

  return (
    <>
      <IconContainer onClick={toggleMenu} ref={iconRef}>
        <GridListIcon />
      </IconContainer>
      {showMenu && (
        <GridMenu ref={menuRef}>
          <GridMenuItem onClick={() => handleMenuItemClick("edit")}>
            <EditIcon color="currentColor" size="14" strokeWidth="1" />
            <GridMenuText>Edit</GridMenuText>
          </GridMenuItem>
          <GridMenuItem onClick={() => handleMenuItemClick("delete")}>
            <TrashIcon />
            <GridMenuText>
              Delete {loading && <Spin size="small" />}
            </GridMenuText>
          </GridMenuItem>
        </GridMenu>
      )}
    </>
  );
}
