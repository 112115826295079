import React from "react";
import { DeleteIcon, EditIcon } from "../../../../../Icons";
import {
  StyledCard,
  ActionBox,
  DeleteButton,
  EditButton,
  CardTitle,
} from "../styles";
import { LANGUAGE_LEVEL_TYPES } from "../../../../../../constants";

const getLevelTitle = (value) => {
  const type = LANGUAGE_LEVEL_TYPES.find((i) => i.value === value);
  if (type) {
    return `(${type?.label})`;
  }
  return "";
};

export default function LanguageCard({ data, onDelete, onEdit }) {
  return (
    <StyledCard>
      <ActionBox>
        <DeleteButton type="button" onClick={onDelete}>
          <DeleteIcon color="currentColor" />
        </DeleteButton>
        <EditButton type="button" onClick={onEdit}>
          <EditIcon color="currentColor" />
        </EditButton>
      </ActionBox>
      <CardTitle>
        {data?.language?.label} {getLevelTitle(data.level)}
      </CardTitle>
    </StyledCard>
  );
}
