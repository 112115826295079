import { useDispatch } from "react-redux";
import { open } from "../store/slices/question";

const useQuestion = () => {
  const dispatch = useDispatch();

  const question = (payload = {}, callback) => {
    dispatch(
      open({
        ...payload,
        callback,
      })
    );
  };

  return question;
};

export default useQuestion;
