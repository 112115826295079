/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { Tag, message } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ButtonLink } from "tg-design";
import { MATCH_STATE_CHANGE } from "../../../matchDetail/queries";
import { TgTable, TgDrawer } from "../../../tg-styles/Layouts";
import MatchDetailDrawer from "../../../matchDetail/MatchDetailDrawer";
import { MATCH_STATES, POSITION_STATES } from "../../../../constants";
import { captureErrorWithData } from "../../../../helper";

const statusFilter = [
  { text: "Created", value: MATCH_STATES.CREATED },
  { text: "Sent", value: MATCH_STATES.SENT },
  { text: "Interested", value: MATCH_STATES.INTERESTED },
  { text: "In Assessment", value: MATCH_STATES.TG_ASSESSMENT },
  { text: "Company Assessment", value: MATCH_STATES.COMPANY_ASSESSMENT },
  { text: "Interview", value: MATCH_STATES.INTERVIEW },
  { text: "Sent Offer", value: MATCH_STATES.SENT_OFFER },
  { text: "Filled", value: MATCH_STATES.HIRED },
  { text: "Passed", value: MATCH_STATES.PASSED },
  { text: "Failed", value: MATCH_STATES.FAILED },
  { text: "Canceled", value: MATCH_STATES.CANCELED },
];

const positionStateFilter = [
  { text: "On Hold", value: POSITION_STATES.ON_HOLD },
  { text: "Created", value: POSITION_STATES.CREATED },
  { text: "Submitted", value: POSITION_STATES.SUBMITTED },
  { text: "In Progress", value: POSITION_STATES.IN_PROGRESS },
  { text: "Filled", value: POSITION_STATES.FILLED },
  { text: "Closed", value: POSITION_STATES.CLOSED },
  { text: "Canceled", value: POSITION_STATES.CANCELED, color: "red" },
];

const getSearchParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

const encodeFilters = (filters) => {
  const result = {};
  // eslint-disable-next-line
  const filters_state = filters.state ? filters.state.join(",") : "";
  const filters_position_state = filters?.position?.state
    ? filters.position.state.join(",")
    : "";
  // eslint-disable-next-line
  if (filters_state) {
    // eslint-disable-next-line
    result.filters_state = filters_state;
  }
  if (filters_position_state) {
    result.filters_position_state = filters_position_state;
  }
  return result;
};

const decodeFilters = (line) => {
  const state = new URLSearchParams(line).get("filters_state");
  const positionState = new URLSearchParams(line).get("filters_position_state");
  return {
    state: state ? state.split(",") : undefined,
    "position.state": positionState ? positionState.split(",") : undefined,
  };
};

export default function Matches({ matches, refetch }) {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [searchQueryData, setSearchQueryData] = useState({
    pagination: {
      current: parseInt(getSearchParam("currentPage") || 1),
      pageSize: parseInt(getSearchParam("pageSize") || 10),
    },
    filters: decodeFilters(useLocation().search),
    sorter: {
      field: getSearchParam("sort_field") || "createdAt",
      order: getSearchParam("sort_order") || "ascend",
    },
  });
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [changeMatchState] = useMutation(MATCH_STATE_CHANGE);

  const getPositionStateColor = (value) => {
    const state = positionStateFilter.find((i) => i.value === value);
    return state.color || "blue";
  };

  useEffect(() => {
    query.set("currentPage", searchQueryData.pagination.current);
    query.set("pageSize", searchQueryData.pagination.pageSize);
    query.set("sort_field", searchQueryData.sorter.field);
    if (searchQueryData.sorter.order) {
      query.set("sort_order", searchQueryData.sorter.order);
    } else {
      query.delete("sort_order");
    }

    query.delete("filters_state");
    query.delete("filters_position_state");

    const encodedFilters = encodeFilters(searchQueryData.filters);
    // eslint-disable-next-line
    for (const key in encodedFilters) {
      query.set(key, encodedFilters[key]);
    }

    history.push({ search: query.toString() });
    // eslint-disable-next-line
  }, [searchQueryData]);

  const columns = [
    {
      title: "Match Details",
      dataIndex: "id",
      render: () => <ButtonLink>Details</ButtonLink>,
    },
    {
      title: "Company",
      dataIndex: "position",
      render: (value) =>
        value && (
          <Link to={`/companies/${value.company.id}`}>
            {value.company.name}{" "}
            {value.isTest && <span style={{ color: "#ff7675" }}>-Test-</span>}
          </Link>
        ),
    },
    {
      title: "Position",
      dataIndex: "position",
      render: (value) =>
        value && (
          <Link to={`/positions/${value.id}`}>
            {value.title}{" "}
            {value.isTest && <span style={{ color: "#ff7675" }}>-Test-</span>}
          </Link>
        ),
    },
    {
      title: "Position State",
      dataIndex: ["position", "state"],
      filters: positionStateFilter,
      filterMultiple: true,
      filteredValue: searchQueryData.filters?.["position.state"],
      onFilter: (value, record) => record.position.state.indexOf(value) === 0,
      align: "center",
      render: (value) => (
        <Tag color={getPositionStateColor(value)}>{value}</Tag>
      ),
    },
    {
      title: "Match Status",
      dataIndex: "state",
      filters: statusFilter,
      filterMultiple: true,
      filteredValue: searchQueryData.filters?.state,
      align: "center",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Score",
      dataIndex: "rate",
      align: "center",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      sortDirections: ["ascend", "descend"],
      align: "center",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (date) => dayjs(date).format("DD.MM.YYYY"),
    },
  ];

  const onClose = () => {
    setDrawerVisible(false);
    refetch();
  };

  const openDrawer = (matchId) => {
    setSelectedMatch(matchId);
    setDrawerVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSearchQueryData({
      pagination,
      filters,
      sorter,
    });
  };

  let filteredMatches = matches;
  if (searchQueryData.filters?.state) {
    filteredMatches = matches.filter((match) =>
      searchQueryData.filters?.state.includes(match.state)
    );
  }

  const handleChangeStatus = async (value) => {
    try {
      await changeMatchState({
        variables: {
          id: value.cardId,
          state: value.targetColumnId,
        },
      });
      refetch();
    } catch (error) {
      captureErrorWithData(error);
      message.error(error.message || error);
    }
  };

  return (
    <div>
      {isDrawerVisible && (
        <TgDrawer closable={false} onClose={onClose} visible={isDrawerVisible}>
          <MatchDetailDrawer
            matchId={selectedMatch}
            handleChangeStatus={handleChangeStatus}
            updateMatchState={onClose}
            onClose={onClose}
          />
        </TgDrawer>
      )}
      <TgTable
        columns={columns}
        dataSource={filteredMatches}
        sortOrder={searchQueryData.sorter.field}
        sortDirections={searchQueryData.sorter.order}
        pagination={searchQueryData.pagination}
        filters={searchQueryData.filters}
        onChange={handleChange}
        onRow={(record) => ({
          onClick: () => {
            openDrawer(record.id);
          },
        })}
      />
    </div>
  );
}
