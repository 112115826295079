import React from "react";
import { Link } from "react-router-dom";
import { ButtonLink } from "tg-design";
import dayjs from "dayjs";
import DataText from "./DataText";
import { MATCH_STATES } from "../../../constants";

export default function SummarySection({ match, setCurrentDrawer, refetch }) {
  return (
    <>
      <h2 style={{ marginTop: "15px" }}>Summary</h2>
      {match?.position && (
        <DataText title="Position">
          <Link to={`/positions/${match.position.id}`}>
            {match.position.title}
          </Link>
        </DataText>
      )}
      <DataText title="Company">
        <Link to={`/companies/${match.position.company.id}`}>
          {match.position.company.name}
        </Link>
      </DataText>
      <DataText title="Email">{match.user.email}</DataText>
      <DataText title="Inviter">
        {match?.user?.inviter?.name ? (
          <Link to={`/developers/${match?.user?.inviter?.id}`}>
            {match?.user?.inviter?.name}
          </Link>
        ) : (
          "none"
        )}
      </DataText>
      <DataText title="Phone">
        {match.user.phone ? `${match.user.phone}` : "none"}
      </DataText>
      <DataText title="Creation Time">
        {dayjs(match.createdAt).format("DD.MM.YYYY")}
      </DataText>
      {match.state === "SENT" && (
        <DataText title="Seen Status">
          {match.history.length > 0 &&
          match.history[0].actions.find((item) => item.type === 2)
            ? "Seen"
            : "Not Seen"}
        </DataText>
      )}
      {match.state === MATCH_STATES.PASSED && (
        <DataText title="Pass Reasons">
          {match.rejectReason?.length > 0
            ? match.rejectReason.map((i, index) => (
                <>
                  {i.label}
                  {match.rejectReason.length - 1 > index ? ", " : ""}
                </>
              ))
            : "No reason specified"}
        </DataText>
      )}
      {match?.failReasons && (
        <DataText title="Fail Reasons">
          <ButtonLink
            onClick={() => setCurrentDrawer("failReasons", { match, refetch })}
          >
            {match.failReasons && "See Reasons"}
            {!match.failReasons && "None"}
          </ButtonLink>
        </DataText>
      )}
      <DataText title="Actions">
        <Link to={`/print-match/${match.id}`} target="_blank" rel="noopener">
          Print
        </Link>
      </DataText>
    </>
  );
}
