import { gql } from "@apollo/client";

export const GET_RECRUITER = gql`
  query recruiter(
    $id: String
    $recruiterPositionFilters: recruiterPositionFilterInputType
  ) {
    recruiter(id: $id) {
      id
      avatarURL
      name
      role
      email
      phone
      jobType
      assignedPositionsCount(filters: $recruiterPositionFilters)
      assignedCompaniesCount
    }
  }
`;

export const LOGOUT_QUERY = gql`
  {
    logout
  }
`;

export const GET_PLACES = gql`
  query allPlaces($search: String, $showCountries: Boolean) {
    allPlaces(search: $search, showCountries: $showCountries) {
      places {
        id
        city
        country
        capital
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;
