import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_RECRUITER } from "./queries/general";

import AppContext from "./components/contexts/AppContext";
import ErrorPage from "./components/ErrorPage";

export default function AppProvider({ children }) {
  const [state, setState] = useState({
    isAuth: false,
    isAdmin: false,
  });
  const { refetch: getRecruiterData } = useQuery(GET_RECRUITER, {
    skip: true,
  });

  const [queryError, setQueryError] = useState();

  const getRecruiter = async () => {
    try {
      const result = await getRecruiterData();

      // eslint-disable-next-line
      if (!result) {
        return;
      }

      const { data } = result;
      if (data.recruiter === null) {
        setState({
          ...state,
        });
      }

      const newState = {
        ...state,
        isAuth: true,
        isAdmin: data.recruiter.role === "ADMIN",
        externalSourcingFeature: true,
      };

      setState(newState);
    } catch (err) {
      setQueryError(err);
    }
  };

  useEffect(() => {
    const prepare = async () => {
      getRecruiter();
    };

    const isAuth = localStorage.getItem("isAuth");
    if (isAuth) {
      prepare();
    } else {
      setState({
        ...state,
      });
    }
    // eslint-disable-next-line
  }, []);

  if (queryError) return <ErrorPage error={queryError} />;
  if (state.status === "pending") {
    return "Loading...";
  }

  const logout = () => {
    setState({
      ...state,
      isAuth: false,
      isAdmin: false,
    });
  };

  const refresh = () => {
    setState({
      ...state,
    });
    getRecruiter();
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        logout,
        refresh,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
