import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Tabs, Button, message } from "antd";
import { ButtonLink } from "tg-design";
import { MATCH_STATE_CHANGE, GET_POSITION_MATCHES } from "../../queries";
import { getQueries } from "../../module/QueryHelper";
import { MATCH_STATES, SORTERS } from "../../../../constants/index";
import { getUrlParams, setQueries } from "../../../../helper";
import MatchDetailDrawer from "../../../matchDetail/MatchDetailDrawer";
import { TgDrawer, TgTable } from "../../../tg-styles/Layouts";
import PartialError from "../../../ErrorPage/PartialError";

const { TabPane } = Tabs;

const ActionRow = ({ selectedRowSize, onCancel, onSubmit }) => (
  <div className="action-wrap">
    {selectedRowSize ? (
      <div className="action-row">
        <span>
          <strong>{selectedRowSize}</strong> kişi seçildi
        </span>
        <Button size="small" type="primary" onClick={() => onSubmit("SENT")}>
          Send the match
        </Button>
        <Button size="small" danger onClick={() => onSubmit("CANCELED")}>
          Cancel the match
        </Button>
        <Button size="small" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    ) : null}
  </div>
);

const initialMatchCounter = {
  CREATED: 0,
  SENT: 0,
  INTERESTED: 0,
  PASSED: 0,
  FAILED: 0,
  TG_ASSESSMENT: 0,
  DIRECTED: 0,
  CANCELED: 0,
};

const DEFAULT_LIMIT = 10;
const DEFAULT_SORT = { updatedAt: "DESC" };

export default function Matches({ positionId }) {
  const [queryString, setQueryString] = useState("");
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [matchStateChange] = useMutation(MATCH_STATE_CHANGE);
  const [getPostionMatches, { loading, error, data, refetch }] = useLazyQuery(
    GET_POSITION_MATCHES,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    const params = getUrlParams();
    if (!params.matchState) {
      params.matchState = "CREATED";
    }
    const {
      search,
      filters,
      page = 1,
      limit = DEFAULT_LIMIT,
      sort = DEFAULT_SORT,
    } = getQueries(params);
    const fetch = async () => {
      getPostionMatches({
        variables: {
          id: positionId,
          limit,
          page,
          search,
          filters,
          sort,
        },
      });
    };
    fetch();
  }, [queryString, getPostionMatches, positionId]);

  const onChange = (pagination, filters, sorter) => {
    const sort = {
      [sorter.field]: SORTERS[sorter.order],
    };
    const params = getUrlParams();
    setQueryString(
      setQueries(
        Object.assign(params, {
          page: pagination.current,
          limit: pagination.pageSize,
          sort: JSON.stringify(sort),
          ...filters,
        })
      )
    );
  };

  const handleTabChange = (e) => {
    const params = getUrlParams();
    params.page = 1;
    setQueryString(
      setQueries(
        Object.assign(params, {
          matchState: e,
        })
      )
    );
  };

  if (error) return <PartialError />;

  const onCancel = () => setSelectedRowIds([]);

  const sendMatches = async (state) => {
    try {
      const { data } = await matchStateChange({
        variables: { ids: selectedRowIds, state },
      });
      await refetch();
      onCancel();
      if (data) {
        message.success(
          `Toplam ${data.matchStateChange.total} Eşleşme gönderildi. ${data.matchStateChange.success.length} başarılı, ${data.matchStateChange.failed.length} başarısız.`
        );
      }
    } catch (error) {
      message.error(`Eşleşmeler gönderilemedi ${error}`);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowIds,
    onChange: (selectedRowId) => {
      setSelectedRowIds(selectedRowId);
    },
  };

  const matchCounter = data
    ? data.position.matches.matchCounter
    : initialMatchCounter;

  const matchList = data
    ? data.position.matches.matchList.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      })
    : [];

  const getDefaultPage = () => {
    const params = getUrlParams();
    const { page = 1 } = getQueries(params);
    return page;
  };

  const getDefaultLimit = () => {
    const params = getUrlParams();
    const { limit = DEFAULT_LIMIT } = getQueries(params);
    return limit;
  };

  const showDrawer = (id) => {
    setSelectedMatch(id);
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const columns = [
    {
      title: "",
      dataIndex: "id",
      render: (value) => (
        <ButtonLink onClick={() => showDrawer(value)}>Detail</ButtonLink>
      ),
    },
    {
      title: "User",
      dataIndex: ["user", "name"],
      render: (value, record) => (
        <Link to={`/developers/${record.user.id}`}>{value}</Link>
      ),
    },
    {
      title: "Role",
      dataIndex: ["user", "role", "title"],
    },
    {
      title: "Experience",
      dataIndex: ["user", "experience"],
      align: "center",
    },
    {
      title: "Birthyear",
      dataIndex: ["user", "birthyear"],
      align: "center",
    },
    {
      title: "Linkedin Profile",
      dataIndex: ["user", "social", "linkedin"],
      align: "center",
      render: (link, record) => {
        if (link) {
          return /^https?:\/\//.test(link) ? (
            <a target="_blank" href={link} rel="noopener noreferrer">
              Link
            </a>
          ) : (
            <a
              target="_blank"
              href={`https://${link}`}
              rel="noopener noreferrer"
            >
              Link
            </a>
          );
        }

        return (
          <a
            target="_blank"
            href={`https://www.linkedin.com/search/results/all/?keywords=${record.user.name}`}
            rel="noopener noreferrer"
          >
            Search at LinkedIn
          </a>
        );
      },
    },
    {
      title: "Match Date",
      dataIndex: "createdAt",
      align: "center",
      render: (date) => {
        const now = dayjs();
        const dateDiff = dayjs(now).diff(date, "day");
        return dateDiff === 0 ? "Bugün" : `${dateDiff} gün önce`;
      },
      sorter: () => {},
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      align: "center",
      defaultSortOrder: "descend",
      render: (date) => {
        const now = dayjs();
        const dateDiff = dayjs(now).diff(date, "day");
        return dateDiff === 0 ? "Bugün" : `${dateDiff} gün önce`;
      },
      sorter: () => {},
    },
    {
      title: "Score",
      dataIndex: "rate",
      align: "center",
      sorter: () => {},
    },
  ];

  const matchTable = (
    <TgTable
      key={(record) => record.id}
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={matchList}
      loading={loading}
      onChange={onChange}
      pagination={{
        pageSize: getDefaultLimit(),
        defaultCurrent: getDefaultPage(),
        total: data && data.position.matches.totalCount,
      }}
    />
  );

  const getDefaultTabKey = () => {
    const params = getUrlParams();
    return params.matchState || MATCH_STATES.CREATED;
  };

  return (
    <div>
      {isDrawerVisible && (
        <TgDrawer closable={false} onClose={onClose} visible={isDrawerVisible}>
          <MatchDetailDrawer matchId={selectedMatch} />
        </TgDrawer>
      )}

      <Tabs
        defaultActiveKey={getDefaultTabKey()}
        onChange={(key) => handleTabChange(key)}
        size="small"
        tabBarGutter="15px"
      >
        <TabPane tab={`Awaiting (${matchCounter.CREATED})`} key="created">
          <ActionRow
            selectedRowSize={selectedRowIds.length}
            onCancel={onCancel}
            onSubmit={sendMatches}
          />
          <TgTable
            key={(record) => record.id}
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={matchList}
            loading={loading}
            onChange={onChange}
            pagination={{
              pageSize: getDefaultLimit(),
              defaultCurrent: getDefaultPage(),
              total: matchCounter.CREATED,
            }}
          />
        </TabPane>
        <TabPane tab={`Matched (${matchCounter.SENT})`} key="sent">
          {matchTable}
        </TabPane>
        <TabPane
          tab={`Interested (${matchCounter.INTERESTED})`}
          key="interested"
        >
          {matchTable}
        </TabPane>
        <TabPane
          tab={`In Assessment (${matchCounter.TG_ASSESSMENT})`}
          key="tg_assessment"
        >
          {matchTable}
        </TabPane>
        <TabPane tab={`Forwarded (${matchCounter.DIRECTED})`} key="directed">
          {matchTable}
        </TabPane>
        <TabPane tab={`Passed (${matchCounter.PASSED})`} key="passed">
          {matchTable}
        </TabPane>
        <TabPane tab={`Failed (${matchCounter.FAILED})`} key="failed">
          {matchTable}
        </TabPane>
        <TabPane tab={`Canceled (${matchCounter.CANCELED})`} key="canceled">
          {matchTable}
        </TabPane>
      </Tabs>
    </div>
  );
}
