import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "tg-design";
import LinkedinWizardMenu from "./LinkedinWizardMenu";
import { ProfilePhotoTab } from "./ProfilePhotoTab";
import { AboutTab } from "./AboutTab";
import { WorkExperienceTab } from "./WorkExperienceTab";
import { EducationTab } from "./EducationTab";
import { LanguageTab } from "./LanguageTab";
import { SkillTab } from "./SkillTab";
import { LINKEDIN_WIZARD_MENU_ITEMS } from "../../../../../../constants";

const WizardContainer = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0px 2px 10px #a9a9a9;
  border-radius: 5px;
`;

const WizardContent = styled.div`
  padding: 19px 41px;
`;

const WizardButtons = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 41px;
  position: absolute;
  bottom: 50px;
`;

export default function LinkedinWizard({
  checked,
  linkedinData,
  handleStateChange,
  handleFinish,
  user,
  loading,
}) {
  const [wizardStep, setWizardStep] = useState(1);

  const handleNext = () => {
    if (wizardStep === 6) {
      handleFinish();
    } else {
      setWizardStep(wizardStep + 1);
    }
  };
  const handlePrevious = () => {
    setWizardStep(wizardStep - 1);
  };
  return (
    <WizardContainer>
      <LinkedinWizardMenu checked={checked} wizardStep={wizardStep} />
      <div style={{ position: "relative", flex: "1" }}>
        <WizardContent>
          {LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "profile-photo" && (
            <ProfilePhotoTab
              user={user}
              checked={checked}
              linkedinData={linkedinData}
              handleStateChange={(tabName, changes) =>
                handleStateChange(tabName, changes)
              }
            />
          )}
          {LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "about" && (
            <AboutTab
              user={user}
              checked={checked}
              linkedinData={linkedinData}
              handleStateChange={(tabName, changes) =>
                handleStateChange(tabName, changes)
              }
            />
          )}
          {LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "work-experience" && (
            <WorkExperienceTab
              user={user}
              linkedinData={linkedinData}
              handleStateChange={(tabName, changes) =>
                handleStateChange(tabName, changes)
              }
            />
          )}
          {LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "education" && (
            <EducationTab
              user={user}
              linkedinData={linkedinData}
              handleStateChange={(tabName, changes) =>
                handleStateChange(tabName, changes)
              }
            />
          )}
          {LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "languages" && (
            <LanguageTab
              user={user}
              linkedinData={linkedinData}
              handleStateChange={(tabName, changes) =>
                handleStateChange(tabName, changes)
              }
            />
          )}
          {LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "skills" && (
            <SkillTab
              linkedinData={linkedinData}
              handleStateChange={(tabName, changes) =>
                handleStateChange(tabName, changes)
              }
            />
          )}
        </WizardContent>
        <WizardButtons>
          {wizardStep > 1 && (
            <Button className="secondary" onClick={handlePrevious}>
              Previous
            </Button>
          )}
          <Button onClick={handleNext} loading={loading}>
            {wizardStep !== 6 ? "Next" : "Finish"}
          </Button>
        </WizardButtons>
      </div>
    </WizardContainer>
  );
}
