import React from "react";
import { useLocation } from "react-router-dom";
import { LanguageIcon } from "../../../../Icons";
import EmptyData from "../EditDeveloperTab/EmptyData";
import {
  SectionContainer,
  SectionTitle,
  Container,
  InfoContainer,
  IconContainer,
  InformationConatiner,
  Title,
  SecondaryTitle,
} from "./styles";
import { LANGUAGE_LEVEL_TYPES } from "../../../../../constants";

function getLanguageLevel(level) {
  const item = LANGUAGE_LEVEL_TYPES.find(
    (i) => i.value === parseInt(level, 10)
  );
  if (item) {
    return item?.label;
  }

  return null;
}

export default function LanguageSection({ user }) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  if (!user.languages || (user.languages.length === 0 && isPrintPage)) {
    return <></>;
  }
  return (
    <SectionContainer>
      <SectionTitle>Language</SectionTitle>

      {(!user.languages || user.languages.length === 0) && <EmptyData />}

      {user.languages.map((language, index) => {
        return (
          <Container key={index}>
            <InfoContainer>
              <IconContainer>
                <LanguageIcon />
              </IconContainer>
              <InformationConatiner>
                <Title>{language.language?.label}</Title>
                <SecondaryTitle>
                  {getLanguageLevel(language?.level)}
                </SecondaryTitle>
              </InformationConatiner>
            </InfoContainer>
          </Container>
        );
      })}
    </SectionContainer>
  );
}
