import React from "react";
import styled from "styled-components";
import {
  TgKanbanColumnTitle,
  TgKanbanColumnBadge,
  TgKanbanColumnActions,
} from ".";

const Title = styled.span`
  &.is-substate {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
`;

export default function TgKanbanColumnHeader({
  title,
  hasSubState = false,
  isSubstate = false,
  count,
  menus,
}) {
  return (
    <TgKanbanColumnTitle hasSubState={hasSubState}>
      <Title className={isSubstate ? "is-substate" : ""}>{title}</Title>
      <TgKanbanColumnBadge>{count}</TgKanbanColumnBadge>
      <TgKanbanColumnActions>{menus}</TgKanbanColumnActions>
    </TgKanbanColumnTitle>
  );
}
