import React from "react";
import { Image } from "antd";
import { AimOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import MatchStateChange from "./MatchStateChange";

export default function MatchHeader({ position, developer, match, refetch }) {
  const history = useHistory();

  const handleHistory = ({ section, id }) => {
    history.push(`/${section}/detail/${id}`);
  };

  return (
    <div className="detail-header match-header">
      <div>
        <Image width={90} src={position.company.logo} />
        <div className="detail-header__text">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleHistory({ section: "position", id: position.id })
            }
          >
            {position.title}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleHistory({ section: "company", id: position.company.id })
            }
          >
            {position.company.name}
          </div>
        </div>
      </div>
      <div className="divider">
        <AimOutlined style={{ fontSize: "26px", color: "#aabec7" }} />
      </div>

      <div>
        <Image width={90} src={developer.avatarURL} />
        <div className="detail-header__text">
          <div
            onClick={() =>
              handleHistory({ section: "developer", id: developer.id })
            }
            style={{ cursor: "pointer" }}
          >
            {developer.name}
          </div>

          <div>{developer.role?.title}</div>
        </div>
      </div>
      <div className="match-action">
        <MatchStateChange
          id={match.id}
          defaultState={match.state}
          refetch={refetch}
        />
      </div>
    </div>
  );
}
