import React from "react";
import styled from "styled-components";
import {
  EyeIcon,
  MeetingNoteIcon,
  PrivateNoteIcon,
  VerifiedIcon,
} from "../../../Icons";
import { EditIconContainer } from "./NoteSection";

export const NoteTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
  gap: 10px;
  padding: 8px 10px;
  font-weight: 600;
  font-size: 14px;
  background: ${({ background, active, color }) =>
    active ? color : background};
  border: 1px solid;
  border-color: ${({ background, active, color }) =>
    active ? background : color};
  box-sizing: border-box;
  border-radius: 5px;
  cursor: ${({ selectable }) => (selectable ? "pointer" : "")};
  box-shadow: ${({ active }) => (active ? "0 1px 13px 2px #dddddd" : "none")};
`;

const NoteTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const types = [
  {
    icon: PrivateNoteIcon,
    title: "Private Note",
    key: "PRIVATE",
    background: "rgba(238, 104, 104, 0.16)",
    color: "#EE6868",
  },
  {
    icon: MeetingNoteIcon,
    title: "Meeting Note",
    key: "MEETING",
    background: "rgba(100, 183, 220, 0.25)",
    color: "#64B7DC",
  },
  {
    icon: EyeIcon,
    title: "Cauition Note",
    key: "CAUTION",
    background: "rgba(251, 221, 128, 0.16)",
    color: "#fbdd80",
  },
  {
    icon: VerifiedIcon,
    title: "Praise Note",
    key: "PRAISE",
    background: "rgba(143, 222, 172, 0.25)",
    color: "#8fdeac",
  },
];

export default function NoteType({ typeKey, showSelect, handleSelect }) {
  const TypeIcon = (type) => {
    const { icon: TypeIconComponent } = type;
    return (
      <TypeIconComponent
        fill="rgb(68, 68, 68)"
        width="20"
        height="20"
        viewbox="0 0 40 40"
      />
    );
  };

  const TypeSelection = () => {
    return (
      <NoteTypesContainer>
        {types.map((type) => (
          <NoteTypeWrapper
            key={type.key}
            onClick={() => handleSelect(type.key)}
            active={typeKey === type.key || false}
            background={type.background}
            color={type.color}
            selectable={showSelect}
          >
            <EditIconContainer size="20px">{TypeIcon(type)}</EditIconContainer>
            {type.title}
          </NoteTypeWrapper>
        ))}
      </NoteTypesContainer>
    );
  };

  const SimpleType = ({ typeKey }) => {
    const type = types.find((t) => t.key === typeKey);
    return (
      <NoteTypesContainer>
        <NoteTypeWrapper background={type.background} color={type.color}>
          <EditIconContainer size="20px">{TypeIcon(type)}</EditIconContainer>
          {type.title}
        </NoteTypeWrapper>
      </NoteTypesContainer>
    );
  };

  return (
    <>
      {showSelect && <TypeSelection />}
      {!showSelect && <SimpleType typeKey={typeKey} />}
    </>
  );
}
