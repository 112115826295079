import React, { useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Form, Input, Select } from "antd";
import { Button } from "tg-design";
import { GET_ALL_RECRUITER, GET_ALL_COMPANY } from "../queries";
import Companysearch from "./CompanySearch";
import { VALIDATION_MESSAGES } from "../../../constants";
import PartialError from "../../ErrorPage/PartialError";

const { Option } = Select;

export default function PositionFields({
  handlePosition,
  initialValues,
  btnName,
  submitLoading,
}) {
  const {
    loading: recLoading,
    error: recError,
    data: recruiterData,
  } = useQuery(GET_ALL_RECRUITER, {
    variables: {
      limit: 100,
    },
  });
  const [form] = Form.useForm();

  const [getAllCompany, { loading: companyLoading, error: companyError }] =
    useLazyQuery(GET_ALL_COMPANY);

  useEffect(() => {
    if (!initialValues) {
      (async () => {
        await getAllCompany();
      })();
    }
  }, [initialValues, getAllCompany]);

  const setCompanyData = (e) => {
    form.setFieldsValue({
      company: e,
    });
  };

  if (recLoading || companyLoading) return "loading";
  if (recError || companyError) return <PartialError />;

  return (
    <div style={{ width: "100% " }}>
      <Form
        form={form}
        name="positions-field"
        onFinish={handlePosition}
        validateMessages={VALIDATION_MESSAGES}
        layout="vertical"
      >
        {initialValues ? (
          <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true }]}
            requiredMark="optional"
            initialValue={initialValues.company.value}
          >
            <Select options={[initialValues.company]} disabled />
          </Form.Item>
        ) : (
          <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true }]}
            requiredMark="optional"
          >
            <Companysearch
              setSelectedKeys={setCompanyData}
              confirm={() => {}}
              showClearButton={false}
            />
          </Form.Item>
        )}
        <Form.Item
          name="title"
          label="Position Title"
          rules={[{ required: true }]}
          requiredMark="optional"
        >
          <Input placeholder="i.e. Senior Backend Engineer" />
        </Form.Item>
        <Form.Item
          name="assignee"
          label="Talent Partner"
          rules={[{ required: true }]}
          requiredMark="optional"
        >
          <Select placeholder="Select TP" optionFilterProp="children">
            {recruiterData.allRecruiter.recruiters.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="submit" block loading={submitLoading}>
            {btnName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
