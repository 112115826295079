import React, { useState } from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GET_MATCH } from "./queries";
import DeveloperProfile from "./DeveloperProfile/index";
import { TgTabs, TgTab } from "../tg-styles/TgTabs";
import {
  BackgroundTab,
  ComparisonTab,
  ConversationTab,
  ActivityTab,
} from "./SidebarTabs";
import GeneralInfoTab from "../developers/detail/tabs/GeneralInfoTab";
import PartialError from "../ErrorPage/PartialError";
import FlagTab from "../developers/detail/tabs/FlagTab";
import ExternalNotesTab from "./SidebarTabs/ExternalNotesTab";
import { MATCH_STATES } from "../../constants/states";

const Content = styled.div`
  overflow-y: scroll;
  max-height: 100%;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #bbb;
  }
`;

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Sidebar({
  id,
  history,
  setCurrentDrawer,
  handleChangeStatus,
  setUpdateCardId,
  setNextMatch,
  customFlows,
  updateMatchState,
}) {
  const query = useUrlQuery();
  const [currentTab, setCurrentTab] = useState(
    query.get("drawerTab") || "comparison"
  );
  const [match, setMatchData] = useState();

  const { loading, error, refetch } = useQuery(GET_MATCH, {
    variables: { id, historyLimit: 100 },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.errors) {
        setMatchData(data.match);
      }
    },
  });

  const handleChangeState = async ({ state, substate, id }) => {
    await handleChangeStatus({
      sourceColumnId: match.state,
      sourceSubstate: match.substate,
      targetColumnId: state,
      targetSubstate: substate,
      cardId: id,
    });
    setMatchData({
      ...match,
      state,
      substate,
    });
    if (typeof setNextMatch === "function") {
      setNextMatch();
    }
  };

  const handleSeenAll = () => {
    setMatchData({
      ...match,
      unseenConversationCount: 0,
    });
  };

  if (loading) return <Spin />;
  const getTab = () => {
    switch (currentTab) {
      case "comparison":
        return (
          <ComparisonTab
            match={match}
            setCurrentDrawer={setCurrentDrawer}
            refetch={refetch}
          />
        );
      case "profile":
        return <GeneralInfoTab user={match.user} />;
      case "background":
        return <BackgroundTab match={match} />;
      case "internalNotes":
        return <FlagTab user={match.user} sidebarView />;

      case "externalNotes":
        return <ExternalNotesTab match={match} />;
      case "conversation":
        return (
          <ConversationTab
            match={match}
            refetch={refetch}
            onSeenAll={handleSeenAll}
          />
        );
      case "activity":
        return <ActivityTab match={match} />;
      default:
        setCurrentTab("comparison");
        break;
    }
  };

  if (error) return <PartialError />;

  return (
    <>
      {match && (
        <div style={{ height: "calc(100% - 240px)" }}>
          <DeveloperProfile
            match={match}
            history={history}
            handleChangeState={handleChangeState}
            setUpdateCardId={setUpdateCardId}
            setNextMatch={setNextMatch}
            customFlows={customFlows}
            updateMatchState={updateMatchState}
          />

          <div>
            <TgTabs
              currentTab={currentTab}
              handleChange={setCurrentTab}
              tabKey="drawerTab"
            >
              <TgTab name="comparison">Comparison</TgTab>
              <TgTab name="profile">Profile&#160;Info</TgTab>
              <TgTab name="background">Background</TgTab>

              {match.state === MATCH_STATES.EXTERNAL_SOURCING && (
                <TgTab name="externalNotes">External Notes</TgTab>
              )}

              <TgTab name="internalNotes">Internal Notes</TgTab>
              <TgTab name="conversation" count={match.unseenConversationCount}>
                Interview Note
              </TgTab>
              <TgTab name="activity">Activity</TgTab>
            </TgTabs>
          </div>
          <Content>{getTab()}</Content>
        </div>
      )}
    </>
  );
}
