import React, { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "@apollo/client";
import { Card, Statistic, Row, Col } from "antd";
import { ButtonLink } from "tg-design";
import styled from "styled-components";
import { GET_POSITION_REPORTS, GET_REJECT_REASON } from "../../queries";
import { TgTable, TgDrawer } from "../../../tg-styles/Layouts";
import CustomReportForm from "../../drawers/ExternalMatchReportForm";

import CompanyReport from "./CompanyReport";
import PartialError from "../../../ErrorPage/PartialError";

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeftSwitch = styled.div`
  padding: 15px;
  border-radius: 30px 0 0 30px;
  cursor: pointer;
  border-color: ${(props) =>
    props.isCompanyReport === false ? "#64B7DC" : "rgb(145,145,145)"};
  color: ${(props) =>
    props.isCompanyReport === false ? "#64B7DC" : "rgb(145,145,145)"};
`;

const RightSwitch = styled.div`
  padding: 15px;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
  border-color: ${(props) =>
    props.isCompanyReport === true ? "#64B7DC" : "rgb(145,145,145)"};
  color: ${(props) =>
    props.isCompanyReport === true ? "#64B7DC" : "rgb(145,145,145)"};
`;

const Title = styled.div`
  font-weight: bold;
  color: #00000073;
  margin-bottom: 10px;
`;

const Seperator = styled.hr`
  border: 1px solid #dddcdd;
  border-radius: 4px;
  width: 100%;
  margin: 7px 0 12px 0;
`;

dayjs.extend(relativeTime);

const rejectColumns = [
  {
    title: "Pass Reasons",
    dataIndex: "label",
    key: "label",
    sorter: (a, b) => a.label - b.label,
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.count - b.count,
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
    sorter: (a, b) => a.rate - b.rate,
  },
];

const toInterestRate = (stats) => {
  if (stats.matchCount === 0) {
    return "%0";
  }
  const rate = ((stats.interestedCount / stats.matchCount) * 100).toFixed(2);
  return `%${rate}`;
};

const toPassedRate = (stats) => {
  if (stats.matchCount === 0) {
    return "%0";
  }
  const rate = ((stats.passedCount / stats.matchCount) * 100).toFixed(2);
  return `%${rate}`;
};

const toRejectReasonRate = (total, count) => {
  if (total === 0) {
    return "%0";
  }
  return `%${((count / total) * 100).toFixed(2)}`;
};

const toMailRate = (total, count) => {
  if (total === 0) {
    return "0 (%0)";
  }
  return `${count} (%${((count / total) * 100).toFixed(2)})`;
};

const getCompareDateForTimePeriod = (position) => {
  const closedHistory = position.history
    ? position.history.find((i) => i.current === "CLOSED")
    : null;

  if (closedHistory) {
    return closedHistory.createdAt;
  }

  return new Date();
};

export default function Reports({ position }) {
  const [drawerState, setDrawerState] = useState(null);
  const [isCompanyReport, setIsCompanyReport] = useState(false);

  const {
    loading: isReportLoading,
    error: reportError,
    data: report,
  } = useQuery(GET_POSITION_REPORTS, {
    variables: {
      id: position.id,
    },
  });

  const {
    loading: isRejectReasonLoading,
    error: rejectReasonError,
    data: rejectReasons,
  } = useQuery(GET_REJECT_REASON);

  if (isReportLoading || isRejectReasonLoading) return "loading";
  if (reportError || rejectReasonError) return <PartialError />;

  const totalPassCount = (report.positionReports.rejectReasonStats || [])
    .map((i) => i.count)
    .reduce((a, b) => a + b, 0);

  // reject reasonların istatistiksel oranları hesaplanıyor.
  const rejectReasonsItems = rejectReasons.allRejectReason.map((reason) => {
    const stat = report.positionReports.rejectReasonStats.find(
      (i) => i.id === reason.id
    );

    const count = stat ? stat.count : 0;
    return {
      key: reason?.id,
      label: reason?.label,
      count,
      rate: toRejectReasonRate(totalPassCount, count),
    };
  });

  report.positionReports.rejectReasonStats.forEach((stat) => {
    if (!rejectReasons.allRejectReason.some((i) => i.id === stat.id)) {
      rejectReasonsItems.push({
        ...stat,
        key: stat.id,
        rate: toRejectReasonRate(totalPassCount, stat.count),
      });
    }
  });

  const onDrawerClosed = () => {
    setDrawerState(null);
  };

  return (
    <div>
      {drawerState && (
        <TgDrawer closable={false} onClose={onDrawerClosed} visible>
          {drawerState}
        </TgDrawer>
      )}
      <Card
        title="External Statistics"
        style={{ marginBottom: "10px" }}
        extra={
          <ButtonLink
            onClick={() =>
              setDrawerState(
                <CustomReportForm
                  report={position.externalMatchReports}
                  positionId={position.id}
                  handleClose={onDrawerClosed}
                />
              )
            }
          >
            Update External Report
          </ButtonLink>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title="Total Notified Count"
              value={position?.externalMatchReports?.notified}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Total Passed Count"
              value={position?.externalMatchReports?.notInterested}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Total Interviewed but not signed up count"
              value={position?.externalMatchReports?.interviewedButNotSignedUp}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Total Force Matched People"
              value={report?.positionReports?.forceMatchCount}
            />
          </Col>
        </Row>
      </Card>

      <Card
        title={
          <SwitchContainer>
            <LeftSwitch
              isCompanyReport={isCompanyReport}
              onClick={() => setIsCompanyReport(false)}
            >
              General Statistics
            </LeftSwitch>
            <span
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                color: "rgb(221,220,221)",
              }}
            >
              |
            </span>
            <RightSwitch
              isCompanyReport={isCompanyReport}
              onClick={() => setIsCompanyReport(true)}
            >
              See Company Report
            </RightSwitch>
          </SwitchContainer>
        }
        style={{ marginBottom: "10px" }}
      >
        {isCompanyReport && <CompanyReport positionId={position.id} />}
        {!isCompanyReport && (
          <>
            <Title>Position Statistics</Title>
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="Total Match Count"
                  value={report?.positionReports?.matchCount}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Apply Link Clicked User Count"
                  value={report?.positionReports?.clickedApplyLinkCount}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Days Open"
                  value={dayjs(report.positionReports.createdAt).fromNow(
                    getCompareDateForTimePeriod(position),
                    true
                  )}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Positive Responses"
                  value={report?.positionReports?.interestedCount}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Negative Responses"
                  value={report?.positionReports?.passedCount}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Positive Response Ratio (Interested / Matched)"
                  value={toInterestRate(report.positionReports)}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Negative Response Ratio (Passed / Matched)"
                  value={toPassedRate(report.positionReports)}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Failed From Interested"
                  value={
                    report.positionReports.failedCount.failedFromInterested
                  }
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Failed From TG Assessment"
                  value={
                    report.positionReports.failedCount.failedFromTgAssessment
                  }
                />
              </Col>
              <Seperator />
              <Title>New Match Mail Statistics</Title>
              <Col span={24}>
                <Statistic
                  title="Sent"
                  value={report.positionReports.mailStat.delivered}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Opened"
                  value={toMailRate(
                    report.positionReports.mailStat.delivered,
                    report.positionReports.mailStat.opened
                  )}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Clicked"
                  value={toMailRate(
                    report.positionReports.mailStat.delivered,
                    report.positionReports.mailStat.clicked
                  )}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>
      <TgTable dataSource={rejectReasonsItems} columns={rejectColumns} />
    </div>
  );
}
