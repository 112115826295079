import React from "react";
import styled from "styled-components";
import { Button } from "tg-design";
import FormScaffold from "../../../../Form/FormScaffold";

export const SectionTitle = styled.h2`
  display: flex;
  justify-content: space-between;
`;

export const StyledCard = styled.div`
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
`;

export const ActionButton = styled.button`
  border: none;
  background: white;
  cursor: pointer;
  color: #aaa;
  width: 30px;
  height: 30px;
`;

export const DeleteButton = styled(ActionButton)`
  &:hover {
    color: #ff9898;
  }
`;

export const EditButton = styled(ActionButton)`
  margin-top: -10px;
  &:hover {
    color: #64b7dc;
  }
`;

export const ActionBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const CardTitle = styled.h4`
  font-weight: bold;
`;

export const CardLine = styled.p`
  margin: 0px;
`;

export const InlineInputs = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const HalfSizeInput = styled.div`
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;

  :first-child {
    padding-left: 0px;
  }

  :last-child {
    padding-right: 0px;
  }
`;

export const ApplyButton = ({ onClick, disabled }) => {
  return (
    <Button onClick={onClick} disabled={disabled} style={{ marginTop: "10px" }}>
      Apply
    </Button>
  );
};

export const RowInput = ({ children }) => {
  return (
    <FormScaffold options={{ showStatus: false }}>{children}</FormScaffold>
  );
};
