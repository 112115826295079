import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { GET_PASSIVE_USERS } from "../queries";
import { TgTable } from "../../tg-styles/Layouts";
import DeleteRequestsHeader from "./header";
import PartialError from "../../ErrorPage/PartialError";

export default function DeletedUserTable() {
  const columns = [
    {
      title: "User",
      dataIndex: "name",
    },
    {
      title: "Delete Reason",
      dataIndex: "deleteReason",
      align: "center",
      render: (deleteReason) =>
        deleteReason && deleteReason[0] && deleteReason[0].reason
          ? deleteReason[deleteReason.length - 1].reason?.label
          : "No Reason",
    },
    {
      title: "Deleted At",
      dataIndex: "updatedAt",
      align: "center",
      render: (date) => dayjs(date).format("DD.MM.YYYY"),
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      defaultSortOrder: "ascend",
    },
  ];
  const [userData, setUserData] = useState();
  const [getAllUsers, { loading, error }] = useLazyQuery(GET_PASSIVE_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setUserData(data.getAllPassiveUsers);
    },
  });

  useEffect(() => {
    const fetch = async () => {
      getAllUsers();
    };

    fetch();
  }, [getAllUsers]);

  if (error) return <PartialError />;

  return (
    <div>
      <DeleteRequestsHeader name="deleted" />

      <TgTable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={userData || []}
        loading={loading}
      />
    </div>
  );
}
