import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { message, Spin, Switch } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import StatusChangeModal from "../../../tg-styles/TgKanban/StatusChangeModal";
import { EditBoxIcon, TrashIcon2 } from "../../../Icons";
import {
  TgKanbanContainer,
  TgKanbanHeader,
  TgKanbanBody,
  TgKanbanColumn,
  TgKanbanColumnBody,
  TgKanbanColumnCard,
  TgKanbanColumnFooter,
  TgKanbanColumnHeader,
  TgKanbanTrash,
  TgKanbanSubColumn,
  TgKanbanSubColumnContainer,
  MenuAction,
} from "../../../tg-styles/TgKanban";
import SearchInput from "../../../Form/SearchInput";
import SortButton from "../../../Form/SortButton";
import MatchPreview from "./MatchPreview";
import {
  GET_STATE_WITH_MATCHES,
  MATCH_STATE_CHANGE,
  ADD_CUSTOM_FLOW,
  REMOVE_CUSTOM_FLOW,
} from "../../queries";
import {
  MATCH_STATES,
  MATCH_STATE_ALLOWANCE_LIST,
} from "../../../../constants/states";
import { TgDrawer } from "../../../tg-styles/Layouts";
import MatchDetailDrawer from "../../../matchDetail/MatchDetailDrawer";
import { captureErrorWithData, useTriggerOnce } from "../../../../helper";
import {
  FAIL_REASON_MODAL_TYPES,
  CLOSED_POSITION_STATES,
  DEVELOPER_STATUSES,
} from "../../../../constants";
import FailReasonsModal from "../../../tg-styles/TgKanban/FailReasonModal/FailReasonModal";
import CustomFlowEditModal from "./CustomFlowEditModal";
import useQuestion from "../../../../hooks/useQuestion";
import EmptyWorkHistoryModal from "../../../tg-styles/TgKanban/EmptyWorkHistoryModal";
import { GET_USER_WORK_HISTORY } from "../../../developers/queries";

const DEFAULT_LIMIT = 20;

const sortFields = [
  { value: "rate", title: "Sort by match score, ascending" },
  { value: "-rate", title: "Sort by match score, descending" },
  { value: "updatedAt", title: "Sort by last updated, ascending" },
  { value: "-updatedAt", title: "Sort by last updated, descending" },
];

let columns = [
  { id: "EXTERNAL_SOURCING", title: "External Sourcing" },
  { id: "CREATED", title: "Internal Sourcing" },
  { id: "SENT", title: "Sent" },
  { id: "INTERESTED", title: "Interested" },
  { id: "TG_ASSESSMENT", title: "In Assessment" },
  { id: "COMPANY_ASSESSMENT", title: "Company Assessment" },
  { id: "INTERVIEW", title: "Interview" },
  { id: "SENT_OFFER", title: "Sent Offer" },
  { id: "HIRED", title: "Filled" },
  { id: "PASSED", title: "Passed", borderColor: "#FFBBBA" },
  { id: "FAILED", title: "Failed", borderColor: "#FFBBBA" },
  { id: "CANCELED", title: "Canceled", borderColor: "#FFBBBA" },
];

const isTrashActive = (drag) => {
  if (!drag) {
    return false;
  }

  const allowedStates = [
    MATCH_STATES.CREATED,
    MATCH_STATES.TG_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.INTERESTED,
  ];

  return allowedStates.includes(drag.sourceColumnId);
};

const getTrashState = (drag) => {
  const states = {
    [MATCH_STATES.FAILED]: [
      MATCH_STATES.TG_ASSESSMENT,
      MATCH_STATES.INTERVIEW,
      MATCH_STATES.COMPANY_ASSESSMENT,
      MATCH_STATES.INTERESTED,
    ],
    [MATCH_STATES.CANCELED]: [MATCH_STATES.CREATED],
  };

  for (const key of Object.keys(states)) {
    if (states[key].includes(drag.sourceColumnId)) {
      return key;
    }
  }

  return null;
};

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const InvisibleColumnWarningMessage = () => {
  return (
    <>
      <p>
        You are trying to move the match to an <b>invisible column</b> for the
        company.
      </p>
      <p>
        If you do that, we will send the notification mail but the match cannot
        be seen in the company app.
      </p>
    </>
  );
};

const getAllStatesAsQuery = (customFlows) => {
  const states = [];
  columns.forEach((column) => {
    if (customFlows[column.id] && customFlows[column.id].length > 0) {
      customFlows[column.id].forEach((substate) => {
        states.push({
          state: column.id,
          substate: substate.uuid,
          limit: DEFAULT_LIMIT,
        });
      });
    } else {
      states.push({
        state: column.id,
        limit: DEFAULT_LIMIT,
      });
    }
  });
  return states;
};

export default function MatchesNew({
  positionId,
  position: {
    state: positionState,
    customFlows,
    company,
    title: positionTitle,
    isExternal,
  },
  refetch,
}) {
  const question = useQuestion();
  const containerRef = useRef(null);
  const kanbanBodyRef = useRef();
  const query = useUrlQuery();
  const history = useHistory();
  const triggerOnce = useTriggerOnce();
  const [updateCardId, setUpdateCardId] = useState(null);
  const [editCustomFlow, setEditCustomFlow] = useState(null);
  const [drag, setDrag] = useState(null);
  const [filters, setFilters] = useState({
    search: query.get("search"),
    sort: query.get("sort") ? query.get("sort") : "-updatedAt",
    applier: false,
  });
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [isFailedReasonModalOpen, setIsFailedReasonModalOpen] = useState({
    isOpen: false,
    type: null,
  });
  const [isEmptyWorkHistoryModalOpen, setIsEmptyWorkHistoryModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dragState, setDragState] = useState({
    sourceColumnId: null,
    sourceSubstate: null,
    targetColumnId: null,
    targetSubstate: null,
    cardId: null,
  });
  const [isDrawerVisible, setDrawerVisible] = useState(
    query.get("drawerId") !== null
  );
  const [selectedMatch, setSelectedMatch] = useState({
    id: query.get("drawerId"),
  });
  const [isSearching, setSearching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { refetch: getStatesWithMatches } = useQuery(GET_STATE_WITH_MATCHES, {
    variables: {
      positionId,
    },
    skip: true,
  });

  const [loadUserWithLatestWorkHistoryData] = useLazyQuery(
    GET_USER_WORK_HISTORY,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [changeMatchState] = useMutation(MATCH_STATE_CHANGE);
  const [addCustomFlow] = useMutation(ADD_CUSTOM_FLOW);
  const [removeCustomFlow] = useMutation(REMOVE_CUSTOM_FLOW);
  const [rightMover, setRightMover] = useState(false);
  const [rightMoverInterval, setRightMoverInterval] = useState(false);
  const [leftMover, setLeftMover] = useState(false);
  const [leftMoverInterval, setLeftMoverInterval] = useState(false);

  const refreshTopRecords = useCallback(
    async (variables) => {
      setLoading(true);
      const states = getAllStatesAsQuery(customFlows);

      const response = await getStatesWithMatches({
        ...variables,
        states,
      });
      const result = response?.data?.getStatesWithMatches || [];
      setLoading(false);
      return result;
    },
    // eslint-disable-next-line
    [getStatesWithMatches]
  );

  useEffect(() => {
    if (rightMover) {
      const mover = setInterval(() => {
        if (kanbanBodyRef && kanbanBodyRef.current) {
          kanbanBodyRef.current.scrollLeft += 10;
        }
      }, 10);
      setRightMoverInterval(mover);
    } else {
      clearInterval(rightMoverInterval);
    }
    // eslint-disable-next-line
  }, [rightMover]);

  useEffect(() => {
    if (isDrawerVisible) {
      const { id } = selectedMatch;
      query.set("drawerId", id);
      history.push({ search: query.toString() });
    } else {
      query.delete("drawerId");
      query.delete("drawerTab");
      history.push({ search: query.toString() });
    }
    // eslint-disable-next-line
  }, [isDrawerVisible]);

  useEffect(() => {
    const { id } = selectedMatch;
    if (id && isDrawerVisible) {
      query.set("drawerId", id);
      history.push({ search: query.toString() });
    }
    // eslint-disable-next-line
  }, [selectedMatch]);

  useEffect(() => {
    if (leftMover) {
      const mover = setInterval(() => {
        if (
          kanbanBodyRef &&
          kanbanBodyRef.current &&
          kanbanBodyRef.current.scrollLeft > 0
        ) {
          kanbanBodyRef.current.scrollLeft -= 10;
          if (kanbanBodyRef.current.scrollLeft < 0) {
            kanbanBodyRef.current.scrollLeft = 0;
          }
        }
      }, 10);
      setLeftMoverInterval(mover);
    } else {
      clearInterval(leftMoverInterval);
    }
    // eslint-disable-next-line
  }, [leftMover]);

  const moveKanbanScroll = ({ clientX }) => {
    const { left } = kanbanBodyRef.current.getBoundingClientRect();
    const leftDiff = clientX - left - 225;
    if (leftDiff < 0 && !leftMover) {
      return setLeftMover(true);
    }

    if (leftDiff > 0 && leftMover) {
      return setLeftMover(false);
    }

    // Sağa doğru bir hareket var mı?
    const rightDiff = kanbanBodyRef.current.offsetWidth + 110 - clientX;
    if (rightDiff < 0 && !rightMover) {
      setRightMover(true);
    } else if (rightDiff > 0 && rightMover) {
      setRightMover(false);
    }
  };

  useEffect(() => {
    (async () => {
      let items = await refreshTopRecords({
        search: filters.search,
        sort: filters.sort,
        applier: filters.applier,
      });
      if (isExternal) {
        items = items.filter(
          (item) =>
            item.state === MATCH_STATES.SENT ||
            item.state === MATCH_STATES.PASSED
        );
      }
      setData(
        items.map((item) => {
          return {
            ...item,
            isEnded: item.count < item.limit,
          };
        })
      );
    })();
    // eslint-disable-next-line
  }, []);

  if (!data) return "loading";

  const isAllowed = ({ state, substate = null }) => {
    if (!drag) {
      return true;
    }

    if (
      substate &&
      drag.sourceColumnId === state &&
      drag.sourceSubstate !== substate
    ) {
      return true;
    }

    if (!MATCH_STATE_ALLOWANCE_LIST[state]) {
      return true;
    }
    return MATCH_STATE_ALLOWANCE_LIST[state].indexOf(drag.sourceColumnId) > -1;
  };

  const getStateData = (data, state, substate = null) => {
    const item = data.find((i) => i.state === state && i.substate === substate);
    return item || null;
  };

  const getMatchesByStates = (data, state, substate = null) => {
    const item = getStateData(data, state, substate);

    if (item) {
      return item?.matches || null;
    }

    return [];
  };

  const clearMovers = () => {
    setLeftMover(false);
    setLeftMover(false);
    if (leftMoverInterval) {
      clearInterval(leftMoverInterval);
      setLeftMoverInterval(null);
    }
    if (rightMoverInterval) {
      clearInterval(rightMoverInterval);
      setRightMoverInterval(null);
    }
  };

  const moveCardInState = ({
    newState,
    sourceColumnId,
    sourceSubstate = null,
    targetColumnId,
    targetSubstate = null,
    cardId,
  }) => {
    const card = getMatchesByStates(
      newState,
      sourceColumnId,
      sourceSubstate
    ).find((item) => item.id === cardId);

    const targetArray = newState.find(
      (i) => i.state === targetColumnId && i.substate === targetSubstate
    );

    const result = newState.map((item) => {
      if (item.state === sourceColumnId && item.substate === sourceSubstate) {
        return {
          ...item,
          matches: item.matches.filter((match) => match.id !== cardId),
          count: item.count - 1,
        };
      }

      if (item.state === targetColumnId && item.substate === targetSubstate) {
        return {
          ...item,
          matches: [
            { ...card, state: targetColumnId, substate: targetSubstate },
            ...targetArray.matches,
          ],
          count: item.count + 1,
        };
      }
      return {
        ...item,
      };
    });

    return result;
  };

  const handleChangeStatus = async ({
    sourceColumnId,
    sourceSubstate = null,
    targetColumnId,
    targetSubstate = null,
    cardId,
  }) => {
    if (
      (sourceColumnId === targetColumnId &&
        sourceSubstate === targetSubstate) ||
      (sourceColumnId !== targetColumnId &&
        !isAllowed({ state: targetColumnId }))
    ) {
      return;
    }

    let newState = [...data];

    try {
      newState = moveCardInState({
        newState,
        sourceColumnId,
        sourceSubstate,
        targetColumnId,
        targetSubstate,
        cardId,
      });
      setData(newState);
      setDrag(null);

      setUpdateCardId(cardId);
      await changeMatchState({
        variables: {
          id: cardId,
          state: targetColumnId,
          substate: targetSubstate,
        },
      });
      // Drawer kapandıktan sonra hızlıca match state değişikliği yapılırsa,
      // aynı match iki farklı sütunda gözüküyordu. Bu nedenle state'i değiştiğinden
      // emin olduğumuz match'in, başka sütunlarda olmamasını sağlıyoruz.
      setData((lastVersion) => {
        return lastVersion.map((item) => {
          if (
            item.state === targetColumnId &&
            item.substate === targetSubstate
          ) {
            return item;
          }
          const newMatches = item.matches.filter((i) => i.id !== cardId);
          return {
            ...item,
            matches: newMatches,
            count: item.count - (item.matches.length - newMatches.length),
          };
        });
      });
      setUpdateCardId(null);
    } catch (error) {
      // flip-flop. Eğer başarısız olursa, match card'ı taşıdığımız
      // yerde tutmaya devam edemeyiz.
      newState = moveCardInState({
        newState,
        sourceColumnId: targetColumnId,
        sourceSubstate: targetSubstate,
        targetColumnId: sourceColumnId,
        targetSubstate: sourceSubstate,
        cardId,
      });
      setData(newState);
      captureErrorWithData(error);
      message.error(`State update failed! ${error.message || error}`);
      setUpdateCardId(null);
    }
  };

  const onDrop = async () => {
    // Bizim kutucuklarımızdan başka bir şey seçilim sürüklenirse işlem yapmayız.
    if (!drag) {
      return;
    }

    clearMovers();
    const {
      sourceColumnId,
      sourceSubstate,
      targetColumnId,
      targetSubstate,
      cardId,
    } = drag;
    if (
      (sourceColumnId === targetColumnId &&
        sourceSubstate === targetSubstate) ||
      (sourceColumnId !== targetColumnId &&
        !isAllowed({ state: targetColumnId }))
    ) {
      setDrag(null);
      setUpdateCardId(null);
      return;
    }

    setDragState({
      sourceColumnId,
      sourceSubstate,
      targetColumnId,
      targetSubstate,
      cardId,
    });

    const card = getMatchesByStates(data, sourceColumnId, sourceSubstate).find(
      (item) => item.id === cardId
    );

    setSelectedUser({
      id: card.user.id,
      name: card?.user?.name ? card.user.name : "No name",
      rate: card.rate,
      role: card?.user?.role?.title ? card.user.role.title : "No role",
    });
    if (
      sourceColumnId === MATCH_STATES.TG_ASSESSMENT &&
      targetColumnId === MATCH_STATES.FAILED
    ) {
      setIsFailedReasonModalOpen({
        isOpen: true,
        type: FAIL_REASON_MODAL_TYPES.FROM_TG_ASSESSMENT_TO_FAILED,
      });
    } else if (
      (sourceColumnId === MATCH_STATES.COMPANY_ASSESSMENT ||
        sourceColumnId === MATCH_STATES.INTERVIEW) &&
      targetColumnId === MATCH_STATES.FAILED
    ) {
      setIsFailedReasonModalOpen({
        isOpen: true,
        type: FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED,
      });
    } else if (targetColumnId === MATCH_STATES.COMPANY_ASSESSMENT) {
      const { data: latestWorkHistoryData } =
        await loadUserWithLatestWorkHistoryData({
          variables: { id: card.user.id },
        });

      if (
        latestWorkHistoryData?.user?.workHistory?.length === 0 &&
        latestWorkHistoryData?.user?.experience !== 0
      ) {
        return setIsEmptyWorkHistoryModalOpen(true);
      }
      if (
        sourceColumnId !== MATCH_STATES.COMPANY_ASSESSMENT &&
        (card.conversation.length === 0 || !card.conversation)
      ) {
        return message.error(
          "Interview note field (Conversation tab) cannot be empty!"
        );
      }

      const nextStepAction = () => {
        if (sourceColumnId === targetColumnId) {
          handleChangeStatus({
            sourceColumnId,
            sourceSubstate,
            targetColumnId,
            targetSubstate,
            cardId,
          });
        } else {
          /* eslint-disable-next-line no-lonely-if */
          setIsStatusChangeModalOpen(true);
        }
      };

      const mainState = columns.find((i) => i.id === targetColumnId);
      if (mainState.customFlows?.length > 0) {
        const substate = mainState.customFlows.find(
          (i) => i.uuid === targetSubstate
        );
        if (substate.isVisibleToCompany === false) {
          question(
            {
              content: <InvisibleColumnWarningMessage />,
            },
            nextStepAction
          );
        } else {
          nextStepAction();
        }
      } else {
        nextStepAction();
      }
    } else {
      handleChangeStatus({
        sourceColumnId,
        sourceSubstate,
        targetColumnId,
        targetSubstate,
        cardId,
      });
    }
  };

  const handleStatusChangeModalClose = () => {
    setIsStatusChangeModalOpen(false);
  };

  const handleFailReasonChangeModalClose = () => {
    setIsFailedReasonModalOpen({ isOpen: false, type: null });
  };

  const updateMatchState = ({
    cardId,
    sourceColumnId,
    sourceSubstate,
    targetColumnId,
    targetSubstate,
  }) => {
    let newState = [...data];
    newState = moveCardInState({
      newState,
      sourceColumnId,
      sourceSubstate,
      targetColumnId,
      targetSubstate,
      cardId,
    });
    setData(newState);
  };

  const handleFailedStatusChangeCompleted = () => {
    setIsFailedReasonModalOpen({ isOpen: false, type: null });
    updateMatchState(dragState);
  };

  const handleDragChange = debounce((changes) => {
    // Bizim kutucuklarımızdan başka bir şey seçilim sürüklenirse işlem yapmayız.
    if (!drag) {
      return;
    }

    moveKanbanScroll(changes);

    if (
      drag.targetColumnId !== changes.targetColumnId ||
      drag.targetSubstate !== changes.targetSubstate
    ) {
      setDrag({
        ...drag,
        ...changes,
      });
    }
  }, 10);

  const handleAcceptChange = async () => {
    const {
      cardId,
      sourceColumnId,
      sourceSubstate,
      targetColumnId,
      targetSubstate,
    } = dragState;
    setDragState({
      sourceColumnId: null,
      sourceSubstate: null,
      targetColumnId: null,
      targetSubstate: null,
      cardId: null,
    });
    handleChangeStatus({
      sourceColumnId,
      sourceSubstate,
      targetColumnId,
      targetSubstate,
      cardId,
    });
  };

  const handleCardClick = ({ cardId, column, substate, currentIndex }) => {
    setSelectedMatch({
      id: cardId,
      column,
      substate,
      currentIndex,
    });
    setDrawerVisible(true);
  };

  const setStateData = (state, substate = null, value) => {
    setData((lastVersion) => {
      return lastVersion.map((item) => {
        if (item.state === state && item.substate === substate) {
          return {
            ...item,
            ...value,
          };
        }
        return item;
      });
    });
  };

  const handleCloseEmptyWorkHistoryModal = () => {
    setIsEmptyWorkHistoryModalOpen(false);
  };

  const handleCloseDrawer = async () => {
    setDrawerVisible(false);
    const { column, substate } = selectedMatch;
    if (!column) {
      return;
    }

    const state = column.id;
    const substateUuid = substate?.uuid || null;
    const currentStateData = data.find(
      (item) => item.state === state && item.substate === substateUuid
    );

    setLoading(true);
    const response = await getStatesWithMatches({
      ...filters,
      states: [
        {
          state,
          substate: substateUuid,
          limit: currentStateData.limit,
        },
      ],
    });
    setLoading(false);
    const [stateResult] = response?.data?.getStatesWithMatches || [null];
    setStateData(state, substateUuid, {
      matches: stateResult?.matches || [],
      count: stateResult.count,
      isLoading: false,
      isEnded: stateResult.count < stateResult.limit,
      limit: stateResult.limit,
    });
  };

  const handleSearchChange = async (search) => {
    const newFilters = {
      ...filters,
      search,
    };
    setFilters(newFilters);
    query.set("search", search);
    history.push({ search: query.toString() });

    const states = getAllStatesAsQuery(customFlows);
    setSearching(true);
    const response = await getStatesWithMatches({
      ...newFilters,
      states,
    });
    const result = response?.data?.getStatesWithMatches || [];
    setSearching(false);
    setData(result);
  };

  const handleActiveSortChange = async (sort) => {
    const newFilters = {
      ...filters,
      sort,
    };
    setFilters(newFilters);
    query.set("sort", sort);
    history.push({ search: query.toString() });

    const states = getAllStatesAsQuery(customFlows);
    setLoading(true);
    const response = await getStatesWithMatches({
      ...newFilters,
      states,
    });
    const result = response?.data?.getStatesWithMatches || [];
    setLoading(false);
    setData(result);
  };

  const handleSwitchFilterChange = async (applier) => {
    const newFilters = {
      ...filters,
      applier,
    };
    setFilters(newFilters);
    query.set("applier", applier);
    history.push({ search: query.toString() });

    const states = getAllStatesAsQuery(customFlows);
    setLoading(true);
    const response = await getStatesWithMatches({
      ...newFilters,
      states,
    });
    const result = response?.data?.getStatesWithMatches || [];
    setLoading(false);
    setData(result);
  };

  const handleLoadMore = ({ column, substate }) => {
    triggerOnce(async () => {
      const state = column.id;
      const substateUuid = substate?.uuid || null;
      const currentStateData = data.find(
        (item) => item.state === state && item.substate === substateUuid
      );

      if (
        !currentStateData ||
        currentStateData.isLoading ||
        currentStateData.isEnded
      ) {
        return;
      }

      setStateData(state, substateUuid, {
        isLoading: true,
      });

      const result = await getStatesWithMatches({
        states: [
          {
            state,
            substate: substateUuid,
            limit: currentStateData.limit + DEFAULT_LIMIT,
          },
        ],
        search: filters.search,
        sort: filters.sort,
      });

      const [stateResult] = result?.data?.getStatesWithMatches || [null];
      setStateData(state, substateUuid, {
        matches: stateResult?.matches || [],
        isLoading: false,
        isEnded: stateResult.count < stateResult.limit,
        limit: stateResult.limit,
      });
    }, 500);
  };

  const setNextMatch = async () => {
    const { id, currentIndex } = selectedMatch;

    // Doğrudan URL üzerinden gelmişse sonraki match'i gösterme şansımız yok.
    if (currentIndex === undefined) {
      return handleCloseDrawer();
    }

    const state = selectedMatch.column.id;
    const substate = selectedMatch.substate?.uuid || null;

    const column = data.find(
      (i) => i.state === state && i.substate === substate
    );

    let activeIndex = currentIndex;

    if (!column.matches.filter((i) => i.id !== id)[activeIndex]) {
      activeIndex--;
    }

    if (!column.matches.filter((i) => i.id !== id)[activeIndex]) {
      activeIndex = null;
    }

    if (activeIndex === null) {
      const response = await getStatesWithMatches({
        states: [
          {
            state,
            substate,
            limit: column.limit,
          },
        ],
        search: filters.search,
        sort: filters.sort,
      });

      const [result] = response?.data?.getStatesWithMatches || [null];
      setData((value) => {
        return value.map((item) => {
          if (item.state === state && item.substate === substate) {
            return {
              ...item,
              count: result.count,
              matches: result.matches || [],
            };
          }
          return item;
        });
      });

      // Eğer başka veri gelmemişse drawer'ı kapatıyoruz
      if (result.matches.length === 0) {
        return handleCloseDrawer();
      }

      // Veri gelmişse ilk sıradakini next match olarak gösteriyoruz.
      const firstItem = result.matches[0];
      setSelectedMatch({
        ...selectedMatch,
        id: firstItem.id,
        currentIndex: 0,
      });
      return;
    }

    const nextMatch = column.matches.filter((i) => i.id !== id)[activeIndex];
    if (nextMatch) {
      setSelectedMatch({
        ...selectedMatch,
        id: nextMatch.id,
        currentIndex: activeIndex,
      });
      return;
    }

    handleCloseDrawer();
  };

  const getSelectedMatchesByStates = async (
    state,
    substate = null,
    limit = DEFAULT_LIMIT
  ) => {
    const response = await getStatesWithMatches({
      search: filters.search,
      sort: filters.sort,
      states: [
        {
          state,
          substate,
          limit,
        },
      ],
    });
    return (
      (response?.data?.getStatesWithMatches &&
        response?.data?.getStatesWithMatches[0]) ||
      []
    );
  };

  const handleAddSubcolumn = async ({ column }) => {
    try {
      const state = column.id;
      const result = await addCustomFlow({
        variables: {
          positionId,
          title: "My State",
          state,
        },
      });

      const customFlows =
        result?.data?.addCustomFlow?.customFlows[column.id] || [];
      const substate = customFlows[customFlows.length - 1]?.uuid;

      let newState = [...data];

      // Sadece ilk sütun eklendiğinde ilk sütundaki matchleri almamız lazım
      if (customFlows.length === 1) {
        newState = newState.filter(
          (i) => !(i.state === state && i.substate === null)
        );
        const mainState = data.find((i) => i.state === state);
        newState.push(
          await getSelectedMatchesByStates(state, substate, mainState?.limit)
        );
      } else {
        // Yeni bir sütunsa sadece boş bir sütun oluşturmamız lazım.
        newState.push({
          state,
          substate,
          matches: [],
          count: 0,
          limit: DEFAULT_LIMIT,
        });
      }

      setData(newState);
    } catch (error) {
      message.error(error.message);
      captureErrorWithData(error, {
        positionId,
        column,
      });
    }
  };

  const handleOpenFlowSettings = ({ column, substate }) => {
    setEditCustomFlow({
      column,
      substate,
    });
  };

  const handleOnSaveCustomFlowChanges = async () => {
    setEditCustomFlow(null);
    refetch();
  };

  const handleRemoveSubcolumn = async ({ column, substate }) => {
    try {
      await removeCustomFlow({
        variables: {
          positionId,
          state: column.id,
          substate: substate.uuid,
        },
      });
      const newState = data.filter(
        (i) => i.state !== column.id || i.substate !== substate.uuid
      );

      const deletableColumn = data.find(
        (i) => i.state === column.id && i.substate === substate.uuid
      );

      // Sadece hiç sütun kalmadığında ana state'in matchlerini almamız lazım
      if (column.customFlows.length === 0) {
        newState.push(
          await getSelectedMatchesByStates(
            column.id,
            null,
            deletableColumn?.limit
          )
        );
      }

      setData(newState);
    } catch (error) {
      message.error(error.message);
      captureErrorWithData(error, {
        positionId,
        column,
        substate,
      });
    }
  };

  const getMatchCountByStates = (state, substate) => {
    if (substate === undefined) {
      const columns = data.filter((i) => i.state === state);
      return columns.reduce((previous, current) => {
        return previous + (current.count || 0);
      }, 0);
    }
    const column = data.find(
      (i) => i.state === state && i.substate === substate
    );
    return column?.count || 0;
  };

  const getTrashSubstate = (drag) => {
    const state = getTrashState(drag);
    if (customFlows[state] && customFlows[state].length > 0) {
      const [firstItem] = [...customFlows[state]].sort(
        (a, b) => a.index - b.index
      );
      return firstItem?.uuid;
    }
    return null;
  };

  columns.forEach((column) => {
    column.customFlows = customFlows[column.id] || [];
  });

  if (isExternal) {
    columns = columns.filter(
      (column) =>
        column.id === MATCH_STATES.SENT || column.id === MATCH_STATES.PASSED
    );
  }

  let fixedSubstates = [];

  fixedSubstates = columns.filter(
    (i) => i.id === MATCH_STATES.EXTERNAL_SOURCING
  );

  if (fixedSubstates.length > 0) {
    fixedSubstates = fixedSubstates[0].customFlows
      .slice(0, 4)
      .map((i) => i.title);
  }

  return (
    <>
      {isFailedReasonModalOpen.isOpen && (
        <FailReasonsModal
          companyName={company.name}
          roleTitle={positionTitle}
          matchId={dragState.cardId}
          substate={dragState.targetSubstate}
          onClose={handleFailReasonChangeModalClose}
          onCompleted={handleFailedStatusChangeCompleted}
          type={isFailedReasonModalOpen.type}
        />
      )}
      {isStatusChangeModalOpen && (
        <StatusChangeModal
          onClose={handleStatusChangeModalClose}
          onAccept={handleAcceptChange}
          user={selectedUser}
          status={isStatusChangeModalOpen}
        />
      )}
      {isEmptyWorkHistoryModalOpen && (
        <EmptyWorkHistoryModal
          onClose={handleCloseEmptyWorkHistoryModal}
          user={selectedUser}
          history={history}
          status={isStatusChangeModalOpen}
        />
      )}
      {editCustomFlow && (
        <CustomFlowEditModal
          positionId={positionId}
          data={editCustomFlow}
          onCancel={() => setEditCustomFlow(null)}
          onSave={handleOnSaveCustomFlowChanges}
        />
      )}
      {isDrawerVisible && (
        <TgDrawer
          closable={false}
          onClose={handleCloseDrawer}
          visible={isDrawerVisible}
          width="80%"
        >
          <MatchDetailDrawer
            history={history}
            matchId={selectedMatch.id}
            handleChangeStatus={handleChangeStatus}
            setNextMatch={setNextMatch}
            updateMatchState={updateMatchState}
            customFlows={customFlows}
          />
        </TgDrawer>
      )}
      <TgKanbanContainer refx={containerRef}>
        <TgKanbanHeader>
          {isLoading && <Spin />}
          {isExternal && (
            <>
              <span>Show Apply Click</span>
              <Switch
                onChange={handleSwitchFilterChange}
                checked={filters.applier}
              />
            </>
          )}

          <SearchInput
            type="text"
            placeholder="Search by names"
            onLazyChange={handleSearchChange}
            isSearching={isSearching}
            defaultValue={filters.search}
          />
          <SortButton
            menus={sortFields}
            active={filters.sort}
            handleChange={handleActiveSortChange}
          />
        </TgKanbanHeader>
        <TgKanbanBody ref={kanbanBodyRef}>
          {columns.map((column) => (
            <TgKanbanColumn
              key={column.id}
              columnId={column.id}
              handleDragChange={handleDragChange}
              onDrop={onDrop}
              isOnHover={
                drag &&
                drag.targetColumnId === column.id &&
                drag.targetColumnId !== drag.sourceColumnId
              }
              isAllowed={isAllowed({ state: column.id })}
              borderColor={column.borderColor}
              hasSubState={column.customFlows.length > 0}
            >
              <TgKanbanColumnHeader
                title={column.title}
                hasSubState={column.customFlows.length > 0}
                count={getMatchCountByStates(column.id)}
                menus={
                  <MenuAction
                    title="Add subcolumn"
                    icon={<EditBoxIcon />}
                    onClick={() => handleAddSubcolumn({ column })}
                  />
                }
              />
              <TgKanbanColumnBody
                isOnHover={
                  column.customFlows.length === 0 &&
                  drag &&
                  drag.targetColumnId === column.id &&
                  drag.targetColumnId !== drag.sourceColumnId
                }
                onLoadMore={() => handleLoadMore({ column })}
                hasSubState={column.customFlows.length > 0}
              >
                {column.customFlows.length > 0 && (
                  <TgKanbanSubColumnContainer>
                    {column.customFlows.map((substate, substateIndex) => (
                      <TgKanbanSubColumn
                        key={substateIndex}
                        columnId={column.id}
                        substate={substate.uuid}
                        handleDragChange={handleDragChange}
                        onDrop={onDrop}
                        isOnHover={
                          drag &&
                          drag.targetColumnId === column.id &&
                          drag.targetSubstate === substate.uuid &&
                          (drag.targetColumnId !== drag.sourceColumnId ||
                            drag.targetSubstate !== drag.sourceSubstate)
                        }
                        isAllowed={isAllowed({
                          state: column.id,
                          substate: substate.uuid,
                        })}
                        borderColor={column.borderColor}
                        hasSubState={false}
                      >
                        <TgKanbanColumnHeader
                          title={substate.title}
                          isSubstate
                          count={getMatchCountByStates(
                            column.id,
                            substate.uuid
                          )}
                          menus={
                            !fixedSubstates.includes(substate.title) ? (
                              <>
                                <MenuAction
                                  title="Settings"
                                  icon={<EditBoxIcon />}
                                  onClick={() =>
                                    handleOpenFlowSettings({ column, substate })
                                  }
                                />
                                <MenuAction
                                  title="Delete"
                                  icon={<TrashIcon2 />}
                                  onClick={() =>
                                    handleRemoveSubcolumn({ column, substate })
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <MenuAction
                                  title="Settings"
                                  icon={<EditBoxIcon />}
                                  onClick={() =>
                                    handleOpenFlowSettings({ column, substate })
                                  }
                                />
                              </>
                            )
                          }
                        />
                        <TgKanbanColumnBody
                          isOnHover={
                            drag &&
                            drag.targetColumnId === column.id &&
                            drag.targetSubstate === substate.uuid &&
                            (drag.targetColumnId !== drag.sourceColumnId ||
                              drag.targetSubstate !== drag.sourceSubstate)
                          }
                          columnId={column.id}
                          onLoadMore={() =>
                            handleLoadMore({ column, substate })
                          }
                          hasSubState={column.customFlows.length > 0}
                        >
                          {getMatchesByStates(
                            data,
                            column.id,
                            substate.uuid
                          ).map((match, index) => (
                            <TgKanbanColumnCard
                              key={match.id}
                              cardId={match.id}
                              columnId={column.id}
                              substate={match.substate}
                              setDrag={setDrag}
                              onClick={(params) =>
                                handleCardClick({
                                  ...params,
                                  column,
                                  substate,
                                  currentIndex: index,
                                })
                              }
                              updateCardId={updateCardId}
                              draggable={
                                !CLOSED_POSITION_STATES.includes(
                                  positionState
                                ) &&
                                match.user.status !==
                                  DEVELOPER_STATUSES.PRE_LEAD
                              }
                              userStatus={match.user.status}
                            >
                              <MatchPreview match={match} />
                            </TgKanbanColumnCard>
                          ))}
                          <TgKanbanColumnFooter
                            isLoading={
                              getStateData(data, column.id, substate.uuid)
                                ?.isLoading
                            }
                            isEnded={
                              getStateData(data, column.id, substate.uuid)
                                ?.isEnded
                            }
                            count={
                              getStateData(data, column.id, substate.uuid)
                                ?.count
                            }
                          />
                        </TgKanbanColumnBody>
                      </TgKanbanSubColumn>
                    ))}
                  </TgKanbanSubColumnContainer>
                )}
                {column.customFlows.length === 0 && (
                  <>
                    {getMatchesByStates(data, column.id).map((match, index) => (
                      <TgKanbanColumnCard
                        key={match.id}
                        cardId={match.id}
                        columnId={column.id}
                        setDrag={setDrag}
                        onClick={(params) =>
                          handleCardClick({
                            ...params,
                            column,
                            currentIndex: index,
                          })
                        }
                        updateCardId={updateCardId}
                        draggable={
                          !CLOSED_POSITION_STATES.includes(positionState) &&
                          match.user.status !== DEVELOPER_STATUSES.PRE_LEAD
                        }
                        userStatus={match.user.status}
                      >
                        <MatchPreview match={match} />
                      </TgKanbanColumnCard>
                    ))}
                  </>
                )}
                <TgKanbanColumnFooter
                  isLoading={getStateData(data, column.id)?.isLoading}
                  isEnded={getStateData(data, column.id)?.isEnded}
                  count={getStateData(data, column.id)?.count}
                />
              </TgKanbanColumnBody>
            </TgKanbanColumn>
          ))}
        </TgKanbanBody>
        {isTrashActive(drag) && (
          <TgKanbanTrash
            columnId={getTrashState(drag)}
            substate={getTrashSubstate(drag)}
            handleDragChange={handleDragChange}
            onDrop={onDrop}
            isOnHover={
              drag &&
              drag.targetColumnId === getTrashState(drag) &&
              drag.targetColumnId !== drag.sourceColumnId
            }
          >
            {getTrashState(drag)}
          </TgKanbanTrash>
        )}
      </TgKanbanContainer>
    </>
  );
}
