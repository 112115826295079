import React from "react";
import styled from "styled-components";

const Red = styled.span`
  color: #ff9898;
`;

export default function IsRequired() {
  return <Red>*</Red>;
}
