import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { NumericField, Button } from "tg-design";
import * as yup from "yup";
import { message } from "antd";
import { CREATE_BULK_MATCHES } from "../../../../queries";
import { TgDrawerTitle } from "../../../../../tg-styles/Layouts";

const schema = yup.object().shape({
  createdCount: yup.number().min(0).max(200),
  sentCount: yup.number().min(0).max(200),
  interestedCount: yup.number().min(0).max(200),
  tgAssessmentCount: yup.number().min(0).max(200),
});
export default function CreateBulkMatchesForm({
  onClose,
  positionID,
  batchID,
}) {
  const [state, setState] = useState({
    createdCount: 0,
    sentCount: 0,
    interestedCount: 0,
    tgAssessmentCount: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [createBulkMatches] = useMutation(CREATE_BULK_MATCHES);

  const validateItems = () => {
    return schema.isValidSync(state);
  };

  const handleSubmit = async () => {
    const isValid = validateItems();

    if (!isValid) {
      return message.error(
        "Please ensure that your values are not greater than 200 or less than 0"
      );
    }

    setIsLoading(true);

    await createBulkMatches({
      variables: { ...state, positionID, batchID },
    });
    message.success("Bulk matches created!");
    return onClose();
  };

  const handleOnChange = (event, value) => {
    const { name } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div>
      <TgDrawerTitle title="Create bulk matches" handleClose={onClose} />
      <NumericField
        name="createdCount"
        label="Created state user count (max: 200)"
        value={state.createdCount}
        onChange={(event, { value }) => handleOnChange(event, value)}
      />
      <NumericField
        name="sentCount"
        label="Sent state user count (max: 200)"
        value={state.sentCount}
        onChange={(event, { value }) => handleOnChange(event, value)}
      />
      <NumericField
        name="interestedCount"
        label="Interested state user count (max: 200)"
        value={state.interestedCount}
        onChange={(event, { value }) => handleOnChange(event, value)}
      />
      <NumericField
        name="tgAssessmentCount"
        label="TG Assessment state user count (max: 200)"
        value={state.tgAssessmentCount}
        onChange={(event, { value }) => handleOnChange(event, value)}
      />
      <div style={{ marginTop: "10px" }}>
        <Button
          loading={isLoading}
          disabled={
            isLoading ||
            (state.createdCount === 0 &&
              state.interestedCount === 0 &&
              state.sentCount === 0 &&
              state.tgAssessmentCount === 0)
          }
          onClick={handleSubmit}
        >
          Create Bulk Matches
        </Button>
      </div>
    </div>
  );
}
