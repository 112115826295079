import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { handleHead } from "../../../helper";
import { TgHeader } from "../../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../../tg-styles/TgHeaderTabs";
import ProfileBox from "../../matchDetail/DeveloperProfile/ProfileBox";
import { GET_USER } from "../queries";
import GeneralInfoTab from "./tabs/GeneralInfoTab";
import MatchesTab from "./tabs/MatchesTab";
import VersionTab from "./tabs/VersionTab";
import Edit from "./tabs/EditDeveloperTab";
import FlagTab from "./tabs/FlagTab";
import EducationTab from "./tabs/EducationTab";
import WorkExperienceTab from "./tabs/WorkExperienceTab";
import LinkedinTab from "./tabs/LinkedinTab";
import PartialError from "../../ErrorPage/PartialError";

export default function DeveloperDetail() {
  const { userId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "general"
  );

  const [isNavigationAllowed, setIsNavigationAllowed] = useState(true);

  const { refetch, loading, error, data } = useQuery(GET_USER, {
    variables: { id: userId },
    fetchPolicy: "network-only",
  });

  if (loading) return "loading";

  if (error) return <PartialError />;

  const { matches, name } = data.user || [];
  handleHead("profileDetail", name);

  const getTab = () => {
    switch (currentTab) {
      case "edit":
        return (
          <Edit
            user={data.user}
            isNavigationAllowed={isNavigationAllowed}
            setIsNavigationAllowed={setIsNavigationAllowed}
          />
        );
      case "linkedin":
        return (
          <LinkedinTab
            user={data.user}
            setIsNavigationAllowed={setIsNavigationAllowed}
          />
        );
      case "matches":
        return <MatchesTab matches={matches} refetch={refetch} />;
      case "general":
        return <GeneralInfoTab user={data.user} />;
      case "workExperience":
        return <WorkExperienceTab user={data.user} />;
      case "education":
        return <EducationTab user={data.user} />;
      case "versions":
        if (data.user.status === "MEMBER") {
          return <VersionTab user={data.user} />;
        }
        break;
      case "internalNotes":
        return <FlagTab user={data.user} refetch={refetch} />;
      default:
        setCurrentTab("general");
        break;
    }
  };

  return (
    <div>
      <TgHeader
        breadCrumbs={[
          { title: "People", link: "/developers" },
          { title: name, link: `/developers/${userId}` },
        ]}
        notitle
      >
        <ProfileBox user={data.user} />
        <TgHeaderTabs
          currentTab={currentTab}
          handleChange={setCurrentTab}
          isNavigationAllowed={isNavigationAllowed}
          setIsNavigationAllowed={setIsNavigationAllowed}
        >
          <TgHeaderTab name="matches">Matches</TgHeaderTab>
          <TgHeaderTab name="general">General Info</TgHeaderTab>
          <TgHeaderTab name="workExperience">Work Experience</TgHeaderTab>
          <TgHeaderTab name="education">Education</TgHeaderTab>
          <TgHeaderTab name="internalNotes">Internal Notes</TgHeaderTab>
          <TgHeaderTab name="versions">Versions</TgHeaderTab>
          <TgHeaderTab name="edit">Edit</TgHeaderTab>
          <TgHeaderTab name="linkedin">Linkedin Sync</TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>
      <div style={{ paddingLeft: "10px" }}>{getTab()}</div>
    </div>
  );
}
