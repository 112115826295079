import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { message } from "antd";
import FormScaffold from "../../../Form/FormScaffold";
import TextField from "../../../Form/TextField";
import Textarea from "../../../Form/Textarea";
import RichTextEditor from "../../../Form/RichTextEditor";
import { SelectField } from "../../../Form/SelectField";
import {
  useLazyCaller,
  removeTypename,
  getUnsignedUrl,
  captureErrorWithData,
} from "../../../../helper";
import {
  UPDATE_COMPANY,
  GET_ALL_SECTOR,
  GET_ALL_COMPANY_SIZE,
} from "../../queries";
import UploadLogo from "../../modules/UploadLogo";
import UploadOfficePhotos from "../../modules/UploadOfficePhoto";
import PartialError from "../../../ErrorPage/PartialError";
import { GET_ALL_RECRUITER } from "../../../position/queries";

const investStatus = [
  { value: "NONE", name: "None" },
  { value: "PRE_SEED", name: "Pre Seed" },
  { value: "SEED", name: "Seed" },
  { value: "POST_SEED", name: "Post Seed" },
  { value: "SERIES_A", name: "Series A" },
  { value: "SERIES_B", name: "Series B" },
  { value: "SERIES_C", name: "Series C" },
  { value: "SERIES_D", name: "Series D" },
  { value: "SERIES_E", name: "Series E" },
  { value: "PUBLIC", name: "Public" },
  { value: "Acquired", name: "Acquired" },
];

const generalValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required(""),
  website: Yup.string().url(),
  linkedin: Yup.string().matches(
    /^$|((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)([^/]+\/(.)))/,
    "Link is not valid"
  ),
});

export default function GeneralInfo({ company, refetch }) {
  const [inputStatus, setInputStatus] = useState({});
  const [clientPartnerData, setClientPartnerData] = useState([]);
  const [state, setState] = useState({
    ...company,
  });
  const lazyCaller = useLazyCaller();
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const {
    loading: sectorLoading,
    error: sectorError,
    data: allSector,
  } = useQuery(GET_ALL_SECTOR);

  const {
    loading: companySizeLoading,
    error: companySizeError,
    data: allCompanySize,
  } = useQuery(GET_ALL_COMPANY_SIZE);

  const { error: recruiterError } = useQuery(GET_ALL_RECRUITER, {
    fetchPolicy: "no-cache",
    variables: {
      jobType: [1],
      limit: 100,
    },
    onCompleted: (data) => {
      setClientPartnerData(data.allRecruiter.recruiters);
    },
  });

  const handleSubmit = async (newState, field, inputKey) => {
    const inputSelector = inputKey || field;
    try {
      setInputStatus({ ...inputStatus, [inputSelector]: "loading" });
      await updateCompany({
        variables: {
          id: newState.id,
          [field]: newState[field],
        },
      });
      await refetch();
      setInputStatus({ ...inputStatus, [inputSelector]: null });
    } catch (error) {
      captureErrorWithData(error);
      message.error(error.message || error);
      setInputStatus({ ...inputStatus, [inputSelector]: "error" });
    }
  };

  const validateItem = (field, changes, handleOnValid) => {
    setInputStatus({ ...inputStatus, [field]: "pending" });

    if (!generalValidationSchema.fields[field]) {
      return handleOnValid();
    }

    generalValidationSchema.fields[field]
      .validate(changes[field])
      .then(handleOnValid)
      .catch((err) => {
        captureErrorWithData(err);
        setInputStatus({ ...inputStatus, [field]: "error" });
      });
  };

  const applyChange = ({ field, changes, inputKey }) => {
    validateItem(field, changes, () => {
      const newState = {
        ...state,
        ...changes,
      };
      setState(newState);
      lazyCaller(() => handleSubmit(newState, field, inputKey), 500);
    });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    applyChange({
      field: name,
      changes: { [name]: value },
    });
  };

  const handleOnChangeContent = (field, event) => {
    const { value } = event.target;
    const newContents = state.contents
      ? { ...state.contents, [field]: value }
      : { [field]: value };
    applyChange({
      field: "contents",
      changes: {
        contents: removeTypename(newContents),
      },
      inputKey: `contents.${field}`,
    });
  };

  const handleOnChangeValue = (field, value) => {
    applyChange({
      field,
      changes: {
        [field]: value,
      },
    });
  };

  const handleOnNumericChange = (field, event) => {
    const value = event.target.value ? parseInt(event.target.value) : null;
    handleOnChangeValue(field, value);
  };

  const getItemStatus = (name) => {
    if (inputStatus[name]) {
      return inputStatus[name];
    }

    if (Array.isArray(company[name])) {
      if (company[name].length === 0) {
        return "pending";
      }
      return "success";
    }

    if (company[name]) {
      return "success";
    }

    if (name.indexOf(".") > -1) {
      const [root, field] = name.split(".");
      if (company[root] && company[root][field]) {
        return "success";
      }
    }

    return "pending";
  };

  const onLogoStarted = () => {
    setInputStatus({ ...inputStatus, logo: "pending" });
  };

  const onLogoError = () => {
    setInputStatus({ ...inputStatus, logo: "error" });
  };

  const handleOnChangeDirectly = (field, value) => {
    const newState = {
      ...state,
      ...{
        [field]: value,
      },
    };
    setState(newState);
    handleSubmit(newState, field);
  };

  const handleOfficePhotoUpload = (photos) => {
    const newOfficePhotos = [...company.officePhotos, photos].map((i) => {
      return {
        url: getUnsignedUrl(i.url),
        description: i.description,
      };
    });
    handleOnChangeDirectly("officePhotos", newOfficePhotos);
  };

  const setOfficePhotosValidation = (value) => {
    setInputStatus({ ...inputStatus, officePhotos: value });
  };

  if (sectorLoading || companySizeLoading) return "loading";
  if (sectorError || recruiterError || companySizeError)
    return <PartialError />;

  const getCompanySectors = (allSectors, companySector) => {
    return allSectors.filter((item) => {
      return companySector.find((i) => {
        return item.id === i.id;
      });
    });
  };

  const getCompanyTeamSize = (allCompanySize, teamSize) => {
    return allCompanySize.filter((item) => {
      return item.id === teamSize;
    });
  };

  return (
    <div style={{ maxWidth: "600px" }}>
      <FormScaffold label="Company Name" status={getItemStatus("name")}>
        <TextField
          name="name"
          defaultValue={company.name}
          onChange={handleOnChange}
        />
      </FormScaffold>
      <FormScaffold label="Company Logo" status={getItemStatus("logo")}>
        <UploadLogo
          handleLogo={(url) =>
            handleOnChangeDirectly("logo", getUnsignedUrl(url))
          }
          initialLogo={company.logo}
          companyId={company.id}
          onStarted={onLogoStarted}
          onError={onLogoError}
        />
      </FormScaffold>
      <FormScaffold
        label="Company Description"
        status={getItemStatus("description")}
      >
        <RichTextEditor
          name="description"
          defaultValue={company.description}
          onChange={(value) => handleOnChangeValue("description", value)}
        />
      </FormScaffold>
      <FormScaffold label="Company Summary" status={getItemStatus("summary")}>
        <RichTextEditor
          name="summary"
          defaultValue={company.summary}
          onChange={(value) => handleOnChangeValue("summary", value)}
          toolbar={[
            ["italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
          ]}
          formats={["italic", "underline", "strike", "list", "bullet"]}
        />
      </FormScaffold>
      <FormScaffold label="Company Slogan" status={getItemStatus("slogan")}>
        <TextField
          name="slogan"
          defaultValue={company.slogan}
          onChange={handleOnChange}
        />
      </FormScaffold>
      <FormScaffold
        label="Client Partner"
        status={getItemStatus("clientPartner")}
      >
        <SelectField
          name="clientPartner"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          defaultValue={company?.clientPartner && company?.clientPartner}
          options={clientPartnerData && clientPartnerData}
          onChange={(value) => handleOnChangeValue("clientPartner", value.id)}
        />
      </FormScaffold>
      <FormScaffold label="Industry" status={getItemStatus("sector")}>
        <SelectField
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          options={allSector.allSectors}
          defaultValue={getCompanySectors(allSector.allSectors, company.sector)}
          onChange={(values) =>
            handleOnChangeValue(
              "sector",
              values.map((i) => i.id)
            )
          }
        />
      </FormScaffold>
      <FormScaffold label="Year Founded" status={getItemStatus("foundedYear")}>
        <TextField
          type="number"
          name="foundedYear"
          defaultValue={company.foundedYear}
          onChange={(event) => handleOnNumericChange("foundedYear", event)}
        />
      </FormScaffold>
      <FormScaffold label="Website" status={getItemStatus("website")}>
        <TextField
          name="website"
          defaultValue={company.website}
          onChange={handleOnChange}
        />
      </FormScaffold>
      <FormScaffold label="Linkedin" status={getItemStatus("linkedin")}>
        <TextField
          name="linkedin"
          defaultValue={company.linkedin}
          onChange={handleOnChange}
        />
      </FormScaffold>
      <FormScaffold
        label="Investment Status"
        status={getItemStatus("investStatus")}
      >
        <SelectField
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.value}
          options={investStatus}
          defaultValue={investStatus.find(
            (i) => i.name === company.investStatus
          )}
          onChange={(value) => handleOnChangeValue("investStatus", value.value)}
        />
      </FormScaffold>
      <FormScaffold label="Team Size" status={getItemStatus("teamSize")}>
        <SelectField
          isMulti={false}
          closeMenuOnSelect={true}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          options={allCompanySize.allCompanySize}
          defaultValue={getCompanyTeamSize(
            allCompanySize.allCompanySize,
            company.teamSize
          )}
          onChange={(values) => handleOnChangeValue("teamSize", values.id)}
        />
      </FormScaffold>
      <FormScaffold
        label="Tech Team Size"
        status={getItemStatus("techTeamSize")}
      >
        <SelectField
          isMulti={false}
          closeMenuOnSelect={true}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          options={allCompanySize.allCompanySize}
          defaultValue={getCompanyTeamSize(
            allCompanySize.allCompanySize,
            company.techTeamSize
          )}
          onChange={(values) => handleOnChangeValue("techTeamSize", values.id)}
        />
      </FormScaffold>
      <FormScaffold
        label="Workplace Description"
        status={getItemStatus("contents.place")}
      >
        <Textarea
          defaultValue={company.contents ? company.contents.place : ""}
          onChange={(event) => handleOnChangeContent("place", event)}
          rows="6"
        />
      </FormScaffold>
      <FormScaffold
        label="Company Culture"
        status={getItemStatus("contents.culture")}
      >
        <Textarea
          defaultValue={company.contents ? company.contents.culture : ""}
          onChange={(event) => handleOnChangeContent("culture", event)}
          rows="6"
        />
      </FormScaffold>
      <FormScaffold
        label="Office & team photos"
        status={getItemStatus("officePhotos")}
      >
        <UploadOfficePhotos
          onUpload={handleOfficePhotoUpload}
          inputStatus={inputStatus}
          setInputStatus={setInputStatus}
          initialValues={company.officePhotos}
          companyId={company.id}
          refetch={refetch}
          setStatus={setOfficePhotosValidation}
        />
      </FormScaffold>
    </div>
  );
}
