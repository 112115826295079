import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button } from "tg-design";
// eslint-disable-next-line
import moment from "moment";

import {
  Tooltip,
  DatePicker,
  Space,
  Card,
  Statistic,
  Row,
  Col,
  Typography,
} from "antd";
import { GET_SIMPLE_ANALYTICS } from "../queries";
import PartialError from "../../ErrorPage/PartialError";

const { Text } = Typography;

const infoTooltip = (content) => (
  <Tooltip title={content} color="blue">
    <Text strong type="danger" style={{ cursor: "pointer" }}>
      *
    </Text>
  </Tooltip>
);

export default function SimpleAnalytics() {
  const [getAnalytics, { data, loading, error }] =
    useLazyQuery(GET_SIMPLE_ANALYTICS);

  const [state, setState] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const handleOnChange = (_, dateValue) => {
    setState({ ...state, startDate: dateValue[0], endDate: dateValue[1] });
  };

  const handleOnClick = () => {
    const { startDate, endDate } = state;
    getAnalytics({ variables: { startDate, endDate } });
  };

  useEffect(() => {
    handleOnClick();
    // eslint-disable-next-line
  }, []);

  if (error) return <PartialError />;

  return (
    <div>
      <Space direction="horizontal" size={12}>
        <DatePicker.RangePicker
          defaultValue={[moment().startOf("month"), moment().endOf("month")]}
          ranges={{
            "This Week": [moment().startOf("week"), moment()],
            "Last Week": [
              moment().subtract(1, "weeks").startOf("week"),
              moment().subtract(1, "weeks").endOf("week"),
            ],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last 90 Days": [moment().subtract(3, "month"), moment()],
          }}
          onChange={handleOnChange}
        />
        <Button size="sm" onClick={handleOnClick}>
          Calculate
        </Button>
      </Space>

      <Card
        title="Simple Analytics"
        style={{ marginBottom: "10px", marginTop: "35px" }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="New developers (Leads + Members)"
              value={data?.analytics?.newUsers}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="New companies"
              value={data?.analytics?.newCompanies}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="Developers who got hired"
              value={data?.analytics?.hiredUsers}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="Created to Member (member / member + leads )"
              value={data?.analytics?.leadToMember}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="New users who got matched with any position "
              value={data?.analytics?.averageUsersMatchedPosition}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="Active users who got matched with any position"
              value={data?.analytics?.averageUsersMatchedPosition}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="Total number of Interested Matches"
              value={data?.analytics?.totalInterestedMatches}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="Avg. time passed for a new user (non lead) to be interested in first position"
              suffix={infoTooltip("This analytics implemented at: 02/2022")}
              value={moment
                .utc(data?.analytics?.memberToFirstInterestedPosition)
                .format("HH:mm:ss")}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="% of matches that goes created to sent (total # of users who are & were in sent / total # of users who are & were in created)"
              value={data?.analytics?.createdToSentMatches}
            />
          </Col>
          <Col span={12}>
            <Statistic
              loading={loading}
              title="Closed Account"
              value={data?.analytics?.deletedUsers}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}
