import React, { useState } from "react";
import { message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button } from "tg-design";

import { CREATE_FORCE_MATCH } from "../../queries";
import { SelectField } from "../../../Form/SelectField";
import TextField from "../../../Form/TextField";
import { handleCopy, captureErrorWithData } from "../../../../helper";

const MatchStateOptions = [{ label: "TG Assessment", value: "TG_ASSESSMENT" }];

export default function NewUserTab({ position, refetch }) {
  const [state, setState] = useState();
  const [link, setLink] = useState();
  const [addForceMatch, { loading }] = useMutation(CREATE_FORCE_MATCH, {
    onCompleted: (response) => {
      const { addForceMatch: data } = response;
      if (data) {
        setLink(data.link);
      }
    },
  });

  const handleSubmit = async () => {
    try {
      await addForceMatch({
        variables: { ...state, position },
      });
      refetch();
      message.success("The record has been created.");
    } catch (error) {
      captureErrorWithData(error);
      message.error(error.message);
    }
  };

  return (
    <div>
      {loading && "...loading"}
      {!loading && (
        <>
          <TextField
            label="User name"
            placeholder="Add name"
            width="100%"
            onChange={(e) => setState({ ...state, name: e.target.value })}
            mb={4}
          />
          <SelectField
            label="Match will be created this state"
            mb={4}
            options={MatchStateOptions}
            onChange={(event) =>
              setState({ ...state, matchState: event.value })
            }
          />
          <Button onClick={handleSubmit} block>
            Create Force Match
          </Button>
          {link && (
            <div style={{ marginTop: "20px" }}>
              <span>Force Match request created successfully</span> <br />
              <div>
                {link}{" "}
                <CopyOutlined
                  alt="copy to clipboard"
                  onClick={() => handleCopy(link)}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
