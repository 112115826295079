import React, { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Avatar, ButtonLink } from "tg-design";
import FilterLine from "./FilterLine";
import {
  EDUCATION_TYPES,
  REMOTE_OPTIONS_FOR_COMPANY,
  LANGUAGE_LEVEL_TYPES,
} from "../../../../../constants";
import { SkillCriteriaLine, BudgetCriteriaLine } from "./CriteriaLines";
import { TgDrawer } from "../../../../tg-styles/Layouts";
import CreateBulkMatchesForm from "./drawers/CreateBulkUserForm";

const Container = styled.div`
  width: 400px;
  min-width: 400px;
  min-height: 600px;
  background-color: #fff;
  filter: drop-shadow(0px 4px 8px rgba(26, 26, 26, 0.2));
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  background: #f5fafd;
  padding: 14px 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  font-family: "Inter";
  font-style: normal;
  color: #444444;
  align-items: center;
  gap: 10px;
`;

const Content = styled.div`
  padding: 14px 20px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  flex-grow: 2;
`;

const BatchDate = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

const UL = styled.ul`
  margin: 0px;
`;

const BatchCount = styled.div`
  font-weight: bold;
  font-size: 12px;
  border: 1px solid #a2daf1;
  background-color: #f5fafd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: #444444;
`;

export default function BatchCard({
  batch,
  currentFilter,
  setCurrentFilter,
  isTestPosition,
  positionID,
}) {
  const [drawerContent, setDrawerContent] = useState(null);

  const handleClose = () => {
    setDrawerContent(null);
  };

  const tryExchangeRate = batch.exchangeRate?.TRY?.value || null;

  return (
    <>
      {drawerContent && (
        <TgDrawer closable={false} visible onClose={handleClose}>
          {drawerContent}
        </TgDrawer>
      )}
      <Container>
        <Header>
          <HeaderWrapper>
            <BatchCount title="Batch count">{batch.index}</BatchCount>
            <Title>{batch.title}</Title>

            <BatchDate>
              {dayjs(batch.createdAt).format("DD/MM/YYYY HH:mm")}
            </BatchDate>
            <Avatar
              name={batch?.creator?.name}
              src={batch?.creator?.avatarURL}
            />
          </HeaderWrapper>
          {isTestPosition && (
            <ButtonLink
              onClick={() => {
                setDrawerContent(
                  <CreateBulkMatchesForm
                    onClose={handleClose}
                    batchID={batch.id}
                    positionID={positionID}
                  />
                );
              }}
            >
              Create bulk users
            </ButtonLink>
          )}
        </Header>

        <Content>
          <FilterLine
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            title="First Execution Matches"
          >
            {batch.matchCount}
          </FilterLine>
          {batch.includeLeads && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Include leads"
            >
              Yes
            </FilterLine>
          )}
          {batch.isVerifiedByTP && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Verified By TP"
            >
              Yes
            </FilterLine>
          )}
          {batch?.skills?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Must have skills"
            >
              <SkillCriteriaLine skills={batch?.skills || []} />
            </FilterLine>
          )}
          {batch?.otherExpected?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Other required skills"
            >
              <SkillCriteriaLine skills={batch?.otherExpected || []} />
            </FilterLine>
          )}
          {batch.age && batch.age.min > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Age"
            >
              {batch.age.min} - {batch.age.max} year(s)
            </FilterLine>
          )}
          {batch?.languages?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Min language skills"
            >
              <UL>
                {batch.languages.map((item, index) => (
                  <li key={index}>
                    {item.language.label} (
                    {
                      LANGUAGE_LEVEL_TYPES.find(
                        (i) => i.value === item.languageLevel
                      )?.label
                    }
                    )
                  </li>
                ))}
              </UL>
            </FilterLine>
          )}
          {batch?.roles?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Position Roles"
            >
              {batch.roles.map((role, index) => (
                <div key={index}>{role.title}</div>
              ))}
            </FilterLine>
          )}
          {batch.totalExperience && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Total Experience"
            >
              {batch.totalExperience.min} - {batch.totalExperience.max} year(s)
            </FilterLine>
          )}
          {batch.budget && batch.budget.max > 0 && batch.budget.max && (
            <>
              <FilterLine
                currentFilter={currentFilter}
                setCurrentFilter={setCurrentFilter}
                title="Budget"
              >
                <BudgetCriteriaLine budget={batch.budget} />
              </FilterLine>
              <FilterLine
                currentFilter={currentFilter}
                setCurrentFilter={setCurrentFilter}
                title="Exchange Rate"
              >
                {tryExchangeRate
                  ? `${tryExchangeRate.toFixed(2)} USD/TRY`
                  : "?"}
              </FilterLine>
            </>
          )}
          {batch?.skills?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Must have skills"
            >
              <SkillCriteriaLine skills={batch?.skills || []} />
            </FilterLine>
          )}
          {batch?.otherExpected?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Other required skills"
            >
              <SkillCriteriaLine skills={batch?.otherExpected || []} />
            </FilterLine>
          )}
          {batch.age && batch.age.min > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Age"
            >
              {batch.age.min} - {batch.age.max} year(s)
            </FilterLine>
          )}
          {batch?.languages?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Min language skills"
            >
              <UL>
                {batch.languages.map((item, index) => (
                  <li key={index}>
                    {item.language.label} (
                    {
                      LANGUAGE_LEVEL_TYPES.find(
                        (i) => i.value === item.languageLevel
                      )?.label
                    }
                    )
                  </li>
                ))}
              </UL>
            </FilterLine>
          )}
          {batch?.education?.type !== null &&
            batch?.education?.type !== undefined && (
              <FilterLine
                currentFilter={currentFilter}
                setCurrentFilter={setCurrentFilter}
                title="Min education level"
              >
                {
                  EDUCATION_TYPES.find((i) => i.value === batch.education.type)
                    ?.label
                }
              </FilterLine>
            )}
          {batch?.education?.universities?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Universities"
            >
              <UL>
                {batch.education.universities.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))}
              </UL>
            </FilterLine>
          )}
          {batch?.education?.branches?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Branches"
            >
              <UL>
                {batch.education.branches.map((item, index) => (
                  <li key={index}>{item.label}</li>
                ))}
              </UL>
            </FilterLine>
          )}
          {batch?.positionLocation?.remote && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Location options"
            >
              <UL>
                {batch.positionLocation.remote.split(",").map((item, index) => (
                  <li key={index}>
                    {
                      REMOTE_OPTIONS_FOR_COMPANY.find((i) => i.id === item)
                        ?.title
                    }
                  </li>
                ))}
              </UL>
            </FilterLine>
          )}
          {batch?.positionLocation?.expectations?.length > 0 && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Required locations"
            >
              <UL>
                {batch.positionLocation.expectations.map((item, index) => (
                  <li key={index}>
                    {item.capital === "country"
                      ? item.country
                      : `${item.city}, ${item.country}`}
                  </li>
                ))}
              </UL>
            </FilterLine>
          )}
          {batch?.keywords && (
            <FilterLine
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              title="Keywords"
            >
              <UL>
                {batch.keywords.split(",").map((keyword) => {
                  return <li>{keyword}</li>;
                })}
              </UL>
            </FilterLine>
          )}
        </Content>
      </Container>
    </>
  );
}
