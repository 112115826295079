import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: ${({ size }) => size || "25px"};
  height: ${({ size }) => size || "25px"};
  border-radius: 50%;
`;

const EmptyAvatar = styled.div`
  width: ${({ size }) => size || "25px"};
  height: ${({ size }) => size || "25px"};
  border-radius: 50%;
  background-color: #c4c4c4;
`;

export default function Avatar({ url, size }) {
  if (!url) {
    return <EmptyAvatar size={size} />;
  }
  return <Image src={url} size={size} />;
}
