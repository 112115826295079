import React from "react";

export default function TgTotalInfo({ count, title }) {
  if (count >= 0) {
    return (
      <span>
        <b>{count}</b> {title}
      </span>
    );
  }

  return "loading";
}
