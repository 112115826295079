export const getQueries = (values) => {
  let sort;
  if (values.sort) {
    sort = JSON.parse(values.sort);
  }
  const filters = {};
  if (values.country) {
    filters.country = values.country;
  }

  return {
    search: values.name || "",
    page: parseInt(values.page) || 1,
    limit: parseInt(values.limit) || 10,
    sort,
    filters,
  };
};
