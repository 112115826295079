import React from "react";
import { useHistory } from "react-router-dom";
import { TgHeaderTab, TgHeaderTabs } from "../../../tg-styles/TgHeaderTabs";
import { TgHeader } from "../../../tg-styles/Layouts";

export default function EducationHeader({ name }) {
  const history = useHistory();

  return (
    <TgHeader
      breadCrumbs={
        name === "universities"
          ? [
              { title: "Tools", link: "/tools" },
              {
                title: "Universities",
                link: "/tools/education/universities",
              },
            ]
          : [
              { title: "Tools", link: "/tools" },
              {
                title: "Departments",
                link: "/tools/education/branches",
              },
            ]
      }
    >
      <TgHeaderTabs>
        <TgHeaderTab
          name="universities"
          onClick={() => history.push("/tools/education/universities")}
          classname={name === "universities" ? "active" : ""}
        >
          Universities
        </TgHeaderTab>
        <TgHeaderTab
          name="branches"
          onClick={() => history.push("/tools/education/branches")}
          classname={name === "branches" ? "active" : ""}
        >
          Departments
        </TgHeaderTab>
      </TgHeaderTabs>
    </TgHeader>
  );
}
