import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0px;
  margin: 0px 0px 15px 0px;
  border-bottom: 1px solid #e5e5e5;
`;

export default function TgTabs({
  currentTab,
  handleChange,
  tabKey = "tab",
  children,
}) {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  useEffect(() => {
    query.delete("search");
    query.delete("sort");
    query.set(tabKey, currentTab);
    history.push({ search: query.toString() });
    // eslint-disable-next-line
  }, [currentTab]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (child) {
      return React.cloneElement(child, {
        currentTab,
        handleChange,
      });
    }
  });

  return <StyledUl>{childrenWithProps}</StyledUl>;
}
