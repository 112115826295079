import React from "react";
import { TgTable } from "../../tg-styles/Layouts";
import FormScaffold from "../../Form/FormScaffold";
import Textarea from "../../Form/Textarea";

const columns = [
  {
    title: "Fail Reason",
    dataIndex: "label",
  },
];

export default function FailReasons({ data }) {
  const { match } = data;

  return (
    <div style={{ marginTop: "10px" }}>
      <TgTable
        key={(record) => record.id}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={match.failReasons?.reasons}
        pagination={false}
      />

      <FormScaffold
        label="Description"
        options={{ showStatus: false }}
        style={{ marginTop: "30px", paddingLeft: "0px" }}
        formBodyStyles={{ paddingLeft: "0px" }}
      >
        <Textarea
          name="notes"
          defaultValue={match.failReasons?.description}
          disabled
          rows={10}
        />
      </FormScaffold>
    </div>
  );
}
