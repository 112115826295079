import React, { useState, useCallback, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Form, Input, message } from "antd";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "tg-design";
import AlternativeNames from "./AlternativeNames";
import { useResetFormOnCloseModal } from "../../../company/modules/useResetForm";
import { captureErrorWithData, debounce } from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";

const CHECK_IF_UNIVERSITY_BRANCH_EXISTS = gql`
  query checkIfUniversityBranchExistsByLabel($label: String!) {
    checkIfUniversityBranchExistsByLabel(label: $label) {
      label
    }
  }
`;

const ModalBranches = ({ visible, onCancel, createUniversityBranch }) => {
  const [form] = Form.useForm();

  const [state, setState] = useState({ label: "" });
  const [isAlreadyExists, setIsAlreadyExists] = useState(false);

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const [
    checkIfUniversityBranchExistsByLabel,
    { error: universityBranchCheckError },
  ] = useLazyQuery(CHECK_IF_UNIVERSITY_BRANCH_EXISTS, {
    onCompleted: (data) => {
      if (data?.checkIfUniversityBranchExistsByLabel?.label) {
        setIsAlreadyExists(true);
      } else {
        setIsAlreadyExists(false);
      }
    },
    fetchPolicy: "network-only",
  });

  const onOk = async () => {
    const currentValue = form.getFieldsValue();
    if (!currentValue.label) {
      message.error("Name can NOT be empty");
    } else {
      const variables = { ...currentValue };
      try {
        await createUniversityBranch(variables);
        message.success("Branch Created");
        setIsAlreadyExists(false);
      } catch (error) {
        captureErrorWithData(error);
        message.error(error.message || error);
        setIsAlreadyExists(false);
      }

      onCancel();
    }
  };

  const onType = useCallback(
    debounce(async (val) => {
      try {
        await checkIfUniversityBranchExistsByLabel({
          variables: { label: val },
        });
      } catch (err) {
        message.error("Something went wrong");
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (state.label) {
      onType(state.label);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getValidateStatus = () => {
    const currentValue = form.getFieldsValue();

    if (!currentValue.label) {
      return { status: "error", message: `'name' can not be empty` };
    }
    if (isAlreadyExists) {
      return {
        status: "error",
        message: `A university branch with the name '${state.label}' already
      exists.`,
      };
    }
  };

  const handleClose = () => {
    setIsAlreadyExists(false);
    onCancel();
  };

  return (
    <>
      {visible && (
        <Modal handleClose={handleClose}>
          <ModalHeader>Create New Department</ModalHeader>
          <ModalBody>
            {!universityBranchCheckError ? (
              <Form form={form} layout="vertical" name="branchForm">
                <Form.Item
                  name="label"
                  label="Branch Name"
                  onChange={(e) =>
                    setState({ ...state, label: e.target.value })
                  }
                  validateStatus={getValidateStatus()?.status}
                  help={getValidateStatus()?.message}
                >
                  <Input placeholder="Branch Name" />
                </Form.Item>
                <AlternativeNames />
              </Form>
            ) : (
              <PartialError />
            )}
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onOk} disabled={isAlreadyExists}>
              Save
            </Button>
            <Button onClick={handleClose} outline size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ModalBranches;
