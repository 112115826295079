import React, { useState } from "react";
import styled from "styled-components";
import { space } from "styled-system";

const ReadMoreLink = styled.a`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #64b7dc;
  margin-left: 5px;
  cursor: pointer;
  ${space}
`;

export default function ReadMore({ text, limit }) {
  const hasOverflow = text && text.length > limit;
  let preText = text;
  if (hasOverflow) {
    const little = text.substr(0, limit);
    preText = `${little.substr(0, little.lastIndexOf(" "))}...`;
  }
  const [showAll, setShowAll] = useState(preText.length === text.length);

  const handleSeeMore = (e) => {
    e.stopPropagation();
    setShowAll(true);
  };

  const handleSeeLess = (e) => {
    e.stopPropagation();
    setShowAll(false);
  };

  return (
    <span>
      {!showAll && (
        <span>
          {preText}
          {hasOverflow && (
            <ReadMoreLink onClick={(e) => handleSeeMore(e)}>
              See More
            </ReadMoreLink>
          )}
        </span>
      )}
      {showAll && (
        <span>
          {text}
          {hasOverflow && (
            <ReadMoreLink onClick={(e) => handleSeeLess(e)}>
              See Less
            </ReadMoreLink>
          )}
        </span>
      )}
    </span>
  );
}
