import { MATCH_STATES } from "../../../constants";

const matchStates = {
  created: [MATCH_STATES.CREATED],
  sent: [MATCH_STATES.SENT],
  interested: [MATCH_STATES.INTERESTED],
  tg_assessment: [MATCH_STATES.TG_ASSESSMENT],
  passed: [MATCH_STATES.PASSED],
  failed: [MATCH_STATES.FAILED],
  canceled: [MATCH_STATES.CANCELED],
  directed: [
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.HIRED,
  ],
};

export const getQueries = (values) => {
  let sort;
  if (values.sort) {
    sort = JSON.parse(values.sort);
  }
  const filters = {};
  if (values.company) {
    filters.company = values.company;
  }

  if (values.assignee) {
    filters.assignee = values.assignee;
  }

  if (values.state) {
    filters.state = values.state;
  }
  if (values.matchState) {
    const states = matchStates[values.matchState];
    filters.state = { in: states || [MATCH_STATES.CREATED] };
  }
  if (values.isExternal) {
    filters.isExternal = values.isExternal === "true";
  }
  return {
    search: values.title || "",
    page: parseInt(values.page) || 1,
    limit: parseInt(values.limit) || 10,
    sort,
    filters,
  };
};
