import { gql } from "@apollo/client";

export const GET_ALL_ROLES = gql`
  {
    allRole(limit: 0) {
      roles {
        id
        title
        relatedProfession
        isFeatured
        sort
        skills {
          id
          title
        }
      }
      currentPage
    }
  }
`;

export const GET_PROFESSIONS = gql`
  {
    allProfession {
      id
      title
    }
  }
`;

export const GET_ALL_SKILLS = gql`
  {
    allTechnology(limit: 0) {
      technologies {
        id
        title
      }
    }
  }
`;

export const GET_ROLE = gql`
  query Role($id: String) {
    role(id: $id) {
      id
      title
      relatedProfession
      isFeatured
      sort
      skills {
        id
        title
      }
    }
  }
`;

export const CREATE_ROLES = gql`
  mutation CreateRole($title: String, $sort: Int, $skills: [ID]) {
    createRole(title: $title, sort: $sort, skills: $skills) {
      id
      skills {
        title
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole(
    $id: ID!
    $title: String
    $relatedProfession: Int
    $skills: [ID]
    $isFeatured: Boolean
    $sort: Int
  ) {
    updateRole(
      id: $id
      title: $title
      relatedProfession: $relatedProfession
      skills: $skills
      isFeatured: $isFeatured
      sort: $sort
    ) {
      id
    }
  }
`;

export const GET_ALL_TECH = gql`
  {
    allTechnology(limit: 0) {
      technologies {
        id
        title
      }
    }
  }
`;

export const GET_ALL_VERIFIED_TECH = gql`
  query allVerifiedTechnology(
    $limit: Int
    $page: Int
    $search: String
    $verified: Boolean
    $sort: technologySortInputType
  ) {
    allTechnology(
      limit: $limit
      page: $page
      search: $search
      verified: $verified
      sort: $sort
    ) {
      technologies {
        id
        title
        category
        categoryId
        alternativeTexts
        updatedAt
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

export const GET_TECHNOLOGY = gql`
  query GetTechnology($id: ID!) {
    technology(id: $id) {
      id
      title
      category
      categoryId
      alternativeTexts
      verified
      updatedAt
      similers {
        id
        title
      }
    }
  }
`;

export const GET_ALL_BACKLOG_TECH = gql`
  query allBacklogTechnology(
    $limit: Int
    $page: Int
    $search: String
    $verified: Boolean
    $sort: technologySortInputType
  ) {
    allTechnology(
      limit: $limit
      page: $page
      search: $search
      verified: $verified
      sort: $sort
    ) {
      technologies {
        id
        title
        category
        categoryId
        alternativeTexts
        updatedAt
        creatorToken {
          email
        }
        creator {
          email
        }
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

export const CHECK_IF_ROLE_EXISTS = gql`
  query checkIfRoleExistsByTitle($title: String!) {
    checkIfRoleExistsByTitle(title: $title) {
      title
    }
  }
`;

export const DELETE_TECHNOLOGY = gql`
  mutation deleteTechnology($id: ID!) {
    deleteTechnology(id: $id)
  }
`;

export const MAP_TECHNOLOGY = gql`
  mutation mapTechnologyToAnotherTechnology($id: ID!, $toId: ID!) {
    mapTechnologyToAnotherTechnology(id: $id, toId: $toId)
  }
`;

export const UPDATE_TECHNOLOGY = gql`
  mutation updateTechnology(
    $id: ID!
    $verified: Boolean
    $title: String
    $categoryId: Int
    $alternativeTexts: [String]
    $creator: ID
    $creatorToken: ID
  ) {
    updateTechnology(
      id: $id
      verified: $verified
      title: $title
      categoryId: $categoryId
      alternativeTexts: $alternativeTexts
      creatorToken: $creatorToken
      creator: $creator
    ) {
      title
      verified
      alternativeTexts
    }
  }
`;

export const CREATE_TECHNOLOGY = gql`
  mutation addTechnology(
    $title: String!
    $categoryId: Int
    $alternativeTexts: [String]
  ) {
    createTechnologyByAdmin(
      title: $title
      categoryId: $categoryId
      alternativeTexts: $alternativeTexts
    ) {
      title
      verified
      alternativeTexts
      categoryId
    }
  }
`;

export const CREATE_BULK_TECHNOLOGY = gql`
  mutation addBulkTechnology(
    $titles: [String]!
    $categoryId: Int
    $creator: String!
  ) {
    createBulkTechnology(
      titles: $titles
      categoryId: $categoryId
      creator: $creator
    )
  }
`;

export const GET_ALL_UNIVERSITY = gql`
  query AllUniversities(
    $limit: Int
    $page: Int
    $search: String
    $filters: universityFilterInputType
  ) {
    allUniversity(
      limit: $limit
      page: $page
      search: $search
      filters: $filters
    ) {
      universities {
        id
        name
        country
        alternativeTexts
        web_pages
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

export const GET_UNIVERSITY = gql`
  query GetUniversity($id: ID) {
    university(id: $id) {
      id
      name
      country
      alternativeTexts
      web_pages
    }
  }
`;

export const CREATE_UNIVERSITY = gql`
  mutation createUniversity(
    $name: String!
    $country: String!
    $alternativeTexts: [String]
  ) {
    createUniversity(
      name: $name
      country: $country
      alternativeTexts: $alternativeTexts
    ) {
      id
      name
      alternativeTexts
      country
    }
  }
`;

export const UPDATE_UNIVERSITY = gql`
  mutation UpdateUniversity(
    $id: ID!
    $name: String!
    $country: String!
    $alternativeTexts: [String]
  ) {
    updateUniversity(
      id: $id
      name: $name
      country: $country
      alternativeTexts: $alternativeTexts
    ) {
      id
      name
      alternativeTexts
      country
    }
  }
`;

export const GET_ALL_BRANCHES = gql`
  query AllBranches($limit: Int, $page: Int, $search: String) {
    allUniversityBranch(limit: $limit, page: $page, search: $search) {
      universityBranches {
        id
        label
        alternativeTexts
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

export const GET_BRANCH = gql`
  query GetUniversityBranch($id: ID) {
    universityBranch(id: $id) {
      id
      label
      alternativeTexts
    }
  }
`;

export const CREATE_UNIVERSITY_BRANCH = gql`
  mutation createUniversityBranch(
    $label: String!
    $alternativeTexts: [String]
  ) {
    createUniversityBranch(label: $label, alternativeTexts: $alternativeTexts) {
      id
      label
      alternativeTexts
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation UpdateUniversityBranch(
    $id: ID!
    $label: String!
    $alternativeTexts: [String]
  ) {
    updateUniversityBranch(
      id: $id
      label: $label
      alternativeTexts: $alternativeTexts
    ) {
      id
      label
      alternativeTexts
    }
  }
`;

export const GET_ALL_LANGUAGES = gql`
  query allLanguages($limit: Int, $page: Int, $search: String) {
    allLanguages(limit: $limit, page: $page, search: $search) {
      languages {
        id
        label
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

export const GET_LANGUAGE = gql`
  query GetLanguage($id: ID) {
    language(id: $id) {
      id
      label
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation UpdateLanguage($id: ID!, $label: String) {
    updateLanguage(id: $id, label: $label) {
      id
      label
    }
  }
`;

export const GET_USERS_WITH_DELETEREASON = gql`
  {
    getAllUsersHasDeleteReason {
      id
      name
      deleteReason {
        createdAt
        reason {
          label
        }
        active
      }
    }
  }
`;

export const GET_USER_WITH_DELETEREASON = gql`
  query GetUserWithDeleteReason($id: String) {
    user(id: $id) {
      id
      name
      avatarURL
      email
      deleteReason {
        _id
        createdAt
        reason {
          label
        }
        active
      }
    }
  }
`;

export const GET_PASSIVE_USERS = gql`
  {
    getAllPassiveUsers {
      name
      updatedAt
      deleteReason {
        reason {
          label
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID) {
    deleteUser(id: $id) {
      name
      status
    }
  }
`;

export const UPDATE_DELETE_REQUEST = gql`
  mutation updateDeleteRequest($userId: ID!, $deleteRequest: ID!) {
    updateDeleteRequest(userId: $userId, deleteRequest: $deleteRequest) {
      deleteReason {
        active
      }
    }
  }
`;

export const GET_ALL_DOCUMENT = gql`
  query GetAllDocument(
    $page: Int
    $type: String
    $search: String
    $limit: Int
  ) {
    allDocuments(page: $page, type: $type, search: $search, limit: $limit) {
      documents {
        id
        type
        title
        description
        content
        files {
          id
          fileURL
          fileLanguage
        }
        visible
        publishedAt
        coverImageURL
        owner {
          name
          avatarURL
        }
      }
      totalPages
      totalCount
    }
  }
`;

export const GET_DOCUMENT = gql`
  query GetAllDocument($id: ID!) {
    type
    title
    description
    content
    files {
      id
      fileURL
      fileLanguage
    }
    visible
    publishedAt
    coverImageURL
    owner {
      name
      avatarURL
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument(
    $type: String
    $title: String!
    $description: String!
    $content: String
    $coverImageURL: String!
    $files: [DocumentFileInputType]!
    $visible: Boolean
    $publishedAt: Date!
  ) {
    addDocument(
      type: $type
      title: $title
      description: $description
      content: $content
      coverImageURL: $coverImageURL
      files: $files
      visible: $visible
      publishedAt: $publishedAt
    ) {
      id
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument(
    $id: ID!
    $type: String
    $title: String!
    $description: String!
    $content: String
    $coverImageURL: String!
    $files: [DocumentFileInputType]!
    $visible: Boolean
    $publishedAt: Date!
  ) {
    updateDocument(
      id: $id
      type: $type
      title: $title
      description: $description
      content: $content
      coverImageURL: $coverImageURL
      files: $files
      visible: $visible
      publishedAt: $publishedAt
    ) {
      id
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadDocument(file: $file) {
      fileURL
    }
  }
`;

export const DELETE_COVER_IMAGE = gql`
  mutation DeleteCoverImage($id: ID!) {
    deleteCoverImage(id: $id)
  }
`;
