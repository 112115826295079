import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 20px;
`;

const DataLine = styled.div`
  display: flex;
  margin-bottom: 3px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #1a1a1a;
`;

const Title = styled.div``;

const Value = styled.div`
  margin-left: auto;
  font-weight: 600;
  padding-right: 10px;
`;

const Percent = styled.div`
  font-weight: 600;
  color: #9ca3af;
  min-width: 40px;
  text-align: right;
`;

const ProgressLine = styled.div`
  height: 7px;
  width: 100%;
  background: #ebebeb;
`;

// width: 30%;

const Success = styled.div`
  width: ${(props) => props.width || "0px"};
  height: 7px;

  &.success {
    background: #8fdeac;
  }

  &.error {
    background: #de948f;
  }
`;

export default function ProgressBar({
  value,
  percent,
  variant = "success",
  children,
}) {
  return (
    <Container>
      <DataLine>
        <Title>{children}</Title>
        <Value>{value}</Value>
        <Percent>{percent}%</Percent>
      </DataLine>
      <ProgressLine>
        <Success width={`${percent}%`} className={variant}>
          &nbsp;
        </Success>
      </ProgressLine>
    </Container>
  );
}
