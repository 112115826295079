import React from "react";
import styled from "styled-components";

const Figure = styled.figure`
  display: flex;
  align-items: center;
`;

const ListItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.family.regular};
  font-size: 12px;
`;

const ListItemTitleDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 90px;
  background-color: ${(props) => props.color || "aqua"};
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

const colors = ["#0c084c", "#8fdeac", "#fad156", "#72D1FB", "#c2c1d2"];

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return `${text.substring(0, limit)}..`;
  }
  return text;
};

export default function TgDonut({ data }) {
  const totalCount = data
    .map((item) => item.value)
    .reduce((prev, current) => {
      return prev + current;
    }, 0);

  const unit = 100 / totalCount;

  let tempOffset = null;
  const newData = [...data];
  const newList = newData
    .sort((a, b) => b.value - a.value)
    .map((item, index) => {
      const prevItem = data[index - 1];
      let offset = 0;
      if (tempOffset) {
        offset = tempOffset - prevItem.value * unit * 0.999;
      } else {
        offset = 25;
      }
      tempOffset = offset;
      return {
        label: truncateText(item.label, 12),
        value: item.value,
        offset,
        color: colors[index],
      };
    });

  return (
    <>
      <Figure>
        <div>
          <svg
            width="auto"
            height="194px"
            viewBox="0 0 42 42"
            aria-labelledby="list-label"
            role="img"
          >
            <circle
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="#fff"
              role="presentation"
            />
            <circle
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke="#d2d3d4"
              strokeWidth="5"
              role="presentation"
            />

            {newList.map((row, index) => (
              <circle
                cx="21"
                key={index}
                cy="21"
                r="15.91549430918954"
                fill="transparent"
                stroke={row.color}
                strokeWidth="5"
                strokeDasharray={`${row.value * unit}, ${
                  100 - row.value * unit
                }`}
                strokeDashoffset={row.offset}
                aria-labelledby="donut-segment-1-title"
              >
                <title id="donut-segment-1-title">{row.label}</title>
              </circle>
            ))}
          </svg>
        </div>
        <figcaption>
          <ul aria-hidden="true" role="presentation">
            {newList.map((item, index) => (
              <ListItemTitle key={index}>
                <ListItemTitleDot color={item.color} />
                {item.label}
              </ListItemTitle>
            ))}
          </ul>
        </figcaption>
      </Figure>
    </>
  );
}
