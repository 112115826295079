import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { TgHeader } from "../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../tg-styles/TgHeaderTabs";
import { TeamMemberProfile } from "./tabs/TeamMemberEditPage";
import UserSettings from "./tabs/UserSettings";
import { GET_RECRUITER } from "../../queries/general";
import { POSITION_STATES, RECRUITER_ROLES } from "../../constants";
import PartialError from "../ErrorPage/PartialError";

export default function TeamMemberProfileAndSettings() {
  const { recruiterId } = useParams();
  const {
    loading: loggedInRecruiterLoading,
    error: loggedInRecruiterError,
    data: loggedInRecruiterData,
  } = useQuery(GET_RECRUITER, { fetchPolicy: "no-cache" });

  const { loading, error, refetch, data } = useQuery(GET_RECRUITER, {
    variables: {
      id: recruiterId,
      recruiterPositionFilters: {
        states: [POSITION_STATES.IN_PROGRESS, POSITION_STATES.ON_HOLD],
      },
    },
    fetchPolicy: "no-cache",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "teamMemberProfile"
  );

  const getTab = () => {
    switch (currentTab) {
      case "teamMemberProfile":
        return (
          <TeamMemberProfile
            recruiter={data.recruiter}
            refetch={refetch}
            loggedInRecruiter={loggedInRecruiterData.recruiter}
          />
        );
      case "userSettings":
        return (
          <UserSettings
            refecth={refetch}
            recruiter={data.recruiter}
            loggedInRecruiter={loggedInRecruiterData.recruiter}
          />
        );
      default:
        setCurrentTab("teamMemberProfile");
        break;
    }
  };

  if (loading || loggedInRecruiterLoading) return "Loading...";
  if (error || loggedInRecruiterError) return <PartialError />;

  return (
    <div>
      <TgHeader
        breadCrumbs={[
          { title: "Team", link: "/team" },
          { title: data.recruiter.name, link: `/team/${recruiterId}` },
        ]}
      >
        <TgHeaderTabs currentTab={currentTab} handleChange={setCurrentTab}>
          <TgHeaderTab name="teamMemberProfile">Profile</TgHeaderTab>
          {loggedInRecruiterData.recruiter.role === RECRUITER_ROLES.ADMIN && (
            <TgHeaderTab name="userSettings">Settings</TgHeaderTab>
          )}
        </TgHeaderTabs>
      </TgHeader>
      <div style={{ paddingLeft: "10px" }}>{getTab()}</div>
    </div>
  );
}
