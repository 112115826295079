import React, { useState } from "react";
import styled from "styled-components";
import { Tag, Tooltip, Typography } from "antd";
import { ButtonLink, Modal, ModalHeader, ModalBody } from "tg-design";

import ComparisonTable from "../ComparisonTable";
import {
  CURRENCY_TYPES,
  BUDGET_PERIOD_TYPES,
  INCOME_TYPES,
  REMOTE_OPTIONS_FOR_COMPANY,
  REMOTE_OPTIONS_FOR_DEVELOPER,
  MATCH_CREATION_TYPES,
  proExperiences,
} from "../../../constants/index";
import {
  getLanguageLevelText,
  getEducationTypeText,
  isDeveloperSalaryChanged,
  toPrettyPrice,
} from "../../../helper";
import {
  SkillCriteriaLine,
  BudgetCriteriaLine,
  BudgetExpectationLine,
} from "../../position/detail/tabs/BatchesSections/CriteriaLines";
import SummarySection from "./SummarySection";

const { Text } = Typography;

const DEFAULT_EXCHANGE_RATE = {
  base: "USD",
  EUR: {
    code: "EUR",
    value: 0.91776,
  },
  GBP: {
    code: "GBP",
    value: 0.76222,
  },
  TRY: {
    code: "TRY",
    value: 14.59583,
  },
};

const tooltipContent = {
  criteriaPosition:
    "Showing only the technologies that match with user's expectations",
  skillDeveloper:
    "Showing only the technologies that match with positions required skills",
  developerSalaryChanged:
    "The salary expectation has been updated by the user.",
  positionSalaryChanged: "The position budget has been updated.",
};

const CalculatedBudget = styled.div`
  color: #8d8d8d;
  font-size: 12px;
  font-style: "italic";
`;

const CriteriaTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CriteriaBadge = styled.span`
  color: #444444;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px 3px 8px;
  border-radius: 8px;
`;

const SimpleMatchBadge = styled(CriteriaBadge)`
  background-color: #f5fafd;
  border: 1px solid #a2daf1;
`;

const ForceMatchBadge = styled(CriteriaBadge)`
  background-color: #fef9ea;
  border: 1px solid #fad156;
`;

const CriteriaTitle = ({ match }) => {
  if (match?.batch) {
    return (
      <CriteriaTitleContainer>
        Batch Criteria{" "}
        <SimpleMatchBadge>{match?.batch?.title}</SimpleMatchBadge>
      </CriteriaTitleContainer>
    );
  }

  const title = MATCH_CREATION_TYPES[match.creationType].value;

  return (
    <CriteriaTitleContainer>
      Batch Criteria <ForceMatchBadge>{title}</ForceMatchBadge>
    </CriteriaTitleContainer>
  );
};

const getDeveloperMaxEducationType = (developerCriteria) => {
  if (
    !developerCriteria.education ||
    !Array.isArray(developerCriteria.education)
  ) {
    return null;
  }

  const [maxType] = developerCriteria.education
    .filter((i) => i)
    .map((i) => i.type)
    .sort((a, b) => b - a);

  return getEducationTypeText(maxType);
};

const getSchoolList = (universities) => {
  if (!universities || universities.length === 0) {
    return "-";
  }
  return universities
    .filter((university) => university)
    .map((university) => <Tag key={university?.id}>{university?.name}</Tag>);
};

const getBranchList = (branches) => {
  if (!branches || branches.length === 0) {
    return "-";
  }
  return branches
    .filter((branch) => branch)
    .map((branch) => <Tag key={branch?.id}>{branch?.label}</Tag>);
};

const getDeveloperSchoolList = (education) => {
  if (!education || !Array.isArray(education)) {
    return [];
  }
  const schools = education
    .filter((i) => i.school)
    .map((i) => {
      return {
        id: i.school.id,
        name: `${i.school.name} (${i.endDate})`,
      };
    });
  return getSchoolList(schools);
};

const getDeveloperBranchList = (education) => {
  if (!education || !Array.isArray(education)) {
    return [];
  }
  const branches = education
    .filter((i) => i.branch)
    .map((i) => {
      return {
        id: i.branch.id,
        label: `${i.branch.label} (${i.endDate})`,
      };
    });
  return getBranchList(branches);
};

const getLanguageTag = (languages) => {
  if (languages.every((lang) => !lang?.language?.label)) {
    return "-";
  }

  return languages.map((language) => {
    return (
      <Tag key={language?.language?.id}>
        {language?.language?.label} (
        {getLanguageLevelText(language?.languageLevel)})
      </Tag>
    );
  });
};

const getDeveloperLanguage = (languages) => {
  if (!languages || languages.length === 0 || !Array.isArray(languages)) {
    return "-";
  }

  return languages.map((item) => (
    <Tag key={item.id}>
      {item.language?.label} ({getLanguageLevelText(item.level)})
    </Tag>
  ));
};

const infoTooltip = (content) => (
  <Tooltip title={content} color="pink">
    <Text strong type="danger">
      (*)
    </Text>
  </Tooltip>
);

const toPlaceTitle = (place) => {
  return place.capital === "country"
    ? place.city
    : `${place.city}, ${place.country}`;
};

const toPositionRemoteTitles = (remote) => {
  return remote
    .split(",")
    .map((key) => (
      <Tag key={key}>
        {REMOTE_OPTIONS_FOR_COMPANY.find((i) => i.id === key)?.title || key}
      </Tag>
    ));
};

const toDeveloperRemoteTitles = (remote) => {
  return remote
    .split(",")
    .map((key) => (
      <Tag key={key}>
        {REMOTE_OPTIONS_FOR_DEVELOPER.find((i) => i.id === key)?.title || key}
      </Tag>
    ));
};

const toBatchCommonBudget = (budget, rate) => {
  let value = 1;
  const data = { ...budget };

  if (data.period === BUDGET_PERIOD_TYPES.YEARLY) {
    data.min = parseInt(data.min / 12, 10);
    data.max = parseInt(data.max / 12, 10);
  }

  // Eğer position net income tanımlanmışsa bunu brüt hesaba çeviriyoruz
  if (data.income === INCOME_TYPES.NET) {
    data.min = parseInt(data.min / 0.65, 10);
    data.max = parseInt(data.max / 0.65, 10);
  }

  if (data.currency !== CURRENCY_TYPES.USD) {
    ({ value } = rate[data.currency]);
  }

  const min = parseInt(data.min / value);
  const max = parseInt(data.max / value);

  return `(${toPrettyPrice(min, CURRENCY_TYPES.USD)} - ${toPrettyPrice(
    max,
    CURRENCY_TYPES.USD
  )} Gross / Monthly)`;
};

const toDeveloperCommonBudget = (salary, rate) => {
  let value = 1;
  const data = { ...salary };

  if (!salary || salary.open === false) {
    return "";
  }

  // Eğer developer yıllık tanımlanmışsa aylık olanını baz alıyoruz
  if (data.period === BUDGET_PERIOD_TYPES.YEARLY) {
    data.expected = parseInt(data.expected / 12, 10);
  }

  // Eğer developer net income tanımlanmışsa bunu brüt hesaba çeviriyoruz
  if (data.income === INCOME_TYPES.NET) {
    data.expected = parseInt(data.expected / 0.65, 10);
  }

  if (salary.currency !== CURRENCY_TYPES.USD) {
    ({ value } = rate[data.currency]);
  }

  const expected = parseInt(data.expected / value);

  return `(${toPrettyPrice(expected, CURRENCY_TYPES.USD)} Gross / Monthly)`;
};

const toSelectedBatchData = ({
  creationType,
  batch,
  position,
  exchangeRate,
}) => {
  if (creationType === MATCH_CREATION_TYPES.BATCH.key && batch) {
    return batch;
  }

  return {
    roles: [],
    otherExpected: (position?.criteria?.technologies?.otherExpected || []).map(
      (row) => {
        return row.map((item) => {
          return { _id: item._id, title: item.title };
        });
      }
    ),
    totalExperience: position?.criteria?.totalExperience,
    age: null,
    positionLocation: {
      remote: position?.criteria?.positionLocation?.remote,
      expectations: position?.criteria?.positionLocation?.expected
        ? [position?.criteria?.positionLocation?.expected?.place]
        : [],
    },
    education: {
      type: null,
      universities: [],
      branches: [],
    },
    budget: position?.criteria?.budget,
    languages: position?.criteria?.languages,
    skills: (position?.criteria?.technologies?.expected || []).map((row) => {
      return row.map((item) => {
        return {
          experience: item.experience,
          skill: { _id: item._id, title: item.title },
        };
      });
    }),
    exchangeRate,
  };
};

const mappedPositionTechData = (techs) => {
  const skills = (techs || []).map((row) => {
    return row.map((item) => {
      return {
        experience: item.experience,
        skill: { _id: item._id, title: item.title },
      };
    });
  });
  return skills;
};

const adaptedData = ({
  creationType,
  position,
  user: developer,
  batch,
  developerCriteria = {},
  exchangeRate = DEFAULT_EXCHANGE_RATE,
}) => {
  const developerTechExpected = developer.criteria.technologies.expected;
  const companyTechStack = position.company.technologies;
  const positionOtherExpected = position.criteria.technologies.otherExpected;

  const matchOtherTech = positionOtherExpected.reduce((acc, val) => {
    for (const item of developer.otherSkills) {
      if (!item) {
        return [];
      }

      if (item.id === val[0].id) {
        acc.push(item.title);
      }
    }
    return acc;
  }, []);

  const matchTech = developerTechExpected.reduce((acc, val) => {
    for (const item of companyTechStack) {
      if (!item) {
        return [];
      }
      if (item.id === val.id) {
        acc.push(item.title);
      }
    }
    return acc;
  }, []);

  const getPositionAgeCriteria = ({ min = 0, max = 0 }) => {
    if (!min && !max) {
      return "All";
    }
    return `${min} - ${max}`;
  };

  const getDeveloperAge = (birthyear) => {
    if (!birthyear) {
      return "-";
    }
    return new Date().getFullYear() - birthyear;
  };

  const getPositionLocation = (positionLocation) => {
    if (!positionLocation?.remote) {
      return "-";
    }
    return <div>{toPositionRemoteTitles(positionLocation?.remote)}</div>;
  };

  const getBatchPlaces = (expectations = []) => {
    if (expectations?.length > 0) {
      return (
        <>
          {expectations.map((place, index) => (
            <Tag key={index}>{toPlaceTitle(place)}</Tag>
          ))}
        </>
      );
    }
    return "-";
  };

  const getDeveloperLocations = () => {
    if (!developerCriteria?.location || !developerCriteria?.location?.open) {
      return "-";
    }

    return (
      <div>
        {toDeveloperRemoteTitles(developerCriteria?.location?.remote || "")}
      </div>
    );
  };

  const getDeveloperPlaces = () => {
    if (!developerCriteria?.location || !developerCriteria?.location?.open) {
      return "-";
    }

    return (
      <>
        {developerCriteria?.location?.expected.map((place, index) => (
          <Tag key={index}>{toPlaceTitle(place)}</Tag>
        ))}
      </>
    );
  };

  const selectedData = toSelectedBatchData({
    creationType,
    batch,
    position,
    exchangeRate,
  });

  // Eski verilerde bozulma olmaması için kullanmak zorundayız.
  if (developerCriteria === null) {
    developerCriteria = {
      ...developer.criteria,
      skills: developer.skills,
      budget: developer.criteria.salary,
    };
  }

  // Eski verilerde bozulma olmaması için kullanmak zorundayız.
  if (!selectedData.exchangeRate) {
    selectedData.exchangeRate = DEFAULT_EXCHANGE_RATE;
  }

  return {
    batch: [
      {
        subTitle: "Role",
        position:
          selectedData?.roles?.length > 0
            ? (selectedData?.roles || []).map((role, index) => (
                <Tag key={index}>{role.title}</Tag>
              ))
            : "-",
        developer: developer.roles.map((i) => (
          <Tag>
            {i.role?.title} (
            {proExperiences.find((s) => s.value === i.experience)?.name || "?"}
          </Tag>
        )),
        base: "-",
      },
      {
        subTitle: "Total Experience",
        position: selectedData?.totalExperience?.min
          ? `${selectedData?.totalExperience?.min} - ${selectedData?.totalExperience?.max}`
          : "-",
        developer: developerCriteria.experience,
        base: position?.criteria?.totalExperience?.min
          ? `${position?.criteria?.totalExperience?.min} - ${position?.criteria?.totalExperience?.max}`
          : "-",
      },
      {
        subTitle: "Must Have Skills",
        position:
          selectedData?.skills?.length > 0 ? (
            <SkillCriteriaLine skills={selectedData?.skills || []} />
          ) : (
            "-"
          ),
        developer: developerCriteria.skills.map((item, i) => (
          <Tag key={i}>
            {item?.skill?.title || item?.title} - {item.experience} Year(s)
          </Tag>
        )),
        base:
          position?.criteria?.technologies?.expected?.length > 0 ? (
            <SkillCriteriaLine
              skills={
                mappedPositionTechData(
                  position?.criteria?.technologies?.expected
                ) || []
              }
            />
          ) : (
            "-"
          ),
      },
      {
        subTitle: "Other Required Skills",
        position:
          selectedData?.otherExpected?.length > 0 ? (
            <SkillCriteriaLine skills={selectedData?.otherExpected || []} />
          ) : (
            "-"
          ),
        developer: (
          <span>
            {matchOtherTech.join(", ")}{" "}
            {infoTooltip(tooltipContent.skillDeveloper)}
          </span>
        ),
        base:
          position?.criteria?.technologies?.otherExpected?.length > 0 ? (
            <SkillCriteriaLine
              skills={
                mappedPositionTechData(
                  position?.criteria?.technologies?.otherExpected
                ) || []
              }
            />
          ) : (
            "-"
          ),
      },
      {
        subTitle: "Age",
        position: getPositionAgeCriteria(selectedData?.age || {}),
        developer: getDeveloperAge(developerCriteria.birthyear),
        base: "-",
      },
      {
        subTitle: "Salary",
        position: (
          <div>
            <BudgetCriteriaLine budget={selectedData.budget} />
            <CalculatedBudget>
              {toBatchCommonBudget(
                selectedData.budget,
                selectedData.exchangeRate
              )}
            </CalculatedBudget>
          </div>
        ),
        developer: (
          <div>
            {developerCriteria?.salary?.open ? (
              <>
                <BudgetExpectationLine salary={developerCriteria?.salary} />
                {isDeveloperSalaryChanged(
                  developerCriteria.salary,
                  developer
                ) && infoTooltip(tooltipContent.developerSalaryChanged)}
                <CalculatedBudget>
                  {toDeveloperCommonBudget(
                    developerCriteria?.salary,
                    selectedData.exchangeRate
                  )}
                </CalculatedBudget>
              </>
            ) : (
              "-"
            )}
          </div>
        ),
        base: (
          <div>
            <BudgetCriteriaLine budget={position?.criteria?.budget} />
            <CalculatedBudget>
              {toBatchCommonBudget(
                position?.criteria?.budget,
                selectedData.exchangeRate
              )}
            </CalculatedBudget>
          </div>
        ),
      },
      {
        subTitle: "Education (min)",
        position: getEducationTypeText(selectedData?.education?.type),
        developer: getDeveloperMaxEducationType(developerCriteria),
        base: "-",
      },
      {
        subTitle: "University",
        position: getSchoolList(selectedData?.education?.universities || []),
        developer: getDeveloperSchoolList(developerCriteria?.education || []),
        base: "-",
      },
      {
        subTitle: "Department",
        position: getBranchList(selectedData?.education?.branches || []),
        developer: getDeveloperBranchList(developerCriteria?.education || []),
        base: "-",
      },
      {
        subTitle: "Language",
        position: getLanguageTag(selectedData?.languages || []),
        developer: getDeveloperLanguage(developerCriteria?.languages),
        base: getLanguageTag(position?.criteria?.languages || []),
      },
      {
        subTitle: "Position Location",
        position: getPositionLocation(selectedData?.positionLocation),
        developer: getDeveloperLocations(),
        base: getPositionLocation(position?.criteria?.positionLocation),
      },
      {
        subTitle: "Place",
        position: getBatchPlaces(selectedData?.positionLocation?.expectations),
        base: getBatchPlaces(
          position?.criteria?.positionLocation?.expectations
        ),
        developer: getDeveloperPlaces(),
      },
    ],
    general: [
      {
        subTitle: "Nice to Have Skills",
        position:
          position.criteria.technologies.plus
            .map((tech) => tech.title)
            .join(", ") || "-",
        developer: "-",
      },
      {
        subTitle: "Technologies Used",
        position: (
          <span>
            {matchTech.join(", ")}{" "}
            {infoTooltip(tooltipContent.criteriaPosition)}
          </span>
        ),
        developer: developer.criteria.technologies.open
          ? developerTechExpected.map((tech) => tech.title).join(", ")
          : "-",
      },
      {
        subTitle: "Side Benefits",
        position:
          position.criteria.benefits.expected
            .map(({ label }) => label)
            .join(", ") || "-",
        developer: developer.criteria.benefits.open
          ? developer.criteria.benefits.expected
              .map(({ label }) => label)
              .join(", ")
          : "-",
      },
      {
        subTitle: "Company Size",
        position: position.company.teamSize,
        developer: developer.criteria.companySize.open
          ? developer.criteria.companySize.expected
              .map(({ label }) => label)
              .join(", ")
          : "-",
      },
    ],
  };
};

const getPreviousMatchesCount = (match) => {
  const count = match.user.allVerifiedMatches.length;
  if (count === 0) {
    return 0;
  }

  const verifiedMatchIds = match.user.allVerifiedMatches.map(
    (item) => item.position.id
  );

  if (verifiedMatchIds.includes(match.position.id)) {
    return count - 1;
  }

  return count;
};

export default function ComparisonTab({ match, setCurrentDrawer, refetch }) {
  const [state, setState] = useState({ isModalOpen: false });
  const comparisonData = adaptedData(match);
  const allTgAssessmentCount = getPreviousMatchesCount(match);

  return (
    <>
      {allTgAssessmentCount >= 1 && (
        <ButtonLink
          style={{ marginTop: "15px" }}
          onClick={() => setState({ ...state, isModalOpen: true })}
        >
          Other Verified Matches: {allTgAssessmentCount}
        </ButtonLink>
      )}
      {state.isModalOpen && (
        <Modal
          width="1000px"
          handleClose={() => setState({ ...state, isModalOpen: false })}
        >
          <ModalHeader>
            <span style={{ fontSize: "20px" }}>All Verified Matches</span>
          </ModalHeader>
          <ModalBody>
            <ul>
              {match?.user?.allVerifiedMatches
                .filter((m) => m.id !== match.id)
                .map((match) => {
                  return (
                    <li key={match.id}>
                      <ButtonLink
                        style={{ marginTop: "15px" }}
                        rel="noopener noreferrer"
                        href={`/positions/${match.position.id}?drawerId=${match.id}&drawerTab=comparison`}
                        target="_blank"
                        onClick={() =>
                          setState({ ...state, isModalOpen: true })
                        }
                      >
                        {match.position?.company?.name}, {match.position?.title}{" "}
                        | {match.state}
                      </ButtonLink>
                    </li>
                  );
                })}
            </ul>
          </ModalBody>
        </Modal>
      )}
      <ComparisonTable
        title={<CriteriaTitle match={match} />}
        data={comparisonData.batch}
      />
      <ComparisonTable title="General Info" data={comparisonData.general} />
      <SummarySection
        match={match}
        setCurrentDrawer={setCurrentDrawer}
        refetch={refetch}
      />
    </>
  );
}
