import React from "react";
import { selectStyles } from "./SelectStyles";
import { Label } from "../FormUi";
import { ColumnBlock } from "../BlockUi";
import { StyledAsyncSelect } from "./styles";
import IsRequired from "../IsRequired";

export default function AsyncSelectField({
  textAlign,
  valueAlign,
  optionStyle,
  selectedBgColorful,
  isRequired = false,
  ...props
}) {
  const { label, width } = props;
  return (
    <ColumnBlock width={width}>
      {label && (
        <Label mb={1}>
          {label} {isRequired && <IsRequired />}
        </Label>
      )}
      <StyledAsyncSelect
        {...props}
        styles={selectStyles({
          textAlign,
          valueAlign,
          optionStyle,
          selectedBgColorful,
        })}
      />
    </ColumnBlock>
  );
}
