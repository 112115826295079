import React, { useState } from "react";
import { TgHeader } from "../../../tg-styles/Layouts";

import { TgHeaderTabs, TgHeaderTab } from "../../../tg-styles/TgHeaderTabs";

import CommunityTab from "./CommunityTab";
import NewUserTab from "./NewUserTab";

export default function ForcMatchForm({ position, refetch }) {
  const [state, setState] = useState({ tab: "newUser" });

  const getTab = () => {
    switch (state.tab) {
      case "newUser":
        return <NewUserTab position={position} refetch={refetch} />;
      case "community":
        return <CommunityTab position={position} refetch={refetch} />;
      default:
        setState({ ...state, tab: "newUser" });
        break;
    }
  };

  const setTab = (tab) => {
    setState({ ...state, tab });
  };

  return (
    <div>
      <TgHeader showBreadCrumbs={false} title="Add Force Match">
        <TgHeaderTabs currentTab={state.tab || "newUser"} handleChange={setTab}>
          <TgHeaderTab name="newUser">New User</TgHeaderTab>
          <TgHeaderTab name="community">In Community</TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>
      {getTab()}
    </div>
  );
}
