import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Button, Progress, Tabs, Col } from "antd";
import {
  LeftOutlined,
  InfoCircleTwoTone,
  BankTwoTone,
} from "@ant-design/icons";
import MatchHeader from "./MatchHeader";
import { GET_MATCH } from "./queries";
import ComparisonTable from "./ComparisonTable";
import History from "./History";
import { handleHead } from "../../helper";
import PartialError from "../ErrorPage/PartialError";

const { TabPane } = Tabs;

export default function Match() {
  const { matchId } = useParams();
  const domHistory = useHistory();
  const { loading, error, data, refetch } = useQuery(GET_MATCH, {
    variables: { id: matchId },
  });

  if (loading) return "loading...";
  if (error) return <PartialError />;

  const { position, user, history, rejectReason, fields, createdAt, rate } =
    data.match;
  handleHead("matchDetail", user.name, position.title);

  return (
    <div>
      <Button
        size="small"
        className="back-btn"
        onClick={() => domHistory.goBack()}
      >
        <LeftOutlined />
        Back
      </Button>
      <MatchHeader
        position={position}
        developer={user}
        match={data.match}
        refetch={refetch}
      />

      <Tabs tabPosition="left" size="large" className="detail-tab-list">
        <TabPane
          key="1"
          tab={
            <span>
              <InfoCircleTwoTone />
              Info
            </span>
          }
        >
          <Col span={19}>
            <Progress
              percent={rate}
              format={(percent) => <strong>{`%${percent} Match`}</strong>}
              strokeWidth={7}
              strokeColor="#3475ae"
            />
          </Col>

          <ComparisonTable
            title="General Info"
            position={position}
            developer={user}
            fields={fields}
            type="info"
          />
          <ComparisonTable
            title="Skills"
            position={position}
            developer={user}
            fields={fields}
            type="skill"
          />
          <ComparisonTable
            title="Criteria"
            position={position}
            developer={user}
            fields={fields}
            type="criteria"
          />
        </TabPane>

        <TabPane
          key="2"
          tab={
            <span>
              <BankTwoTone twoToneColor="#a5a5a5" />
              History
            </span>
          }
        >
          <History
            history={history}
            matchCreatedAt={createdAt}
            rejectReason={rejectReason}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
