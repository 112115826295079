import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import styled from "styled-components";
import { Button, ButtonDropdown, DropdownItem } from "tg-design";
import FormScaffold from "../../../../Form/FormScaffold";
import TextField from "../../../../Form/TextField";
import {
  FIND_MATCHED_USER_COUNT_BY_BATCH_FILTER,
  ADD_NEW_BATCH,
} from "../../../queries";
import { POSITION_STATES } from "../../../../../constants";
import { toValidSkills } from "../../../module/SkillGroup";
import { toValidOtherSkills } from "../../../module/OtherSkillGroup";
import {
  toValidLanguages,
  getDefaultLanguages,
} from "../../../module/LanguageGroup";
import CalculateMatchCount from "../../../module/CalculateMatchCount";
import useQuestion from "../../../../../hooks/useQuestion";
import DeveloperFilterForm from "./DeveloperFilterForm";

const DEFAULT_OPTIONS = {
  showStatus: false,
};

const Message = styled.div`
  background-color: #fff9f9;
  color: #ee6868;
  margin-bottom: 10px;
  border: 1px solid #ee6868;
  padding: 20px;
  border-radius: 4px;
`;

const Error = styled.div`
  color: #ee6868;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 100px;

  & div.query {
    max-width: 600px;
  }
`;

const ControlSection = styled.div`
  position: fixed;
  top: 300px;
  right: 50px;
  width: 300px;
  justify-content: center;
`;

const BatchControlForm = styled.div`
  padding-top: 10px;
`;

const getStateFromCriteria = (criteria) => {
  return {
    keywords: criteria?.keywords || null,
    includeLeads: false,
    isVerifiedByTP: false,
    roles: [],
    totalExperience: {
      min: criteria.totalExperience.min || null,
      max: criteria.totalExperience.max || null,
    },
    technologies: {
      expected: (criteria?.technologies?.expected || []).map((row) => {
        return row.map((item) => {
          return {
            experience: item.experience,
            title: item.title,
            _id: item._id,
          };
        });
      }),
      otherExpected: (criteria?.technologies?.otherExpected || []).map(
        (row) => {
          return row.map((item) => {
            return {
              title: item.title,
              _id: item._id,
            };
          });
        }
      ),
    },
    age: {
      min: criteria.age.min || null,
      max: criteria.age.max || null,
    },
    budget: {
      period: criteria.budget.period || "MONTHLY",
      income: criteria.budget.income || "GROSS",
      min: criteria.budget.min || null,
      max: criteria.budget.max || null,
      currency: criteria.budget.currency || "USD",
    },
    education: {
      type: criteria.education.type,
      universities: (criteria.education.universities || []).map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }),
      branches: (criteria.education.branches || []).map((item) => {
        return {
          value: item.id,
          label: item.label,
        };
      }),
    },
    languages: getDefaultLanguages(criteria.languages),
    positionLocation: {
      expectations: criteria?.positionLocation?.expected
        ? [
            {
              label:
                criteria?.positionLocation?.expected?.place?.capital ===
                "country"
                  ? criteria?.positionLocation?.expected?.place?.city
                  : `${criteria?.positionLocation?.expected?.place?.city}, ${criteria?.positionLocation?.expected?.place?.country}`,
              value: criteria?.positionLocation?.expected?.place?.id,
            },
          ]
        : [],
      remote: criteria?.positionLocation?.remote,
    },
    livingCities: criteria.livingCities || [],
  };
};

const getStateFromBatch = (batch) => {
  return {
    keywords: batch?.keywords || null,
    includeLeads: batch.includeLeads,
    isVerifiedByTP: batch.isVerifiedByTP,
    roles: batch.roles,
    totalExperience: {
      min: batch.totalExperience.min || null,
      max: batch.totalExperience.max || null,
    },
    technologies: {
      expected: batch.skills.map((row) => {
        return row.map((item) => {
          return {
            experience: item.experience,
            title: item.skill.title,
            _id: item.skill._id,
          };
        });
      }),
      otherExpected: batch.otherExpected.map((row) => {
        return row.map((item) => {
          return {
            title: item.title,
            _id: item._id,
          };
        });
      }),
    },
    age: {
      min: batch.age.min || null,
      max: batch.age.max || null,
    },
    budget: {
      period: batch.budget.period || "MONTHLY",
      income: batch.budget.income || "GROSS",
      min: batch.budget.min || null,
      max: batch.budget.max || null,
      currency: batch.budget.currency || "USD",
    },
    education: {
      type: batch.education.type,
      universities: (batch.education.universities || []).map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }),
      branches: (batch.education.branches || []).map((item) => {
        return {
          value: item.id,
          label: item.label,
        };
      }),
    },
    languages: getDefaultLanguages(batch.languages),
    positionLocation: {
      expectations: batch.positionLocation.expectations.map((item) => {
        return {
          label:
            item.capital === "country"
              ? item.city
              : `${item.city}, ${item.country}`,
          value: item.id,
        };
      }),
      remote: batch.positionLocation.remote,
    },
    livingCities: batch.livingCities.map((item) => {
      return {
        label:
          item.capital === "country"
            ? item.city
            : `${item.city}, ${item.country}`,
        value: item.id,
      };
    }),
  };
};

export default function CreateNewBatchSection({ position, batches, refetch }) {
  const question = useQuestion();
  const [error, setError] = useState(null);
  const [controls, setControls] = useState({
    loading: false,
    isCriteriaChanged: false,
    count: null,
    title: `Batch ${batches.length + 1}`,
    saving: false,
  });
  const [formState, setFormState] = useState(
    !batches || batches?.length === 0
      ? getStateFromCriteria(position.criteria)
      : getStateFromBatch(batches[batches.length - 1])
  );
  const [addNewBatch] = useMutation(ADD_NEW_BATCH);
  const { refetch: findMatchedUserCountByBatchFilter } = useQuery(
    FIND_MATCHED_USER_COUNT_BY_BATCH_FILTER,
    {
      skip: true,
    }
  );

  const handleGetPreviousBatches = (batch) => {
    setFormState({
      ...formState,
      ...getStateFromBatch(batch),
    });
  };

  const getFilterParameters = () => {
    return {
      includeLeads: formState.includeLeads,
      isVerifiedByTP: formState.isVerifiedByTP,
      totalExperience: formState.totalExperience,
      age: formState.age,
      skills: toValidSkills(formState?.technologies?.expected || []),
      otherExpected: toValidOtherSkills(
        formState?.technologies?.otherExpected || []
      ),
      budget: formState.budget || null,
      positionLocation: {
        expectations: (formState?.positionLocation?.expectations || []).map(
          (i) => i.value
        ),
        remote: formState?.positionLocation?.remote || null,
      },
      education: {
        type: formState?.education?.type,
        universities: (formState?.education?.universities || []).map(
          (i) => i.value
        ),
        branches: (formState?.education?.branches || []).map((i) => i.value),
      },
      languages: toValidLanguages(formState.languages || []),
      roles: formState.roles ? formState.roles.map((i) => i.id) : [],
      keywords: formState?.keywords || null,
      livingCities: formState.livingCities
        ? formState.livingCities.map((i) => i.value)
        : [],
    };
  };

  const handleCountTrigger = async () => {
    try {
      setError(null);
      setControls({
        ...controls,
        loading: true,
        isCriteriaChanged: false,
      });
      const { data } = await findMatchedUserCountByBatchFilter({
        positionId: position.id,
        filters: getFilterParameters(),
      });
      setControls((state) => {
        return {
          ...state,
          loading: false,
          count: data.findMatchedUserCountByBatchFilter,
        };
      });
    } catch (error) {
      setControls((state) => {
        return {
          ...state,
          loading: false,
          count: null,
        };
      });
      throw error;
    }
  };

  const sendNewBatchRequest = async () => {
    try {
      setControls({
        ...controls,
        saving: true,
      });
      await addNewBatch({
        variables: {
          positionId: position.id,
          title: controls.title || "Batch",
          filters: getFilterParameters(),
        },
      });
      setControls((state) => {
        return {
          ...state,
          saving: false,
          title: `Batch ${batches.length + 2}`,
          loading: false,
          isCriteriaChanged: false,
          count: null,
        };
      });
      refetch();
      setError(null);
      message.success("The batch has been created!");
    } catch (error) {
      setControls((state) => {
        return {
          ...state,
          saving: false,
        };
      });
      message.error(`Error: ${error.message}`);
    }
  };

  const saveNewBatch = async () => {
    if (position.state !== POSITION_STATES.IN_PROGRESS) {
      message.error("The position state should be In Progress.");
      return;
    }

    if (controls.count === 0) {
      question(
        {
          content: (
            <div>
              There is <b>not</b> any matched developer. Do you still want to
              create the batch?
            </div>
          ),
        },
        sendNewBatchRequest
      );
    } else if (controls.count > 300) {
      setError(
        "This batch contains more than 300 matches, it's too large. Please add more filters to narrow it down."
      );
    } else {
      question(
        {
          content: (
            <div>
              Do you want to create this batch with{" "}
              <b>{controls.count} matched developers</b>?
            </div>
          ),
        },
        sendNewBatchRequest
      );
    }
  };

  return (
    <Container>
      <div className="query">
        <DeveloperFilterForm
          formState={formState}
          setFormState={setFormState}
          controls={controls}
          setControls={setControls}
        />
      </div>
      <ControlSection>
        {batches.length > 0 && (
          <div style={{ marginBottom: "10px" }}>
            <ButtonDropdown title="Get from previous criterias">
              {batches.map((batch, index) => (
                <DropdownItem
                  onClick={() => handleGetPreviousBatches(batch)}
                  key={index}
                >
                  {batch.title}
                </DropdownItem>
              ))}
            </ButtonDropdown>
          </div>
        )}
        {position.state !== POSITION_STATES.IN_PROGRESS && (
          <Message>
            The position&apos;s state is not <b>In Progress</b>.
          </Message>
        )}
        <CalculateMatchCount
          loading={controls.loading}
          matchCount={controls.count}
          isCriteriaChanged={controls.isCriteriaChanged}
          handleClick={handleCountTrigger}
        />
        {controls.count !== null && controls.isCriteriaChanged === false && (
          <BatchControlForm>
            <FormScaffold label="Batch title" options={DEFAULT_OPTIONS}>
              <TextField
                type="text"
                placeholder="Batch title (optional)"
                value={controls.title}
                defaultValue=""
                onChange={(event) =>
                  setControls({
                    ...controls,
                    title: event.target.value,
                  })
                }
                disabled={controls.saving}
                style={{ marginBottom: "10px" }}
              />
            </FormScaffold>
            <Button
              block
              onClick={saveNewBatch}
              loading={controls.saving}
              disabled={controls.saving}
            >
              Create batch {batches.length + 1}
            </Button>
            {error && <Error>{error}</Error>}
          </BatchControlForm>
        )}
      </ControlSection>
    </Container>
  );
}
