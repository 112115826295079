import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import { message } from "antd";
import { Button } from "tg-design";
import {
  POSITIVE_ACTION_BUTTON_TITLES,
  MATCH_STATE_ALLOWANCE_LIST,
  MATCH_STATES,
  NEGATIVE_ACTION_BUTTON_TITLES,
  REVIVE_ACTION_BUTTON_TITLES,
} from "../../../constants/states";
import { DropDownButton } from "../../Form/Button";
import ProfileBox from "./ProfileBox";
import StatusChangeModal from "../../tg-styles/TgKanban/StatusChangeModal";
import FailReasonsModal from "../../tg-styles/TgKanban/FailReasonModal/FailReasonModal";
import {
  FAIL_REASON_MODAL_TYPES,
  CLOSED_POSITION_STATES,
  DEVELOPER_STATUSES,
} from "../../../constants";
import EmptyWorkHistoryModal from "../../tg-styles/TgKanban/EmptyWorkHistoryModal";
import { GET_USER_WORK_HISTORY } from "../../developers/queries";
import { SENT_TO_GREENHOUSE } from "../queries";
import PassReasonModal from "../../tg-styles/TgKanban/PassReasonModal/PassReasonModal";

const Container = styled.div``;

const ActionBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const NEGATIVE_STATES = [
  MATCH_STATES.FAILED,
  MATCH_STATES.PASSED,
  MATCH_STATES.CANCELED,
];

const getNextPositiveState = (currentState) => {
  if (currentState === MATCH_STATES.CANCELED) {
    return null;
  }

  let nextState = null;
  Object.keys(MATCH_STATE_ALLOWANCE_LIST).forEach((state) => {
    if (
      nextState === null &&
      !NEGATIVE_STATES.includes(state) &&
      MATCH_STATE_ALLOWANCE_LIST[state].includes(currentState)
    ) {
      nextState = state;
    }
  });

  return nextState;
};

const getAvaliableNegativeStates = (currentState) => {
  const nextState = [];
  Object.keys(MATCH_STATE_ALLOWANCE_LIST).forEach((state) => {
    if (
      NEGATIVE_STATES.includes(state) &&
      MATCH_STATE_ALLOWANCE_LIST[state].includes(currentState)
    ) {
      nextState.push(state);
    }
  });

  return nextState;
};

const getNextStepTitle = (state, substate = null, title) => {
  if (substate) {
    if (NEGATIVE_STATES.includes(state)) {
      return `Change to ${title}`;
    }

    return `Proceed to ${title}`;
  }

  return (
    POSITIVE_ACTION_BUTTON_TITLES[state] || NEGATIVE_ACTION_BUTTON_TITLES[state]
  );
};

const getNextStep = (match, nextPositiveState, customFlows) => {
  const { state, substate } = match;

  if (customFlows && customFlows[state] && customFlows[state].length > 0) {
    const currentState = customFlows[state].find((i) => i.uuid === substate);
    const nextStates = [
      ...customFlows[state].filter((i) => i.index > currentState.index),
    ].sort((a, b) => a.index - b.index);
    if (nextStates.length > 0) {
      return {
        state,
        substate: nextStates[0].uuid,
        title: getNextStepTitle(state, nextStates[0].uuid, nextStates[0].title),
      };
    }
  }

  if (
    customFlows &&
    customFlows[nextPositiveState] &&
    customFlows[nextPositiveState].length > 0
  ) {
    return {
      state: nextPositiveState,
      substate: customFlows[nextPositiveState][0].uuid,
      title: getNextStepTitle(
        nextPositiveState,
        customFlows[nextPositiveState][0].uuid,
        customFlows[nextPositiveState][0].title
      ),
    };
  }

  return {
    state: nextPositiveState,
    substate: null,
    title: POSITIVE_ACTION_BUTTON_TITLES[nextPositiveState],
  };
};

export default function DeveloperProfile({
  match,
  history,
  handleChangeState,
  setUpdateCardId,
  setNextMatch,
  customFlows,
  updateMatchState,
}) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  const [atsStatus, setAtsStatus] = useState(match.sentToGreenhouse || false);

  const [sentMatchToGreenhouse, { loading: greenhouseLoading }] = useMutation(
    SENT_TO_GREENHOUSE,
    {
      variables: { matchId: match.id },
      onCompleted: ({ sentMatchToGreenhouse }) => {
        if (sentMatchToGreenhouse) {
          message.success("The candidate successfully sent to Greenhouse");
          setAtsStatus(true);
        } else {
          message.error("This candidate couldn't be sent to Greenhouse!");
          setAtsStatus(false);
        }
      },
    }
  );

  const [loading, setLoading] = useState({
    changingState: false,
    changingToNegativeState: false,
  });
  const [targetState, setTargetState] = useState(null);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState({
    isOpen: false,
    state: null,
  });
  const [isEmptyWorkHistoryModalOpen, setIsEmptyWorkHistoryModalOpen] =
    useState(false);
  const [isFailReasonModalOpen, setIsFailReasonModalOpen] = useState({
    isOpen: false,
    substate: null,
  });
  const [isPassReasonModalOpen, setIsPassReasonModalOpen] = useState({
    isOpen: false,
    substate: null,
  });
  const [loadUserWithLatestWorkHistoryData] = useLazyQuery(
    GET_USER_WORK_HISTORY,
    {
      fetchPolicy: "no-cache",
    }
  );

  const avaliableNegativeStates = getAvaliableNegativeStates(match.state);

  const acceptChanges = async ({ state, substate, action }) => {
    setLoading({
      ...loading,
      [action]: true,
    });
    await handleChangeState({
      id: match.id,
      state,
      substate,
    });
    if (setUpdateCardId) {
      setUpdateCardId(null);
    }
    setLoading({
      ...loading,
      [action]: false,
    });
  };

  const closeStatusChangeModal = () => {
    return setIsStatusChangeModalOpen({
      ...isStatusChangeModalOpen,
      isOpen: false,
    });
  };

  const closeEmptyWorkHistoryModal = () => {
    return setIsEmptyWorkHistoryModalOpen(false);
  };

  const getNextStatePair = (state) => {
    let substate = null;
    if (customFlows) {
      const sortedCustomFlows = [...(customFlows[state] || [])].sort(
        (a, b) => a.index - b.index
      );
      if (sortedCustomFlows && sortedCustomFlows.length > 0) {
        [substate] = sortedCustomFlows;
      }
    }

    return {
      state,
      substate: substate ? substate.uuid : null,
      title: getNextStepTitle(state, substate, substate?.title),
    };
  };

  const handleStateChangeNew = async ({ state, substate, action }) => {
    if (state === MATCH_STATES.COMPANY_ASSESSMENT) {
      const { data: latestWorkHistoryData } =
        await loadUserWithLatestWorkHistoryData({
          variables: { id: match?.user?.id },
        });

      if (
        latestWorkHistoryData?.user?.workHistory?.length === 0 &&
        latestWorkHistoryData?.user?.experience !== 0
      ) {
        return setIsEmptyWorkHistoryModalOpen(true);
      }
      if (
        match.conversation === undefined ||
        match.conversation === null ||
        match.conversation.length === 0
      ) {
        return message.error(
          "Interview note field (Conversation tab) cannot be empty!"
        );
      }
    }

    const previousState = match.state;

    if (
      previousState === MATCH_STATES.EXTERNAL_SOURCING &&
      state === MATCH_STATES.PASSED
    ) {
      setTargetState({ state, substate });
      return setIsPassReasonModalOpen({ isOpen: true, substate });
    }

    if (
      match.state !== MATCH_STATES.COMPANY_ASSESSMENT &&
      state === MATCH_STATES.COMPANY_ASSESSMENT
    ) {
      setTargetState({ state, substate });
      return setIsStatusChangeModalOpen({
        isOpen: true,
        state,
        substate,
      });
    }

    if (
      previousState === MATCH_STATES.TG_ASSESSMENT &&
      state === MATCH_STATES.FAILED
    ) {
      return setIsFailReasonModalOpen({
        type: FAIL_REASON_MODAL_TYPES.FROM_TG_ASSESSMENT_TO_FAILED,
        isOpen: true,
        substate,
      });
    }

    if (
      (previousState === MATCH_STATES.COMPANY_ASSESSMENT ||
        previousState === MATCH_STATES.INTERVIEW) &&
      state === MATCH_STATES.FAILED
    ) {
      return setIsFailReasonModalOpen({
        type: FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED,
        isOpen: true,
        substate,
      });
    }

    setLoading({
      ...loading,
      [action]: true,
    });
    await handleChangeState({
      id: match.id,
      state,
      substate,
    });
    if (setUpdateCardId) {
      setUpdateCardId(null);
    }
    setLoading({
      ...loading,
      [action]: false,
    });
  };

  const handleFailReasonChangeModalClose = () => {
    setIsFailReasonModalOpen({
      isOpen: false,
      substate: null,
    });
  };

  const handlePassReasonChangeModalClose = () => {
    setIsPassReasonModalOpen({
      isOpen: false,
      substate: null,
    });
  };

  const handlePassReasonsSubmitted = async () => {
    acceptChanges({
      action: "changingState",
      state: targetState.state,
      substate: targetState.substate,
    });

    setIsPassReasonModalOpen({
      isOpen: false,
      substate: null,
    });

    if (typeof setNextMatch === "function") {
      setNextMatch();
    }
  };

  const handleFailedStatusChangeCompleted = () => {
    setIsFailReasonModalOpen({
      isOpen: false,
      substate: null,
    });
    updateMatchState({
      cardId: match.id,
      sourceColumnId: match.state,
      sourceSubstate: match.substate,
      targetColumnId: MATCH_STATES.FAILED,
      targetSubstate: isFailReasonModalOpen.substate,
    });
    if (typeof setNextMatch === "function") {
      setNextMatch();
    }
  };

  const failedMatchReviveMenu = [
    {
      key: MATCH_STATES.TG_ASSESSMENT,
      title: REVIVE_ACTION_BUTTON_TITLES[MATCH_STATES.TG_ASSESSMENT],
    },
    {
      key: MATCH_STATES.COMPANY_ASSESSMENT,
      title: REVIVE_ACTION_BUTTON_TITLES[MATCH_STATES.COMPANY_ASSESSMENT],
    },
    {
      key: MATCH_STATES.INTERVIEW,
      title: REVIVE_ACTION_BUTTON_TITLES[MATCH_STATES.INTERVIEW],
    },
    {
      key: MATCH_STATES.SENT_OFFER,
      title: REVIVE_ACTION_BUTTON_TITLES[MATCH_STATES.SENT_OFFER],
    },
  ];

  const canceledMatchReviveMenu = [
    {
      key: MATCH_STATES.CREATED,
      title: REVIVE_ACTION_BUTTON_TITLES[MATCH_STATES.CREATED],
    },
  ];

  const negativeActionList = [
    {
      key: MATCH_STATES.PASSED,
      title: getNextStatePair(MATCH_STATES.PASSED).title,
    },
  ];

  const nextPositiveState = getNextPositiveState(match.state);
  const nextStep = getNextStep(match, nextPositiveState, customFlows);

  const notAllowedATSStates = [
    MATCH_STATES.EXTERNAL_SOURCING,
    MATCH_STATES.CREATED,
    MATCH_STATES.SENT,
    MATCH_STATES.PASSED,
    MATCH_STATES.CANCELED,
  ];

  const handleSentATSButton = () => {
    sentMatchToGreenhouse();
  };
  return (
    <>
      {isPassReasonModalOpen?.isOpen && (
        <PassReasonModal
          userId={match.user.id}
          onClose={handlePassReasonChangeModalClose}
          matchId={match.id}
          onCompleted={handlePassReasonsSubmitted}
        />
      )}
      {isFailReasonModalOpen?.isOpen && (
        <FailReasonsModal
          roleTitle={match.position.title}
          companyName={match.position.company.name}
          matchId={match.id}
          substate={isFailReasonModalOpen.substate}
          type={isFailReasonModalOpen.type}
          onClose={handleFailReasonChangeModalClose}
          onCompleted={handleFailedStatusChangeCompleted}
        />
      )}
      {isStatusChangeModalOpen?.isOpen && (
        <StatusChangeModal
          onClose={() => closeStatusChangeModal()}
          onAccept={() =>
            acceptChanges({
              action: "changingState",
              state: targetState.state,
              substate: targetState.substate,
            })
          }
          user={{
            name: match.user.name,
            rate: match.rate,
            role: match.user.role?.title,
          }}
        />
      )}
      {isEmptyWorkHistoryModalOpen && (
        <EmptyWorkHistoryModal
          onClose={closeEmptyWorkHistoryModal}
          user={match.user}
          history={history}
        />
      )}
      <Container>
        <ProfileBox match={match} user={match.user} showLink />
        {!isPrintPage &&
          match?.user?.status !== DEVELOPER_STATUSES.PRE_LEAD &&
          !CLOSED_POSITION_STATES.includes(match?.position?.state) && (
            <ActionBox>
              {nextPositiveState && (
                <Button
                  style={{ marginRight: "10px" }}
                  loading={loading.changingState}
                  onClick={() =>
                    handleStateChangeNew({
                      ...nextStep,
                      action: "changingState",
                    })
                  }
                >
                  {nextStep.title}
                </Button>
              )}
              {avaliableNegativeStates.length === 1 && (
                <Button
                  variant="danger"
                  outline
                  loading={loading.changingToNegativeState}
                  onClick={() =>
                    handleStateChangeNew({
                      ...getNextStatePair(avaliableNegativeStates[0]),
                      action: "changingToNegativeState",
                    })
                  }
                >
                  {avaliableNegativeStates.includes("FAILED") &&
                    getNextStatePair(MATCH_STATES.FAILED).title}
                  {avaliableNegativeStates.includes("PASSED") &&
                    getNextStatePair(MATCH_STATES.PASSED).title}
                  {avaliableNegativeStates.includes("CANCELED") &&
                    getNextStatePair(MATCH_STATES.CANCELED).title}
                </Button>
              )}
              {avaliableNegativeStates.length > 1 && (
                <DropDownButton
                  title="Disqualify"
                  loading={loading.changingToNegativeState}
                  handleClick={() =>
                    handleStateChangeNew({
                      ...getNextStatePair(MATCH_STATES.FAILED),
                      action: "changingToNegativeState",
                    })
                  }
                  handleMenuItemClick={(menu) => {
                    handleStateChangeNew({
                      ...getNextStatePair(menu.key),
                      action: "changingToNegativeState",
                    });
                  }}
                  menus={negativeActionList}
                />
              )}
              {match.state === MATCH_STATES.FAILED && (
                <DropDownButton
                  title="REVIVE USER"
                  handleClick={() => {}}
                  handleMenuItemClick={(menu) =>
                    handleStateChangeNew({
                      ...getNextStatePair(menu.key),
                      action: "changingState",
                    })
                  }
                  menus={failedMatchReviveMenu}
                />
              )}
              {match.state === MATCH_STATES.CANCELED && (
                <DropDownButton
                  title="REVIVE USER"
                  handleClick={() => {}}
                  handleMenuItemClick={(menu) =>
                    handleStateChangeNew({
                      ...getNextStatePair(menu.key),
                      action: "changingState",
                    })
                  }
                  menus={canceledMatchReviveMenu}
                />
              )}
              {!notAllowedATSStates.includes(match.state) &&
                match.position.greenhouseJobId &&
                !atsStatus && (
                  <Button
                    style={{ marginLeft: "6px" }}
                    variant="secondary"
                    outline
                    dashed
                    onClick={handleSentATSButton}
                    loading={greenhouseLoading}
                  >
                    Sent to Greenhouse
                  </Button>
                )}
            </ActionBox>
          )}
      </Container>
    </>
  );
}
