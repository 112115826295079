import React, { useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "tg-design";

const parseComponent = (item) => {
  if (item.component) {
    return item;
  }

  return {
    title: null,
    component: item,
  };
};

export default function DeepDrawer({ base, ...props }) {
  const [currentSection, setCurrentSection] = useState(null);
  const [data, setData] = useState(null);
  /* eslint-disable-next-line prefer-const */
  let { title, component } = parseComponent(
    currentSection ? props[currentSection] : base
  );

  const setCurrentDrawer = (id, data = null) => {
    setCurrentSection(id);
    setData(data);
  };

  component = React.cloneElement(component, {
    setCurrentDrawer,
    data,
  });

  return (
    <>
      {currentSection && (
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <Button
            variant="secondary"
            outline
            size="xs"
            className="back-btn"
            onClick={() => setCurrentDrawer(null)}
            style={{ marginRight: "10px" }}
          >
            <LeftOutlined />
            Back
          </Button>
          {title && <h2 style={{ display: "inline" }}>{title}</h2>}
        </div>
      )}
      {component}
    </>
  );
}
