import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { TgDrawerTitle } from "../../../../../tg-styles/Layouts";
import TextField from "../../../../../Form/TextField";
import Textarea from "../../../../../Form/Textarea";
import { SelectField } from "../../../../../Form/SelectField";
import { CheckboxField } from "../../../../../Form/CheckboxField";
import { getMonthOptions, createYearsList } from "../../../../../../helper";
import { CURRENT_YEAR, CURRENT_MONTH } from "../../../../../../constants";
import { InlineInputs, HalfSizeInput, ApplyButton, RowInput } from "../styles";
import { workHistoryValidationSchema } from "../validations";

const MONTH_OPTIONS = getMonthOptions({ locale: "en" });
const YEARS = createYearsList();

const setMonthAndYear = (item, key) => {
  if (item[`${key}Date`]) {
    item[`${key}Month`] = new Date(item[`${key}Date`]).getMonth() + 1;
    item[`${key}Year`] = new Date(item[`${key}Date`]).getFullYear();
  }
};

const getPossibleEndYears = (updatedItem) => {
  return YEARS.filter((year) => year.value >= updatedItem.startYear);
};

const getPossibleStartMonths = (updatedItem, monthOptions) => {
  if (updatedItem.startYear === CURRENT_YEAR) {
    return monthOptions.filter((month) => month.value <= CURRENT_MONTH);
  }
  return [...monthOptions];
};

const getPossibleEndMonths = (updatedItem, monthOptions) => {
  let possibleEndMonths = [...monthOptions];
  if (updatedItem.endYear === CURRENT_YEAR) {
    possibleEndMonths = possibleEndMonths.filter(
      (month) => month.value <= CURRENT_MONTH
    );
  }

  if (
    Number.isInteger(updatedItem.startYear) &&
    Number.isInteger(updatedItem.endYear) &&
    updatedItem.startYear === updatedItem.endYear &&
    updatedItem.startMonth
  ) {
    possibleEndMonths = possibleEndMonths.filter(
      (month) => month.value >= updatedItem.startMonth
    );
  }
  return possibleEndMonths;
};

const setFullDate = (item, key) => {
  if (item[`${key}Month`] && item[`${key}Year`]) {
    item[`${key}Date`] = new Date(item[`${key}Year`], item[`${key}Month`] - 1);
  }
};

const isAcceptableEndMonth = (updatedItem) => {
  if (
    !updatedItem.endMonths.some((month) => month.value === updatedItem.endMonth)
  ) {
    return false;
  }

  if (
    Number.isInteger(updatedItem.startYear) &&
    Number.isInteger(updatedItem.endYear) &&
    updatedItem.startYear === updatedItem.endYear &&
    updatedItem.endMonth < updatedItem.startMonth
  ) {
    return false;
  }
  return true;
};

const toFormState = (data) => {
  const form = {
    ...data,
  };
  setMonthAndYear(form, "start");
  setMonthAndYear(form, "end");
  return {
    ...form,
    present: form.endDate === null,
    endYears: getPossibleEndYears(form),
    startMonths: getPossibleStartMonths(form, MONTH_OPTIONS),
    endMonths: getPossibleEndMonths(form, MONTH_OPTIONS),
  };
};

export default function WorkHistoryForm({ data = {}, onClose, onSave }) {
  const [isValid, setValid] = useState(false);
  const [form, setForm] = useState(toFormState(data));
  const onChange = ({ name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleOptionChange = ({ type, value }) => {
    const newState = {
      ...form,
      [type]: value,
    };

    setFullDate(newState, "start");
    setFullDate(newState, "end");

    newState.endYears = getPossibleEndYears(newState);

    if (
      Number.isInteger(newState.startYear) &&
      Number.isInteger(newState.endYear) &&
      newState.endYear < newState.startYear
    ) {
      newState.endYear = null;
    }

    newState.startMonths = getPossibleStartMonths(newState, MONTH_OPTIONS);
    newState.endMonths = getPossibleEndMonths(newState, MONTH_OPTIONS);

    if (
      newState.startYear === CURRENT_YEAR &&
      newState.startMonth > CURRENT_MONTH
    ) {
      newState.startMonth = null;
    }
    if (!isAcceptableEndMonth(newState)) {
      newState.endMonth = null;
    }

    setForm(newState);
  };

  const handlePresentChange = (event) => {
    const newState = {
      ...form,
      present: event.target.checked,
    };

    if (event.target.checked) {
      newState.endMonth = null;
      newState.endYear = null;
      newState.endDate = null;
    }

    setForm(newState);
  };

  const handleSave = () => {
    onSave({
      company: form.company,
      position: form.position,
      startDate: form.startDate
        ? dayjs(form.startDate).format("YYYY-MM-DD")
        : null,
      endDate: form.endDate ? dayjs(form.endDate).format("YYYY-MM-DD") : null,
      description: form.description,
      present: form.present,
    });
  };

  useEffect(() => {
    workHistoryValidationSchema
      .validate(form)
      .then(() => {
        setValid(true);
      })
      .catch(() => {
        setValid(false);
      });
  }, [form]);

  return (
    <>
      <TgDrawerTitle title="Work History" handleClose={onClose} />
      <RowInput>
        <TextField
          isRequired
          name="company"
          label="Company Name"
          defaultValue={data.company}
          onChange={(event) =>
            onChange({ name: "company", value: event.target.value })
          }
        />
      </RowInput>
      <RowInput>
        <TextField
          isRequired
          name="position"
          label="Position"
          defaultValue={data.position}
          onChange={(event) =>
            onChange({ name: "position", value: event.target.value })
          }
        />
      </RowInput>
      <InlineInputs>
        <HalfSizeInput>
          <SelectField
            isRequired
            label="Month Started"
            isSearchable
            options={form.startMonths}
            value={
              form.startMonth
                ? form.startMonths.find(
                    (item) => item.value === form.startMonth
                  )
                : null
            }
            onChange={(selectedOption) =>
              handleOptionChange({
                ...selectedOption,
                type: "startMonth",
              })
            }
          />
        </HalfSizeInput>
        <HalfSizeInput>
          <SelectField
            isRequired
            label="Year started"
            isSearchable
            options={YEARS}
            value={
              form.startYear
                ? YEARS.find((item) => item.value === form.startYear)
                : null
            }
            onChange={(selectedOption) =>
              handleOptionChange({
                ...selectedOption,
                type: "startYear",
              })
            }
          />
        </HalfSizeInput>
      </InlineInputs>
      <RowInput>
        <CheckboxField
          checked={form.present}
          name="present"
          onChange={handlePresentChange}
          value={form.present}
          containerStyles={{
            fontSize: "14px",
            color: "#444444",
          }}
          checkboxStyles={{
            height: "20px",
            width: "20px",
            marginTop: "-1px",
            marginRight: "10px",
          }}
        >
          Still working in this position.
        </CheckboxField>
      </RowInput>
      {!form.present && (
        <InlineInputs>
          <HalfSizeInput>
            <SelectField
              isRequired
              label="Month Left"
              isSearchable
              options={form.endMonths}
              value={
                form.endMonth
                  ? form.endMonths.find((item) => item.value === form.endMonth)
                  : null
              }
              onChange={(selectedOption) =>
                handleOptionChange({
                  ...selectedOption,
                  type: "endMonth",
                })
              }
            />
          </HalfSizeInput>
          <HalfSizeInput>
            <SelectField
              isRequired
              label="Year finished"
              isSearchable
              options={form.endYears}
              value={
                form.endYear
                  ? form.endYears.find((item) => item.value === form.endYear)
                  : null
              }
              onChange={(selectedOption) =>
                handleOptionChange({
                  ...selectedOption,
                  type: "endYear",
                })
              }
            />
          </HalfSizeInput>
        </InlineInputs>
      )}
      <RowInput>
        <Textarea
          name="description"
          label="Details (Optional)"
          defaultValue={data.description}
          rows="5"
          onChange={(event) =>
            onChange({ name: "description", value: event.target.value })
          }
        />
      </RowInput>
      <ApplyButton onClick={handleSave} disabled={!isValid} />
    </>
  );
}
