import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { message } from "antd";
import { TgDrawerTitle } from "../../../../../tg-styles/Layouts";
import { SelectField, AsyncSelectField } from "../../../../../Form/SelectField";
import { debounce } from "../../../../../../helper";
import { LANGUAGE_LEVEL_TYPES } from "../../../../../../constants";
import { languageValidationSchema } from "../validations";
import { ApplyButton, RowInput } from "../styles";
import { ALL_LANGUAGES } from "../../../../../../queries/education";

const toFormState = (data) => {
  return {
    ...data,
  };
};

const getLevelType = (form) => {
  if (form.level !== null && form.level !== undefined) {
    return LANGUAGE_LEVEL_TYPES.find((level) => level.value === form.level);
  }
  return null;
};

export default function LanguageForm({ data = {}, onClose, onSave }) {
  const [isValid, setValid] = useState(false);
  const [form, setForm] = useState(toFormState(data));
  const { refetch: languageRefetch } = useQuery(ALL_LANGUAGES, {
    skip: true,
  });

  const handleLanguageSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await languageRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _languages = data.allLanguages.languages.map((language) => {
          return {
            value: language?.id,
            label: language?.label,
          };
        });
        const sortedlanguages = _languages.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        callback(sortedlanguages);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleOptionChange = ({ value, label, type }) => {
    let _value = value;

    if (type === "language") {
      _value = {
        label,
        id: value,
      };
    }

    const newState = {
      ...form,
      [type]: _value,
    };

    setForm(newState);
  };

  const handleSave = () => {
    onSave({
      language: {
        id: form.language?.id,
        label: form.language?.label,
      },
      level: form.level,
    });
  };

  useEffect(() => {
    languageValidationSchema
      .validate({
        language: form.language && form.language.id,
        level: form.level,
      })
      .then(() => {
        setValid(true);
      })
      .catch(() => {
        setValid(false);
      });
  }, [form]);

  return (
    <>
      <TgDrawerTitle title="Language" handleClose={onClose} />
      <RowInput>
        <AsyncSelectField
          isRequired
          label="Language"
          isSearchable
          loadOptions={handleLanguageSearch}
          onChange={(selectedOption) =>
            handleOptionChange({
              ...selectedOption,
              type: "language",
            })
          }
          value={form.language?.label ? { label: form.language?.label } : null}
        />
      </RowInput>
      <RowInput>
        <SelectField
          isRequired
          label="Level"
          isSearchable
          options={LANGUAGE_LEVEL_TYPES}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={(selectedOption) =>
            handleOptionChange({
              ...selectedOption,
              type: "level",
            })
          }
          value={getLevelType(form) || null}
        />
      </RowInput>

      <ApplyButton onClick={handleSave} disabled={!isValid} />
    </>
  );
}
