import gql from "graphql-tag";

export const SEARCH_UNIVERSITY = gql`
  query AllUniversity($search: String) {
    allUniversity(search: $search) {
      universities {
        id
        name
      }
    }
  }
`;

export const SEARCH_BRANCH = gql`
  query AllUniversityBranch($search: String) {
    allUniversityBranch(search: $search) {
      universityBranches {
        id
        label
      }
    }
  }
`;

export const ALL_LANGUAGES = gql`
  query AllLanguages($search: String) {
    allLanguages(search: $search) {
      languages {
        id
        label
      }
    }
  }
`;
