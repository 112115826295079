import { useRef, useEffect } from "react";

export const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible]);
};
