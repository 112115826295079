import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #f6f6f6;
  margin-right: 20px;
  width: 260px;
  min-width: 260px;
  border-radius: 5px;
  padding: 16px 13px 40px 13px;
  border: ${(props) =>
    props.borderColor
      ? `2px solid ${props.borderColor}`
      : "2px dashed #f1f1f1"};
  &.on-hover {
    border: ${(props) =>
      props.borderColor
        ? `2px dashed ${props.borderColor}`
        : "2px dashed #64b7dc"};
  }

  &.is-not-allowed {
    opacity: 0.4;
    border-color: #f1f1f1;

    &.has-substate {
      opacity: 1;
    }
  }

  &.has-substate {
    width: auto;
    min-width: unset;
    padding: 16px 13px;
  }
`;

export default function TgKanbanColumn({
  handleDragChange,
  onDrop,
  columnId,
  substate = null,
  children,
  isOnHover = false,
  isAllowed,
  borderColor,
  hasSubState = false,
  className: inheritedClass = "",
  ...props
}) {
  const [classValue, setClassValue] = useState("");
  const handleDragOver = useCallback(
    (event) => {
      if (hasSubState) {
        return event.preventDefault();
      }

      handleDragChange({
        targetColumnId: columnId,
        targetSubstate: substate,
        clientX: event.clientX,
      });

      event.preventDefault();
    },
    // eslint-disable-next-line
    [columnId, substate, handleDragChange]
  );

  useEffect(() => {
    const classes = [inheritedClass];
    if (isOnHover && hasSubState === false) {
      classes.push("on-hover");
    }

    if (!isAllowed) {
      classes.push("is-not-allowed");
    }

    if (hasSubState) {
      classes.push("has-substate");
    }

    if (classValue !== classes.join(" ")) {
      setClassValue(classes.join(" "));
    }
    // eslint-disable-next-line
  }, [isOnHover, hasSubState, isAllowed]);

  return (
    <Container
      onDrop={(event) => {
        event.stopPropagation();
        onDrop(event);
      }}
      onDragOver={handleDragOver}
      className={classValue}
      borderColor={borderColor}
      {...props}
    >
      {children}
    </Container>
  );
}
