import React, { useState } from "react";
import { Select } from "antd";
import { useLazyQuery } from "@apollo/client";
import { ButtonLink } from "tg-design";
import { debounce } from "../../../helper";
import { GET_ALL_COMPANY } from "../queries";
import PartialError from "../../ErrorPage/PartialError";

const { Option } = Select;

export default function CompanySearch({
  confirm,
  setSelectedKeys,
  showClearButton = true,
}) {
  const [companyData, setCompanyData] = useState();
  const [getCompanies, { error }] = useLazyQuery(GET_ALL_COMPANY, {
    onCompleted: (data) => {
      setCompanyData(data.allCompany.companies);
    },
  });

  const onBlur = () => {
    setCompanyData(null);
  };

  const onSearch = async (val) => {
    await getCompanies({ variables: { search: val, limit: 0 } });
  };

  const onConfirm = (e) => {
    setSelectedKeys(e);
    confirm();
  };

  if (error) return <PartialError />;

  return (
    <div>
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Select a company"
        optionFilterProp="children"
        onChange={onConfirm}
        onBlur={onBlur}
        onSearch={debounce(onSearch, 300)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {companyData &&
          companyData.map((field) => (
            <Option key={field.id} value={field.id}>
              {field.name}
            </Option>
          ))}
      </Select>
      {showClearButton && (
        <ButtonLink style={{ margin: "10px" }} onClick={() => onConfirm(null)}>
          Clear Selected
        </ButtonLink>
      )}
    </div>
  );
}
