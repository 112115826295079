import React from "react";
import {
  BUDGET_PERIOD_TITLES,
  INCOME_TYPE_TITLES,
} from "../../../../../../constants";
import { toPrettyPrice } from "../../../../../../helper";

export default function BudgetCriteriaLine({ budget }) {
  return (
    <>
      <span>
        {toPrettyPrice(budget.min, budget.currency)}-
        {toPrettyPrice(budget.max, budget.currency)}
      </span>
      /{BUDGET_PERIOD_TITLES[budget.period]}
      <span> ({INCOME_TYPE_TITLES[budget.income]}) </span>
    </>
  );
}
