import React, { useState, useEffect } from "react";
import {
  GroupTitle,
  ColumnTitle,
  SectionTitle,
  CurrentSectionContainer,
  OriginalContainer,
  LinkedinSectionContainer,
  PrimaryTitle,
  SecondaryTitle,
  DateText,
} from "./styles";

import { RowBlock, ColumnBlock } from "../../../../../Form/BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../../../../Icons";

export const EducationTab = ({ handleStateChange, linkedinData, user }) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const newState = linkedinData.education.map((education) => {
      return education;
    });
    setState(newState);
    // eslint-disable-next-line
  }, []);

  const toggleCheck = (idx) => {
    const newState = state.slice();
    newState[idx].isChecked = !newState[idx].isChecked;
    const changes = newState.filter((education) => education.isChecked);

    setState(newState);

    setState(newState);
    handleStateChange("education", changes);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Education</GroupTitle>

      <RowBlock>
        <ColumnBlock
          width="50%"
          justifyContent="flex-start"
          margin="0 0 100px 0"
        >
          <ColumnTitle>Current</ColumnTitle>
          {user.education.length === 0 ? (
            <span>No education history found.</span>
          ) : (
            user.education.map((item, index) => {
              return (
                <CurrentSectionContainer
                  style={{ marginBottom: "20px" }}
                  key={index}
                >
                  <div>
                    <PrimaryTitle>{item.school?.name}</PrimaryTitle>
                    <SecondaryTitle>{item.branch?.label}</SecondaryTitle>
                    <DateText>
                      {item.startDate} - {item.endDate ? item.endDate : "?"}
                    </DateText>
                  </div>
                </CurrentSectionContainer>
              );
            })
          )}
        </ColumnBlock>
        <ColumnBlock
          width="50%"
          justifyContent="flex-start"
          margin="0 0 100px 0"
        >
          <ColumnTitle>From Linkedin</ColumnTitle>

          {linkedinData.education.length === 0 ||
          linkedinData.education.flat().length === 0 ? (
            <div>
              <span>No education history found.</span>
            </div>
          ) : (
            linkedinData.education.map((item, idx) => {
              return (
                <div key={idx}>
                  <SectionTitle>Original</SectionTitle>

                  <OriginalContainer>
                    <div style={{ flex: "1" }}>
                      <span>
                        {item.school}/ {item.branch}
                      </span>
                      <DateText>
                        {item.startDate} - {item.endDate ? item.endDate : "?"}
                      </DateText>
                    </div>
                  </OriginalContainer>

                  <SectionTitle>TG suggestions</SectionTitle>
                  {item.suggestions.length === 0 ? (
                    <div>
                      <span>No suggestions found.</span>
                    </div>
                  ) : (
                    item.suggestions.map((suggestion, index) => {
                      return (
                        <LinkedinSectionContainer
                          isChecked={state[idx]?.isChecked}
                          onClick={() => {
                            toggleCheck(idx);
                          }}
                          style={{ marginBottom: "20px" }}
                          key={index}
                        >
                          <div style={{ flex: "1" }}>
                            <PrimaryTitle>
                              {suggestion.school?.name}
                            </PrimaryTitle>
                            <SecondaryTitle>
                              {suggestion.branch?.label}
                            </SecondaryTitle>
                            <DateText>
                              {suggestion.startDate} -{" "}
                              {suggestion.endDate ? suggestion.endDate : "?"}
                            </DateText>
                          </div>
                          <div>
                            {state[idx]?.isChecked ? (
                              <CheckedBoxIcon />
                            ) : (
                              <UncheckedBoxIcon />
                            )}
                          </div>
                        </LinkedinSectionContainer>
                      );
                    })
                  )}
                </div>
              );
            })
          )}
        </ColumnBlock>
      </RowBlock>
    </div>
  );
};
