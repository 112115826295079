import React from "react";
import styled from "styled-components";
import { Label } from "../FormUi";

export const Input = styled.textarea`
  -webkit-appearance: none;
  background-color: #f5f6f8;
  border-radius: 5px;
  padding: 11px 9px;
  font-weight: bold;
  text-align: left;
  font-family: ${(props) => props.theme.family.demi};
  color: #444444;
  resize: none;
  font-size: 14px;
  border-width: 2px;
  border-color: ${(props) => (props.hasError ? "#ff7675" : "transparent")};
  outline: none;
  border-style: solid;
  :focus {
    border-color: #64b7dc !important;
  }
`;

const fieldStyle = {
  minHeight: "84px",
  display: "flex",
  flexDirection: "column",
};

const Textarea = ({ ...props }) => {
  const { error, otherStyle } = props;
  return (
    <>
      <div style={{ ...fieldStyle, ...otherStyle }}>
        <Label mb={1}>{props.label}</Label>
        <Input value={props.value} {...props} />
        {error ? (
          <Label mt={1} hasError={error}>
            {error.message}
          </Label>
        ) : null}
      </div>
    </>
  );
};

export default Textarea;
