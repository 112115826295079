/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { Button } from "tg-design";
import { TgDrawer, TgTable, TgButtonField } from "../../../tg-styles/Layouts";

import { SORTERS } from "../../../../constants/index";

import { getUrlParams, setQueries } from "../../../../helper";

import { GET_ALL_DOCUMENT } from "../../queries";

import DocumentSidebar from "../modules/DocumentSidebar";
import { getQueries } from "../modules/util";
import PartialError from "../../../ErrorPage/PartialError";

export default function News() {
  const [queryString, setQueryString] = useState("");
  const [drawerState, setdrawerState] = useState({ visible: false, doc: {} });
  const [documentsData, setDocumentsData] = useState("");

  const [getAllDocument, { loading, error, data }] = useLazyQuery(
    GET_ALL_DOCUMENT,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setDocumentsData(data.allDocuments.documents);
      },
    }
  );

  const DEFAULT_LIMIT = 20;
  const DEFAULT_SORT = { publishedAt: "DESC" };

  const fetch = async () => {
    const params = getUrlParams();
    const {
      page = 1,
      limit = DEFAULT_LIMIT,
      sort = DEFAULT_SORT,
      type = "NEWS",
    } = getQueries(params);
    getAllDocument({ variables: { page, limit, sort, type } });
  };

  useEffect(() => {
    fetch();
  }, [queryString, window.history]);

  const getDefaultPage = () => {
    const params = getUrlParams();
    const { page = 1 } = getQueries(params);
    return page;
  };

  const getDefaultLimit = () => {
    const params = getUrlParams();
    const { limit = DEFAULT_LIMIT } = getQueries(params);
    return limit;
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "id",
      render: (value, record) => {
        return record.title;
      },
    },
    {
      title: "Owner",
      dataIndex: "owner",
      render: (owner) => <span>{owner.name || "-"}</span>,
    },
    {
      title: "Published At",
      dataIndex: "publishedAt",
      render: (date, record) => {
        if (!record.visible) {
          return "Draft";
        }
        if (!date) {
          return "Missing publish date";
        }
        return dayjs(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Edit",
      dataIndex: "id",
      render: (value, record) => <a onClick={() => showDrawer(record)}>Edit</a>,
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    const sort = {
      [sorter.field]: SORTERS[sorter.order],
    };
    const params = getUrlParams();
    setQueryString(
      setQueries(
        Object.assign(params, {
          page: pagination.current,
          limit: pagination.pageSize,
          sort: JSON.stringify(sort),
          ...filters,
        })
      )
    );
  };

  const showDrawer = (doc) => {
    setdrawerState({
      visible: doc ? "edit" : "create",
      doc,
    });
  };

  const onClose = () => {
    setdrawerState({
      visible: false,
      doc: null,
    });
  };

  if (error) return <PartialError />;

  return (
    <>
      <TgButtonField>
        <Button onClick={() => showDrawer()}>Add News</Button>
      </TgButtonField>

      {drawerState.visible === "edit" && (
        <TgDrawer closable={false} onClose={onClose} visible>
          <DocumentSidebar
            initialValues={drawerState.doc}
            refetch={fetch}
            handleDrawerClose={onClose}
            type="NEWS"
          />
        </TgDrawer>
      )}
      {drawerState.visible === "create" && (
        <TgDrawer closable={false} onClose={onClose} visible>
          <DocumentSidebar
            type="NEWS"
            refetch={fetch}
            handleDrawerClose={onClose}
          />
        </TgDrawer>
      )}
      <TgTable
        width="100%"
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        dataSource={documentsData}
        onChange={onChange}
        pagination={{
          pageSize: getDefaultLimit(),
          total: data && data.allDocuments.totalCount,
          defaultCurrent: getDefaultPage(),
        }}
      />
    </>
  );
}
