import React from "react";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  BoxTitle,
  Row,
  FlexRow,
  SectionTitle,
  SectionDescription,
  STATUS_COLORS,
} from "./styles";
import ReadMore from "../../../../ReadMore";

export default function PersonalInfo({
  bio,
  birthyear,
  matchSettings,
  email,
  phone,
  status,
  social = {},
  createdAt,
  updatedAt,
  utms = {},
  resume = {},
  preferences = {},
}) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  function calculateRemainedDays(date) {
    const now = new Date();
    const lastUpdateDate = new Date(date);
    const availableDate = new Date(
      lastUpdateDate.getTime() + 90 * 24 * 3600 * 1000
    );
    const remainedDay =
      (availableDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
    // eslint-disable-next-line no-bitwise
    return ` ${~~remainedDay} `;
  }
  return (
    <Row>
      <BoxTitle mb={2} mt={2}>
        Personal Info
      </BoxTitle>
      {!isPrintPage && (
        <>
          <Row mb={2}>
            <SectionTitle>Match Availability</SectionTitle>
            {matchSettings.available ? (
              <SectionDescription color={STATUS_COLORS.green}>
                Available
              </SectionDescription>
            ) : (
              <SectionDescription color={STATUS_COLORS.red}>
                Unavailable (
                <b>{calculateRemainedDays(matchSettings.availableDate)}</b> days
                remained)
              </SectionDescription>
            )}
          </Row>
          <Row mb={2}>
            <SectionTitle>Actively Looking for a job</SectionTitle>
            {preferences?.openToWork && (
              <SectionDescription color={STATUS_COLORS.green}>
                Yes
              </SectionDescription>
            )}
            {!preferences?.openToWork && (
              <SectionDescription color={STATUS_COLORS.red}>
                No
              </SectionDescription>
            )}
          </Row>
          <Row mb={2}>
            <SectionTitle>Lead Status</SectionTitle>
            <SectionDescription>{status}</SectionDescription>
          </Row>
        </>
      )}

      <Row mb={2}>
        <SectionTitle>Email</SectionTitle>
        <SectionDescription>{email || "-"}</SectionDescription>
      </Row>
      {social?.linkedin && (
        <Row mb={2}>
          <SectionTitle>Linkedin</SectionTitle>
          <SectionDescription>{social.linkedin || "-"}</SectionDescription>
        </Row>
      )}

      {social?.github && (
        <Row mb={2}>
          <SectionTitle>Github</SectionTitle>
          <SectionDescription>{social.github || "-"}</SectionDescription>
        </Row>
      )}

      {social?.stackoverflow && (
        <Row mb={2}>
          <SectionTitle>Stackoverflow</SectionTitle>
          <SectionDescription>{social.stackoverflow || "-"}</SectionDescription>
        </Row>
      )}
      {social?.blog && (
        <Row mb={2}>
          <SectionTitle>Blog</SectionTitle>
          <SectionDescription>{social.blog || "-"}</SectionDescription>
        </Row>
      )}
      {social?.youtube && (
        <Row mb={2}>
          <SectionTitle>YouTube</SectionTitle>
          <SectionDescription>{social.youtube || "-"}</SectionDescription>
        </Row>
      )}
      {social?.twitter && (
        <Row mb={2}>
          <SectionTitle>Twitter</SectionTitle>
          <SectionDescription>{social.twitter || "-"}</SectionDescription>
        </Row>
      )}
      <Row mb={2}>
        <SectionTitle>Phone</SectionTitle>
        <SectionDescription>{phone || "-"}</SectionDescription>
      </Row>

      <Row mb={2}>
        <SectionTitle>Age</SectionTitle>
        <SectionDescription>
          {new Date().getFullYear() - birthyear || "-"}
        </SectionDescription>
      </Row>

      <Row mb={2}>
        <SectionTitle>About</SectionTitle>
        <SectionDescription>
          {bio && !isPrintPage ? <ReadMore text={bio} limit={250} /> : "-"}
          {bio && isPrintPage ? <span>{bio}</span> : "-"}
        </SectionDescription>
      </Row>

      {!isPrintPage && (
        <>
          <Row mb={2}>
            <SectionTitle>Signed up</SectionTitle>
            <SectionDescription>
              {dayjs(createdAt).format("DD.MM.YYYY")}
            </SectionDescription>
          </Row>

          <Row mb={2}>
            <SectionTitle>Last updated</SectionTitle>
            <SectionDescription>
              {dayjs(updatedAt).format("DD.MM.YYYY")}
            </SectionDescription>
          </Row>

          <Row mb={2}>
            <SectionTitle>UTM parameters</SectionTitle>
            <SectionDescription>
              <FlexRow style={{ gap: "10px" }}>
                <span>source: {utms?.source || "-"}</span>
                <span>medium: {utms?.medium || "-"}</span>
                <span>campaign: {utms?.campaign || "-"}</span>
                <span>term: {utms?.term || "-"}</span>
                <span>content: {utms?.content || "-"}</span>
              </FlexRow>
            </SectionDescription>
          </Row>
          <Row mb={2}>
            <SectionTitle>Resume</SectionTitle>
            <SectionDescription>
              {resume?.resumeURL ? (
                <Link
                  to={{ pathname: resume?.resumeURL }}
                  target="_blank"
                  rel="noopener"
                >
                  {resume?.fileName || "No name"}
                </Link>
              ) : (
                "-"
              )}
            </SectionDescription>
          </Row>
        </>
      )}
    </Row>
  );
}
