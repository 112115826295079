import React, { useState } from "react";
import { Spin } from "antd";
import { Button } from "tg-design";
import { TgDrawerTitle } from "../../tg-styles/Layouts";
import FormScaffold from "../../Form/FormScaffold";
import RichTextEditor from "../../Form/RichTextEditor";
import { SelectField } from "../../Form/SelectField";
import { FlagIcon } from "../../Icons";
import { FLAGS } from "../../../constants";

const flagOptions = [
  {
    title: "Green Flagged Note",
    key: "GREEN",
  },
  {
    title: "Yellow Flagged Note",
    key: "YELLOW",
  },
  {
    title: "Red Flagged Note",
    key: "RED",
  },
];

const flagOuickFill = [
  {
    title: "Quick Fill Note #1",
    key: "Quick Fill Note #1",
  },
  {
    title: "Quick Fill Note #2",
    key: "Quick Fill Note #2",
  },
  {
    title: "Quick Fill Note #3",
    key: "Quick Fill Note #3",
  },
];

export default function CreateOrUpdateFlag({
  onClose,
  initialValues,
  handleSubmit,
}) {
  const [flagNote, setFlagNote] = useState({
    flagId: initialValues?.id || null,
    note: initialValues?.note || null,
    type: initialValues?.type || null,
    isNew: initialValues && true,
  });
  const [loading, setLoading] = useState(false);

  const handleClick = async (note) => {
    setLoading(true);
    await handleSubmit(note);
    setLoading(false);
  };

  return (
    <div>
      <TgDrawerTitle
        title={initialValues ? "Edit Internal Note" : "Create Internal Note"}
        handleClose={onClose}
      />

      <FormScaffold
        label="Internal Note"
        options={{ showStatus: false }}
        formBodyStyles={{ paddingLeft: "0" }}
        explanation={
          <div>
            <span>This internal note will be private to TG TEAM only.</span>
            <br />
            <span>
              Red, yellow and green flags will be visible to TG team on every
              match of the candidate.
            </span>
          </div>
        }
      >
        <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          <SelectField
            getOptionLabel={(option) => {
              return (
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <FlagIcon
                    colors={
                      FLAGS.find((item) => option.key === item.type).colors
                    }
                    showFlagOnly
                  />
                  <span>{option.title}</span>
                </div>
              );
            }}
            getOptionValue={(option) => {
              if (initialValues) {
                return;
              }
              return option.key;
            }}
            options={flagOptions}
            isSearchable={false}
            onChange={(value) =>
              setFlagNote({
                ...flagNote,
                type: value.key,
              })
            }
            value={
              flagNote.type
                ? flagOptions.find((item) => item.key === flagNote.type)
                : null
            }
            placeholder="Select State"
          />
          <SelectField
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.key}
            options={flagOuickFill}
            isSearchable={false}
            onChange={(value) =>
              setFlagNote({
                ...flagNote,
                note: value.key,
              })
            }
            placeholder="Quick Fill"
          />
        </div>
        <RichTextEditor
          name="flags"
          label="Internal Note"
          defaultValue={flagNote.note}
          value={flagNote.note}
          onChange={(value) =>
            setFlagNote({
              ...flagNote,
              note: value,
            })
          }
        />
      </FormScaffold>
      <Button block onClick={() => handleClick(flagNote)}>
        {initialValues ? "Update Internal Note" : "Submit"}{" "}
        {loading && <Spin size="small" />}
      </Button>
    </div>
  );
}
