import React, { useState } from "react";

import { TgHeader } from "../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../tg-styles/TgHeaderTabs";

import { CategorizedAnalytics, SimpleAnalytics } from "./tabs";

export default function Analytics() {
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "simple"
  );

  const getTab = () => {
    switch (currentTab) {
      case "simple":
        return <SimpleAnalytics />;
      case "categorized":
        return <CategorizedAnalytics />;
      default:
        setCurrentTab("simple");
        break;
    }
  };

  return (
    <div>
      <TgHeader
        breadCrumbs={[{ title: "Analytics", link: "/analytics" }]}
        title="Analytics"
      >
        <TgHeaderTabs currentTab={currentTab} handleChange={setCurrentTab}>
          <TgHeaderTab name="simple">Simple</TgHeaderTab>
          <TgHeaderTab name="categorized">Categorized</TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>
      <div style={{ paddingLeft: "10px" }}>{getTab()}</div>
    </div>
  );
}
