import React from "react";

import {
  FlexRow,
  FlexColumn,
  Item,
  IndexBox,
  Title,
  Experience,
} from "./styles";
import { proExperiences } from "../../../../../constants";

const RoleListView = ({ data = [] }) => {
  const getExperienceLabel = (experience) => {
    const { name } = proExperiences.filter((i) => i.value === experience)[0];
    return <Experience>{name}</Experience>;
  };

  return data.map((item, index) => (
    <Item key={index}>
      <IndexBox>{index + 1}</IndexBox>
      <Title>{item?.label}</Title>
      {getExperienceLabel(item.value, index)}
    </Item>
  ));
};

export default function RoleView({ roles }) {
  const data = roles.map((role) => {
    return { label: role.role.title, value: role.experience };
  });
  return (
    <FlexRow>
      <FlexColumn width={{ mobileS: "100%", laptopL: "50%" }} mt={2}>
        <RoleListView data={data} />
      </FlexColumn>
    </FlexRow>
  );
}
