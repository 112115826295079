import React from "react";
import { useLocation } from "react-router-dom";
import { EducationHistoryIcon } from "../../../../Icons";
import EmptyData from "../EditDeveloperTab/EmptyData";
import {
  SectionContainer,
  SectionTitle,
  Container,
  InfoContainer,
  IconContainer,
  InformationConatiner,
  Title,
  DegreeAndDateContainer,
  Degree,
  Dates,
  SecondaryTitle,
} from "./styles";
import { EDUCATION_TYPES } from "../../../../../constants";

function getDegree(type) {
  return EDUCATION_TYPES[type]?.label;
}
export default function EducationHistorySection({ user }) {
  const location = useLocation();
  const isPrintPage = location.pathname.includes("print");
  if (!user.education || (user.education.length === 0 && isPrintPage)) {
    return <></>;
  }

  return (
    <SectionContainer>
      <SectionTitle>Education History</SectionTitle>
      {(!user.education || user.education.length === 0) && <EmptyData />}

      {user.education.map((education, index) => {
        return (
          <Container key={index}>
            <InfoContainer>
              <IconContainer>
                <EducationHistoryIcon />
              </IconContainer>
              <InformationConatiner>
                <Title>{education?.school?.name}</Title>
                <SecondaryTitle>{education?.branch?.label}</SecondaryTitle>
              </InformationConatiner>
            </InfoContainer>
            <DegreeAndDateContainer>
              <Degree>{getDegree(education.type)}</Degree>
              <Dates>
                {education.startDate} -{" "}
                {!education.endDate ? "?" : education.endDate}
              </Dates>
            </DegreeAndDateContainer>
          </Container>
        );
      })}
    </SectionContainer>
  );
}
