import { Table } from "antd";
import styled from "styled-components";

export default styled(Table)`
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #c7c7c7;
    padding: 9.25px 0 9.25px 10px;
    height: 60px;
  }
  .ant-table-thead > tr > th {
    font-size: 14px;
    font-weight: 600;
    background: white;
    border-bottom: 2px solid #656565;
    padding: 10px;
  }
  .ant-pagination-item,
  .ant-pagination-options,
  .ant-pagination-item-ellipsis {
    line-height: 48px !important;
  }
  .ant-table-pagination.ant-pagination {
    min-height: 48px;
  }
  .ant-pagination-item-active a {
    background: #64b7dc;
    color: white;
    border-radius: 5px;
  }
  .ant-pagination-item,
  .ant-pagination-item-active a,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    width: 47px;
    height: 48px;
    border: 0;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    color: #64b7dc;
    line-height: 1em;
    svg {
      width: 2em;
      height: 2em;
    }
  }
  img {
    width: 43.2px;
    height: 40.5px;
    border-radius: 20px;
    margin-right: 28px;
  }
`;
