import React, { useEffect, useState } from "react";
import styled from "styled-components";
import md5 from "md5";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Button, Tooltip } from "tg-design";
import { handleHead } from "../../helper";
import { TgTable, TgHeader, TgTotalInfo, TgDrawer } from "../tg-styles/Layouts";
import { FIND_MATCHED_USERS } from "./queries";
import PartialError from "../ErrorPage/PartialError";
import DeveloperFilterForm from "../position/detail/tabs/BatchesSections/DeveloperFilterForm";
import { toValidSkills } from "../position/module/SkillGroup";
import { toValidOtherSkills } from "../position/module/OtherSkillGroup";
import { toValidLanguages } from "../position/module/LanguageGroup";
import SearchLine from "./SearchLine";
import { CircleCheckIcon } from "../Icons";

const QueryBox = styled.div`
  padding: 40px 0px 80px 0px;
`;

const QueryTitle = styled.div`
  position: fixed;
  top: 0px;
  font-size: 30px;
  font-weight: bold;
  background-color: white;
  border-bottom: 1px solid #f1f1f1;
  width: calc(50% - 60px);
  z-index: 99999;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  padding: 20px;
  width: calc(50% - 20px);
  background: white;
  margin-left: -20px;
  margin-right: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  & button {
    width: 100%;
  }
`;

const columns = [
  {
    title: "Developer",
    dataIndex: "name",
    render: (value, record) => (
      <Link to={`/developers/${record.id}`}>
        {value || "No name"}
        {record?.isVerifiedByTP && <CircleCheckIcon />}
        {record.isTest && (
          <span style={{ color: "#ff7675", paddingLeft: "5px" }}>-Test-</span>
        )}
      </Link>
    ),
  },
  {
    title: "Role",
    dataIndex: "roles",
    render: (value) => {
      if (!value) {
        return null;
      }
      return (
        <Tooltip
          text={
            value.length > 0
              ? value.map((i, idx) => (
                  <span>
                    {i.role.title}
                    {idx !== value.length - 1 ? ", " : ""}
                  </span>
                ))
              : "Missing role"
          }
        >
          {value[0]?.role?.title || "Missing role"}
        </Tooltip>
      );
    },
  },
  {
    title: "Experience",
    dataIndex: "experience",
    render: (value) => value,
  },
  {
    title: "Linkedin",
    dataIndex: "social",
    render: (value) => {
      if (!value) {
        return null;
      }
      return (
        <Link to={{ pathname: value.linkedin }} target="_blank">
          {value.linkedin}
        </Link>
      );
    },
  },
  {
    title: "Total Matches",
    dataIndex: "matches",
    render: (value) => value.length,
  },
];

const DEFAULT_QUERY_STATE = {
  keywords: null,
  text: "",
  includeLeads: false,
  isVerifiedByTP: false,
  roles: [],
  totalExperience: {
    min: null,
    max: null,
  },
  technologies: {
    expected: [],
    otherExpected: [],
  },
  age: {
    min: null,
    max: null,
  },
  budget: {
    period: "MONTHLY",
    income: "GROSS",
    min: null,
    max: null,
    currency: "USD",
  },
  education: {
    type: null,
    universities: [],
    branches: [],
  },
  languages: [
    {
      language: { id: null, label: null },
      languageLevel: null,
    },
  ],
  positionLocation: {
    expectations: [],
    remote: null,
  },
};

const getFilterParameters = (state) => {
  return {
    text: state.text,
    keywords: state.keywords,
    includeLeads: state.includeLeads,
    isVerifiedByTP: state.isVerifiedByTP,
    totalExperience: state.totalExperience,
    age: state.age,
    skills: toValidSkills(state?.technologies?.expected || []),
    otherExpected: toValidOtherSkills(state?.technologies?.otherExpected || []),
    budget: state.budget || null,
    positionLocation: {
      expectations: (state?.positionLocation?.expectations || []).map(
        (i) => i.value
      ),
      remote: state?.positionLocation?.remote || null,
    },
    education: {
      type: state?.education?.type,
      universities: (state?.education?.universities || []).map((i) => i.value),
      branches: (state?.education?.branches || []).map((i) => i.value),
    },
    languages: toValidLanguages(state.languages || []),
    roles: state.roles ? state.roles.map((i) => i.id) : [],
    livingCities: state.livingCities
      ? state.livingCities.map((i) => i.value)
      : [],
  };
};

const getChangedFilterCount = (queryState) => {
  const queryParams = getFilterParameters(queryState);
  const defaultParams = getFilterParameters(DEFAULT_QUERY_STATE);

  return Object.keys(defaultParams).reduce((total, key) => {
    if (key === "budget") {
      const { min, max } = queryParams[key] || {};
      if (!min && !max) {
        return total;
      }
    }

    if (key === "text") {
      return total;
    }

    if (
      md5(JSON.stringify(defaultParams[key])) ===
      md5(JSON.stringify(queryParams[key]))
    ) {
      return total;
    }

    return total + 1;
  }, 0);
};

export default function Developers() {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [userData, setUserData] = useState();
  const [expand, setExpand] = useState(false);
  const [queryState, setQueryState] = useState(
    JSON.parse(JSON.stringify(DEFAULT_QUERY_STATE))
  );
  const [findMatchedUsers, { loading, data, error }] = useLazyQuery(
    FIND_MATCHED_USERS,
    {
      fetchPolicy: "network-only",
    }
  );
  handleHead("developers");

  const refreshData = async ({ page, limit }) => {
    const { data } = await findMatchedUsers({
      query: FIND_MATCHED_USERS,
      variables: {
        page,
        limit,
        filters: getFilterParameters(queryState),
      },
    });
    setUserData(data?.findMatchedUsers || []);
  };

  const onChange = async (selection, filters, sorter, extra) => {
    if (extra.action === "paginate") {
      const newPagination = {
        ...pagination,
        page: selection.current,
      };
      setPagination(newPagination);
      refreshData(newPagination);
    }
  };

  const handleDrawerClose = () => {
    setExpand(false);
  };

  const handleSearch = async () => {
    setExpand(false);
    const newPagination = {
      ...pagination,
      page: 1,
    };
    setPagination(newPagination);
    refreshData(newPagination);
  };

  const clearFilters = async () => {
    const newQueryState = JSON.parse(JSON.stringify(DEFAULT_QUERY_STATE));
    setQueryState(newQueryState);
  };

  const handleSearchChange = (event) => {
    setQueryState({ ...queryState, text: event.target.value });
  };

  useEffect(() => {
    refreshData(pagination);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.allUser) {
      setUserData(data.allUser);
    }
  }, [data]);

  if (error) return <PartialError />;

  return (
    <div>
      <TgHeader breadCrumbs={[{ title: "People", link: "/developers" }]} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <TgTotalInfo count={userData && userData.totalCount} title="users" />
          <Button
            size="sm"
            outline
            badgeCount={getChangedFilterCount(queryState)}
            onClick={() => setExpand(!expand)}
          >
            Filters
          </Button>
          <SearchLine
            value={queryState.text}
            onChange={handleSearchChange}
            handleSearchClick={handleSearch}
          />
        </div>
      </div>

      {expand && (
        <TgDrawer closable={false} onClose={handleDrawerClose} visible>
          <QueryTitle>Filters</QueryTitle>
          <QueryBox>
            <DeveloperFilterForm
              formState={queryState}
              setFormState={setQueryState}
            />
          </QueryBox>
          <ButtonContainer>
            <Button onClick={handleSearch}>Apply</Button>
            <Button onClick={clearFilters} outline>
              Clear Filters
            </Button>
          </ButtonContainer>
        </TgDrawer>
      )}

      <TgTable
        loading={loading}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={userData && userData.matchedUsers}
        onChange={onChange}
        pagination={{
          pageSize: pagination.limit,
          current: userData && userData.currentPage,
          total: userData && userData.totalCount,
        }}
      />
    </div>
  );
}
