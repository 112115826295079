import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonLink, Button } from "tg-design";
import { GET_ALL_BRANCHES, CREATE_UNIVERSITY_BRANCH } from "../../queries";
import { getQueries } from "./getQueries";
import BranchEdit from "./Edit/index";
import ModalBranches from "../Module/ModalBranches";
import TitleSearch from "../Module/TitleSearch";
import { SORTERS } from "../../../../constants/index";
import { getUrlParams, setQueries } from "../../../../helper";
import EducationHeader from "../Module/header";
import {
  TgDrawer,
  TgTable,
  TgTotalInfo,
  TgButtonField,
} from "../../../tg-styles/Layouts";
import PartialError from "../../../ErrorPage/PartialError";

export default function BranchTable() {
  const [drawerState, setdrawerState] = useState({ visible: false });
  const [selectedId, setSelectedId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [branchData, setBranchData] = useState();
  const [updateQuery, setUpdateQuery] = useState(false);
  const [queryString, setQueryString] = useState("");
  const [tableKey, setTableKey] = useState(0);

  const DEFAULT_LIMIT = 10;

  const [getAllBranches, { loading, data, error }] = useLazyQuery(
    GET_ALL_BRANCHES,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setBranchData(response.allUniversityBranch.universityBranches);
      },
    }
  );

  const [createUniversityBranches] = useMutation(CREATE_UNIVERSITY_BRANCH);

  const createUniversityBranch = async (val) => {
    await createUniversityBranches({ variables: { ...val } });
    if (queryString !== "") {
      setUpdateQuery(true);
    }
  };

  const showDrawer = (id) => {
    setSelectedId(id);
    setdrawerState({
      visible: true,
    });
  };

  const columns = [
    {
      title: "Branches",
      dataIndex: "label",
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <TitleSearch
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          placeHolder="Search Branches"
        />
      ),
      render: (value, record) => (
        <ButtonLink onClick={() => showDrawer(record.id)} color="#1890FF">
          {value}
        </ButtonLink>
      ),
    },
    {
      title: "Label",
      dataIndex: "label",
      align: "center",
      render: (text) => text,
    },
  ];

  const onClose = () => {
    setdrawerState({
      visible: false,
    });
    setUpdateQuery(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getDefaultPage = () => {
    const params = getUrlParams();
    const { page = 1 } = getQueries(params);
    return page;
  };

  const getDefaultLimit = () => {
    const params = getUrlParams();
    const { limit = DEFAULT_LIMIT } = getQueries(params);
    return limit;
  };

  const onChange = (pagination, filters, sorter) => {
    const sort = {
      [sorter.field]: SORTERS[sorter.order],
    };
    const params = getUrlParams();
    setQueryString(
      setQueries(
        Object.assign(params, {
          page: pagination.current,
          limit: pagination.pageSize,
          sort: JSON.stringify(sort),
          ...filters,
        })
      )
    );
  };

  const clearFilters = () => {
    const url = new URL(window.location);
    url.search = "";
    window.history.pushState({}, "", url);
    setQueryString("?");
    setTableKey(tableKey + 1);
  };

  useEffect(() => {
    const params = getUrlParams();
    const { search, page = 1, limit = DEFAULT_LIMIT } = getQueries(params);
    const fetch = async () => {
      getAllBranches({
        variables: { search, limit, page },
      });
    };
    fetch();
    setUpdateQuery(false);
  }, [queryString, updateQuery, getAllBranches]);

  if (error) return <PartialError />;

  return (
    <div>
      <EducationHeader name="branches" />
      {drawerState.visible && (
        <TgDrawer
          closable={false}
          onClose={onClose}
          visible={drawerState.visible}
        >
          <BranchEdit id={selectedId} handleClose={onClose} />
        </TgDrawer>
      )}
      <div className="info-field">
        <TgTotalInfo
          count={data && data.allUniversityBranch.totalCount}
          title="departments"
        />

        <TgButtonField>
          <ButtonLink
            onClick={() => clearFilters()}
            style={{ marginRight: "15px" }}
          >
            Clear Filters
          </ButtonLink>
          <Button onClick={() => showModal()}>Create New Department</Button>
        </TgButtonField>
      </div>
      <TgTable
        key={tableKey}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={branchData || []}
        onChange={onChange}
        loading={loading}
        pagination={{
          pageSize: getDefaultLimit(),
          total: data && data.allUniversityBranch.totalCount,
          defaultCurrent: getDefaultPage(),
        }}
      />
      <ModalBranches
        visible={isModalVisible}
        onCancel={handleCancel}
        handleOk={handleOk}
        createUniversityBranch={createUniversityBranch}
      />
    </div>
  );
}
