import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, FormWrapper, TextField, message } from "tg-design";
import * as Yup from "yup";
import { CREATE_PIN } from "../queries";
import { FormContainer, inputStyles } from "../styles";

const emailValidate = Yup.object().shape({
  email: Yup.string().trim().email().required(),
});

export default function EmailVerifyStep({ email, handleStep }) {
  const [createPin, { loading }] = useMutation(CREATE_PIN);
  const [isReadyToGo, setIsReadyToGo] = useState(
    emailValidate.isValidSync({ email })
  );

  const handleVerify = async (e, data) => {
    try {
      await createPin({
        variables: {
          ...data,
          operation: "FORGOT_PASSWORD_FOR_RECRUITER",
        },
      });
      handleStep(data.email);
    } catch (err) {
      err.graphQLErrors.map((errorMessage) => {
        return message.error(errorMessage.message);
      });
    }
  };

  const handleChange = (e, data) => {
    setIsReadyToGo(emailValidate.isValidSync(data));
  };

  return (
    <FormWrapper onChange={handleChange} onSubmit={handleVerify}>
      <FormContainer>
        <TextField
          name="email"
          label="Email"
          autoFocus
          defaultValue={email}
          style={inputStyles}
        />
        <Button
          type="submit"
          style={{ marginTop: "20px", width: "100%", marginBottom: "20px" }}
          disabled={!isReadyToGo}
          loading={loading}
        >
          Verify Email Address
        </Button>
      </FormContainer>
    </FormWrapper>
  );
}
