import React, { useState, useRef, useCallback } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import ReactCrop from "react-image-crop";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import "react-image-crop/dist/ReactCrop.css";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import Uploader from "../../../common/Uploader";
import { getCroppedImg, getUnsignedUrl } from "../../../../helper";
import { UPLOAD_FILE, DELETE_COVER_IMAGE } from "../../queries";

const CropperContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DEFAULT_CROP_OPTIONS = {
  unit: "px",
  x: 30,
  y: 10,
  width: 300,
  height: 100,
  aspect: 3 / 1,
  aspectRatio: 3,
  minHeight: 145,
};

export default function UploadCoverImage({
  documentId,
  handlePhotoUpload,
  initialPhoto,
  onStarted,
  onError,
}) {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [deleteCoverImage] = useMutation(DELETE_COVER_IMAGE);

  const [uploadedFile, setUploadedFile] = useState(
    initialPhoto
      ? {
          uuid: uuidv4(),
          isUploading: false,
          isDeleting: false,
          url: initialPhoto,
        }
      : null
  );
  const [isModalOpen, setModal] = useState(false);
  const [selectImage, setSelectImage] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [targetImage, setTargetImage] = useState(null);
  const [crop, setCrop] = useState(DEFAULT_CROP_OPTIONS);
  const imgRef = useRef(null);

  const handleDelete = async () => {
    try {
      if (onStarted) {
        onStarted();
      }
      setUploadedFile({
        ...uploadedFile,
        isDeleting: true,
      });
      if (documentId) {
        await deleteCoverImage({
          variables: { id: documentId },
        });
        message.success("Photo deleted");
      }
      handlePhotoUpload(null);
      setUploadedFile(null);
    } catch (error) {
      message.error("Photo deletion failed");
      if (onError) {
        onError();
      }
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const startCropping = (selectedFiles) => {
    setSelectImage(selectedFiles);
    setModal(true);
    const reader = new FileReader();
    reader.addEventListener("load", () => setTargetImage(reader.result));
    reader.readAsDataURL(selectedFiles[0]);
  };

  const onCropComplete = async (cropImage) => {
    if (imgRef && cropImage.width && cropImage.height) {
      const croppedImage = await getCroppedImg(
        imgRef.current,
        cropImage,
        selectImage[0].name
      );
      setCompletedCrop(croppedImage);
    }
  };

  const handleCropperClose = () => {
    setModal(false);
    setSelectImage(null);
    setCompletedCrop(null);
    setTargetImage(null);
    imgRef.current = null;
    setCrop(DEFAULT_CROP_OPTIONS);
  };

  const handleCroppedImage = async (e) => {
    e.preventDefault();
    setModal(false);

    try {
      if (onStarted) {
        onStarted();
      }
      const file = {
        uuid: uuidv4(),
        name: selectImage[0].name,
        isUploading: true,
      };
      setUploadedFile(file);
      const response = await uploadFile({
        variables: { file: completedCrop },
      });

      setUploadedFile({
        ...file,
        isUploading: false,
        url: response.data.uploadDocument.fileURL,
      });
      handlePhotoUpload(getUnsignedUrl(response.data.uploadDocument.fileURL));
    } catch (error) {
      message.error("Photo upload failed");
      if (onError) {
        onError();
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal handleClose={handleCropperClose}>
          <ModalHeader>Crop the image</ModalHeader>
          <ModalBody>
            <CropperContainer>
              <ReactCrop
                src={targetImage}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onImageLoaded={onLoad}
                onComplete={onCropComplete}
              />
            </CropperContainer>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCroppedImage}>Crop & Save</Button>
          </ModalFooter>
        </Modal>
      )}
      <Uploader
        accept=".jpeg,.png,.jpg"
        onSelection={startCropping}
        onDelete={handleDelete}
        files={uploadedFile ? [uploadedFile] : []}
        width={
          DEFAULT_CROP_OPTIONS.minHeight * DEFAULT_CROP_OPTIONS.aspectRatio
        }
      />
    </>
  );
}
