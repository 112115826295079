import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Form, Input, Spin, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "tg-design";
import { GET_BRANCH, UPDATE_BRANCH } from "../../../queries";
import { TgDrawerTitle } from "../../../../tg-styles/Layouts";
import layout from "../../../layout";
import { VALIDATION_MESSAGES } from "../../../../../constants";
import { captureErrorWithData } from "../../../../../helper";
import PartialError from "../../../../ErrorPage/PartialError";

export default function BranchEdit({ id, handleClose }) {
  const branchId = id;
  const { loading, error, data, refetch } = useQuery(GET_BRANCH, {
    variables: { id: branchId },
  });

  const [updateBranch] = useMutation(UPDATE_BRANCH);

  if (loading) return <Spin />;
  if (error) return <PartialError />;

  const handleSubmit = async (values) => {
    try {
      const variables = {
        id: branchId,
        ...values,
      };

      await updateBranch({ variables });
      await refetch();
      message.success("Branch Updated");
    } catch (err) {
      captureErrorWithData(err);
      message.error(err.message || err);
    }
  };

  return (
    <>
      <TgDrawerTitle title="Edit Department" handleClose={handleClose} />
      <Form
        {...layout}
        name="role-edit"
        onFinish={handleSubmit}
        validateMessages={VALIDATION_MESSAGES}
        initialValues={data.universityBranch}
      >
        <Form.Item
          name="label"
          label="Branch Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Branch Name" />
        </Form.Item>

        <Form.List name="alternativeTexts">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    required={false}
                    key={index}
                    label="Alternative Names"
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Name"
                        addonAfter={
                          fields.length >= 1 ? (
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null
                        }
                      />
                    </Form.Item>
                  </Form.Item>
                ))}
                <Form.Item label=" ">
                  <Button
                    variant="text"
                    dashed
                    size="xs"
                    block
                    style={{
                      color: "#7F7F7F",
                    }}
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Add Alternative Name
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.Item>
          <Button type="submit" block size="xs">
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
