/* eslint-disable */
import React, { useState } from "react";
import VerifiedTechnologies from "./Modules/Verified";
import BacklogTechnologies from "./Modules/Backlog";
import { setQueries } from "../../../helper";
import { TgHeader } from "../../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../../tg-styles/TgHeaderTabs";

export default function TechnologyManagement() {
  const [activeKey, setActiveKey] = useState(1);
  const handleTabChange = (key) => {
    setActiveKey(key);
    setQueries({});
  };

  return (
    <>
      <TgHeader
        breadCrumbs={[
          { title: "Tools", link: "/tools" },
          {
            title: "Technologies",
            link: "/tools/technologies",
          },
        ]}
      >
        <TgHeaderTabs>
          <TgHeaderTab
            name="backlog"
            onClick={() => {
              handleTabChange(1);
            }}
            classname={activeKey == 1 && "active"}
          >
            Backlog (Unverified) Technologies
          </TgHeaderTab>
          <TgHeaderTab
            name="verified"
            onClick={() => {
              handleTabChange(2);
            }}
            classname={activeKey == 2 && "active"}
          >
            Verified Technologies
          </TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>

      <div>
        {activeKey == 1 && <BacklogTechnologies />}
        {activeKey == 2 && <VerifiedTechnologies />}
      </div>
    </>
  );
}
