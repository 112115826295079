import React, { createContext, useState } from "react";
import { io } from "socket.io-client";

const WebSocketContext = createContext(null);

export { WebSocketContext };

const isLoggedIn = () => {
  return localStorage.getItem("isAuth") || false;
};

export default ({ children }) => {
  const [socket, setSocket] = useState(null);

  const getSocket = () => {
    const url = process.env.REACT_APP_WS_URL.replace("/subs", "");
    const socket = io(url, {
      path: "/socket/",
      transports: ["websocket", "polling"],
    });

    socket.on("connect_error", () => {
      const timeout = setTimeout(() => {
        socket.connect();
        clearTimeout(timeout);
      }, 3000);
    });

    return socket;
  };

  const boot = () => {
    if (isLoggedIn()) {
      setSocket(getSocket());
    }
  };

  if (!socket) {
    boot();
  }

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        boot,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
