import React from "react";
import styled from "styled-components";

const DataTextRow = styled.div`
  margin-bottom: 5px;
`;

const DataTextTitle = styled.span`
  font-weight: 500;
  padding-right: 5px;
`;

export default function DataText({ title, children }) {
  return (
    <DataTextRow>
      <DataTextTitle>{title}:</DataTextTitle>
      {children}
    </DataTextRow>
  );
}
