import React from "react";
import styled from "styled-components";
import { EditIcon, NotesIcon } from "../../../Icons";

import Avatar from "../../../Avatar";
import NoteType from "./NoteType";

export const Description = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  margin-bottom: 20px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: ${({ size }) => size || "60px"};
  height: ${({ size }) => size || "60px"};
  background: #e5e5e5;
  border-radius: 5px;
`;

export const EditIconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  width: ${({ size }) => size || "60px"};
  height: ${({ size }) => size || "60px"};
  background: ${({ background }) => (!background ? "none" : "#e5e5e5")};
  border-radius: 5px;
`;
export const Container = styled.div`
  margin-left: 20px;
  width: 100%;
`;

export const InterviewNotesContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const InterviewNotes = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: rgb(17, 24, 39);
`;

export const ResponsiveInterviewNotes = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: rgb(17, 24, 39);
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const InterviewNotesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-beetween;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

export const ResponsiveInterviewNotesContainer = styled.div`
  display: none;
  margin-top: 40px;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const AvatarContainer = styled.div`
  margin-right: 11px;
`;

const NameContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

const RoleContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #9ca3af;
`;

const OwnerInformation = styled.div``;

export const ResponsiveView = styled.div``;

const NoteHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NoteTypeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  justify-content: flex-end;
`;

export const NoteHeader = ({
  name,
  avatarURL,
  role,
  companyName,
  avatarSize,
  noteType,
}) => {
  return (
    <NoteHeaderContainer>
      <AvatarContainer>
        <Avatar url={avatarURL || null} size={avatarSize} />
      </AvatarContainer>

      <OwnerInformation>
        <NameContainer>{name || "Missing Name"}</NameContainer>
        {role && <RoleContainer>{`${role}, ${companyName}`}</RoleContainer>}
      </OwnerInformation>

      {noteType && (
        <NoteTypeContainer>
          <NoteType typeKey={noteType} showSelect={false} />
        </NoteTypeContainer>
      )}
    </NoteHeaderContainer>
  );
};

export default function NoteSection({
  note,
  companyName,
  handleEdit,
  assignee,
}) {
  return (
    <>
      <NoteHeader
        name={assignee?.name}
        avatarURL={assignee?.avatarURL}
        role={note.creator.role}
        companyName={companyName}
        avatarSize="50px"
        noteType={note?.type}
      />
      <InterviewNotesContainer>
        <IconContainer>
          <NotesIcon />
        </IconContainer>
        <Container>
          <InterviewNotesHeader>
            <div>Notes from the interview:</div>

            {note.creatorType === 1 && (
              <EditIconContainer size="25px" onClick={handleEdit}>
                <EditIcon />
              </EditIconContainer>
            )}
          </InterviewNotesHeader>
          <InterviewNotes
            className="richText"
            dangerouslySetInnerHTML={{
              __html: note.note || "-",
            }}
          />
        </Container>
      </InterviewNotesContainer>
    </>
  );
}
