import React from "react";
import styled from "styled-components";
import { InfoIcon } from "../../../../../Icons";

const Container = styled.div`
  margin-top: 10px;
  background: #f6f6f6;
  padding: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  & svg {
    margin-right: 10px;
  }
`;

const Text = styled.p`
  margin: 0px;
  color: #9ca3af;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 5px;
`;

export default function EmptyData() {
  return (
    <Container>
      <InfoIcon />
      <Text>There are no items to display.</Text>
    </Container>
  );
}
