import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Tag, Alert } from "antd";
import { Button } from "tg-design";
import { POSITION_STATES } from "../../constants";
import { handleHead, setCookie, getCookie } from "../../helper";
import { GET_RECRUITER_WITH_SUMMARY } from "./queries";
import { TgPositionCard, TgHeader, TgCardWrapper } from "../tg-styles/Layouts";
import PartialError from "../ErrorPage/PartialError";

export default function Home() {
  const [recruiterData, setRecruiterData] = useState();
  const [showMessage, setShowMessage] = useState({
    talentPartner: !getCookie("talent-partner-summary-description-close"),
    clientPartner: !getCookie("client-partner-summary-description-close"),
  });
  const { loading, error } = useQuery(GET_RECRUITER_WITH_SUMMARY, {
    onCompleted: (data) => {
      setRecruiterData(data.recruiter);
    },
    fetchPolicy: "network-only",
  });
  handleHead("home");

  if (loading) return "loading";
  if (error) return <PartialError />;

  const closeMessage = (role) => {
    if (role === "talent-partner") {
      setCookie("talent-partner-summary-description-close", true);
      setShowMessage({ ...showMessage, talentPartner: false });
    }
    if (role === "client-partner") {
      setCookie("client-partner-summary-description-close", true);
      setShowMessage({ ...showMessage, clientPartner: false });
    }
  };

  const TalentPartnerMessage = () => {
    return (
      <span>
        Showing only the positions that are in{" "}
        <Tag color="orange">IN_PROGRESS</Tag>state and assigned to you.
      </span>
    );
  };

  const ClientPartnerMessage = () => {
    return (
      <span>
        Showing only the positions that are in <Tag color="blue">CREATED</Tag>
        <Tag color="red">AWAITING_APPROVAL</Tag>and{" "}
        <Tag color="orange">IN_PROGRESS</Tag>state, assigned to you.
      </span>
    );
  };

  const ClientPartnerSummary = ({ data = [] }) => {
    const createdPositions = data.filter(
      (i) => i.state === POSITION_STATES.CREATED
    );
    const submittedPositions = data.filter(
      (i) => i.state === POSITION_STATES.SUBMITTED
    );
    const inProgressPositions = data.filter(
      (i) => i.state === POSITION_STATES.IN_PROGRESS
    );

    return (
      <>
        {createdPositions.map((item, index) => (
          <TgPositionCard key={index} value={item} />
        ))}
        {createdPositions.length > 0 && <hr width="100%" />}
        {submittedPositions.map((item, index) => (
          <TgPositionCard key={index} value={item} />
        ))}
        {submittedPositions.length > 0 && <hr width="100%" />}
        {inProgressPositions.map((item, index) => (
          <TgPositionCard key={index} value={item} />
        ))}
      </>
    );
  };

  return (
    <>
      {recruiterData && (
        <>
          <TgHeader title={`Welcome, ${recruiterData.name} `} />

          <div style={{ marginLeft: "17.5px" }}>
            <span style={{ fontWeight: "600", fontSize: "22px" }}>
              Here are the latest status of the positions you’re working on as{" "}
              <b>Talent Partner</b>
            </span>
          </div>
          <TgCardWrapper>
            {recruiterData.talentPartnerSummary.length > 0 ? (
              recruiterData.talentPartnerSummary.map((item, index) => (
                <TgPositionCard key={index} value={item} />
              ))
            ) : (
              <p style={{ margin: "20px 0 10px 17.5px" }}>
                &quot;There are no positions assigned to you at this
                moment.&quot;
              </p>
            )}
          </TgCardWrapper>

          {showMessage?.talentPartner ? (
            <Alert
              message={TalentPartnerMessage()}
              type="info"
              action={
                <Button
                  onClick={() => closeMessage("talent-partner")}
                  outline
                  size="xs"
                >
                  Close
                </Button>
              }
            />
          ) : (
            <hr />
          )}
          <div style={{ marginLeft: "17.5px", marginTop: "20px" }}>
            <span style={{ fontWeight: "600", fontSize: "22px" }}>
              Here are the open positions for your account as
              <b> Client Partner</b>
            </span>
          </div>
          <TgCardWrapper>
            {recruiterData.clientPartnerSummary.length > 0 ? (
              <ClientPartnerSummary data={recruiterData.clientPartnerSummary} />
            ) : (
              <p style={{ margin: "20px 0 10px 17.5px" }}>
                &quot;There are no positions assigned to you at this
                moment.&quot;
              </p>
            )}
          </TgCardWrapper>
          {showMessage?.clientPartner && (
            <Alert
              message={ClientPartnerMessage()}
              type="info"
              action={
                <Button
                  onClick={() => closeMessage("client-partner")}
                  outline
                  size="xs"
                >
                  Close
                </Button>
              }
            />
          )}
        </>
      )}
    </>
  );
}
