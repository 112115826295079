import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, message, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "tg-design";
import { CREATE_TECHNOLOGY } from "../../queries";
import {
  TECHNOLOGY_CATEGORIES,
  VALIDATION_MESSAGES,
} from "../../../../constants";
import { TgDrawerTitle } from "../../../tg-styles/Layouts";
import { captureErrorWithData } from "../../../../helper";

export default function Technology({ refetch, handleDrawerClose }) {
  const [createTechnology] = useMutation(CREATE_TECHNOLOGY, {
    onCompleted: (data) => {
      message.success(`${data.createTechnologyByAdmin.title} was created.`);
      refetch();
      handleDrawerClose();
    },
  });

  const handleSubmit = async (values) => {
    try {
      const variables = {
        verified: false,
        creatorToken: null,
        creator: null,
        ...values,
      };
      await createTechnology({ variables });
    } catch (error) {
      captureErrorWithData(error);
      message.error("Something went wrong");
    }
  };

  return (
    <>
      <TgDrawerTitle title="Create Technology" />
      <Form
        name="technologyCreate"
        onFinish={handleSubmit}
        validateMessages={VALIDATION_MESSAGES}
      >
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input placeholder="title" />
        </Form.Item>
        <Form.Item name="categoryId" label="Category">
          <Select
            showSearch
            allowClear
            placeholder="Select Category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {TECHNOLOGY_CATEGORIES.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.List name="alternativeTexts">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field) => (
                  <Form.Item
                    required={false}
                    key={field.key}
                    label="Alternative Name"
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Name"
                        addonAfter={
                          fields.length >= 1 ? (
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null
                        }
                      />
                    </Form.Item>
                  </Form.Item>
                ))}
                <Form.Item label={<PlusOutlined />}>
                  <Button
                    variant="text"
                    dashed
                    size="sm"
                    block
                    onClick={() => {
                      add();
                    }}
                    style={{
                      color: "#7F7F7F",
                    }}
                  >
                    Add Alternative Name
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.Item>
          <Button type="submit" block>
            Create
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
