import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";
import { Switch, message } from "antd";
import FormScaffold from "../../../../Form/FormScaffold";
import TextField from "../../../../Form/TextField";
import { AsyncSelectField, SelectField } from "../../../../Form/SelectField";
import { debounce } from "../../../../../helper";
import {
  SEARCH_UNIVERSITY,
  SEARCH_BRANCH,
  ALL_LANGUAGES,
  GET_ROLES,
} from "../../../queries";
import { GET_PLACES } from "../../../../../queries/general";
import {
  BUDGET_PERIODS,
  CURRENCIES,
  EDUCATION_TYPES,
  INCOME_OPTIONS,
  REMOTE_OPTIONS_FOR_COMPANY,
} from "../../../../../constants";
import SkillGroup from "../../../module/SkillGroup";
import LanguageGroup from "../../../module/LanguageGroup";
import QuickUniversitySelection from "../../../module/QuickUniversitySelection";
import QuickBranchSelection from "../../../module/QuickBranchSelection";
import { ColumnBlock, RowBlock } from "../../../../Form/BlockUi";
import { InlineInputs } from "../../../../Form/FormUi";
import {
  BlockCheckboxField,
  FlatCheckboxField,
} from "../../../../Form/CheckboxField";
import NumericField from "../../../../Form/NumericField";
import PartialError from "../../../../ErrorPage/PartialError";
import OtherSkillGroup from "../../../module/OtherSkillGroup";

const DEFAULT_OPTIONS = {
  showStatus: false,
};

export default function DeveloperFilterForm({
  formState,
  setFormState,
  controls,
  setControls = () => {},
}) {
  const { data: roleResult } = useQuery(GET_ROLES);

  const { refetch: searchPlaces } = useQuery(GET_PLACES, {
    skip: true,
  });
  const { refetch: universityRefetch } = useQuery(SEARCH_UNIVERSITY, {
    skip: true,
  });
  const { refetch: branchRefetch } = useQuery(SEARCH_BRANCH, {
    skip: true,
  });
  const {
    data: langData,
    loading,
    error: langError,
  } = useQuery(ALL_LANGUAGES, {
    variables: { limit: 300 },
  });

  const applyChange = ({ changes }) => {
    setFormState({
      ...formState,
      ...changes,
    });
    setControls({
      ...controls,
      isCriteriaChanged: true,
    });
  };

  const handleNumericCriteriaChange = (
    motherField,
    childField,
    event = null
  ) => {
    const value = event?.target ? parseInt(event?.target?.value) : event;
    applyChange({
      changes: {
        [motherField]: { ...formState[motherField], [childField]: value },
      },
    });
  };

  const handleMultiSelectChange = (motherField, childField, values) => {
    if (!formState.technologies.expected && motherField === "technologies") {
      formState.technologies.expected = [];
    }

    applyChange({
      changes: {
        [motherField]: { ...formState[motherField], [childField]: values },
      },
    });
  };

  const handleBudgetOptionChange = (e) => {
    applyChange({
      changes: {
        budget: { ...formState.budget, [e.key]: e.checked },
      },
    });
  };

  const handleSelectChange = (motherField, childField, event) => {
    let value;

    if (event === null) {
      value = null;
    } else if (event.value === 0) {
      value = 0;
    } else if (event.value !== false) {
      value = event ? event.value || event.id : null;
    } else {
      value = event.value;
    }

    applyChange({
      changes: {
        [motherField]: { ...formState[motherField], [childField]: value },
      },
    });
  };

  const handlePlaceSearch = useCallback(
    debounce(async (search, callback) => {
      try {
        const { data } = await searchPlaces({
          search,
          showCountries: true,
        });
        callback(
          data.allPlaces.places.map((i) => {
            const label =
              i.capital === "country" ? i.city : `${i.city}, ${i.country}`;
            return { label, value: i.id };
          })
        );
      } catch (err) {
        message.error("Something went wrong");
      }
    }, 500),
    []
  );

  const handleTextFieldChange = (field, event) => {
    const value = event?.target?.value ? event.target.value : null;
    applyChange({
      changes: {
        [field]: value,
      },
    });
  };

  const handleUniversitySearch = useCallback(
    debounce(async (val, callback) => {
      // refetch because of useQuery and useLazyQuery doesnt return a promise
      try {
        const { data } = await universityRefetch({
          search: val,
        });
        callback(
          data.allUniversity.universities.map((i) => {
            return { label: i.name, value: i.id };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleBranchSearch = useCallback(
    debounce(async (val, callback) => {
      // refetch because of useQuery and useLazyQuery doesnt return a promise
      try {
        const { data } = await branchRefetch({
          search: val,
        });

        callback(
          data.allUniversityBranch.universityBranches.map((i) => {
            return { label: i?.label, value: i.id };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleQuickUniversitySelection = (universities) => {
    applyChange({
      changes: {
        education: {
          ...formState.education,
          universities: [...universities],
        },
      },
    });
  };

  const handleQuickBranchSelection = (branches) => {
    applyChange({
      changes: {
        education: {
          ...formState.education,
          branches: [...branches],
        },
      },
    });
  };

  const handleRemoteChange = (event, option) => {
    const { checked } = event.target;
    const { remote } = formState?.positionLocation || {};
    let values = remote ? remote.split(",") : [];

    if (checked) {
      values.push(option.id);
    } else {
      values = values.filter((item) => item !== option.id);
    }

    const changes = {
      positionLocation: {
        ...formState.positionLocation,
        remote: values.join(","),
        expectations:
          values.includes("half") || values.includes("none")
            ? formState.positionLocation.expectations
            : [],
      },
    };

    applyChange({ changes });
  };

  const handleSkillChange = (skills) => {
    applyChange({
      changes: {
        technologies: {
          ...formState.technologies,
          expected: skills,
        },
      },
    });
  };

  const handleOtherSkillChange = (skills) => {
    applyChange({
      field: "technologies",
      changes: {
        technologies: {
          ...formState.technologies,
          otherExpected: skills,
        },
      },
    });
  };

  if (loading) return "loading";
  if (langError) return <PartialError />;

  return (
    <>
      <FormScaffold
        options={DEFAULT_OPTIONS}
        label={
          <>
            <span style={{ marginRight: "15px" }}>Include leads?</span>
            <Switch
              onChange={(value) =>
                applyChange({ changes: { includeLeads: value } })
              }
              checked={formState.includeLeads}
            />
          </>
        }
      />
      <FormScaffold
        options={DEFAULT_OPTIONS}
        label={
          <>
            <span style={{ marginRight: "15px" }}>Verified By TP?</span>
            <Switch
              onChange={(value) =>
                applyChange({ changes: { isVerifiedByTP: value } })
              }
              checked={formState.isVerifiedByTP}
            />
          </>
        }
      />
      <RowBlock mb={2}>
        <SelectField
          name="role"
          label="Role"
          isMulti
          isClearable
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.id}
          options={roleResult?.allRole?.roles || []}
          onChange={(event) => applyChange({ changes: { roles: event } })}
          value={formState.roles}
          closeMenuOnSelect={false}
        />
      </RowBlock>
      <FormScaffold label="Total Experience" options={DEFAULT_OPTIONS}>
        <TextField
          type="number"
          placeholder="min"
          name="experience-min"
          defaultValue={formState.totalExperience.min}
          value={formState.totalExperience.min}
          onChange={(event) =>
            handleNumericCriteriaChange("totalExperience", "min", event)
          }
          style={{ marginBottom: "10px" }}
        />
        <TextField
          type="number"
          placeholder="max"
          name="experience-max"
          defaultValue={formState.totalExperience.max}
          value={formState.totalExperience.max}
          onChange={(event) =>
            handleNumericCriteriaChange("totalExperience", "max", event)
          }
        />
      </FormScaffold>
      <FormScaffold label="Must Have Skills" options={DEFAULT_OPTIONS}>
        <SkillGroup
          state={formState.technologies.expected}
          setState={handleSkillChange}
        />
      </FormScaffold>
      <FormScaffold label="Other Required Skills" options={DEFAULT_OPTIONS}>
        <OtherSkillGroup
          state={formState.technologies.otherExpected}
          setState={handleOtherSkillChange}
        />
      </FormScaffold>
      <FormScaffold label="Age Range" options={DEFAULT_OPTIONS}>
        <TextField
          type="number"
          name="age-min"
          placeholder="min"
          defaultValue={formState.age.min}
          value={formState.age.min}
          onChange={(event) => handleNumericCriteriaChange("age", "min", event)}
          style={{ marginBottom: "10px" }}
        />
        <TextField
          type="number"
          placeholder="max"
          name="age-max"
          defaultValue={formState.age.max}
          value={formState.age.max}
          onChange={(event) => handleNumericCriteriaChange("age", "max", event)}
        />
      </FormScaffold>
      <FormScaffold
        label="Budget"
        explanation="Enter the gross or net salary range that will be used for matching."
        options={DEFAULT_OPTIONS}
      >
        <InlineInputs>
          <FlatCheckboxField
            onChange={handleBudgetOptionChange}
            icon={false}
            name="income"
            defaultValue={formState?.budget?.income}
            value={formState?.budget?.income}
            options={INCOME_OPTIONS}
            checked
            mr={2}
            width="60%"
          />
          <FlatCheckboxField
            onChange={handleBudgetOptionChange}
            icon={false}
            name="period"
            defaultValue={formState.budget.period}
            value={formState.budget.period}
            options={BUDGET_PERIODS}
            checked
            width="60%"
          />
        </InlineInputs>
        <InlineInputs mb={2}>
          <NumericField
            type="number"
            name="budget-min"
            placeholder="Min"
            defaultValue={formState.budget.min}
            value={formState.budget.min}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleNumericCriteriaChange("budget", "min", event)
            }
          />
          <NumericField
            type="number"
            name="budget-max"
            placeholder="Max"
            defaultValue={formState.budget.max}
            value={formState.budget.max}
            style={{ width: "100%" }}
            onChange={(event) =>
              handleNumericCriteriaChange("budget", "max", event)
            }
          />
          <SelectField
            name="budget-currency"
            placeholder="Currency"
            defaultValue={{
              label: formState.budget.currency || {
                label: "USD",
                value: "USD",
              },
            }}
            value={{
              label: formState.budget.currency,
            }}
            options={CURRENCIES}
            onChange={(event) =>
              handleSelectChange("budget", "currency", event)
            }
          />
        </InlineInputs>
      </FormScaffold>
      <FormScaffold
        label="Search in TG Meeting Notes"
        explanation={
          <>
            If there is more than one keyword, the input should be in this
            format: <b>mongoDB,spring framework</b>
          </>
        }
        options={DEFAULT_OPTIONS}
      >
        <TextField
          name="keywords"
          placeholder="keywords"
          defaultValue={formState.keywords}
          value={formState.keywords}
          onChange={(event) => handleTextFieldChange("keywords", event)}
          style={{ marginBottom: "10px" }}
        />
      </FormScaffold>
      <FormScaffold
        label="Education"
        styles={{ body: { ml: 4, my: 2 } }}
        options={DEFAULT_OPTIONS}
      >
        <SelectField
          name="education"
          label="Min Education"
          isClearable
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={EDUCATION_TYPES.find(
            (i) => i.value === formState.education.type
          )}
          options={EDUCATION_TYPES}
          onChange={(event) => handleSelectChange("education", "type", event)}
        />
        <RowBlock alignItems="flex-end" mt={3}>
          <ColumnBlock width="80%">
            <AsyncSelectField
              isMulti
              label="University"
              placeholder="Select University"
              loadOptions={handleUniversitySearch}
              onChange={(event) =>
                handleMultiSelectChange("education", "universities", event)
              }
              value={formState.education.universities}
            />
          </ColumnBlock>
          <ColumnBlock pl={2} width="20%">
            <QuickUniversitySelection
              selectGroup={handleQuickUniversitySelection}
            />
          </ColumnBlock>
        </RowBlock>

        <RowBlock alignItems="flex-end" mt={3}>
          <ColumnBlock width="80%">
            <AsyncSelectField
              isMulti
              label="Branch"
              placeholder="Select Department"
              loadOptions={handleBranchSearch}
              onChange={(event) =>
                handleMultiSelectChange("education", "branches", event)
              }
              value={formState.education.branches}
            />
          </ColumnBlock>
          <ColumnBlock pl={2} width="20%">
            <QuickBranchSelection selectGroup={handleQuickBranchSelection} />
          </ColumnBlock>
        </RowBlock>
      </FormScaffold>
      <LanguageGroup
        languageData={langData.allLanguages.languages}
        getItemStatus={() => {}}
        state={formState.languages}
        setState={(languages) => applyChange({ changes: { languages } })}
      />
      <FormScaffold label="Position Location" options={DEFAULT_OPTIONS}>
        <RowBlock>
          <ColumnBlock width="100%">
            {REMOTE_OPTIONS_FOR_COMPANY.map((option, index) => {
              return (
                <BlockCheckboxField
                  label={option.title}
                  key={index}
                  id={option.id}
                  name={option.id}
                  onChange={(event) => handleRemoteChange(event, option)}
                  checked={formState?.positionLocation?.remote?.includes(
                    option.id
                  )}
                  icon
                  checkboxStyles={{
                    height: "14px",
                    width: "14px",
                    margin: "0 10px 0 0",
                  }}
                />
              );
            })}
          </ColumnBlock>
        </RowBlock>
        {(formState?.positionLocation?.remote?.includes("half") ||
          formState?.positionLocation?.remote?.includes("none")) && (
          <RowBlock mt={2}>
            <ColumnBlock width="100%">
              <AsyncSelectField
                isMulti
                label="Place"
                placeholder="Select multiple places"
                loadOptions={handlePlaceSearch}
                onChange={(event) =>
                  handleMultiSelectChange(
                    "positionLocation",
                    "expectations",
                    event
                  )
                }
                value={formState?.positionLocation?.expectations || []}
              />
            </ColumnBlock>
          </RowBlock>
        )}
      </FormScaffold>
      <RowBlock mb={2}>
        <ColumnBlock width="100%">
          <AsyncSelectField
            isMulti
            label="Living Cities"
            placeholder="Select multiple places"
            loadOptions={handlePlaceSearch}
            onChange={(event) =>
              applyChange({ changes: { livingCities: event } })
            }
            value={formState?.livingCities || []}
          />
        </ColumnBlock>
      </RowBlock>
    </>
  );
}
