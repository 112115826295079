import React from "react";
import { Form, message, Radio, Select, Spin } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "tg-design";
import {
  ADD_EXISTING_EMPLOYER,
  GET_COMPANY,
  GET_EXISTING_EMPLOYERS,
} from "../queries";
import PartialError from "../../ErrorPage/PartialError";
import { EMPLOYER_TYPES } from "../../../constants";

const ExistingContactModal = ({ setIsExistingModalVisible }) => {
  const [form] = Form.useForm();
  const { companyId } = useParams();

  const [addExistingEmployer] = useMutation(ADD_EXISTING_EMPLOYER, {
    refetchQueries: [
      {
        query: GET_COMPANY,
        variables: { id: companyId },
      },
      {
        query: GET_EXISTING_EMPLOYERS,
        variables: { companyId },
      },
    ],
  });
  const { data, loading, error } = useQuery(GET_EXISTING_EMPLOYERS, {
    variables: {
      companyId,
    },
  });

  const handleAddExistingContact = async () => {
    try {
      const employerId = form.getFieldValue("existingContact");
      const accessType = form.getFieldValue("accessType");
      await addExistingEmployer({
        variables: {
          employerId,
          companyId,
          accessType,
        },
      });
      setIsExistingModalVisible(false);
    } catch (e) {
      message.error(e.message);
    }
  };

  return (
    <Modal handleClose={() => setIsExistingModalVisible(false)}>
      <ModalHeader>Add existing employer</ModalHeader>
      <ModalBody>
        <Form form={form} layout="vertical" name="existingContactForm">
          {error && <PartialError />}
          {loading && <Spin />}
          <Form.Item
            name="existingContact"
            label="Existing Contact"
            rules={[
              {
                required: true,
                message: "Please select an existing contact",
              },
            ]}
          >
            <Select
              loading={loading}
              showSearch
              placeholder="Select existing employer"
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {data?.existingEmployers?.map((employer) => (
                <Select.Option key={employer.id} value={employer.id}>
                  {employer.fullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="accessType" label="Access Type">
            <Radio.Group>
              <Radio.Button value={EMPLOYER_TYPES.ADMIN}>Admin</Radio.Button>
              <Radio.Button value={EMPLOYER_TYPES.MEMBER}>Member</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={handleAddExistingContact}>
          Save
        </Button>
        <Button
          onClick={() => setIsExistingModalVisible(false)}
          outline
          size="sm"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { ExistingContactModal };
