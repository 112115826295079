import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, Select, InputNumber, Radio, message } from "antd";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "tg-design";
import TextArea from "antd/lib/input/TextArea";
import { useQuery } from "@apollo/client";
import { useResetFormOnCloseModal } from "./useResetForm";
import { captureErrorWithData, debounce } from "../../../helper";
import { ISTANBUL_PLACE_ID, IstanbulRegions } from "../../../constants/index";
import { GET_CITIES } from "../../developers/queries";

const { Option } = Select;

export const ModalOfficeLocation = ({
  visible,
  onCancel,
  selectedValue,
  updateLocation,
  createLocation,
}) => {
  const [form] = Form.useForm();
  const [isCitySearching, setIsCitySearching] = useState(false);
  const [showIstanbulRegions, setShowIstanbulRegions] = useState(false);
  const [filteredCities, setFilteredCities] = useState(
    selectedValue && selectedValue.place ? [selectedValue.place] : []
  );

  const { refetch: searchCities } = useQuery(GET_CITIES, {
    skip: true,
  });

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = async () => {
    const currentValue = form.getFieldsValue();
    if (!currentValue.title) {
      message.error("Title can NOT be empty");
    } else if (!currentValue.place) {
      message.error("City can NOT be empty");
    } else if (
      currentValue.place === ISTANBUL_PLACE_ID &&
      !currentValue.istanbulRegion
    ) {
      message.error("Region can NOT be empty");
    } else {
      try {
        if (selectedValue) {
          await updateLocation({ ...currentValue, id: selectedValue.id });
          onCancel();
        } else {
          await createLocation(currentValue);
          onCancel();
        }

        message.success("Location updated");
      } catch (error) {
        captureErrorWithData(error);
        message.error("Something went wrong");
      }
    }
  };

  const startCitySearching = useCallback(
    debounce(async (search) => {
      try {
        const { data } = await searchCities({
          search,
        });
        setFilteredCities(data.allPlaces.places);
        setIsCitySearching(false);
      } catch (err) {
        message.error("Something went wrong");
      }
    }, 500),
    []
  );

  const onSearchCity = (search) => {
    setIsCitySearching(true);
    startCitySearching(search);
  };

  const selectCity = (selectedCity) => {
    if (selectedCity === ISTANBUL_PLACE_ID) {
      setShowIstanbulRegions(true);
    } else {
      setShowIstanbulRegions(false);
    }
  };

  useEffect(() => {
    setFilteredCities(
      selectedValue && selectedValue.place ? [selectedValue.place] : []
    );
    if (
      selectedValue &&
      selectedValue.place &&
      selectedValue.place.id === ISTANBUL_PLACE_ID
    ) {
      setShowIstanbulRegions(true);
    }
  }, [selectedValue]);

  return (
    <>
      {visible && (
        <Modal handleClose={onCancel}>
          <ModalHeader>
            {selectedValue ? "Update Location" : "Add New Location"}
          </ModalHeader>
          <ModalBody>
            <Form
              form={form}
              layout="vertical"
              name="locationForm"
              initialValues={
                selectedValue
                  ? {
                      ...selectedValue,
                      place: selectedValue.place
                        ? selectedValue.place.id
                        : null,
                    }
                  : {}
              }
            >
              <Form.Item
                name="title"
                label="Address Title"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="place"
                label="Select City"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search"
                  optionFilterProp="children"
                  loading={isCitySearching}
                  onSearch={onSearchCity}
                  onSelect={selectCity}
                >
                  {filteredCities.map((item) => (
                    <Option
                      key={item.id}
                      value={item.id}
                      style={{ zIndex: "999999" }}
                    >
                      {item.city}, {item.country}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {showIstanbulRegions && (
                <Form.Item
                  name="istanbulRegion"
                  label="Which Side?"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {IstanbulRegions.map((item) => (
                      <Option key={item.id} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item name={["location", "coordinates", 1]} label="Latitude">
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name={["location", "coordinates", 0]}
                label="Longitude"
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="fullAddress" label="Full Address">
                <TextArea />
              </Form.Item>
              <Form.Item label="Office Type" name="headquarter">
                <Radio.Group>
                  <Radio.Button value>Headquarter</Radio.Button>
                  <Radio.Button value={false}>Registered</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onOk}>
              Save
            </Button>
            <Button onClick={onCancel} outline size="sm">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
