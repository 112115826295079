import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  height: 100%;
`;

export default function TgKanbanSubColumnContainer({ children }) {
  return <Container>{children}</Container>;
}
