import { useState } from "react";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { message } from "antd";
import localeData from "dayjs/plugin/localeData";
import {
  REMOTE_OPTIONS_FOR_DEVELOPER,
  REMOTE_OPTIONS_FOR_COMPANY,
  POSITION_STATES,
  HEAD_DATA,
  EDUCATION_TYPES,
  LANGUAGE_LEVEL_TYPES,
  CURRENT_YEAR,
  ERROR_TYPES,
} from "./constants";

dayjs.extend(localeData);

export const captureErrorWithData = (error, data) => {
  if (error?.name && error.name === ERROR_TYPES.VALIDATION) {
    return;
  }
  Sentry.withScope((scope) => {
    scope.setExtra("data", data);
    Sentry.captureException(error);
  });
};

export const createCopyOfArray = (array) => {
  return JSON.parse(JSON.stringify(array));
};

export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) return match[2];
  return null;
};

export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; Path=/;`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

// TODO: Bunu değiştirmemiz gerekiyor.
const DEFAULT_LOCALE = "tr-TR";

export const currencyFormatter = (value) => {
  if (value) {
    return new Intl.NumberFormat(DEFAULT_LOCALE).format(value);
  }
  return null;
};

export const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = "0.0";
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(DEFAULT_LOCALE)
      .format(1111)
      .replace(/1/g, "");
    const decimal = new Intl.NumberFormat(DEFAULT_LOCALE)
      .format(1.1)
      .replace(/1/g, "");
    let reversedVal = val.replace(new RegExp(`\\${group}`, "g"), "");
    reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, "g"), ".");
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, "");
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal *= Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    captureErrorWithData(error);
  }
};

export const toPercent = (value, total) => {
  if (total === 0) {
    return 0;
  }
  return Math.round((100 * value) / total);
};

export const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  }
  if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

export const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join("&");
};

export const isPositionOpen = (state) => {
  switch (state) {
    case POSITION_STATES.CREATED:
    case POSITION_STATES.SUBMITTED:
    case POSITION_STATES.IN_PROGRESS:
    case POSITION_STATES.FILLED:
      return true;
    case POSITION_STATES.CLOSED:
    case POSITION_STATES.ON_HOLD:
      return false;
    default:
      return true;
  }
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function debouncer(...args) {
    const context = this;
    const later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const handleHead = (key, param1, param2) => {
  let { title } = HEAD_DATA[key];
  if (param1 && param2) {
    title = `${param1} - ${param2} ${title}`;
  } else if (param1) {
    title = `${param1} ${title}`;
  }
  document.title = title;
};

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const entries = urlParams.entries();
  const params = {};
  for (const entry of entries) {
    const [key, value] = entry;
    params[key] = value;
  }
  return params;
};

export const setQueries = (values) => {
  Object.keys(values).forEach(
    (key) =>
      values[key] === undefined || (values[key] == null && delete values[key])
  );
  const url = new URL(window.location);
  const pa = new URLSearchParams(values).toString();
  url.search = pa;
  window.history.pushState({}, "", url);
  return pa;
};

export const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getRemoteTitleForDeveloper = (value) => {
  const item = REMOTE_OPTIONS_FOR_DEVELOPER.find((i) => i.id === value);
  if (item) {
    return item.title;
  }

  return value;
};

export const getRemoteTitleForCompany = (value) => {
  const item = REMOTE_OPTIONS_FOR_COMPANY.find((i) => i.id === value);
  if (item) {
    return item.title;
  }

  return value;
};

/**
 * useLazyCaller
 *
 * Bu custom hook şu işe yarar; belirtilen timeout süresinin sonunda, gönderilen
 * "callback" fonksiyonunu çağırır. Ancak, timeout bitmeden bir kez daha ilgili
 * fonksiyon çağrılmaya çalışılırsa, timeout sıfırlanır ve tekrar çağrım için
 * timeout süresi kadar beklenir.
 *
 * Örneğin timeout 1000 olsun. 100ms sonra tekrar fonksiyon çağrılırsa, ilk çağrımdan
 * 1100 ms sonra callback çağrılacaktır. ANCAK, timeout bittikten sonra sadece 1
 * kez çağrılır.
 *
 * @returns Function
 */
export const useLazyCaller = () => {
  const [task, setTask] = useState(null);
  const lazyCaller = (callback, timeout) => {
    if (task) {
      clearTimeout(task);
    }

    setTask(setTimeout(callback, timeout));
  };
  return lazyCaller;
};

export const getEducationTypeText = (educationType, emptyValue = "-") => {
  if (educationType === undefined || educationType === null) {
    return emptyValue;
  }
  const match = EDUCATION_TYPES.find((item) => item.value === educationType);
  if (match) {
    return match.label;
  }
  return emptyValue;
};

export const getLanguageLevelText = (level) => {
  level = LANGUAGE_LEVEL_TYPES.find((item) => item.value === level);
  return level ? level.label : "?";
};

export const useTriggerOnce = () => {
  const [status, setStatus] = useState(null);
  const caller = (callback, period) => {
    if (status === null) {
      callback();
      const timeout = setTimeout(() => {
        setStatus(null);
      }, period);
      setStatus(timeout);
    }
  };
  return caller;
};

export const getUnsignedUrl = (signedUrl) => {
  if (!signedUrl) {
    return signedUrl;
  }

  const { origin, pathname } = new URL(signedUrl);
  return `${origin}${pathname}`;
};

export const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = Math.ceil(crop.width * scaleX);
  canvas.height = Math.ceil(crop.height * scaleY);
  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        blob.name = fileName;
        resolve(blob);
      },
      "image/jpeg",
      1
    );
  });
};

export const filterNullOfObject = (obj) => {
  const newObj = {};
  const keys = Object.keys(obj);
  keys.forEach((k) => {
    if (obj[k] !== null) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
};

export const deepFindObject = (obj, path) => {
  const paths = path.split(".");
  let i;
  let current = obj;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    }
    if (!current[paths[i]]) {
      return null;
    }
    current = current[paths[i]];
  }
  return current;
};

export const handleCopy = (value) => {
  const textarea = document.createElement("textarea");
  textarea.textContent = value;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);

  message.success("Copied to clipboard");
};

export const getMonthOptions = ({ locale = "en" }) => {
  dayjs.locale(locale);
  const monthList = dayjs.months();
  return monthList.map((month, idx) => {
    return { value: idx + 1, label: month };
  });
};

export const rangeOfYears = (start, end) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

export const createYearsList = () => {
  const START_YEAR = 1950;
  return rangeOfYears(START_YEAR, CURRENT_YEAR)
    .map((year) => {
      return { label: year, value: year };
    })
    .sort((a, b) => b.value - a.value);
};

export const isAllInputsValid = (inputStatuses) => {
  return Object.keys(inputStatuses).reduce((status, key) => {
    let current = true;
    if (inputStatuses[key] === "error") {
      current = false;
    }
    return status && current;
  }, true);
};

export const getUserWorkAndEducationHistoryOptions = (match) => {
  const educationTitle = ["**Education**"];
  const education = match.user.education.map((i) => {
    return `\n
**${i.school?.name}** / ${i.branch?.label}\n
${getEducationTypeText(i.type)}\n
${i.startDate} - ${i.endDate ? i.endDate : "?"}\n\n
`;
  });

  const workTitle = ["**Experiences**"];
  const work = match.user.workHistory.map((i) => {
    return `\n
**${i.company}**\n
${i.position} \n
${dayjs(i.startDate).format("MM.YYYY")} - ${
      i.endDate ? `${dayjs(i.endDate).format("MM.YYYY")}` : "Present"
    }\n\n
`;
  });

  const userHistoryOptions = [
    {
      title: "Education",
      id: "education",
      note:
        match.user.education.length > 0
          ? educationTitle.concat(education).join("")
          : "",
    },
    {
      title: "Work Experience",
      id: "work",
      note:
        match.user.workHistory.length > 0
          ? workTitle.concat(work).join("")
          : "",
    },
  ];

  return userHistoryOptions;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s
    .toLowerCase()
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
    .join(" ");
};

export const isDeveloperSalaryChanged = (developerCriteria, developer) => {
  if (
    developerCriteria.expected &&
    developerCriteria.expected !== developer.criteria.salary.expected
  ) {
    return true;
  }
  if (
    developerCriteria.currency &&
    developerCriteria.currency !== developer.criteria.salary.currency
  ) {
    return true;
  }
  if (
    developerCriteria.income &&
    developerCriteria.income !== developer.criteria.salary.income
  ) {
    return true;
  }
  if (
    developerCriteria.period &&
    developerCriteria.period !== developer.criteria.salary.period
  ) {
    return true;
  }
  return false;
};

export const removeWhiteSpace = (val) => {
  return val.replace(/\s+/g, "");
};

export const removeDuplicateFlags = (arr) => {
  const types = arr.map((i) => i.type);

  const uniqueTypes = types.reduce((acc, curr) => {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
  return uniqueTypes;
};

export const toPrettyPrice = (value, currency) => {
  return new Intl.NumberFormat("en-gb", {
    style: "currency",
    currency,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const useModal = () => {
  const [showModal, setShow] = useState(false);

  function setShowModal() {
    setShow(!showModal);
  }

  return {
    showModal,
    setShowModal,
  };
};

export const filterElementsWithSameObjectValuesFromArray = ({ array, key }) => {
  const filteredArray = array.filter((i) => i && i[key]);

  return [
    ...new Map(
      filteredArray.filter((i) => i[key]).map((item) => [item[key], item])
    ).values(),
  ];
};

export const getLocalStorage = ({ key, defaultValue, isJSON }) => {
  let val = localStorage.getItem(key);
  if (!val) {
    return defaultValue;
  }
  if (isJSON) {
    val = JSON.parse(val);
  }
  return val;
};

export const setLocalStorage = ({ key, isJSON, value }) => {
  if (isJSON) {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};
