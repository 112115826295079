import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LogoWrapper = styled.div`
  padding-top: 30px;
  margin-right: 5px;
  text-align: center;
  img {
    width: ${(props) => (props.width ? props.width : "216px")};
    height: auto;
  }
`;

const recURL = process.env.PUBLIC_URL;

export default function TgLogo({ width }) {
  return (
    <LogoWrapper width={width}>
      <Link to="/">
        <img
          src={`${recURL}/logo_dark_single.png`}
          alt="TalentGrid dark logo"
        />
      </Link>
    </LogoWrapper>
  );
}
