import React, { useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Avatar, Menu } from "antd";
import {
  LogoutOutlined,
  ProfileOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useLogout } from "../config/auth";
import { GET_RECRUITER, LOGOUT_QUERY } from "../queries/general";
import PartialError from "../components/ErrorPage/PartialError";

const RecruiterMenu = () => {
  const [recruiter, setRecruiter] = useState({ avatarURL: "", name: "" });

  const history = useHistory();
  const logout = useLogout();
  const { loading, error } = useQuery(GET_RECRUITER, {
    onCompleted: (data) => {
      setRecruiter({
        avatarURL: data.recruiter.avatarURL || null,
        name: data.recruiter.name,
      });
    },
  });

  const [logoutQuery] = useLazyQuery(LOGOUT_QUERY, {
    onCompleted: () => {
      logout();
    },
  });

  const handleLogout = () => {
    logoutQuery();
  };

  if (loading) return "loading...";
  if (error) return <PartialError />;

  const { avatarURL } = recruiter;

  const defaultAvatarURL = "/default_avatar.png";
  const recURL = process.env.PUBLIC_URL;

  const { SubMenu } = Menu;

  return (
    <Menu
      mode="vertical"
      style={{ width: "100%", height: "48px", border: "none" }}
      className="recruiterMenu"
      builtinPlacements={{
        rightTop: {
          points: ["tl", "tr"],
          overflow: {
            adjustX: 0,
            adjustY: 0,
          },
          offset: [0, -84],
        },
      }}
    >
      <SubMenu
        key="SubMenu"
        title={
          <div>
            {avatarURL ? (
              <Avatar size="large" src={avatarURL} />
            ) : (
              <Avatar size="large" src={`${recURL}${defaultAvatarURL}`} />
            )}
          </div>
        }
      >
        <Menu.Item
          key="/team"
          icon={<TeamOutlined />}
          onClick={() => history.push("/team")}
        >
          Team
        </Menu.Item>
        <Menu.Item
          key="/profile"
          icon={<ProfileOutlined />}
          onClick={() => history.push("/profile")}
        >
          Profile
        </Menu.Item>
        <Menu.Item
          key="/logout"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Logout
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default RecruiterMenu;
