import React from "react";
import styled from "styled-components";
import { LINKEDIN_WIZARD_MENU_ITEMS } from "../../../../../../constants";

import { UncheckedBoxIcon, CheckedBoxIcon } from "../../../../../Icons";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: solid #c7c7c7 1px;
  min-width: 180px;
  min-height: calc(100vh - 350px);
  margin: 29px 16px;
  color: #979797;
  font-weight: 500;
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconContainer = styled.span`
  display: flex;
  margin-right: 15px;
`;

export default function LinkedinWizardMenu({ checked, wizardStep }) {
  return (
    <MenuContainer>
      <MenuItem>
        <span
          style={{
            color:
              LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "profile-photo"
                ? "#64B7DC"
                : checked.avatarURL && "#0A0B11",
            fontWeight: checked.avatarURL && "600",
          }}
        >
          Profile Photo
        </span>
        <IconContainer>
          {checked.avatarURL ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
        </IconContainer>
      </MenuItem>
      <br />
      <MenuItem>
        <span
          style={{
            color:
              LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "about"
                ? "#64B7DC"
                : checked.bio && "#0A0B11",
            fontWeight: checked.bio && "600",
          }}
        >
          About
        </span>
        <IconContainer>
          {checked.bio ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
        </IconContainer>
      </MenuItem>
      <br />
      <MenuItem>
        <span
          style={{
            color:
              LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "work-experience"
                ? "#64B7DC"
                : checked.workHistory && "#0A0B11",
            fontWeight: checked.workHistory && "600",
          }}
        >
          Work Experience
        </span>
        <IconContainer>
          {checked.workHistory ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
        </IconContainer>
      </MenuItem>
      <br />
      <MenuItem>
        <span
          style={{
            color:
              LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "education"
                ? "#64B7DC"
                : checked.education && "#0A0B11",
            fontWeight: checked.education && "600",
          }}
        >
          Education
        </span>
        <IconContainer>
          {checked.education ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
        </IconContainer>
      </MenuItem>
      <br />
      <MenuItem>
        <span
          style={{
            color:
              LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "languages"
                ? "#64B7DC"
                : checked.languages && "#0A0B11",
            fontWeight: checked.languages && "600",
          }}
        >
          Languages
        </span>
        <IconContainer>
          {checked.languages ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
        </IconContainer>
      </MenuItem>
      <br />
      <MenuItem>
        <span
          style={{
            color:
              LINKEDIN_WIZARD_MENU_ITEMS[wizardStep] === "skills"
                ? "#64B7DC"
                : checked.skills && "#0A0B11",
            fontWeight: checked.skills && "600",
          }}
        >
          Skills
        </span>
        <IconContainer>
          {checked.skills ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
        </IconContainer>
      </MenuItem>
      <br />
    </MenuContainer>
  );
}
