import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Form, Input, Spin, message, Switch } from "antd";
import { Button } from "tg-design";
import { GET_ALL_VERIFIED_TECH, UPDATE_ROLE } from "../../queries";
import SkillInput from "../skillInput";
import ProfessionInput from "../professionInput";
import {
  captureErrorWithData,
  removeTypename,
  filterElementsWithSameObjectValuesFromArray,
} from "../../../../helper";
import layout from "../../layout";
import { VALIDATION_MESSAGES } from "../../../../constants";
import PartialError from "../../../ErrorPage/PartialError";
import { TgDrawerTitle } from "../../../tg-styles/Layouts";

export default function RoleEdit({
  refetch,
  handleClose,
  professionData,
  selectedRole,
}) {
  const [isFeatured, setIsFeatured] = useState(selectedRole.isFeatured);

  const {
    data: allSkillData,
    loading: allSkillLoading,
    error: skillError,
  } = useQuery(GET_ALL_VERIFIED_TECH, {
    variables: {
      limit: 9999,
      verified: true,
    },
  });

  const [updateRole] = useMutation(UPDATE_ROLE);

  const handleSubmit = async (values) => {
    const val = removeTypename(values);

    const uniqueSkills = filterElementsWithSameObjectValuesFromArray({
      array: val.skills,
      key: "id",
    });

    const formattedSkills = uniqueSkills.filter((v) => v).map((v) => v.id);

    const variables = {
      id: selectedRole.id,
      title: val.title,
      isFeatured: val.isFeatured,
      relatedProfession: val.relatedProfession,
      skills: formattedSkills,
      sort: parseInt(val.sort, 10),
    };

    try {
      await updateRole({ variables });
      message.success("Role Updated");
      refetch();
    } catch (err) {
      captureErrorWithData(err);
      message.error(err.message || err);
    }
  };

  if (allSkillLoading) return <Spin />;
  if (skillError) return <PartialError />;

  return (
    <>
      <TgDrawerTitle title="Edit Role" handleClose={handleClose} />
      <Form
        {...layout}
        name="role-edit"
        onFinish={handleSubmit}
        validateMessages={VALIDATION_MESSAGES}
        initialValues={selectedRole}
      >
        <Form.Item name="title" label="Role Title" rules={[{ required: true }]}>
          <Input placeholder="Role Title" />
        </Form.Item>

        <Form.Item name="isFeatured" label="is Featured">
          <Switch
            checked={isFeatured}
            onChange={() => setIsFeatured(!isFeatured)}
          />
        </Form.Item>

        <ProfessionInput data={professionData} />

        <Form.Item name="sort" label="Sorting">
          <Input placeholder="Sort" />
        </Form.Item>

        <Form.Item label="Skills" name="skills">
          <SkillInput allSkillData={allSkillData} />
        </Form.Item>

        <Form.Item>
          <Button type="submit" block size="xs">
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
