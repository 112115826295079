import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: -10px;
`;

export default function TgKanbanContainer({ children, refx, ...props }) {
  return (
    <Container ref={refx} {...props}>
      {children}
    </Container>
  );
}
