import styled from "styled-components";

export default styled.div`
  margin-top: 10px;
  min-height: 600px;
  height: calc(100vh - 250px);
  width: 100%;
  margin-right: 300px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #dadada;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #dadada;
  }
  @media only screen and (max-width: 768px) {
    height: 600px;
  }
`;
