import React from "react";
import styled from "styled-components";
import { ActionMenus, ActionMenu } from "tg-design";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
`;

const toFullMapper = (item) => {
  return {
    label: item.label,
    value: item.value,
    key: item.value,
  };
};

const quickSelectGroups = [
  {
    universities: [
      {
        label: "Boğaziçi University",
        value: "605c025cbc4d02169783db23",
      },
      {
        label: "Istanbul Technical University",
        value: "605c025cbc4d02169783db40",
      },
      {
        label: "Yildiz Technical University",
        value: "605c025cbc4d02169783db68",
      },
      {
        label: "Koç University",
        value: "605c025cbc4d02169783db4c",
      },
      {
        label: "Bilkent University",
        value: "605c025cbc4d02169783db22",
      },
      {
        label: "Middle East Technical University",
        value: "605c025cbc4d02169783db55",
      },
      {
        label: "Sabanci University",
        value: "605c025cbc4d02169783db5b",
      },
      {
        label: "Izmir Institute of Technology",
        value: "605c025cbc4d02169783db41",
      },
      {
        label: "Ozyegin University",
        value: "605c025cbc4d02169783db59",
      },
      {
        label: "Istanbul University",
        value: "605c025dbc4d02169783dd4b",
      },
      {
        label: "Technische Universität München",
        value: "605c025cbc4d02169783c994",
      },
      {
        label: "Galatasaray University",
        value: "605c025cbc4d02169783db36",
      },
      {
        label: "Hacettepe University",
        value: "605c025cbc4d02169783db3a",
      },
      {
        label: "Tobb Economics and Technology University",
        value: "605c025cbc4d02169783db30",
      },
    ].map(toFullMapper),
    title: "TR 1 Grubu",
  },
  {
    universities: [
      {
        label: "Dokuz Eylül University",
        value: "605c025cbc4d02169783db29",
      },
      {
        label: "Beykent University",
        value: "605c025cbc4d02169783db1f",
      },
      {
        label: "Cukurova University",
        value: "605c025cbc4d02169783db27",
      },
      {
        label: "Gazi University Ankara",
        value: "605c025cbc4d02169783db34",
      },
      {
        label: "Marmara University",
        value: "605c025cbc4d02169783db4e",
      },
      {
        label: "Türkisch-Deutsche Universität",
        value: "605c025cbc4d02169783db60",
      },
      {
        label: "Yeditepe University",
        value: "605c025cbc4d02169783db67",
      },
      {
        label: "Istanbul Bilgi University",
        value: "605c025cbc4d02169783db21",
      },
      {
        label: "Hacettepe University",
        value: "605c025cbc4d02169783db3a",
      },
      {
        label: "Kadir Has University",
        value: "605c025cbc4d02169783db45",
      },
      {
        label: "Isik University",
        value: "605c025cbc4d02169783db3e",
      },
      {
        label: "Kocaeli University",
        value: "605c025cbc4d02169783db49",
      },
      {
        label: "Anadolu University",
        value: "605c025cbc4d02169783db16",
      },
      {
        label: "Firat (Euphrates) University",
        value: "605c025cbc4d02169783db31",
      },
      {
        label: "Bahcesehir University",
        value: "605c025cbc4d02169783db1b",
      },
      {
        label: "Gebze Tecnical University",
        value: "605c025dbc4d02169783dd42",
      },
      {
        label: "Ege University",
        value: "605c025cbc4d02169783db2e",
      },
      {
        label: "Ankara University",
        value: "605c025cbc4d02169783db17",
      },
      {
        label: "Sakarya University",
        value: "605c025cbc4d02169783db5c",
      },
    ].map(toFullMapper),
    title: "TR 2 Grubu",
  },
];

export default function QuickUniversitySelection({ selectGroup }) {
  return (
    <Container>
      <ActionMenus icon="Quick Sel." menuStyles={{ top: 30 }}>
        {quickSelectGroups.map((item) => (
          <ActionMenu
            key={item.title}
            onClick={() => selectGroup(item.universities)}
          >
            <span style={{ color: "#64B7DC" }}>{item.title}</span>
          </ActionMenu>
        ))}
      </ActionMenus>
    </Container>
  );
}
