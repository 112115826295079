import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { close } from "../../../store/slices/question";

export default function Question() {
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question);
  if (question.isOpen === false) {
    return "";
  }

  const handleOk = () => {
    question.callback();
    dispatch(close());
  };

  const handleCancel = () => {
    dispatch(close());
  };

  return (
    <Modal handleClose={handleCancel}>
      <ModalHeader>{question.title}</ModalHeader>
      <ModalBody>{question.content}</ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={handleOk}>
          Yes
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
}
