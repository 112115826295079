import React from "react";
import {
  BUDGET_PERIOD_TITLES,
  INCOME_TYPE_TITLES,
} from "../../../../../../constants";
import { toPrettyPrice } from "../../../../../../helper";

export default function BudgetExpectationLine({ salary }) {
  return (
    <>
      <span>{toPrettyPrice(salary.expected, salary.currency)}</span>/
      {BUDGET_PERIOD_TITLES[salary.period]}
      <span> ({INCOME_TYPE_TITLES[salary.income]}) </span>
    </>
  );
}
