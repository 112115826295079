import React, { useState, useContext, useCallback, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { message, Switch } from "antd";
import { useHistory } from "react-router-dom";
import { Button } from "tg-design";
import TextField from "../../Form/TextField";
import { TgDrawerTitle } from "../../tg-styles/Layouts";
import { CREATE_COMPANY_WITH_NAME, CHECK_IF_COMPANY_EXISTS } from "../queries";
import { captureErrorWithData, debounce } from "../../../helper";
import FormScaffold from "../../Form/FormScaffold";
import AppContext from "../../contexts/AppContext";
import PartialError from "../../ErrorPage/PartialError";

const DEFAULT_OPTIONS = {
  showStatus: false,
};

export default function CreateCompanyForm({ onClose }) {
  const [state, setState] = useState({ name: "", isTest: false });
  const [isAlreadyExists, setIsAlreadyExists] = useState(false);
  const [createCompany] = useMutation(CREATE_COMPANY_WITH_NAME);
  const [checkIfCompanyExistsByName, { error }] = useLazyQuery(
    CHECK_IF_COMPANY_EXISTS,
    {
      onCompleted: (data) => {
        if (data?.checkIfCompanyExistsByName?.name) {
          setIsAlreadyExists(true);
        } else {
          setIsAlreadyExists(false);
        }
      },
      fetchPolicy: "network-only",
    }
  );

  const history = useHistory();
  const appContext = useContext(AppContext);

  const handleSubmit = async () => {
    try {
      const { data } = await createCompany({ variables: state });
      message.success("Company created succesfully");
      history.push(`/companies/${data.addCompany.id}`);
    } catch (error) {
      captureErrorWithData(error);
      message.error(error.message || error);
      setIsAlreadyExists(false);
    }
  };

  const onType = useCallback(
    debounce(async (val) => {
      try {
        await checkIfCompanyExistsByName({
          variables: { name: val },
        });
      } catch (err) {
        message.error("Something went wrong");
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (state.name) {
      onType(state.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (error) return <PartialError />;

  return (
    <div>
      <TgDrawerTitle title="Create new company" handleClose={onClose} />

      <TextField
        value={state.name}
        label="Company Name:"
        placeholder="Add name"
        width="100%"
        onChange={(e) => setState({ ...state, name: e.target.value })}
      />
      <div style={{ marginBottom: "30px", color: "#ff7675" }}>
        {isAlreadyExists &&
          `A company with the name '${state.name}' already exists.`}
      </div>
      {appContext.isAdmin && (
        <FormScaffold
          style={{ marginBottom: "30px" }}
          options={DEFAULT_OPTIONS}
          label={
            <>
              <span style={{ marginRight: "15px" }}>Test</span>
              <Switch
                onChange={(value) => setState({ ...state, isTest: value })}
                checked={state.isTest}
              />
            </>
          }
        />
      )}

      <Button
        disabled={state.name.length === 0 || isAlreadyExists}
        onClick={handleSubmit}
        block
      >
        Create New Company
      </Button>
    </div>
  );
}
