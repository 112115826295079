import React, { useState } from "react";
import { Spin, Tag } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { Button, ButtonLink } from "tg-design";
import ModalRoles from "./modules/ModalRoles";
import {
  TgDrawer,
  TgTable,
  TgHeader,
  TgButtonField,
} from "../../tg-styles/Layouts";
import RoleEdit from "./edit/edit";
import { GET_ALL_ROLES, CREATE_ROLES, GET_PROFESSIONS } from "../queries";
import PartialError from "../../ErrorPage/PartialError";

const isFeatured = (value) => {
  if (value) return <StarFilled />;
  if (!value) return <StarOutlined />;
};

const getSkills = (skills) => {
  if (skills.length > 0) {
    return skills.slice(0, 3).map((item, i) => {
      return (
        <div key={i}>
          <Tag>{item.title}</Tag>
        </div>
      );
    });
  }
  return [];
};

export default function Roles() {
  const { loading, error, data, refetch } = useQuery(GET_ALL_ROLES);

  const {
    loading: professionLoading,
    error: professionError,
    data: professionData,
  } = useQuery(GET_PROFESSIONS);

  const [createRoles] = useMutation(CREATE_ROLES);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawerState, setDrawerState] = useState({ visible: false });
  const [selectedId, setSelectedId] = useState(null);

  const showDrawer = (id) => {
    setSelectedId(id);
    setDrawerState({
      visible: true,
    });
  };

  const onClose = () => {
    setDrawerState({
      visible: false,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const createRole = async (val) => {
    await createRoles({ variables: { ...val } });
    refetch();
  };

  if (loading || professionLoading) return <Spin />;
  if (error || professionError) return <PartialError />;

  const columns = [
    {
      title: "Role",
      dataIndex: "title",
      render: (value, record) => (
        <ButtonLink color="#1890FF" onClick={() => showDrawer(record.id)}>
          {value || "No name"}
        </ButtonLink>
      ),
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: "Related Profession",
      dataIndex: "relatedProfession",
      align: "center",
      render: (value) =>
        professionData.allProfession.find((i) => parseInt(i.id) === value)
          ?.title || "-",
    },
    {
      title: "IsFeatured",
      dataIndex: "isFeatured",
      align: "center",
      render: (value) => isFeatured(value),
      sorter: (a) => a.isFeatured,
    },
    {
      title: "Sorting",
      dataIndex: "sort",
      align: "center",
      render: (value) => value,
      sorter: (a, b) => a.sort - b.sort,
    },
    {
      title: "Skills",
      dataIndex: "skills",
      render: (value) => (
        <div>
          {getSkills(value)}{" "}
          {value.length > 3 && `and ${value.length - 3} more`}
        </div>
      ),
    },
  ];

  return (
    <div>
      {drawerState.visible && (
        <TgDrawer
          closable={false}
          onClose={onClose}
          visible={drawerState.visible}
        >
          <RoleEdit
            refetch={refetch}
            handleClose={onClose}
            selectedRole={data.allRole.roles.find((i) => i.id === selectedId)}
            professionData={professionData}
          />
        </TgDrawer>
      )}

      <TgHeader
        breadCrumbs={[
          { title: "Tools", link: "/tools" },
          {
            title: "Roles",
            link: "/tools/roles",
          },
        ]}
      />
      <TgButtonField onClick={() => showModal()}>
        <Button>Create New Role</Button>
      </TgButtonField>

      <TgTable
        loading={loading}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data.allRole && data.allRole.roles}
      />
      <ModalRoles
        visible={isModalVisible}
        onCancel={handleCancel}
        handleOk={handleOk}
        createRole={createRole}
      />
    </div>
  );
}
