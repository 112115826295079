import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import relativeTime from "dayjs/plugin/relativeTime";
import { DateText } from "../../../developers/detail/tabs/LinkedinTab/WizardTab/styles";
import { GET_POTENTIAL_USER_POSITION } from "../../../developers/queries";
import { ColumnBlock, RowBlock } from "../../../Form/BlockUi";
import Avatar from "../../../Avatar";

dayjs.extend(relativeTime);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  background: #f9fafb;
  padding: 20px;
  width: 100%;
`;

const Header = styled.text`
  display: block;
  font-size: 16px;
  font-weight: 600;
`;

const Note = styled.text`
  font-size: 15px;
`;

const CreatorName = styled.span`
  color: #111827;
  font-weight: 700;
`;

export default function ExternalNotesTab({ match }) {
  const { data, loading, error } = useQuery(GET_POTENTIAL_USER_POSITION, {
    variables: { matchId: match.id },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error!</div>;

  return (
    <RowBlock wrap="nowrap" justifyContent="start" alignItems="start" mt={4}>
      {data?.getPotentialUserPosition?.positions?.length > 0 &&
      data?.getPotentialUserPosition?.positions[0]?.note ? (
        <Container>
          <RowBlock gridGap="10px" justify="start">
            {match.position.assignee.avatarURL ? (
              <Avatar size="40px" url={match.position.assignee.avatarURL} />
            ) : (
              <Avatar size="40px" url="/default_avatar.png" />
            )}
            <ColumnBlock>
              <CreatorName>{match.position.assignee.name}</CreatorName>
              <DateText>
                {dayjs(data.getPotentialUserPosition.updatedAt).fromNow()}
              </DateText>
            </ColumnBlock>
          </RowBlock>
          <Header>External Note:</Header>
          <Note>{data.getPotentialUserPosition.positions[0].note}</Note>{" "}
        </Container>
      ) : (
        <Note>There is no external note for this user</Note>
      )}
    </RowBlock>
  );
}
