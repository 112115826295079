import React from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;

  &.has-substate {
    border-bottom: 1px solid #c7c7c7;
    margin: -16px -13px;
    padding: 16px 13px;
    margin-bottom: 13px;
  }
`;

export default function TgKanbanColumnTitle({ children, hasSubState = false }) {
  const classes = [];
  if (hasSubState) {
    classes.push("has-substate");
  }

  return <Container className={classes.join(" ")}>{children}</Container>;
}
