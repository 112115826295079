import { gql } from "@apollo/client";

export const GET_COMPANY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      isTest
      hasGreenhouseIntegration
      clientPartner {
        id
        name
      }
      contacts {
        id
        fullName
        email
        isSign
        isTest
        invite {
          token
          expired
        }
        role
        phone
        permission {
          role
          accessiblePositions {
            position
            authority {
              _0
              _1
              _2
            }
          }
        }
      }
      description
      summary
      slogan
      contents {
        place
        culture
      }
      website
      linkedin
      locations {
        id
        title
        location {
          type
          coordinates
        }
        fullAddress
        place {
          id
          city
          country
        }
        istanbulRegion
        headquarter
      }
      logo
      sector {
        id
        label
      }
      investStatus
      foundedYear
      companySize
      teamSize
      techTeamSize
      tgNotes
      officePhotos {
        id
        url
        description
      }
      technologies {
        id
        title
        category
        categoryId
      }
      positions {
        id
        title
        state
        isTest
        assignee {
          name
        }
        matches {
          matchList {
            id
          }
        }
        createdAt
      }
    }
  }
`;

export const GET_ALL_SECTOR = gql`
  {
    allSectors {
      id
      label
    }
  }
`;

export const GET_ALL_COMPANY_SIZE = gql`
  {
    allCompanySize {
      id
      label
      min
      max
    }
  }
`;

export const CHECK_IF_COMPANY_EXISTS = gql`
  query checkIfCompanyExistsByName($name: String!) {
    checkIfCompanyExistsByName(name: $name) {
      name
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation addCompany(
    $name: String!
    $description: String!
    $contacts: [ID]
    $logo: String
    $website: String
    $sector: [ID]
    $investStatus: String
    $foundedYear: Int
    $companySize: Int
    $teamSize: Int
    $techTeamSize: Int
    $tgNotes: String
    $officePhotos: [OfficePhotoInputType]
    $technologies: [ID]
  ) {
    addCompany(
      name: $name
      description: $description
      contacts: $contacts
      logo: $logo
      website: $website
      sector: $sector
      investStatus: $investStatus
      foundedYear: $foundedYear
      companySize: $companySize
      teamSize: $teamSize
      techTeamSize: $techTeamSize
      tgNotes: $tgNotes
      officePhotos: $officePhotos
      technologies: $technologies
    ) {
      id
    }
  }
`;

export const CREATE_COMPANY_WITH_NAME = gql`
  mutation addCompany($name: String!, $isTest: Boolean) {
    addCompany(name: $name, isTest: $isTest) {
      id
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany(
    $id: ID!
    $name: String
    $description: String
    $summary: String
    $slogan: String
    $contacts: [ID]
    $logo: String
    $website: String
    $linkedin: String
    $sector: [ID]
    $investStatus: String
    $foundedYear: Int
    $companySize: Int
    $teamSize: Int
    $techTeamSize: Int
    $tgNotes: String
    $officePhotos: [OfficePhotoInputType]
    $technologies: [ID]
    $contents: ContentInputType
    $clientPartner: ID
  ) {
    updateCompany(
      id: $id
      name: $name
      description: $description
      clientPartner: $clientPartner
      summary: $summary
      slogan: $slogan
      contacts: $contacts
      logo: $logo
      website: $website
      linkedin: $linkedin
      sector: $sector
      investStatus: $investStatus
      foundedYear: $foundedYear
      companySize: $companySize
      teamSize: $teamSize
      techTeamSize: $techTeamSize
      tgNotes: $tgNotes
      officePhotos: $officePhotos
      technologies: $technologies
      contents: $contents
    ) {
      id
    }
  }
`;

export const CREATE_OFFICE_LOCATION = gql`
  mutation addCompanyLocation(
    $companyId: ID!
    $title: String!
    $location: PointInputType
    $headquarter: Boolean
    $fullAddress: String
    $place: String!
    $istanbulRegion: String
  ) {
    addCompanyLocation(
      companyId: $companyId
      title: $title
      location: $location
      headquarter: $headquarter
      fullAddress: $fullAddress
      place: $place
      istanbulRegion: $istanbulRegion
    ) {
      id
    }
  }
`;

export const UPDATE_OFFICE_LOCATION = gql`
  mutation updateCompanyLocation(
    $companyId: ID!
    $id: ID!
    $title: String!
    $location: PointInputType
    $fullAddress: String
    $headquarter: Boolean
    $place: String!
    $istanbulRegion: String
  ) {
    updateCompanyLocation(
      id: $id
      companyId: $companyId
      title: $title
      location: $location
      headquarter: $headquarter
      fullAddress: $fullAddress
      place: $place
      istanbulRegion: $istanbulRegion
    ) {
      id
    }
  }
`;

export const DELETE_COMPANY_LOCATION = gql`
  mutation deleteCompanyLocation($locationId: ID!) {
    deleteCompanyLocation(locationId: $locationId) {
      id
      locations {
        id
      }
    }
  }
`;

export const GET_EXISTING_EMPLOYERS = gql`
  query existingEmployers($companyId: ID!) {
    existingEmployers(companyId: $companyId) {
      id
      fullName
      email
      isSign
      role
    }
  }
`;

export const INVITE_EMPLOYER = gql`
  mutation InviteEmployer($id: ID!) {
    inviteEmployer(id: $id) {
      id
      fullName
      email
      role
      phone
      isSign
    }
  }
`;

export const NOTIFY_EMPLOYER_FOR_COMPANY = gql`
  mutation notifyEmployerForCompany($employerId: ID!, $companyId: ID!) {
    notifyEmployerForCompany(id: $employerId, companyId: $companyId) {
      id
    }
  }
`;

export const CREATE_EMPLOYER = gql`
  mutation CreateEmployer(
    $fullName: String!
    $email: String!
    $role: String!
    $phone: String
    $company: ID!
    $accessType: Int!
  ) {
    addEmployer(
      fullName: $fullName
      email: $email
      role: $role
      phone: $phone
      company: $company
      accessType: $accessType
    ) {
      id
      fullName
      email
      role
      isSign
      phone
    }
  }
`;

export const UPDATE_EMPLOYER = gql`
  mutation updateEmployer(
    $id: ID!
    $fullName: String
    $email: String
    $role: String
    $phone: String
    $company: ID!
    $accessType: Int!
  ) {
    updateEmployer(
      id: $id
      fullName: $fullName
      email: $email
      role: $role
      phone: $phone
      company: $company
      accessType: $accessType
    ) {
      id
      fullName
      email
      role
      isSign
      phone
    }
  }
`;

export const REMOVE_EMPLOYER = gql`
  mutation removeEmployer($id: ID!, $companyId: ID!) {
    removeEmployerFromCompany(id: $id, companyId: $companyId) {
      id
    }
  }
`;

export const ADD_EXISTING_EMPLOYER = gql`
  mutation addExistingEmployer(
    $companyId: ID!
    $employerId: ID!
    $accessType: Int!
  ) {
    addExistingEmployer(
      companyId: $companyId
      employerId: $employerId
      accessType: $accessType
    ) {
      id
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadCompanyImage($image: Upload!) {
    uploadCompanyImage(image: $image) {
      imageUrl
    }
  }
`;

export const DELETE_COMPANY_LOGO = gql`
  mutation deleteCompanyLogo($imageUrl: String!, $company: ID) {
    deleteCompanyLogo(imageUrl: $imageUrl, company: $company)
  }
`;

export const DELETE_COMPANY_PHOTO = gql`
  mutation deleteCompanyPhoto($imageUrl: String!, $company: ID) {
    deleteCompanyPhoto(imageUrl: $imageUrl, company: $company)
  }
`;

export const UPDATE_COMPANY_PHOTO_DESCRIPTION = gql`
  mutation updateCompanyPhotoDescription(
    $description: String
    $company: ID
    $officePhotoId: ID
  ) {
    updateCompanyPhotoDescription(
      description: $description
      company: $company
      officePhotoId: $officePhotoId
    )
  }
`;

export const GET_TECHNOLOGIES = gql`
  query allTechnology(
    $search: String
    $limit: Int
    $sort: technologySortInputType
    $verified: Boolean
    $exclude: [ID]
  ) {
    allTechnology(
      search: $search
      limit: $limit
      sort: $sort
      verified: $verified
      exclude: $exclude
    ) {
      technologies {
        id
        title
        categoryId
        category
      }
    }
  }
`;
