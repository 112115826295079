import React from "react";
import styled from "styled-components";
import { space } from "styled-system";
import { Avatar } from "antd";
import dayjs from "dayjs";
import { ColumnBlock, RowBlock } from "../../Form/BlockUi";

import {
  captureErrorWithData,
  deepFindObject,
  getUnsignedUrl,
} from "../../../helper";
import {
  EDUCATION_TYPES,
  LANGUAGE_LEVEL_TYPES,
  REMOTE_OPTIONS_FOR_COMPANY,
} from "../../../constants";

const skillExperiences = [
  { value: 1, name: "1 year" },
  { value: 2, name: "2 years" },
  { value: 3, name: "3 years" },
  { value: 4, name: "4 years" },
  { value: 5, name: "5 years" },
  { value: 6, name: "6 years" },
  { value: 7, name: "7 years" },
  { value: 8, name: "8+ years" },
];

const Previuous = styled(ColumnBlock)`
  width: 35%;
  font-weight: bold;
`;
const Current = styled(ColumnBlock)`
  width: 35%;
  font-weight: bold;
`;
const Title = styled(RowBlock)`
  width: 20%;
  justify-content: left;
  font-weight: bold;
`;

const Owner = styled.div`
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  padding: 14px 8px;
  transition: opacity 0.5s;
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  border: 1px solid ${(props) => props.theme.colors.font[3]};
  border-radius: 4px;
  background: #f5fafd;
  font-size: 14px;
  line-height: 25px;
  ${space}
`;

const Value = styled.span``;

const Item = styled.div`
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.font[2]};
  transition: opacity 0.5s;
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 25px;
  background: ${(props) => (props.isActive ? "#F5FAFD" : "none")};
  ${space}
`;

const Group = styled.div`
  border: 1px dashed #e1e0e9;
  margin: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  align-items: flex-start;
  padding: 5px 10px;
`;

const generateKey = (index) => {
  return `${index}_${Math.random()}`;
};

const getListViewLabels = (list, labelKey) => {
  return list.map((i, index) => (
    <Value key={generateKey(index)}>{i[labelKey]}</Value>
  ));
};

const getLanguageLevelLabel = (level) => {
  if (!level) {
    return;
  }
  return LANGUAGE_LEVEL_TYPES.filter((i) => i.value === level)[0].label;
};

const getEducationTypeLabel = (type) => {
  if (!type && type !== 0) {
    return "-";
  }
  return EDUCATION_TYPES.filter((i) => i.value === type)[0].label;
};

const getSkillGroupListViewLabels = (list) => {
  return list.map((i, index) => (
    <div key={generateKey(index)} style={{ textAlign: "center" }}>
      <Group>
        {i.map((skill) => (
          <Value key={generateKey(index)}>
            {skill.title}
            {skill.experience &&
              `, ${
                skillExperiences.find((s) => s.value === skill.experience).name
              }`}
          </Value>
        ))}
      </Group>
      <Value>{index !== list.length - 1 && "OR"}</Value>
    </div>
  ));
};

const getSkillListViewLabels = (list) => {
  if (list && list.length === 0) {
    return <Value />;
  }
  return list.map((skill, index) => (
    <Value key={generateKey(index)}>
      {skill?.title && skill?.experience && (
        <Value>
          {skill.title},{" "}
          {skillExperiences.find((s) => s.value === skill.experience)?.name}
        </Value>
      )}
    </Value>
  ));
};

const getEducationListViewLabels = (list) => {
  return list.map((education, index) => (
    <Group key={generateKey(index)}>
      <Value>
        Education Type:{" "}
        {getEducationTypeLabel(education.type) || "Not preferred"}
      </Value>
      <Value>School: {education.school.name || "Not preferred"}</Value>
      <Value>Start Date: {education.startDate || "Not preferred"}</Value>
      <Value>End Date: {education.endDate || "Not preferred"}</Value>
    </Group>
  ));
};

const getLanguageListViewLabels = (list) => {
  return list.map((item, index) => (
    <Group key={generateKey(index)}>
      <Value>Language: {item?.language?.label || "Not preferred"}</Value>
      <Value>
        Level:
        {getLanguageLevelLabel(item.level || item.languageLevel) ||
          "Not preferred"}
      </Value>
    </Group>
  ));
};

export const VersionOwner = ({ timestamp, name, avatarURL, ...props }) => {
  return (
    <Owner {...props}>
      <RowBlock justify="flex-start">
        <ColumnBlock>{dayjs(timestamp).format("DD/MM/YYYY HH:mm")}</ColumnBlock>
        <ColumnBlock>Version Number: {timestamp}</ColumnBlock>
      </RowBlock>
      <RowBlock width="70%" justify="flex-end" align="center">
        <Title>{name}</Title>
        {avatarURL && <Avatar size="large" src={getUnsignedUrl(avatarURL)} />}
        {!avatarURL && (
          <Avatar
            size="large"
            src={`${process.env.PUBLIC_URL}/default_avatar.png`}
          />
        )}
      </RowBlock>
    </Owner>
  );
};

export const RangeField = ({
  name,
  title,
  path,
  doc,
  state,
  showCurrencyBox,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  const previous = state?.selected?.diff ? state?.selected?.diff[name] : null;
  const current = deepFindObject(doc, path);

  return (
    <Item isActive={previous} {...props}>
      <Title>{title}</Title>
      <Previuous>
        {previous && (
          <>
            <Value>Min: {previous.min}</Value>
            <Value>Max: {previous.max}</Value>
            {showCurrencyBox && <Value>Currency: {previous.currency}</Value>}
          </>
        )}
      </Previuous>
      <Current>
        <>
          <Value>Min: {current.min}</Value>
          <Value>Max: {current.max}</Value>
          {showCurrencyBox && <Value>Currency: {current.currency}</Value>}
        </>
      </Current>
    </Item>
  );
};

export const UserEducationListField = ({
  name,
  title,
  path,
  doc,
  state,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  const previous = state?.selected?.diff ? state?.selected?.diff[name] : null;
  const current = deepFindObject(doc, path);

  return (
    <Item isActive={previous} {...props}>
      <Title>{title}</Title>
      <Previuous>{previous && getEducationListViewLabels(previous)}</Previuous>
      <Previuous>{current && getEducationListViewLabels(current)}</Previuous>
    </Item>
  );
};

export const LanguageListField = ({
  name,
  title,
  path,
  doc,
  state,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  const previous = state?.selected?.diff ? state?.selected?.diff[name] : null;
  const current = deepFindObject(doc, path);

  return (
    <Item isActive={previous} {...props}>
      <Title>{title}</Title>
      <Previuous>{previous && getLanguageListViewLabels(previous)}</Previuous>
      <Previuous>{current && getLanguageListViewLabels(current)}</Previuous>
    </Item>
  );
};

export const SimpleTextField = ({
  name,
  title,
  path,
  doc,
  state,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  let current;
  let previous;
  try {
    current = deepFindObject(doc, path);
    previous = deepFindObject(state.selected.diff, name);
  } catch (error) {
    captureErrorWithData(error);
  }

  return (
    <Item isActive={previous} {...props}>
      <Title>{title}</Title>
      <Previuous>
        {previous && (
          <Value
            dangerouslySetInnerHTML={{
              __html: previous || "",
            }}
          />
        )}
      </Previuous>
      <Current>
        {current && (
          <Value
            dangerouslySetInnerHTML={{
              __html: current || "",
            }}
          />
        )}
      </Current>
    </Item>
  );
};

export const SimpleListField = ({
  name,
  title,
  path,
  doc,
  state,
  labelKey,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  let current;
  let previous;
  try {
    current = deepFindObject(doc, path);
    previous = deepFindObject(state.selected.diff, name);
  } catch (error) {
    captureErrorWithData(error);
  }

  return (
    <Item isActive={previous && previous.length > 0} {...props}>
      <Title>{title}</Title>
      <Previuous>{previous && getListViewLabels(previous, labelKey)}</Previuous>
      <Current>{current && getListViewLabels(current, labelKey)}</Current>
    </Item>
  );
};

export const RemoteOptionTextField = ({
  name,
  title,
  path,
  doc,
  state,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  let current;
  let previous;
  try {
    current = deepFindObject(doc, path);
    previous = deepFindObject(state.selected.diff, name);
  } catch (error) {
    captureErrorWithData(error);
  }

  return (
    <Item isActive={previous} {...props}>
      <Title>{title}</Title>
      <Previuous>
        {previous && (
          <div>
            {REMOTE_OPTIONS_FOR_COMPANY.filter((i) =>
              previous.includes(i.id)
            ).map((item) => (
              <Value>
                {item.title}
                <br />
              </Value>
            ))}
          </div>
        )}
      </Previuous>
      <Current>
        {current && (
          <div>
            {REMOTE_OPTIONS_FOR_COMPANY.filter((i) =>
              current.includes(i.id)
            ).map((item) => (
              <Value>
                {item.title}
                <br />
              </Value>
            ))}
          </div>
        )}
      </Current>
    </Item>
  );
};

export const EducationTypeTextField = ({
  name,
  title,
  path,
  doc,
  state,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  let current;
  let previous;
  try {
    current = deepFindObject(doc, path);
    previous = deepFindObject(state.selected.diff, name);
  } catch (error) {
    captureErrorWithData(error);
  }

  return (
    <Item isActive={previous || false} {...props}>
      <Title>{title}</Title>
      <Previuous>
        {previous && (
          <Value>
            {previous &&
              previous !== undefined &&
              getEducationTypeLabel(previous)}
          </Value>
        )}
      </Previuous>
      <Current>
        {current && current !== undefined && (
          <Value>{getEducationTypeLabel(current)}</Value>
        )}
      </Current>
    </Item>
  );
};

export const SkillGroupListField = ({
  name,
  title,
  path,
  doc,
  state,
  ...props
}) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  let current;
  let previous;
  try {
    current = deepFindObject(doc, path);
    previous = deepFindObject(state.selected.diff, name);
  } catch (error) {
    captureErrorWithData(error);
  }

  return (
    <Item isActive={previous} {...props}>
      <Title>{title}</Title>
      <Previuous>{previous && getSkillGroupListViewLabels(previous)}</Previuous>
      <Current>{current && getSkillGroupListViewLabels(current)}</Current>
    </Item>
  );
};

export const SkillListField = ({ name, title, path, doc, state, ...props }) => {
  if (!path || !doc || !state || !name) {
    /* eslint-disable-next-line no-console */
    console.warn("A missing argument");
    return <></>;
  }

  let current;
  let previous;
  try {
    current = deepFindObject(doc, path);
    previous = deepFindObject(state.selected.diff, name);
  } catch (error) {
    captureErrorWithData(error);
  }

  return (
    <Item isActive={previous} {...props}>
      <Title>{title}</Title>
      <Previuous>{previous && getSkillListViewLabels(previous)}</Previuous>
      <Current>{current && getSkillListViewLabels(current)}</Current>
    </Item>
  );
};
