import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { message, Tooltip } from "antd";
import styled from "styled-components";
import { GET_FAILED_REASON } from "../../../../position/queries";
import { BlockCheckboxField } from "../../../../Form/CheckboxField";
import PartialError from "../../../../ErrorPage/PartialError";
import { EmailIcon } from "../../../../Icons";
import { SEND_FAIL_REASON_TEST_EMAIL } from "../../../../matchDetail/queries";
import { captureErrorWithData } from "../../../../../helper";
import { FAIL_REASON_MODAL_TYPES } from "../../../../../constants";
import TextField from "../../../../Form/TextField";

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 9fr 1fr;
  column-gap: 15px;
  display: ${({ isMailIconsEnabled }) =>
    isMailIconsEnabled ? "grid" : "block"};
`;

const IconContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20px;
`;

const getFailReasonType = (type) => {
  if (type === FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED) {
    return 2;
  }
  return 1;
};

export default function OptionStep({
  failReasons,
  handleFailReasonChange,
  matchId,
  companyName,
  roleTitle,
  failReasonType,
  setOtherReason,
  otherReason,
}) {
  const { loading, error, data } = useQuery(GET_FAILED_REASON, {
    variables: {
      type: getFailReasonType(failReasonType),
    },
  });
  const [sendFailReasonTestEmailToRecruiter] = useMutation(
    SEND_FAIL_REASON_TEST_EMAIL
  );

  const sendFailReasonTestEmail = async ({ matchId, failReason }) => {
    let failReasonDescription = failReason.description;

    failReasonDescription = failReasonDescription
      .replace("{COMPANY_NAME}", companyName)
      .replace("{ROLE}", roleTitle);

    try {
      await sendFailReasonTestEmailToRecruiter({
        variables: {
          id: matchId,
          failReason: failReasonDescription,
        },
      });

      message.success("Mail sent to your e-mail address");
    } catch (error) {
      captureErrorWithData(error);
      message.error(error.message || error);
    }
  };

  if (loading) return "loading";
  if (error) return <PartialError />;

  return (
    <CheckboxContainer
      isMailIconsEnabled={
        failReasonType === FAIL_REASON_MODAL_TYPES.FROM_TG_ASSESSMENT_TO_FAILED
      }
    >
      {data.allRejectReason.map((item) => (
        <>
          <div>
            <BlockCheckboxField
              label={item?.label}
              key={item.id}
              id={item.id}
              name={item.id}
              value={failReasons.includes(item.id)}
              onChange={(event) =>
                handleFailReasonChange({
                  value: item.id,
                  description: item.description,
                  event,
                  failReasonType,
                })
              }
              checked={failReasons.includes(item.id)}
              icon
              checkboxStyles={{
                height: "14px",
                width: "14px",
                margin: "0 10px 0 0",
              }}
            />
          </div>

          {failReasonType ===
            FAIL_REASON_MODAL_TYPES.FROM_TG_ASSESSMENT_TO_FAILED && (
            <Tooltip
              placement="right"
              title="Send me a test e-mail"
              zIndex={9999999}
            >
              <IconContainer
                onClick={() => {
                  return sendFailReasonTestEmail({
                    matchId,
                    failReason: item,
                  });
                }}
              >
                <EmailIcon />
              </IconContainer>
            </Tooltip>
          )}
        </>
      ))}

      {failReasonType ===
        FAIL_REASON_MODAL_TYPES.FROM_COMPANY_STATES_TO_FAILED && (
        <div>
          <BlockCheckboxField
            label="Other"
            key="other"
            id="other"
            name="other"
            icon
            value={failReasons.includes("other")}
            onChange={(event) =>
              handleFailReasonChange({
                value: "other",
                event,
                failReasonType,
              })
            }
            checked={failReasons.includes("other")}
            checkboxStyles={{
              height: "14px",
              width: "14px",
              margin: "0 10px 0 0",
            }}
            containerStyles={{
              width: "unset",
              height: "unset",
            }}
          />

          {failReasons.includes("other") && (
            <TextField
              value={otherReason}
              autoFocus
              onChange={(e) => setOtherReason(e)}
            />
          )}
        </div>
      )}
    </CheckboxContainer>
  );
}
