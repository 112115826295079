import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthToken } from "../config/auth";
import Sider from "./Sider";
import { TgLayout } from "../components/tg-styles/Layouts";
import Question from "../components/common/Question";

const { Content } = TgLayout;

const AuthLayout = ({
  component: Component,
  header: HeaderRow,
  props,
  ...rest
}) => {
  const [, isAuth] = useAuthToken();
  const { showSider = true } = props;
  if (isAuth()) {
    return (
      <TgLayout
        style={{
          minHeight: "100vh",
          marginLeft: showSider ? "140px" : "0px",
          background: "white",
        }}
      >
        {showSider && <Sider />}

        <TgLayout className="site-layout" style={{ background: "white" }}>
          <Content>
            <div
              style={{ padding: "12px 24px 12px 0px", minHeight: "360px" }} // TgHeader'da (-) margin var border icin
            >
              <Route {...rest} render={(props) => <Component {...props} />} />
            </div>
          </Content>
        </TgLayout>
        <Question />
      </TgLayout>
    );
  }

  return <Route render={() => <Redirect to="/login" />} />;
};

export default AuthLayout;
