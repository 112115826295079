import * as Yup from "yup";

export const tpProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required("Title is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z]+@talentgrid.io/,
      "E-mail format is wrong: xxx@talentgrid.io"
    )
    .required(),
  phone: Yup.string()
    .matches(/^\+(?:[0-9]●?){6,14}[0-9]$/)
    .required(`Phone is required`),
});
