import gql from "graphql-tag";

export const UPLOAD_AVATAR = gql`
  mutation uploadAvatar($image: Upload, $id: ID) {
    uploadAvatar(image: $image, id: $id) {
      imageUrl
    }
  }
`;

export const DELETE_AVATAR = gql`
  mutation deleteAvatar($id: ID) {
    deleteAvatar(id: $id)
  }
`;
