import React, { useState } from "react";
import { TgHeader } from "../tg-styles/Layouts";
import { TgHeaderTabs, TgHeaderTab } from "../tg-styles/TgHeaderTabs";
import Team from "./tabs/Team";

export default function TalentGridTeam() {
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(
    urlParams.get("tab") ? urlParams.get("tab") : "users"
  );

  const getTab = () => {
    switch (currentTab) {
      case "users":
        return <Team />;
      default:
        setCurrentTab("users");
        break;
    }
  };

  return (
    <div>
      <TgHeader
        breadCrumbs={[{ title: "Team", link: "/team" }]}
        title="TalentGrid Team"
      >
        <TgHeaderTabs currentTab={currentTab} handleChange={setCurrentTab}>
          <TgHeaderTab name="users">Users</TgHeaderTab>
        </TgHeaderTabs>
      </TgHeader>
      <div style={{ paddingLeft: "10px" }}>{getTab()}</div>
    </div>
  );
}
