export function selectStyles({
  textAlign,
  valueAlign,
  optionStyle,
  selectedBgColorful,
  bgColor,
  hasError,
}) {
  return {
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: 14,
        textAlign: "left",
        cursor: "pointer",
        color: "#444444",
        background: state.isFocused || state.isSelected ? "#f5f6f8" : "",
        "&:hover": {
          background: "#f5f6f8",
        },
        ...optionStyle,
      };
    },
    container: (base, state) => ({
      ...base,
      width: "100%",
      border: hasError ? "1px solid #ff7675" : "none",
      borderRadius: 5,
      textAlign: "left",
      color: state.hasValue ? "#444444" : "",
      fontWeight: state.hasValue ? "bold" : "",
      fontFamily: "Inter",
    }),
    placeholder: (base) => ({
      ...base,
      textAlign: textAlign || "left",
      width: "100%",
      color: "#aaa",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter",
    }),
    control: (base, state) => {
      return {
        ...base,
        boxShadow: "none",
        height: !state.isMulti ? "40px" : "auto",
        fontSize: 14,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        padding: "2px",
        paddingLeft: "9px",
        background:
          bgColor ||
          (state.hasValue && selectedBgColorful && !state.isMulti
            ? "#c7eed5"
            : "#f5f6f8"),
        border: state.isFocused ? "2px solid #64b7dc" : "2px solid transparent",
        "&:focus": {
          border: "2px solid #64b7dc",
        },
        "&:hover": {
          border: state.menuIsOpen
            ? "2px solid #64b7dc"
            : "2px solid transparent",
        },
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        border: "1px solid #c7eed5",
        backgroundColor: "#c7eed5",
        borderRadius: "4px",
      };
    },
    valueContainer: (base) => {
      return {
        ...base,
        width: "100%",
        padding: 0,
        justifyContent: valueAlign || "left",
      };
    },
  };
}

export const labelStyles = {
  fontSize: 16,
  paddingTop: 8,
  marginRight: 5,
  width: 50,
  textAlign: "right",
};

export function creatableSelectStyles({
  selectedBgColorful,
  optionStyle,
  valueAlign,
}) {
  return {
    option: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: 14,
      ...optionStyle,
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "2px solid #64b7dc" : "2px solid transparent",
      boxShadow: "none",
      borderColor: "#64b7dc",
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: "14px",
      justifyContent: "center",
      textAlign: "center",
      backgroundColor:
        state.hasValue && selectedBgColorful && !state.isMulti
          ? "#c7eed5"
          : "#f5f6f8",
      "&:focus": {
        border: "2px solid #64b7dc",
      },
      "&:hover": {
        border: state.menuIsOpen
          ? "2px solid #64b7dc"
          : "2px solid transparent",
      },
    }),
    valueContainer: (base) => {
      return {
        ...base,
        justifyContent: valueAlign || "left",
      };
    },
    placeholder: (base) => ({
      ...base,
      color: "#aaa",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        border: "1px solid #c7eed5",
        backgroundColor: "#c7eed5",
        borderRadius: "4px",
      };
    },
  };
}
