import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { space } from "styled-system";
import { message, Spin } from "antd";
import { ColumnBlock, RowBlock } from "../../../Form/BlockUi";
import { USER_VERSION } from "../../queries";
import { filterNullOfObject } from "../../../../helper";
import useKeyPress from "../../../../hooks/useKeyPress";
import {
  RemoteOptionTextField,
  SimpleListField,
  SimpleTextField,
  SkillListField,
  UserEducationListField,
  LanguageListField,
  VersionOwner,
} from "../../../module/VersionView";
import { TgButtonField } from "../../../tg-styles/Layouts";
import PartialError from "../../../ErrorPage/PartialError";

const Header = styled.div`
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.font[2]};
  transition: opacity 0.5s;
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 25px;
  ${space}
`;

const Previuous = styled(ColumnBlock)`
  width: 35%;
  font-weight: bold;
`;
const Current = styled(ColumnBlock)`
  width: 35%;
  font-weight: bold;
`;
const Title = styled(RowBlock)`
  width: 20%;
  justify-content: left;
  font-weight: bold;
`;

const ArrowButton = styled(TgButtonField)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.blue[1]};
  font-size: 18px;
  cursor: pointer;
`;

const WaitMessage = () => {
  return (
    <>
      <Spin />
      <span style={{ marginLeft: "10px" }}>
        It may take a long time please wait
      </span>
    </>
  );
};

export default function VersionTab({ user }) {
  const { userId } = useParams();
  const [isReady, setIsReady] = useState(false);
  const [userState, setUserState] = useState(user);
  const [emptyView, setEmptyView] = useState(false);
  const handleLeft = useKeyPress({ key: "ArrowLeft" });
  const handleRight = useKeyPress({ key: "ArrowRight" });

  const [state, setState] = useState({
    all: [],
    selected: {},
    nextArrow: false,
    previousArrow: true,
  });

  const { loading: UserLoading, error } = useQuery(USER_VERSION, {
    variables: {
      id: userId,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.user.changes && data.user.criteria) {
        const all = data.user.changes
          .concat(data.user.criteria.changes)
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
        const selected = all[0];
        setState({ ...state, all, selected });
        setIsReady(true);
      } else {
        setEmptyView(true);
      }
    },
  });

  const goToDom = (key = "") => {
    const currentDom = document.getElementById(key);
    if (currentDom) {
      currentDom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleNext = () => {
    const idx = state.all.findIndex(
      (c) => c.timestamp === state.selected.timestamp
    );

    if (idx <= 0) {
      message.warn("This is the latest version");
      return false;
    }
    const selected = state.all[idx - 1];
    const { __typename, ...changes } = filterNullOfObject(selected.current);

    let newUserState = { ...userState, ...changes };
    if (__typename === "DeveloperCriteriaType") {
      newUserState = {
        ...userState,
        criteria: { ...userState.criteria, ...changes },
      };
    }

    const key = Object.keys(changes)[0];
    if (key) {
      goToDom(key);
    }

    setUserState(newUserState);
    const nextArrow = idx <= state.all.length;
    setState({ ...state, selected, nextArrow });
  };

  const handlePrevious = () => {
    const idx = state.all.findIndex(
      (c) => c.timestamp === state.selected.timestamp
    );

    if (state.all.length - 1 === idx) {
      message.warn("This is first version");
      return false;
    }
    const selected = state.all[idx + 1];

    const { __typename, ...changes } = filterNullOfObject(state.selected.diff);

    let newUserState = { ...userState, ...changes };
    if (__typename === "DeveloperCriteriaType") {
      newUserState = {
        ...userState,
        criteria: { ...userState.criteria, ...changes },
      };
    }

    const key = Object.keys(changes)[0];
    if (key) {
      goToDom(key);
    }

    setUserState(newUserState);
    const previousArrow = idx < state.all.length;
    setState({ ...state, selected, previousArrow });
  };

  useEffect(() => {
    if (handleLeft) {
      handlePrevious();
    }
    if (handleRight) {
      handleNext();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [handleLeft, handleRight]);
  if (error) return <PartialError />;

  return (
    <div>
      {emptyView && "There is no version history"}
      {UserLoading && !isReady && <WaitMessage />}
      {!UserLoading && isReady && (
        <>
          <VersionOwner
            timestamp={state?.selected?.timestamp}
            name={state?.selected?.owner?.name}
            avatarURL={state?.selected?.owner?.avatarURL}
          />
          <Header mt={3}>
            <Title>Title</Title>
            <Previuous>Previous</Previuous>
            <Current>Current</Current>
          </Header>
          <SimpleTextField
            id="name"
            title="Name"
            name="name"
            path="name"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="bio"
            title="Bio"
            name="bio"
            path="bio"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="email"
            title="Email"
            name="email"
            path="email"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="role"
            title="Role"
            name="role.title"
            path="role.title"
            doc={userState}
            state={state}
          />

          <SimpleTextField
            id="experience"
            title="Experience"
            name="experience"
            path="experience"
            doc={userState}
            state={state}
          />

          <SimpleTextField
            id="birthyear"
            title="Birthyear"
            name="birthyear"
            path="birthyear"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="livingCity"
            title="City of residence"
            name="livingCity.city"
            path="livingCity.city"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="phone"
            title="Phone"
            name="phone"
            path="phone"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="linkedin"
            title="Linkedin"
            name="social.linkedin"
            path="social.linkedin"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="github"
            title="Github"
            name="social.github"
            path="social.github"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="stackoverflow"
            title="StackOverflow"
            name="stackoverflow"
            path="social.stackoverflow"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="twitter"
            title="Twitter"
            name="twitter"
            path="social.twitter"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="blog"
            title="Blog"
            name="blog"
            path="social.blog"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="youtube"
            title="YouTube"
            name="youtube"
            path="social.youtube"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="salary"
            title="Salary"
            name="criteria.salary"
            path="criteria.salary.expected"
            doc={userState}
            state={state}
          />
          <SimpleTextField
            id="preferences"
            title="Currency"
            name="preferences"
            path="preferences.currency"
            doc={userState}
            state={state}
          />
          <SimpleListField
            id="benefits"
            title="Benefits"
            name="criteria.benefits"
            path="criteria.benefits.expected"
            doc={userState}
            state={state}
            labelKey="label"
          />
          <SimpleListField
            id="companySize"
            title="Company Size"
            name="criteria.companySize"
            path="criteria.companySize.expected"
            doc={userState}
            state={state}
            labelKey="label"
          />
          <SkillListField
            id="skills"
            title="Skill and Experience"
            name="skills"
            path="skills"
            doc={userState}
            state={state}
          />
          <SimpleListField
            title="Other Skills"
            name="otherSkills"
            path="otherSkills"
            doc={userState}
            state={state}
            labelKey="title"
          />
          <UserEducationListField
            title="Education"
            name="education"
            path="education"
            doc={userState}
            state={state}
          />
          <LanguageListField
            title="Languages"
            name="languages"
            path="languages"
            doc={userState}
            state={state}
          />
          <RemoteOptionTextField
            title="Working Style"
            name="location.remote"
            path="criteria.location.remote"
            doc={userState}
            state={state}
          />
          <SimpleListField
            title="Working Locations"
            name="location.expected"
            path="criteria.location.expected"
            labelKey="city"
            doc={userState}
            state={state}
          />
          <SimpleListField
            title="Desired Technologies"
            name="technologies.expected"
            path="criteria.technologies.expected"
            labelKey="title"
            doc={userState}
            state={state}
          />
          <RowBlock width="600px" mt={3}>
            <ArrowButton onClick={handlePrevious}>{"< Previous"}</ArrowButton>
            <ArrowButton onClick={handleNext}>{"Next >"}</ArrowButton>
          </RowBlock>
        </>
      )}
    </div>
  );
}
