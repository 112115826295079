import gql from "graphql-tag";

export const UPLOAD_RECRUITER_AVATAR = gql`
  mutation uploadRecruiterAvatar($image: Upload, $id: ID) {
    uploadRecruiterAvatar(image: $image, id: $id) {
      imageUrl
    }
  }
`;

export const DELETE_RECRUITER_AVATAR = gql`
  mutation deleteRecruiterAvatar($id: ID) {
    deleteRecruiterAvatar(id: $id)
  }
`;

export const UPDATE_RECRUITER = gql`
  mutation updateRecruiter(
    $id: ID!
    $name: String
    $phone: String
    $email: String
    $role: String
  ) {
    updateRecruiter(
      id: $id
      name: $name
      phone: $phone
      email: $email
      role: $role
    ) {
      id
      name
      phone
      email
      role
    }
  }
`;

export const UPDATE_RECRUITER_SETTINGS_BY_ADMIN = gql`
  mutation updateRecruiterSettingsByAdmin(
    $id: ID!
    $role: String!
    $jobType: [Int]!
  ) {
    updateRecruiterSettingsByAdmin(id: $id, role: $role, jobType: $jobType) {
      role
      jobType
    }
  }
`;

export const UPDATE_COMPANIES_CLIENT_PARTNERS = gql`
  mutation updateCompaniesClientPartners(
    $oldClientPartner: ID
    $newClientPartner: ID
  ) {
    updateCompaniesClientPartners(
      oldClientPartner: $oldClientPartner
      newClientPartner: $newClientPartner
    ) {
      id
    }
  }
`;

export const UPDATE_POSITIONS_ASSIGNEE = gql`
  mutation updatePositionsAssignee(
    $oldAssignee: ID
    $newAssignee: ID
    $states: [String]
  ) {
    updatePositionsAssignee(
      oldAssignee: $oldAssignee
      newAssignee: $newAssignee
      states: $states
    ) {
      id
    }
  }
`;

export const REMOVE_RECRUITER_FROM_TEAM = gql`
  mutation removeRecruiterFromTeam($recruiterId: ID!) {
    removeRecruiterFromTeam(recruiterId: $recruiterId) {
      role
    }
  }
`;

export const CREATE_NEW_RECRUITER = gql`
  mutation addRecruiter(
    $name: String!
    $email: String!
    $role: String!
    $jobType: [Int]!
  ) {
    addRecruiter(name: $name, email: $email, role: $role, jobType: $jobType) {
      id
      name
      email
    }
  }
`;
