import React, { useState, useRef } from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  background: #f6f6f6;
  width: 480px;
  max-height: 460px;
  height: fit-content;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 36px;
`;

const CardTitle = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0c084c;
  cursor: pointer;
`;

const Triangle = styled.div`
  display: inline-block;
  position: relative;
  left: 4px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #0c084c;
  transform: ${(props) => (props.open ? "rotate(90deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
  margin-right: 15px;
  margin-bottom: 1px;
`;

const CardContent = styled.div`
  position: ${({ open }) => (open ? "inherit" : "absolute")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? 1 : 0)};
  height: 100%;
  word-break: break-word;
  transition: opacity 0.9s ease;
  overflow: scroll;
  width: 100%;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0;
  width: 100%;
  border-bottom: 1px solid #3333331a;
  padding: 5px 0;
`;

const ContentKey = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  width: 70%;
`;

const ContentValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #000;
  width: 260px;
  text-align: right;
`;

export default function CollapsableCard({ title = "", data, open }) {
  const [state, setState] = useState({ visible: open, contentHeight: "0px" });

  const contentRef = useRef();

  const handleToggle = () => {
    setState({
      ...state,
      visible: !state.visible,
      contentHeight: state.visible
        ? `${contentRef.current.scrollHeight}px`
        : "0px",
    });
  };
  return (
    <CardContainer>
      <CardTitle onClick={handleToggle}>
        <Triangle open={state.visible} onClick={handleToggle} />
        {title}
      </CardTitle>
      <CardContent ref={contentRef} open={state.visible}>
        {data &&
          data.map((item) => (
            <ContentRow>
              <ContentKey>{item.key}</ContentKey>
              <ContentValue>{item.value}</ContentValue>
            </ContentRow>
          ))}
        {data && data.length === 0 && <>There is no data to show.</>}
      </CardContent>
    </CardContainer>
  );
}
