import React from "react";
import styled from "styled-components";
import TgLogo from "../tg-styles/sidebar/TgLogo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 60px;
  margin: 0;
  padding-top: 20px;

  @media only screen and (max-width: 768px) {
    line-height: 34px;
  }
`;

const ChildContainer = styled.div`
  background: white;
  padding: 0 50px;
  margin: 60px 0;
  border: 1px solid rgb(199, 199, 199);
`;

export default function AuthTemplate({ title, children }) {
  return (
    <Container>
      <TgLogo width="173px" />
      <Title>{title}</Title>
      <ChildContainer>{children}</ChildContainer>
    </Container>
  );
}
