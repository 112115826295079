import React from "react";
import styled from "styled-components";
import { toRichTextState, MarkdownContent, ReadMore, Button } from "tg-design";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ColumnBlock, RowBlock } from "../../../../Form/BlockUi";
import { Candidate, InterviewMessage } from "../ConversationItem";

dayjs.extend(relativeTime);

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 33px 15px 20px;
`;

const NoteBox = styled.div`
  width: 100%;
  background: #f9fafb;
  border-radius: 4px;
  padding: 10px 20px 16px 20px;
  min-height: 200px;
`;
const Image = styled.img`
  border-radius: 50%;
  margin: 15px 20px 0px 0px;
  height: 30px;
  width: 30px;
`;

const ReacruiterName = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #111827;
`;

const CreatedDate = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6b7280;
  margin-left: 10px;
`;

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #64b7dc;
  border-radius: 8px;
  padding: 7px 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #111827;
`;

const InterviewInfo = styled.div`
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #111827;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export default function PreviousNoteItem({
  setState,
  setCurrentTab,
  previousInterviewData,
  match,
}) {
  const handlePreviousNoteSelect = ({ note }) => {
    setState({
      note: toRichTextState(note),
    });

    setCurrentTab("noteTab");
  };

  return (
    <Wrapper>
      <Image src={previousInterviewData.creator.avatarURL} />
      <NoteBox>
        <RowBlock justify="space-between" align="center">
          <RowBlock>
            <ReacruiterName>
              {previousInterviewData.creator.name}
            </ReacruiterName>
            <CreatedDate>
              {dayjs(previousInterviewData.createdAt).fromNow()}
            </CreatedDate>
          </RowBlock>
          <ColumnBlock>
            <Button
              onClick={() => {
                handlePreviousNoteSelect({ note: previousInterviewData.note });
              }}
            >
              Use this
            </Button>
          </ColumnBlock>
        </RowBlock>

        <InterviewInfo>
          <Pill>{previousInterviewData.position.title}</Pill>{" "}
          <span style={{ marginLeft: "8px", marginRight: "8px" }}>
            role for
          </span>
          <Pill>{previousInterviewData.position.company.name}</Pill>
        </InterviewInfo>

        <InterviewMessage>
          Notes from <Candidate>{match.user.name}</Candidate> interview:
        </InterviewMessage>

        <ReadMore maxHeight={0}>
          <MarkdownContent>{previousInterviewData.note}</MarkdownContent>
        </ReadMore>
      </NoteBox>
    </Wrapper>
  );
}
