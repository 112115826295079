import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Tag } from "tg-design";
import {
  RECRUITER_ROLES,
  DESCRIPTIVE_RECRUITER_ROLES,
  DESCRIPTIVE_RECRUITER_JOB_TYPES,
} from "../../../constants";
import { GET_ALL_RECRUITER } from "../../position/queries";
import { TgDrawer, TgTable } from "../../tg-styles/Layouts";
import AddNewRecruiterForm from "../drawers/NewRecruiterForm";

import { GET_RECRUITER } from "../../../queries/general";
import PartialError from "../../ErrorPage/PartialError";

const TagContainer = styled.div`
  & div {
    width: 100px;
    text-align: center;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-bottom: 20px;
`;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (user, record) => {
      return <Link to={`/team/${record.id}`}>{user}</Link>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    align: "center",
    render: (email) => {
      return <div>{email}</div>;
    },
  },
  {
    title: "Permissions",
    dataIndex: "role",
    align: "center",
    render: (role) => {
      return (
        <TagContainer>
          <Tag
            backgroundColor={
              role === RECRUITER_ROLES.ADMIN
                ? "rgba(143, 222, 172, 0.31);"
                : "#E5E5E5"
            }
            content={DESCRIPTIVE_RECRUITER_ROLES[role]}
          />
        </TagContainer>
      );
    },
  },
  {
    title: "Role",
    dataIndex: "jobType",
    align: "center",
    render: (jobTypes) => {
      return (
        <TagContainer>
          <Tag
            content={[...jobTypes]
              .sort()
              .map((item) => DESCRIPTIVE_RECRUITER_JOB_TYPES[item])
              .join(", ")}
          />
        </TagContainer>
      );
    },
  },
];

export default function Team() {
  const [getAllRecruiter, { error, refetch, loading, data }] = useLazyQuery(
    GET_ALL_RECRUITER,
    { fetchPolicy: "network-only" }
  );
  const { data: loggedInRecruiterData } = useQuery(GET_RECRUITER);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  useEffect(() => {
    getAllRecruiter({
      query: GET_ALL_RECRUITER,
      variables: { limit: 10, page: 1 },
    });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onChange = async (pagination, filters, sorter, extra) => {
    if (extra.action === "paginate") {
      await getAllRecruiter({
        query: GET_ALL_RECRUITER,
        variables: { limit: 10, page: pagination.current },
      });
    }
  };

  if (error) return <PartialError />;

  const onDrawerClosed = () => {
    setIsDrawerVisible(null);
  };

  return (
    <>
      {isDrawerVisible && (
        <TgDrawer closable={false} onClose={onDrawerClosed} visible>
          <AddNewRecruiterForm refetch={refetch} />
        </TgDrawer>
      )}

      {loggedInRecruiterData?.recruiter?.role === RECRUITER_ROLES.ADMIN && (
        <ButtonRow>
          <Button
            onClick={() => {
              setIsDrawerVisible(true);
            }}
          >
            Invite New Recruiter
          </Button>
        </ButtonRow>
      )}
      <TgTable
        size="small"
        dataSource={data?.allRecruiter?.recruiters?.filter(
          (recruiter) => recruiter.role !== RECRUITER_ROLES.PASSIVE
        )}
        columns={columns}
        onChange={onChange}
        rowKey={(record) => record.id}
        loading={loading}
        pagination={{
          pageSize: 10,
          total: data && data?.allRecruiter?.totalCount,
          defaultCurrent: 1,
        }}
      />
    </>
  );
}
