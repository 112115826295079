import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

const CustomButtons = styled.button`
  min-width: 100px;
  display: flex;
`;

export const CustomExternalSourcingRichTextArea = ({ ...props }) => {
  const quillRef = useRef();

  const handleInsertVariable = (variable) => {
    if (quillRef?.current) {
      const editor = quillRef.current.getEditor();
      const cursorPosition = editor.getSelection()?.index || 0;
      editor.insertText(cursorPosition, variable);
      editor.setSelection(cursorPosition + variable.length);
    }
  };

  const handleInsertCompanyName = () => {
    handleInsertVariable("[COMPANY_NAME] ");
  };

  const handleInsertCandidateName = () => {
    handleInsertVariable("[CANDIDATE_NAME] ");
  };

  const handleInsertForceMatchLink = () => {
    handleInsertVariable("[FORCE_MATCH_LINK] ");
  };

  const handleInsertPositionTitle = () => {
    handleInsertVariable("[POSITION_TITLE] ");
  };

  const handleInsertTalentPartnerName = () => {
    handleInsertVariable("[TP_NAME] ");
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: "#toolbar",
        handlers: {
          insertCompanyName: handleInsertCompanyName,
          insertForceMatchLink: handleInsertForceMatchLink,
          insertCandidateName: handleInsertCandidateName,
          insertPositionTitle: handleInsertPositionTitle,
          insertTalentPartnerName: handleInsertTalentPartnerName,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    // eslint-disable-next-line
    [quillRef]
  );

  CustomExternalSourcingRichTextArea.modules = modules;
  return (
    <div>
      <div id="toolbar" style={{ height: "70px", display: "flex" }}>
        <CustomButtons className="ql-insertCompanyName">
          Company name
        </CustomButtons>
        <CustomButtons className="ql-insertForceMatchLink">
          Force match link
        </CustomButtons>
        <CustomButtons className="ql-insertCandidateName">
          Candidate name
        </CustomButtons>
        <CustomButtons className="ql-insertPositionTitle">
          Position title
        </CustomButtons>
        <CustomButtons className="ql-insertTalentPartnerName">
          Talent Partner Name
        </CustomButtons>
      </div>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        className="rich-text"
        modules={CustomExternalSourcingRichTextArea.modules}
        {...props}
      />
    </div>
  );
};
