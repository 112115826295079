import React from "react";
import styled from "styled-components";
import { Button } from "tg-design";
import { Candidate, InterviewMessage } from "../../ConversationItem";
import PreviousNoteItem from "../PreviousNoteItem";

const Container = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  border: 1px solid #e3e4e5;
  margin-top: 16px;
  border-radius: 4px;
`;

const EmptyPreviousInterviewNotes = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export default function PreviousNotesTab({
  previousInterviewDatas,
  setState,
  setCurrentTab,
  match,
}) {
  return (
    <Container>
      {previousInterviewDatas.length > 0 &&
        previousInterviewDatas.map((previousInterviewData) => {
          return (
            <PreviousNoteItem
              match={match}
              setCurrentTab={setCurrentTab}
              setState={setState}
              previousInterviewData={previousInterviewData}
            />
          );
        })}

      {previousInterviewDatas.length === 0 && (
        <>
          <EmptyPreviousInterviewNotes>
            <InterviewMessage>
              {" "}
              No interview notes were written about{" "}
              <Candidate>{match.user.name}</Candidate>.
            </InterviewMessage>
            <Button
              onClick={() => {
                setCurrentTab("noteTab");
              }}
            >
              Add interview note
            </Button>
          </EmptyPreviousInterviewNotes>
        </>
      )}
    </Container>
  );
}
