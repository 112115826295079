import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  GroupTitle,
  ColumnTitle,
  CurrentSectionContainer,
  LinkedinSectionContainer,
  PrimaryTitle,
  SecondaryTitle,
  DateText,
  DescriptionText,
} from "./styles";
import ReadMore from "../../../../../ReadMore";

import { RowBlock, ColumnBlock } from "../../../../../Form/BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../../../../Icons";

export const WorkExperienceTab = ({
  handleStateChange,
  linkedinData,
  user,
}) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const newState = linkedinData.workHistory.map((work) => {
      return work;
    });
    setState(newState);
    // eslint-disable-next-line
  }, []);

  const toggleCheck = (idx) => {
    const newState = state.slice();
    newState[idx].isChecked = !newState[idx].isChecked;
    const changes = newState.filter((work) => work.isChecked);
    setState(newState);
    handleStateChange("workHistory", changes);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Work Experience</GroupTitle>

      <RowBlock>
        <ColumnBlock
          width="50%"
          justifyContent="flex-start"
          margin="0 0 100px 0"
        >
          <ColumnTitle>Current</ColumnTitle>
          {user.workHistory.length === 0 ? (
            <span>No work history found.</span>
          ) : (
            user.workHistory.map((item, index) => {
              return (
                <CurrentSectionContainer
                  style={{ marginBottom: "20px" }}
                  key={index}
                >
                  <div>
                    <PrimaryTitle>{item.company}</PrimaryTitle>
                    <SecondaryTitle>{item.position}</SecondaryTitle>
                    <DateText>
                      {dayjs(item.startDate).locale("en").format("MMMM YYYY")} -{" "}
                      {item.endDate
                        ? dayjs(item.endDate).locale("en").format("MMMM YYYY")
                        : "Present"}
                    </DateText>
                    <DescriptionText>
                      {item.description && (
                        <ReadMore text={item.description || ""} limit={30} />
                      )}
                    </DescriptionText>
                  </div>
                </CurrentSectionContainer>
              );
            })
          )}
        </ColumnBlock>
        <ColumnBlock
          width="50%"
          justifyContent="flex-start"
          margin="0 0 100px 0"
        >
          <ColumnTitle>From Linkedin</ColumnTitle>

          {linkedinData.workHistory.length === 0 ? (
            <div>
              <span>No work history found.</span>
            </div>
          ) : (
            linkedinData.workHistory.map((item, idx) => {
              return (
                <LinkedinSectionContainer
                  isChecked={state[idx]?.isChecked}
                  onClick={() => {
                    toggleCheck(idx);
                  }}
                  style={{ marginBottom: "20px" }}
                  key={idx}
                >
                  <div style={{ flex: "1" }}>
                    <PrimaryTitle>{item.company}</PrimaryTitle>
                    <SecondaryTitle>{item.position}</SecondaryTitle>
                    <DateText>
                      {dayjs(item.startDate).locale("en").format("MMMM YYYY")} -{" "}
                      {item.endDate
                        ? dayjs(item.endDate).locale("en").format("MMMM YYYY")
                        : "Present"}
                    </DateText>
                    <DescriptionText>
                      {item.description && (
                        <ReadMore text={item.description || ""} limit={30} />
                      )}
                    </DescriptionText>
                  </div>
                  <div>
                    {state[idx]?.isChecked ? (
                      <CheckedBoxIcon />
                    ) : (
                      <UncheckedBoxIcon />
                    )}
                  </div>
                </LinkedinSectionContainer>
              );
            })
          )}
        </ColumnBlock>
      </RowBlock>
    </div>
  );
};
