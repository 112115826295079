import React, { useState } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Form, Input, Spin, message, Space, Radio, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "tg-design";
import {
  GET_TECHNOLOGY,
  DELETE_TECHNOLOGY,
  UPDATE_TECHNOLOGY,
  MAP_TECHNOLOGY,
  GET_ALL_VERIFIED_TECH,
} from "../../queries";
import {
  TECHNOLOGY_CATEGORIES,
  VALIDATION_MESSAGES,
} from "../../../../constants";
import { TgDrawerTitle } from "../../../tg-styles/Layouts";
import { captureErrorWithData, debounce } from "../../../../helper";
import PartialError from "../../../ErrorPage/PartialError";

const VERIFIED_OPTIONS = [
  { label: "Verified", value: true },
  { label: "Unverified", value: false },
];

export default function Technology({ id, refetch, handleDrawerClose }) {
  const [state, setState] = useState();
  const [mapField, setMapField] = useState();
  const { loading, error } = useQuery(GET_TECHNOLOGY, {
    variables: { id },
    onCompleted: (data) => {
      const _state = { ...data.technology };
      if (!_state.alternativeTexts.includes(_state.title)) {
        _state.alternativeTexts = [_state.title];
      }

      setState(_state);
    },
  });

  const [getSimilerTechnologies] = useLazyQuery(GET_ALL_VERIFIED_TECH, {
    variables: {
      verified: true,
    },
    onCompleted: (data) => {
      setState({ ...state, similers: data.allTechnology.technologies });
    },
  });

  const [deleteTechnology] = useMutation(DELETE_TECHNOLOGY, {
    onCompleted: () => {
      message.success(`${state.title} was deleted.`);
      refetch();
      handleDrawerClose();
    },
  });

  const [updateTechnology] = useMutation(UPDATE_TECHNOLOGY, {
    onCompleted: () => {
      message.success(`${state.title} was updated.`);
      refetch();
      handleDrawerClose();
    },
  });

  const [mapTechnology] = useMutation(MAP_TECHNOLOGY, {
    onCompleted: () => {
      message.success(`${state.title} was mapped.`);
      refetch();
      handleDrawerClose();
    },
  });

  if (loading) return <Spin />;
  if (error) return <PartialError />;
  if (!state) return <Spin />;

  const handleSubmit = async (values) => {
    try {
      const variables = {
        id,
        verified: true,
        creatorToken: null,
        creator: null,
        ...values,
      };
      await updateTechnology({ variables });
    } catch (err) {
      captureErrorWithData(err);
      message.error("Something went wrong");
    }
  };

  const handleDelete = async () => {
    deleteTechnology({
      variables: {
        id,
      },
    });
  };

  const handleUpdateAndMap = async () => {
    if (!mapField) {
      message.error("Map Field Required");
      return;
    }
    mapTechnology({
      variables: {
        id,
        toId: mapField.id,
      },
    });
  };

  const handleRadioOnChange = (v) => {
    setMapField({ id: v.target.value, name: v.target["data-name"] });
  };

  const handleFormChange = (values) => {
    if (values.search) {
      getSimilerTechnologies({
        variables: { search: values.search, limit: 6, verified: true },
      });
    }
  };

  return (
    <>
      <TgDrawerTitle title="Edit Technology" handleClose={handleDrawerClose} />
      <Form
        name="technologyEdit"
        onFinish={handleSubmit}
        validateMessages={VALIDATION_MESSAGES}
        initialValues={state}
        onValuesChange={debounce(handleFormChange, 300)}
      >
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input placeholder="title" />
        </Form.Item>
        <Form.Item name="categoryId" label="Category">
          <Select
            showSearch
            allowClear
            placeholder="Select Category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {TECHNOLOGY_CATEGORIES.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {!state.verified && (
          <Form.Item name="search" label="Search Similer Technology">
            <Input placeholder="eg: Javascript" />
          </Form.Item>
        )}
        <Form.List name="alternativeTexts">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    required={false}
                    key={index}
                    label="Alternative Names"
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Name"
                        addonAfter={
                          fields.length >= 1 ? (
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null
                        }
                      />
                    </Form.Item>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{ alignItems: "center" }}
                  label={<PlusOutlined />}
                >
                  <Button
                    variant="text"
                    size="sm"
                    block
                    dashed
                    onClick={() => {
                      add();
                    }}
                    style={{
                      color: "#7F7F7F",
                    }}
                  >
                    Add Alternative Name
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        {state.similers.length > 0 && !state.verified && (
          <Form.Item name="mapField" label="Suggestions">
            <Radio.Group size="small">
              <Space direction="vertical">
                {state.similers.map(({ id: smilarId, title }) => (
                  <Radio
                    key={smilarId}
                    name={title}
                    data-name={title}
                    value={smilarId}
                    onChange={handleRadioOnChange}
                  >
                    {title}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
        {state.verified && (
          <Form.Item name="verified" label="Verified">
            <Select
              showSearch
              allowClear
              placeholder="Select Category"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {VERIFIED_OPTIONS.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item?.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <div
            style={{ display: "flex", gap: "5px", justifyContent: "center" }}
          >
            <Button type="submit" outline size="sm">
              {!state.verified && "Accept"}
              {state.verified && "Update"}
            </Button>
            {!state.verified && (
              <>
                <Button
                  size="sm"
                  onClick={handleUpdateAndMap}
                  style={{ flex: 1 }}
                >
                  {mapField && <span>Update and Map to {mapField.name}</span>}
                  {!mapField && <span>Update and Map to Verified Field</span>}
                </Button>
                <Button
                  variant="danger"
                  outline
                  size="sm"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
