import * as Yup from "yup";

export const criteriaValidationSchema = Yup.object().shape({
  totalExperience: Yup.object().shape({
    min: Yup.number().min(1, "Min must be greater than zero").required(),
    max: Yup.number()
      .moreThan(Yup.ref("min"), "Max must be greater than min")
      .required(),
  }),
  technologies: Yup.object()
    .shape({
      expected: Yup.array().nullable().of(Yup.object().nullable()),
      otherExpected: Yup.array().nullable().of(Yup.object().nullable()),
      plus: Yup.array().nullable().of(Yup.object().nullable()),
    })
    .test(
      "min-one-property",
      (value) => value.expected || value.otherExpected || value.plus
    ),
  education: Yup.object()
    .shape({
      type: Yup.number().nullable(),
      universities: Yup.array().nullable().of(Yup.object().nullable()),

      branches: Yup.array().nullable().of(Yup.object().nullable()),
    })
    .test(
      "min-one-property",
      (value) => value.type || value.universities || value.branches
    ),
  languages: Yup.array().nullable().of(Yup.object().nullable()),
  benefits: Yup.object().shape({
    expected: Yup.object()
      .shape({
        id: Yup.number(),
      })
      .nullable(),
  }),
  age: Yup.object().shape({
    min: Yup.number().required(),
    max: Yup.number().required(),
  }),
  budget: Yup.object().shape({
    min: Yup.number().required(),
    max: Yup.number().required(),
  }),
  positionLocation: Yup.object().shape({
    remote: Yup.string().nullable().required(),
    expected: Yup.string().nullable(),
  }),
  stockOption: Yup.object().shape({
    isRange: Yup.string().nullable().required(),
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
    certainValue: Yup.number().nullable(),
  }),
});

export const generalValidationSchema = Yup.object().shape({
  isExternal: Yup.bool().required(),
  title: Yup.string().required(),
  role: Yup.object()
    .shape({
      title: Yup.string().required(),
    })
    .nullable(),
  processDurationInDays: Yup.number().nullable(),
  description: Yup.string().required(),
  recruitmentStages: Yup.string().required(),
  assignee: Yup.object()
    .shape({
      name: Yup.string().required(),
    })
    .nullable(),
  content: Yup.object().shape({
    video: Yup.boolean(),
    loomVideoURL: Yup.string()
      .nullable()
      .matches(
        /^$|((https?:\/\/)?((www|\w\w)\.)?loom\.com\/)((([\w]{2,3})?)([^/]+\/(.)))/
      ),
  }),
  highlights: Yup.array().nullable().of(Yup.object().nullable()),
});
