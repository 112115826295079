import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonLink } from "tg-design";
import { BoxTitle, Row, SectionTitle, SectionDescription } from "./styles";
import SkillView from "./SkillView";
import RoleView from "./RoleView";
import OtherSkillsView from "./OtherSkillView";
import ExpectationView from "./ExpectationView";
import PersonalInfo from "./PersonalInfo";
import { TgTable } from "../../../../tg-styles/Layouts";
import { proExperiences } from "../../../../../constants";

const inviteColumns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
];

export default function GeneralInfoTab({
  user: {
    bio,
    email,
    experience,
    criteria,
    roles,
    skills,
    birthyear,
    status,
    invites,
    phone,
    otherSkills,
    matchSettings,
    social,
    createdAt,
    updatedAt,
    utms,
    resume,
    inviter,
    preferences,
  },
}) {
  const location = useLocation();
  const history = useHistory();
  const isPrintPage = location.pathname.includes("print");

  return (
    <>
      <PersonalInfo
        bio={bio}
        birthyear={birthyear}
        matchSettings={matchSettings}
        email={email}
        phone={phone}
        status={status}
        social={social}
        createdAt={createdAt}
        updatedAt={updatedAt}
        utms={utms}
        resume={resume}
        preferences={preferences}
      />

      <Row mt={3}>
        <BoxTitle fontSize={isPrintPage ? "16px" : "auto"}>
          Roles & Experience
        </BoxTitle>
        <Row my={3}>
          <SectionTitle>Total experience</SectionTitle>
          <SectionDescription>
            {proExperiences.find((s) => experience === s.value)?.name || "?"}
          </SectionDescription>
        </Row>
        {roles.length > 0 && <RoleView roles={roles} />}
      </Row>

      <Row mt={3}>
        <BoxTitle fontSize={isPrintPage ? "16px" : "auto"}>
          Programming Languages & Frameworks
        </BoxTitle>
        <SkillView skills={skills} />
      </Row>
      {otherSkills.length > 0 && (
        <Row mt={3}>
          <BoxTitle mb={3} fontSize={isPrintPage ? "16px" : "auto"}>
            Other Skills
          </BoxTitle>
          <OtherSkillsView otherSkills={otherSkills} />
        </Row>
      )}

      {!isPrintPage && (
        <>
          <Row mt={3} mb={isPrintPage ? 3 : 0}>
            <BoxTitle mb={3} fontSize={isPrintPage ? "16px" : "auto"}>
              Expectations
            </BoxTitle>
            <ExpectationView expectations={criteria} />
          </Row>
          <Row mt={3}>
            <BoxTitle mb={2}>Invitations</BoxTitle>
            {invites.length > 0 ? (
              <TgTable
                columns={inviteColumns}
                dataSource={invites}
                pagination={false}
              />
            ) : (
              "none"
            )}
          </Row>
          <Row mt={3}>
            <BoxTitle mb={2}>Inviter</BoxTitle>
            {inviter ? (
              <ButtonLink
                onClick={() => history.push(`/developers/${inviter.id}`)}
              >
                {inviter.name}
              </ButtonLink>
            ) : (
              "none"
            )}
          </Row>
        </>
      )}
    </>
  );
}
