import React, { useState, useEffect, useContext } from "react";
import { message, Spin } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { Button } from "tg-design";
import { WebSocketContext } from "../../../../../WebSocket";
import { ConfirmationPage } from "./ConfirmationPage";
import LinkedinWizard from "./WizardTab";
import { SuccessIcon } from "../../../../Icons";
import {
  FETCH_USER_FROM_LINKEDIN,
  UPDATE_USER_FROM_LINKEDIN,
} from "../../../queries";
import { CREATE_BULK_TECHNOLOGY } from "../../../../tools/queries";
import { removeTypename, captureErrorWithData } from "../../../../../helper";

const checkedStates = {
  avatarURL: false,
  bio: false,
  workHistory: false,
  education: false,
  languages: false,
  skills: false,
};

const states = {
  avatarURL: null,
  bio: null,
  workHistory: [],
  education: [],
  languages: [],
  skills: [],
};

const WaitMessage = () => {
  return (
    <>
      <Spin />
      <span style={{ marginLeft: "10px" }}>
        It may take a long time please wait
      </span>
    </>
  );
};

export default function LinkedinTab({ user, setIsNavigationAllowed }) {
  const [startLinkedinCrawl] = useLazyQuery(FETCH_USER_FROM_LINKEDIN);
  const ws = useContext(WebSocketContext);

  const [step, setStep] = useState({
    loading: false,
    key: 1,
  });
  const [linkedinData, setLinkedinData] = useState();

  const [state, setState] = useState(states);

  const [isChecked, setIsChecked] = useState(checkedStates);
  const [updatedState, setUpdatedState] = useState({});

  const [updateUser] = useMutation(UPDATE_USER_FROM_LINKEDIN);

  const [createBulkTechnology] = useMutation(CREATE_BULK_TECHNOLOGY);

  const [isUpdating, setIsUpdating] = useState(false);

  const onSubscribe = ({ uuid }) => ws.socket.emit("subscribe", { uuid });
  const onDisconnect = ({ uuid }) => ws.socket.emit("unsubscribe", { uuid });

  const onListen = ({ uuid }) => {
    ws.socket.on("linkedInCrawled", (data) => {
      if (data.error) {
        message.error(`An error has occurred: ${data.error}`);
        setStep({ ...step, key: 2, loading: false });
      } else {
        setStep({ ...step, key: 3, loading: false });
        setLinkedinData({ ...data.linkedin, uuid });
      }

      onDisconnect({ uuid });
    });
  };

  const handleClick = () => {
    setState(states);
    setIsChecked(checkedStates);

    if (!user.social.linkedin) {
      message.error(
        "Linked in profile not found. Please fill in linkedin to continue"
      );
    } else {
      setStep({ ...step, key: 2 });
    }
  };

  const handleConfirmation = async () => {
    const uuid = uuidv4();
    const linkedinURL = user.social?.linkedin || null;
    // eslint-disable-next-line
    const rawUrl = linkedinURL?.split("?").shift();
    const username = rawUrl?.split("/").filter(Boolean).pop();

    if (!username) {
      message.error("Username is not valid");
    }
    try {
      startLinkedinCrawl({
        variables: {
          uuid,
          username,
          userId: user.id,
        },
      });
      setStep({ ...step, loading: true });
      onSubscribe({ uuid });
      onListen({ uuid });
    } catch (error) {
      onDisconnect({ uuid });
      setStep({ ...step, loading: false });
    }
  };

  const handleStateChange = (tabName, changes) => {
    if (Array.isArray(changes)) {
      if (changes.find((item) => item.isChecked)) {
        const newState = {
          ...isChecked,
          [tabName]: true,
        };
        setIsChecked(newState);
      } else {
        const newState = {
          ...isChecked,
          [tabName]: false,
        };
        setIsChecked(newState);
      }
    } else {
      const newState = {
        ...isChecked,
        [tabName]: !isChecked[tabName],
      };
      setIsChecked(newState);
    }
    setState({ ...state, [tabName]: changes });
  };

  const createBacklog = () => {
    const technologies = updatedState.unverifiedSkills.map(
      (item) => item.title
    );
    const variables = {
      titles: technologies,
      verified: false,
      creatorToken: null,
      creator: user.id,
    };
    createBulkTechnology({ variables });
  };

  useEffect(() => {
    const work = state.workHistory.filter((item) => item).map((item) => item);
    const education = state.education
      .filter((item) => item)
      .map((item) => item);
    const language = state.languages.filter((item) => item).map((item) => item);
    const verified = state.skills.filter((item) => {
      if (item.suggestions.length > 0) {
        return item;
      }
      return null;
    });

    const newState = {
      avatarURL: state.avatarURL || null,
      isLinkedinAvatarUpload: !!state.avatarURL,
      bio: state.bio || null,
      workHistory: [...work],
      education: [...education],
      languages: [...language],
      verifiedSkills: [...verified],
      unverifiedSkills: state.skills.filter((item) => {
        if (item.suggestions.length === 0) {
          return item;
        }
        return null;
      }),
    };
    setUpdatedState(newState);
    // eslint-disable-next-line
  }, [state]);

  const handleUpdate = async () => {
    const variables = removeTypename({
      id: user.id,
      bio: updatedState.bio,
      isLinkedinAvatarUpload: !!state.avatarURL,
      avatarURL: updatedState.avatarURL,
      workHistory: updatedState.workHistory.map((item) => {
        return {
          company: item.company,
          position: item.position,
          description: item.description,
          startDate: item.startDate
            ? dayjs(item.startDate).format("YYYY-MM-DD")
            : null,
          endDate: item.endDate
            ? dayjs(item.endDate).format("YYYY-MM-DD")
            : null,
          present: item.present,
        };
      }),
      education: updatedState.education.map((item) => {
        return {
          school: item.suggestions
            ? item.suggestions[0].school.id
            : item.school.id,
          branch: item.suggestions
            ? item.suggestions[0].branch.id
            : item.branch.id,
          startDate: item.suggestion
            ? Number(item.suggestions[0].startDate)
            : Number(item.startDate),
          endDate: item.suggestions
            ? Number(item.suggestions[0].endDate)
            : Number(item.endDate),
          type: item.suggestions ? item.suggestions[0].type : item.type,
        };
      }),
      languages: updatedState.languages.map((item) => {
        return {
          language: item.suggestions
            ? item.suggestions[0]._id
            : item.language.id,
          level: item.suggestions ? item.suggestions[0].level : item.level,
          alternativeText: item.suggestions
            ? item.suggestions[0].alternativeTexts[0]
            : null,
        };
      }),
      otherSkills: updatedState.verifiedSkills.map((item) => {
        return item.id || item.suggestions[0]._id;
      }),
      uuid: linkedinData.uuid,
    });

    setIsUpdating(true);

    try {
      if (updatedState.unverifiedSkills.length > 0) {
        await createBacklog();
        message.success("New technology backlog created!");
      }

      if (Object.values(isChecked).some((item) => item)) {
        await updateUser({
          variables,
        });
        message.success("User profile has been updated!");
      }

      setIsUpdating(false);
      setStep({ ...step, loading: false, key: 4 });
    } catch (error) {
      captureErrorWithData(error);
    }
  };

  setIsNavigationAllowed(!step?.loading);

  return (
    <>
      <div>
        {step.key === 1 && (
          <div>
            <p>Automatically fill user’s profile from Linkedin</p>
            <Button onClick={handleClick}>Pull Details from Linkedin</Button>
          </div>
        )}
        {step.key === 2 && !step.loading && (
          <ConfirmationPage
            user={user}
            handleConfirmation={handleConfirmation}
          />
        )}
        {step.key === 3 && linkedinData && (
          <div>
            <p
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SuccessIcon />
              <span style={{ paddingLeft: "5px" }}>
                Successfully pulled profile details from Linkedin.
              </span>
            </p>
            <LinkedinWizard
              user={user}
              checked={isChecked}
              linkedinData={linkedinData}
              step={step}
              handleStateChange={(tabName, changes) =>
                handleStateChange(tabName, changes)
              }
              handleFinish={handleUpdate}
              loading={isUpdating}
            />
          </div>
        )}

        {step.key === 4 && (
          <div>
            <p
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SuccessIcon />
              <span style={{ paddingLeft: "5px" }}>
                Linkedin synchronization is completed.
              </span>
            </p>
            <Button onClick={handleClick}>Pull Details from Linkedin</Button>
          </div>
        )}

        {step.loading && <WaitMessage />}
      </div>
    </>
  );
}
